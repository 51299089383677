// tslint:disable max-line-length no-commented-code no-big-function

import { computed } from "mobx";

import {
  GDLLayoutSemantic,
  AnyModelType,
  DIRECTION_DIMENSION,
  HV_TO_WH,
  HV_TO_XY,
  WH_TO_HV,
  AdaptiveModelGuardian,
  Frame,
} from "@chuyuan/poster-data-access-layer";
import { cached, ClassStaticCache, memorize, castReadonly, mapIterableAsKeys, isNil } from "@chuyuan/poster-utils";

import { HV_KEYS, XY_KEYS, WH_KEYS } from "../helpers/misc";
import { AbstractDuplexFromReader, AbstractMapReader } from "../../utils/multi-value";

export type ChildLayoutOperator = {
  readonly offset?: ChildLayoutOffsetOperator;
  readonly dimension: ChildLayoutDimensionOperator;
  readonly adjustment: ChildLayoutAdjustmentOperator;
  readonly constraint?: ChildLayoutConstraintOperator;
};

export type OffsetUnitType = "px" | "percent";

export type DimensionUnitType = "px" | "percent" | "portion";

export type XY = "x" | "y";

export type WH = "width" | "height";

export type AdjustmentValueType =
  // 填充容器
  | "fill"
  // 固定尺寸
  | "fixed"
  // 适应内容
  | "adaptive"
  // 等比适应
  | "contain"
  // 等比覆盖
  | "cover"
  // 固定比例
  | "ratio"
  // 等比缩放
  | "scale";

export type ConstraintValueType = "start" | "center" | "end" | "stretch" | "scale";

export type ChildLayoutOffsetOperator = {
  readonly isValueMutable: (key: XY) => boolean;
  readonly getValue: (key: XY) => number;
  readonly setValue: (key: XY, value: number) => boolean;
  readonly getUnit: (key: XY) => OffsetUnitType;
  readonly getPossibleUnits: (key: XY) => ReadonlySet<OffsetUnitType>;
  readonly setUnit: (key: XY, unit: OffsetUnitType) => boolean;
};

export type ChildLayoutDimensionOperator = {
  readonly isValueMutable: (key: WH) => boolean;
  readonly getValue: (key: WH) => number;
  readonly setValue: (key: WH, value: number) => boolean;
  readonly getUnit: (key: WH) => DimensionUnitType;
  readonly getPossibleUnits: (key: WH) => ReadonlySet<DimensionUnitType>;
  readonly setUnit: (key: WH, unit: DimensionUnitType) => boolean;
};

export type ChildLayoutAdjustmentOperator = {
  readonly isRatioLockable: () => boolean;
  readonly getRatioLocked: () => boolean;
  readonly setRatioLocked: (value: boolean) => boolean;
  readonly isHidden: (key: WH) => boolean;
  readonly getValue: (key: WH) => AdjustmentValueType;
  readonly getPossibleValue: (key: WH) => ReadonlySet<AdjustmentValueType>;
  readonly setValue: (key: WH, value: AdjustmentValueType) => boolean | ChildLayoutAdjustmentSetValueRecord[];
};

export type ChildLayoutAdjustmentSetValueRecord = {
  target: AnyModelType;
  index: number;
  changes: Array<{
    kind: "to fixed";
    key: WH;
  }>;
  oldData: ElementChildLayoutData;
  newData: ElementChildLayoutData;
};

export type ChildLayoutConstraintOperator = {
  readonly getValue: (key: WH) => ConstraintValueType;
  readonly getPossibleValue: (key: WH) => ReadonlySet<ConstraintValueType>;
  readonly setValue: (key: WH, value: ConstraintValueType) => boolean;
};

const PIXEL_ONLY = new Set(["px"] as const);
const PERCENT_ONLY = new Set(["percent"] as const);
const PIXEL_AND_PERCENT = new Set(["px", "percent"] as const);
const PIXEL_AND_PERCENT_AND_PORTION = new Set(["px", "percent", "portion"] as const);

export const AdaptiveAbsoluteAdjustmentTypes = castReadonly(new Set<AdjustmentValueType>(["fixed", "adaptive"]));

export const NonAdaptiveAbsoluteAdjustmentTypes = castReadonly(new Set<AdjustmentValueType>(["fixed"]));

export const AdaptiveAbsoluteRatioLockedAdjustmentTypes = castReadonly(new Set<AdjustmentValueType>(["ratio", "contain", "cover"]));

export const NonAdaptiveAbsoluteRatioLockedAdjustmentTypes = castReadonly(new Set<AdjustmentValueType>(["ratio", "contain", "cover"]));

export const AdaptiveFlexAdjustmentTypes = castReadonly(new Set<AdjustmentValueType>(["adaptive", "fixed", "fill"]));

export const NonAdaptiveFlexAdjustmentTypes = castReadonly(new Set<AdjustmentValueType>(["fixed", "fill"]));

export const FlexRatioLockedAdjustmentTypes = castReadonly(new Set<AdjustmentValueType>(["ratio"]));

export const FlexParallelRatioLockedAdjustmentTypes = castReadonly(new Set<AdjustmentValueType>(["ratio", "scale"]));

export const FlexOrthogonalOnFixedRatioLockedAdjustmentTypes = castReadonly(new Set<AdjustmentValueType>(["ratio", "fill"]));

export const FlexOrthogonalOnRatioRatioLockedAdjustmentTypes = castReadonly(new Set<AdjustmentValueType>(["fixed", "fill"]));

export const AllAdjustmentTypes = {
  adaptive: {
    absolute: AdaptiveAbsoluteAdjustmentTypes,
    absoluteRatioLocked: AdaptiveAbsoluteRatioLockedAdjustmentTypes,
    flex: AdaptiveFlexAdjustmentTypes,
  },
  nonAdaptive: {
    absolute: NonAdaptiveAbsoluteAdjustmentTypes,
    absoluteRatioLocked: NonAdaptiveAbsoluteRatioLockedAdjustmentTypes,
    flex: NonAdaptiveFlexAdjustmentTypes,
  },
} as const;

export const ConstraintFixedTypes = castReadonly(new Set<ConstraintValueType>(["start", "end", "center", "stretch", "scale"]));

export const ConstraintAlignmentTypes = castReadonly(new Set<ConstraintValueType>(["start", "end", "center"]));

export type ElementChildLayoutData = {
  readonly op: GDLLayoutSemantic.Operator;
  readonly child: GDLLayoutSemantic.Child;
  readonly dimension: {
    readonly [K in "width" | "height"]: {
      mutable: boolean;
      value: number;
      dependency: "self" | "parent" | "children";
      unit: DimensionUnitType;
      possibleUnits: ReadonlySet<DimensionUnitType>;
    };
  };
  readonly offset: {
    readonly [K in "x" | "y"]: {
      mutable: boolean;
      value: number;
      reversed: boolean;
      dependency: "self" | "parent" | "children";
      unit: OffsetUnitType;
      possibleUnits: ReadonlySet<OffsetUnitType>;
    };
  };
  adjustment: {
    locked: boolean;
  } & {
    readonly [K in "width" | "height"]: {
      value: AdjustmentValueType;
      possibleValues: ReadonlySet<AdjustmentValueType>;
      hidden: boolean;
    };
  };
  constraint?: {
    readonly [K in "width" | "height"]: {
      value: ConstraintValueType;
      possibleValues: ReadonlySet<ConstraintValueType>;
    };
  };
};

export const getElementChildLayoutDataCpt = cached((target: AnyModelType) => computed(() => createElementChildLayoutData(target)));

function createElementChildLayoutData(target: AnyModelType): ElementChildLayoutData {
  const isAdaptiveTarget = !AdaptiveModelGuardian.is(target) ? false : target.kind !== "frame" ? true : target.layout.containerType !== "absolute";

  const AdjustmentTypes = isAdaptiveTarget ? AllAdjustmentTypes.adaptive : AllAdjustmentTypes.nonAdaptive;

  const { selfBox } = target.layout;
  const { width: selfBoxWidth, height: selfBoxHeight } = selfBox;

  const op = new GDLLayoutSemantic.Operator(target);
  const child = op.getChild();

  console.log("createElementChildLayoutData", {
    selfBox,
    selfBoxWidth,
    selfBoxHeight,
    child,
    op,
    target,
  });

  const data: ElementChildLayoutData = {
    op,
    child,
    dimension: {
      width: {
        unit: "px",
        value: selfBoxWidth,
        possibleUnits: PIXEL_AND_PERCENT,
        mutable: true,
        dependency: "self",
      },
      height: {
        unit: "px",
        value: selfBoxHeight,
        possibleUnits: PIXEL_AND_PERCENT,
        mutable: true,
        dependency: "self",
      },
    },
    offset: {
      x: {
        unit: "px",
        value: 0,
        possibleUnits: PIXEL_AND_PERCENT,
        mutable: true,
        reversed: false,
        dependency: "self",
      },
      y: {
        unit: "px",
        value: 0,
        possibleUnits: PIXEL_AND_PERCENT,
        mutable: true,
        reversed: false,
        dependency: "self",
      },
    },
    adjustment: {
      locked: false,
      width: {
        value: "fixed",
        possibleValues: AdjustmentTypes.absolute,
        hidden: false,
      },
      height: {
        value: "fixed",
        possibleValues: AdjustmentTypes.absolute,
        hidden: false,
      },
    },
  };

  console.log("createElementChildLayoutData-init-data", JSON.parse(JSON.stringify(data)));

  const contentBox = target.parent()?.layout.contentBox;

  if (child.kind === "absolute") {
    // 绝对布局
    // 只要是绝对布局, 就具备约束面板
    data.constraint = {
      width: {
        value: "start",
        possibleValues: ConstraintFixedTypes,
      },
      height: {
        value: "start",
        possibleValues: ConstraintFixedTypes,
      },
    };
    const { constraint } = data;

    const absolute = child.data;
    if (absolute.kind === "ratio") {
      // 绝对布局 + 宽px + 高px + 横px/% + 纵px/% + 可能为锁定比例
      const { ratio, alignment } = absolute;

      const { adjustment } = data;

      // 偏移面板同步单位和值
      for (const hv of HV_KEYS) {
        const xy = HV_TO_XY[hv];
        const offset = data.offset[xy];
        const { value, unit } = absolute[xy];
        offset.value = value;
        offset.unit = unit;
        if (unit === "percent") {
          offset.dependency = "parent";
        }
      }

      // 调整大小面板同步锁定比例状态
      adjustment.locked = isNil(ratio);

      if (!isNil(ratio)) {
        // 绝对布局 + 宽px + 高px + 横px/% + 纵px/% + 锁定比例
        // 调整大小面板: 调整值, 选项调整为锁定选项
        const value = typeof ratio === "number" ? "ratio" : ratio;
        const { dimension } = data;
        dimension.width.possibleUnits = dimension.height.possibleUnits = PIXEL_ONLY;
        adjustment.width.value = adjustment.height.value = value;
        adjustment.width.possibleValues = adjustment.height.possibleValues = AdjustmentTypes.absoluteRatioLocked;
        adjustment.height.hidden = true;
        constraint.width.possibleValues = constraint.height.possibleValues = ConstraintAlignmentTypes;
      }

      // 约束面板显示对齐信息
      constraint.width.value = alignment.horizontal;
      constraint.height.value = alignment.vertical;
    } else {
      // 绝对布局 + 宽px/% + 高px/% + 横px/% + 纵px/% + 一定不是锁定比例
      for (const hv of HV_KEYS) {
        const dimension = absolute[hv];
        const xy = HV_TO_XY[hv];
        const wh = HV_TO_WH[hv];

        const offsetInData = data.offset[xy];
        const dimInData = data.dimension[wh];

        if (dimension.kind === "fixed") {
          // 同步偏移单位和值
          {
            const { value, unit } = dimension.offset;
            offsetInData.value = value;
            offsetInData.unit = unit;
            if (unit === "percent") {
              offsetInData.dependency = "parent";
            }
          }

          const dim = dimension.dimension;
          if (dim === "auto") {
            // 宽或高为自适应
            // 调整大小面板: 值为适应内容
            dimInData.possibleUnits = PIXEL_ONLY;
            dimInData.mutable = true;
            data.adjustment[wh].value = "adaptive";
            if (target.kind === "frame") {
              dimInData.dependency = "children";
            }
            constraint[wh].possibleValues = ConstraintAlignmentTypes;
          } else {
            // 宽高为px/%
            const { unit } = dim;
            dimInData.unit = unit;
            if (unit === "percent") {
              const parentDimension = contentBox?.[wh];
              dimInData.value = parentDimension ? dimInData.value / parentDimension : 0;
              dimInData.dependency = "parent";
            }
          }

          // 约束面板显示对齐信息
          const alignment = (constraint[wh].value = dimension.alignment);

          if (alignment === "end") {
            offsetInData.value = -offsetInData.value;
            offsetInData.reversed = true;
          }
        } else {
          // 更新偏移面板
          const { unit } = dimension;
          offsetInData.unit = unit;
          offsetInData.possibleUnits = unit === "percent" ? PERCENT_ONLY : PIXEL_ONLY;
          dimInData.possibleUnits = unit === "percent" ? PERCENT_ONLY : PIXEL_ONLY;
          dimInData.unit = unit;

          if (unit === "percent") {
            // 更新尺寸依赖
            dimInData.dependency = "parent";
            const p = contentBox?.[wh];
            if (p) {
              dimInData.value /= p;
              offsetInData.value = selfBox[xy] / p;
            } else {
              dimInData.value = 0;
              offsetInData.value = dimension.before;
            }
          } else {
            offsetInData.value = selfBox[xy];
          }

          // 约束面板显示拉伸或者缩放
          constraint[wh].value = unit === "px" ? "stretch" : "scale";
        }
      }
    }
  } else {
    // flex布局
    const { parentType, direction } = child;

    // 统一初始值
    data.offset.x.mutable = data.offset.y.mutable = true;
    data.offset.x.value = selfBox.x;
    data.offset.y.value = selfBox.y;
    data.offset.x.possibleUnits = data.offset.y.possibleUnits = PIXEL_ONLY;
    data.dimension.width.possibleUnits = data.dimension.height.possibleUnits = PIXEL_ONLY;

    // 更新尺寸依赖
    for (const hv of HV_KEYS) {
      const wh = HV_TO_WH[hv];
      const dim = child.itemDirection === "static" ? child[wh] : child[DIRECTION_DIMENSION[child.direction][wh]];
      if (dim.kind === "adaptive") {
        if (target.kind === "frame") {
          data.dimension[wh].dependency = "children";
        }
        data.dimension[wh].mutable = true;
      } else if (dim.kind === "percent" || dim.kind === "portion") {
        data.dimension[wh].dependency = "parent";
      } else if (dim.kind === "stretch") {
        data.dimension[wh].mutable = true;
      }
    }

    // 设定具体值
    if (parentType === "list" && child.itemDirection === "dynamic") {
      // TODO: 父元素为自动布局且flex item跟随父元素布局方向
      const pwh = DIRECTION_DIMENSION.parallel[direction];
      const owh = DIRECTION_DIMENSION.orthogonal[direction];
      const parallelDimension = data.dimension[pwh];

      const { parallel, orthogonal } = child;

      const parallelAdjustment = data.adjustment[pwh];
      const orthogonalAdjustment = data.adjustment[owh];

      if (parallel.kind === "pixel" && orthogonal.kind === "pixel" && typeof child.ratio === "number") {
        // 锁定比例 + 固定比例
        data.adjustment.locked = true;
        parallelAdjustment.value = orthogonalAdjustment.value = "ratio";
        parallelAdjustment.possibleValues = FlexParallelRatioLockedAdjustmentTypes;
        orthogonalAdjustment.hidden = true;
        orthogonalAdjustment.possibleValues = FlexOrthogonalOnFixedRatioLockedAdjustmentTypes;
      } else {
        if (parallel.kind === "ratio") {
          // 锁定比例 + 等比缩放
          data.adjustment.locked = true;
          parallelAdjustment.value = "scale";
          parallelAdjustment.possibleValues = FlexParallelRatioLockedAdjustmentTypes;
          orthogonalAdjustment.value = convertFlexCommonKindToAdjustmentValue(orthogonal.kind);
          orthogonalAdjustment.possibleValues = FlexOrthogonalOnRatioRatioLockedAdjustmentTypes;
        } else {
          // 非锁定比例
          if (parallel.kind === "percent" || parallel.kind === "portion") {
            parallelDimension.unit = parallel.kind;
            parallelDimension.value = parallel.percent;
          }
          if (parallel.kind === "percent" || parallel.kind === "portion" || parallel.kind === "pixel") {
            parallelDimension.possibleUnits = PIXEL_AND_PERCENT_AND_PORTION;
          }
          parallelAdjustment.value = convertFlexCommonKindToAdjustmentValue(parallel.kind);
          orthogonalAdjustment.value = convertFlexCommonKindToAdjustmentValue(orthogonal.kind);
          parallelAdjustment.possibleValues = orthogonalAdjustment.possibleValues = AdjustmentTypes.flex;
        }
      }
      console.log("createElementChildLayoutData-adjustment", { parallelAdjustment, orthogonalAdjustment, parallel, orthogonal });
    } else {
      // 父元素为堆叠布局或者flex item固定方向
      const width = child.itemDirection === "static" ? child.width : child[DIRECTION_DIMENSION[child.direction].width];
      const height = child.itemDirection === "static" ? child.height : child[DIRECTION_DIMENSION[child.direction].height];
      if (width.kind === "adaptive" || width.kind === "stretch") {
        data.dimension.width.mutable = true;
      }
      if (height.kind === "adaptive" || height.kind === "stretch") {
        data.dimension.height.mutable = true;
      }
      if (width.kind === "pixel" && height.kind === "pixel" && typeof child.ratio === "number") {
        data.adjustment.locked = true;
        data.adjustment.width.value = data.adjustment.height.value = "ratio";
        data.adjustment.width.possibleValues = data.adjustment.height.possibleValues = FlexRatioLockedAdjustmentTypes;
        data.adjustment.height.hidden = true;
      } else {
        // data.adjustment.width.value = convertFlexCommonKindToAdjustmentValue(width.kind);
        // data.adjustment.height.value = convertFlexCommonKindToAdjustmentValue(height.kind);
        data.adjustment.width.possibleValues = data.adjustment.height.possibleValues = AdjustmentTypes.flex;
      }
      console.log("createElementChildLayoutData-wh", { width, height });
    }
  }

  console.log("createElementChildLayoutData-result-data", JSON.parse(JSON.stringify(data)));

  return data;
}

function convertFlexCommonKindToAdjustmentValue(kind: GDLLayoutSemantic.FlexChildDynamic["parallel"]["kind"]) {
  if (kind === "stretch") return "fill";
  if (kind === "adaptive") return "adaptive";
  if (kind === "pixel" || kind === "percent" || kind === "portion") return "fixed";
  if (kind === "ratio") return "scale";
  throw new Error(`Unknown kind ${kind}`);
}

export const CanvasAbsoluteAdjustmentTypes = castReadonly(new Set<AdjustmentValueType>(["fixed"]));

export const CanvasFlexAdjustmentTypes = castReadonly(new Set<AdjustmentValueType>(["fixed", "adaptive"]));

export class CanvasChildLayoutOperator implements ChildLayoutOperator {
  static of = ClassStaticCache;

  constructor(readonly target: Frame) {}

  @memorize
  get dimension() {
    return CanvasChildLayoutDimensionOperator.of(this.target);
  }

  @memorize
  get adjustment() {
    return CanvasChildLayoutAdjustmentOperator.of(this.target);
  }
}

export class CanvasChildLayoutDimensionOperator implements ChildLayoutDimensionOperator {
  static of = ClassStaticCache;

  constructor(readonly target: Frame) {}

  isValueMutable(key: WH) {
    console.log("isValueMutable-CanvasChildLayoutOperator", { key });
    const value = this.target.layout.canvas.dimension[key];
    return typeof value === "number";
  }

  getValue(key: WH) {
    return this.target.layout.selfBox[key];
  }

  setValue(key: WH, value: number) {
    console.log("CanvasChildLayoutDimensionOperator-setValue");
    this.target.layout.canvas.setDimension({ [key]: value });
    return true;
  }

  getUnit() {
    return "px" as const;
  }

  getPossibleUnits() {
    return PIXEL_ONLY;
  }

  setUnit() {
    return false;
  }
}

export class CanvasChildLayoutAdjustmentOperator implements ChildLayoutAdjustmentOperator {
  static of = ClassStaticCache;

  constructor(readonly target: Frame) {}

  isRatioLockable() {
    return false;
  }

  getRatioLocked() {
    return false;
  }

  setRatioLocked() {
    return false;
  }

  isHidden(_key?: WH) {
    return false;
  }

  getValue(key: WH) {
    const value = this.target.layout.canvas.dimension[key];
    if (typeof value === "number") return "fixed";
    return "adaptive";
  }

  getPossibleValue() {
    const { containerType } = this.target.layout;
    return containerType === "absolute" ? CanvasAbsoluteAdjustmentTypes : CanvasFlexAdjustmentTypes;
  }

  setValue(key: WH, value: AdjustmentValueType) {
    console.log("CanvasChildLayoutAdjustmentOperator");
    if (!this.getPossibleValue().has(value)) return false;
    if (this.getValue(key) === value) return false;
    const { layout } = this.target;
    const pixel = value === "adaptive" ? undefined : layout.selfBox.width;
    layout.canvas.setDimension({ [key]: pixel });
    return true;
  }
}

export class ElementChildLayoutOperator implements ChildLayoutOperator {
  static of = ClassStaticCache;

  constructor(readonly target: AnyModelType) {}

  @memorize
  get offset() {
    return ElementChildLayoutOffsetOperator.of(this.target);
  }

  @memorize
  get dimension() {
    return ElementChildLayoutDimensionOperator.of(this.target);
  }

  @memorize
  get adjustment() {
    return ElementChildLayoutAdjustmentOperator.of(this.target);
  }

  @computed
  get constraint() {
    const data = getElementChildLayoutDataCpt(this.target).get();
    if (data.constraint) return this._constraint;
    return undefined;
  }

  @memorize
  private get _constraint() {
    return ElementChildLayoutConstraintOperator.of(this.target);
  }
}

export class ElementChildLayoutOffsetOperator implements ChildLayoutOffsetOperator {
  static of = ClassStaticCache;

  constructor(readonly target: AnyModelType) {}

  /**
   * @internal
   */
  @memorize
  private get data() {
    const cpt = getElementChildLayoutDataCpt(this.target);
    return () => cpt.get();
  }

  isValueMutable(key: XY) {
    return this.data().offset[key].mutable;
  }

  getValue(key: XY) {
    return this.data().offset[key].value;
  }

  setValue(key: XY, value: number) {
    console.log("ElementChildLayoutOffsetOperator");
    const data = this.data();
    const offsetInData = data.offset[key];
    if (offsetInData.reversed) value = -value;
    if (!offsetInData.mutable || offsetInData.value === value) return false;
    const { op, child } = data;
    if (child.kind === "absolute") {
      return op.setAbsoluteOffsetValue(key, value);
    }
    return false;
  }

  getUnit(key: XY) {
    return this.data().offset[key].unit;
  }

  getPossibleUnits(key: XY) {
    return this.data().offset[key].possibleUnits;
  }

  setUnit(key: XY, unit: OffsetUnitType) {
    const data = this.data();
    const dimInData = data.offset[key];
    if (unit === dimInData.unit || !dimInData.possibleUnits.has(unit)) return false;
    const { op, child } = data;
    if (child.kind === "absolute") {
      return op.setAbsoluteOffsetUnit(key, unit);
    }
    return false;
  }
}

export class ElementChildLayoutDimensionOperator implements ChildLayoutDimensionOperator {
  static of = ClassStaticCache;

  constructor(readonly target: AnyModelType) {}

  /**
   * @internal
   */
  @memorize
  private get data() {
    const cpt = getElementChildLayoutDataCpt(this.target);
    return () => cpt.get();
  }

  isValueMutable(key: WH) {
    console.log("isValueMutable-ElementChildLayoutDimensionOperator", {
      key,
      a: this.data(),
      b: this.data().dimension,
      c: this.data().dimension[key],
      d: this.data().dimension[key].mutable,
    });
    return this.data().dimension[key].mutable;
  }

  getValue(key: WH) {
    return this.data().dimension[key].value;
  }

  setValue(key: WH, value: number) {
    const data = this.data();
    const dimInData = data.dimension[key];

    console.log("ElementChildLayoutDimensionOperator", { data, dimInData, value, key });

    if (!dimInData.mutable || dimInData.value === value) return false;
    const { op, child } = data;
    if (child.kind === "absolute") {
      return op.setAbsoluteDimensionValue(WH_TO_HV[key], value);
    } else {
      const dim = child.itemDirection === "dynamic" ? child[DIRECTION_DIMENSION[child.direction][key]] : child[key];

      console.log("ElementChildLayoutDimensionOperator", { data, dimInData, value, key, dim, DIRECTION_DIMENSION });

      if (dim.kind === "pixel") {
        return op.setFlexPixelValue(key, value);
      }
      if (dim.kind === "percent" || dim.kind === "portion") {
        return op.setFlexParallelPercentOrPortionValue(dim.kind, value);
      }
      if (dim.kind === "ratio") {
        const { selfBox } = this.target.layout;
        const dimension =
          child.direction === "row"
            ? {
                width: value,
                height: selfBox.height,
              }
            : {
                width: selfBox.width,
                height: value,
              };
        return op.setFlexParallelRatioValue(dimension);
      }
    }
    return false;
  }

  getUnit(key: WH) {
    return this.data().dimension[key].unit;
  }

  getPossibleUnits(key: WH) {
    return this.data().dimension[key].possibleUnits;
  }

  setUnit(key: WH, unit: DimensionUnitType) {
    const data = this.data();
    const dimInData = data.dimension[key];
    if (unit === dimInData.unit || !dimInData.possibleUnits.has(unit)) return false;
    const { op, child } = data;
    if (child.kind === "absolute") {
      if (unit !== "portion") {
        return op.setAbsoluteDimensionUnit(WH_TO_HV[key], unit);
      }
    } else {
      if (unit === "px") {
        return op.setFlexPixelType(key);
      }
      if (unit === "percent" || unit === "portion") {
        return op.setFlexParallelPercentOrPortionType(unit);
      }
    }
    return false;
  }
}

export class ElementChildLayoutAdjustmentOperator implements ChildLayoutAdjustmentOperator {
  static of = ClassStaticCache;

  constructor(readonly target: AnyModelType) {}

  /**
   * @internal
   */
  @memorize
  private get data() {
    const cpt = getElementChildLayoutDataCpt(this.target);
    return () => cpt.get();
  }

  isRatioLockable() {
    return true;
  }

  getRatioLocked() {
    return this.data().adjustment.locked;
  }

  setRatioLocked(value: boolean) {
    const data = this.data();
    if (data.adjustment.locked === value) return false;
    const { op, child } = data;
    if (child.kind === "absolute") {
      return op.setAbsolutePixelRatioLocked(value, true);
    } else {
      return op.setFlexPixelRatioLocked(value);
    }
  }

  isHidden(key: WH) {
    return this.data().adjustment[key].hidden;
  }

  getValue(key: WH) {
    return this.data().adjustment[key].value;
  }

  getPossibleValue(key: WH) {
    return this.data().adjustment[key].possibleValues;
  }

  setValue(key: WH, value: AdjustmentValueType) {
    console.log("ElementChildLayoutAdjustmentOperator");
    const data = this.data();
    const adjustmentInData = data.adjustment[key];
    const oldValue = adjustmentInData.value;

    console.log("ElementChildLayoutAdjustmentOperator", { oldValue, data, value, adjustmentInData, a: adjustmentInData.possibleValues.has(value) });

    if (oldValue === value || !adjustmentInData.possibleValues.has(value)) return false;

    const run = () => {
      const { op, child } = data;
      if (child.kind === "absolute") {
        const hv = WH_TO_HV[key];
        if (value === "adaptive") {
          return op.setAbsoluteAdaptiveType(hv);
        }
        if (value === "ratio") {
          return [op.setAbsoluteFixedType(hv), op.setAbsolutePixelRatioLocked(true)].some(Boolean);
        }
        if (value === "fixed") {
          return op.setAbsoluteFixedType(hv);
        }
        if (value === "contain" || value === "cover") {
          return [op.setAbsoluteFixedType(hv), op.setAbsolutePixelRatioLocked(value, true)].some(Boolean);
        }
      } else {
        const po = DIRECTION_DIMENSION[child.direction][key];
        if (value === "adaptive") {
          return op.setFlexAdaptiveType(po);
        }
        if (value === "fixed") {
          return op.setFlexPixelType(po);
        }
        if (value === "fill") {
          return op.setFlexStretchType(po);
        }
        if (value === "scale") {
          return op.setFlexParallelRatioType(true);
        }
        if (value === "ratio") {
          return [op.setFlexPixelType("width"), op.setFlexPixelType("height"), op.setFlexPixelRatioLocked(true)].some(Boolean);
        }
      }
      return false;
    };

    const { target } = this;

    if (target.kind === "frame") {
      const { containerType } = target.layout;
      if (containerType === "flex list" || containerType === "flex pile") {
        const { children } = target;

        // 收集子元素的尺寸
        const childrenDimensionMap = new Map(children.map((child) => [child, child.layout.selfBox[key]]));

        // 执行更新
        if (!run()) return false;

        // 检查是否需要更新子元素尺寸
        const newDependency = this.data().dimension[key].dependency;
        if (newDependency === "children") {
          const oldDependency = data.dimension[key].dependency;
          if (oldDependency !== newDependency) {
            const records: ChildLayoutAdjustmentSetValueRecord[] = [];
            for (let i = 0, leni = children.length; i < leni; i++) {
              const c = children[i];
              const cpt = getElementChildLayoutDataCpt(c);
              const childData = cpt.get();
              if (childData.dimension[key].dependency === "parent") {
                const op = new GDLLayoutSemantic.Product.Operator(c);
                const changed = [op.setDimensionType(key, "fixed").changed, op.setDimensionValue(key, childrenDimensionMap.get(c) || 0).changed].some(
                  Boolean
                );
                if (changed) {
                  records.push({
                    target: c,
                    index: i,
                    changes: [
                      {
                        kind: "to fixed",
                        key,
                      },
                    ],
                    oldData: childData,
                    newData: cpt.get(),
                  });
                }
              }
            }
            return records;
          }
        }
      }
    }

    return run();
  }
}

const EMPTY_SET: ReadonlySet<never> = new Set();

export class ElementChildLayoutConstraintOperator implements ChildLayoutConstraintOperator {
  static of = ClassStaticCache;

  constructor(readonly target: AnyModelType) {}

  /**
   * @internal
   */
  @memorize
  private get data() {
    const cpt = getElementChildLayoutDataCpt(this.target);
    return () => cpt.get();
  }

  getValue(key: WH): ConstraintValueType {
    const { constraint } = this.data();
    if (!constraint) return "start";
    return constraint[key].value;
  }

  getPossibleValue(key: WH) {
    const { constraint } = this.data();
    if (!constraint) return EMPTY_SET;
    return constraint[key].possibleValues;
  }

  setValue(key: WH, value: ConstraintValueType) {
    console.log("ElementChildLayoutConstraintOperator");
    const data = this.data();
    const { constraint } = data;
    if (!constraint) return false;
    const constraintInData = constraint[key];
    const oldValue = constraintInData.value;
    if (oldValue === value || !constraintInData.possibleValues.has(value)) return false;
    const { op, child } = data;
    if (child.kind === "absolute") {
      const hv = WH_TO_HV[key];
      if (value === "stretch" || value === "scale") {
        const ret = op.setAbsoluteAlignmentStretch(hv, STRETCH_SCALE_TO_PX_PERCENT[value], true);
        return typeof ret === "object" ? ret.changed : ret;
      }
      return [op.setAbsoluteAlignment(hv, value), op.setAbsoluteDimensionUnit(hv, "px")].some(Boolean);
    }
    return false;
  }
}

const STRETCH_SCALE_TO_PX_PERCENT = {
  stretch: "px",
  scale: "percent",
} as const;

export const OffsetFields = mapIterableAsKeys(XY_KEYS, (key) => {
  return {
    value: AbstractDuplexFromReader.define(
      (op: ElementChildLayoutOperator) => op.offset.getValue(key),
      (op: ElementChildLayoutOperator, value: number) => op.offset.setValue(key, value)
    ),
    unit: AbstractDuplexFromReader.define(
      (op: ElementChildLayoutOperator) => op.offset.getUnit(key),
      (op: ElementChildLayoutOperator, value: OffsetUnitType) => op.offset.setUnit(key, value)
    ),
    isValueMutable: AbstractMapReader.define((op: ElementChildLayoutOperator) => op.offset.isValueMutable(key)),
    possibleUnits: AbstractMapReader.define((op: ElementChildLayoutOperator) => op.offset.getPossibleUnits(key)),
  };
});

export const DimensionFields = mapIterableAsKeys(WH_KEYS, (key) => {
  return {
    value: AbstractDuplexFromReader.defineIdentical(
      (op: ChildLayoutOperator) => op.dimension.getValue(key),
      (op, value) => {
        return op.dimension.setValue(key, value);
      }
    ),
    unit: AbstractDuplexFromReader.defineIdentical(
      (op: ChildLayoutOperator) => op.dimension.getUnit(key),
      (op, value) => op.dimension.setUnit(key, value)
    ),
    isValueMutable: AbstractMapReader.define((op: ChildLayoutOperator) => op.dimension.isValueMutable(key)),
    possibleUnits: AbstractMapReader.define((op: ChildLayoutOperator) => op.dimension.getPossibleUnits(key)),
  };
});

export const RotationDuplex = AbstractDuplexFromReader.define(
  (target: AnyModelType) => target.layout.transform.rotation,
  function (target: AnyModelType, value: number) {
    if (this.getFn(target) === value) return false;
    target.layout.transform.rotation = value;
    return true;
  }
);

export const IsFlexItemInheritParentDirectionDisabledReader = AbstractMapReader.define((target: AnyModelType) => {
  if (target.kind === "frame" && !target.parent()) return false;
  const { child } = getElementChildLayoutDataCpt(target).get();
  return child.kind === "absolute";
});

export const FlexItemInheritParentDirectionDuplex = AbstractDuplexFromReader.define(
  (target: AnyModelType) => {
    const { child } = getElementChildLayoutDataCpt(target).get();
    return child.kind === "flex" && child.itemDirection === "dynamic";
  },
  function (target: AnyModelType, value: boolean) {
    if (this.getFn(target) === value) return false;
    const op = new GDLLayoutSemantic.Operator(target);
    return op.setFlexItemDirectionType(value ? "dynamic" : "static");
  }
);

// 调整大小面板

export const IsRatioLockableReader = AbstractMapReader.define((op: ChildLayoutOperator) => op.adjustment.isRatioLockable());

export const IsRatioLockedDuplex = AbstractDuplexFromReader.define(
  (op: ChildLayoutOperator) => op.adjustment.getRatioLocked(),
  function (op: ChildLayoutOperator, value: boolean) {
    if (this.getFn(op) === value) return false;
    return op.adjustment.setRatioLocked(value);
  }
);

export const AdjustmentDimensionFields = mapIterableAsKeys(WH_KEYS, (key) => {
  return {
    value: AbstractDuplexFromReader.define(
      (op: ChildLayoutOperator) => op.adjustment.getValue(key),
      function (op: ChildLayoutOperator, value: AdjustmentValueType) {
        if (this.getFn(op) === value) return false;
        return op.adjustment.setValue(key, value);
      }
    ),
    possibleValues: AbstractMapReader.define((op: ChildLayoutOperator) => op.adjustment.getPossibleValue(key)),
    hidden: AbstractMapReader.define((op: ChildLayoutOperator) => op.adjustment.isHidden(key)),
  };
});

export const HasConstraintReader = AbstractMapReader.define((op: ChildLayoutOperator) => !!op.constraint);

export const ConstraintFields = mapIterableAsKeys(WH_KEYS, (key) => {
  return {
    value: AbstractDuplexFromReader.define(
      (op: ChildLayoutOperator) => op.constraint?.getValue(key) || "start",
      function (op: ChildLayoutOperator, value: ConstraintValueType) {
        const { constraint } = op;
        if (!constraint) return false;
        if (this.getFn(op) === value) return false;
        return constraint.setValue(key, value);
      }
    ),
    possibleValues: AbstractMapReader.define((op: ChildLayoutOperator) => op.constraint?.getPossibleValue(key) || EMPTY_SET),
  };
});
