import { Frame } from "@chuyuan/poster-data-access-layer";
import { EntityLayoutData } from "@chuyuan/poster-data-structure";
import { guardTypeExtends } from "@chuyuan/poster-utils";
import { DimensionLike } from "../helpers/misc";

export type AddTargetInput = {
  readonly parent: Frame;
};

export function getDefaultEntityLayoutData(parent: DimensionLike) {
  const { width, height } = parent;
  return guardTypeExtends<EntityLayoutData>()({
    absolute: {
      kind: "constraint",
      horizontal: {
        kind: "fixed",
        dimension: { unit: "px", value: width },
        offset: { unit: "px", value: 0 },
        alignment: "start",
      },
      vertical: {
        kind: "fixed",
        dimension: { unit: "px", value: height },
        offset: { unit: "px", value: 0 },
        alignment: "start",
      },
    },
    flexItem: {
      kind: "static",
      width: {
        kind: "stretch",
      },
      height: {
        kind: "stretch",
      },
    },
  });
}

export function getTextDefaultEntityLayoutData(parent: DimensionLike) {
  const { width, height } = parent;
  return guardTypeExtends<EntityLayoutData>()({
    absolute: {
      kind: "constraint",
      horizontal: {
        kind: "fixed",
        dimension: { unit: "px", value: width },
        offset: { unit: "px", value: 0 },
        alignment: "start",
      },
      vertical: {
        kind: "fixed",
        dimension: { unit: "px", value: height / 8 },
        offset: { unit: "px", value: height / 4 },
        alignment: "start",
      },
    },
    flexItem: {
      kind: "static",
      width: {
        kind: "stretch",
      },
      height: {
        kind: "stretch",
      },
    },
  });
}

export function getImageLayoutData(parent: DimensionLike, resolution: DimensionLike) {
  const { width, height } = parent;
  const { width: w, height: h } = resolution;
  const s = Math.min(w ? width / w : 0, h ? height / h : 0);
  return guardTypeExtends<EntityLayoutData>()({
    absolute: {
      kind: "ratio",
      width: w,
      height: h,
      ratio: s,
      x: { unit: "px", value: (width - w * s) / 2 },
      y: { unit: "px", value: (height - h * s) / 2 },
      alignment: {
        kind: "static",
        horizontal: "start",
        vertical: "start",
      },
    },
    flexItem: {
      kind: "static",
      width: { kind: "pixel", pixel: w },
      height: { kind: "pixel", pixel: h },
    },
  });
}
