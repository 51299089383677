import { useObserver } from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import { i18n } from "../../utils/i18n";
import { useModule } from "../../utils/modulize";
import { renderReactPortalChildren } from "../../utils/react-portal-helpers";
import { EditorSlotModuleToken } from "./editor-slot-module";
import { LeftOutlined, ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Divider, Input, Space } from "antd";
import { EditorContext } from "../helpers/react-context";
import { EditorNavigatorSlotModuleToken } from "./navigator-module";

export const Navigator = React.memo(
  () => {
    return useObserver(() => {
      const editor = React.useContext(EditorContext);
      const EditorNavigatorSlotModule = useModule(EditorNavigatorSlotModuleToken);
      if (!EditorNavigatorSlotModule) return null;

      const onUndo = () => {
        editor.session.history.canUndo && editor.session.history.undo();
      };

      const onRedo = () => {
        editor.session.history.canRedo && editor.session.history.redo();
      };

      // console.log("historyFetcher", { canUndo: editor.session.history.canUndo, canRedo: editor.session.history.canRedo });

      const onInput = ({ target }: any) => {
        console.log("onInput", { a: target.value });
        editor.componentName = target.value;
      };

      const onBack = () => {
        window.close();
      };

      return (
        <NavDOM>
          <div className="left-bar">
            <Space style={{ marginLeft: 20 }}>
              <LeftOutlined onClick={onBack} />
              <Divider type="vertical" />
              <div className="text">
                {/* <文件标题 /> */}
                <Input placeholder="请填写组件名称..." bordered={false} onChange={onInput} />
              </div>
              <div className="text">
                <尺寸 />
              </div>
              <Divider type="vertical" />
              <ArrowLeftOutlined style={{ color: editor.session.history.canUndo ? "#000" : "#999" }} onClick={onUndo} />
              <ArrowRightOutlined style={{ color: editor.session.history.canRedo ? "#000" : "#999" }} onClick={onRedo} />
            </Space>
          </div>
          <div className="middle-bar"></div>
          <div className="right-bar">
            <NavigatorRight />
          </div>
        </NavDOM>
      );
    });
  },
  () => true
);
Navigator.displayName = "Navigator";

const NavigatorRight = React.memo(() => {
  return useObserver(() => {
    const items = useModule(EditorSlotModuleToken)?.navBarRight.items;
    return <>{renderReactPortalChildren(items)}</>;
  });
});
NavigatorRight.displayName = "NavigatorRight";

const NavDOM = styled.div`
  min-height: 60px;
  padding: 0;
  background: #fff;
  border-bottom: 1px solid #eae9e8;
  overflow-x: auto;
  overflow-y: visible;
  display: flex;

  .left-bar {
    flex: 0 0 auto;
    display: flex;
  }

  .middle-bar {
    flex: 1 1 0;
    display: flex;
  }

  .right-bar {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
  }
`;

// const 文件标题 = i18n({
//   en: () => "文件标题",
//   zh: () => "文件标题",
// });

const 尺寸 = i18n({
  en: () => "尺寸",
  zh: () => "尺寸",
});
