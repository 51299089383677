import { useMemo } from "react";
import { observable, runInAction } from "mobx";
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  isPlainObject,
} from "@chuyuan/poster-utils";

const DEFAULT_OPTIONS = { deep: false } as const;

export function useSource<T extends object>(current: T) {
  const source = useMemo(
    () => observable(current, EMPTY_OBJECT, DEFAULT_OPTIONS),
    EMPTY_ARRAY
  );
  if (process.env["NODE_ENV"] !== "production") {
    if (!isPlainObject(current)) {
      console.warn(`the passed source is not plain object`);
    }
  }
  runInAction(() => {
    for (const key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        if (Object.prototype.hasOwnProperty.call(current, key)) continue;
        (source as unknown as Record<string, unknown>)[key] = undefined;
      }
    }
    Object.assign(source, current);
  });
  return source;
}

export function useLocalStore<TStore, TSource extends object>(
  initializer: (source: TSource) => TStore,
  current: TSource
): TStore {
  const source = useSource(current);
  return useMemo(() => initializer(source), EMPTY_ARRAY);
}
