export function safeDimension(value?: number) {
  if (Number.isFinite(value!) && value! > 0) return value!
  return 0
}

export function safeDimensionOrUndefined(value?: number) {
  if (value === undefined) return undefined
  if (Number.isFinite(value) && value > 0) return value
  return 0
}

export function safeNumber(value?: number) {
  if (Number.isFinite(value!)) return value!
  return 0
}
