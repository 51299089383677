import { AnyModelType } from "@chuyuan/poster-data-access-layer";

/**
 * 判断单个节点是否可删除
 * @param target
 */
export function isTargetDeletable(target: AnyModelType) {
  if (target.kind === "frame" && !target.parent()) return false;
  return true;
}
