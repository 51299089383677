import * as React from "react";
import { useObserver } from "mobx-react";
import { runInAction } from "mobx";

import type { TextSpec } from "@chuyuan/poster-data-structure";
import {
  TextInlineGap,
  TextLineGap,
  TextTransformIcon,
  TextDecorationIcon,
  TextDecorationOverlineIcon,
  TextDecorationLineThroughIcon,
  TextDecorationUnderlineIcon,
} from "../../../../poster-ui/icons/new-icons";

import { i18n, useLocale } from "../../utils/i18n";
import { Block, Row, Gap, TitleText, Square } from "../ui-components/section";
import type { TextDecorationType } from "./state.text";
import { Radio, RadioOption } from "../ui-components/radio";
import { FontFamilyBlock } from "./font-family-block";
import type { DuplexFieldLike } from "../../utils/multi-value";
import { InputNumber } from "../ui-components/input-number";
import type { EditTextStylePanelContentProps } from "./edit-style-panel";
import { getInputProps } from "../../utils/react-multi-value";
import { EditorContext } from "../helpers/react-context";

export const BasicSettingsPanel = React.memo(
  (props: EditTextStylePanelContentProps) => {
    const { fields } = props;

    return useObserver(() => {
      return (
        <>
          <Block>
            <FontFamilyBlock language="zh" field={fields.fontFamilyZh} />
            <FontFamilyBlock language="en" field={fields.fontFamilyEn} />
            <Row className="margin-y">
              <Square>
                <TextInlineGap />
              </Square>
              <Gap />
              <InlineGapValue field={fields.inlineGap} />
              <Square>
                <TextLineGap />
              </Square>
              <LineGapValue field={fields.lineGap} />
            </Row>
            <Row className="margin-y">
              <Square>
                <TextTransformIcon />
              </Square>
              <Gap />
              <TextTransformValue field={fields.textTransform} />
            </Row>
            <Row className="margin-y">
              <Square>
                <TextDecorationIcon />
              </Square>
              <Gap />
              <TextDecorationValue field={fields.textDecoration} />
            </Row>
          </Block>
          {fields.kind === "basic" ? (
            <Block>
              <Row className="margin-y">
                <TitleText>
                  <字符超区时 />
                </TitleText>
              </Row>
              <Row className="margin-y">
                <OverflowValue field={fields.overflow} />
              </Row>
            </Block>
          ) : null}
        </>
      );
    });
  }
);
BasicSettingsPanel.displayName = "BasicSettingsPanel";

const InlineGapValue = React.memo(
  (props: { readonly field: DuplexFieldLike<number> }) => {
    const { field } = props;

    const editor = React.useContext(EditorContext);
    const locale = useLocale();

    return useObserver(() => {
      const multiValueProps = getInputProps(field, locale);
      return (
        <InputNumber
          {...multiValueProps}
          step={0.01}
          onChange={(newValue) => runInAction(() => field.set(newValue))}
          onComplete={() =>
            runInAction(() => {
              editor.session.history.push({ name: "修改字间距" });
            })
          }
        />
      );
    });
  }
);
InlineGapValue.displayName = "InlineGapValue";

const LineGapValue = React.memo(
  (props: { readonly field: DuplexFieldLike<number> }) => {
    const { field } = props;

    const editor = React.useContext(EditorContext);
    const locale = useLocale();

    return useObserver(() => {
      const multiValueProps = getInputProps(field, locale);
      return (
        <InputNumber
          {...multiValueProps}
          step={0.01}
          onChange={(newValue) => runInAction(() => field.set(newValue))}
          onComplete={() =>
            runInAction(() => {
              editor.session.history.push({ name: "修改行间距" });
            })
          }
        />
      );
    });
  }
);
LineGapValue.displayName = "LineGapValue";

const TextTransformValue = React.memo(
  (props: {
    readonly field: DuplexFieldLike<TextSpec.TextStyleTextTransformType>;
  }) => {
    const { field } = props;

    const editor = React.useContext(EditorContext);
    const locale = useLocale();

    return useObserver(() => {
      const multiValueProps = getInputProps(field, locale);
      return (
        <Radio
          {...multiValueProps}
          className="iconic"
          onChange={(newValue) =>
            runInAction(() => {
              if (!field.set(newValue).records.some(Boolean)) return;
              editor.session.history.push({ name: "修改文字大小写变换" });
            })
          }
          options={TextTransformOptions}
        />
      );
    });
  }
);
TextTransformValue.displayName = "TextTransformValue";

const TextTransformOptions: ReadonlyArray<
  RadioOption<TextSpec.TextStyleTextTransformType>
> = [
  {
    value: "none",
    title: () => "-",
  },
  {
    value: "lowercase",
    title: () => "aa",
  },
  {
    value: "capitalize",
    title: () => "Aa",
  },
  {
    value: "uppercase",
    title: () => "AA",
  },
];

const TextDecorationValue = React.memo(
  (props: { readonly field: DuplexFieldLike<TextDecorationType> }) => {
    const { field } = props;

    const editor = React.useContext(EditorContext);
    const locale = useLocale();

    return useObserver(() => {
      const multiValueProps = getInputProps(field, locale);
      return (
        <Radio
          {...multiValueProps}
          className="iconic"
          onChange={(newValue) =>
            runInAction(() => {
              if (!field.set(newValue).records.some(Boolean)) return;
              editor.session.history.push({ name: "修改文字装饰" });
            })
          }
          options={TextDecorationOptions}
        />
      );
    });
  }
);
TextDecorationValue.displayName = "TextDecorationValue";

const TextDecorationOptions: ReadonlyArray<RadioOption<TextDecorationType>> = [
  {
    value: "none",
    title: () => "-",
  },
  {
    value: "overline",
    title: () => <TextDecorationOverlineIcon />,
  },
  {
    value: "line-through",
    title: () => <TextDecorationLineThroughIcon />,
  },
  {
    value: "underline",
    title: () => <TextDecorationUnderlineIcon />,
  },
];

const OverflowValue = React.memo(
  (props: {
    readonly field: DuplexFieldLike<TextSpec.TextStyleOverflowType>;
  }) => {
    const { field } = props;

    const editor = React.useContext(EditorContext);
    const locale = useLocale();

    return useObserver(() => {
      const multiValueProps = getInputProps(field, locale);
      return (
        <Radio
          {...multiValueProps}
          onChange={(newValue) =>
            runInAction(() => {
              if (!field.set(newValue).records.some(Boolean)) return;
              editor.session.history.push({ name: "修改文字超区行为" });
            })
          }
          options={OverflowOptions}
        />
      );
    });
  }
);
OverflowValue.displayName = "OverflowValue";

const OverflowOptions: ReadonlyArray<
  RadioOption<TextSpec.TextStyleOverflowType>
> = [
  {
    value: "fit",
    title: () => <自适应字号 />,
  },
  {
    value: "hidden",
    title: () => <隐藏超区字符 />,
  },
  {
    value: "shrink",
    hidden: true,
    title: () => <等比缩小 />,
  },
];

const 字符超区时 = i18n({
  en: () => "Overflow Behavior",
  zh: () => "字符超区时",
});

const 自适应字号 = i18n({
  en: () => "Adaptive",
  zh: () => "自适应字号",
});

const 隐藏超区字符 = i18n({
  en: () => "Hidden",
  zh: () => "隐藏超区字符",
});

const 等比缩小 = i18n({
  en: () => "Shrink",
  zh: () => "等比缩小",
});
