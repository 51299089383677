import { RenderTypeMap } from "@chuyuan/poster-data-access-layer";
import { RendererAPI } from "@chuyuan/poster-renderer-react";
import { computed, makeObservable } from "mobx";
import { getResolvedResourceData } from "../../utils/mobx-resources";
import { SessionState } from "./session-state";

export class CanvasRenderState {
  constructor(readonly session: SessionState) {
    makeObservable(this);
  }

  @computed
  get renderParams() {
    const { ds, root } = this.session;
    return ds.render.render(root);
  }

  @computed
  get renderAPI(): RendererAPI<RenderTypeMap> {
    const dynamicFontManager = getResolvedResourceData(
      this.session.editor.resources.dynamicFontManager.get()
    );
    return {
      getUrlFromImageData: (url) => {
        const { editor } = this.session;
        if (editor.api.getImageRealUrl) {
          return (
            getResolvedResourceData(editor.resources.imageRealUrl.get(url)) ??
            url
          );
        }
        return url;
      },
      getFontFamily: async (uniqueName, content) => {
        const font = await dynamicFontManager?.getFontOperator(
          uniqueName,
          content
        );
        if (!font) return uniqueName;
        return font.uniqueName;
      },
      getFontPathInfo: () => undefined,
      getFontGlyphPath: () => undefined,
    };
  }
}
