import { DynamicFontManager } from "@chuyuan/poster-font-pack";
import { Resource, ResourceMap } from "../../utils/mobx-resources";
import { FontSetData } from "./api";
import { EditorState } from "./editor-state";

export class ResourcesState {
  constructor(readonly editor: EditorState) {}

  readonly fontSetList = new Resource<readonly FontSetData[]>();

  readonly dynamicFontManager = new Resource<DynamicFontManager>();

  /**
   * 把数据结构里的 url 转换为实际的 url
   */
  readonly imageRealUrl = new ResourceMap<string, string>();
}
