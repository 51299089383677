import * as React from "react";
import styled from "styled-components";
import { useObserver } from "mobx-react";
import { runInAction, computed } from "mobx";

import {
  AnyModelType,
  HV_TO_BOUNDING_KEY,
  HV_TO_WH,
} from "@chuyuan/poster-data-access-layer";
import { cached, memorize } from "@chuyuan/poster-utils";

import { Row } from "../ui-components/section";
import { IconBtn } from "../ui-components/icon-btn";
import { useLocalStore } from "../../utils/mobx-react-hooks";
import {
  alignAbsoluteTargetsToSide,
  alignAbsoluteTargetsToCentralLine,
  distributeAbsoluteTargetsFromEdgeToEdge,
} from "../helpers/gdl-move-absolute";
import { mergeTargetsVisualBounding } from "../helpers/gdl-bounding";
import { SessionState } from "../editor-state/session-state";
import { Bounding, HV_INDEXES } from "../helpers/misc";
import {
  boundingToCenter,
  pickDimensionToBounding,
} from "../helpers/data-picker";
import { EditorContext } from "../helpers/react-context";
import {
  AlignAllBottom,
  AlignAllCenterH,
  AlignAllCenterV,
  AlignAllH,
  AlignAllLeft,
  AlignAllRight,
  AlignAllTop,
  AlignAllV,
} from "../../../../poster-ui/icons/new-icons";

class State {
  constructor(
    readonly props: {
      readonly targets: ReadonlySet<AnyModelType>;
      readonly session: SessionState;
    }
  ) {}

  @computed
  get enabled() {
    const { targets } = this.props;
    if (!targets.size) return false;
    for (const target of targets) {
      const parent = target.parent();
      if (!parent) return false;
      const { containerType } = parent.layout;
      if (containerType !== "absolute") return false;
    }
    return true;
  }

  @memorize
  get handleSide() {
    const names = {
      left: "左",
      right: "右",
      top: "上",
      bottom: "下",
      horizontal: "水平居中",
      vertical: "竖直居中",
    };
    return cached((side: keyof Bounding | "horizontal" | "vertical") => {
      return () =>
        runInAction(() => {
          if (!this.enabled) return;
          const { targets, session } = this.props;
          if (!targets.size) return;
          if (targets.size === 1) {
            const target = Array.from(targets)[0];
            const parent = target.parent();
            if (!parent) return;
            const { selfBox, coordinate } = parent.layout;
            const transform = coordinate.contentFromWorldTransform;
            switch (side) {
              case "horizontal":
              case "vertical": {
                const offset = selfBox[HV_TO_WH[side]] / 2;
                alignAbsoluteTargetsToCentralLine(
                  targets,
                  side,
                  offset,
                  transform
                );
                break;
              }
              default: {
                const bounding = pickDimensionToBounding(selfBox);
                alignAbsoluteTargetsToSide(targets, side, bounding, transform);
              }
            }
          } else {
            const bounding = mergeTargetsVisualBounding(targets)!;
            switch (side) {
              case "horizontal":
              case "vertical": {
                const offset = boundingToCenter(bounding)[HV_INDEXES[side]];
                alignAbsoluteTargetsToCentralLine(targets, side, offset);
                break;
              }
              default: {
                alignAbsoluteTargetsToSide(targets, side, bounding);
              }
            }
          }
          session.history.push({ name: `应用自由布局${names[side]}对齐` });
        });
    });
  }

  @memorize
  get handleDistribute() {
    const names = {
      horizontal: "水平",
      vertical: "竖直",
    };
    return cached((direction: "horizontal" | "vertical") => {
      return () =>
        runInAction(() => {
          if (!this.enabled) return;
          const { targets, session } = this.props;
          if (targets.size < 2) return;
          const bounding = mergeTargetsVisualBounding(targets)!;
          const keys = HV_TO_BOUNDING_KEY[direction];
          const offset1 = bounding[keys.start];
          const offset2 = bounding[keys.end];
          distributeAbsoluteTargetsFromEdgeToEdge(
            targets,
            direction,
            offset1,
            offset2
          );
          session.history.push({ name: `应用自由布局${names[direction]}分布` });
        });
    });
  }
}
/**@name 绝对布局。包含水平左中右，竖直上中下 */
export const AbsoluteAlignmentBar = React.memo(
  (props: { readonly targets: ReadonlySet<AnyModelType> }) => {
    const { targets } = props;
    const { session } = React.useContext(EditorContext);
    const state = useLocalStore((p) => new State(p), { targets, session });
    return useObserver(() => {
      if (!state.enabled) return null;
      return (
        <Row className="auto edged gap-25" style={{ padding: "8px 12px" }}>
          <StyledIconBtn
            onClick={state.handleSide("left")}
            children={<AlignAllLeft />}
          />
          <StyledIconBtn
            onClick={state.handleSide("horizontal")}
            children={<AlignAllCenterH />}
          />
          <StyledIconBtn
            onClick={state.handleSide("right")}
            children={<AlignAllRight />}
          />
          <StyledIconBtn
            disabled={targets.size < 3}
            onClick={state.handleDistribute("horizontal")}
            children={<AlignAllH />}
          />
          {/* <Separator /> */}
          <StyledIconBtn
            onClick={state.handleSide("top")}
            children={<AlignAllTop />}
          />
          <StyledIconBtn
            onClick={state.handleSide("vertical")}
            children={<AlignAllCenterV />}
          />
          <StyledIconBtn
            onClick={state.handleSide("bottom")}
            children={<AlignAllBottom />}
          />
          <StyledIconBtn
            disabled={targets.size < 3}
            onClick={state.handleDistribute("vertical")}
            children={<AlignAllV />}
          />
        </Row>
      );
    });
  }
);
AbsoluteAlignmentBar.displayName = "AbsoluteAlignmentBar";

const StyledIconBtn = styled(IconBtn)`
  flex: 0 0 auto;
  padding: 2px;
  font-size: 24px;
`;
