
import * as React from 'react'

/**@name 注册/注销drop/dragover事件 */
export class DragDropProtector extends React.Component<React.PropsWithChildren> {
  override componentDidMount() {
    document.addEventListener('dragover', onPreventDefault)
    document.addEventListener('drop', onPreventDefault)
  }

  override componentWillUnmount() {
    document.removeEventListener('dragover', onPreventDefault)
    document.removeEventListener('drop', onPreventDefault)
  }

  override render() {
    const { children } = this.props
    return typeof children === 'undefined' ? null : children
  }
}

function onPreventDefault(e: Event) {
  e.preventDefault()
}
