// import { makeObservable } from 'mobx'
import { Locale } from '../../utils/i18n'
import { EditorState } from './editor-state'
import { ValueChain, ValueChainBox, ValueChainWithDefault } from '../../utils/value-chain'

export class EditorOptions {
  /**
   * @internal
   */
  readonly _allLocales = new ValueChainWithDefault<Locale>(new ValueChainBox('zh'))

  readonly locale = new ValueChain<Locale>()

  constructor(readonly editor: EditorState) {}
}
