import * as React from "react";
import { runInAction } from "mobx";
import { useObserver } from "mobx-react";
import { times } from "lodash";

import type { EntityLayoutContainerType, EntityLayoutFlexDirection } from "@chuyuan/poster-data-structure";
import type { GDLLayoutSemantic } from "@chuyuan/poster-data-access-layer";

import { Panel } from "../ui-components/panel";
import { Block, Row, Gap, TitleText, Square } from "../ui-components/section";
import { i18n, useLocale } from "../../utils/i18n";
import { LayoutFields } from "./state.ui";
import { RadioValue } from "../ui-components-stateful/enum";
import { RadioOption } from "../ui-components/radio";
import { CheckboxValue } from "../ui-components-stateful/boolean";
import { SpreadKeyDuplex } from "../../utils/multi-value";
import { Alignment2DValue } from "../ui-components-stateful/alignment-2d";
import { GapType } from "./state.layout.parent";
import { InputNumberArrayValue } from "../ui-components-stateful/number-array";
import { Dialog } from "../ui-components/dialog";
import { ShortValue } from "../ui-components-stateful/number";
import { IconBtn } from "../ui-components/icon-btn";
import { callAutoConversionMessage } from "./common";
import { useModule } from "../../utils/modulize";
import { GlobalContextModuleToken } from "../ui-components/global-context";
import { LeftRightPanel } from "../gdl-common/popup";
import {
  ElementLayoutAutoH,
  ElementLayoutAutoV,
  FromBottom,
  FromTop,
  Padding,
  PaddingBottom,
  PaddingLeft,
  PaddingRight,
  PaddingTop,
  PropertyDetails,
  SizeConstraintV,
} from "../../../../poster-ui/icons/new-icons";

/** @name 容器布局面板*/
export const LayoutContainerPanel = React.memo((props: { readonly fields: LayoutFields }) => {
  return useObserver(() => {
    const { fields } = props;

    const { targets } = fields;

    if (!targets.length) return null;

    const containerTypeValue = fields.containerType.get();
    return (
      <Panel title={<布局 />}>
        <ContainerTypeBlock fields={fields} />
        {fields.isAnyContainerTypeAbsolute.get() ? null : (
          <>
            <FlexDirectionBlock fields={fields} />
            {containerTypeValue !== "flex list" ? null : <FlexSortBlock fields={fields} />}
            <FlexChildrenAlignmentBlock fields={fields} />
            {containerTypeValue !== "flex list" ? null : <FlexGapsBlock fields={fields} />}
            <FlexPaddingBlock fields={fields} />
          </>
        )}
      </Panel>
    );
  });
});
LayoutContainerPanel.displayName = "LayoutContainerPanel";

const ContainerTypeBlock = React.memo((props: { readonly fields: LayoutFields }) => {
  const { fields } = props;

  const locale = useLocale();
  const ctx = useModule(GlobalContextModuleToken);

  return useObserver(() => {
    const field = fields.containerType;
    return (
      <Block>
        123
        <RadioValue
          field={field}
          historyName="容器布局类型"
          options={ContainerTypeOptions}
          onChange={(newValue) =>
            runInAction(() => {
              const { records } = field.set(newValue);
              const group = Array.from(
                (function* () {
                  for (const record of records) {
                    if (typeof record !== "object") continue;
                    yield* record;
                  }
                })()
              );
              callAutoConversionMessage(ctx, [group], locale);
              return records.some(Boolean);
            })
          }
        />
      </Block>
    );
  });
});
ContainerTypeBlock.displayName = "ContainerTypeBlock";

const FlexDirectionBlock = React.memo((props: { readonly fields: LayoutFields }) => {
  const { fields } = props;
  return useObserver(() => {
    // 当堆叠布局不继承父级, 也没有表达式控制的时候, 隐藏相关 ui
    if (fields.isFlexContainerInheritParentDirectionHidden.get()) return null;

    return (
      <Block>
        <Row className="margin-y">
          <Row>
            <TitleText>
              <排布方向 />
            </TitleText>
          </Row>
          {!fields.isSelfAndParentFlexContainer.get() ? null : (
            <Row className="auto pointer" as="label">
              <TitleText>
                <继承父级 />
              </TitleText>
              <Gap />
              <CheckboxValue field={fields.flexContainerInheritParentDirection} historyName={(v) => (v ? "设置为跟随方向" : "设置为独立方向")} />
            </Row>
          )}
        </Row>
        <Row>
          <RadioValue field={fields.flexDirection} historyName="排布方向" options={FlexDirectionOptions} />
        </Row>
      </Block>
    );
  });
});
FlexDirectionBlock.displayName = "FlexDirectionBlock";

const FlexSortBlock = React.memo((props: { readonly fields: LayoutFields }) => {
  const { fields } = props;
  return useObserver(() => {
    const direction = fields.flexDirection.get();

    return (
      <Block>
        <Row className="margin-y">
          <TitleText>
            <排布顺序 />
          </TitleText>
        </Row>
        <Row>
          <RadioValue field={fields.flexSort} historyName="排布顺序" options={direction === "row" ? FlexSortHOptions : FlexSortVOptions} />
        </Row>
      </Block>
    );
  });
});
FlexSortBlock.displayName = "FlexSortBlock";

const FlexChildrenAlignmentBlock = React.memo((props: { readonly fields: LayoutFields }) => {
  const { fields } = props;

  const duplex = fields.flexChildrenAlignment.parent;
  const nextFields = React.useMemo(() => {
    return {
      horizontal: new SpreadKeyDuplex(duplex, "horizontal"),
      vertical: new SpreadKeyDuplex(duplex, "vertical"),
    };
  }, [duplex]);

  return (
    <Block>
      <Row className="edged">
        <TitleText>
          <对齐状态 />
        </TitleText>
        <Alignment2DValue {...nextFields} historyName="网格布局子元素对齐" />
      </Row>
    </Block>
  );
});
FlexSortBlock.displayName = "FlexSortBlock";

const FlexGapsBlock = React.memo((props: { readonly fields: LayoutFields }) => {
  const { fields } = props;

  return useObserver(() => {
    const gapTypeValue = fields.flexGapType.get();
    return (
      <>
        <Block>
          <Row className="margin-y">
            <TitleText>
              <间距 />
            </TitleText>
          </Row>
          <Row>
            <RadioValue field={fields.flexGapType} options={FlexGapTypeOptions} historyName="间距类型" />
          </Row>
        </Block>
        {!gapTypeValue ? null : <FlexGapsListBlock fields={fields} type={gapTypeValue} />}
      </>
    );
  });
});
FlexGapsBlock.displayName = "FlexGapsBlock";

const FlexGapsListBlock = React.memo((props: { readonly fields: LayoutFields; readonly type: GapType }) => {
  const { fields, type } = props;

  const [isAdvancedDisplayed, setIsAdvancedDisplayed] = React.useState(false);

  const state = React.useRef<{
    block?: HTMLElement | null;
    btn?: HTMLElement | null;
  }>({}).current;

  return useObserver(() => {
    const gapTypeValue = fields.flexGapType.get();
    const minFullLength = fields.minFlexGapFullListLength.get()[0] || 0;
    return (
      <Block ref={(ref) => (state.block = ref)}>
        <Row className="margin-y edged">
          <TitleText>{type === "fixed" ? <固定间距 /> : <自动间距 />}</TitleText>
          {gapTypeValue !== "fixed" ? null : (
            <>
              <IconBtn
                disabled={minFullLength < 2}
                ref={(ref) => (state.btn = ref)}
                component={PropertyDetails}
                onClick={() => setIsAdvancedDisplayed(true)}
              />
              {!(isAdvancedDisplayed && state.block && state.btn) ? null : (
                <LeftRightPanel
                  target={state.btn}
                  block={state.block}
                  onClose={() => setIsAdvancedDisplayed(false)}
                  render={({ onClose }) => (
                    <Dialog title={<高级间距 />} onClose={onClose} style={{ width: 240 }}>
                      <AdvancedGaps fields={fields} />
                    </Dialog>
                  )}
                />
              )}
            </>
          )}
        </Row>
        <Row>
          <Square>
            <SizeConstraintV />
          </Square>
          <Gap />
          <InputNumberArrayValue
            field={fields.flexGapList}
            suffix="px"
            disabled={type === "auto"}
            historyName="固定间距值"
            onChange={(values) =>
              runInAction(() => {
                if (!values.length) return false;
                const length = fields.maxFlexGapFullListLength.get()[0] || 0;
                if (values.length > 1 && values.length > length) {
                  values = values.slice(0, length);
                }
                return fields.flexGapList.set(values).records.some(Boolean);
              })
            }
          />
        </Row>
      </Block>
    );
  });
});
FlexGapsListBlock.displayName = "FlexGapsListBlock";

function AdvancedGaps(props: { readonly fields: LayoutFields }) {
  const { fields } = props;

  return useObserver(() => {
    const gapsLength = fields.minFlexGapFullListLength.get()[0] || 0;

    if (!(gapsLength > 0)) return null;

    return (
      <>
        {times(gapsLength, (i) => {
          return (
            <Row key={i} className="margin-y">
              <Square>
                <SizeConstraintV />
              </Square>
              <Gap />
              <ShortValue field={fields.getFlexGapListIndex(i)} historyName="固定间距值" suffix="px" align="left" />
            </Row>
          );
        })}
      </>
    );
  });
}

const FlexPaddingBlock = React.memo((props: { readonly fields: LayoutFields }) => {
  const { fields } = props;

  const [isAdvancedDisplayed, setIsAdvancedDisplayed] = React.useState(false);

  const state = React.useRef<{
    block?: HTMLElement | null;
    btn?: HTMLElement | null;
  }>({}).current;

  return useObserver(() => {
    return (
      <>
        <Block ref={(ref) => (state.block = ref)}>
          <Row className="margin-y edged">
            <TitleText>
              <边距 />
            </TitleText>
            <IconBtn ref={(ref) => (state.btn = ref)} component={PropertyDetails} onClick={() => setIsAdvancedDisplayed(true)} />
          </Row>
          <Row>
            <Square>
              <Padding />
            </Square>
            <Gap />
            <InputNumberArrayValue
              field={fields.flexPadding}
              suffix="px"
              historyName="边距值"
              onChange={(values) =>
                runInAction(() => {
                  if (!values.length) return false;
                  return fields.flexPadding.set(values).records.some(Boolean);
                })
              }
            />
          </Row>
        </Block>
        {!(isAdvancedDisplayed && state.block && state.btn) ? null : (
          <LeftRightPanel
            target={state.btn}
            block={state.block}
            onClose={() => setIsAdvancedDisplayed(false)}
            render={({ onClose }) => (
              <Dialog title={<高级边距 />} onClose={onClose} style={{ width: 240 }}>
                <AdvancedPadding fields={fields} />
              </Dialog>
            )}
          />
        )}
      </>
    );
  });
});
FlexPaddingBlock.displayName = "FlexPaddingBlock";

function AdvancedPadding(props: { readonly fields: LayoutFields }) {
  const { fields } = props;
  const sides = fields.flexPaddingSides;
  return (
    <>
      {(
        [
          { side: "top", icon: PaddingTop },
          { side: "right", icon: PaddingRight },
          { side: "bottom", icon: PaddingBottom },
          { side: "left", icon: PaddingLeft },
        ] as const
      ).map(({ side, icon: Icon }, i) => {
        return (
          <Row key={i} className="margin-y">
            <Square>
              <Icon />
            </Square>
            <Gap />
            <ShortValue field={sides[side]} historyName={`${side}边距值`} dragReverse suffix="px" align="left" />
          </Row>
        );
      })}
    </>
  );
}

const ContainerTypeOptions: ReadonlyArray<RadioOption<EntityLayoutContainerType>> = [
  {
    value: "absolute",
    title: () => <自由 />,
  },
  {
    value: "flex pile",
    title: () => <堆叠 />,
  },
  {
    value: "flex list",
    title: () => <自动 />,
  },
];

const FlexDirectionOptions: ReadonlyArray<RadioOption<EntityLayoutFlexDirection>> = [
  {
    value: "column",
    title: () => <ElementLayoutAutoV />,
  },
  {
    value: "row",
    title: () => <ElementLayoutAutoH />,
  },
];

const FlexSortHOptions: ReadonlyArray<RadioOption<GDLLayoutSemantic.FlexListSort>> = [
  {
    value: "normal",
    title: () => <FromTop style={{ transform: `rotate(-90deg)` }} />,
  },
  {
    value: "reverse",
    title: () => <FromBottom style={{ transform: `rotate(-90deg)` }} />,
  },
];

const FlexSortVOptions: ReadonlyArray<RadioOption<GDLLayoutSemantic.FlexListSort>> = [
  {
    value: "normal",
    title: () => <FromTop />,
  },
  {
    value: "reverse",
    title: () => <FromBottom />,
  },
];

const FlexGapTypeOptions: ReadonlyArray<RadioOption<GapType>> = [
  {
    value: "fixed",
    title: () => <固定间距 />,
  },
  {
    value: "auto",
    title: () => <自动间距 />,
  },
];

const 布局 = i18n({
  en: () => "Layout",
  zh: () => "布局",
});

const 自由 = i18n({
  en: () => "Free",
  zh: () => "自由",
});

const 堆叠 = i18n({
  en: () => "Pile",
  zh: () => "堆叠",
});

const 自动 = i18n({
  en: () => "Auto",
  zh: () => "自动",
});

const 排布方向 = i18n({
  en: () => "Flex Direction",
  zh: () => "排布方向",
});

const 排布顺序 = i18n({
  en: () => "Flex Sort",
  zh: () => "排布顺序",
});

const 继承父级 = i18n({
  en: () => "Inherit",
  zh: () => "继承父级",
});

const 对齐状态 = i18n({
  en: () => "Children Alignment",
  zh: () => "对齐状态",
});

const 间距 = i18n({
  en: () => "Gaps",
  zh: () => "间距",
});

const 固定间距 = i18n({
  en: () => "Fixed Gaps",
  zh: () => "固定间距",
});

const 自动间距 = i18n({
  en: () => "Auto Gaps",
  zh: () => "自动间距",
});

const 高级间距 = i18n({
  en: () => "Advanced Gaps",
  zh: () => "高级间距",
});

const 边距 = i18n({
  en: () => "Padding",
  zh: () => "边距",
});

const 高级边距 = i18n({
  en: () => "Advanced Padding",
  zh: () => "高级边距",
});
