import { SketchPicker } from 'react-color'
import styled from 'styled-components'

export const StyledSketchPicker = styled(SketchPicker)`
  box-shadow: none !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  width: 100% !important;
  padding: 0 !important;

  input[style*="80%"] {
    width: 100% !important;
  }
`
StyledSketchPicker.displayName = 'StyledSketchPicker'
