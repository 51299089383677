import * as React from "react";
import { useObserver } from "mobx-react";
import styled from "styled-components";

import type { EditTextStylePanelContentProps } from "./edit-style-panel";
import type { TextSpec } from "@chuyuan/poster-data-structure";
import { EditorContext } from "../helpers/react-context";
import { IconBtn } from "../ui-components/icon-btn";
import { RadioOption } from "../ui-components/radio";
import {
  TextStripperLineIcon,
  TextUnderlineIcon,
  TextCaseIcon,
} from "../../../../poster-ui/icons/new-icons";

/**@name 大小写枚举 */
const TextTransformOptions: ReadonlyArray<
  RadioOption<TextSpec.TextStyleTextTransformType>
> = [
  {
    value: "none",
    title: () => "-",
  },
  {
    value: "lowercase",
    title: () => "aa",
  },
  {
    value: "capitalize",
    title: () => "Aa",
  },
  {
    value: "uppercase",
    title: () => "AA",
  },
];

export const EditTextStyleIconsPanelContent = React.memo(
  (props: EditTextStylePanelContentProps) => {
    const { fields } = props;
    const { textDecoration, textTransform } = fields;

    const [caseIndex, setCaseIndex] = React.useState(0);
    const [lineThroughShow, setLineThroughShow] = React.useState(false);
    const [underlineShow, setUnderlineShow] = React.useState(false);
    const editor = React.useContext(EditorContext);

    /**@name 切换大小写 */
    const onTextCase = () => {
      const newValue = TextTransformOptions[caseIndex]["value"];
      console.log("caseIndex", {
        caseIndex,
        newValue,
      });
      setCaseIndex(caseIndex >= 3 ? 0 : caseIndex + 1);
      if (!textTransform.set(newValue).records.some(Boolean)) return;
      editor.session.history.push({ name: "修改文字大小写变换" });
    };

    React.useEffect(() => {
      const newValue = lineThroughShow ? "line-through" : "none";
      if (!textDecoration.set(newValue).records.some(Boolean)) return;
    }, [lineThroughShow]);

    React.useEffect(() => {
      const newValue = underlineShow ? "underline" : "none";
      if (!textDecoration.set(newValue).records.some(Boolean)) return;
    }, [underlineShow]);

    return useObserver(() => {
      return (
        <>
          <StyleIconsDom>
            {/* 粗体-暂不做 */}
            {/* <IconBtn
            ref={setRef}
            className="fold"
            component={ArrowDown}
            onClick={() => setDisplay(true)}
        /> */}
            {/* 斜体-暂不做 */}
            {/* <IconBtn
            ref={setRef}
            className="fold"
            component={ArrowDown}
            onClick={() => setDisplay(true)}
        /> */}
            {/* 大小写 */}
            <IconBtn component={TextCaseIcon} onClick={onTextCase} />
            {/* 删除线 */}
            <IconBtn
              component={TextStripperLineIcon}
              onClick={() => {
                setLineThroughShow(!lineThroughShow);
              }}
            />
            {/* 下划线 */}
            <IconBtn
              component={TextUnderlineIcon}
              onClick={() => {
                setUnderlineShow(!underlineShow);
              }}
            />
          </StyleIconsDom>
        </>
      );
    });
  }
);
EditTextStyleIconsPanelContent.displayName = "EditTextStyleIconsPanelContent";

const StyleIconsDom = styled.div`
  display: flex;
  span {
    flex: 1;
    font-size: 20px;
  }
`;
