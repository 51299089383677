import * as raf from 'raf'

export class Raf {
  private id?: number

  private next?: () => void

  clear() {
    const { id } = this
    if (id === undefined) return
    raf.cancel(id)
    this.id = undefined
    this.next = undefined
  }

  flush() {
    const { id } = this
    if (id === undefined) return
    raf.cancel(id)
    this.id = undefined
    const fn = this.next
    if (fn) {
      this.next = undefined
      fn()
    }
  }

  push(block: () => void) {
    const { id } = this
    if (id === undefined) {
      block()
      this.checkInNextFrame()
    } else {
      this.next = block
    }
  }

  later(block: () => void) {
    if (this.id === undefined) {
      this.checkInNextFrame()
    }
    this.next = block
  }

  private checkInNextFrame() {
    this.id = raf(() => {
      this.id = undefined
      const fn = this.next
      if (fn) {
        this.next = undefined
        this.checkInNextFrame()
        fn()
      }
    })
  }
}
