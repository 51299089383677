import * as React from 'react'
import styled from 'styled-components'
import { i18n } from '../../utils/i18n'

/**
 * 一个无边框的多操作确认框
 */
export function ConfirmBox(props: {
  readonly description: () => React.ReactNode
  readonly actions: ReadonlyArray<{
    readonly title: () => JSX.Element
    readonly theme?: 'primary' | 'default'
    readonly action: () => void
  }>
}) {
  return (
    <Container>
      <div>{props.description()}</div>
      <Actions>
        {props.actions.map(action => {
          return (
            <Btn
              className={action.theme}
              onClick={action.action}
            >
              {action.title()}
            </Btn>
          )
        })}
      </Actions>
    </Container>
  )
}

export function SimpleConfirmBox(props: {
  readonly description: () => React.ReactNode
  readonly onOk?: () => void
  readonly onCancel?: () => void
}) {
  const { description, onOk, onCancel } = props
  return <ConfirmBox
    description={description}
    actions={[{
      title: () => <取消 />,
      action: () => onCancel?.(),
    }, {
      theme: 'primary',
      title: () => <确定 />,
      action: () => onOk?.(),
    }]}
  />
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 296px;
`

const Actions = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`

const Btn = styled.span`
  flex: 0 0 auto;
  margin: 0 16px;
  color: #606060;
  cursor: pointer;
  user-select: none;

  &.primary {
    color: #0040f0;
  }
`

const 取消 = i18n({
  en: () => 'Cancel',
  zh: () => '取消',
})

const 确定 = i18n({
  en: () => 'Confirm',
  zh: () => '确定',
})
