// 本模块用于计算popup框的位置
// 位置计算会考虑:
// 1. popup框必须在给定容器(container)内
// 2. popup框会避开指向对象(target)以及其他额外的需要躲避的区域(avoids)
// 3. popup框上的某个点对齐指向对象(target)的某个点, 这样的组合可以有多个, 按优先度匹配
// 同时, 计算会给出从popup框指向理想位置的点的坐标, 以及指向对象(target)在popup的位置

import * as React from "react";
import { EMPTY_ARRAY, predicate } from "@chuyuan/poster-utils";
import {
  PolygonClipping,
  subVector2D,
  getClosestPointToRing,
  getClosestPointToLineSegmentRaw,
  Vector2DArray,
} from "@chuyuan/poster-math";

import { useElementResizeDetectorOperator } from "../../utils/react-element-resize-detector";
import { useForceUpdate } from "../../utils/react-hooks";

// 位点
export type AnchorPoint =
  | "left-top"
  | "left"
  | "left-bottom"
  | "top"
  | "center"
  | "bottom"
  | "right-top"
  | "right"
  | "right-bottom";

// 锚点
export type Anchor = {
  /**
   * 指向锚点
   */
  readonly target: AnchorPoint;
  /**
   * 弹窗锚点
   */
  readonly content: AnchorPoint;
};

export interface LocatorProps {
  /**
   * 内容
   */
  readonly content: Bounding;
  /**
   * 容器
   */
  readonly container?: Bounding;
  /**
   * 区域
   */
  readonly region?: Bounding;
  /**
   * 指向对象的边框 (屏幕坐标系)
   */
  readonly target: Bounding;
  /**
   * 避开区域的边框 (屏幕坐标系)
   */
  readonly avoids?: readonly Bounding[];
  /**
   * 偏好的锚点列表
   */
  readonly anchors?: readonly Anchor[];
  /**
   * 间距
   */
  readonly padding?: number;
}

export interface LocatorData {
  /**
   * popup内容框的偏移值
   */
  readonly offset: readonly [number, number];
  /**
   * 指向箭头在popup内容框的哪条边上
   */
  readonly direction: "left" | "top" | "bottom" | "right" | "none";
  /**
   * 指向箭头起点在popup框的坐标
   */
  readonly pointer?: Vector2DArray;
}

/**
 * 获取 popup 定位参数
 */
export function getLocator(props: LocatorProps): LocatorData | undefined {
  const { content, padding = 8 } = props;
  let { container, region } = props;

  if (!container)
    container = {
      left: 0,
      top: 0,
      right: window.innerWidth,
      bottom: window.innerHeight,
    };
  if (!region) region = container;

  const anchors = ((): readonly Anchor[] => {
    const list = props.anchors || EMPTY_ARRAY;
    if (list.length) return list;
    return [
      { target: "center", content: "left" },
      { target: "center", content: "right" },
      { target: "center", content: "center" },
    ];
  })();

  const containerNFP = getInnerNFPFromBoundings(
    expandBounding(region, -padding),
    content
  );

  if (!containerNFP) return;

  const targetBounding = props.target;

  const targetNFP = getOuterNFPFromBoundings(
    expandBounding(targetBounding, padding),
    content
  );

  const avoids = props.avoids || EMPTY_ARRAY;

  const avoidPolygons = avoids.map((b) => {
    const nfp = getOuterNFPFromBoundings(expandBounding(b, padding), content);
    return [boundingToRing(nfp)];
  });

  // 以 locator 左上角为基准点的可行域
  const xyRegion = PolygonClipping.difference(
    [boundingToRing(containerNFP)],
    [boundingToRing(targetNFP)],
    ...avoidPolygons
  );

  const rets = anchors
    .map((anchor) => {
      // 需要将点转换到以 locator 左上角为基准的点
      const [tx, ty] = getBoundingAnchorPoint(targetBounding, anchor.target);
      const [lx, ly] = getBoundingAnchorPoint(content, anchor.content);
      const x = content.left - lx + tx;
      const y = content.top - ly + ty;
      // 当然, 计算结果里的 point 和 ring 也是以 locator 左上角为基准的
      const r = getClosestPointToMultiPolygon([x, y], xyRegion);
      if (!r) return;
      const targetPoint: Vector2DArray = [tx, ty];
      return { ...r, targetPoint };
    })
    .filter(predicate);

  if (!rets.length) return;

  const ret = rets.reduce(
    (a, b) => (b.distanceSquared < a.distanceSquared ? b : a),
    rets[0]
  );

  const offsetPoint = ret.point;

  const finalLocatorBounding = applyOffsetPointToBounding(offsetPoint, content);

  const position = getBoundingPositionToAnotherBounding(
    finalLocatorBounding,
    targetBounding
  );

  let pointer: Vector2DArray | undefined;
  if (position) {
    const [x, y] = ret.targetPoint;
    const [x1, y1, x2, y2] = getBoundingLine(finalLocatorBounding, position);
    const point = getClosestPointToLineSegmentRaw(x, y, x1, y1, x2, y2);
    pointer = subVector2D(point, offsetPoint);
  }

  return {
    offset: [offsetPoint[0] - container.left, offsetPoint[1] - container.top],
    direction: position || "none",
    pointer,
  };
}

/**
 * 以 react node 的方式渲染一个 popup 定位器
 */
export function Locator(
  props: Omit<LocatorProps, "content"> & {
    readonly children?:
      | React.ReactNode
      | ((data: LocatorData) => React.ReactNode);
  }
) {
  const [locatorRef, setLocatorRef] = React.useState<HTMLElement | null>(null);
  const forceRender = useForceUpdate();

  const op = useElementResizeDetectorOperator(forceRender);

  const stateRef = React.useRef<{
    readonly getRef: (ref: HTMLElement | null) => void;
  }>();
  const state =
    stateRef.current ||
    (stateRef.current = {
      getRef: (r) => {
        op.replace(r);
        setLocatorRef(r);
      },
    });

  const data = (locatorRef &&
    getLocator({
      ...props,
      content: locatorRef.getBoundingClientRect(),
    })) || {
    offset: [0, 0],
    direction: "none",
  };

  const { offset } = data;

  const children =
    typeof props.children === "function"
      ? props.children(data)
      : props.children;

  return (
    <div
      ref={state.getRef}
      style={{
        position: "absolute",
        float: "left",
        visibility: locatorRef ? undefined : "hidden",
        left: offset[0],
        top: offset[1],
      }}
    >
      {children}
    </div>
  );
}

function getBoundingAnchorPoint(b: Bounding, p: AnchorPoint): Vector2DArray {
  let x: number;
  let y: number;
  if (p.includes("left")) {
    x = b.left;
  } else if (p.includes("right")) {
    x = b.right;
  } else {
    x = (b.left + b.right) / 2;
  }
  if (p.includes("top")) {
    y = b.top;
  } else if (p.includes("bottom")) {
    y = b.bottom;
  } else {
    y = (b.top + b.bottom) / 2;
  }
  return [x, y];
}

function getBoundingPositionToAnotherBounding(
  surrounded: Bounding,
  surrounding: Bounding
) {
  if (surrounding.right <= surrounded.left) {
    if (surrounding.bottom <= surrounded.top) return;
    if (surrounding.top >= surrounded.bottom) return;
    return "left";
  }
  if (surrounding.left >= surrounded.right) {
    if (surrounding.bottom <= surrounded.top) return;
    if (surrounding.top >= surrounded.bottom) return;
    return "right";
  }
  if (surrounding.bottom <= surrounded.top) {
    if (surrounding.right <= surrounded.left) return;
    if (surrounding.left >= surrounded.right) return;
    return "top";
  }
  if (surrounding.top >= surrounded.bottom) {
    if (surrounding.right <= surrounded.left) return;
    if (surrounding.left >= surrounded.right) return;
    return "bottom";
  }
  return undefined;
}

function getBoundingLine(b: Bounding, side: keyof Bounding) {
  switch (side) {
    case "left":
      return [b.left, b.top, b.left, b.bottom] as const;
    case "right":
      return [b.right, b.top, b.right, b.bottom] as const;
    case "top":
      return [b.left, b.top, b.right, b.top] as const;
    case "bottom":
      return [b.left, b.bottom, b.right, b.bottom] as const;
  }
}

function applyOffsetPointToBounding(p: Vector2DArray, b: Bounding) {
  return {
    left: p[0],
    right: p[0] + b.right - b.left,
    top: p[1],
    bottom: p[1] + b.bottom - b.top,
  };
}

// 本模块的 nfp 的锚点定义为「bounding左上角」

function getOuterNFPFromBoundings(surrounded: Bounding, surrounding: Bounding) {
  const width = surrounding.right - surrounding.left;
  const height = surrounding.bottom - surrounding.top;
  return {
    left: surrounded.left - width,
    right: surrounded.right,
    top: surrounded.top - height,
    bottom: surrounded.bottom,
  };
}

function getInnerNFPFromBoundings(surrounded: Bounding, surrounding: Bounding) {
  const width = surrounding.right - surrounding.left;
  const right = surrounded.right - width;
  if (right < surrounded.left) return;

  const height = surrounding.bottom - surrounding.top;
  const bottom = surrounded.bottom - height;
  if (bottom < surrounded.top) return;

  return {
    left: surrounded.left,
    right,
    top: surrounded.top,
    bottom,
  };
}

function boundingToRing(b: Bounding): PolygonClipping.Ring {
  return [
    [b.left, b.top],
    [b.right, b.top],
    [b.right, b.bottom],
    [b.left, b.bottom],
  ];
}

function expandBounding(b: Bounding, size: number) {
  return {
    left: b.left - size,
    right: b.right + size,
    top: b.top - size,
    bottom: b.bottom + size,
  };
}

function getClosestPointToMultiPolygon(
  point: Vector2DArray,
  feasibleRegion: PolygonClipping.MultiPolygon
) {
  let picked:
    | undefined
    | {
        readonly point: Vector2DArray;
        readonly ring: PolygonClipping.Ring;
        readonly distanceSquared: number;
      };
  for (const polygon of feasibleRegion) {
    if (!polygon.length) continue;
    for (const ring of polygon) {
      const { point: closest, distanceSquared: d2 } = getClosestPointToRing(
        point,
        ring
      );
      if (!picked || picked.distanceSquared > d2) {
        picked = { point: closest, ring, distanceSquared: d2 };
      }
    }
  }
  return picked;
}

export type Bounding = {
  readonly left: number;
  readonly right: number;
  readonly top: number;
  readonly bottom: number;
};
