import * as React from "react";

import { EMPTY_ARRAY, Mutable, memorize } from "@chuyuan/poster-utils";
import { observable } from "mobx";

export interface PortalItem<T> {
  readonly id: number;
  readonly props: Readonly<T>;
}

export class PortalContainer<T> {
  /**
   * @internal
   */
  private _NEXT_ID = 0;

  /**
   * @internal
   */
  private readonly _itemsBox = observable.box<Set<PortalItem<T>>>(new Set(), {
    deep: false,
  });

  create(props: Readonly<T>) {
    const item = { id: this._NEXT_ID++, props };
    this._itemsBox.get().add(item);
    this._broadcast();
    return new PortalRegister<T>(this, item);
  }

  /**
   * @internal
   */
  _dispose(item: PortalItem<T>) {
    return this._itemsBox.get().delete(item);
  }

  /**
   * @internal
   */
  _broadcast() {
    const box = this._itemsBox;
    box.set(new Set(box.get()));
  }

  get items(): ReadonlySet<PortalItem<T>> {
    return this._itemsBox.get();
  }

  @memorize
  get Portal() {
    const container = this;
    const BoundPortal = React.memo((props: Readonly<T>) => {
      usePortal(container, props);
      return null;
    });
    BoundPortal.displayName = "BoundPortal";
    return BoundPortal;
  }
}

export class PortalRegister<T> {
  constructor(
    readonly container: PortalContainer<T>,
    readonly item: Mutable<PortalItem<T>>
  ) {}

  update(props: Readonly<T>) {
    const { container, item } = this;
    if (container.items.has(item)) {
      item.props = props;
      container._broadcast();
      return true;
    }
    return false;
  }

  dispose() {
    const { container, item } = this;
    if (container._dispose(item)) {
      container._broadcast();
      return true;
    }
    return false;
  }
}

export function Portal<T>(inputProps: {
  readonly props: Readonly<T>;
  readonly container: PortalContainer<T>;
}) {
  const { props, container } = inputProps;

  usePortal(container, props);

  return null;
}

export function usePortal<T>(
  container: PortalContainer<T>,
  props: Readonly<T>
) {
  const stateRef = React.useRef<{
    container: typeof container;
    props: typeof props;
    register?: PortalRegister<T>;
    readonly effect: () => () => void;
  }>();
  const state =
    stateRef.current ||
    (stateRef.current = {
      container,
      props,
      effect: () => () => {
        state.register?.dispose();
      },
    });

  const prevRegister = state.register;

  if (state.container === container) {
    if (prevRegister) {
      if (state.props !== props) {
        prevRegister.update(props);
      }
    } else {
      state.register = container.create(props);
    }
  } else {
    if (prevRegister) prevRegister.dispose();
    state.register = container.create(props);
  }

  state.props = props;

  React.useEffect(state.effect, EMPTY_ARRAY);
}
