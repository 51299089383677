import * as React from "react";
import styled from "styled-components";

import { ArrowRight, ArrowRightNew } from "../../../../poster-ui/icons/new-icons";
import { EMPTY_OBJECT, memorize } from "@chuyuan/poster-utils";
import { getAngle, Vector2DArray, cos, sin } from "@chuyuan/poster-math";

import type { CommonProps } from "./common";
import type { UseState } from "../../utils/react-hooks";
import { DragMask } from "./drag-mask";
import { Popup } from "./popup";
import { InputNumber } from "./input-number";
import { useLocale } from "../../utils/i18n";
import { 多个值 } from "../../utils/react-multi-value";
import { isMac } from "../../utils/device-detect";
import { boundingToCenter } from "../helpers/data-picker";

export interface AngleBtnProps extends CommonProps {
  readonly value: number | "various";
  readonly onChange?: (value: number) => void;
  readonly onComplete?: (value: number) => void;
}

export interface AngleBtnRef {
  readonly btn: HTMLButtonElement | null;
}

interface DotData {
  readonly point: Vector2DArray;
  readonly degree: number;
}

interface State {
  readonly isMouseDown?: {
    readonly btn: HTMLButtonElement;
    readonly x: number;
    readonly y: number;
  };
  readonly isDragging?: boolean;
  readonly isVisible?: boolean;
}

class Operator {
  btn: HTMLButtonElement | null = null;

  constructor(public props: AngleBtnProps, public stateRef: UseState<State>) {}

  @memorize
  get dots(): readonly DotData[] {
    const cx = 28;
    const cy = 28;
    const r = 28;
    const n = 8;
    const step = 360 / n;
    const dots: DotData[] = [];
    for (let i = 0; i < 8; i++) {
      const degree = i * step;
      const x = cx + r * cos(degree);
      const y = cy + r * sin(degree);
      dots.push({ point: [x, y], degree });
    }
    return dots;
  }

  readonly getBtnRef = (r: HTMLButtonElement | null) => (this.btn = r);

  readonly onBtnMouseDown = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!(e.buttons & 1)) return;
    e.preventDefault();
    e.stopPropagation();
    const { pageX: x, pageY: y, currentTarget: btn } = e;
    this.stateRef[1]((s) => ({ ...s, isMouseDown: { btn, x, y } }));
  };

  private _prevDegree = 0;

  readonly onDragging = (e: MouseEvent) => {
    const [state, setState] = this.stateRef;

    const { isMouseDown } = state;
    if (!isMouseDown) return;

    if (!state.isDragging) {
      if ((isMouseDown.x - e.pageX) ** 2 + (isMouseDown.y - e.pageY) ** 2 > 25) {
        setState((s) => ({ ...s, isDragging: true }));
      }
      return;
    }

    const b = isMouseDown.btn.getBoundingClientRect();
    const [cx, cy] = boundingToCenter(b);
    const dx = e.pageX - cx;
    const dy = e.pageY - cy;
    if (!dx && !dy) return;

    let degree = getAngle([dx, dy]);
    if (isMac() ? e.metaKey : e.ctrlKey) {
      degree = degree >> 0;
    } else if (e.shiftKey) {
      degree = ((degree / 5) >> 0) * 5;
    }
    degree = ((degree % 360) + 360) % 360;

    this._prevDegree = degree;

    this.props.onChange?.(degree);
  };

  readonly onDragEnd = () => {
    const [state, setState] = this.stateRef;
    setState((s) => ({
      ...s,
      isMouseDown: undefined,
      isDragging: false,
      isVisible: state.isDragging ? s.isVisible : true,
    }));
    if (state.isDragging) {
      this.props.onComplete?.(this._prevDegree);
    }
  };
}

export const AngleBtn = React.forwardRef<AngleBtnRef, AngleBtnProps>((props, ref) => {
  const { className, style, value, disabled } = props;

  const locale = useLocale();

  const stateRef = React.useState<State>(EMPTY_OBJECT);
  const [state, setState] = stateRef;

  const opRef = React.useRef<Operator>();
  const op = opRef.current || (opRef.current = new Operator(props, stateRef));
  op.props = props;
  op.stateRef = stateRef;

  React.useImperativeHandle(ref, () => ({
    get btn() {
      return op.btn;
    },
  }));

  return (
    <>
      <Btn
        ref={op.getBtnRef}
        className={`${className} angle-btn-btn`}
        disabled={disabled}
        onMouseDown={op.onBtnMouseDown}
        style={style}
        children={
          value === "various" ? "?" : <div className="angle-btn-arrow" style={{ transform: `rotate(${value}deg)` }} children={<ArrowRightNew />} />
        }
      />
      {disabled || !state.isVisible ? null : (
        <Popup
          onClose={() => setState((s) => ({ ...s, isVisible: false }))}
          render={() => {
            const { btn } = op;
            if (!btn) return;
            const [cx, cy] = boundingToCenter(btn.getBoundingClientRect());
            return (
              <Panel style={{ transform: `translate(${cx}px, ${cy}px)` }}>
                <div className="angle-btn-panel-padding">
                  {/* {op.dots.map((dot, i) => (
                    <div
                      key={i}
                      className="angle-btn-panel-dot"
                      onClick={() => {
                        props.onChange?.(dot.degree);
                        props.onComplete?.(dot.degree);
                      }}
                      style={{
                        transform: `translate(${dot.point[0]}px, ${dot.point[1]}px)`,
                      }}
                    />
                  ))} */}
                  <button
                    className="angle-btn-panel-circle"
                    onMouseDown={op.onBtnMouseDown}
                    children={
                      value === "various" ? (
                        "?"
                      ) : (
                        <div className="angle-btn-arrow" style={{ transform: `rotate(${value}deg)` }} children={<ArrowRightNew />} />
                      )
                    }
                  />
                </div>
                <InputNumber
                  className="whole"
                  step={1}
                  value={value === "various" ? undefined : value}
                  placeholder={value === "various" ? 多个值.get(locale) : undefined}
                  align="left"
                  suffix="°"
                  onChange={props.onChange}
                  onComplete={props.onComplete}
                />
              </Panel>
            );
          }}
        />
      )}
      {disabled || !state.isMouseDown ? null : <DragMask style={{ cursor: "grabbing" }} onDragging={op.onDragging} onDragEnd={op.onDragEnd} />}
    </>
  );
});

const Btn = styled.button`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 0;
  color: #606060;
  background: #f8f7f6;
  box-sizing: border-box;
  border-radius: 4px;
  border: 0;
  font-size: 16px;
  line-height: 16px;
  cursor: grab;
  user-select: none;

  &:active {
    background: #eae9e8;
    cursor: grabbing;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  svg {
    display: block;
  }
`;

const Panel = styled.div`
  position: absolute;
  width: 80px;
  left: -40px;
  top: -40px;
  transform-origin: 0 0;
  padding: 12px;
  background: #fff;
  box-shadow: 0px 8px 24px 3px rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  svg {
    display: block;
  }

  .angle-btn-panel-padding {
    position: relative;
    padding: 8px;
    margin-bottom: 12px;
  }

  .angle-btn-panel-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    color: #606060;
    background: #f8f7f6;
    border-radius: 20px;
    font-size: 16px;
    line-height: 16px;
    padding: 0;
    border: 0;
    cursor: grab;
    user-select: none;

    &:active {
      background: #eae9e8;
      cursor: grabbing;
    }
  }

  .angle-btn-panel-dot {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    left: -6px;
    top: -6px;
    border-radius: 4px;
    background: transparent;
    transform-origin: 0 0;
    cursor: pointer;

    ::after {
      width: 4px;
      height: 4px;
      content: " ";
      display: block;
      background: #eae9e8;
      border-radius: 2px;
    }

    &:hover {
      background: #f8f7f6;
      ::after {
        background: #c0c0c0;
      }
    }

    &:active {
      background: #eae9e8;
      ::after {
        background: #606060;
      }
    }
  }
`;
