import { observable, makeObservable, action } from "mobx";
import { DataStore, Frame } from "@chuyuan/poster-data-access-layer";
import { EditorViewport } from "./editor-viewport";
import { SessionState } from "./session-state";
import { EditorOptions } from "./editor-options";
import { ResourcesState } from "./resources-state";
import { EditorAPI } from "./api";

export class EditorState {
  componentName: string;
  static createDefaultRoot() {
    const ds = new DataStore();
    const root = new Frame(ds, {
      layout: {
        root: { width: 360, height: 640 },
      },
    });
    // const text = new Text(ds, {
    //   content: { text: "test123" },
    //   layout: {
    //     absolute: {
    //       kind: "constraint",
    //       horizontal: { kind: "stretch" },
    //       vertical: { kind: "stretch" },
    //     },
    //   },
    // });
    // root.setChildren([text]);
    return root;
  }

  /**
   * @internal
   */
  @observable.ref
  private _currentSession: SessionState;

  readonly viewport = new EditorViewport();

  readonly options = new EditorOptions(this);

  readonly resources = new ResourcesState(this);

  get session() {
    return this._currentSession;
  }

  constructor(readonly api: EditorAPI) {
    this._currentSession = new SessionState(this, EditorState.createDefaultRoot());
    this.componentName = "";
    makeObservable(this);
  }

  @action
  setNewSession(root?: Frame) {
    return (this._currentSession = new SessionState(this, root || EditorState.createDefaultRoot()));
  }

  /**
   * @internal
   */
  initialize() {}

  /**
   * @internal
   */
  destroy() {}
}
