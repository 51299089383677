// tslint:disable max-line-length

import { AnyModelType } from "@chuyuan/poster-data-access-layer";
import {
  LayoutPageIcon,
  LayoutGroupIcon,
  LayoutTextIcon,
  LayoutImageIcon,
  ElementLayoutPile,
  ElementLayoutAutoH,
  ElementLayoutAutoV,
} from "../../../../poster-ui/icons/new-icons";

/**
 * 获取 gdl 树节点统一的图标
 * @param target gdl树节点
 */
export function getGDLTargetIconComponent(target: AnyModelType) {
  if (!target.parent()) return LayoutPageIcon;
  switch (target.kind) {
    case "text":
      return LayoutTextIcon;
    case "image":
      return LayoutImageIcon;
  }
  const { containerType } = target.layout;
  // console.log("containerType", { containerType, parent: !target.parent() });
  if (containerType === "absolute") {
    return LayoutGroupIcon;
  }
  if (containerType === "flex pile") {
    return ElementLayoutPile;
  }
  const direction = target.layout.flexbox.childrenDirection;
  if (direction === "row") {
    return ElementLayoutAutoH;
  }
  return ElementLayoutAutoV;
}
