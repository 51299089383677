import { useObserver } from 'mobx-react'
import * as React from 'react'
import styled from 'styled-components'
import { useModule } from '../../utils/modulize'
import { EditorContext } from '../helpers/react-context'
import { EditorSlotModuleToken } from './editor-slot-module'
import { getSelectionFilter } from '../helpers/selection-filter'

/**@name 素材样式导航栏 */
export const ImageNavigator = React.memo((props: {
  readonly openEdit: React.MouseEventHandler
}) => {
  const { session } = React.useContext(EditorContext)

  const EditorSlotModule = useModule(EditorSlotModuleToken)

  return useObserver(() => {
    if (!EditorSlotModule) return null

    const selection = session.selection.set

    if (!selection.size) return null

    const targets = getSelectionFilter(session.root, selection).imagesOnly
    if (targets.size !== selection.size) return null

    return (
      <NavDOM>
        <div className="left-bar">
          {"图片效果(暂未开发)"}
        </div >
        <div className="middle-bar"></div>
        <div className="right-bar" onClick={props.openEdit}>
          图层编辑
        </div>
      </NavDOM >
    )
  })
}, () => true)

const NavDOM = styled.div`
  z-index: 9;
  min-height: 60px;
  padding: 0;
  background: #fff;
  border-bottom: 1px solid #eae9e8;
  overflow-x: auto;
  overflow-y: visible;
  display: flex;

  .left-bar {
    margin-left: 20px;
    align-items: center;
    flex: 0 0 auto;
    display: flex;
    cursor: pointer;
  }

  .middle-bar {
    flex: 1 1 0;
    display: flex;
  }

  .right-bar {
    cursor: pointer;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
`
