import * as React from "react";
import { useObserver } from "mobx-react";

import { EMPTY_ARRAY } from "@chuyuan/poster-utils";

import { useModule } from "../../utils/modulize";
import { EventSystemModuleToken } from "../event-system";
import { RootState, ContainerState, NodeState, getSharedState, RootOptions } from "./state";
import { PointerEvent, PointerEventRegisterData } from "./event";
import { BBox } from "./bbox";
import { RenderList, Render } from "./render";
import { EventLayer } from "./common";
import { MultiSelection } from "./multi-selection.ui";
import { RotationVisual, RotationEvent } from "./rotation.ui";
import { ResizeVisual, ResizeEvent } from "./resize.ui";
import { FlexListReorder } from "./flex-list-reorder.ui";
import { useDeepEqualCache } from "../../utils/react-hooks";
import { EditorContext } from "../helpers/react-context";
import { SelectableTarget } from "../editor-state/types";
import { useLocalStore } from "../../utils/mobx-react-hooks";
import { EditorSlotModuleToken } from "../editor/editor-slot-module";
import { CanvasEvent } from "../canvas-events/canvas-event";
import { AnyParentModelType, LeafModelType } from "@chuyuan/poster-data-access-layer";
import { INFINITE_BOUNDING } from "../helpers/misc";
import { GlobalContextModuleToken } from "../ui-components/global-context";

export const RootOptionsContext = React.createContext<RootOptions>({
  allowRotation: true,
  allowRegionSelection: true,
  allowMultiSelection: true,
  allowResize: true,
  allowMove: true,
  allowDisplayParents: true,
  allowDisplayPadding: true,
});

export const MinimalRootOptions: RootOptions = {
  allowRotation: false,
  allowRegionSelection: false,
  allowMultiSelection: false,
  allowResize: false,
  allowMove: false,
  allowDisplayParents: false,
  allowDisplayPadding: false,
};

export const Root = React.memo((props: { readonly target: SelectableTarget }) => {
  const { session } = React.useContext(EditorContext);
  const ctx = useModule(GlobalContextModuleToken);
  const eventSystem = useModule(EventSystemModuleToken);
  const EditorSlot = useModule(EditorSlotModuleToken);
  const options = useDeepEqualCache(React.useContext(RootOptionsContext));
  const rootTarget = props.target;
  const root = useLocalStore((p) => new RootState(p), {
    ctx,
    session,
    eventSystem,
    rootTarget,
    options,
  });
  React.useEffect(() => (root.mount(), () => root.unmount()), EMPTY_ARRAY);
  return (
    <>
      <CanvasEvent {...root.CanvasEventProps} />
      <Node root={root} parent={null} target={rootTarget} />
      {!EditorSlot ? null : (
        <EditorSlot.aboveGraphic.Portal priority={0}>
          <svg style={DEFAULT_CONTAINER_STYLE}>
            <RenderList root={root.renderInSvg} />
          </svg>
          <div style={DEFAULT_CONTAINER_STYLE}>
            <RenderList root={root.renderInReact} />
          </div>
        </EditorSlot.aboveGraphic.Portal>
      )}
      <RootDispatch root={root} />
    </>
  );
});
Root.displayName = "Root";

// 全局分发

const RootDispatch = React.memo((props: { readonly root: RootState }) => {
  const { root } = props;
  return useObserver(() => {
    return (
      <>
        <RootEvent root={root} />
        <RootOperationVisual root={root} />
        {!root.multiSelection.isMultiSelected ? null : <MultiSelection state={root.multiSelection} />}
        {!root.flexListReorder ? null : <FlexListReorder state={root.flexListReorder} />}
      </>
    );
  });
});
RootDispatch.displayName = "RootDispatch";

const RootEvent = React.memo((props: { readonly root: RootState }) => {
  const { root } = props;
  return useObserver(() => {
    const {
      eventRoot,
      props: { options },
    } = root;
    return (
      <>
        <PointerEvent root={eventRoot} register={root.RegionRegister} />
        <PointerEvent root={eventRoot} register={root.DeepSelectElementRegister} />
        {!root.selection ? null : <PointerEvent root={eventRoot} register={root.selection.register} />}
        {!options.allowMove || !root.move ? null : <PointerEvent root={eventRoot} register={root.move.register} />}
        {!options.allowRegionSelection || !root.regionSelectionInitData ? null : (
          <PointerEvent root={eventRoot} register={root.RegionSelectionRegister} />
        )}
      </>
    );
  });
});
RootEvent.displayName = "RootEvent";

// 全局界面

const RootOperationVisual = React.memo((props: { readonly root: RootState }) => {
  const { root } = props;
  const { options } = root.props;
  return (
    <>
      {!options.allowRegionSelection ? null : (
        <>
          <Render
            root={root.renderInSvg}
            props={{
              path: EMPTY_ARRAY,
              layer: "global",
              selected: true,
              children: <RegionSelectionFrame root={root} />,
            }}
          />
          <RegionSelectionChildrenVisual root={root} />
        </>
      )}
    </>
  );
});
RootOperationVisual.displayName = "RootOperationVisual";

// 选区的框

const RegionSelectionFrame = React.memo((props: { readonly root: RootState }) => {
  const { root } = props;
  return useObserver(() => {
    const curr = root.regionSelectionCurrentData;
    if (!curr) return null;

    const b = curr.bounding;

    const scale = 1 / root.props.session.viewport.scale || 0;

    return (
      <rect
        x={b.left}
        y={b.top}
        width={b.right - b.left}
        height={b.bottom - b.top}
        fill="#a0a0a0"
        fillOpacity="0.1"
        stroke="#a0a0a0"
        strokeWidth={scale}
        vectorEffect="non-scaling-size"
      />
    );
  });
});
RegionSelectionFrame.displayName = "RegionSelectionFrame";

const RegionSelectionChildrenVisual = React.memo((props: { readonly root: RootState }) => {
  const { root } = props;
  return useObserver(() => {
    const curr = root.regionSelectionCurrentData;
    if (!curr) return null;

    const b = curr.contentBounding;
    if (!b) return null;

    const tempSelection = curr.optimizedSelection;

    const children: React.ReactNode[] = [];

    for (const target of tempSelection) {
      children.push(
        <Render
          key={target.id}
          root={root.renderInSvg}
          props={{
            path: getSharedState(target).path,
            layer: "highlight",
            selected: false,
            children: <RegionSelectionAnyTargetFrame root={root} target={target} />,
          }}
        />
      );
    }

    return <>{children}</>;
  });
});
RegionSelectionChildrenVisual.displayName = "RegionSelectionChildrenVisual";

const RegionSelectionAnyTargetFrame = React.memo((props: { readonly root: RootState; readonly target: SelectableTarget }) => {
  return useObserver(() => {
    const { root, target } = props;

    const scale = 1 / root.props.session.viewport.scale || 0;

    const { width: w, height: h } = target.layout.selfBox;

    const color = getSharedState(target).frameColor;

    return (
      <rect
        fill="none"
        width={w}
        height={h}
        stroke={color}
        strokeWidth={scale}
        transform={`matrix(${target.layout.coordinate.selfFromWorldTransform.toArray()})`}
        vectorEffect="non-scaling-size"
      />
    );
  });
});
RegionSelectionAnyTargetFrame.displayName = "RegionSelectionAnyTargetFrame";

const DEFAULT_CONTAINER_STYLE: React.CSSProperties = {
  overflow: "visible",
  position: "absolute",
  display: "block",
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  pointerEvents: "none",
};

function Node(props: { readonly root: RootState; readonly parent: ContainerState | null; readonly target: SelectableTarget }) {
  const { target } = props;
  return useObserver(() => {
    if (target.kind === "frame") {
      return <ContainerNode {...props} target={target} />;
    } else {
      return <LeafNode {...props} target={target} />;
    }
  });
}

// 一般节点
const LeafNode = React.memo((props: { readonly root: RootState; readonly parent: ContainerState | null; readonly target: LeafModelType }) => {
  const { root, parent, target } = props;
  const state = root.getLeafNodeState(parent, target);
  React.useEffect(() => (state.mount(), () => state.unmount()), EMPTY_ARRAY);
  return <Operation state={state} />;
});
LeafNode.displayName = "LeafNode";

// 容器节点
const ContainerNode = React.memo(
  (props: { readonly root: RootState; readonly parent: ContainerState | null; readonly target: AnyParentModelType }) => {
    const { root, parent, target } = props;

    // console.log("ContainerNode", { root, parent, target });

    const state = root.getContainerNodeState(parent, target);
    React.useEffect(() => (state.mount(), () => state.unmount()), EMPTY_ARRAY);
    return useObserver(() => {
      const isSelectable = parent || root.props.session.ui.isNodeSelectable(target).value || (!target.parent() && state.isSelected);
      return (
        <>
          {!isSelectable ? null : <Operation state={state} />}
          {isSelectable && !state.isDisplayChildren
            ? null
            : target.children.map((child) => <Node key={child.id} root={root} parent={state} target={child} />)}
        </>
      );
    });
  }
);
ContainerNode.displayName = "ContainerNode";

const Operation = React.memo((props: { readonly state: NodeState }) => {
  const { state } = props;
  return useObserver(() => {
    const { path, isSelected } = state;
    const { root } = state.props;

    return (
      <>
        <OperationEvent state={state} />
        <Render
          root={root.renderInSvg}
          props={{
            path,
            layer: isSelected || state.isHighlighted ? "highlight" : "default",
            selected: isSelected,
            children: <OperationVisual state={state} />,
          }}
        />
      </>
    );
  });
});
Operation.displayName = "Operation";

const OperationEvent = React.memo((props: { readonly state: NodeState }) => {
  const { state } = props;
  return useObserver(() => {
    const { root, target, parent } = state.props;
    const shared = getSharedState(target);
    const { session, options } = root.props;
    const isSelectable = session.ui.selectableTypes.value.has(target.kind);
    return (
      <>
        {isSelectable && (state.isSelected || shared.isSelectable) ? <SelectionEvent state={state} /> : null}
        {root.multiSelection.isOptimisticMultiSelected || shared.isLocked ? null : (
          <>
            {options.allowResize && state.isSelected ? <ResizeEvent state={state.resize} /> : null}
            {options.allowRotation && state.isSelected && parent ? <RotationEvent state={state.rotation} /> : null}
          </>
        )}
      </>
    );
  });
});
OperationEvent.displayName = "OperationEvent";

const OperationVisual = React.memo((props: { readonly state: NodeState }) => {
  const { state } = props;
  return useObserver(() => {
    if (!state.isDisplayFrame) return null;

    const { root, target, parent } = state.props;
    const { options } = root.props;

    const shared = getSharedState(target);
    const setting = shared.selectFrameSetting.value;
    if (setting && !setting.visible) return null;

    const transform = target.layout.coordinate.selfFromWorldTransform.toArray();

    return (
      <g transform={`matrix(${transform})`}>
        <SelectionFrame state={state} />
        {root.multiSelection.isOptimisticMultiSelected || shared.isLocked ? null : (
          <>
            {options.allowResize && state.isSelected ? <ResizeVisual state={state.resize} /> : null}
            {options.allowRotation && state.isSelected && parent ? <RotationVisual state={state.rotation} /> : null}
          </>
        )}
      </g>
    );
  });
});
OperationVisual.displayName = "OperationVisual";

const DefaultPointerEventData: PointerEventRegisterData = {
  bbox: new BBox(INFINITE_BOUNDING),
  handlers: {},
};

// 选择事件

const SelectionEvent = React.memo((props: { readonly state: NodeState }) => {
  const { state } = props;
  return useObserver(() => {
    const { path, isSelected } = state;
    const { target, root } = state.props;
    const { layout } = target;
    const { coordinate, selfBox } = layout;

    const layer: EventLayer = isSelected
      ? root.multiSelection.isOptimisticMultiSelected
        ? "multi"
        : "highlight"
      : state.isHighlighted
      ? "highlight"
      : "default";

    const register = React.useMemo(
      () => ({
        path,
        layer,
        selected: isSelected,
        data: () => DefaultPointerEventData,
      }),
      [path, layer, isSelected]
    );

    register.data = () => {
      const transform = coordinate.selfFromWorldTransform;
      const inverseTransform = transform.clone().inverse();

      const bounding = {
        left: 0,
        right: selfBox.width,
        top: 0,
        bottom: selfBox.height,
      };
      // console.log('state.selectionHandlers', state.selectionHandlers);
      return {
        bbox: new BBox(bounding, inverseTransform),
        handlers: state.selectionHandlers,
        cursor: () => (root.isMoveStarted && !root.isMoving ? "not-allowed" : "auto"),
      };
    };

    return <PointerEvent root={root.eventRoot} register={register} />;
  });
});
SelectionEvent.displayName = "SelectionEvent";

// 选择框

const SelectionFrame = React.memo((props: { readonly state: NodeState }) => {
  const { state } = props;
  return useObserver(() => {
    const { root, target } = state.props;

    const scale = 1 / root.props.session.viewport.scale || 0;

    const { width: w, height: h } = target.layout.selfBox;

    const { isSelected } = state;

    const strokeWidth = (isSelected ? 1 : state.isHovering ? 2 : 1) * scale;
    const strokeDasharray = isSelected || state.isHovering ? undefined : 2 * scale;
    const color = getSharedState(target).frameColor;

    const frameNode = (
      <rect
        fill="none"
        width={w}
        height={h}
        stroke={color}
        strokeWidth={strokeWidth}
        strokeDasharray={strokeDasharray}
        strokeLinecap="butt"
        vectorEffect="non-scaling-size"
      />
    );

    if (isSelected && root.props.options.allowDisplayPadding) {
      do {
        const { paddingBox } = state;
        if (paddingBox) {
          const { left, right } = paddingBox;
          const cw = w - left - right;
          if (!(cw > 0)) break;
          const { top, bottom } = paddingBox;
          const ch = h - top - bottom;
          if (!(ch > 0)) break;
          const maskId = `content-padding-${target.id}`;
          return (
            <>
              <defs>
                <mask id={maskId} width={w} height={h}>
                  <rect width={w} height={h} fill="white" />
                  <rect x={left} y={top} width={cw} height={ch} fill="black" />
                </mask>
              </defs>
              <rect width={w} height={h} mask={`url(#${maskId})`} fill="#a0ff76" fillOpacity="0.3" />
              {frameNode}
            </>
          );
        }
      } while (false);
    }

    return frameNode;
  });
});
SelectionFrame.displayName = "SelectionFrame";
