import { DataStore, Frame } from "@chuyuan/poster-data-access-layer";
import { CanvasEventHost } from "../canvas-events/canvas-event";
import { CanvasRenderState } from "./canvas-render-state";
import { CanvasViewport } from "./canvas-viewport";
import { CustomClipboard } from "./clipboard-state";
import { EditorState } from "./editor-state";
import { HistoryState } from "./history-state";
import { SelectionState } from "./selection-state";
import { UIState } from "./ui-state";

export class SessionState {
  readonly ds: DataStore;

  readonly root: Frame;
  /**
   * 历史记录状态
   */
  readonly history: HistoryState;

  /**
   * 选择状态
   */
  readonly selection: SelectionState;

  readonly ui: UIState;

  readonly viewport: CanvasViewport;

  readonly render: CanvasRenderState;

  readonly canvasEvents: CanvasEventHost;

  readonly clipboard: CustomClipboard;

  constructor(readonly editor: EditorState, root: Frame) {
    this.ds = root.ds;
    this.root = root;
    this.selection = new SelectionState(this);
    this.history = new HistoryState(this);
    this.ui = new UIState(this);
    this.viewport = new CanvasViewport(this);
    this.render = new CanvasRenderState(this);
    this.canvasEvents = new CanvasEventHost();
    this.clipboard = new CustomClipboard();
  }
}
