import * as React from "react";
import { useObserver } from "mobx-react";
import { EMPTY_ARRAY } from "@chuyuan/poster-utils";

import { MultiSelectionState } from "./multi-selection.state";
import { PointerEvent, PointerEventRegister } from "./event";
import { Render } from "./render";
import { ResizeVisual } from "./resize.ui";

export const MultiSelection = React.memo(
  (props: { readonly state: MultiSelectionState }) => {
    const { state } = props;
    return (
      <>
        <MultiSelectionEvent state={state} />
        <MultiSelectionVisual state={state} />
      </>
    );
  }
);
MultiSelection.displayName = "MultiSelection";

function MultiSelectionEvent(props: { readonly state: MultiSelectionState }) {
  const { state } = props;
  return useObserver(() => {
    const { eventRoot } = state.root;
    return (
      <>
        <PointerEvent root={eventRoot} register={state.selectionRegister} />
        {Object.values<PointerEventRegister>(state.resize.hoverRegisters).map(
          (register, i) => (
            <PointerEvent key={i} root={eventRoot} register={register} />
          )
        )}
        {!state.resize.resizeData ? null : (
          <PointerEvent root={eventRoot} register={state.resize.dragRegister} />
        )}
      </>
    );
  });
}

function MultiSelectionVisual(props: { readonly state: MultiSelectionState }) {
  const { state } = props;
  return (
    <>
      <Render
        root={state.root.renderInSvg}
        props={{
          path: EMPTY_ARRAY,
          layer: "fallback",
          selected: true,
          children: <OutlineFrame state={state} />,
        }}
      />
      <Render
        root={state.root.renderInSvg}
        props={{
          path: EMPTY_ARRAY,
          layer: "global",
          selected: true,
          children: <ResizeVisual state={state.resize} />,
        }}
      />
    </>
  );
}

// 包住所有选择对象的框

const OutlineFrame = React.memo(
  (props: { readonly state: MultiSelectionState }) => {
    const { state } = props;
    return useObserver(() => {
      const b = state.bounding;
      if (!b) return null;

      const scale = 1 / state.root.props.session.viewport.scale || 0;

      const strokeWidth = scale;

      return (
        <rect
          x={b.left}
          y={b.top}
          width={b.right - b.left}
          height={b.bottom - b.top}
          fill="none"
          stroke="#a0a0a0"
          strokeWidth={strokeWidth}
          strokeDasharray={2 * strokeWidth}
          strokeLinecap="butt"
          vectorEffect="non-scaling-size"
        />
      );
    });
  }
);
OutlineFrame.displayName = "OutlineFrame";
