import * as React from 'react'
import { Popup } from './popup'
import { Locator, Bounding, LocatorProps } from './popup-locator'
import { Popover } from './popover'

export interface PopupPopoverProps extends Omit<LocatorProps, 'container' | 'content' | 'target'> {
  readonly target: Bounding
  readonly onClose?: () => void
  readonly children?: React.ReactNode
}

export function PopupPopover(props: PopupPopoverProps) {
  const { target, onClose, children, ...rest } = props
  return (
    <Popup
      onClose={onClose}
      render={() => (
        <Locator
          {...rest}
          target={target}
          children={locator => (
            <Popover locator={locator}>
              {children}
            </Popover>
          )}
        />
      )}
    />
  )
}
