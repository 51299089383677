import * as React from 'react'
import { useObserver } from 'mobx-react'

import { runInAction } from 'mobx'
import { useLocale, i18n } from '../../utils/i18n'
import type { DuplexFieldLike } from '../../utils/multi-value'
import { InputNumber } from '../ui-components/input-number'
import { getInputProps } from '../../utils/react-multi-value'
import { EditorContext } from '../helpers/react-context'

export const FontSizeValue = React.memo((props: {
  readonly field: DuplexFieldLike<number>
}) => {
  const { field } = props

  const editor = React.useContext(EditorContext)
  const locale = useLocale()

  return useObserver(() => {
    const multiValueProps = getInputProps(field, locale)
    const disabled = multiValueProps.disabled
    return (
      <>
        <InputNumber
          {...multiValueProps}
          disabled={disabled}
          min={0}
          step={1}
          suffix={字号.get(locale)}
          dragDirection="vertical"
          dragReverse
          onChange={newValue => runInAction(() => field.set(newValue))}
          onComplete={() => runInAction(() => {
            editor.session.history.push({ name: '修改字号' })
          })}
        />
      </>
    )
  })
})
FontSizeValue.displayName = 'FontSizeValue'

const 字号 = i18n({
  en: () => 'Size',
  zh: () => '字号',
})
