import * as React from 'react'
import { useObserver } from 'mobx-react'

import { PointerEvent } from './event'
import { ResizeState } from './resize.state'
import { typedMemo } from '../../utils/react-hooks'

export const ResizeEvent = typedMemo(
  function ResizeEvent$<T>(props: {
    readonly state: ResizeState<T>
  }) {
    const { state } = props
    return useObserver(() => {
      const { op } = state
      const root = op.getRoot()
      const { eventRoot } = root
      return (
        <>
          {state.hoverRegisters.map((register, i) =>
            <PointerEvent
              key={i}
              root={eventRoot}
              register={register}
            />
          )}
          {!state.resizeData ? null : (
            <PointerEvent
              root={root.eventRoot}
              register={state.dragRegister}
            />
          )}
        </>
      )
    })
  }
)
ResizeEvent.displayName = 'ResizeEvent'

export const ResizeVisual = typedMemo(
  function ResizeVisual$<T>(props: {
    readonly state: ResizeState<T>
  }) {
    const { state } = props
    return (
      <>
        <ResizeCorners<T> state={state} />
      </>
    )
  }
)
ResizeVisual.displayName = 'ResizeVisual'

// 选框四个角

const ResizeCorners = typedMemo(
  function ResizeCorners$<T>(props: {
    readonly state: ResizeState<T>
  }) {
    const { state } = props
    return useObserver(() => {
      const { op } = state

      const { horizontal: allowH, vertical: allowV, corner: allowCorner } = op.getAllowedSides()

      if (!allowH && !allowV) return null

      const scale = op.getRoot().uiScale

      const { left: x1, right: x2, top: y1, bottom: y2 } = op.getBounding()
      const cx = (x1 + x2) / 2
      const cy = (y1 + y2) / 2

      const color = op.getColor()

      const size = 6 * scale
      const border = 1 * scale
      const s = size - border
      const semi = s / 2

      const getDot = ([x, y]: readonly number[], i: number) => (
        <rect
          key={i}
          x={-semi + x}
          y={-semi + y}
          width={s}
          height={s}
          fill="#fff"
          stroke={color}
          strokeWidth={border}
          vectorEffect="non-scaling-size"
        />
      )

      const childrenH = !allowH ? null : [[x1, cy], [x2, cy]].map(getDot)

      const childrenV = !allowV ? null : [[cx, y1], [cx, y2]].map(getDot)

      const children = !(allowH && allowV && allowCorner) ? null : [[x1, y1], [x2, y1], [x2, y2], [x1, y2]].map(getDot)

      return <>{childrenH}{childrenV}{children}</>
    })
  }
)
ResizeCorners.displayName = 'ResizeCorners'
