import {
  AnyModelType,
  Frame,
  LeafModelType,
} from "@chuyuan/poster-data-access-layer";
import type { ItemOf } from "@chuyuan/poster-utils";

/**
 * 遍历所有叶子元素 (即不能充当容器的元素)
 * @param target 容器
 */
export function* iterateLeafModelType(target: Frame): Generator<LeafModelType> {
  for (const child of target.children) {
    if (child.kind === "frame") {
      yield* iterateLeafModelType(child);
    } else {
      yield child;
    }
  }
}

/**
 * 按照树结构遍历所有元素
 * @param target 任意 GDL 对象
 */
export function* iterateAnyModelTypePost(
  target: AnyModelType
): Generator<AnyModelType> {
  if (target.kind === "frame") {
    for (const child of target.children) {
      yield* iterateAnyModelTypePost(child);
    }
  }
  yield target;
}

/**
 * 去除集合中所有子元素
 * - 如果含有父元素, 去除所有子元素
 * @param set 集合
 */
export function removeOffsprings<T extends AnyModelType>(set: Set<T>) {
  for (const node of set) {
    if (node.kind !== "frame") continue;
    const { allOffsprings } = node.tree;
    for (const child of set) {
      if (allOffsprings.has(child as ItemOf<typeof allOffsprings>)) {
        set.delete(child);
      }
    }
  }
  return set;
}
