import * as React from "react";
import { useObserver } from "mobx-react";
import { Text } from "@chuyuan/poster-data-access-layer";
import { useEqualSet } from "../../utils/react-hooks";
import { Fields } from "./state.ui";
import { Block, Row } from "../ui-components/section";
import { Select as AntdSelect, Divider } from "antd";
import { i18n } from "../../utils/i18n";
import { FillingPaintValue } from "../ui-components-stateful/filling-paint-text-spec";
import { InputNumber } from "../ui-components/input-number";

const ShapeTypeOptions = [
  {
    value: "rectangle",
    label: "矩形",
  },
  {
    value: "oval",
    label: "椭圆",
  },
  {
    value: "regular polygon",
    label: "正多边形",
  },
  {
    value: "diagonal",
    label: "对角线",
  },
  {
    value: "label",
    label: "标签",
  },
];

const StrokeLineCapOptions = [
  {
    value: "square",
    label: "直角",
  },
  {
    value: "round",
    label: "圆角",
  },
];

/**@name 文本区域背景 */
export const TextBackgroundRegionPanel = React.memo(
  (props: { readonly targets: ReadonlySet<Text> }) => {
    const fields = Fields.of(useEqualSet(props.targets));
    const { paint, options } = fields.backgrounds.region.colors.stroke;
    const fillPaint = fields.backgrounds.region.colors.fill.paint;
    const fillOptions = fields.backgrounds.region.colors.fill.options;

    const onChangeShapeType = (newValue: any) => {
      fields.backgrounds.region.type.set(newValue);
    };

    const onChangeBorderLineCap = (newValue: any) => {
      fields.backgrounds.region.borderLineCap.set(newValue);
    };

    return useObserver(() => {
      return (
        <Block>
          <文字背景形状 />
          <Divider />
          <Row className="margin-y edged">
            <形状类型 />
            <AntdSelect
              options={ShapeTypeOptions}
              value={fields.backgrounds.region.type.get()}
              onChange={onChangeShapeType}
            />
          </Row>
          <Row className="margin-y edged">
            <描边颜色 />
            <FillingPaintValue field={paint} options={options} />
          </Row>
          <Row className="margin-y">
            <描边宽度 />
            <InputNumber
              value={fields.backgrounds.region.strokeWidth.get()}
              placeholder={"请输入描边宽度"}
              min={0}
              step={1}
              suffix={"px"}
              dragDirection="vertical"
              dragReverse
              onChange={(newValue) => {
                fields.backgrounds.region.strokeWidth.set(newValue);
              }}
            />
          </Row>
          <Row className="margin-y edged">
            <描边端点类型 />
            <AntdSelect
              options={StrokeLineCapOptions}
              value={fields.backgrounds.region.borderLineCap.get()}
              onChange={onChangeBorderLineCap}
            />
          </Row>
          <Divider />
          <Row className="margin-y">
            <文字背景颜色 />
            <FillingPaintValue field={fillPaint} options={fillOptions} />
          </Row>
          <Divider />
          <Row className="margin-y">
            <InputNumber
              min={0}
              step={0.1}
              value={(fields.backgrounds.region.scaleX.get() || 0) * 10}
              prefix={"缩放"}
              dragDirection="vertical"
              dragReverse
              onChange={(newValue) => {
                console.log("newValue", newValue);
                fields.backgrounds.region.scaleX.set(newValue / 10);
                fields.backgrounds.region.scaleY.set(newValue / 10);
              }}
            />
          </Row>
          <Divider />
        </Block>
      );
    });
  }
);
TextBackgroundRegionPanel.displayName = "TextBackgroundRegionPanel";

const 文字背景形状 = i18n({
  en: () => "Text background shape",
  zh: () => "文字背景形状",
});

const 形状类型 = i18n({
  en: () => "Shape type",
  zh: () => "形状类型",
});

const 描边颜色 = i18n({
  en: () => "Stroke color",
  zh: () => "描边颜色",
});

const 描边宽度 = i18n({
  en: () => "Stroke width",
  zh: () => "描边宽度",
});

const 文字背景颜色 = i18n({
  en: () => "Text Background color",
  zh: () => "文字背景颜色",
});

const 描边端点类型 = i18n({
  en: () => "Stroke endpoint type",
  zh: () => "描边端点类型",
});
