import {
  Bounding,
  ReadonlyAffine2D,
  Vector2DArray,
} from "@chuyuan/poster-math";

export class BBox {
  constructor(
    readonly bounding: Bounding,
    readonly inverseTransform?: ReadonlyAffine2D
  ) {}

  contains(point: Vector2DArray) {
    const { inverseTransform } = this;
    const p = inverseTransform ? inverseTransform.timesVector(point) : point;
    const b = this.bounding;
    const x = p[0];
    const y = p[1];
    return x >= b.left && x <= b.right && y >= b.top && y <= b.bottom;
  }

  toJSON() {
    return {
      bounding: this.bounding,
      inverseTransform: this.inverseTransform,
    };
  }
}
