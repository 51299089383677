import { computed, makeObservable } from "mobx";
import { times } from "lodash";

import type {
  EntityLayoutContainerType,
  EntityLayoutFlexDirection,
  EntityLayoutFlexSort,
  EntityLayoutAlignmentType,
} from "@chuyuan/poster-data-structure";
import {
  GDLLayoutSemantic,
  DIRECTION_DIMENSION,
  parseCssPaddingLikeForSides,
  Frame,
  convertFourSidesToCssPaddingLike,
} from "@chuyuan/poster-data-access-layer";
import { ClassStaticCache, EMPTY_OBJECT, isEqual } from "@chuyuan/poster-utils";

import { AbstractDuplexFromReader, AbstractMapReader, DuplexFromReader, ReaderLike } from "../../utils/multi-value";
import { getElementChildLayoutDataCpt, AdjustmentValueType } from "./state.layout.child";
import { SelectableTarget } from "../editor-state/types";
import { Bounding } from "../helpers/misc";

export const DisplayOuterDuplex = AbstractDuplexFromReader.defineIdentical(
  (target: Frame) => !target.appearance.cropOutter,
  (target, value) => target.appearance.setCropOutter(!value)
);

class AnyContainerData {
  static of = ClassStaticCache;

  constructor(readonly target: Frame) {
    makeObservable(this);
  }

  @computed
  get sort() {
    return new GDLLayoutSemantic.Operator(this.target).getFlexListContainer().sort;
  }

  @computed
  get childrenAlignment(): Alignment2D {
    const container = new GDLLayoutSemantic.Operator(this.target).getContainer();
    if (container.kind === "absolute") return EMPTY_OBJECT;
    const { childrenAlignment, direction } = container;
    const { gapType } = this;
    if (childrenAlignment.kind === "dynamic") {
      const dimKeys = DIRECTION_DIMENSION[container.direction];
      if (gapType === "auto") {
        if (direction === "row") {
          return { vertical: childrenAlignment[dimKeys.height] };
        } else {
          return { horizontal: childrenAlignment[dimKeys.width] };
        }
      } else {
        return {
          horizontal: childrenAlignment[dimKeys.width],
          vertical: childrenAlignment[dimKeys.height],
        };
      }
    } else {
      const { horizontal, vertical } = childrenAlignment;
      if (gapType === "auto") {
        if (direction === "row") {
          return { vertical };
        } else {
          return { horizontal };
        }
      } else {
        return { horizontal, vertical };
      }
    }
  }

  @computed
  get gapType() {
    return new GDLLayoutSemantic.Operator(this.target).getFlexLayoutGapsType();
  }

  @computed
  get gapsRawList(): readonly number[] {
    const { target } = this;
    const gapsLength = target.childrenInLayout.length - 1;
    const op = new GDLLayoutSemantic.Operator(target);
    const rawGaps = op.getFlexLayoutGaps();
    const rawGapsLength = rawGaps.length;
    const list = rawGapsLength > gapsLength ? rawGaps.slice(0, gapsLength) : rawGaps;
    if (list.length < 1) return [0];
    return list;
  }

  @computed
  get gapsFullList(): readonly number[] {
    const { target } = this;
    const gapsLength = target.childrenInLayout.length - 1;
    const op = new GDLLayoutSemantic.Operator(target);
    const rawGaps = op.getFlexLayoutGaps();
    const rawGapsLength = rawGaps.length;
    const lastGapsIndex = rawGapsLength - 1;
    return rawGapsLength === gapsLength
      ? rawGaps
      : rawGapsLength > gapsLength
      ? rawGaps.slice(0, gapsLength)
      : times(gapsLength, (i) => rawGaps[i] ?? rawGaps[lastGapsIndex] ?? 0);
  }

  @computed
  get padding(): readonly number[] {
    const { padding } = this.target.layout.flexbox;
    const { left, right, top, bottom } = padding;
    if (padding.unit === "percent") {
      return [top.computedValue, right.computedValue, bottom.computedValue, left.computedValue];
    }
    const values = padding.data?.value || 0;
    return convertFourSidesToCssPaddingLike(parseCssPaddingLikeForSides(values));
  }

  // @computed
  // get flexPadding(): readonly number[] {
  //   // const { padding } = this.target.layout.;
  //   // const { left, right, top, bottom } = padding;
  //   // if (padding.unit === "percent") {
  //   //   return [top.computedValue, right.computedValue, bottom.computedValue, left.computedValue];
  //   // }
  //   // const values = padding.data?.value || 0;
  //   // return convertFourSidesToCssPaddingLike(parseCssPaddingLikeForSides(values));
  // }
}

export type ContainerTypeRecord = {
  target: SelectableTarget;
  index: number;
  width: boolean;
  height: boolean;
  locked: boolean;
};

export const ContainerTypeDuplex = AbstractDuplexFromReader.define(
  (target: Frame) => target.layout.containerType,
  function (target: Frame, value: EntityLayoutContainerType) {
    if (this.getFn(target) === value) return false;
    const op = new GDLLayoutSemantic.Operator(target);
    const { children } = target;
    const oldDataMap = new Map(children.map((child) => [child, getElementChildLayoutDataCpt(child).get()]));

    const ret = op.setContainerType(value);
    if (!ret) return ret;

    const records: ContainerTypeRecord[] = [];

    for (let i = 0, leni = children.length; i < leni; i++) {
      const child = children[i];
      const oldData = oldDataMap.get(child);
      if (!oldData) continue;
      const newData = getElementChildLayoutDataCpt(child).get();
      const ow = isFixedOrRatio(oldData.adjustment.width.value);
      const oh = isFixedOrRatio(oldData.adjustment.height.value);
      const nw = isFixedOrRatio(newData.adjustment.width.value);
      const nh = isFixedOrRatio(newData.adjustment.height.value);
      const owu = oldData.dimension.width.unit === "px";
      const ohu = oldData.dimension.height.unit === "px";
      const nwu = newData.dimension.width.unit === "px";
      const nhu = newData.dimension.height.unit === "px";
      const isWidthChanged = nw && (!ow || (!owu && nwu));
      const isHeightChanged = nh && (!oh || (!ohu && nhu));
      if (!isWidthChanged && !isHeightChanged) continue;
      const locked = newData.adjustment.locked;
      records.push({
        target: child,
        index: i,
        width: isWidthChanged,
        height: isHeightChanged,
        locked,
      });
    }

    return records;
  }
);

function isFixedOrRatio(x: AdjustmentValueType) {
  return x === "fixed" || x === "ratio";
}

export const FlexIsParentFlexContainerReader = AbstractMapReader.define((target: SelectableTarget) => {
  if (target.kind === "frame" && !target.parent()) return false;
  const { child } = getElementChildLayoutDataCpt(target).get();
  return child.kind === "flex";
});

export const FlexIsSelfAndParentFlexContainerReader = AbstractMapReader.define((target: Frame) => {
  if (!target.parent()) return false;
  const { child } = getElementChildLayoutDataCpt(target).get();
  return child.kind === "flex" && ContainerTypeDuplex.getFn(target) !== "absolute";
});

export const FlexIsDirectionInheritedDuplex = AbstractDuplexFromReader.define(
  (target: Frame) => target.layout.flexbox.isChildrenDirectionInherited,
  function (target: Frame, value: boolean) {
    if (this.getFn(target) === value) return false;
    const op = new GDLLayoutSemantic.Operator(target);
    if (value) {
      op.setFlexLayoutDirection("inherit");
    } else {
      op.setFlexLayoutDirection(target.layout.flexbox.childrenDirection);
    }
    return true;
  }
);

export const FlexDirectionDisabledReader = AbstractMapReader.define((target: Frame) => FlexIsDirectionInheritedDuplex.getFn(target));

export const FlexDirectionDuplex = AbstractDuplexFromReader.define(
  (target: Frame) => target.layout.flexbox.childrenDirection,
  function (target: Frame, value: EntityLayoutFlexDirection) {
    if (this.getFn(target) === value) return false;
    const op = new GDLLayoutSemantic.Operator(target);
    op.setFlexLayoutDirection(value);
    return true;
  }
);

export const FlexSortDuplex = AbstractDuplexFromReader.define(
  (target: Frame) => AnyContainerData.of(target).sort,
  function (target: Frame, value: EntityLayoutFlexSort) {
    if (this.getFn(target) === value) return false;
    const op = new GDLLayoutSemantic.Operator(target);
    const container = op.getContainer();
    if (container.kind === "absolute") return false;
    op.setFlexLayoutSort(value);
    // 因为 childrenAlignment 的默认值是会跟随 sort 动, 所以从产品出发这里需要固定现场值
    const alignment = container.childrenAlignment;
    if (alignment.kind === "dynamic") {
      op.setFlexChildrenAlignment(alignment);
    }
    return true;
  }
);

export type Alignment2D = {
  readonly horizontal?: EntityLayoutAlignmentType;
  readonly vertical?: EntityLayoutAlignmentType;
};

export const FlexChildAlignmentDuplex = AbstractDuplexFromReader.define(
  (target: Frame) => AnyContainerData.of(target).childrenAlignment,
  function (target: Frame, value: Alignment2D) {
    if (isEqual(this.getFn(target), value)) return false;
    const op = new GDLLayoutSemantic.Operator(target);
    return op.setFlexChildrenAlignment({ ...value, kind: "static" });
  }
);

export type GapType = "fixed" | "auto";

export const FlexGapTypeDuplex = AbstractDuplexFromReader.define(
  (target: Frame) => AnyContainerData.of(target).gapType,
  function (target: Frame, value: GapType) {
    if (this.getFn(target) === value) return false;
    const op = new GDLLayoutSemantic.Operator(target);
    op.setFlexLayoutGapsType(value);
    return true;
  }
);

export const FlexGapsRawListDuplex = AbstractDuplexFromReader.define(
  (target: Frame) => AnyContainerData.of(target).gapsRawList,
  function (target: Frame, value: readonly number[]) {
    const full = AnyContainerData.of(target).gapsFullList;
    if (value.length > full.length) {
      value = value.slice(0, full.length);
    }
    const oldValue = this.getFn(target);
    if (isEqual(oldValue, value)) return false;
    const op = new GDLLayoutSemantic.Operator(target);
    op.setFlexLayoutFixedGaps(value);
    return true;
  }
);

export const FlexGapsFullListDuplex = AbstractDuplexFromReader.define(
  (target: Frame) => AnyContainerData.of(target).gapsFullList,
  function (target: Frame, value: readonly number[]) {
    const oldValue = this.getFn(target);
    if (isEqual(oldValue, value)) return false;
    const op = new GDLLayoutSemantic.Operator(target);
    op.setFlexLayoutFixedGaps(value);
    return true;
  }
);

export function createFlexGapsIndexDuplex(parent: ReaderLike<Frame>) {
  return function createIndex(index: number) {
    return new DuplexFromReader(
      parent,
      (target: Frame) => AnyContainerData.of(target).gapsFullList[index] || 0,
      (target: Frame, value: number) => {
        const data = AnyContainerData.of(target);
        const full = data.gapsFullList;
        if (index > full.length) return false;
        const oldValue = full[index] || 0;
        if (Object.is(oldValue, value)) return false;
        const raw = data.gapsRawList;
        const newGaps = raw.length < index ? full.slice(0, index) : raw.slice();
        newGaps[index] = value;
        const op = new GDLLayoutSemantic.Operator(target);
        op.setFlexLayoutFixedGaps(newGaps);
        return true;
      }
    );
  };
}

export const FlexPaddingDuplex = AbstractDuplexFromReader.define(
  (target: Frame) => AnyContainerData.of(target).padding,
  function (target: Frame, value: readonly number[]) {
    const oldValue = this.getFn(target);
    if (isEqual(oldValue, value)) return false;
    const { padding } = target.layout.flexbox;
    padding.unit = "px";
    padding.setNativeValues(value);
    return true;
  }
);

export function createFlexPaddingSideDuplex(parent: ReaderLike<Frame>) {
  return function createIndex(key: keyof Bounding) {
    return new DuplexFromReader(
      parent,
      (target: Frame) => target.layout.flexbox.padding[key].computedValue,
      (target: Frame, value: number) => {
        const side = target.layout.flexbox.padding[key];
        const oldValue = side.computedValue;
        if (Object.is(oldValue, value)) return false;
        side.nativeValue = value;
        return true;
      }
    );
  };
}
