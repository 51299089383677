import * as React from "react";
import styled, { css } from "styled-components";
import * as tinycolor from "tinycolor2";

export type ButtonRef = HTMLButtonElement;

export interface ButtonProps
  extends Omit<
    Readonly<React.ButtonHTMLAttributes<HTMLButtonElement>>,
    "type"
  > {
  /**
   * flex 占用空间
   * @default auto
   */
  readonly space?: "auto" | "stretch";
  /**
   * 尺寸
   */
  readonly size?: "small" | "default";
  /**
   * 色彩
   * - default 大概是银色 (默认值)
   * - primary 一般是蓝色
   * - danger  通常是红色
   *
   * @default default
   */
  readonly color?: "primary" | "danger" | "default";
  /**
   * 样式类型
   * - primary 填充色 (默认值)
   * - line 实线线框
   * - dashed 虚线线框
   * - light 文字颜色加浅色填充
   * - text 文字颜色
   * @default primary
   */
  readonly type?: "primary" | "line" | "dashed" | "light" | "text";
}

export const Button = React.forwardRef<ButtonRef, ButtonProps>((props, ref) => {
  const { space, size, color, type, disabled, ...newProps } = props;
  if (disabled) {
    for (const key of Object.keys(newProps)) {
      if (key.startsWith("on")) {
        (newProps as Record<string, unknown>)[key] = undefined;
      }
    }
    newProps.className = appendClassName(newProps.className, "disabled");
    newProps["aria-disabled"] = true;
  }
  if (color && color !== "default") {
    newProps.className = appendClassName(newProps.className, `color-${color}`);
  }
  if (type && type !== "primary") {
    newProps.className = appendClassName(newProps.className, `type-${type}`);
  }
  if (size && size !== "default") {
    newProps.className = appendClassName(newProps.className, `size-${size}`);
  }
  if (space && space !== "auto") {
    newProps.className = appendClassName(newProps.className, `space-${space}`);
  }
  // console.log('ButtonDOM', { ...newProps, props });
  return <ButtonDOM {...newProps} ref={ref} />;
});

function appendClassName(x: string | undefined, y: string) {
  return x ? `${x} ${y}` : y;
}

export const SquareButton = styled(Button)`
  width: 32px;
  height: 32px;
  user-select: none;
  font-weight: 500;
`;

const DefaultColor: Color = {
  main: "#606060",
  darken1: tinycolor.mix("#606060", "#000", 20).toHexString(),
  darken2: tinycolor.mix("#606060", "#000", 40).toHexString(),
  lighten1: tinycolor.mix("#606060", "#fff", 95).toHexString(),
  lighten2: tinycolor.mix("#606060", "#fff", 90).toHexString(),
};

const DefaultColorBorder = {
  main: "#eae9e8",
  darken1: tinycolor.mix("#eae9e8", "#000", 20).toHexString(),
  darken2: tinycolor.mix("#eae9e8", "#000", 40).toHexString(),
};

const PrimaryColor: Color = {
  main: "#f15722",
  darken1: tinycolor.mix("#f15722", "#000", 20).toHexString(),
  darken2: tinycolor.mix("#f15722", "#000", 40).toHexString(),
  lighten1: tinycolor.mix("#f15722", "#fff", 95).toHexString(),
  lighten2: tinycolor.mix("#f15722", "#fff", 90).toHexString(),
};

const DangerColor: Color = {
  main: "#eb5757",
  darken1: tinycolor.mix("#eb5757", "#000", 20).toHexString(),
  darken2: tinycolor.mix("#eb5757", "#000", 40).toHexString(),
  lighten1: tinycolor.mix("#eb5757", "#fff", 90).toHexString(),
  lighten2: tinycolor.mix("#eb5757", "#fff", 80).toHexString(),
};

export const BUTTON_CSS = css`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 7px;
  color: #606060;
  background-color: #f8f7f6;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  outline: 0;
  transition: all 0.3s;
  padding: 4px 24px;
  position: relative;

  &:hover,
  &:active {
    color: #202020;
  }

  &:active {
    background-color: #eae9e8;
  }

  &:focus {
    border-color: #c0c0c0;
    outline: 0;
  }

  ${createTypeColor(DefaultColor, DefaultColorBorder)}

  &.color-primary {
    ${createPrimaryColor(PrimaryColor)}
    ${createTypeColor(PrimaryColor)}
  }

  &.color-danger {
    ${createPrimaryColor(DangerColor)}
    ${createTypeColor(DangerColor)}
  }

  &.type-line,
  &.type-dashed {
    background-color: transparent;
  }

  &.type-dashed {
    border-style: dashed;
  }

  &.size-small {
    padding: 3px;
    border-radius: 3px;
  }

  &.space-stretch {
    flex: 1 1 0;
    width: 0;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const ButtonDOM = styled.button`
  ${BUTTON_CSS}
`;

type Color = {
  readonly main: string;
  readonly border?: string;
  readonly darken1: string;
  readonly darken2: string;
  readonly lighten1: string;
  readonly lighten2: string;
};

function createPrimaryColor(
  props: Pick<Color, "main" | "darken1" | "darken2">
) {
  const { main, darken1, darken2 } = props;
  return css`\
color: #fff;
background-color: ${main};

&:hover, &:focus {
  background-color: ${darken1};
}

&:active {
  background-color: ${darken2};
}
`;
}

function createTypeColor(
  props: Color,
  border: Pick<Color, "main" | "darken1" | "darken2"> = props
) {
  const { main, darken1, darken2, lighten1, lighten2 } = props;
  return css`
    \ &.type-line,
    &.type-dashed,
    &.type-light,
    &.type-text {
      color: ${main};
    }

    &.type-line,
    &.type-dashed {
      background-color: transparent;
      border-color: ${border.main};

      &:not(.disabled) {
        &:hover {
          color: ${darken1};
          border-color: ${border.darken1};
        }

        &:active {
          color: ${darken2};
          border-color: ${border.darken2};
        }
      }
    }

    &.type-light {
      background-color: ${lighten1};
      border-color: transparent;

      &:not(.disabled) {
        &:hover,
        &:active {
          color: ${darken1};
        }

        &:active {
          background-color: ${lighten2};
        }

        &:focus {
          border-color: ${border.main};
        }
      }
    }

    &.type-text {
      background-color: transparent;
      border-color: transparent;
    }
  `;
}
