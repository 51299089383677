import * as React from 'react'
import { useObserver } from 'mobx-react'

import { EditorSlotModuleToken } from '../editor/editor-slot-module'
import { useModule } from '../../utils/modulize'
import { i18n } from '../../utils/i18n'
import { EditorContext } from '../helpers/react-context'
import { StylePanel } from './style-panel'

export const UseStylePanelWidget = React.memo(() => {
  const EditorSlotModule = useModule(EditorSlotModuleToken)

  if (!EditorSlotModule) return null

  return (
    <EditorSlotModule.properties.Portal title={<样式 />} priority={10}>
      <Entry />
    </EditorSlotModule.properties.Portal>
  )
}, () => true)

const Entry = React.memo(() => {
  const { session } = React.useContext(EditorContext)
  return useObserver(() => {
    const selection = session.selection.set

    const targets = selection.size ? selection : new Set([session.root])

    return (
      <>
        <StylePanel targets={targets} />
      </>
    )
  })
}, () => true)

const 样式 = i18n({
  en: () => 'Style',
  zh: () => '样式',
})
