import * as React from "react";
// import { runInAction } from "mobx";
import { observer } from "mobx-react";
import styled from "styled-components";

import { HeaderPanel } from "../ui-components/header-panel";
import { i18n, useLocale } from "../../utils/i18n";
import { EditorContext } from "../helpers/react-context";
import { SelectableTarget } from "../editor-state/types";
import { Panel } from "../ui-components/panel";
import { Row } from "../ui-components/section";
import { Checkbox } from "../ui-components/checkbox";
// import { Select } from "../ui-components/select";
import { getGDLTargetName } from "../gdl-common/target-names";
import { Select as AntdSelect, Divider } from "antd";
import { parse } from "querystring";
import "antd/dist/antd.css";
import { EditorAPI } from "../editor-state/api";
import _ from "lodash";

/**@name 规则面板 */
export const RulesPanel = observer(() => {
  const locale = useLocale();
  const editor = React.useContext(EditorContext);
  const { selection } = editor.session;
  const { api } = editor.session.editor;

  const pitList: any[] = [];
  let index = 0;

  const pushPit = (root: any) => {
    const children = root.getChildren();
    children.map((child: any) => {
      const targetName = getGDLTargetName(child, locale);
      const targetId = child.id;
      const targetRules = child.extras.generationRules;
      const targetData = {
        targetName,
        targetId,
        index,
        targetRules,
        target: child,
      };

      index++;

      if (child.kind == "text" || child.kind == "image") {
        pitList.push(targetData);
      }
      if (child.kind == "frame") {
        pitList.push(targetData);
        if (child.getChildren() && child.getChildren().length > 0) {
          pushPit(child);
        }
      }
    });
  };

  pushPit(editor.session.root);

  // selection.set;
  // console.log("api", {
  //   a: editor.session.root.extras.generationRules,
  //   pitList,
  // });

  return (
    <RulesPanelDOM>
      <HeaderPanel title={<添加规则 />}>
        {selection.isMoreThanOne ? null : <RulesPanelContent api={api} pitList={pitList} target={Array.from(selection.set)[0]} />}
      </HeaderPanel>
    </RulesPanelDOM>
  );
});

const getPageQuery = () => {
  const { href } = window.location;
  const qsIndex = href.indexOf("?");
  const sharpIndex = href.indexOf("#");

  if (qsIndex !== -1) {
    if (qsIndex > sharpIndex) {
      return parse(href.split("?")[1]);
    }

    return parse(href.slice(qsIndex + 1, sharpIndex));
  }

  return {};
};

const RulesPanelContent = observer((props: { readonly target: SelectableTarget; api: EditorAPI; pitList: any[] }) => {
  const locationQuery = getPageQuery();
  const token = locationQuery["access_token"];
  const { target, api, pitList } = props;
  // const [tagList, setTagList] = React.useState([]);
  const [pitTypeOptions, setPitTypeOptions] = React.useState([]);
  const [ruleList, setRuleList] = React.useState<any[]>([]);

  const [labelOptions, setLabelOptions] = React.useState([]);
  const [selectLabel, setSelectLabel] = React.useState({
    key: "1",
    title: "项目类型",
  });
  const [labelChildrenOptions, setLabelChildrenOptions] = React.useState<any>([]);
  // const [selectLabelChild, setSelectLabelChild] = React.useState<any>({});

  // console.log("selectLabelChild", selectLabelChild);

  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    let result: any = [];
    if (api.pitTypeList) {
      // 首次调用
      result = api.pitTypeList({});
      setPitTypeOptions(result);
    }

    if (api.labelList) {
      // 首次调用
      result = await api.labelList({ token });
      setLabelOptions(result);
    }

    if (api.labelChildrenList) {
      // 首次调用
      result = await api.labelChildrenList({ token });
      setLabelChildrenOptions(result);
    }
    // console.log("pitList", pitList);
    // setRuleList(ruleList.concat([pitList]));
  };

  const locale = useLocale();
  const titleName = !target ? "未选中图层，无名称" : getGDLTargetName(target, locale);

  const onChangePit = (value: string[], label: any, key: number) => {
    console.log(`selected ${value}`, label);
    // pitList[key]["type"] = label.label;
    // setSelectPit(label);
    pitList[key].target.extras.setGenerationRules({
      ...pitList[key].target.extras.generationRules,
      inputTypes: [
        {
          id: label.value,
          description: label.label,
        },
      ],
    });
  };

  const labelChange = async (value: string[], label: any) => {
    // console.log(`selected ${value}`, label);
    setSelectLabel(label);
    const result = await api.labelChildrenList({ token, groupId: value });
    setLabelChildrenOptions(result);
  };

  const labelChildChange = () => {
    // console.log(`selected ${value}`, label);
    // setSelectLabelChild(label);
  };

  // const onAddPit = () => {
  //   setPitList(pitList.concat({}));
  // };

  // console.log("pitList", pitList);

  const onSaveRule = () => {
    const ruleData: any[] = [];
    pitList.map((pit) => {
      const ruleJson = {
        id: pit["targetId"] || "",
        type: (pit["targetRules"]["inputTypes"] && pit["targetRules"]["inputTypes"][0]["id"]) || "",
        enableColor: pit["targetRules"]["isColoring"] || false,
        enableInput: pit["targetRules"]["isInput"] || false,
        ...pit,
      };
      ruleData.push(ruleJson);
    });
    // setRuleList(ruleList.concat([ruleData]));
    localStorage.setItem("ruleList", JSON.stringify(ruleList.concat([ruleData])));
  };

  const onAddRule = () => {
    const ruleData: any[] = [];
    pitList.map((pit) => {
      const ruleJson = {
        id: pit["targetId"] || "",
        type: (pit["targetRules"]["inputTypes"] && pit["targetRules"]["inputTypes"][0]["id"]) || "",
        enableColor: pit["targetRules"]["isColoring"] || false,
        enableInput: pit["targetRules"]["isInput"] || false,
        ...pit,
      };
      ruleData.push(ruleJson);
    });
    setRuleList(ruleList.concat([ruleData]));
    // localStorage.setItem(
    //   "ruleList",
    //   JSON.stringify(ruleList.concat([ruleData]))
    // );
  };

  // console.log("ruleList", ruleList);

  return (
    <>
      <Title>
        <当前选中的图层 />
      </Title>
      <SubTitle>{titleName}</SubTitle>
      <Divider />
      <Title>
        <图层规则 />
      </Title>
      <Panel title={`新规则`}>
        {pitList.map((pit: any, key: number) => {
          return (
            <Panel type="inline" title={"坑位[" + (key + 1) + "] " + pit.targetName}>
              <SubTitle>
                <坑位规则 />
              </SubTitle>
              <Row className="margin-y pointer edged" as="label">
                <div>
                  <是否设置为输入坑 />
                </div>
                <div>
                  <Checkbox
                    checked={pit.targetRules.isInput}
                    onChange={(newValue) => {
                      pit.target.extras.setGenerationRules({
                        ...pit.target.extras.generationRules,
                        isInput: newValue,
                      });
                    }}
                  />
                </div>
              </Row>
              <Row className="margin-y">
                <坑位类型 />
              </Row>
              <Row>
                <AntdSelect
                  // mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="请选择坑位类型"
                  defaultValue={[]}
                  onChange={(value, label) => {
                    onChangePit(value, label, key);
                  }}
                  options={pitTypeOptions}
                />
              </Row>
              {/* </Panel> */}
              {/* <Divider /> */}
              {/* <Panel type="inline" title={<颜色规则 />}> */}
              <SubTitle>
                <颜色规则 />
              </SubTitle>
              <Row className="margin-y pointer edged" as="label">
                <div>
                  <是否参与配色 />
                </div>
                <div>
                  <Checkbox
                    checked={pit.targetRules.isColoring}
                    onChange={(newValue) => {
                      pit.target.extras.setGenerationRules({
                        ...pit.target.extras.generationRules,
                        isColoring: newValue,
                      });
                    }}
                  />
                </div>
              </Row>
            </Panel>
          );
        })}
      </Panel>
      <AddButton onClick={onSaveRule}>
        <保存></保存>
      </AddButton>
      <AddButton onClick={onAddRule}>
        <添加一个规则></添加一个规则>
      </AddButton>
      <SubTitle>{"规则列表"}</SubTitle>
      {ruleList.map((rule, index) => {
        return (
          <Panel title={`规则${index + 1}`}>
            {rule.map((pit: any, key: number) => {
              return (
                <Panel type="inline" title={"坑位[" + (key + 1) + "] " + pit.targetName}>
                  <SubTitle>
                    <坑位规则 />
                  </SubTitle>
                  <Row className="margin-y pointer edged" as="label">
                    <div>
                      <是否设置为输入坑 />
                    </div>
                    <div>
                      <Checkbox
                        checked={pit.targetRules.isInput}
                        // onChange={(newValue) => {
                        //   // pit.target.extras.setGenerationRules({
                        //   //   ...pit.target.extras.generationRules,
                        //   //   isInput: newValue,
                        //   // });
                        // }}
                      />
                    </div>
                  </Row>
                  <Row className="margin-y">
                    <坑位类型 />
                  </Row>
                  <Row>
                    <AntdSelect
                      // mode="multiple"
                      disabled
                      style={{ width: "100%" }}
                      placeholder="请选择坑位类型"
                      defaultValue={[pit.type]}
                      // onChange={(value, label) => {
                      //   // onChangePit(value, label, key);
                      // }}
                      options={pitTypeOptions}
                    />
                  </Row>
                  {/* </Panel> */}
                  {/* <Divider /> */}
                  {/* <Panel type="inline" title={<颜色规则 />}> */}
                  <SubTitle>
                    <颜色规则 />
                  </SubTitle>
                  <Row className="margin-y pointer edged" as="label">
                    <div>
                      <是否参与配色 />
                    </div>
                    <div>
                      <Checkbox
                        checked={pit.targetRules.isColoring}
                        // onChange={(newValue) => {
                        //   // pit.target.extras.setGenerationRules({
                        //   //   ...pit.target.extras.generationRules,
                        //   //   isColoring: newValue,
                        //   // });
                        // }}
                      />
                    </div>
                  </Row>
                </Panel>
              );
            })}
          </Panel>
        );
      })}
      <Divider />
      <Title>
        <组件的规则 />
      </Title>
      <Panel type="inline" title={<标签 />}>
        {/* <LabelList>
            {tagList.map((tag: any) => {
              return <Tag>{tag.label}</Tag>;
            })}
          </LabelList> */}
        <Row className="margin-y pointer edged" as="label">
          <AntdSelect
            allowClear
            style={{ width: "100%" }}
            placeholder="请选择"
            defaultValue={[selectLabel.title]}
            onChange={labelChange}
            options={labelOptions}
            fieldNames={{
              label: "title",
              value: "key",
            }}
          />
        </Row>
        <Row className="margin-y pointer edged" as="label">
          <AntdSelect
            mode="tags"
            allowClear
            style={{ width: "100%" }}
            placeholder="请选择"
            onChange={labelChildChange}
            fieldNames={{
              label: "labelName",
              value: "labelId",
            }}
            options={labelChildrenOptions}
          />
        </Row>
      </Panel>
    </>
  );
});

const Title = styled.div`
  position: relative;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #606060;
`;

const SubTitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #999;
  margin-top: 8px;
`;

// const LabelList = styled.div`
//   padding: 4px 10px;
//   border: 1px solid #d1d1d1;
//   margin-bottom: 10px;
// `;

const AddButton = styled.div`
  cursor: pointer;
  border: 1px solid #40a9ff;
  padding: 8px 4px;
  margin: 10px 0;
  border-radius: 10px;
`;

const RulesPanelDOM = styled.div`
  background: #fff;
  pointer-events: auto;
`;

const 添加规则 = i18n({
  en: () => `Set Rules(Setting the input)`,
  zh: () => `添加规则（设置输入）`,
});

const 保存 = i18n({
  en: () => `Save`,
  zh: () => `保存`,
});

const 添加一个规则 = i18n({
  en: () => `+ Adding a rule`,
  zh: () => `添加一个规则`,
});

// const 添加一个坑位 = i18n({
//   en: () => `+ Add a pit`,
//   zh: () => `+ 添加一个坑位`,
// });

const 当前选中的图层 = i18n({
  en: () => `The currently selected layer`,
  zh: () => `当前选中的图层`,
});

const 图层规则 = i18n({
  en: () => `Layer rules`,
  zh: () => `图层规则`,
});

const 组件的规则 = i18n({
  en: () => `Component rules`,
  zh: () => `组件的规则`,
});

const 坑位规则 = i18n({
  en: () => `Rules of input slot`,
  zh: () => `坑位规则`,
});

// const 选择坑位 = i18n({
//   en: () => `Pit selection`,
//   zh: () => `选择坑位`,
// });

const 颜色规则 = i18n({
  en: () => `Rules of color`,
  zh: () => `颜色规则`,
});

const 标签 = i18n({
  en: () => `Labels`,
  zh: () => `标签`,
});

const 是否设置为输入坑 = i18n({
  en: () => `Set as input slot`,
  zh: () => `是否设置为输入坑`,
});

const 是否参与配色 = i18n({
  en: () => `Whether to participate in color matching`,
  zh: () => `是否参与配色`,
});

const 坑位类型 = i18n({
  en: () => `Input slot type`,
  zh: () => `坑位类型`,
});
