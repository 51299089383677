import * as React from "react";
import { runInAction } from "mobx";
import { useObserver } from "mobx-react";

import {
  DuplexFieldLike,
  ReadFieldLike,
  ScaleValueField,
} from "../../utils/multi-value";
import type { OffsetUnitType, XY } from "./state.layout.child";
import { InputNumberHistory } from "../ui-components-stateful/number";
// import { SelectHistory } from '../ui-components-stateful/enum'
import { useLocale } from "../../utils/i18n";
import { getDependentInputPropsList } from "../../utils/react-multi-value";
import { Row } from "../ui-components/section";
// import { SelectItemProps } from '../ui-components/select'

export type LayoutOffsetValueProps = {
  readonly direction: XY;
  readonly value: DuplexFieldLike<number>;
  readonly unit: DuplexFieldLike<OffsetUnitType>;
  readonly possibleUnits: ReadFieldLike<ReadonlySet<OffsetUnitType>>;
};

export const LayoutOffsetValue = React.memo((props: LayoutOffsetValueProps) => {
  const { direction, value: inputValue, unit, possibleUnits } = props;

  const locale = useLocale();

  return useObserver(() => {
    const unitData = unit.data;

    const value = React.useMemo(() => {
      if (unitData.variety === "various" || unitData.value === "px")
        return inputValue;
      return new ScaleValueField(inputValue, 100);
    }, [inputValue, unitData]);

    const [unitProps, valueProps] = getDependentInputPropsList(
      [unit, value] as const,
      locale
    );

    const historyTag = direction === "x" ? "横向位移" : "纵向位移";

    console.log(unitProps, possibleUnits);

    return (
      <>
        <Row className="margin-x">
          <InputNumberHistory
            {...valueProps}
            prefix={direction === "x" ? "X" : "Y"}
            dragDirection={direction === "x" ? "horizontal" : "vertical"}
            dragReverse={direction === "y"}
            historyName={historyTag}
            onChange={(newValue) => runInAction(() => value.set(newValue))}
          />
        </Row>
        {/* <Row className="margin-x">
          <SelectHistory
            {...unitProps}
            filter={possibleUnits}
            historyName={`${historyTag}单位`}
            options={UnitOptions}
            onChange={newValue => runInAction(() => unit.set(newValue).records.some(Boolean))}
          />
        </Row> */}
      </>
    );
  });
});
LayoutOffsetValue.displayName = "LayoutOffsetValue";

// const UnitOptions: ReadonlyArray<SelectItemProps<OffsetUnitType>> = [{
//   value: 'px',
//   label: () => 'Px',
// }, {
//   value: 'percent',
//   label: () => '%',
// }]
