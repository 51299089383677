import * as React from "react";
import { useObserver } from "mobx-react";
import { EMPTY_ARRAY, ObjectCount } from "@chuyuan/poster-utils";
import "./editor.css";
import { getEnvLocales, LocaleContext } from "../../utils/i18n";
import { CrashSolver } from "../crash-solver";
import { EditorContext } from "../helpers/react-context";
import { EditorState } from "../editor-state/editor-state";
import DebugLocalStorage from "../debug/local-storage";
import { $skip } from "../../utils/value-chain";
import { Editor } from "./editor";
import { EditorAPI } from "../editor-state/api";
import { RenderResult } from "./canvas";

export interface GraphicEditorProps {
  /**
   * 编辑器 api
   */
  readonly api: EditorAPI;
  /**
   * 是否仅调用渲染
   */
  readonly renderOnly?: boolean;
  /**
   * 编辑器初始化时, 会回调这个函数, 传入初始化的编辑器对象
   */
  readonly init?: (editor: EditorState) => unknown;
  /**
   * 子元素, 通常是自定义模块注入
   */
  readonly children?: React.ReactNode;
}

export const GraphicEditor = React.memo<GraphicEditorProps>(
  (props) => {
    const { renderOnly, init, api, children } = props;

    const editor = React.useMemo(() => createEditorState(api), EMPTY_ARRAY);

    const count = React.useMemo(() => new ObjectCount(), EMPTY_ARRAY);

    React.useEffect(() => {
      if (typeof init === "function") {
        init(editor);
      }
    }, EMPTY_ARRAY);

    return useObserver(() => {
      return (
        <LocaleContext.Provider value={editor.options._allLocales.value}>
          <EditorContext.Provider value={editor}>
            <CrashSolver>
              {renderOnly ? (
                <RenderResult key={count.get(editor.session)} />
              ) : (
                <Editor key={count.get(editor.session)} children={children} />
              )}
            </CrashSolver>
          </EditorContext.Provider>
        </LocaleContext.Provider>
      );
    });
  },
  () => true
);

GraphicEditor.displayName = "GraphicEditor";

function createEditorState(api: EditorAPI) {
  const editor = new EditorState(api);

  // 处理 locale 的一些问题

  const { options } = editor;

  options._allLocales.createGetter(() => {
    const envLocales = getEnvLocales();
    if (envLocales.length) {
      return envLocales;
    }
    return $skip;
  });

  options._allLocales.createChain(options.locale);

  options._allLocales.createGetter(() => {
    const debugLocale = DebugLocalStorage.locale;
    if (debugLocale) return debugLocale;
    return $skip;
  });

  // 处理 imageRealUrl
  if (api.getImageRealUrl) {
    const getImageRealUrl = api.getImageRealUrl.bind(api);
    const { imageRealUrl } = editor.resources;
    imageRealUrl.events.on("request", (url) => {
      Promise.resolve(getImageRealUrl(url)).then(
        (value) => imageRealUrl.resolve(url, value),
        (error) => imageRealUrl.reject(url, error)
      );
    });
  }

  return editor;
}
