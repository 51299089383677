export class TimerLoop<T> {
  private started?: boolean
  private timer?: T

  constructor(
    readonly request: (block: () => void) => T,
    readonly cancel: (timer: T) => unknown,
    readonly cb: () => void,
  ) {}

  start() {
    if (this.started) return
    this.started = true
    const { request } = this
    this.timer = request(() => this.trigger())
  }

  stop() {
    if (!this.started) return
    this.started = false
    if (this.timer !== undefined) {
      const { cancel } = this
      cancel(this.timer)
      this.timer = undefined
    }
  }

  private trigger() {
    if (this.started) {
      const { request } = this
      this.timer = request(() => this.trigger())
      this.cb()
    } else {
      this.timer = undefined
    }
  }
}
