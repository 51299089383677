import type { FillingPaint as CoreFillingPaint } from "@chuyuan/poster-data-structure";
import { EMPTY_ARRAY } from "@chuyuan/poster-utils";

import type { FillingPaint } from "../ui-components-stateful/filling-paint";
import { formatColorCached } from "../ui-components-stateful/filling-paint-common";
import { createTinyColor } from "../../utils/tinycolor-helpers";
import {
  getCssLinearGradientStringFromGradientStops,
  formatGradientStops,
  getCssRadialGradientStringFromGradientStops,
} from "../ui-components/gradient-picker";
import { pickDimension } from "../helpers/data-picker";

export function toFillingPaint(
  fill: string | CoreFillingPaint | null | undefined,
  colorWhenTransparent: string
): FillingPaint {
  if (typeof fill === "string") {
    return {
      ...formatColorCached(fill),
      kind: "color",
    };
  }
  if (!fill) {
    return {
      kind: "color",
      color: colorWhenTransparent,
      opacity: 0,
    };
  }
  if (fill.kind === "color") {
    const { color } = fill;
    return {
      kind: "color",
      color: color || colorWhenTransparent,
      opacity: color ? fill.opacity ?? 1 : 0,
    };
  }
  if (fill.kind === "gradient") {
    const { method, stops } = fill;
    if (!method || method.type === "linear") {
      return {
        kind: "linear gradient",
        angle: method?.angle || 0,
        stops: stops || EMPTY_ARRAY,
      };
    } else {
      return {
        kind: "radial gradient",
        stops: stops || EMPTY_ARRAY,
      };
    }
  }
  if (fill.kind === "image") {
    const { url, dimension } = fill;
    return {
      kind: "image",
      image: url && dimension ? { ...dimension, url } : undefined,
      fit: fill.fit || "raw",
      scale: fill.scale ?? 1,
      opacity: fill.opacity ?? 1,
    };
  }
  return {
    kind: "color",
    color: colorWhenTransparent,
    opacity: 0,
  };
}

export function fromFillingPaint(value: FillingPaint): CoreFillingPaint {
  if (value.kind === "color") {
    return {
      kind: "color",
      color: value.color,
      opacity: value.opacity,
    };
  }
  if (value.kind === "linear gradient") {
    return {
      kind: "gradient",
      method: { type: "linear", angle: value.angle },
      stops: value.stops,
    };
  }
  if (value.kind === "radial gradient") {
    return {
      kind: "gradient",
      method: { type: "radial" },
      stops: value.stops,
    };
  }
  if (value.kind === "image") {
    const { image, ...rest } = value;
    return {
      ...rest,
      kind: "image",
      url: image && image.url,
      dimension: image && pickDimension(image),
    };
  }
  throw new Error(`Unknown value ${JSON.stringify(value)}`);
}

export function getFillingPaintCssBackground(fill: string | CoreFillingPaint) {
  if (typeof fill === "string") {
    return { backgroundColor: fill };
  }
  if (fill.kind === "color") {
    return {
      backgroundColor: createTinyColor(fill.color, fill.opacity).toString(),
    };
  }
  if (fill.kind === "gradient") {
    const stops = fill.stops || EMPTY_ARRAY;
    if (!stops.length) return;

    const { method } = fill;
    if (!method || method.type === "linear") {
      const angle = method?.angle || 0;
      const value = getCssLinearGradientStringFromGradientStops(
        formatGradientStops(stops),
        angle
      );
      return { backgroundImage: value };
    } else {
      const value = getCssRadialGradientStringFromGradientStops(
        formatGradientStops(stops),
        "circle at 50%"
      );
      return { backgroundImage: value };
    }
  }
  if (fill.kind === "image") {
    const { url } = fill;
    if (!url) return;
    const value = `url(${JSON.stringify(url)})`;
    return { backgroundImage: value };
  }
  return undefined;
}
