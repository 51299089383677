import * as React from "react";
import { EMPTY_ARRAY, memorize } from "@chuyuan/poster-utils";

// 对默认行为提供全局统一的 observer
// @see https://www.bennadel.com/blog/3954-intersectionobserver-api-performance-many-vs-shared-in-angular-11-0-5.htm

export interface ObserverProviderItemData {
  observed: boolean;
  visible?: boolean;
  readonly cbs: Array<(visible: boolean) => void>;
}

export class ObserverProvider {
  @memorize
  static get default() {
    return new ObserverProvider();
  }

  readonly observer: IntersectionObserver;

  private map = new WeakMap<Element, ObserverProviderItemData>();

  constructor(options?: IntersectionObserverInit) {
    this.observer = new IntersectionObserver((entries) => {
      const { map } = this;
      for (const entry of entries) {
        const item = map.get(entry.target);
        if (!item) continue;
        const visible = entry.isIntersecting;
        if (visible !== item.visible) {
          item.visible = visible;
          const { cbs } = item;
          const len = cbs.length;
          if (len === 1) {
            cbs[0](visible);
          } else {
            for (let i = 0; i < len; i++) {
              cbs[i](visible);
            }
          }
        }
      }
    }, options);
  }

  observe(e: Element, cb: (visible: boolean) => void) {
    const { map } = this;
    let item = map.get(e);
    if (!item) map.set(e, (item = { observed: false, cbs: [] }));
    item.cbs.push(cb);
    if (item.observed) {
      const { visible } = item;
      // 已经监听但还没拿到回调, 不用立刻执行
      if (visible === undefined) return;
      cb(visible);
    } else {
      item.observed = true;
      this.observer.observe(e);
    }
  }

  unobserve(e: Element, cb: (visible: boolean) => void) {
    const { map } = this;
    const item = map.get(e);
    if (!item || !item.observed) return;
    const { cbs } = item;
    const pos = cbs.indexOf(cb);
    if (pos < 0) return;
    cbs.splice(pos, 1);
    if (!cbs.length) {
      item.observed = false;
      item.visible = undefined;
      this.observer.unobserve(e);
    }
  }

  disconnect() {
    this.map = new WeakMap();
    this.observer.disconnect();
  }
}

export class DummyOperator<T extends Element> {
  readonly provider?: ObserverProvider;

  ref: T | null = null;

  constructor(public cb: (visible: boolean) => void) {}

  visible = true;

  replace(ref: T | null) {
    if (this.ref === ref) return;
    if (this.ref) this.unmount(this.ref);
    this.ref = ref;
    if (ref) this.mount(ref);
  }

  mount(_ref: T) {
    this.cb(true);
  }

  unmount(_ref: T) {}

  dispose() {}

  getRef = (ref: T | null) => this.replace(ref);

  effect = () => () => this.dispose();
}

export class VisibleInWindowOperator<T extends Element> {
  /**
   * @internal
   */
  private readonly observer: IntersectionObserver;

  constructor(public cb: (visible: boolean) => void) {
    this.observer = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        cb(entry.isIntersecting);
      }
    });
  }

  visible = false;

  ref: T | null = null;

  replace(ref: T | null) {
    if (this.ref === ref) return;
    if (this.ref) this.unmount(this.ref);
    this.ref = ref;
    if (ref) this.mount(ref);
  }

  mount(ref: T) {
    this.observer.observe(ref);
  }

  unmount(ref: T) {
    this.observer.unobserve(ref);
  }

  dispose() {
    this.observer.disconnect();
  }

  getRef = (ref: T | null) => this.replace(ref);

  effect = () => () => this.dispose();
}

export function useVisible<T extends Element>(
  create: typeof useVisibleInWindowOperator
) {
  const [visible, setVisible] = React.useState(false);
  const op = create<T>(setVisible);
  op.visible = visible;
  return op;
}

export function useVisibleInWindowOperator<T extends Element>(
  cb: (visible: boolean) => void
) {
  const opRef = React.useRef<DummyOperator<T>>();
  const op =
    opRef.current ||
    (opRef.current =
      typeof IntersectionObserver === "function"
        ? new VisibleInWindowOperator(cb)
        : new DummyOperator(cb));
  op.cb = cb;
  React.useEffect(op.effect, EMPTY_ARRAY);
  return op;
}

export class VisibleByProviderOperator<T extends Element> {
  constructor(
    readonly provider: ObserverProvider,
    public cb: (visible: boolean) => void
  ) {}

  visible = false;

  ref: T | null = null;

  replace(ref: T | null) {
    if (this.ref === ref) return;
    if (this.ref) this.unmount(this.ref);
    this.ref = ref;
    if (ref) this.mount(ref);
  }

  mount(ref: T) {
    this.provider.observe(ref, this._callback);
  }

  unmount(ref: T) {
    this.provider.unobserve(ref, this._callback);
  }

  dispose() {
    const { ref } = this;
    if (ref) {
      this.provider.unobserve(ref, this._callback);
    }
  }

  _callback = (visible: boolean) => this.cb(visible);

  getRef = (ref: T | null) => this.replace(ref);

  effect = () => () => this.dispose();
}

export function useVisibleByProviderOperator<T extends Element>(
  provider: ObserverProvider | undefined,
  cb: (visible: boolean) => void
) {
  const opRef = React.useRef<DummyOperator<T>>();

  let op =
    opRef.current ||
    (opRef.current = provider
      ? new VisibleByProviderOperator(provider, cb)
      : new DummyOperator(cb));

  const prevProvider = op.provider;

  if (prevProvider !== provider) {
    op.dispose();
    op = opRef.current = provider
      ? new VisibleByProviderOperator(provider, cb)
      : new DummyOperator(cb);
  }

  op.cb = cb;

  React.useEffect(op.effect, EMPTY_ARRAY);

  return op;
}

export function useVisibleByDefaultProviderOperator<T extends Element>(
  cb: (visible: boolean) => void
) {
  return useVisibleByProviderOperator<T>(
    typeof IntersectionObserver === "function"
      ? ObserverProvider.default
      : undefined,
    cb
  );
}

export function isSupported() {
  return typeof IntersectionObserver === "function";
}
