import * as React from "react";
import { useObserver } from "mobx-react";
import { runInAction } from "mobx";

import { MaybeThunk, dethunk } from "@chuyuan/poster-utils";

import { useLocale } from "../../../utils/i18n";
import { DuplexFieldLike } from "../../../utils/multi-value";
import { getInputProps } from "../../../utils/react-multi-value";
import {
  InputNumberArray,
  InputNumberArrayProps,
  InputNumberArrayRef,
} from "../../ui-components/input-number-array";
import { EditorContext } from "../../helpers/react-context";

export type InputNumberArrayValueRef = InputNumberArrayRef;

export interface InputNumberArrayValueProps
  extends Omit<InputNumberArrayHistoryProps, "value"> {
  readonly field: DuplexFieldLike<readonly number[]>;
}

export const InputNumberArrayValue = React.memo(
  React.forwardRef<InputNumberArrayValueRef, InputNumberArrayValueProps>(
    (props, ref) => {
      const { field, onChange, ...rest } = props;

      const locale = useLocale();

      return useObserver(() => {
        const fieldProps = getInputProps(field, locale);
        return (
          <InputNumberArrayHistory
            {...fieldProps}
            {...rest}
            ref={ref}
            onChange={(newValue) =>
              runInAction(() => {
                if (onChange) return onChange(newValue);
                return field.set(newValue).records.some(Boolean);
              })
            }
          />
        );
      });
    }
  )
);
InputNumberArrayValue.displayName = "InputNumberArrayValue";

export type InputNumberArrayHistoryRef = InputNumberArrayRef;

export interface InputNumberArrayHistoryProps extends InputNumberArrayProps {
  readonly historyName: MaybeThunk<string>;
  readonly onChange?: (value: readonly number[]) => boolean;
}

export const InputNumberArrayHistory = React.forwardRef<
  InputNumberArrayHistoryRef,
  InputNumberArrayHistoryProps
>((props, ref) => {
  const { historyName, ...rest } = props;

  const { session } = React.useContext(EditorContext);

  type State = {
    isChanged?: boolean;
  };

  const stateRef = React.useRef<State>();
  const state: State = stateRef.current || (stateRef.current = {});

  return (
    <InputNumberArray
      {...rest}
      ref={ref}
      onChange={(...args) => {
        const ret = props.onChange?.(...args);
        if (!ret) return;
        state.isChanged = true;
      }}
      onComplete={(...args) =>
        runInAction(() => {
          if (state.isChanged) {
            state.isChanged = undefined;
            session.history.push({ name: `修改${dethunk(historyName)}` });
          }
          props.onComplete?.(...args);
        })
      }
    />
  );
});
InputNumberArrayHistory.displayName = "InputNumberArrayHistory";
