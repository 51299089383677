import * as React from "react";
import { runInAction } from "mobx";
import { useObserver } from "mobx-react";

import {
  DuplexFieldLike,
  ReadFieldLike,
  ScaleValueField,
} from "../../utils/multi-value";
import type { DimensionUnitType, WH } from "./state.layout.child";
import { InputNumberHistory } from "../ui-components-stateful/number";
import { useLocale } from "../../utils/i18n";
import { getDependentInputPropsList } from "../../utils/react-multi-value";
import { Row } from "../ui-components/section";

export type LayoutDimensionValueProps = {
  readonly direction: WH;
  readonly value: DuplexFieldLike<number>;
  readonly unit: DuplexFieldLike<DimensionUnitType>;
  readonly possibleUnits: ReadFieldLike<ReadonlySet<DimensionUnitType>>;
};

export const LayoutDimensionValue = React.memo(
  (props: LayoutDimensionValueProps) => {
    const { direction, value: inputValue, unit, possibleUnits } = props;

    const locale = useLocale();

    return useObserver(() => {
      const unitData = unit.data;

      const value = React.useMemo(() => {
        if (unitData.variety === "various" || unitData.value === "px")
          return inputValue;
        return new ScaleValueField(inputValue, 100);
      }, [inputValue, unitData]);

      const [unitProps, valueProps] = getDependentInputPropsList(
        [unit, value] as const,
        locale
      );

      console.log(possibleUnits, unitProps);

      const historyTag = direction === "width" ? "宽度" : "高度";

      return (
        <>
          <Row className="margin-x">
            <InputNumberHistory
              {...valueProps}
              prefix={direction === "width" ? "宽" : "高"}
              dragDirection={direction === "width" ? "horizontal" : "vertical"}
              dragReverse={direction === "height"}
              historyName={historyTag}
              onChange={(newValue) => runInAction(() => value.set(newValue))}
            />
          </Row>
        </>
      );
    });
  }
);
LayoutDimensionValue.displayName = "LayoutDimensionValue";
