import * as React from "react";
import styled from "styled-components";
import { Frame } from "@chuyuan/poster-data-access-layer";

import { i18n } from "../../utils/i18n";
import { TreePanel } from "./tree";
import { SessionState } from "../editor-state/session-state";
import { GlobalContext } from "../ui-components/global-context";
import { EventHost } from "../../utils/event-system";

/**@name 左侧图层树 */
export const GraphicLayoutTextPanel = React.memo(
  (props: {
    readonly session: SessionState;
    readonly root: Frame;
    readonly ctx?: GlobalContext;
    readonly host?: EventHost;
  }) => {
    const { session, root, host, ctx } = props;
    return (
      <Wrapper>
        <FixedModeTitle />
        <Panel>
          <TreePanel session={session} root={root} host={host} ctx={ctx} />
        </Panel>
      </Wrapper>
    );
  }
);
GraphicLayoutTextPanel.displayName = "GraphicLayoutTextPanel";

const FixedModeTitle = React.memo(() => {
  return (
    <ModeTabs>
      <ModeTab className="selected">
        <添加文字 />
      </ModeTab>
    </ModeTabs>
  );
});
FixedModeTitle.displayName = "FixedModeTitle";

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  direction: ltr;
  background: #fff;
  transition: all 1s ease-in-out;
  border-right: 1px solid #eae9e8;
`;

const ModeTabs = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  padding: 4px;
  display: flex;
  height: 66px;
  align-items: center;
  border-bottom: 1px solid #eae9e8;

  &.center {
    justify-content: center;
  }
`;

const ModeTab = styled.div`
  padding: 0 8px;
  text-align: center;
  color: #a0a0a0;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  &.selected {
    color: #202020;
    /* font-weight: bold; */
  }
`;

const Panel = styled.div`
  flex: 1 1 0;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 10px;
  .add-title-item {
    padding: 14px 8px;
    background: #f2f1f0;
    cursor: pointer;
    border-radius: 4px;
  }
  .title-h1 {
    font-size: 18px;
    padding: 20px 8px;
    font-weight: bold;
  }
  .title-h2 {
    font-size: 16px;
    padding: 16px 8px;
    font-weight: bold;
  }
  .title-text {
    font-size: 12px;
    padding: 12px 8px;
  }
`;

const 添加文字 = i18n({
  en: () => "Add Text",
  zh: () => "添加文字",
});
