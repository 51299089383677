import * as React from "react";
import { useObserver } from "mobx-react";
import { Panel } from "../ui-components/panel";
import { Row, TitleText } from "../ui-components/section";
import { i18n } from "../../utils/i18n";
import { LayoutFields } from "./state.ui";
import { LayoutOffsetValue } from "./layout-offset";
import { LayoutDimensionValue } from "./layout-dimension";
import { useModule } from "../../utils/modulize";
import { LayoutModuleToken } from "./layout.module";
import { ShortValue } from "../ui-components-stateful/number";
import { CheckboxValue } from "../ui-components-stateful/boolean";
import { WH_KEYS, XY_KEYS } from "../helpers/misc";
import DebugLocalStorage from "../debug/local-storage";
import { renderReactPortalChildren } from "../../utils/react-portal-helpers";

/**@name 布局 x, y, width, height, rotation 等属性面板*/
export const LayoutPropertiesPanel = React.memo((props: { readonly fields: LayoutFields }) => {
  const { fields } = props;

  const LayoutModule = useModule(LayoutModuleToken);

  return useObserver(() => {
    const { targets } = fields;

    const slots = LayoutModule?.PropertiesSlot.items;

    if (!targets.length) return null;

    // const sides = fields.flexPaddingSides;

    return (
      <Panel unfoldable className="grid-content">
        {DebugLocalStorage.debug &&
          targets.length === 1 &&
          targets.map((target, i) => !(target.kind === "frame" && !target.parent()) && <div key={i}>{target.id}</div>)}
        {fields.isOffsetsDisabled ? null : (
          <>
            {XY_KEYS.map((key) => {
              const fs = fields.offsets[key];
              return (
                <Row key={key}>
                  <LayoutOffsetValue {...fs} direction={key} />
                </Row>
              );
            })}
          </>
        )}
        {WH_KEYS.map((key) => {
          const fs = fields.dimensions[key];
          return (
            <Row key={key}>
              <LayoutDimensionValue {...fs} direction={key} />
            </Row>
          );
        })}
        {fields.hasCanvas ? null : (
          <Row>
            <Row className="margin-x">
              <ShortValue prefix="°" dragDirection="vertical" field={fields.rotation} historyName="旋转值" />
            </Row>
            <Row className="margin-x">{renderReactPortalChildren(slots)}</Row>
          </Row>
        )}
        {!fields.isParentFlexContainer.get() ? null : (
          <Row className="pointer edged" as="label">
            <TitleText>
              <跟随父级容器方向 />
            </TitleText>
            <div>
              <CheckboxValue field={fields.flexItemInheritParentDirection} historyName={(v) => `${v ? "开启" : "关闭"}跟随父级容器方向`} />
            </div>
          </Row>
        )}
        {!fields.hasOnlyElementContainers ? null : (
          <Row className="pointer edged" as="label">
            <TitleText>
              <显示框外部分 />
            </TitleText>
            <div>
              <CheckboxValue field={fields.displayOuter} historyName={(v) => `${v ? "显示" : "隐藏"}框外部分`} />
            </div>
          </Row>
        )}
      </Panel>
    );
  });
});
LayoutPropertiesPanel.displayName = "LayoutPropertiesPanel";

const 跟随父级容器方向 = i18n({
  en: () => "inherit parent direction",
  zh: () => "跟随父级容器方向",
});

const 显示框外部分 = i18n({
  en: () => "display overflow content",
  zh: () => "显示框外部分",
});
