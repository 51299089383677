export function preventDefault(e: { preventDefault: () => unknown }) {
  e.preventDefault()
}

export function stopPropagation(e: { stopPropagation: () => unknown }) {
  e.stopPropagation()
}

export function preventPropagationAndDefault(e: { preventDefault(): void; stopPropagation(): void }) {
  e.preventDefault()
  e.stopPropagation()
}
