import localstorage from '../../utils/mobx-local-storage'

/**
 * 命名空间
 */
export const LS_NS = 'chuyuan graphic editor'

/**
 * 启用debug模式
 */
export const LS_DEBUG_KEY = `${LS_NS} debug`

/**
 * 启用GDL调试
 */
export const LS_ENABLE_GDL_DEBUG_KEY = `${LS_NS} enable dal debug`

/**
 * 显示画布碰撞检测
 */
export const LS_DISPLAY_CANVAS_COLLISION_KEY = `${LS_NS} display canvas collision`

/**
 * 启用canvas渲染
 */
export const LS_ENABLE_CANVAS_RENDER_KEY = `${LS_NS} enable canvas render`

/**
 * 启用svg渲染
 */
export const LS_ENABLE_SVG_RENDER_KEY = `${LS_NS} enable svg render`

/**
 * 启用历史记录
 */
export const LS_ENABLE_HISTORY_KEY = `${LS_NS} enable history`

/**
 * 禁用默认渲染
 */
export const LS_DISABLE_DEFAULT_RENDER_KEY = `${LS_NS} disable default render`

/**
 * 隐藏侧边栏
 */
export const LS_HIDE_SIDEBAR_KEY = `${LS_NS} hide sidebar`

/**
 * 调试 banner resize
 */
export const LS_DEBUG_BANNER_RESIZE = `${LS_NS} debug banner resize`

/**
 * debug模式的语言
 */
export const LS_DEBUG_LOCALE = `${LS_NS} debug locale`

export class DebugLocalStorage {
  get debug() {
    return !!localstorage.getItem(LS_DEBUG_KEY)
  }

  set debug(value) {
    localstorage.setItem(LS_DEBUG_KEY, !!value)
  }

  get enableGDLDebug() {
    return !!localstorage.getItem(LS_ENABLE_GDL_DEBUG_KEY)
  }

  set enableGDLDebug(value) {
    localstorage.setItem(LS_ENABLE_GDL_DEBUG_KEY, !!value)
  }

  get displayCanvasCollision() {
    return !!localstorage.getItem(LS_DISPLAY_CANVAS_COLLISION_KEY)
  }

  set displayCanvasCollision(value) {
    localstorage.setItem(LS_DISPLAY_CANVAS_COLLISION_KEY, !!value)
  }

  get enableCanvasRender() {
    return !!localstorage.getItem(LS_ENABLE_CANVAS_RENDER_KEY)
  }

  set enableCanvasRender(value) {
    localstorage.setItem(LS_ENABLE_CANVAS_RENDER_KEY, !!value)
  }

  get enableSvgRender() {
    return !!localstorage.getItem(LS_ENABLE_SVG_RENDER_KEY)
  }

  set enableSvgRender(value) {
    localstorage.setItem(LS_ENABLE_SVG_RENDER_KEY, !!value)
  }

  get enableHistory() {
    return !!localstorage.getItem(LS_ENABLE_HISTORY_KEY)
  }

  set enableHistory(value) {
    localstorage.setItem(LS_ENABLE_HISTORY_KEY, !!value)
  }

  get disableDefaultRender() {
    return !!localstorage.getItem(LS_DISABLE_DEFAULT_RENDER_KEY)
  }

  set disableDefaultRender(value) {
    localstorage.setItem(LS_DISABLE_DEFAULT_RENDER_KEY, !!value)
  }

  get hideSidebar() {
    return !!localstorage.getItem(LS_HIDE_SIDEBAR_KEY)
  }

  set hideSidebar(value) {
    localstorage.setItem(LS_HIDE_SIDEBAR_KEY, !!value)
  }

  // get debugBannerResize() {
  //   return !!localstorage.getItem(LS_DEBUG_BANNER_RESIZE)
  // }

  // set debugBannerResize(value) {
  //   localstorage.setItem(LS_DEBUG_BANNER_RESIZE, !!value)
  // }

  get locale() {
    return String(localstorage.getItem(LS_DEBUG_LOCALE) || '')
  }

  set locale(value) {
    const v = typeof value === 'string' ? value : ''
    localstorage.setItem(LS_DEBUG_LOCALE, v)
  }
}

export default new DebugLocalStorage()
