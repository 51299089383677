import * as React from "react";
import { times } from "lodash";
import { EMPTY_ARRAY } from "@chuyuan/poster-utils";
import { safeDimension } from "./data-guard";
import { formatMinMax, formatValueBetweenMinMax } from "./common";

export interface HTMLInputElementCompatibleProps
  extends Readonly<React.InputHTMLAttributes<HTMLInputElement>> {
  readonly onBlur?: (
    e: React.FocusEvent<HTMLInputElement> | FocusEvent
  ) => void;
}

export interface UseInputNumberProps
  extends Readonly<
    Pick<
      HTMLInputElementCompatibleProps,
      "readOnly" | "disabled" | "onFocus" | "onKeyDown" | "onBlur"
    >
  > {
  readonly value?: number;
  readonly min?: number;
  readonly max?: number;
  readonly step?: number;
  readonly format?: (value: number) => number | string;
  readonly onChange?: (value: number) => void;
  readonly onComplete?: (value: number) => void;
}

export function useInputNumberProps(props: UseInputNumberProps) {
  const { value, format } = props;

  const stateRef = React.useRef<{
    changed?: boolean;
    // 解释一下这个字段.
    // input的blur事件触发的顺序是: mouse down -> blur
    // 然后如果在 mouse down 事件里触发了一些动作, 导致传入的 onChange, onComplete, onBlur 等, 变成了新的语义
    // 然后在 blur 事件中, 原本预期是触发旧语义的动作, 错误地触发了新语义.
    // 所以这个值代表了正确的语义 props (主要是关于回调函数)
    focusProps?: UseInputNumberProps;
    focusHtmlProps?: HTMLInputElementCompatibleProps;
    isFocused?: HTMLInputElement;
    readonly effect: () => () => void;
  }>();
  const state =
    stateRef.current ||
    (stateRef.current = {
      effect: () => () => {
        const ref = state.isFocused;
        if (ref) {
          const onBlur = state.focusHtmlProps?.onBlur;
          if (onBlur) {
            const cb = (e: FocusEvent) => {
              ref.removeEventListener("blur", cb);
              onBlur(e);
            };
            ref.addEventListener("blur", cb);
            ref.blur();
          }
        }
      },
    });

  // unmount 发生在 onBlur 之前会导致 onBlur 不被触发, 所以需要手动触发
  // @see https://github.com/facebook/react/issues/12363

  React.useEffect(state.effect, EMPTY_ARRAY);

  const propsText =
    typeof value === "number" ? String(format ? format(value) : value) : "";
  const [draft, setDraft] = React.useState({
    text: propsText,
    isFocused: false,
  });

  if (!draft.isFocused && propsText !== draft.text) {
    setDraft({ ...draft, text: propsText });
  }

  const minmax = formatMinMax(props.min, props.max);
  const draftValue = parseText(draft.text);
  const isDraftValueFinite = Number.isFinite(draftValue);

  const newProps: HTMLInputElementCompatibleProps = {
    readOnly: props.readOnly,
    disabled: props.disabled,
    autoComplete: "off",
    autoCorrect: "off",
    autoCapitalize: "off",
    spellCheck: false,
    value: draft.text,
    "aria-valuenow": isDraftValueFinite ? draftValue : undefined,
    "aria-valuemin": minmax && minmax.min,
    "aria-valuemax": minmax && minmax.max,
    onFocus: (e) => {
      state.isFocused = e.currentTarget;
      state.changed = false;
      setDraft((x) => ({ ...x, isFocused: true }));
      props.onFocus?.(e);
    },
    onChange: (e) => {
      const text = e.target.value;
      setDraft((x) => ({ ...x, text }));
    },
    onKeyDown: (e) => {
      // 内容更新的时候触发语义 props 更新
      state.focusProps = props;
      state.focusHtmlProps = newProps;
      props.onKeyDown?.(e);
      if (e.isPropagationStopped()) return;
      if (props.readOnly || props.disabled) {
        return;
      }
      if (minmax && isDraftValueFinite) {
        // minmax 不为空意味着是有效的, 无效的话, 定义 key down 不修改值
        // 数值是可数的
        if (e.key === "Enter") {
          // 因为draftValue一定是有效的, 因此用感叹号去除undefined
          state.changed = true;
          const newValue = formatValueBetweenMinMax(draftValue, minmax)!;
          props.onChange?.(newValue);
        } else {
          const step = safeDimension(props.step ?? 1);
          if (e.key === "ArrowDown") {
            state.changed = true;
            const newValue = formatValueBetweenMinMax(
              draftValue - step,
              minmax
            )!;
            setDraft((x) => ({ ...x, text: String(newValue) }));
            props.onChange?.(newValue);
          } else if (e.key === "ArrowUp") {
            state.changed = true;
            const newValue = formatValueBetweenMinMax(
              draftValue + step,
              minmax
            )!;
            setDraft((x) => ({ ...x, text: String(newValue) }));
            props.onChange?.(newValue);
          }
        }
      }
      // 对于默认行为的数字操作, 即使没有响应 onChange, 也应该禁止默认行为
      if (e.key === "ArrowDown" || e.key === "ArrowUp" || e.key === "Enter") {
        e.preventDefault();
      }
    },
    onBlur: (e) => {
      state.isFocused = undefined;
      // 读取语义 props
      const p = state.focusProps || props;
      setDraft((x) => ({ ...x, isFocused: false }));
      if (isDraftValueFinite) {
        const newValue = minmax
          ? formatValueBetweenMinMax(draftValue, minmax)!
          : draftValue;
        const text = String(newValue);
        setDraft((x) => ({ ...x, text }));
        if (propsText !== text) {
          // 值不同意味着没有 emit 过 changes
          state.changed = true;
          p.onChange?.(draftValue);
        }
        if (state.changed) {
          p.onComplete?.(draftValue);
        }
      }
      state.changed = false;
      p.onBlur?.(e);
    },
  };

  if (!state.isFocused) {
    // 非 focus 的时候总是更新语义 props
    state.focusProps = props;
    state.focusHtmlProps = newProps;
  }

  return {
    ...draft,
    minmax,
    draftValue,
    isDraftValueFinite,
    props: newProps,
  };
}

export const ToFixedFormatter = times(
  8,
  (i) => (value: number) => +value.toFixed(i)
);

function parseText(x: string) {
  return x ? +x : NaN;
}
