import * as React from "react";
import * as ElementResizeDetector from "element-resize-detector";
import { EMPTY_ARRAY } from "@chuyuan/poster-utils";

export const ERD = ElementResizeDetector({ strategy: "scroll" });

export class ElementResizeDetectorOperatorLegacy<T extends HTMLElement> {
  constructor(public cb: (element: T) => void, readonly erd: typeof ERD) {}

  ref: T | null = null;

  replace(ref: T | null) {
    if (this.ref === ref) return;
    if (this.ref) this.unmount(this.ref);
    this.ref = ref;
    if (ref) this.mount(ref);
  }

  mount(ref: T) {
    this.erd.listenTo(ref, (e) => this.cb(e as T));
  }

  unmount(ref: T) {
    this.erd.uninstall(ref);
  }

  dispose() {
    if (this.ref) {
      this.erd.uninstall(this.ref);
    }
  }

  getRef = (ref: T | null) => this.replace(ref);

  effect = () => () => this.dispose();
}

export class ElementResizeDetectorOperatorNative<T extends Element> {
  readonly observer: ResizeObserver;

  constructor(public cb: (element: T) => void) {
    this.observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        cb(entry.target as T);
      }
    });
  }

  ref: T | null = null;

  replace(ref: T | null) {
    if (this.ref === ref) return;
    if (this.ref) this.unmount(this.ref);
    this.ref = ref;
    if (ref) this.mount(ref);
  }

  mount(ref: T) {
    this.observer.observe(ref);
  }

  unmount(ref: T) {
    this.observer.unobserve(ref);
  }

  dispose() {
    this.observer.disconnect();
  }

  getRef = (ref: T | null) => this.replace(ref);

  effect = () => () => this.dispose();
}

export type AnyElementResizeDetectorOperator<T extends HTMLElement> =
  | ElementResizeDetectorOperatorNative<T>
  | ElementResizeDetectorOperatorLegacy<T>;

export function createElementResizeDetectorOperator<T extends HTMLElement>(
  cb: (element: T) => void,
  erd = ERD
) {
  if (hasResizeObserver()) {
    return new ElementResizeDetectorOperatorNative(cb);
  }
  return new ElementResizeDetectorOperatorLegacy(cb, erd);
}

export function useElementResizeDetectorOperator<T extends HTMLElement>(
  cb: (element: T) => void,
  erd = ERD
) {
  const opRef = React.useRef<AnyElementResizeDetectorOperator<T>>();
  const op =
    opRef.current ||
    (opRef.current = createElementResizeDetectorOperator(cb, erd));
  op.cb = cb;
  React.useEffect(op.effect, EMPTY_ARRAY);
  return op;
}

function hasResizeObserver() {
  return typeof ResizeObserver === "function";
}
