import * as React from "react";
import styled from "styled-components";
import * as cx from "classnames";
import Icon from "@ant-design/icons";

import { dethunk } from "@chuyuan/poster-utils";

import { ArrowDown } from "../../../../poster-ui/icons/new-icons";

type ReactProps<T> = React.DetailedHTMLProps<React.HTMLAttributes<T>, T>;

export interface MenuProps
  extends Omit<ReactProps<HTMLDivElement>, "children"> {
  readonly children?: React.ReactNode | (() => React.ReactNode);
}

export class Menu extends React.PureComponent<MenuProps> {
  onContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { onContextMenu } = this.props;
    if (typeof onContextMenu === "function") {
      onContextMenu(e);
    }
    if (!e.isPropagationStopped()) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  override render() {
    const { ref, children, ...rest } = this.props;

    return (
      <MenuDOM
        {...rest}
        onContextMenu={this.onContextMenu}
        children={dethunk(children)}
      />
    );
  }
}

export interface MenuItemProps<T>
  extends Omit<ReactProps<HTMLDivElement>, "children"> {
  data?: T;
  disabled?: boolean;
  disableHover?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>, data?: T) => void;
  content?: React.ReactNode;
  children?: React.ReactNode | (() => React.ReactNode);
}

export interface MenuItemState {
  hover: boolean;
}

export class MenuItem<T> extends React.PureComponent<
  MenuItemProps<T>,
  MenuItemState
> {
  override state: MenuItemState = {
    hover: false,
  };

  onMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ hover: true });
    if (typeof this.props.onMouseEnter === "function") {
      this.props.onMouseEnter(e);
    }
  };

  onMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ hover: false });
    if (typeof this.props.onMouseLeave === "function") {
      this.props.onMouseLeave(e);
    }
  };

  onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (typeof this.props.onClick === "function") {
      this.props.onClick(e, this.props.data);
    }
  };

  override render() {
    const {
      className,
      disabled,
      disableHover,
      content,
      children,
      ref,
      ...rest
    } = this.props;
    const hasChildren = !!(Array.isArray(children)
      ? children.length > 0
      : children);

    return (
      <MenuItemDOM
        {...rest}
        className={cx(
          className,
          disabled && "disabled",
          disableHover !== false && "hover"
        )}
        onClick={disabled ? undefined : this.onClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <MenuItemContentDOM>{content}</MenuItemContentDOM>
        {hasChildren && (
          <MenuItemCaretDOM>
            <Icon
              component={ArrowDown}
              style={{ transform: "rotate(-90deg)" }}
            />
          </MenuItemCaretDOM>
        )}
        {hasChildren && this.state.hover && (
          <MenuDOM className="is-sub">{dethunk(children)}</MenuDOM>
        )}
      </MenuItemDOM>
    );
  }
}

const MenuDOM = styled.div`
  position: relative;
  min-width: 12.5em;
  max-width: 20em;
  padding: 0.3em 0;
  color: #101010;
  background: #f8f8f7;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  user-select: none;

  &.is-sub {
    position: absolute;
    top: -0.3em;
    left: 100%;
  }
`;

const MenuSeparatorDOM = styled.div`
  margin: 0.4em 0;
  height: 1px;
  border-bottom: solid 1px #e3e3e4;
`;

export { MenuSeparatorDOM as MenuSeparator };

const MenuItemDOM = styled.div`
  position: relative;
  padding: 0.5em 0.7em;
  letter-spacing: 0.01666666667em;
  cursor: pointer;
  display: flex;

  &.hover:hover {
    color: #fff;
    background-color: #459aeb;
  }

  &.disabled {
    &,
    &:hover {
      color: unset;
      background-color: unset;
      cursor: unset;
      opacity: 0.5;
    }
  }
`;

const MenuItemContentDOM = styled.div`
  flex: 1 1 0;
  padding: 0 1em;
  max-width: 100%;
`;

const MenuItemCaretDOM = styled.div`
  flex: 0 0 auto;
`;

export function createGroups(groups: React.ReactNode[][]) {
  const result: React.ReactNode[] = [];
  let i = -1;
  for (const group of groups) {
    if (!group.length) continue;
    i++;
    if (i) {
      result.push(<MenuSeparatorDOM key={`separator-${i}`} />);
    }
    result.push(...group);
  }
  return result;
}
