import { EMPTY_OBJECT } from "@chuyuan/poster-utils";
import * as React from "react";
import { ContextMenu, ContextMenuProps } from "../gdl-context-menu";
import { EditorContext } from "../helpers/react-context";
import { GlobalContext } from "../ui-components/global-context";
import { Popover } from "../ui-components/popover";
import { popup, Popup, PopupProps } from "../ui-components/popup";
import { Anchor, Locator } from "../ui-components/popup-locator";

export const CANVAS_PANEL_DEFAULT_ANCHORS: readonly Anchor[] = [
  { target: "left", content: "right" },
  { target: "left-top", content: "right-top" },
  { target: "right", content: "left" },
  { target: "right-top", content: "left-top" },
];

export function CanvasPanel(
  props: Omit<PopupProps, "ctx"> & {
    readonly target: HTMLElement;
    readonly onClose: () => void;
  }
) {
  const editor = React.useContext(EditorContext);

  const { ui } = editor.session;
  const { target, render, ...rest } = props;
  return (
    <Popup
      {...rest}
      render={(p) => (
        <Locator
          region={(ui.canvasRef || p.container).getBoundingClientRect()}
          target={target.getBoundingClientRect()}
          // anchors={CANVAS_PANEL_DEFAULT_ANCHORS}
          anchors={[
            { target: "center", content: "top" },
            { target: "center", content: "bottom" },
          ]}
        >
          {render({ ...p, onClose: props.onClose })}
        </Locator>
      )}
    />
  );
}

export function LeftRightPanel(
  props: Omit<PopupProps, "ctx"> & {
    readonly target: HTMLElement;
    readonly block: HTMLElement;
    readonly onClose: () => void;
  }
) {
  const { target, block, render, ...rest } = props;
  const { left, right } = block.getBoundingClientRect();
  return (
    <Popup
      {...rest}
      render={(p) => (
        <Locator
          target={target.getBoundingClientRect()}
          avoids={[
            { left: left - 16, right: right + 16, top: -1e5, bottom: 1e5 },
          ]}
        >
          {render({ ...p, onClose: props.onClose })}
        </Locator>
      )}
    />
  );
}

/**
 * 调出 GDL 对象下拉菜单
 * @param ctx
 * @param clientX 当前鼠标的窗口横坐标
 * @param clientY 当前鼠标的窗口纵坐标
 * @param props
 */
export function callContextMenu(
  ctx: GlobalContext | undefined,
  clientX: number,
  clientY: number,
  props: ContextMenuProps = EMPTY_OBJECT
) {
  if (!ctx) return;
  popup({
    ctx,
    render: ({ onClose }) => (
      <Locator
        target={{
          left: clientX,
          right: clientX,
          top: clientY,
          bottom: clientY,
        }}
        padding={0}
        anchors={[
          { target: "center", content: "left-top" },
          { target: "center", content: "left-bottom" },
          { target: "center", content: "right-top" },
          { target: "center", content: "right-bottom" },
        ]}
        children={
          <ContextMenu
            {...props}
            onClose={(e) => (onClose(), props.onClose?.(e))}
          />
        }
      />
    ),
  });
}

/**
 * 召唤一个下拉框
 * - 会避开 target
 */
export function callDropDown(
  props: PopupProps & {
    readonly target: Pick<HTMLElement, "getBoundingClientRect">;
  }
) {
  const { ctx, target, render } = props;
  popup({
    ctx,
    render: (p) => (
      <Locator
        target={target.getBoundingClientRect()}
        anchors={[
          { target: "center", content: "top" },
          { target: "center", content: "bottom" },
        ]}
        children={(locator) => <Popover locator={locator}>{render(p)}</Popover>}
      />
    ),
  });
}
