import * as pinyinlite from 'pinyinlite/index_full'

/**
 * 将中的每一个字转化为拼音 (包括多音字)
 */
export const pinyin = ((...args) => pinyinlite(...args)) as typeof pinyinlite

/**
 * 将文本中的汉字转化为拼音 (因为有多音字, 会返回所有可能的组合)
 * @param text 文本
 */
export function getAllPinyinCombinations(text: string) {
  const result = pinyinlite(text, { keepUnrecognized: true })
  return producePinyinResult(result)
}

/**
 * 将 pinyinlite 结果转化为所有可能的字符串组合
 * @param data 结果数据
 */
export function producePinyinResult(data: ReadonlyArray<readonly string[]>) {
  let result: string[] = []
  for (let i = 0, leni = data.length; i < leni; i++) {
    const pys = data[i]
    const pysLength = pys.length
    if (!pysLength) continue
    const lenk = result.length
    if (lenk) {
      if (pysLength === 1) {
        const py = pys[0]
        for (let k = 0; k < lenk; k++) {
          result[k] = `${result[k]}${py}`
        }
      } else {
        const source = result
        result = []
        for (let j = 0, lenj = pys.length; j < lenj; j++) {
          const py = pys[j]
          for (let k = 0; k < lenk; k++) {
            result.push(`${source[k]}${py}`)
          }
        }
      }
    } else {
      result = pys.slice()
    }
  }
  return result
}
