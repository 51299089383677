import * as React from "react";
import { useObserver } from "mobx-react";
import { runInAction } from "mobx";

import { SkewXIcon, SkewYIcon } from "../../../../../poster-ui/icons/new-icons";
import { MaybeThunk, dethunk } from "@chuyuan/poster-utils";

import { useLocale } from "../../../utils/i18n";
import { Gap } from "../../ui-components/section";
import { DuplexFieldLike, MapDuplexField } from "../../../utils/multi-value";
import { getInputProps } from "../../../utils/react-multi-value";
import {
  InputNumber,
  InputNumberProps,
  InputNumberRef,
} from "../../ui-components/input-number";
import { SingleSlider } from "../../ui-components/slider";
import { useRaf } from "../../../utils/react-hooks";
import { AngleBtn } from "../../ui-components/angle-btn";
import { EditorContext } from "../../helpers/react-context";

export const NoUnitGTE0PercentLongValue = React.memo(
  (props: {
    readonly historyName: MaybeThunk<string>;
    readonly max?: number;
    readonly step?: number;
    readonly field: DuplexFieldLike<number>;
  }) => {
    const { field: inputField, max, step } = props;

    const { session } = React.useContext(EditorContext);
    const locale = useLocale();

    const field = React.useMemo(
      () =>
        MapDuplexField.same(
          inputField,
          (x) => x * 100,
          (x) => x / 100
        ),
      [inputField]
    );

    const raf = useRaf();

    return useObserver(() => {
      const multiValueProps = getInputProps(field, locale);
      const onChange = (newValue: number) => {
        raf.push(() => runInAction(() => field.set(newValue)));
      };
      const onComplete = () =>
        runInAction(() => {
          raf.flush();
          session.history.push({ name: `修改${dethunk(props.historyName)}` });
        });
      return (
        <>
          <SingleSlider
            {...multiValueProps}
            min={0}
            max={max ?? 100}
            step={step ?? 1}
            onChange={onChange}
            onComplete={onComplete}
          />
          <Gap />
          <InputNumber
            {...multiValueProps}
            align="left"
            min={0}
            step={step ?? 1}
            onChange={onChange}
            onComplete={onComplete}
            style={{ maxWidth: 64 }}
          />
        </>
      );
    });
  }
);
NoUnitGTE0PercentLongValue.displayName = "NoUnitGTE0PercentLongValue";

export const GTE0PxLongValue = React.memo(
  (props: {
    readonly historyName: MaybeThunk<string>;
    readonly max?: number;
    readonly step?: number;
    readonly field: DuplexFieldLike<number>;
  }) => {
    const { field, max, step } = props;

    const { session } = React.useContext(EditorContext);
    const locale = useLocale();

    const raf = useRaf();

    return useObserver(() => {
      const multiValueProps = getInputProps(field, locale);
      const onChange = (newValue: number) => {
        raf.push(() => runInAction(() => field.set(newValue)));
      };
      const onComplete = () =>
        runInAction(() => {
          raf.flush();
          session.history.push({ name: `修改${dethunk(props.historyName)}` });
        });
      return (
        <>
          <SingleSlider
            {...multiValueProps}
            min={0}
            max={max ?? 100}
            step={step ?? 1}
            onChange={onChange}
            onComplete={onComplete}
          />
          <Gap />
          <InputNumber
            {...multiValueProps}
            align="left"
            suffix="px"
            min={0}
            step={step ?? 1}
            onChange={onChange}
            onComplete={onComplete}
            style={{ maxWidth: 64 }}
          />
        </>
      );
    });
  }
);
NoUnitGTE0PercentLongValue.displayName = "NoUnitGTE0PercentLongValue";

export const GTE0LongValue = React.memo(
  (props: {
    readonly historyName: MaybeThunk<string>;
    readonly min?: number;
    readonly max?: number;
    readonly step?: number;
    readonly suffix?: React.ReactNode;
    readonly field: DuplexFieldLike<number>;
  }) => {
    const { field, min, max, step } = props;

    const { session } = React.useContext(EditorContext);
    const locale = useLocale();

    const raf = useRaf();

    return useObserver(() => {
      const multiValueProps = getInputProps(field, locale);
      const onChange = (newValue: number) => {
        raf.push(() => runInAction(() => field.set(newValue)));
      };
      const onComplete = () =>
        runInAction(() => {
          raf.flush();
          session.history.push({ name: `修改${dethunk(props.historyName)}` });
        });
      return (
        <>
          <SingleSlider
            {...multiValueProps}
            min={min ?? 0}
            max={max ?? 100}
            step={step ?? 1}
            onChange={onChange}
            onComplete={onComplete}
          />
          <Gap />
          <InputNumber
            {...multiValueProps}
            align="left"
            suffix={props.suffix}
            min={min ?? 0}
            step={step ?? 1}
            onChange={onChange}
            onComplete={onComplete}
            style={{ maxWidth: 64 }}
          />
        </>
      );
    });
  }
);
GTE0LongValue.displayName = "GTE0LongValue";

export const SkewLongValue = React.memo(
  (props: {
    readonly historyName: MaybeThunk<string>;
    readonly field: DuplexFieldLike<number>;
  }) => {
    const { field } = props;

    const { session } = React.useContext(EditorContext);
    const locale = useLocale();

    const raf = useRaf();

    return useObserver(() => {
      const multiValueProps = getInputProps(field, locale);
      const onChange = (newValue: number) => {
        raf.push(() => runInAction(() => field.set(newValue)));
      };
      const onComplete = () =>
        runInAction(() => {
          raf.flush();
          session.history.push({ name: `修改${dethunk(props.historyName)}` });
        });
      return (
        <>
          <SingleSlider
            {...multiValueProps}
            min={-85}
            max={85}
            step={1}
            onChange={onChange}
            onComplete={onComplete}
          />
          <Gap />
          <InputNumber
            {...multiValueProps}
            align="left"
            min={-90}
            max={90}
            step={1}
            dragReverse
            onChange={onChange}
            onComplete={onComplete}
            style={{ maxWidth: 64 }}
          />
        </>
      );
    });
  }
);
SkewLongValue.displayName = "SkewLongValue";

export const SkewSortValue = React.memo(
  (props: {
    readonly direction: "x" | "y";
    readonly historyName: MaybeThunk<string>;
    readonly field: DuplexFieldLike<number>;
  }) => {
    const { direction, field } = props;

    const { session } = React.useContext(EditorContext);
    const locale = useLocale();

    const raf = useRaf();

    const Icon = getSkewIcon[direction]();

    return useObserver(() => {
      const multiValueProps = getInputProps(field, locale);
      const onChange = (newValue: number) => {
        raf.push(() => runInAction(() => field.set(newValue)));
      };
      const onComplete = () =>
        runInAction(() => {
          raf.flush();
          session.history.push({ name: `修改${dethunk(props.historyName)}` });
        });
      return (
        <InputNumber
          {...multiValueProps}
          align="left"
          min={-90}
          max={90}
          step={1}
          prefix={<Icon />}
          suffix="°"
          dragReverse
          onChange={onChange}
          onComplete={onComplete}
        />
      );
    });
  }
);
SkewLongValue.displayName = "SkewLongValue";

const getSkewIcon = {
  x: () => SkewXIcon,
  y: () => SkewYIcon,
};

export const AngleShortValue = React.memo(
  (props: {
    readonly historyName: MaybeThunk<string>;
    readonly field: DuplexFieldLike<number>;
  }) => {
    const { field } = props;

    const { session } = React.useContext(EditorContext);
    const locale = useLocale();

    const raf = useRaf();

    return useObserver(() => {
      const multiValueProps = getInputProps(field, locale);
      return (
        <AngleBtn
          {...multiValueProps}
          value={field.get() ?? "various"}
          onChange={(newValue) => {
            raf.push(() => runInAction(() => field.set(newValue)));
          }}
          onComplete={() =>
            runInAction(() => {
              raf.flush();
              session.history.push({
                name: `修改${dethunk(props.historyName)}`,
              });
            })
          }
        />
      );
    });
  }
);
AngleShortValue.displayName = "AngleShortValue";

export const AngleLongValue = React.memo(
  (props: {
    readonly historyName: MaybeThunk<string>;
    readonly field: DuplexFieldLike<number>;
  }) => {
    const { field } = props;

    const { session } = React.useContext(EditorContext);
    const locale = useLocale();

    const raf = useRaf();

    return useObserver(() => {
      const multiValueProps = getInputProps(field, locale);
      const onChange = (newValue: number) => {
        raf.push(() => runInAction(() => field.set(newValue)));
      };
      const onComplete = () =>
        runInAction(() => {
          raf.flush();
          session.history.push({ name: `修改${dethunk(props.historyName)}` });
        });
      return (
        <>
          <AngleBtn
            {...multiValueProps}
            value={field.get() ?? "various"}
            onChange={onChange}
            onComplete={onComplete}
          />
          <Gap />
          <SingleSlider
            {...multiValueProps}
            min={0}
            max={360}
            step={1}
            onChange={onChange}
            onComplete={onComplete}
          />
          <Gap />
          <InputNumber
            {...multiValueProps}
            align="left"
            suffix="°"
            min={0}
            step={1}
            max={360}
            onChange={onChange}
            onComplete={onComplete}
            style={{ maxWidth: 64 }}
          />
        </>
      );
    });
  }
);
AngleLongValue.displayName = "AngleLongValue";

export type ShortValueRef = InputNumberRef;

export interface ShortValueProps
  extends Omit<InputNumberProps, "value" | "onChange" | "onComplete"> {
  readonly historyName: MaybeThunk<string>;
  readonly field: DuplexFieldLike<number>;
}

export const ShortValue = React.memo(
  React.forwardRef<ShortValueRef, ShortValueProps>((props, ref) => {
    const { field, ...rest } = props;

    const locale = useLocale();

    return useObserver(() => {
      const fieldProps = getInputProps(field, locale);
      return (
        <InputNumberHistory
          {...fieldProps}
          {...rest}
          ref={ref}
          onChange={(v) => runInAction(() => field.set(v))}
        />
      );
    });
  })
);
ShortValue.displayName = "ShortValue";

export type InputNumberHistoryRef = InputNumberRef;

export interface InputNumberHistoryProps extends InputNumberProps {
  readonly historyName: MaybeThunk<string>;
}

export const InputNumberHistory = React.forwardRef<
  InputNumberHistoryRef,
  InputNumberHistoryProps
>((props, ref) => {
  const { historyName, ...rest } = props;

  const { session } = React.useContext(EditorContext);

  type State = {
    isChanged?: boolean;
  };

  const stateRef = React.useRef<State>();
  const state: State = stateRef.current || (stateRef.current = {});

  return (
    <InputNumber
      {...rest}
      ref={ref}
      onChange={(...args) => {
        state.isChanged = true;
        props.onChange?.(...args);
      }}
      onComplete={(...args) =>
        runInAction(() => {
          if (state.isChanged) {
            state.isChanged = undefined;
            session.history.push({ name: `修改${dethunk(historyName)}` });
          }
          props.onComplete?.(...args);
        })
      }
    />
  );
});
InputNumberHistory.displayName = "InputNumberHistory";
