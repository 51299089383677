import { observable, computed, runInAction, makeObservable } from "mobx";

import { memorize, EMPTY_ARRAY } from "@chuyuan/poster-utils";
import { GDLLayoutSemantic } from "@chuyuan/poster-data-access-layer";
import { ReadonlyAffine2D, solveTransformAsSxSRT } from "@chuyuan/poster-math";

import {
  PointerEventRegister,
  PointerEventHandlers,
  PointerEventRegisterData,
} from "./event";
import {
  CORNERS_AND_SIDES_ORDERED,
  getEdgeCursor,
  ResizeCorner,
  CORNERS_CLOCK_WISE,
  getCornerCursor,
  SIDES_ORDERED,
} from "./common";
import type { RootState } from "./state";
import { BBox } from "./bbox";
import { Raf } from "../../utils/raf";
import { Bounding, INFINITE_BOUNDING } from "../helpers/misc";
import { getBoundingGivenCenterAndPadding } from "../helpers/data-picker";

export interface ResizeOperator<T> {
  readonly getRoot: () => RootState;
  readonly getTransform?: () => ReadonlyAffine2D | undefined;
  readonly getHoverRegister: () => Omit<PointerEventRegister, "data">;
  readonly getColor: () => string;
  readonly getAllowedSides: () => ResizeAllowedSize;
  readonly getBounding: () => Bounding;
  readonly getSnapshot: () => T;
  readonly apply: (snapshot: T, input: GDLLayoutSemantic.ResizeInput) => void;
  readonly onStart?: () => void;
  readonly onEnd?: () => void;
}

export type ResizeAllowedSize = {
  readonly horizontal: boolean;
  readonly vertical: boolean;
  readonly corner: boolean;
};

let RESIZE_STATE_COUNT = 0;

export class ResizeState<T> {
  constructor(readonly op: ResizeOperator<T>) {
    makeObservable(this);
  }

  readonly raf = new Raf();

  private readonly _uniqKey = `resize state ${++RESIZE_STATE_COUNT}`;

  private _disposed = false;

  dispose() {
    if (this._disposed) return;
    this._disposed = true;
    this.raf.flush();
    this.op
      .getRoot()
      .props.session.ui.isNonRealtimeRenderDisabled.delete(this._uniqKey);
  }

  @observable.ref
  resizeData?: {
    readonly snapshot: T;
    readonly corner: ResizeCorner;
    readonly x: number;
    readonly y: number;
  } = undefined;

  getResizeHandlers(corner: ResizeCorner): PointerEventHandlers {
    return {
      enter: () => {},
      leave: () => {},
      move: (e) => e.stopPropagation(),
      down: (e) => {
        e.stopPropagation();
        const { op } = this;
        const { data } = e;
        const root = op.getRoot();
        const vp = root.props.session.viewport.snapshot();
        const [x, y] = vp.fromAbsolute(data.absoluteX, data.absoluteY);
        this.resizeData = {
          x,
          y,
          snapshot: op.getSnapshot(),
          corner,
        };
        op.onStart?.();
      },
    };
  }

  hasMoved = false;

  @computed
  get hoverRegisters() {
    const { op } = this;
    const cornersAndSides = getAllowedCornersAndSides(op.getAllowedSides());
    const register = op.getHoverRegister();
    return Array.from(cornersAndSides).map((corner, i) => {
      return {
        ...register,
        priority: (register.priority || 0) + i,
        data: () => {
          const transform = op.getTransform?.();
          const scale = op.getRoot().uiScale;
          const b = op.getBounding();
          const cn = CORNER_XY_NAME[corner];
          const sx = (CORNER_SIGNAL[cn.x] + 1) / 2;
          const sy = (CORNER_SIGNAL[cn.y] + 1) / 2;
          const x = (1 - sx) * b.left + sx * b.right;
          const y = (1 - sy) * b.top + sy * b.bottom;
          const cornerSemiSize = 10 * scale;
          return {
            bbox: new BBox(
              getBoundingGivenCenterAndPadding(x, y, cornerSemiSize),
              transform?.clone().inverse()
            ),
            cursor: () => {
              const degree = transform
                ? solveTransformAsSxSRT(transform.toArray()).θ
                : 0;
              const getCursor =
                corner in INFINITE_BOUNDING ? getEdgeCursor : getCornerCursor;
              return getCursor(degree, CORNERS_CLOCK_WISE.indexOf(corner));
            },
            handlers: this.getResizeHandlers(corner),
          };
        },
      };
    });
  }

  @memorize
  get dragRegister(): PointerEventRegister {
    const data: PointerEventRegisterData = {
      bbox: new BBox(INFINITE_BOUNDING),
      handlers: this.dragHandlers,
      cursor: () => {
        const { resizeData } = this;
        if (!resizeData) return "";
        const { corner } = resizeData;
        const { op } = this;
        const transform = op.getTransform?.()?.toArray();
        const degree = transform ? solveTransformAsSxSRT(transform).θ : 0;
        const index = CORNERS_CLOCK_WISE.indexOf(resizeData.corner);
        const getCursor =
          corner in INFINITE_BOUNDING ? getEdgeCursor : getCornerCursor;
        return getCursor(degree, index);
      },
    };
    return {
      path: EMPTY_ARRAY,
      layer: "cover",
      selected: true,
      data: () => data,
    };
  }

  @memorize
  get dragHandlers(): PointerEventHandlers {
    return {
      move: (e) => {
        if (!this.resizeData) return;

        e.stopPropagation();
        const { data } = e;

        if (!this.hasMoved) {
          this.op
            .getRoot()
            .props.session.ui.isNonRealtimeRenderDisabled.set(
              this._uniqKey,
              true
            );
        }

        this.hasMoved = true;

        this.raf.push(() =>
          runInAction(() => {
            const { resizeData } = this;
            if (!resizeData) return;

            const { corner } = resizeData;

            const horizontal = corner.includes("left")
              ? "start"
              : corner.includes("right")
              ? "end"
              : "";
            const vertical = corner.includes("top")
              ? "start"
              : corner.includes("bottom")
              ? "end"
              : "";

            if (!horizontal && !vertical) return;

            const { op } = this;

            const root = op.getRoot();
            const vp = root.props.session.viewport.snapshot();
            const [x2, y2] = vp.fromAbsolute(data.absoluteX, data.absoluteY);

            const init = resizeData.snapshot;

            const common =
              horizontal && vertical
                ? ({
                    kind: "both",
                    horizontal,
                    vertical,
                  } as const)
                : ({
                    kind: "single",
                    side: horizontal
                      ? horizontal === "start"
                        ? "left"
                        : "right"
                      : vertical === "start"
                      ? "top"
                      : "bottom",
                  } as const);

            const input: GDLLayoutSemantic.ResizeInput = {
              ...common,
              symmetric: data.altKey,
              preserveRatio: data.shiftKey,
              x1: resizeData.x,
              y1: resizeData.y,
              x2,
              y2,
            };

            op.apply(init, input);
          })
        );
      },
      up: (e) => {
        e.stopPropagation();
        this.raf.flush();
        this.resizeData = undefined;
        this.hasMoved = false;
        this.op
          .getRoot()
          .props.session.ui.isNonRealtimeRenderDisabled.delete(this._uniqKey);
        this.op.getRoot().props.session.history.push({ name: "修改对象尺寸" });
        this.op.onEnd?.();
      },
    };
  }
}

function getAllowedCornersAndSides(allowedSides: ResizeAllowedSize) {
  const { horizontal: h, vertical: v, corner: c } = allowedSides;
  if (h) {
    if (v) {
      if (c) {
        return CORNERS_AND_SIDES_ORDERED;
      } else {
        return SIDES_ORDERED;
      }
    } else {
      return ["left", "right"] as const;
    }
  } else {
    if (v) {
      return ["top", "bottom"] as const;
    } else {
      return EMPTY_ARRAY;
    }
  }
}

const CORNER_XY_NAME = {
  left: { x: "left", y: "center" },
  right: { x: "right", y: "center" },
  top: { x: "center", y: "top" },
  bottom: { x: "center", y: "bottom" },
  "top-left": { x: "left", y: "top" },
  "top-right": { x: "right", y: "top" },
  "bottom-right": { x: "right", y: "bottom" },
  "bottom-left": { x: "left", y: "bottom" },
} as const;

const CORNER_SIGNAL = {
  left: -1,
  right: 1,
  top: -1,
  bottom: 1,
  center: 0,
} as const;
