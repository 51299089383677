export const BOUNDING_KEYS: ReadonlyArray<keyof Bounding> = ['left', 'right', 'top', 'bottom']

export type Bounding = {
  readonly left: number
  readonly right: number
  readonly top: number
  readonly bottom: number
}

export type PointingDirection = 'right' | 'left' | 'up' | 'down'

export type BoxLike = XYLike & DimensionLike

export type XYLike = {
  readonly x: number
  readonly y: number
}

export type DimensionLike = {
  readonly width: number
  readonly height: number
}

export const INFINITE_BOUNDING = {
  left: -Infinity,
  right: Infinity,
  top: -Infinity,
  bottom: Infinity,
} as const

export const REVERSE_INFINITE_BOUNDING = {
  left: Infinity,
  right: -Infinity,
  top: Infinity,
  bottom: -Infinity,
} as const

export const HV_KEYS = ['horizontal', 'vertical'] as const

export const HV_INDEXES = {
  horizontal: 0,
  vertical: 1,
} as const

export const XY_KEYS = ['x', 'y'] as const

export const WH_KEYS = ['width', 'height'] as const
