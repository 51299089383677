import * as React from "react";
import { useObserver } from "mobx-react";
import { runInAction } from "mobx";

import type { Nullable } from "@chuyuan/poster-utils";

import { DuplexFieldLike } from "../../../utils/multi-value";
import { getCheckboxProps } from "../../../utils/react-multi-value";
import {
  Checkbox,
  CheckboxProps,
  CheckboxRef,
} from "../../ui-components/checkbox";
import {
  ButtonRef,
  ButtonProps,
  Button,
  SquareButton,
} from "../../ui-components/button";
import { EditorContext } from "../../helpers/react-context";
import { SessionState } from "../../editor-state/session-state";

export type BooleanHistoryName = string | ((newValue: boolean) => string);
export type BooleanOnChange = (
  value: boolean
) => boolean | ((cb: (changed: boolean) => void) => void);

export interface BooleanValueCommonProps {
  readonly historyName: BooleanHistoryName;
  readonly onChange?: BooleanOnChange;
}

export type CheckboxValueRef = CheckboxRef;

export interface CheckboxValueProps
  extends Omit<CheckboxHistoryProps, "value"> {
  readonly field: DuplexFieldLike<boolean>;
}

export const CheckboxValue = React.memo(
  React.forwardRef<CheckboxValueRef, CheckboxValueProps>((props, ref) => {
    const { field, ...rest } = props;

    return useObserver(() => {
      const fieldProps = getCheckboxProps(field);
      return (
        <CheckboxHistory
          {...fieldProps}
          {...rest}
          ref={ref}
          onChange={(newValue) =>
            runInAction(() => field.set(newValue).records.some(Boolean))
          }
        />
      );
    });
  })
);
CheckboxValue.displayName = "CheckboxValue";

export type CheckboxHistoryRef = CheckboxRef;

export interface CheckboxHistoryProps
  extends Omit<CheckboxProps, "onChange">,
    BooleanValueCommonProps {}

export const CheckboxHistory = React.forwardRef<
  CheckboxHistoryRef,
  CheckboxHistoryProps
>((props, ref) => {
  const { historyName: _0, ...rest } = props;

  const { session } = React.useContext(EditorContext);

  const state = useBooleanCommonState(session, props);

  return <Checkbox {...rest} ref={ref} onChange={state.onChange} />;
});
CheckboxHistory.displayName = "CheckboxHistory";

export type ButtonBooleanValueRef = ButtonBooleanHistoryRef;

export interface ButtonBooleanValueProps extends ButtonBooleanHistoryProps {
  readonly field: DuplexFieldLike<boolean>;
  readonly props?: (
    value: CheckboxProps["checked"]
  ) => Nullable<Partial<ButtonBooleanProps>>;
}

export const ButtonBooleanValue = React.memo(
  React.forwardRef<ButtonBooleanValueRef, ButtonBooleanValueProps>(
    (props, ref) => {
      const { field, props: reactiveProps, onChange, ...rest } = props;

      return useObserver(() => {
        const fieldProps = getCheckboxProps(field);
        return (
          <ButtonBooleanHistory
            {...fieldProps}
            {...rest}
            {...(reactiveProps && reactiveProps(fieldProps.checked))}
            ref={ref}
            onChange={(newValue) =>
              runInAction(() => {
                if (onChange) return onChange(newValue);
                return field.set(newValue).records.some(Boolean);
              })
            }
          />
        );
      });
    }
  )
);
ButtonBooleanValue.displayName = "ButtonBooleanValue";

export type ButtonBooleanHistoryRef = ButtonBooleanRef;

export interface ButtonBooleanHistoryProps
  extends Omit<ButtonBooleanProps, "onChange">,
    BooleanValueCommonProps {}

export const ButtonBooleanHistory = React.forwardRef<
  ButtonBooleanHistoryRef,
  ButtonBooleanHistoryProps
>((props, ref) => {
  const { historyName: _0, ...rest } = props;

  const { session } = React.useContext(EditorContext);

  const state = useBooleanCommonState(session, props);

  return <ButtonBoolean {...rest} ref={ref} onChange={state.onChange} />;
});
ButtonBooleanHistory.displayName = "ButtonBooleanHistory";

export type ButtonBooleanRef = ButtonRef;

export interface ButtonBooleanProps
  extends Omit<CheckboxProps, "children">,
    Omit<ButtonProps, "onChange" | "value" | "children"> {
  readonly square?: boolean;
  readonly children?: (value: CheckboxProps["checked"]) => React.ReactNode;
}

export const ButtonBoolean = React.forwardRef<
  ButtonBooleanRef,
  ButtonBooleanProps
>((props, ref) => {
  const {
    square,
    checked,
    onChange,
    intermediateNextValue: _2,
    children,
    ...rest
  } = props;

  const Component = square ? SquareButton : Button;

  return (
    <Component
      type="light"
      color={checked ? "primary" : undefined}
      {...rest}
      ref={ref}
      onClick={() => {
        const intermediateNextValue = props.intermediateNextValue ?? true;
        const newValue =
          checked === "intermediate" ? intermediateNextValue : !checked;
        props.onChange?.(newValue);
      }}
      children={
        children
          ? children(checked)
          : checked === "intermediate"
          ? "?"
          : checked
          ? "√"
          : "×"
      }
    />
  );
});
ButtonBoolean.displayName = "ButtonBoolean";

function useBooleanCommonState<T extends BooleanValueCommonProps>(
  inputSession: SessionState,
  inputProps: T
) {
  type State = Required<Pick<CheckboxProps, "onChange">> & {
    session: SessionState;
    props: T;
  };

  const stateRef = React.useRef<State>();

  const state: State =
    stateRef.current ||
    (stateRef.current = {
      session: inputSession,
      props: inputProps,
      onChange: (newValue) =>
        runInAction(() => {
          const { props, session } = state;
          const ret = props.onChange?.(newValue);
          if (!ret) return;
          const run = () =>
            runInAction(() => {
              const fn = props.historyName;
              const historyName = typeof fn === "function" ? fn(newValue) : fn;
              session.history.push({ name: historyName });
            });
          if (typeof ret === "function") {
            ret((changed) => changed && run());
          } else {
            run();
          }
        }),
    });

  state.session = inputSession;

  state.props = inputProps;

  return state;
}
