import * as React from "react";
import styled from "styled-components";
import { Image as AntImage, Pagination, Spin } from "antd";
import { Frame, Image } from "@chuyuan/poster-data-access-layer";
import { i18n } from "../../utils/i18n";
import { SessionState } from "../editor-state/session-state";
import { getImageLayoutData } from "../gdl-common/add-content";
import type { PaginationProps } from "antd";

/**@name 共享资源中心素材 */
export const ResourcesMaterialLayout = React.memo(
  (props: { readonly session: SessionState; readonly root: Frame }) => {
    const { session } = props;
    const { api } = session.editor;
    const pageSize = 20;

    const [dataSource, setDataSource] = React.useState([]);
    const [total, setTotal] = React.useState(0);
    const [current, setCurrent] = React.useState(1);
    const [loading, setLoading] = React.useState(true);

    const init = async () => {
      let result: any = [];
      if (api.resourceMaterialList) {
        // 首次调用
        result = await api.resourceMaterialList({
          pageNum: 1,
          pageSize,
        });
        setDataSource(result?.rows);
        setTotal(result?.total);
        setLoading(false);
      }
    };

    const onChange: PaginationProps["onChange"] = async (page) => {
      setCurrent(page);
      setLoading(true);
      let result: any = [];
      if (api.resourceMaterialList) {
        result = await api.resourceMaterialList({
          pageNum: page,
          pageSize,
        });
        setDataSource(result?.rows);
        setLoading(false);
      }
    };

    React.useEffect(() => {
      init();
    }, []);

    return (
      <Wrapper>
        <FixedModeTitle />
        <FixedDataSource
          dataSource={dataSource}
          session={session}
          loading={loading}
        />
        <Pagination
          simple
          defaultCurrent={1}
          current={current}
          onChange={onChange}
          total={total}
        />
      </Wrapper>
    );
  }
);
ResourcesMaterialLayout.displayName = "ResourcesMaterialLayout";

const FixedDataSource = React.memo(
  (props: {
    readonly dataSource: any[];
    readonly session: SessionState;
    readonly loading: boolean;
  }) => {
    const { dataSource, session } = props;
    const { selection } = session;
    const selectedNodes = Array.from(selection);
    const nodes = selectedNodes.length ? selectedNodes : [session.root];

    const getAddTargetsMap = () => {
      // 如果有选中的容器, 直接从选中的容器里面创建
      // 1. 未选中的时候默认选中画布
      // 2. 同一个children只能有一个创建位点
      // 3. 位点取选中元素index的最小值, 如果选中了容器, 则为0

      const addTargetsMap = new Map<Frame, number>();
      for (const node of nodes) {
        if (node.kind === "frame") {
          addTargetsMap.set(node, 0);
        } else {
          const parent = node.parent();
          if (parent) {
            const currIndex = addTargetsMap.get(parent);
            const myIndex = parent.children.indexOf(node);
            if (myIndex >= 0) {
              if (typeof currIndex === "number") {
                if (myIndex < currIndex) {
                  addTargetsMap.set(parent, myIndex);
                }
              } else {
                addTargetsMap.set(parent, myIndex);
              }
            } else {
              addTargetsMap.set(parent, 0);
            }
          }
        }
      }
      return addTargetsMap;
    };

    const onSelect = async (item: any) => {
      const { fileUrl, fileName } = item;
      const { api } = session.editor;
      if (api.callMaterialSelector) {
        api.callMaterialSelector({
          onData: (data: any) => {
            if (data) {
              const inputs = Array.from(getAddTargetsMap().entries()).map(
                ([parent, index]) => ({ parent, index })
              );
              const layers = [];
              for (const { parent } of inputs) {
                const layer = new Image(session.ds, {
                  content: {
                    url: data.url,
                  },
                  layout: getImageLayoutData(
                    parent.layout.contentBox,
                    data.dimension
                  ),
                });
                parent.setChildren([layer, ...parent.children]);
                layers.push(layer);
              }
              session.ds.imageResources.resolve(data.url, data);
              session.selection.replace(...layers);
              session.history.push({ name: "增加图片内容" });
            }
          },
          fileUrl: fileUrl,
          fileName: fileName,
        });
      }
    };

    return (
      <FixedListDOM>
        {props.loading && <Spin tip="Loading..." />}
        {dataSource &&
          dataSource.map((item: any) => {
            return (
              <Item
                onClick={() => {
                  onSelect(item);
                }}
              >
                <AntImage
                  height={130}
                  width={150}
                  preview={false}
                  src={item.fileUrl}
                />
              </Item>
            );
          })}
      </FixedListDOM>
    );
  }
);

const FixedModeTitle = React.memo(() => {
  return (
    <ModeTabs>
      <ModeTab className="selected">
        <共享资源素材 />
      </ModeTab>
    </ModeTabs>
  );
});
FixedModeTitle.displayName = "FixedModeTitle";

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  direction: ltr;
  background: #fff;
  transition: all 1s ease-in-out;
  border-right: 1px solid #eae9e8;
  .ant-pagination {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    background: #fff;
    height: 40px;
  }
`;

const ModeTabs = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  padding: 4px;
  display: flex;
  height: 66px;
  align-items: center;
  border-bottom: 1px solid #eae9e8;

  &.center {
    justify-content: center;
  }
`;

const ModeTab = styled.div`
  padding: 0 8px;
  text-align: center;
  color: #a0a0a0;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  &.selected {
    color: #202020;
    font-weight: bold;
  }
`;

const FixedListDOM = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 10px;
  gap: 12px;
  overflow-y: auto;
  .ant-spin {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding-top: 50px;
  }
`;

const Item = styled.div`
  cursor: pointer;
  .ant-image-img {
    object-fit: contain;
    background-color: #d9d9d9;
  }
`;

const 共享资源素材 = i18n({
  en: () => "Shared resource material",
  zh: () => "共享资源素材",
});
