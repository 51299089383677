import * as React from 'react'

import { OpacityBlock } from './opacity'
import { useEqualSet } from '../../utils/react-hooks'
import { AppearanceFields } from './state.ui'
import { BorderRadiusBlock } from './border-radius'
import { BackgroundFillBlock } from './background-fill'
import { getSelectionFilter } from '../helpers/selection-filter'
import { EditorContext } from '../helpers/react-context'
import { SelectableTarget } from '../editor-state/types'

export const StylePanelDefaultBlocks = React.memo((props: {
  readonly targets: ReadonlySet<SelectableTarget>
}) => {
  const { session } = React.useContext(EditorContext)
  const fields = AppearanceFields.of(useEqualSet(props.targets))
  const filter = getSelectionFilter(session.root, props.targets)
  if (!filter.set.size) return null

  const hasElementsOnly = filter.elementsOnly.size === filter.set.size
  const hasCanvasAndSymbolOnly = filter.rootOnly.size === filter.set.size

  return (
    <>
      {filter.textsOnly.size ? null : <BorderRadiusBlock fields={fields} />}
      {hasElementsOnly ? <OpacityBlock value={fields.opacity} /> : null}
      {hasCanvasAndSymbolOnly ? <BackgroundFillBlock fields={fields} /> : null}
    </>
  )
})
StylePanelDefaultBlocks.displayName = 'StylePanelDefaultBlocks'
