import * as React from "react";
import { isEqual } from "lodash";
import {
  isEqualShallow,
  EMPTY_ARRAY,
  isSetEqual,
  dethunk,
  MaybePromise,
  identity,
  defineHiddenReadonly,
  Nullable,
  EventEmitter,
  noop,
  isAsyncIterator,
} from "@chuyuan/poster-utils";
import { Raf } from "./raf";

export function useBoxedValue<T>(x: T) {
  const ref = React.useRef(x);
  ref.current = x;
  return ref;
}

export function useMutableValue<T>(x: T) {
  const [value, setValue] = React.useState(x);
  const ref = React.useRef(value);
  ref.current = value;
  return {
    get: () => ref.current,
    set: (value: T) => {
      ref.current = value;
      setValue(value);
    },
  };
}

export function useOverrideState<T>(
  propsValue: T,
  onChange?: (prev: T, next: T) => unknown
) {
  const ref = React.useRef(propsValue);
  const state = React.useState(propsValue);
  let value = state[0];
  if (ref.current !== propsValue) {
    if (onChange) {
      onChange(ref.current, propsValue);
    }
    ref.current = propsValue;
    state[1](propsValue);
    value = propsValue;
  }
  return [value, state[1]] as const;
}

export function useChanged<T>(value: T) {
  const ref = React.useRef(value);
  const oldValue = ref.current;
  if (oldValue !== value) {
    ref.current = value;
    return true;
  }
  return false;
}

export function useChangedValue<T>(value: T) {
  const ref = React.useRef(value);
  const oldValue = ref.current;
  if (oldValue !== value) {
    ref.current = value;
    return { oldValue, newValue: value };
  }
  return undefined;
}

export function useEqualSet<T>(value: ReadonlySet<T>) {
  return useEqualCache(value, isSetEqual);
}

export function useEqualCache<T>(value: T, equals: (a: T, b: T) => boolean) {
  const ref = React.useRef(value);
  if (!equals(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

export function useShallowEqualCache<T>(value: T) {
  const ref = React.useRef(value);
  if (!isEqualShallow(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

export function useDeepEqualCache<T>(value: T) {
  const ref = React.useRef(value);
  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

export function useDraftInput<T>(
  inputValue: T,
  emitChange: (value: T) => void
) {
  const inputValueRef = React.useRef(inputValue);
  const [draftValue, setDraftValue] = React.useState(inputValue);
  const draftValueBox = useBoxedValue(draftValue);
  const emitChangeBox = useBoxedValue(emitChange);

  const onSetValue = React.useMemo(
    () => (value: T) => {
      draftValueBox.current = value;
      setDraftValue(value);
    },
    []
  );

  const onBlur = React.useMemo(
    () => () => {
      const oldValue = inputValueRef.current;
      const newValue = draftValueBox.current;
      if (newValue === oldValue) return;
      emitChangeBox.current(newValue);
    },
    []
  );

  if (inputValueRef.current !== inputValue) {
    inputValueRef.current = inputValue;
    setDraftValue(inputValue);
  }

  return { value: draftValue, setValue: onSetValue, onBlur };
}

export type HooksFetcherLike<T, D = undefined> = HooksFetcherCommon<T> &
  (HooksFetcherPending<D> | HooksFetcherResolved<T> | HooksFetcherRejected<D>);

export interface HooksFetcherPending<T = undefined> {
  readonly state: "pending";
  readonly result: T;
  readonly error: undefined;
}

export interface HooksFetcherResolved<T> {
  readonly state: "resolved";
  readonly result: T;
  readonly error: undefined;
}

export interface HooksFetcherRejected<T = undefined> {
  readonly state: "rejected";
  readonly result: T;
  readonly error: unknown;
}

export interface HooksFetcherCommon<T> extends Pick<PromiseLike<T>, "then"> {}

export type FetcherReturnType<T> =
  | T
  | { [Symbol.asyncIterator](): AsyncIterator<unknown, T, void | undefined> };

class FetcherRequestData<T> {
  private readonly _request!: FetcherRequest<T>;

  constructor(
    request: FetcherRequest<T>,
    readonly state: "pending" | "resolved" | "rejected",
    readonly result?: T,
    readonly error?: unknown
  ) {
    defineHiddenReadonly(this, "_request", request);
  }

  get then() {
    const { promise } = this._request;
    return promise.then.bind(promise);
  }
}

class FetcherRequest<T> {
  data: FetcherRequestData<T>;

  disposed?: boolean;

  readonly promise: Promise<T>;

  constructor(
    factory: () => MaybePromise<FetcherReturnType<T>>,
    defaultValue?: T
  ) {
    this.data = new FetcherRequestData(this, "pending", defaultValue);
    this.promise = this.run(factory);
  }

  dispose() {
    this.disposed = true;
  }

  async run(factory: () => MaybePromise<FetcherReturnType<T>>): Promise<T> {
    try {
      const ret = await factory();
      if (isAsyncIterator(ret)) {
        const gen = ret[Symbol.asyncIterator]();
        while (true) {
          if (this.disposed) {
            gen.return?.();
            throw new Error("disposed");
          }
          const itr = await gen.next();
          if (itr.done) {
            this.data = new FetcherRequestData(this, "resolved", itr.value);
            return itr.value;
          }
        }
      } else {
        this.data = new FetcherRequestData(this, "resolved", ret);
        return ret;
      }
    } catch (e) {
      this.data = new FetcherRequestData(this, "rejected", undefined, e);
      throw e;
    }
  }
}

export function useFetcherLike<T>(
  deps: readonly unknown[] | undefined,
  factory: () => MaybePromise<FetcherReturnType<T>>
): HooksFetcherLike<T>;
export function useFetcherLike<T>(
  deps: readonly unknown[] | undefined,
  factory: () => MaybePromise<FetcherReturnType<T>>,
  defaultValue: T | (() => T)
): HooksFetcherLike<T, T>;
export function useFetcherLike<T>(
  deps: readonly unknown[] | undefined,
  factory: () => MaybePromise<FetcherReturnType<T>>,
  defaultValue?: T | (() => T)
): HooksFetcherLike<T> {
  const setCount = React.useState(0)[1];

  type State = {
    mounted?: boolean;
    deps?: readonly unknown[];
    request?: FetcherRequest<T>;
    readonly effect: () => () => void;
  };

  const stateRef = React.useRef<State>();
  const state: State =
    stateRef.current ||
    (stateRef.current = {
      effect: () => {
        state.mounted = true;
        return () => {
          state.mounted = false;
        };
      },
    });

  React.useEffect(state.effect, EMPTY_ARRAY);

  let { request } = state;

  if (request) {
    if (!isEqualShallow(state.deps, deps)) {
      request.dispose();
      state.deps = deps;
      request = state.request = new FetcherRequest<T>(
        factory,
        dethunk(defaultValue)
      );
      request.promise.catch(identity).then(() => {
        if (state.mounted === false) return;
        if (state.request === request) {
          setCount(increase);
        }
      });
    }
  } else {
    request = state.request = new FetcherRequest<T>(
      factory,
      dethunk(defaultValue)
    );
    state.deps = deps;
    request.promise.catch(identity).then(() => {
      if (state.mounted === false) return;
      if (state.request === request) {
        setCount(increase);
      }
    });
  }

  return request.data as HooksFetcherLike<T>;
}

function increase(x: number) {
  return x + 1;
}

export function useResetKey(id: unknown): number {
  const keyRef = React.useRef(0);
  const idRef = React.useRef(id);
  if (idRef.current !== id) {
    idRef.current = id;
    keyRef.current = ~keyRef.current;
  }
  return keyRef.current;
}

export function useForceUpdate() {
  const update = React.useState(false)[1];
  const cbRef = React.useRef<() => void>();
  return cbRef.current || (cbRef.current = () => update(oppositeBoolean));
}

function oppositeBoolean(x: boolean) {
  return !x;
}

export function typedMemo<T extends React.FunctionComponent<any>>(
  Component: T,
  propsAreEqual?: (
    prevProps: Readonly<React.PropsWithChildren<ComponentProps<T>>>,
    nextProps: Readonly<React.PropsWithChildren<ComponentProps<T>>>
  ) => boolean
): T & { displayName?: string } {
  const component = React.memo<ComponentProps<T>>(
    Component,
    propsAreEqual
  ) as unknown as T & { displayName?: string };
  component.displayName = Component.displayName;
  return component;
}

type ComponentProps<T> = T extends React.FunctionComponent<infer U> ? U : {};

export class HooksSubscription<T> {
  readonly listeners = new Set<(value: T) => unknown>();

  emit(value: T) {
    for (const listener of this.listeners) {
      listener(value);
    }
  }

  listen(setState: (state: T) => unknown) {
    const listeners = this.listeners;
    listeners.add(setState);
    return () => {
      listeners.delete(setState);
    };
  }
}

export function useHooksSubscription<T>(
  sub: HooksSubscription<T>,
  initValue: T
) {
  const [data, setData] = React.useState(initValue);
  React.useEffect(() => sub.listen(setData), [sub]);
  return data;
}

export function useHooksSubscriptionList<T>(
  sub: HooksSubscription<readonly T[]>,
  obs: HooksObservation<T>
) {
  const [data, setData] = React.useState(obs.values);
  React.useEffect(() => {
    if (obs.values !== data) setData(obs.values);
    return sub.listen(setData);
  }, [obs, sub]);
  return data;
}

export class HooksObservableValue<T> extends HooksSubscription<T> {
  constructor(protected _value: T) {
    super();
  }

  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.emit(value);
  }
}

export function createHooksObservableValue<T>(
  x: T | (() => T)
): HooksObservableValue<T>;
export function createHooksObservableValue<
  T = undefined
>(): HooksObservableValue<T | undefined>;
export function createHooksObservableValue<T>(x?: T | (() => T)) {
  const observable = React.useMemo(
    () =>
      new HooksObservableValue(typeof x === "function" ? (x as Function)() : x),
    EMPTY_ARRAY
  );
  useHooksObservableValue(observable);
  return observable;
}

export function useHooksObservableValue<T>(
  observableValue: HooksObservableValue<T>
) {
  const [, setValue] = React.useState(observableValue.value);
  React.useEffect(() => observableValue.listen(setValue), [observableValue]);
  return observableValue.value;
}

export class HooksObservation<T> {
  readonly observers = new Set<HooksObserver<T>>();

  _values?: readonly T[];

  constructor(readonly emit: (obs: HooksObservation<T>) => unknown) {}

  get values() {
    return this._values || (this._values = this._getValues());
  }

  create(props: T) {
    const observer = new HooksObserver<T>(this, props);
    this.observers.add(observer);
    this._values = undefined;
    this.broadcast();
    return observer;
  }

  broadcast() {
    this.emit(this);
  }

  private _getValues() {
    const ret: T[] = [];
    for (const observer of this.observers) {
      ret.push(observer.data);
    }
    return ret;
  }
}

export class HooksObserver<T> {
  disposed = false;

  constructor(readonly observation: HooksObservation<T>, public data: T) {}

  update(data: T) {
    if (isEqual(data, this.data)) return;
    this.data = data;
    this.observation.broadcast();
  }

  dispose() {
    if (this.disposed) return;
    this.disposed = true;
    const { observation } = this;
    observation.observers.delete(this);
    observation._values = undefined;
    observation.broadcast();
  }
}

export function useRaf() {
  const stateRef = React.useRef<{
    readonly raf: Raf;
    readonly effect: () => () => void;
  }>();
  const state =
    stateRef.current ||
    (stateRef.current = {
      raf: new Raf(),
      effect: () => () => {
        state.raf.flush();
      },
    });
  React.useEffect(state.effect, EMPTY_ARRAY);
  return state.raf;
}

export type UseState<T> = readonly [T, React.Dispatch<React.SetStateAction<T>>];

export function syncForwardedRef<T>(
  forwardedRef: React.ForwardedRef<T>,
  ref: T | null
) {
  if (forwardedRef) {
    if (typeof forwardedRef === "function") {
      forwardedRef(ref);
    } else if (typeof forwardedRef === "object") {
      forwardedRef.current = ref;
    }
  }
}

export class Refs<T> {
  ref: T | null = null;

  private _bound?: Set<NonNullable<React.ForwardedRef<T>>>;
  private _events?: EventEmitter<{ change(ref: T | null): void }>;
  private _setState?: UseState<T | null>[1];

  /**
   * ref 更新时触发重渲染
   */
  useChanges() {
    this._setState = React.useState<T | null>(null)[1];
    return this;
  }

  /**
   * 绑定一个 forwardedRef, 如果没有添加过则添加
   * @param forwardedRef
   */
  put(forwardedRef: Nullable<React.ForwardedRef<T>>) {
    if (!forwardedRef) return this;
    const set = this._bound || (this._bound = new Set());
    if (set.has(forwardedRef)) return this;
    set.add(forwardedRef);
    syncForwardedRef(forwardedRef, this.ref);
    return this;
  }

  /**
   * ref 变化时触发回调函数
   * @param cb 回调函数
   */
  listen(cb: (ref: T | null) => void) {
    const events = this._events || (this._events = new EventEmitter());
    return events.subscribe("change", cb);
  }

  /**
   * 当 ref 不为 null 时触发回调
   * @param cb
   */
  ensure(cb: (ref: T) => void) {
    const { ref } = this;
    if (ref) {
      cb(ref);
      return noop;
    } else {
      return this.listen((r) => r && cb(r));
    }
  }

  /**
   * 传递给 <component ref={getRef} /> 的函数
   */
  getRef = (ref: T | null) => {
    if (this.ref === ref) return;
    this.ref = ref;
    this._setState?.(ref);
    const bound = this._bound;
    if (bound) {
      for (const forwardedRef of bound) {
        syncForwardedRef(forwardedRef, ref);
      }
    }
    const events = this._events;
    if (events) {
      events.emit("change", ref);
    }
  };
}

/**
 * 同一个 ref 同步到多个源
 * - 如果想要得到 ref, 又
 */
export function useRefs<T>() {
  const refsRef = React.useRef<Refs<T>>();
  return refsRef.current || (refsRef.current = new Refs());
}

export class ObservableState<T> {
  /**
   * @internal
   */
  private _value: T;

  /**
   * @internal
   */
  private _listeners = new Set<(value: T) => void>();

  constructor(initialValue: T) {
    this._value = initialValue;
  }

  use() {
    const setValue = React.useState(this._value)[1];
    React.useEffect(() => {
      this._listeners.add(setValue);
      setValue(this._value);
      return () => {
        this._listeners.delete(setValue);
      };
    }, [this]);
    return this;
  }

  listen(cb: (value: T) => void) {
    const fn = (value: T) => cb(value);
    this._listeners.add(fn);
    return () => {
      this._listeners.delete(fn);
    };
  }

  get() {
    return this._value;
  }

  set(value: T) {
    if (Object.is(this._value, value)) return;
    this._value = value;
    for (const setState of this._listeners) {
      setState(value);
    }
  }
}

export function useObservableState<T>(initialValue: T | (() => T)) {
  const stateRef = React.useRef<ObservableState<T>>();
  return (
    stateRef.current ||
    (stateRef.current = new ObservableState(dethunk(initialValue)))
  );
}
