import * as React from "react";
import { Panel } from "../ui-components/panel";
import { Row, Square, Gap } from "../ui-components/section";
import { i18n } from "../../utils/i18n";
import { LayoutFields } from "./state.ui";
import { SelectValue } from "../ui-components-stateful/enum";
import { ConstraintValueType } from "./state.layout.child";
import { SelectItemProps } from "../ui-components/select";
import {
  SizeConstraintH,
  SizeConstraintV,
} from "../../../../poster-ui/icons/new-icons";

/** @name 约束面板*/
export const LayoutConstraintPanel = React.memo(
  (props: { readonly fields: LayoutFields }) => {
    const { fields } = props;

    const { targets } = fields;

    if (!targets.length) return null;

    const { width, height } = fields.constraints.dimension;

    return (
      <Panel title={<约束 />}>
        <Row className="margin-y">
          <Square>
            <SizeConstraintH />
          </Square>
          <Gap />
          <Row>
            <SelectValue
              field={width.value}
              filter={width.possibleValues}
              historyName="横向约束"
              options={ConstraintValueHorizontalOptions}
            />
          </Row>
        </Row>
        <Row className="margin-y">
          <Square>
            <SizeConstraintV />
          </Square>
          <Gap />
          <Row>
            <SelectValue
              field={height.value}
              filter={height.possibleValues}
              historyName="纵向约束"
              options={ConstraintValueVerticalOptions}
            />
          </Row>
        </Row>
      </Panel>
    );
  }
);
LayoutConstraintPanel.displayName = "LayoutConstraintPanel";

const ConstraintValueHorizontalOptions: ReadonlyArray<
  SelectItemProps<ConstraintValueType>
> = [
  {
    value: "start",
    label: () => <左 />,
  },
  {
    value: "end",
    label: () => <右 />,
  },
  {
    value: "center",
    label: () => <水平居中 />,
  },
  {
    value: "stretch",
    label: () => <水平拉伸 />,
  },
  {
    value: "scale",
    label: () => <水平缩放 />,
  },
];

const ConstraintValueVerticalOptions: ReadonlyArray<
  SelectItemProps<ConstraintValueType>
> = [
  {
    value: "start",
    label: () => <上 />,
  },
  {
    value: "end",
    label: () => <下 />,
  },
  {
    value: "center",
    label: () => <垂直居中 />,
  },
  {
    value: "stretch",
    label: () => <垂直拉伸 />,
  },
  {
    value: "scale",
    label: () => <垂直缩放 />,
  },
];

const 约束 = i18n({
  en: () => "Constraint",
  zh: () => "约束",
});

const 左 = i18n({
  en: () => "Left",
  zh: () => "左",
});

const 右 = i18n({
  en: () => "Right",
  zh: () => "右",
});

const 上 = i18n({
  en: () => "Top",
  zh: () => "上",
});

const 下 = i18n({
  en: () => "Bottom",
  zh: () => "下",
});

const 水平居中 = i18n({
  en: () => "Horizontal Center",
  zh: () => "水平居中",
});

const 垂直居中 = i18n({
  en: () => "Vertical Center",
  zh: () => "垂直居中",
});

const 水平拉伸 = i18n({
  en: () => "Horizontal Stretch",
  zh: () => "水平拉伸",
});

const 垂直拉伸 = i18n({
  en: () => "Vertical Stretch",
  zh: () => "垂直拉伸",
});

const 水平缩放 = i18n({
  en: () => "Horizontal Scale",
  zh: () => "水平缩放",
});

const 垂直缩放 = i18n({
  en: () => "Vertical Scale",
  zh: () => "垂直缩放",
});
