import * as React from "react";
import styled from "styled-components";
import { useObserver } from "mobx-react";
import { runInAction } from "mobx";

import type { TextSpec } from "@chuyuan/poster-data-structure";
import { AOutlined, SkewXIcon, SkewYIcon } from "../../../../poster-ui/icons/new-icons";
import { MaybeThunk, dethunk, typedEntriesOf, typedKeysOf } from "@chuyuan/poster-utils";

import { i18n, useLocale, I18N, Locale } from "../../utils/i18n";
import { Panel } from "../ui-components/panel";
import type { BasicTextStyleFields, AdvancedEffectLayerFields } from "./state.ui";
import { Block, Row, Gap, TitleText, Square } from "../ui-components/section";
import { DuplexFieldLike, MapDuplexField, DuplexLike } from "../../utils/multi-value";
import { InputNumber } from "../ui-components/input-number";
import { Tooltip, LEFT_ANCHORS } from "../ui-components/tooltip";
import type { EditTextStylePanelContentProps } from "./edit-style-panel";
import { FillingPaintValue, NullableFillingPaint, FillingPaintValueProps, FieldsOptions } from "../ui-components-stateful/filling-paint-text-spec";
import { SingleSlider } from "../ui-components/slider";
import { useRaf } from "../../utils/react-hooks";
import { SkewLongValue, AngleShortValue, GTE0LongValue } from "../ui-components-stateful/number";
import { XY_KEYS } from "../helpers/misc";
import { EditorContext } from "../helpers/react-context";
import { getInputProps } from "../../utils/react-multi-value";

export interface LayerStylePanelProps extends EditTextStylePanelContentProps {
  readonly fields: BasicTextStyleFields;
}

type Layer = {
  kind: "self" | "offset";
  index: number;
  layer: AdvancedEffectLayerFields;
};

type ColorItem = {
  field: DuplexLike<NullableFillingPaint>;
  options: FieldsOptions;
  title: React.ReactNode;
  supportedTypes?: FillingPaintValueProps["supportedTypes"];
};

type ColorItems = {
  fill: ColorItem[];
  stroke: ColorItem[];
  shadow: ColorItem[];
};

type StrokeWidthItem = {
  field: DuplexFieldLike<number>;
  title: React.ReactNode;
  historyName: string;
  unit: "em" | "px";
};

const LayerPaintList = [
  { type: "fill", key: "fillPaint" },
  { type: "stroke", key: "strokePaint" },
  { type: "shadow", key: "shadowPaint" },
] as const;

export const LayerStylePanel = React.memo((props: LayerStylePanelProps) => {
  const { fields } = props;

  const locale = useLocale();

  return useObserver(() => {
    const offsetLayers: Layer[] = [];
    const offsetLayersLength = fields.offsetLayersLength.get() || 0;
    for (let i = 0; i < offsetLayersLength; i++) {
      offsetLayers.push({
        kind: "offset",
        index: i,
        layer: fields.getAdvancedEffectLayerFields(fields.getOffsetLayerWithCache(i)),
      });
    }
    const layers: Layer[] = [
      {
        kind: "self",
        index: 0,
        layer: fields.getAdvancedEffectLayerFields(fields.selfLayerWithCache),
      },
      ...offsetLayers,
    ];

    const colorItems: ColorItems = {
      fill: [],
      stroke: [],
      shadow: [],
    };

    const strokeWidthItems: StrokeWidthItem[] = [];

    for (const { kind, index, layer } of layers) {
      console.log("layer==========", { layer, kind, index });
      const title = kind === "self" ? 自身渲染层.get(locale) : 偏移层.get(locale, { no: index + 1 });
      for (const { type, key } of LayerPaintList) {
        const field = layer[key];
        const options = layer[`${key}Options`];
        colorItems[type].push({ field, options, title });
      }
      {
        const field = layer.strokeWidth;
        strokeWidthItems.push({ field, title, historyName: title, unit: "em" });
      }
    }

    applyBackgrounds({ fields, locale, colorItems, strokeWidthItems });

    return (
      <Wrapper>
        {renderColorBlock(colorItems)}

        {renderStrokeWidthBlock(strokeWidthItems)}

        {!offsetLayers.length ? null : (
          <Panel title={<偏移距离 />}>
            {offsetLayers.map(({ index, layer }, i) => {
              return (
                <Tooltip key={i} content={<偏移层 no={index + 1} />} anchors={LEFT_ANCHORS}>
                  <Row className="margin-y">
                    <AngleShortValue
                      field={layer.offsetAngle}
                      historyName={() => {
                        const n = 偏移层.get("zh", { no: index + 1 });
                        return `${n}偏移角度`;
                      }}
                    />
                    <Gap />
                    <EmValueLong
                      field={layer.offsetDistance}
                      max={100}
                      historyName={() => {
                        const n = 偏移层.get("zh", { no: index + 1 });
                        return `${n}偏移距离`;
                      }}
                    />
                  </Row>
                </Tooltip>
              );
            })}
          </Panel>
        )}

        {!layers.length ? null : (
          <Panel title={<投影 />}>
            {layers.map(({ kind, index, layer }, i) => {
              return (
                <Tooltip key={i} content={kind === "self" ? <自身渲染层 /> : <偏移层 no={index + 1} />} anchors={LEFT_ANCHORS}>
                  <Row className="margin-y">
                    <AngleShortValue
                      field={layer.shadowAngle}
                      historyName={() => {
                        const n = 偏移层.get("zh", { no: index + 1 });
                        return `${n}投影角度`;
                      }}
                    />
                    <Gap />
                    <EmValueShort
                      field={layer.shadowOffset}
                      historyName={() => {
                        const n = 偏移层.get("zh", { no: index + 1 });
                        return `${n}投影偏移`;
                      }}
                    />
                    <Gap />
                    <EmValueShort
                      field={layer.shadowBlurRadius}
                      historyName={() => {
                        const n = 偏移层.get("zh", { no: index + 1 });
                        return `${n}投影模糊半径`;
                      }}
                    />
                  </Row>
                </Tooltip>
              );
            })}
            <Row className="margin-y">
              <Row className="auto square center">
                <TitleText>
                  <角度 />
                </TitleText>
              </Row>
              <Gap />
              <Row className="center">
                <TitleText>
                  <偏移 />
                </TitleText>
              </Row>
              <Gap />
              <Row className="center">
                <TitleText>
                  <模糊 />
                </TitleText>
              </Row>
            </Row>
          </Panel>
        )}

        <Panel title={<斜切 />}>
          {XY_KEYS.map((key) => {
            return (
              <Row key={key} className="margin-y">
                <Square>{key === "x" ? <SkewXIcon /> : <SkewYIcon />}</Square>
                <Gap />
                <SkewLongValue field={fields.skew[key]} historyName={key === "x" ? "横向斜切" : "纵向斜切"} />
              </Row>
            );
          })}
        </Panel>
      </Wrapper>
    );
  });
});
LayerStylePanel.displayName = "LayerStylePanel";

function applyBackgrounds(props: {
  readonly fields: BasicTextStyleFields;
  readonly locale: Locale;
  readonly colorItems: ColorItems;
  readonly strokeWidthItems: StrokeWidthItem[];
}) {
  const { fields, locale, colorItems, strokeWidthItems } = props;
  for (const [key, data] of typedEntriesOf(fields.backgrounds)) {
    if (!data.has.get()) continue;
    const title = 背景表[key].get(locale);
    for (const [type, { paint, options }] of typedEntriesOf(data.colors)) {
      const list = colorItems[type];
      list.push({ field: paint, options, title, supportedTypes: ["color"] });
    }
    {
      const field = data.strokeWidth;
      strokeWidthItems.push({ field, title, historyName: title, unit: "px" });
    }
  }
}

const ColorBlocks = [
  {
    title: () => <填充 />,
    key: "fill",
  },
  {
    title: () => <描边 />,
    key: "stroke",
  },
  {
    title: () => <投影 />,
    key: "shadow",
  },
] as const;

function renderColorBlock(colorItems: ColorItems) {
  return !typedKeysOf(colorItems).some((k) => colorItems[k].length) ? null : (
    <Panel title={<颜色 />}>
      {ColorBlocks.map(({ title, key }) => {
        const list = colorItems[key];
        if (!list.length) return null;
        return (
          <Block key={key}>
            <Row className="margin-y">
              <TitleText>{title()}</TitleText>
            </Row>
            <Row className="margin-y">
              <div className="circle-list">
                {list.map(({ field, options, title: tip, supportedTypes }, i) => {
                  return (
                    <Tooltip key={i} content={tip}>
                      <div className="circle-item">
                        <FillingPaintValue preset="circle" field={field} options={options} supportedTypes={supportedTypes} />
                      </div>
                    </Tooltip>
                  );
                })}
              </div>
            </Row>
          </Block>
        );
      })}
    </Panel>
  );
}

function renderStrokeWidthBlock(list: readonly StrokeWidthItem[]) {
  return !list.length ? null : (
    <Panel title={<描边粗细 />}>
      {list.map(({ field, title: tip, historyName, unit }, i) => {
        console.log("描边粗细", { field, tip });
        const Component = unit === "em" ? EmValueLong : GTE0LongValue;
        return (
          <Tooltip key={i} content={tip} anchors={LEFT_ANCHORS}>
            <Row className="margin-y">
              <Square>
                <AOutlined />
              </Square>
              <Gap />
              <Component field={field} max={25} historyName={() => `${historyName}描边粗细`} />
            </Row>
          </Tooltip>
        );
      })}
    </Panel>
  );
}

export const EmValueLong = React.memo(
  (props: { readonly historyName: MaybeThunk<string>; readonly max?: number; readonly field: DuplexFieldLike<number> }) => {
    const { field: inputField, max = 100 } = props;

    const { session } = React.useContext(EditorContext);
    const locale = useLocale();

    const field = React.useMemo(
      () =>
        MapDuplexField.same(
          inputField,
          (x) => x * 100,
          (x) => x / 100
        ),
      [inputField]
    );

    const raf = useRaf();

    return useObserver(() => {
      const multiValueProps = getInputProps(field, locale);
      const onChange = (newValue: number) => {
        raf.push(() => runInAction(() => field.set(newValue)));
      };
      const onComplete = () =>
        runInAction(() => {
          raf.flush();
          session.history.push({ name: `修改${dethunk(props.historyName)}` });
        });
      return (
        <>
          <SingleSlider {...multiValueProps} min={0} max={max} step={1} onChange={onChange} onComplete={onComplete} />
          <Gap />
          <InputNumber {...multiValueProps} align="left" min={0} step={1} onChange={onChange} onComplete={onComplete} style={{ maxWidth: 64 }} />
        </>
      );
    });
  }
);
EmValueLong.displayName = "EmValueLong";

export const EmValueShort = React.memo((props: { readonly historyName: MaybeThunk<string>; readonly field: DuplexFieldLike<number> }) => {
  const { field: inputField } = props;

  const { session } = React.useContext(EditorContext);
  const locale = useLocale();

  const field = React.useMemo(
    () =>
      MapDuplexField.same(
        inputField,
        (x) => x * 100,
        (x) => x / 100
      ),
    [inputField]
  );

  const raf = useRaf();

  return useObserver(() => {
    const multiValueProps = getInputProps(field, locale);
    const onChange = (newValue: number) => {
      raf.push(() => runInAction(() => field.set(newValue)));
    };
    const onComplete = () =>
      runInAction(() => {
        raf.flush();
        session.history.push({ name: `修改${dethunk(props.historyName)}` });
      });
    return <InputNumber {...multiValueProps} align="left" min={0} step={1} onChange={onChange} onComplete={onComplete} />;
  });
});
EmValueShort.displayName = "EmValueShort";

const Wrapper = styled.div`
  margin: 0 -12px 0;

  & .circle-list {
    margin: -4px;
    display: flex;
  }

  & .circle-item {
    padding: 4px;
  }
`;
Wrapper.displayName = "Wrapper";

const 颜色 = i18n({
  en: () => "Color",
  zh: () => "颜色",
});

const 填充 = i18n({
  en: () => "Fill",
  zh: () => "填充",
});

const 描边 = i18n({
  en: () => "Stroke",
  zh: () => "描边",
});

const 投影 = i18n({
  en: () => "Shadow",
  zh: () => "投影",
});

const 自身渲染层 = i18n({
  en: () => `Self Layer`,
  zh: () => `自身渲染层`,
});

const 偏移层 = i18n<{
  readonly no: number;
}>({
  en: (p) => `Offset Layer ${p.no}`,
  zh: (p) => `偏移层${p.no}`,
});

const 描边粗细 = i18n({
  en: () => "Stroke Width",
  zh: () => "描边粗细",
});

const 偏移距离 = i18n({
  en: () => "Offset Distance",
  zh: () => "偏移距离",
});

const 角度 = i18n({
  en: () => "Angle",
  zh: () => "角度",
});

const 偏移 = i18n({
  en: () => "Distance",
  zh: () => "偏移",
});

const 模糊 = i18n({
  en: () => "Radius",
  zh: () => "模糊",
});

const 斜切 = i18n({
  en: () => "Skew",
  zh: () => "斜切",
});

const 字符背景 = i18n({
  en: () => "Character Background",
  zh: () => "字符背景",
});

const 短语背景 = i18n({
  en: () => "Phrase Background",
  zh: () => "短语背景",
});

const 行背景 = i18n({
  en: () => "Line Background",
  zh: () => "行背景",
});

const 段落背景 = i18n({
  en: () => "Paragraph Background",
  zh: () => "段落背景",
});

const 区域背景 = i18n({
  en: () => "Region Background",
  zh: () => "区域背景",
});

const 行两端背景 = i18n({
  en: () => "Line Ends Background",
  zh: () => "行两端背景",
});

const 区域两端背景 = i18n({
  en: () => "Region Ends Background",
  zh: () => "区域两端背景",
});

const 背景表: {
  readonly [K in keyof TextSpec.TextLayoutBackgrounds]-?: I18N;
} = {
  character: 字符背景,
  phrase: 短语背景,
  line: 行背景,
  paragraph: 段落背景,
  region: 区域背景,
  lineEnds: 行两端背景,
  regionEnds: 区域两端背景,
};
