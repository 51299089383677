import { i18n, Locale } from './i18n'
import { ReadFieldLike, WriteLog } from './multi-value'

/**
 * 获取含有 placeholder 的单字段多值
 * @param field 字段
 * @param locale 语言
 */
export function getInputProps<T>(field: ReadFieldLike<T>, locale?: Locale) {
  const { data, disabled } = field
  const various = data.variety === 'various'
  return {
    disabled,
    value: various ? undefined : data.value,
    placeholder: various ? 多个值.get(locale) : undefined,
  }
}

/**
 * 获取类似 checkbox 的单字段多值
 * @param field
 */
export function getCheckboxProps(field: ReadFieldLike<boolean>) {
  const { data } = field
  return {
    disabled: field.disabled,
    checked: data.variety === 'various' ? 'intermediate' as const : data.value,
  }
}

export type DependentPropsList<T extends ReadonlyArray<ReadFieldLike<any>>> = {
  -readonly [P in keyof T]: {
    disabled?: boolean
    value?: T[P] extends ReadFieldLike<infer U> ? U : never
    placeholder?: string
  }
}

const DISABLED = { disabled: true } as const

/**
 * 获取含有 placeholder 的关联字段多值属性
 * - 前一个字段如果为 various, 后一个字段会自动变成 various
 * @param inputs
 * @param locale
 */
export function getDependentInputPropsList<Arr extends ReadonlyArray<ReadFieldLike<unknown>>>(
  inputs: Arr,
  locale?: Locale,
): DependentPropsList<Arr>
export function getDependentInputPropsList(
  inputs: ReadonlyArray<ReadFieldLike<unknown>>,
  locale?: Locale,
): unknown {
  const { length } = inputs
  if (length === 0) return []

  const list = []

  let i = 0
  for (; i < length; i++) {
    const input = inputs[i]
    const { data, disabled } = input
    const various = data.variety === 'various'
    list.push({
      disabled,
      value: various ? undefined : data.value,
      placeholder: various ? 多个值.get(locale) : undefined,
    })
    if (various) {
      i++
      break
    }
  }

  for (; i < length; i++) {
    list.push(DISABLED)
  }

  return list
}

export function hasChangesInLog(log: WriteLog<boolean>) {
  const { records } = log
  return records.length > 0 && records.some(Boolean)
}

export const 多个值 = i18n({
  en: () => `(various)`,
  zh: () => `(多个值)`,
})
