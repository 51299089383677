import * as React from "react";
import { createRoot } from "react-dom/client";
import "./styles.css";
import "./global";
import { App } from "./app";

const body = document.createElement("div");
document.body.appendChild(body);
const root = createRoot(body);
root.render(<App />);
