import { once } from 'lodash'
import { getAutoCursor } from '../dynamic-cursor'

export const getRootContainer = once(() => new RootContainer())

export class RootContainer {
  create() {
    const container = this._create()
    document.body.append(container)
    return {
      container,
      dispose: () => {
        if (container.parentElement) {
          container.parentElement.removeChild(container)
        }
      }
    }
  }

  protected _create() {
    const root = document.createElement('div')
    const { style } = root
    style.position = 'fixed'
    style.left = '0px'
    style.top = '0px'
    style.width = '0px'
    style.height = '0px'
    style.zIndex = '1000'
    style.fontSize = '12px'
    style.fontFamily = 'PingFangSC-Light,PingFang SC,Microsoft YaHei,sans-serif'
    style.lineHeight = '1.5'
    style.cursor = getAutoCursor()
    return root
  }
}
