/**
 * @author: songlei 190835837@qq.com
 * @create: 2022-06-24 19:08:59
 * @LastEditors: songlei 190835837@qq.com
 * @lastTime: 2022-11-18 19:40:15
 * @filePath: /idp_web_new/src/common/http.ts
 * @description: http 请求封装类
 */
// import eventCenter from "@/common/events";
import { fetch } from "whatwg-fetch";
import { assign } from "lodash";
import utils, { json2Form } from "./utils";
// import saveAs from "file-saver";

class Http {
  events: { [propName: string]: Function[] };

  constructor() {
    this.events = {};
  }

  async request<T = any>(url: string, options: any = {}, opt?: any) {
    opt = assign(
      {
        showErrorTip: true,
      },
      opt || {}
    );
    // console.log(url, options);

    // options = assign({ credentials: "include", mode: "cors" }, options);
    if (utils.getToken()) {
      options.headers = Object.assign(options.headers || {}, {
        Authorization: utils.getToken(),
      });
    }
    return fetch(this.getWholeUrl(url), options).then<T>(
      (res: any) => {
        if (res.status != 200) {
          return this.triggerError(
            { code: res.status.toString(), msg: res.statusText },
            opt || {}
          );
        }
        const token = res.headers.get("Authorization");
        if (token) {
          utils.setToken(token);
        }
        // console.log(url, 'res', res);
        return res.json().then(
          (response: any) => {
            console.debug("%c" + url, "color: blue;", response);
            // 白名单
            const whiteList = ["/font/open/feature-toggle"];
            if (whiteList.includes(url)) {
              return response;
            }
            if (
              response.code !== HTTP_CODE.SUCCESS.code &&
              url != "/system/user/getInfo"
            ) {
              this.triggerError(response, opt);
              response.data = [];
              return response;
            }
            if (opt["raw"]) {
              return response;
            }
            if (response.pageCount) {
              return response;
            }
            if (response.data && response.data.access_token) {
              utils.setToken(response.data.access_token);
            }
            return response; //data.result;
          },
          (err: any) => {
            return this.triggerError(err, opt);
          }
        );
      },
      (err: any) => {
        return this.triggerError(err, opt);
      }
    );
  }

  concatUrlParams(url: string, params: { [propName: string]: any }) {
    params = params || {};
    let paramsStr = Object.keys(params)
      .map((key) => {
        return [key, params[key]].join("=");
      })
      .join("&");
    if (paramsStr) {
      url = `${url}?${paramsStr}`;
    }
    return url;
  }

  get<T = any>(
    url: string,
    params: { [propName: string]: any } = {},
    options: { method?: string; headers?: any } = {},
    opt?: object
  ) {
    const { pageNum = 1, pageSize = 20 } = params;
    options.method = "GET";
    options.headers = assign(
      {
        "Content-Type": "application/json;charset=UTF-8",
      },
      {
        pageNum: pageNum,
      },
      {
        pageSize: pageSize,
      },
      options.headers
    );
    return this.request<T>(this.concatUrlParams(url, params), options, opt);
  }

  post<T = any>(
    url: string,
    params: any,
    options: { method?: string; body?: string; headers?: object } = {},
    opt?: object
  ) {
    const { pageNum = 1, pageSize = 20 } = params;
    options.method = "POST";
    options.headers = assign(
      {
        "Content-Type": "application/json;charset=UTF-8",
      },
      {
        pageNum: pageNum,
      },
      {
        pageSize: pageSize,
      },
      options.headers
    );
    options.body = JSON.stringify(params);
    // options.headers = { cookie: document.cookie };
    return this.request<T>(url, options, opt);
  }

  put<T = any>(
    url: string,
    params: Object = {},
    options: { method?: string; body?: string; headers?: object } = {},
    opt?: object
  ) {
    options.method = "PUT";
    options.headers = assign(
      {
        "Content-Type": "application/json;charset=UTF-8",
      },
      options.headers
    );
    options.body = JSON.stringify(params);
    // options.headers = { cookie: document.cookie };
    return this.request<T>(url, options, opt);
  }

  delete<T = any>(
    url: string,
    params: Object = {},
    options: { method?: string; body?: string; headers?: object } = {},
    opt?: object
  ) {
    options.method = "DELETE";
    options.headers = assign(
      {
        "Content-Type": "application/json;charset=UTF-8",
      },
      options.headers
    );
    options.body = JSON.stringify(params);
    // options.headers = { cookie: document.cookie };
    return this.request<T>(url, options, opt);
  }

  postBlob<T = any>(
    url: string,
    name: string,
    params: Object = {},
    options: { method?: string; body?: string; headers?: object } = {},
    opt?: object
  ) {
    options.method = "POST";
    options.headers = assign(
      {
        "Content-Type": "application/json;charset=UTF-8",
      },
      options.headers
    );
    options.body = JSON.stringify(params);
    // options = assign({ credentials: "include", mode: "cors" }, options);

    return fetch(this.getWholeUrl(url), options as any)
      .then((res: any) => {
        if (res.status == 200) {
          return res.blob();
        }
        return this.triggerError(
          { code: res.status.toString(), msg: res.statusText },
          opt || {}
        );
      })
      .then((blob: any) => {
        // saveAs(blob, name);
      });
  }

  postForm(
    url: string,
    params: object = {},
    options: { method?: string; body?: string; headers?: object } = {},
    opt?: object
  ) {
    params = params || {};
    options = options || {};
    options.method = "POST";
    options.headers = {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    };
    options.body = json2Form(params);
    return this.request(url, options, opt);
  }

  uploadFile(
    url: string,
    params: { [props: string]: any } = {},
    options: { method?: string; body?: any; headers?: object } = {},
    opt?: object
  ) {
    params = params || {};
    options = options || {};
    options.method = "POST";

    const form = new FormData();
    for (let prop in params) {
      form.append(prop, params[prop]);
    }
    options.body = form;

    return this.request(url, options, opt);
  }

  onError(code: string, handle: Function) {
    if (!this.events[code]) {
      this.events[code] = [];
    }
    this.events[code].push(handle);
  }

  triggerError(
    err: { msg?: string; code?: string; stack?: string; exception?: string },
    opt: any
  ) {
    console.error("triggerError", err, opt);
    let { showErrorTip } = opt;

    if (err && err.msg === "Aborted") {
      // console.log("window stop", err);
      showErrorTip = false;
    }
    if (err.code == "401") {
      // return history.replace({
      //   pathname: '/login',
      //   search: stringify({
      //     redirect: window.location.href,
      //   }),
      // });
      // eventCenter.emit('login', true);
      return Promise.reject(err);
    }
    if (showErrorTip && err.code !== "401") {
      // message.error(err.exception || err.msg || "请求异常");
    }
    // let handles = this.events[err.code];
    // if (handles) {
    //     console.log("%c请求错误啦", "color:red;font-size:30px;", err, opt);
    //     handles.forEach(handle => handle(err));
    // }
    return Promise.reject(err);
  }

  getWholeUrl(url: string, domain?: string) {
    if (url && url.indexOf("http") !== 0) {
      domain = domain || HTTP_URL.MAIN;
      url = `${domain}${url.startsWith("/") ? "" : "/"}${url}`;
    }
    return url;
  }
}

export const HTTP_CODE = {
  DATA_SUCCESS: { code: "0", name: "请求成功" },
  DATA_NO_PERMISSION: { code: "2", name: "没有权限" },
  SUCCESS: { code: 200, name: "请求成功" },
  LOGIN_FAIL: { code: 401, name: "登陆状态失效" },
  DATA_ERROR: { code: "201", name: "数据解析异常" },
  BAD_REQUEST: { code: "400", name: "非法参数" },
  NO_AUTHENTICATION: { code: "403", name: "未认证" },
  NOT_FOUND: { code: "404", name: "该服务接口不存在" },
  EXCEPTION: { code: "500", name: "服务器异常" },
  FETCH_ERROR: { code: "501", name: "请求异常" },
};

export const HTTP_URL = {
  MAIN: (() => {
    return "https://demo.chuyuanai.com";
  })(),
  NODE_SERVER: (() => {
    // return "http://localhost:3000";
    return "http://1.14.15.246:3000";
  })(),
};

export default new Http();
