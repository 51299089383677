import * as React from "react";
import { useObserver } from "mobx-react";
import { EMPTY_ARRAY } from "@chuyuan/poster-utils";
import { Renderer } from "@chuyuan/poster-renderer-react";

import { PointerEvent } from "./event";
import { FlexListReorderState } from "./flex-list-reorder.state";
import { Render } from "./render";
import { SelectableTarget } from "../editor-state/types";

export const FlexListReorder = React.memo(
  (props: { readonly state: FlexListReorderState }) => {
    const { state } = props;
    return (
      <>
        <FlexListReorderEvent state={state} />
        <FlexListReorderVisual state={state} />
      </>
    );
  }
);
FlexListReorder.displayName = "FlexListReorder";

function FlexListReorderEvent(props: { readonly state: FlexListReorderState }) {
  const { state } = props;
  return useObserver(() => {
    const { dropRegisters: PointerRegisterList } = state;
    if (!PointerRegisterList) return null;

    const { eventRoot } = state.root;

    return (
      <>
        {PointerRegisterList.map((register, i) => (
          <PointerEvent key={i} root={eventRoot} register={register} />
        ))}
        <PointerEvent root={eventRoot} register={state.dragRegister} />
      </>
    );
  });
}

function FlexListReorderVisual(props: {
  readonly state: FlexListReorderState;
}) {
  const { state } = props;
  return (
    <>
      <Render
        root={state.root.renderInSvg}
        props={{
          path: EMPTY_ARRAY,
          layer: "global",
          selected: true,
          children: <FlexListReorderFrame state={state} />,
        }}
      />
      {/* <Render
        root={state.root.renderInReact}
        props={{
          path: EMPTY_ARRAY,
          layer: 'cover',
          selected: true,
          children: <FlexListReorderDragImage state={state} />
        }}
      /> */}
    </>
  );
}

const FlexListReorderFrame = React.memo(
  (props: { readonly state: FlexListReorderState }) => {
    const { state } = props;
    return useObserver(() => {
      const { data } = state;
      if (!data) return null;

      const { hoverIndex } = state;

      const { direction, orthogonal, minParallel, maxParallel, uiScale } = data;

      const normalStrokeWidth = 2 * uiScale;

      const renderOutline = () => {
        const coordinates =
          direction === "row"
            ? {
                x: minParallel,
                width: maxParallel - minParallel,
                height: orthogonal,
              }
            : {
                y: minParallel,
                height: maxParallel - minParallel,
                width: orthogonal,
              };
        return (
          <rect
            {...coordinates}
            fill="none"
            stroke="#0040f0"
            strokeWidth={normalStrokeWidth}
            vectorEffect="non-scaling-size"
          />
        );
      };

      const renderBars = () => {
        return data.bars.map((bar, i) => {
          const { offset } = bar;
          const coordinates =
            direction === "row"
              ? {
                  x1: offset,
                  x2: offset,
                  y1: -normalStrokeWidth / 2,
                  y2: orthogonal + normalStrokeWidth / 2,
                }
              : {
                  x1: -normalStrokeWidth / 2,
                  x2: orthogonal + normalStrokeWidth / 2,
                  y1: offset,
                  y2: offset,
                };
          const strokeWidth =
            hoverIndex === i ? 4 * uiScale : normalStrokeWidth;
          return (
            <line
              key={i}
              {...coordinates}
              strokeLinecap="butt"
              stroke="#0040f0"
              strokeWidth={strokeWidth}
              vectorEffect="non-scaling-size"
            />
          );
        });
      };

      return (
        <g transform={`matrix(${data.transform.toArray()})`}>
          {renderOutline()}
          {renderBars()}
        </g>
      );
    });
  }
);
FlexListReorderFrame.displayName = "FlexListReorderFrame";

const FlexListReorderDragImage = React.memo(
  (props: { readonly state: FlexListReorderState }) => {
    const { state } = props;
    return useObserver(() => {
      const { data } = state;
      if (!data) return null;

      const p1 = state.startPosition;
      const p2 = state.currentPosition;
      const dx = p2.x - p1.x;
      const dy = p2.y - p1.y;

      return (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            transform: `translate(${dx}px, ${dy}px)`,
            opacity: 0.5,
          }}
        >
          {data.targets.map((target, i) => {
            return <Child key={i} state={state} target={target} />;
          })}
        </div>
      );
    });
  }
);
FlexListReorderDragImage.displayName = "FlexListReorderDragImage";

const Child = React.memo(
  (props: {
    readonly state: FlexListReorderState;
    readonly target: SelectableTarget;
  }) => {
    const { state, target } = props;
    return useObserver(() => {
      const { renderAPI } = state.root.props.session.render;
      const renderParams = state.getRenderParams(target).get();
      const transform = target.layout.coordinate.selfFromWorldTransform;
      return (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            transform: `matrix(${transform.toArray()})`,
          }}
        >
          <Renderer render={renderParams} api={renderAPI} />
        </div>
      );
    });
  }
);
Child.displayName = "Child";
