import {
  createFieldValuesGuardian,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  ExtractTypeWithKey,
  IdenticalUnionFieldKey,
  KeyMap,
  MergeUnionObject,
  pick,
  touchArrayField as ta,
  typedValuesOf,
} from "@chuyuan/poster-utils";
import {
  FillingPaint,
  getDefaultFillingPaint,
  NullableFillingPaint,
} from "../ui-components-stateful/filling-paint";

type FillingPaintUnionKey = Exclude<
  IdenticalUnionFieldKey<FillingPaint>,
  "color" | "opacity"
>;

type MergedFillingPaint = Readonly<MergeUnionObject<FillingPaint>>;

type FillingPaintCacheData = Partial<
  Pick<MergedFillingPaint, FillingPaintUnionKey>
> & {
  readonly opacity?: number;
  readonly color?: {
    readonly color: string;
    readonly opacity: number;
  };
};

type FillingPaintWithOpacity = ExtractTypeWithKey<
  Exclude<FillingPaint, { readonly kind: "color" }>,
  "opacity"
>;

const isFillingPaintWithOpacity = createFieldValuesGuardian(
  "kind",
  typedValuesOf<KeyMap<FillingPaintWithOpacity["kind"]>>({
    image: "image",
  })
);

const FillingPaintCacheKey = typedValuesOf<KeyMap<FillingPaintUnionKey>>({
  angle: "angle",
  stops: "stops",
  image: "image",
  scale: "scale",
  fit: "fit",
});

export class FillingPaintCache {
  cache: FillingPaintCacheData = EMPTY_OBJECT;

  getFn: this["get"] = (...args) => this.get(...args);

  get(
    kind: FillingPaint["kind"],
    oldValue: NullableFillingPaint
  ): FillingPaint {
    const df = getDefaultFillingPaint(kind);
    const cache =
      oldValue.kind === "empty" ? this.cache : this.getCache(oldValue);
    if (df.kind === "color") {
      return { ...df, ...cache.color };
    }
    if (df.kind === "linear gradient" || df.kind === "radial gradient") {
      const stops = cache.stops || EMPTY_ARRAY;
      if (!stops.length) {
        const { color } = cache;
        if (color) {
          return {
            ...df,
            stops: [
              {
                color: color.color,
                opacity: color.opacity,
                offset: 0,
              },
              {
                color: color.color,
                opacity: 0,
                offset: 1,
              },
            ],
          };
        }
      }
    }
    return { ...df, ...cache };
  }

  put(value: FillingPaint): void {
    this.cache = this.getCache(value);
  }

  getCache(value: FillingPaint): FillingPaintCacheData {
    // AMAZON-14308
    // AMAZON-14757
    // 从纯色切换到渐变, 用纯色当前颜色修改 stops 第一个颜色 (包含颜色和透明度, 也就是说纯色的透明度不是渐变整体的透明度)
    // 从渐变切换到纯色, 用第一个渐变色生成纯色 (不叠加透明度)
    const cache = {
      ...this.cache,
      ...pick(value, FillingPaintCacheKey),
    };
    if (value.kind === "color") {
      const { color, opacity } = value;
      cache.color = { color, opacity };
      const stops = ta(cache, "stops");
      if (stops.length) {
        stops[0] = { ...stops[0], color, opacity };
      }
    } else {
      if (isFillingPaintWithOpacity(value)) {
        cache.opacity = value.opacity;
      }
      if (
        value.kind === "linear gradient" ||
        value.kind === "radial gradient"
      ) {
        const { stops } = value;
        if (stops.length) {
          const { color, opacity = 1 } = stops[0];
          if (typeof color === "string" && typeof opacity === "number") {
            cache.color = { color, opacity };
          } else {
            delete cache.color;
          }
        } else {
          delete cache.color;
        }
      }
    }
    return cache;
  }
}
