import { dethunk, MaybeThunk, memorize } from "@chuyuan/poster-utils";
import {
  Vector2DArray,
  Affine2DArray,
  multiplyTransformVector2D,
  multiplyTransformVector2Ds,
  getInverseTransform,
  pointInPolygonWindingNumber,
} from "@chuyuan/poster-math";

export interface PlainPolygon {
  readonly vertices: readonly Vector2DArray[];
  readonly transform: Affine2DArray;
}

export class Polygon {
  readonly kind = "polygon";

  private readonly _vertices: MaybeThunk<readonly Vector2DArray[]>;
  private readonly _transform: MaybeThunk<Affine2DArray>;

  constructor(
    vertices: MaybeThunk<readonly Vector2DArray[]>,
    transform: MaybeThunk<Affine2DArray>
  ) {
    this._vertices = vertices;
    this._transform = transform;
  }

  @memorize
  get transformedVertices() {
    return multiplyTransformVector2Ds(this.transform, this.vertices);
  }

  @memorize
  get vertices() {
    return dethunk(this._vertices);
  }

  @memorize
  get transform() {
    return dethunk(this._transform);
  }

  @memorize
  get inverseTransform() {
    return getInverseTransform(this.transform);
  }

  contains(point: Vector2DArray) {
    const transformedPoint = multiplyTransformVector2D(
      this.inverseTransform,
      point
    );
    return pointInPolygonWindingNumber(transformedPoint, this.vertices);
  }

  toJSON(): PlainPolygon {
    return {
      vertices: this.vertices,
      transform: this.transform,
    };
  }
}

export interface PlainBounding {
  readonly bounding: BoundingLike;
  readonly transform: Affine2DArray;
}

export interface BoundingLike {
  readonly left: number;
  readonly right: number;
  readonly top: number;
  readonly bottom: number;
}

export class Bounding {
  readonly kind = "bounding";

  private readonly _bounding: MaybeThunk<BoundingLike>;
  private readonly _transform: MaybeThunk<Affine2DArray>;

  constructor(
    bounding: MaybeThunk<BoundingLike>,
    transform: MaybeThunk<Affine2DArray>
  ) {
    this._bounding = bounding;
    this._transform = transform;
  }

  @memorize
  get bounding() {
    return dethunk(this._bounding);
  }

  @memorize
  get transform() {
    return dethunk(this._transform);
  }

  @memorize
  get inverseTransform() {
    return getInverseTransform(this.transform);
  }

  contains(point: Vector2DArray) {
    const [x, y] = multiplyTransformVector2D(this.inverseTransform, point);
    const b = this.bounding;
    return x >= b.left && x <= b.right && y >= b.top && y <= b.bottom;
  }

  toJSON(): PlainBounding {
    return {
      bounding: this.bounding,
      transform: this.transform,
    };
  }
}
