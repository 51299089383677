import { computed } from "mobx";

import { Frame, sortTargetsFromRootToLeaf } from "@chuyuan/poster-data-access-layer";
import { memorize, ClassStaticCache, isIterable, assignNewProperties, cached, mapIterableAsKeys, isEqual } from "@chuyuan/poster-utils";

import {
  RotationDuplex,
  FlexItemInheritParentDirectionDuplex,
  IsFlexItemInheritParentDirectionDisabledReader,
  OffsetFields,
  ElementChildLayoutOperator,
  DimensionFields,
  CanvasChildLayoutOperator,
  AdjustmentDimensionFields,
  IsRatioLockableReader,
  IsRatioLockedDuplex,
  HasConstraintReader,
  ConstraintFields,
} from "./state.layout.child";
import {
  DisplayOuterDuplex,
  ContainerTypeDuplex,
  FlexIsParentFlexContainerReader,
  FlexIsSelfAndParentFlexContainerReader,
  FlexIsDirectionInheritedDuplex,
  FlexDirectionDuplex,
  FlexDirectionDisabledReader,
  FlexSortDuplex,
  FlexChildAlignmentDuplex,
  FlexGapTypeDuplex,
  FlexGapsRawListDuplex,
  FlexPaddingDuplex,
  createFlexGapsIndexDuplex,
  FlexGapsFullListDuplex,
  createFlexPaddingSideDuplex,
} from "./state.layout.parent";
import {
  DuplexField,
  DisabledDuplexField,
  DuplexFieldDisability,
  MapReader,
  IntersectionSetReadField,
  ReadField,
  Reader,
  ReadableSourceFromGetter,
  SomeReducer,
  KeyReader,
  min,
  max,
  EveryReducer,
} from "../../utils/multi-value";
import { SelectableTarget } from "../editor-state/types";
import { getSelectionFilter } from "../helpers/selection-filter";
import { mapValues } from "lodash";
import { BOUNDING_KEYS } from "../helpers/misc";

export class LayoutFields {
  static of = ClassStaticCache;

  readonly targets: readonly SelectableTarget[];

  constructor(readonly root: Frame, targets: SelectableTarget | Iterable<SelectableTarget>) {
    this.targets = isIterable(targets) ? Array.from(new Set(targets)) : [targets];
  }

  @memorize
  private get filter() {
    return getSelectionFilter(this.root, this.targets);
  }

  @memorize
  private get source() {
    const cpt = computed((): readonly SelectableTarget[] => sortTargetsFromRootToLeaf(this.targets));
    return new ReadableSourceFromGetter(() => cpt.get());
  }

  @computed
  private get sortedFilter() {
    return getSelectionFilter(this.root, this.source.getTargets());
  }

  @memorize
  private get containers() {
    return assignNewProperties(new ReadableSourceFromGetter(() => Array.from(this.sortedFilter.containersOnly)), {
      set: this.filter.containersOnly,
    } as const);
  }

  @memorize
  private get elementContainers() {
    return assignNewProperties(new ReadableSourceFromGetter(() => Array.from(this.sortedFilter.nonRootContainersOnly)), {
      set: this.filter.nonRootContainersOnly,
    } as const);
  }

  @memorize
  private get elements() {
    return assignNewProperties(new ReadableSourceFromGetter(() => Array.from(this.sortedFilter.elementsOnly)), {
      set: this.filter.elementsOnly,
    } as const);
  }

  @memorize
  private get elementsOperators() {
    return new MapReader(this.elements, (x) => ElementChildLayoutOperator.of(x));
  }

  @memorize
  private get targetsOperators() {
    return new MapReader(this.source, (x) => {
      if (x === this.root) {
        return CanvasChildLayoutOperator.of(x);
      }
      return ElementChildLayoutOperator.of(x);
    });
  }

  /**
   * 所有的对象的父元素是否都是 flex 容器
   */
  @memorize
  get isParentFlexContainer() {
    const { elements, targets } = this;
    if (targets.length !== elements.set.size) {
      return new ReadField(Reader.literals(false));
    }
    return new ReadField(FlexIsParentFlexContainerReader.of(elements));
  }

  /**
   * 所有的对象及其父元素是否都是 flex 容器
   */
  @memorize
  get isSelfAndParentFlexContainer() {
    const { containers, targets } = this;
    if (targets.length !== containers.set.size) {
      return new ReadField(Reader.literals(false));
    }
    return new ReadField(FlexIsSelfAndParentFlexContainerReader.of(containers));
  }

  /**
   * 是否存在画布
   */
  @memorize
  get hasCanvas() {
    return this.filter.rootOnly.size > 0;
  }

  /**
   * 是否只有容器
   */
  @memorize
  get hasOnlyContainers() {
    const { targets, containers } = this;
    return targets.length === containers.set.size;
  }

  /**
   * 是否只有元素容器
   */
  @memorize
  get hasOnlyElementContainers() {
    const { targets, elementContainers } = this;
    return targets.length === elementContainers.set.size;
  }

  /**
   * 偏移面板是否禁止
   */
  @memorize
  get isOffsetsDisabled() {
    const { targets, elements } = this;
    return targets.length !== elements.set.size;
  }

  /**
   * 偏移面板
   */
  @memorize
  get offsets() {
    const { elementsOperators } = this;
    const disabled = this.isOffsetsDisabled;
    return mapValues(OffsetFields, (fields) => {
      if (disabled) {
        return {
          value: DisabledDuplexField.instance,
          unit: DisabledDuplexField.instance,
          possibleUnits: DisabledDuplexField.instance,
        };
      }
      return {
        value: new DuplexFieldDisability(fields.value.of(elementsOperators), new MapReader(fields.isValueMutable.of(elementsOperators), (x) => !x)),
        unit: new DuplexField(fields.unit.of(elementsOperators)),
        possibleUnits: new IntersectionSetReadField(fields.possibleUnits.of(elementsOperators)),
      };
    });
  }

  /**
   * 尺寸面板
   */
  @memorize
  get dimensions() {
    const { targetsOperators } = this;
    return mapValues(DimensionFields, (fields) => {
      return {
        value: new DuplexFieldDisability(fields.value.of(targetsOperators), new MapReader(fields.isValueMutable.of(targetsOperators), (x) => !x)),
        unit: new DuplexField(fields.unit.of(targetsOperators)),
        possibleUnits: new IntersectionSetReadField(fields.possibleUnits.of(targetsOperators)),
      };
    });
  }

  /**
   * 调整大小面板
   */
  @memorize
  get adjustments() {
    const { targetsOperators } = this;
    return {
      lock: new DuplexFieldDisability(IsRatioLockedDuplex.of(targetsOperators), new MapReader(IsRatioLockableReader.of(targetsOperators), (x) => !x)),
      dimension: mapValues(AdjustmentDimensionFields, (fields) => {
        return {
          value: new DuplexField(fields.value.of(targetsOperators)),
          possibleValues: new IntersectionSetReadField(fields.possibleValues.of(targetsOperators)),
          hidden: new EveryReducer(fields.hidden.of(targetsOperators)),
        };
      }),
    };
  }

  /**
   * 约束面板
   */
  @memorize
  get constraints() {
    const { targetsOperators } = this;
    return {
      enabled: new ReadField(HasConstraintReader.of(targetsOperators)),
      dimension: mapValues(ConstraintFields, (fields) => {
        return {
          value: new DuplexField(fields.value.of(targetsOperators)),
          possibleValues: new IntersectionSetReadField(fields.possibleValues.of(targetsOperators)),
        };
      }),
    };
  }

  /**
   * 旋转值
   */
  @memorize
  get rotation() {
    const { elements, targets } = this;
    if (targets.length !== elements.set.size) return DisabledDuplexField.instance;
    return new DuplexField(RotationDuplex.of(elements));
  }

  /**
   * flex 子元素是否跟随父元素的方向
   */
  @memorize
  get flexItemInheritParentDirection() {
    const { elements, targets } = this;
    if (targets.length !== elements.set.size) return DisabledDuplexField.instance;
    return new DuplexFieldDisability(FlexItemInheritParentDirectionDuplex.of(elements), IsFlexItemInheritParentDirectionDisabledReader.of(elements));
  }

  /**
   * 是否显示超区部分
   */
  @memorize
  get displayOuter() {
    const { elementContainers, targets } = this;
    if (targets.length !== elementContainers.set.size) return DisabledDuplexField.instance;
    return new DuplexField(DisplayOuterDuplex.of(elementContainers));
  }

  /**
   * 容器类型
   */
  @memorize
  get containerType() {
    const { containers, targets } = this;
    if (targets.length !== containers.set.size) return DisabledDuplexField.instance;
    return new DuplexField(ContainerTypeDuplex.of(containers));
  }

  /**
   * 是否有容器的类型是绝对布局
   */
  @memorize
  get isAnyContainerTypeAbsolute() {
    const { containers, targets } = this;
    if (targets.length !== containers.set.size) return new ReadField(Reader.literals(false));
    return new SomeReducer(this.containerType.parent, (x) => x === "absolute");
  }

  /**
   * 是否隐藏「继承父级」的选项
   */
  @memorize
  get isFlexContainerInheritParentDirectionHidden() {
    const { containers, targets } = this;
    if (targets.length !== containers.set.size) return new ReadField(Reader.literals(true));
    return new SomeReducer(
      new MapReader(containers, (target) => {
        return (
          // 当堆叠布局不继承父级, 也没有表达式控制的时候, 隐藏相关 ui
          ContainerTypeDuplex.getFn(target) === "flex pile" && !FlexIsDirectionInheritedDuplex.getFn(target)
        );
      })
    );
  }

  /**
   * flex容器是否跟随父flex容器的方向
   */
  @memorize
  get flexContainerInheritParentDirection() {
    const { containers, targets } = this;
    if (targets.length !== containers.set.size) return DisabledDuplexField.instance;
    return new DuplexFieldDisability(
      FlexIsDirectionInheritedDuplex.of(containers),
      new MapReader(this.isSelfAndParentFlexContainer.parent, (x) => !x)
    );
  }

  /**
   * flex容器子元素方向
   */
  @memorize
  get flexDirection() {
    const { containers, targets } = this;
    if (targets.length !== containers.set.size) return DisabledDuplexField.instance;
    return new DuplexFieldDisability(FlexDirectionDuplex.of(containers), FlexDirectionDisabledReader.of(containers));
  }

  /**
   * flex容器排序
   */
  @memorize
  get flexSort() {
    const { containers, targets } = this;
    if (targets.length !== containers.set.size) return DisabledDuplexField.instance;
    return new DuplexField(FlexSortDuplex.of(containers));
  }

  /**
   * flex容器子元素对齐
   */
  @memorize
  get flexChildrenAlignment() {
    const { containers, targets } = this;
    if (targets.length !== containers.set.size) return DisabledDuplexField.instance;
    return new DuplexField(FlexChildAlignmentDuplex.of(containers));
  }

  /**
   * flex容器间距类型
   */
  @memorize
  get flexGapType() {
    const { containers, targets } = this;
    if (targets.length !== containers.set.size) return DisabledDuplexField.instance;
    return new DuplexField(FlexGapTypeDuplex.of(containers));
  }

  /**
   * flex容器间距列表
   * - 当且仅当间距类型为 fixed 的时候生效
   */
  @memorize
  get flexGapList() {
    const { containers, targets } = this;
    if (targets.length !== containers.set.size) return DisabledDuplexField.instance;
    return new DuplexField(FlexGapsRawListDuplex.of(containers), isEqual);
  }

  /**
   * flex容器间距列表索引编辑
   * - 当且仅当间距类型为 fixed 的时候生效
   */
  @memorize
  get getFlexGapListIndex() {
    const { containers, targets } = this;
    if (targets.length !== containers.set.size) return () => DisabledDuplexField.instance;
    const getDuplex = createFlexGapsIndexDuplex(containers);
    return cached((index: number) => new DuplexField(getDuplex(index)));
  }

  @memorize
  get minFlexGapFullListLength() {
    return min(new KeyReader(FlexGapsFullListDuplex.of(this.containers), "length"));
  }

  @memorize
  get maxFlexGapFullListLength() {
    return max(new KeyReader(FlexGapsFullListDuplex.of(this.containers), "length"));
  }

  /**
   * flex容器内边距
   */
  @memorize
  get flexPadding() {
    const { containers, targets } = this;
    if (targets.length !== containers.set.size) return DisabledDuplexField.instance;
    return new DuplexField(FlexPaddingDuplex.of(containers), isEqual);
  }

  /**
   * flex容器内边距
   */
  @memorize
  get flexPaddingSides() {
    const { containers, targets } = this;
    if (targets.length !== containers.set.size) {
      return mapIterableAsKeys(BOUNDING_KEYS, () => DisabledDuplexField.instance);
    }
    const create = createFlexPaddingSideDuplex(containers);
    return mapIterableAsKeys(BOUNDING_KEYS, (key) => new DuplexField(create(key)));
  }
}
