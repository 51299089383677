import * as React from 'react'
import styled from 'styled-components'
import * as cx from 'classnames'
import type { CommonProps } from './common'
import { TooltipProps, Tooltip } from './tooltip'
import { syncForwardedRef } from '../../utils/react-hooks'

export interface RadioOption<T> {
  readonly value: T
  readonly title: () => React.ReactNode
  readonly hidden?: boolean
  readonly tooltip?: Omit<TooltipProps, 'children'>
}

export interface RadioProps<T> extends CommonProps {
  readonly value?: T
  readonly options: ReadonlyArray<RadioOption<T>>
  readonly onChange?: (value: T) => void
  readonly onClick?: (value: T) => void
}

export type RadioRef = {
  readonly outline: HTMLDivElement | null
}

export interface RadioComponent {
  <T>(
    props: RadioProps<T> & {
      readonly ref?: React.Ref<RadioRef>
    }
  ): JSX.Element
  displayName?: string
}

export const Radio = React.forwardRef(
  function _Radio<T>(props: RadioProps<T>, forwardedRef: React.ForwardedRef<RadioRef>) {
    const { disabled, readOnly, onChange, onClick } = props
    const unclickable = disabled || readOnly

    type State = {
      outline: HTMLDivElement | null
      radioRef: RadioRef
      forwardedRef: React.ForwardedRef<RadioRef>
      getOutlineRef: (ref: HTMLDivElement | null) => void
    }

    const stateRef = React.useRef<State>()
    const state: State = stateRef.current || (stateRef.current = {
      outline: null,
      radioRef: {
        get outline() {
          return state.outline
        },
      },
      forwardedRef: null,
      getOutlineRef: ref => state.outline = ref
    })

    if (state.forwardedRef !== forwardedRef) {
      state.forwardedRef = forwardedRef
      syncForwardedRef(forwardedRef, state.radioRef)
    }

    return (
      <RadioContainer
        ref={state.getOutlineRef}
        className={cx(
          props.className,
          disabled && 'disabled',
        )}
        style={props.style}
      >
        {props.options.map((option, i) => {
          const selected = option.value === props.value
          if (!selected && option.hidden) return null
          const btn = (
            <div
              className={`radio-item ${selected ? 'selected' : ''}`}
              onClick={
                unclickable ? undefined :
                  selected ? (
                    onClick ? () => onClick(option.value) : undefined
                  ) : (
                    onClick || onChange ? () => {
                      onClick?.(option.value)
                      onChange?.(option.value)
                    } : undefined
                  )
              }
              children={option.title()}
            />
          )
          const { tooltip } = option
          if (tooltip) {
            return (
              <React.Fragment key={i}>
                <Tooltip {...tooltip}>
                  {btn}
                </Tooltip>
              </React.Fragment>
            )
          } else {
            return (
              <React.Fragment key={i}>
                {btn}
              </React.Fragment>
            )
          }
        })}
      </RadioContainer>
    )
  }
) as RadioComponent
Radio.displayName = 'Radio'

const RadioContainer = styled.div`
  flex: 1 1 0;
  display: flex;
  padding: 4px;
  height: 32px;
  align-items: center;
  background: #fff;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;

  &.iconic {
    font-size: 24px;
  }

  &:not(.disabled) {
    & > .radio-item {
      &:hover {
        color: #606060;
      }
    }
  }

  & > .radio-item {
    flex: 1 1 0;
    display: flex;
    padding: 4px;
    height: 100%;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    color: #a0a0a0;

    &:not(:first-child) {
      margin-left: 12px;
    }

    &.selected {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
      background: #f8f7f6;
      color: #606060;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
