import * as React from "react";
import styled from "styled-components";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import {
  ClassStaticCache,
  memorize,
  EventEmitter,
  EMPTY_ARRAY,
  KeyMap,
  createValueInSetGuardian,
  createValueInSetFormatter,
  typedValuesOf,
  castReadonly,
} from "@chuyuan/poster-utils";
import {
  SuccessIcon,
  WarningIcon,
  ErrorIcon,
  CloseOutlined,
} from "../../../../poster-ui/icons/new-icons";

import { Popup } from "./popup";
import { PortalContainer, PortalRegister } from "../../utils/react-portal-mobx";
import { GlobalContext } from "./global-context";
import { useForceUpdate } from "../../utils/react-hooks";
import { IconBtn } from "./icon-btn";
import { useObserver } from "mobx-react";

export type MessageType = "info" | "success" | "error" | "warning";

export interface MessageProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * 全局上下文
   */
  readonly ctx: GlobalContext;
  /**
   * 全局唯一 key, 可以用来覆盖已经显示的 message
   * @default undefined
   */
  readonly key?: string | number;
  /**
   * 信息类型 (影响颜色等样式)
   * @default info
   */
  readonly type?: MessageType;
  /**
   * 信息内容
   * @default undefined
   */
  readonly content?: React.ReactNode;
  /**
   * 额外的描述 (辅助显示)
   * @default undefined
   */
  readonly description?: React.ReactNode;
  /**
   * 持续时间, 单位 ms
   * - 大于 0 时生效, 其他时候代表无限时间
   * @default 3000
   */
  readonly duration?: number;
  /**
   * 是否显示点击按钮
   * @default false
   */
  readonly closable?: boolean;
  /**
   * 是否穿透事件
   * 注意: closable: true 时依然可以点击关闭按钮, 其他部分保持穿透
   * @default false
   */
  readonly through?: boolean;
  /**
   * 如果鼠标 hover 了信息框, 是否暂停持续时间计时
   * @default false
   */
  readonly pauseOnHover?: boolean;
}

export function message(props: MessageProps) {
  const { key } = props;

  const my = MessageContext.of(props.ctx);
  const { List: list, ns } = my;

  if (key !== undefined) {
    const prevOp = ns.get(key);
    if (prevOp) {
      prevOp.update(props);
      return new MessageAPI(prevOp);
    }
  }

  const op = new MessageOperator(my, props);
  const register = list.create({ op });
  op.register = register;
  if (key !== undefined) {
    ns.set(key, op);
  }
  return new MessageAPI(op);
}

message.info = function callErrorMessage(
  ctx: GlobalContext,
  content: React.ReactNode,
  duration?: number,
  options?: Omit<MessageProps, "content" | "duration" | "ctx">
) {
  return message({ ...options, ctx, type: "success", content, duration });
};

message.success = function callErrorMessage(
  ctx: GlobalContext,
  content: React.ReactNode,
  duration?: number,
  options?: Omit<MessageProps, "content" | "duration" | "ctx">
) {
  return message({ ...options, ctx, type: "success", content, duration });
};

message.warning = function callErrorMessage(
  ctx: GlobalContext,
  content: React.ReactNode,
  duration?: number,
  options?: Omit<MessageProps, "content" | "duration" | "ctx">
) {
  return message({ ...options, ctx, type: "warning", content, duration });
};

message.warn = message.warning;

message.error = function callErrorMessage(
  ctx: GlobalContext,
  content: React.ReactNode,
  duration?: number,
  options?: Omit<MessageProps, "content" | "duration" | "ctx">
) {
  return message({ ...options, ctx, type: "error", content, duration });
};

export class MessageAPI {
  /**
   * @internal
   */
  private _op: MessageOperator;

  constructor(op: MessageOperator) {
    this._op = op;
  }

  /**
   * @internal
   */
  @memorize
  private get _promise() {
    return new Promise<void>((resolve) => {
      if (this._op.disposed) {
        resolve();
      } else {
        this.listen("closed", resolve);
      }
    });
  }

  get state() {
    const op = this._op;
    if (op.disposed) return "closed";
    if (op.closing) return "closing";
    if (op.entered) return "opened";
    return "open";
  }

  @memorize
  get then() {
    const p = this._promise;
    return p.then.bind(p);
  }

  listen<K extends "opened" | "close" | "closed">(key: K, cb: () => void) {
    return this._op.events.subscribe(key, cb);
  }

  close() {
    const op = this._op;
    if (!op.closing) op.close();
    return this;
  }
}

class MessageContext {
  static of = ClassStaticCache;

  constructor(state: GlobalContext) {
    state.Portal.create({
      children: <MessageList state={this} />,
    });
  }

  readonly ns = new Map<string | number, MessageOperator>();

  readonly List = new PortalContainer<{
    readonly op: MessageOperator;
  }>();
}

class CountDownTimer {
  state: "stopped" | "started" | "over" = "stopped";
  private timer?: ReturnType<typeof setTimeout>;
  private startedAt?: number;

  constructor(private rest: number, private readonly cb: () => void) {}

  start() {
    if (this.state !== "stopped") return;
    this.state = "started";
    this.startedAt = Date.now();
    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(this.over, this.rest);
  }

  stop() {
    if (this.state !== "started") return;
    this.state = "stopped";
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    const duration = Date.now() - this.startedAt!;
    if (duration < this.rest) {
      this.rest -= duration;
    } else {
      this.over();
    }
  }

  private over = () => {
    if (this.state === "over") return;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.state = "over";
    this.cb();
  };
}

class MessageOperator {
  readonly key?: string | number;

  readonly events = new EventEmitter<{
    update(): void;
    opened(op: MessageOperator): void;
    close(op: MessageOperator): void;
    closed(op: MessageOperator): void;
  }>();

  disposed?: boolean;

  entered?: boolean;

  closing?: boolean;

  timer?: CountDownTimer;

  setTimerTime = 0;

  restDuration = 0;

  register!: PortalRegister<{
    readonly op: MessageOperator;
  }>;

  constructor(readonly ctx: MessageContext, public props: MessageProps) {
    this.key = props.key;
  }

  update(props: MessageProps) {
    if (this.disposed || this.closing) return;
    this.props = props;
    this.setTimer();
    this.events.emit("update");
  }

  private dispose() {
    if (this.disposed) return;
    this.disposed = true;
    this.clearTimer();
  }

  private setTimer() {
    this.clearTimer();
    const duration = formatDuration(this.props.duration ?? 3000);
    if (duration) {
      const timer = (this.timer = new CountDownTimer(
        duration,
        this.triggerClose
      ));
      timer.start();
    }
  }

  private clearTimer() {
    if (this.timer) {
      this.timer.stop();
      this.timer = undefined;
    }
  }

  close() {
    if (this.closing) return;
    this.clearTimer();
    this.triggerClose();
  }

  private triggerClose = () => {
    this.timer = undefined;
    this.closing = true;
    this.register.dispose();
    this.events.emit("close", this);
  };

  onEntered = () => {
    if (this.disposed || this.closing) return;
    this.entered = true;
    this.setTimer();
    this.events.emit("opened", this);
  };

  onExited = () => {
    if (this.disposed) return;
    this.dispose();
    if (this.key !== undefined) {
      this.ctx.ns.delete(this.key);
    }
    this.events.emit("closed", this);
  };

  onMouseEnter = () => {
    const { timer } = this;
    if (!timer) return;
    if (this.props.pauseOnHover) {
      timer.stop();
    }
  };

  onMouseLeave = () => {
    const { timer } = this;
    if (!timer) return;
    if (timer.state === "stopped") {
      timer.start();
    }
  };
}

function formatDuration(x: unknown) {
  if (typeof x === "number" && Number.isFinite(x) && x > 0) {
    return x;
  }
  return 0;
}

function MessageList({ state }: { readonly state: MessageContext }) {
  return useObserver(() => {
    const items = Array.from(state.List.items);

    // 当这个 component 被 unmount 的时候, 不会触发 onExited, 需要手动触发
    React.useEffect(
      () => () => {
        for (const item of state.List.items) {
          item.props.op.onExited();
        }
      },
      EMPTY_ARRAY
    );

    return (
      <Popup
        useMask={false}
        render={({ container }) => {
          container.style.zIndex = "1001";
          return (
            <List>
              <TransitionGroup component={null}>
                {items.map(({ id, props: { op } }) => {
                  return (
                    <CSSTransition
                      key={id}
                      classNames="fade"
                      timeout={300}
                      in
                      appear
                      unmountOnExit
                      onEntered={op.onEntered}
                      onExited={op.onExited}
                    >
                      <MessageItem op={op} />
                    </CSSTransition>
                  );
                })}
              </TransitionGroup>
            </List>
          );
        }}
      />
    );
  });
}

const MessageTypeEnumSet = castReadonly(
  new Set(
    typedValuesOf<KeyMap<MessageType>>({
      info: "info",
      success: "success",
      warning: "warning",
      error: "error",
    })
  )
);

export const isMessageType = createValueInSetGuardian(MessageTypeEnumSet);

export const formatMessageType = createValueInSetFormatter(
  MessageTypeEnumSet,
  "info"
);

const ICONS = {
  info: () => WarningIcon,
  success: () => SuccessIcon,
  warning: () => WarningIcon,
  error: () => ErrorIcon,
} as const;

const MessageItem = React.memo(({ op }: { readonly op: MessageOperator }) => {
  const forceUpdate = useForceUpdate();

  React.useEffect(() => op.events.subscribe("update", forceUpdate), [op]);

  const { props } = op;
  const {
    key,
    type: _type,
    content,
    description,
    duration: _duration,
    className,
    closable,
    through,
    ...rest
  } = props;
  const type = formatMessageType(_type);
  const Icon = (ICONS[type] || ICONS.info)();
  return (
    <Message
      {...rest}
      className={`${className || ""} ${through ? "through" : ""} type-${type}`}
      onMouseEnter={op.onMouseEnter}
      onMouseLeave={op.onMouseLeave}
    >
      <div className="icon">
        <Icon />
      </div>
      <div className="content">
        <div>{content}</div>
        <div className="description">{description}</div>
      </div>
      {!closable ? null : (
        <div className="close">
          <IconBtn component={CloseOutlined} onClick={() => op.close()} />
        </div>
      )}
    </Message>
  );
});
MessageItem.displayName = "MessageItem";

const List = styled.div`
  position: absolute;
  top: 72px;
  width: max-content;
  max-width: 80vw;
  transform: translate(-50%);
  left: 50vw;
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
`;
List.displayName = "List";

const Message = styled.div`
  position: relative;
  width: max-content;
  max-width: 100%;
  padding: 8px 16px;
  background: #fff;
  border: 1px solid;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 24px;
  display: flex;

  &:last-child {
    margin-bottom: 0;
  }

  &.fade-appear,
  &.fade-enter {
    opacity: 0;
    margin-top: -32px;
  }

  &.fade-appear-active,
  &.fade-enter-active {
    margin-top: 0;
    opacity: 1;
    transition: all 0.3s;
  }

  &.fade-exit {
    margin-top: 0;
    opacity: 1;
  }

  &.fade-exit-active {
    opacity: 0;
    margin-top: -32px;
    transition: all 0.2s ease-in;
  }

  &.through {
    pointer-events: none;
  }

  & > .icon {
    flex: 0 0 auto;
    position: relative;
    margin-right: 8px;
    vertical-align: baseline;
    display: inline;
    top: 0.125em;
  }

  & > .content {
    flex: 1 1 auto;
    user-select: auto;

    & > .description {
      color: #a0a0a0;
      font-size: 12px;
      line-height: 16px;
    }
  }

  & > .close {
    flex: 0 0 auto;
    margin-left: 8px;
    pointer-events: auto;
  }

  &.type-info {
    background-color: #e6f7ff;
    border-color: #91d5ff;

    & > .icon {
      color: #1890ff;
    }
  }

  &.type-success {
    background-color: #f6ffed;
    border-color: #b7eb8f;

    & > .icon {
      color: #52c41a;
    }
  }

  &.type-warning {
    background-color: #fffbe6;
    border-color: #ffe58f;

    & > .icon {
      color: #faad14;
    }
  }

  &.type-error {
    background-color: #fff1f0;
    border-color: #ffccc7;

    & > .icon {
      color: #eb5757;
    }
  }
`;
Message.displayName = "Message";
