import * as tinycolor from 'tinycolor2'

/**
 * 将任意格式的 color 和 opacity 混合数据项转化为标准的 tinycolor 对象
 * @param color 颜色
 * @param opacity 透明度
 */
export function createTinyColor(color?: string, opacity?: number) {
  let tc = tinycolor(color || 'transparent')
  if (!tc.isValid()) tc = tinycolor('transparent')
  tc.setAlpha(tc.getAlpha() * formatOpacity(opacity))
  return tc
}

export function formatOpacity(x: unknown) {
  if (typeof x === 'number' && Number.isFinite(x)) {
    return x < 0 ? 0 : x > 1 ? 1 : x
  }
  return 1
}
