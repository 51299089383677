// tslint:disable max-line-length

import * as React from "react";
import styled from "styled-components";
import Icon from "@ant-design/icons";
import {
  runInAction,
  action,
  computed,
  observable,
  autorun,
  makeObservable,
} from "mobx";
import { useObserver } from "mobx-react";
import * as cx from "classnames";
import {
  AnyModelType,
  FrameChildModelType,
} from "@chuyuan/poster-data-access-layer";
import {
  isEqual,
  EMPTY_ARRAY,
  Disposers,
  compareArray,
  setDifferenceMutable,
} from "@chuyuan/poster-utils";
import { isMac } from "../../utils/device-detect";

import { LocaleContext } from "../../utils/i18n";
import { useModule } from "../../utils/modulize";
import { EventSystemModuleToken, SelectGDLObject } from "../event-system";
import type { EventHost } from "../../utils/event-system";
import { SessionState } from "../editor-state/session-state";
import { SelectableTarget } from "../editor-state/types";
import { getOptimisticSelection } from "../helpers/selection-filter";
import { getGDLTargetName } from "../gdl-common/target-names";
import DebugLocalStorage from "../debug/local-storage";
import { getGDLTargetIconComponent } from "../gdl-common/target-icons";
import { stopPropagation } from "../../utils/dom";
import { ObservableWeakMap } from "../../utils/mobx-observable-weak-map";
import { callContextMenu } from "../gdl-common/popup";
import { GlobalContextModuleToken } from "../ui-components/global-context";
import {
  CollapseArrow,
  LayerInvisible,
  LayerVisible,
} from "../../../../poster-ui/icons/new-icons";

export type DropSide = "before" | "after" | "center";

export abstract class CommonRootState<T extends CommonNodeState> {
  readonly collapsed = new ObservableWeakMap<object, boolean>();

  abstract readonly props: {
    readonly session: SessionState;
  };

  /**
   * 获取展开的对象
   * @param added 新加的选中项
   */
  abstract getExpandedTargets(
    added: ReadonlySet<SelectableTarget>
  ): ReadonlySet<SelectableTarget>;

  abstract setCollapsedBatch(
    set: ReadonlySet<SelectableTarget>,
    value: boolean
  ): void;

  readonly disposers = new Disposers();

  readonly allStates = new Set<T>();

  readonly asyncScrollIntoViewCommandMap = new WeakMap<
    SelectableTarget,
    {
      readonly expireAt: number;
    }
  >();

  previousSelectedPaths: ReadonlyArray<readonly number[]> = EMPTY_ARRAY;

  private _eventSystem?: EventHost;

  get selection() {
    const { session } = this.props;
    return getOptimisticSelection(session).set;
  }

  setEventSystem(value?: EventHost) {
    if (value === this._eventSystem) return;
    this._eventSystem = value;
  }

  applySelectionChanges(
    prev: ReadonlySet<SelectableTarget>,
    next: ReadonlySet<SelectableTarget>
  ) {
    const added = setDifferenceMutable(new Set(next), prev);
    const expanded = this.getExpandedTargets(added);
    this.setCollapsedBatch(expanded, false);

    // 添加异步滚动指令
    const { asyncScrollIntoViewCommandMap } = this;
    const expireAt = Date.now() + 500;
    for (const target of added) {
      asyncScrollIntoViewCommandMap.set(target, { expireAt });
    }
    const removed = setDifferenceMutable(new Set(prev), next);
    for (const target of removed) {
      asyncScrollIntoViewCommandMap.delete(target);
    }
  }

  mount() {
    this.disposers.override(
      "session change",
      autorun(() => {
        const { selection } = this.props.session;
        let prev = selection.set;
        this.disposers.override(
          "selection change",
          selection.events.subscribe("change", () => {
            // console.log('event-change');
            this.previousSelectedPaths = EMPTY_ARRAY;
            const next = selection.set;
            const curr = prev;
            prev = next;
            this.applySelectionChanges(curr, next);
          })
        );
      })
    );
  }

  unmount() {
    this.disposers.clear();
  }
}

export abstract class CommonNodeState {
  abstract readonly props: {
    readonly target: AnyModelType;
    readonly root: CommonRootState<CommonNodeState>;
    readonly parent:
      | null
      | (CommonNodeState & {
          readonly children: readonly FrameChildModelType[];
        });
  };

  abstract readonly arrow?: "collapsed" | "expanded";

  abstract readonly ghost?: boolean;

  abstract readonly disabled?: boolean;

  abstract readonly draggable?: boolean;

  abstract readonly droppable?: boolean;

  abstract readonly dropAreaCenter?: boolean;

  entryRef: HTMLElement | null = null;

  prevIsSelected?: boolean;

  @observable.ref
  isEditing = false;

  constructor() {
    makeObservable(this);
  }

  @computed
  get isSelected(): boolean {
    const { target, root } = this.props;
    return root.selection.has(target);
  }

  @computed
  get path(): readonly number[] {
    const { parent } = this.props;
    if (!parent) return EMPTY_ARRAY;
    const index = parent.children.indexOf(
      this.props.target as FrameChildModelType
    );
    return [...parent.path, index];
  }

  @computed
  get isAncestorSelected(): boolean {
    const { parent } = this.props;
    if (!parent) return false;
    return parent.isSelected || parent.isAncestorSelected;
  }

  *iteratePathRange(from: readonly number[], to: readonly number[]) {
    if (compareArray(from, to) > 0) {
      // 保证 from <= to
      [from, to] = [to, from];
    }
    const states = this.props.root.allStates;
    for (const state of states) {
      const { path } = state;
      if (isPathBetween(from, to, path)) {
        yield state.props.target;
      }
    }
  }

  getSelectedPathRange() {
    let data: { from: readonly number[]; to: readonly number[] } | undefined;
    const states = this.props.root.allStates;
    for (const state of states) {
      if (!state.isSelected) continue;
      const { path } = state;
      if (data) {
        if (compareArray(path, data.from) < 0) data.from = path;
        if (compareArray(data.to, path) < 0) data.to = path;
      } else {
        data = { from: path, to: path };
      }
    }
    return data;
  }

  selectRange() {
    const { root, target } = this.props;
    const { path } = this;
    const { selection } = root.props.session;
    const paths = root.previousSelectedPaths;
    if (paths.length) {
      selection.add(target);
      selection.add(...this.iteratePathRange(path, paths[paths.length - 1]));
      return;
    }
    if (root.selection.size) {
      const data = this.getSelectedPathRange();
      if (data) {
        if (compareArray(path, data.from) < 0) {
          selection.add(target);
          selection.add(...this.iteratePathRange(path, data.from));
          return;
        }
        if (compareArray(data.to, path) < 0) {
          selection.add(target);
          selection.add(...this.iteratePathRange(data.to, path));
          return;
        }
      }
    }
    // root.applyForceExpandTargets()
    selection.replace(target);
  }

  mount() {
    this.props.root.allStates.add(this);
  }

  unmount() {
    const { target, root } = this.props;
    root.allStates.delete(this);
    root.props.session.ui.hovering.delete(target, "sidebar hover");
  }

  get isNameLocked() {
    const { target } = this.props;
    return target.kind === "frame" && !target.parent();
  }

  onDragStart(e: React.DragEvent<HTMLDivElement>) {
    e.stopPropagation();
    e.dataTransfer.effectAllowed = "move";
    // 创建空图片代替默认的拖动图像 (因为挡住了画面)
    const emptyImage = document.createElement("canvas");
    emptyImage.width = 1;
    emptyImage.height = 1;
    // chrome 下必须 append 到body, 否则会显示一个地球图标
    document.body.append(emptyImage);
    e.dataTransfer.setDragImage(emptyImage, 0, 0);
    this.handleDragInit(e);
    // 用 drag 事件解决 chrome 下 DOM 有改变时 dragend 被立马触发的问题
    // @see https://stackoverflow.com/questions/28408720/jquery-changing-the-dom-on-dragstart-event-fires-dragend-immediately/44927892#44927892
    const onDrag = (e1: DragEvent) => {
      // 只要setDragImage完事就可以 remove 掉了
      document.body.removeChild(emptyImage);
      // drag事件只监听一次
      window.removeEventListener("drag", onDrag, { capture: true });
      this.handleDragStart(e1);
      const onFinish = action("reorder finish", (e2: Event) => {
        window.removeEventListener("drop", onFinish);
        window.removeEventListener("dragend", onFinish);
        window.removeEventListener("ondragexit", onFinish);
        this.handleDragFinish(e2);
      });
      window.addEventListener("drop", onFinish);
      window.addEventListener("dragend", onFinish);
      window.addEventListener("ondragexit", onFinish);
    };
    window.addEventListener("drag", onDrag, { capture: true });
  }

  onDrop(_event: React.DragEvent<HTMLDivElement>, _side: DropSide) {}

  protected handleDragInit(_e: React.DragEvent<HTMLDivElement>): void {}

  protected handleDragStart(_e: DragEvent): void {}

  protected handleDragFinish(_e: Event): void {}

  abstract onToggleCollapsed(): void;
}

export const Node = React.memo((props: { readonly state: CommonNodeState }) => {
  const { state } = props;

  const eventSystem = useModule(EventSystemModuleToken);

  const ctx = useModule(GlobalContextModuleToken);

  const locale = React.useContext(LocaleContext);

  const [entryRef, setEntryRef] = React.useState<HTMLElement | null>(null);

  React.useEffect(() => (state.mount(), () => state.unmount()), [state]);

  return useObserver(() => {
    const { path } = state;
    const { target, root } = state.props;
    const { session } = root.props;
    const { ui } = session;
    const indent = path.length;

    const displayName = getGDLTargetName(target, locale);

    const { isEditing, isSelected } = state;

    if (entryRef) {
      const map = root.asyncScrollIntoViewCommandMap;
      const cmd = map.get(target);
      if (cmd) {
        if (cmd.expireAt > Date.now()) {
          entryRef.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "nearest",
          });
        }
        map.delete(target);
      }
    }

    const isHovering = !!ui.hovering.get(target);

    let test = "";
    if (DebugLocalStorage.debug) {
      // tslint:disable-next-line: no-collapsible-if
      if (target.kind === "frame") {
        const containerType = target.layout.containerType;
        if (containerType === "flex list" || containerType === "flex pile") {
          const direction = target.layout.flexbox.childrenDirection;
          const n = containerType === "flex list" ? "l" : "p";
          test = `(${n}${direction === "row" ? "→" : "↓"})`;
        }
      }
    }

    let disabled = state.disabled;
    if (!disabled) {
      const selectableTypes = ui.selectableTypes.value;
      if (!selectableTypes.has(target.kind)) {
        disabled = true;
      }
    }

    const { arrow } = state;

    const intentLength = 18;
    const padding = intentLength * indent;

    return (
      <TargetEntry
        ref={setEntryRef}
        draggable={state.draggable}
        className={cx({
          selected: isSelected,
          hover: isHovering,
          "ancestor-selected": state.isAncestorSelected,
          disabled,
          ghost: state.ghost,
        })}
        onMouseEnter={() =>
          runInAction(() => {
            ui.hovering.set(target, true, "sidebar hover");
          })
        }
        onMouseLeave={() =>
          runInAction(() => {
            ui.hovering.set(target, false, "sidebar hover");
          })
        }
        onDoubleClick={
          disabled || state.isNameLocked
            ? undefined
            : (e) =>
                runInAction(() => {
                  e.preventDefault();
                  e.stopPropagation();
                  state.isEditing = true;
                })
        }
        onClick={
          disabled
            ? undefined
            : (e) =>
                runInAction(() => {
                  if (eventSystem) {
                    const event = eventSystem.dispatch(SelectGDLObject, {
                      target,
                    });
                    if (event.isDefaultPrevented()) return;
                  }
                  // 这里有一套多选操作
                  const { selection } = session;
                  const metaKey = isMac() ? e.metaKey : e.ctrlKey;
                  if (metaKey) {
                    if (selection.has(target)) {
                      // 去除选中, 同时去除最后选择id
                      selection.delete(target);
                      const paths = root.previousSelectedPaths;
                      if (
                        paths.length &&
                        isEqual(paths[paths.length - 1], path)
                      ) {
                        root.previousSelectedPaths = paths.slice(0, -1);
                      }
                    } else {
                      // 添加选中, 同时加到最后选择id
                      selection.add(target);
                      root.previousSelectedPaths = [path];
                    }
                  } else {
                    if (e.shiftKey) {
                      // 一条龙多选
                      state.selectRange();
                    } else {
                      // 单选
                      // root.applyForceExpandTargets()
                      selection.replace(target);
                    }
                    root.previousSelectedPaths = [path];
                  }
                })
        }
        onContextMenu={
          disabled
            ? undefined
            : (e) =>
                runInAction(() => {
                  if (eventSystem) {
                    const event = eventSystem.dispatch(SelectGDLObject, {
                      target,
                    });
                    if (event.isDefaultPrevented()) return;
                  }

                  const { selection } = session;
                  if (!selection.has(target)) {
                    const metaKey = isMac() ? e.metaKey : e.ctrlKey;
                    if (metaKey) {
                      selection.add(target);
                    } else {
                      selection.replace(target);
                    }
                  }
                  const selectionCache = Array.from(selection);
                  callContextMenu(ctx, e.clientX, e.clientY, {
                    getTargets: () => selectionCache,
                  });
                })
        }
        onDragStart={
          disabled
            ? undefined
            : (e) =>
                runInAction(() => {
                  state.onDragStart(e);
                })
        }
      >
        {/* 前置padding */}
        <div style={{ width: padding, flex: "0 0 auto" }} />

        {/* 下拉按钮 */}
        {!arrow ? (
          <ArrowIcon className="arrow hidden" />
        ) : (
          <ArrowIcon
            className={`arrow ${arrow}`}
            onClick={(e: any) => {
              e.stopPropagation();
              e.preventDefault();
              state.onToggleCollapsed();
            }}
          />
        )}

        {/* 对象图标 */}
        <Icon
          className="target-icon"
          component={getGDLTargetIconComponent(target)}
        />

        {/* 对象名及其输入框 */}
        <div className={cx("node-name", isEditing ? "isEditing" : "")}>
          <div className="text">
            {displayName}
            {test ? " " + test : ""}
          </div>
          {isEditing && target !== session.root && (
            <NodeNameEditor
              initialValue={target.properties.name || displayName}
              onChange={(value) =>
                runInAction(() => {
                  if (target.properties.name !== value) {
                    target.properties.setName(value);
                  }
                  state.isEditing = false;
                })
              }
            />
          )}
        </div>

        {/* 操作图标 */}
        {target === session.root
          ? null
          : renderActions({ isHovering, target, session })}

        {/* drop区域 */}
        {state.droppable && (
          <>
            <DropArea
              side="before"
              padding={padding + 22}
              onDrop={state.onDrop}
            />
            <DropArea
              side="after"
              padding={padding + 22}
              onDrop={state.onDrop}
            />
            {state.dropAreaCenter && (
              <DropArea
                side="center"
                padding={padding + 22 + intentLength}
                onDrop={state.onDrop}
              />
            )}
          </>
        )}
      </TargetEntry>
    );
  });
});
Node.displayName = "Node";

function NodeNameEditor(props: {
  readonly initialValue: string;
  readonly onChange: (value: string) => void;
}) {
  const [value, setValue] = React.useState(props.initialValue);

  const triggerChange = () => props.onChange(value);

  return (
    <input
      className="input"
      autoFocus
      value={value}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          triggerChange();
        }
      }}
      onChange={(e) => setValue(e.target.value)}
      onBlur={triggerChange}
      onClick={stopPropagation}
      onContextMenu={stopPropagation}
    />
  );
}

function DropArea(props: {
  readonly side: DropSide;
  readonly padding: number;
  readonly onDrop?: (
    event: React.DragEvent<HTMLDivElement>,
    side: DropSide
  ) => void;
}) {
  const { side, padding, onDrop } = props;
  const [hover, setHover] = React.useState(false);
  return (
    <DropAreaDOM
      className={cx(side, { hover })}
      style={{ left: padding }}
      onDragOver={() => setHover(true)}
      onDragLeave={() => setHover(false)}
      onDrop={(e) => {
        setHover(false);
        runInAction(() => onDrop?.(e, side));
      }}
    />
  );
}

function renderActions(props: {
  readonly isHovering: boolean;
  readonly target: FrameChildModelType;
  readonly session: SessionState;
}) {
  const { isHovering, target, session } = props;

  const className = cx({ hover: isHovering, always: false });

  return (
    <ActionContainer className={className}>
      {renderVisibleAction({ target, session })}
    </ActionContainer>
  );
}

function renderVisibleAction(props: {
  readonly target: FrameChildModelType;
  readonly session: SessionState;
}) {
  const { target, session } = props;
  const visible = !target.properties.disabled;
  return (
    <Icon
      component={visible ? LayerVisible : LayerInvisible}
      className={cx("action", "btn", { always: !visible })}
      onClick={(e: any) =>
        runInAction(() => {
          e.stopPropagation();
          const newValue = !target.properties.disabled;
          target.properties.setDisabled(newValue);
          if (newValue) {
            session.history.push({ name: "隐藏元素" });
          } else {
            session.history.push({ name: "显示元素" });
          }
        })
      }
    />
  );
}

function isPathBetween(
  from: readonly number[],
  to: readonly number[],
  target: readonly number[]
) {
  return compareArray(from, target) <= 0 && compareArray(target, to) <= 0;
}

// 对象条目
const TargetEntry = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 16px;
  color: #a0a0a0;
  user-select: none;
  border: 1px solid transparent;

  .node-name {
    position: relative;
    flex: 1 1 0;
    margin-left: 8px;
    overflow: hidden;

    .text {
      font-size: 12px;
      letter-spacing: 0.2px;
      color: #202020;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .input {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      padding: 0;
      border: 0;
      font-size: 12px;
      letter-spacing: 0.2px;
      color: #101010;
      background: transparent;

      &:focus {
        outline: 0;
      }
    }

    &.isEditing .text {
      visibility: hidden;
    }
  }

  &:not(.selected) {
    &:hover,
    &.hover {
      border-color: #0040f0;
    }
  }

  &.ancestor-selected {
    background: rgba(47, 128, 237, 0.05);
  }

  &.selected {
    background: rgba(47, 128, 237, 0.1);
  }

  &.ghost,
  &.disabled {
    opacity: 0.5;
    border-color: transparent;
  }

  &.disabled {
    cursor: not-allowed;

    & > *:not(.arrow) {
      pointer-events: none;
    }
  }
`;

const ArrowIcon = styled(Icon).attrs({ component: CollapseArrow })`
  margin-left: -2px;
  margin-right: 4px;
  transition: all 0.2s;
  cursor: pointer;

  &.collapsed {
    transform: rotate(-90deg);
  }

  &.hidden {
    visibility: hidden;
  }
`;

const ActionContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  max-width: 0;
  font-size: 12px;
  margin-left: 2px;
  transition: all 0.2s;

  &.hover,
  &.always {
    max-width: 36px;
  }

  .action {
    opacity: 0;
  }

  .action.always,
  &.hover .action {
    opacity: 1;
  }

  .btn {
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    line-height: 20px;
    margin-left: 2px;
    padding: 0;
    border: 0;
    text-align: center;
  }
`;

const DropAreaDOM = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 50%;

  &.before {
    top: 0;
    border-top: 2px solid transparent;
  }

  &.after {
    bottom: 0;
    border-bottom: 2px solid transparent;
  }

  &.center {
    margin: auto;
    height: 33.3333%;

    &::after {
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      height: 100%;
      content: " ";
      pointer-events: none;
      border-bottom: 2px solid transparent;
    }
  }

  &.hover,
  &.hover::after {
    border-color: #0040f0;
  }
`;
