import * as React from "react";
import styled from "styled-components";
import Icon from "@ant-design/icons";

import { CloseOutlined } from "../../../../poster-ui/icons/new-icons";

export interface DialogProps {
  readonly className?: string;
  readonly style?: React.CSSProperties;
  readonly flexContent?: boolean;
  readonly title?: React.ReactNode;
  readonly closable?: boolean;
  readonly children?: React.ReactNode;
  readonly onClose?: () => void;
}

/**
 * 一个带有标题和关闭按钮的对话框
 */
export function Dialog(props: DialogProps) {
  const closable = props.closable ?? true;
  let { className } = props;
  if (props.flexContent) {
    className = className ? `${className} flex-content` : "flex-content";
  }
  const { title } = props;
  return (
    <Container className={className} style={props.style}>
      {title === undefined || title === null ? (
        <>
          {!closable ? null : (
            <div className="close" onClick={props.onClose}>
              <Icon className="icon" component={CloseOutlined} />
            </div>
          )}
        </>
      ) : (
        <div className="title">
          <div className="title-text">{props.title}</div>
          {!closable ? null : (
            <div className="close" onClick={props.onClose}>
              <Icon className="icon" component={CloseOutlined} />
            </div>
          )}
        </div>
      )}
      <div className="content">{props.children}</div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 8px 24px 3px rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  .title {
    display: flex;
    padding: 12px;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
    border-bottom: 1px solid #eae9e8;
  }

  .title-text {
    flex: 1 1 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #606060;
  }

  .close {
    margin-left: 8px;
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.65);

    &.absolute {
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }

  .content {
    padding: 12px;
  }

  .icon {
    cursor: pointer;
  }

  &.flex-content {
    .title {
      flex: 0 0 auto;
    }

    .content {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }
  }
`;
