import { observable, action, makeObservable } from "mobx";
import { memorize, EventEmitter, first } from "@chuyuan/poster-utils";
import { EventQueueItem, areEventQueueItemSame } from "./event-queue";

export type EventHandler<T extends Event = Event> = (event: T) => unknown;

export type PointerEventHandlerMap<T = unknown> = {
  "pointer down": EventHandler<PointerEvent<T>>;
  "pointer move": EventHandler<PointerEvent<T>>;
  "pointer up": EventHandler<PointerEvent<T>>;
  "pointer enter": EventHandler<PointerEvent<T>>;
  "pointer leave": EventHandler<PointerEvent<T>>;
  "pointer tap": EventHandler<PointerEvent<T>>;
  "pointer context menu": EventHandler<PointerEvent<T>>;
};

export type KeyboardEventHandlerMap<T = unknown> = {
  shortcut: EventHandler<KeyboardEvent<T>>;
};

export type EventHandlerMap<T = unknown> = PointerEventHandlerMap<T> &
  KeyboardEventHandlerMap<T>;

export interface EventLike {
  stopPropagation(): unknown;
  preventDefault(): unknown;
}

export interface MouseEventLike extends EventLike {
  readonly altKey: boolean;
  readonly ctrlKey: boolean;
  readonly metaKey: boolean;
  readonly shiftKey: boolean;
}

export class Events<S> {
  @observable.ref
  private _currentTarget?: EventQueueItem<S> = undefined;

  @observable.ref
  private _pointerDownTarget?: EventQueueItem<S> = undefined;

  private _events = new EventEmitter<EventHandlerMap<EventQueueItem<S>>>();

  constructor() {
    makeObservable(this);
  }

  get currentTarget() {
    return this._currentTarget;
  }

  @action
  pointerDown(
    iterable: Iterable<EventQueueItem<S>>,
    x: number,
    y: number,
    absoluteX: number,
    absoluteY: number,
    nativeEvent: MouseEventLike
  ) {
    const target = (this._currentTarget = first(iterable));
    const targets = target ? [target] : [];

    const event = new PointerEvent({
      targets,
      x,
      y,
      absoluteX,
      absoluteY,
      nativeEvent,
    });

    // 调用当前对象的按下方法
    if (target) {
      callHandler(target.pointer!.handlers["pointer down"], event);
    }
    if (event.isPropagationStopped()) return;

    // 触发全局按下事件
    this._emit("pointer down", event);

    this._pointerDownTarget = target;
  }

  @action
  pointerMove(
    iterable: Iterable<EventQueueItem<S>>,
    x: number,
    y: number,
    absoluteX: number,
    absoluteY: number,
    nativeEvent: MouseEventLike
  ) {
    const currentTarget = this.currentTarget;
    const currentTargets = currentTarget ? [currentTarget] : [];

    const nextTarget = (this._currentTarget = first(iterable));
    const nextTargets = nextTarget ? [nextTarget] : [];

    // 触发移动方法
    {
      const moveEvent = new PointerEvent({
        targets: nextTargets,
        x,
        y,
        absoluteX,
        absoluteY,
        nativeEvent,
      });

      // 调用当前对象的移动方法
      if (nextTarget) {
        callHandler(nextTarget.pointer!.handlers["pointer move"], moveEvent);
      }
      if (moveEvent.isPropagationStopped()) return;

      // 触发全局移动事件
      this._emit("pointer move", moveEvent);
      if (moveEvent.isPropagationStopped()) return;
    }

    // 触发元素出入方法
    {
      const leaveEvent = new PointerEvent({
        targets: currentTargets,
        x,
        y,
        absoluteX,
        absoluteY,
        nativeEvent,
      });
      const enterEvent = new PointerEvent({
        targets: nextTargets,
        x,
        y,
        absoluteX,
        absoluteY,
        nativeEvent,
      });

      if (currentTarget) {
        if (nextTarget) {
          if (currentTarget !== nextTarget) {
            // 触发当前对象的离开事件
            callHandler(
              currentTarget.pointer!.handlers["pointer leave"],
              leaveEvent
            );
            if (leaveEvent.isPropagationStopped()) return;

            // 触发下一个对象的进入事件
            callHandler(
              nextTarget.pointer!.handlers["pointer enter"],
              enterEvent
            );
            if (enterEvent.isPropagationStopped()) return;

            // 触发全局离开事件
            this._emit("pointer leave", leaveEvent);
            if (leaveEvent.isPropagationStopped()) return;

            // 触发全局进入事件
            this._emit("pointer enter", enterEvent);
            if (enterEvent.isPropagationStopped()) return;
          }
        } else {
          // 触发当前对象的离开事件
          callHandler(
            currentTarget.pointer!.handlers["pointer leave"],
            leaveEvent
          );
          if (leaveEvent.isPropagationStopped()) return;

          this._emit("pointer leave", leaveEvent);
          if (leaveEvent.isPropagationStopped()) return;
        }
      } else {
        if (nextTarget) {
          // 触发下一个对象的进入事件
          callHandler(
            nextTarget.pointer!.handlers["pointer enter"],
            enterEvent
          );
          if (enterEvent.isPropagationStopped()) return;

          this._emit("pointer enter", enterEvent);
          if (enterEvent.isPropagationStopped()) return;
        }
      }
    }
  }

  @action
  pointerUp(
    iterable: Iterable<EventQueueItem<S>>,
    x: number,
    y: number,
    absoluteX: number,
    absoluteY: number,
    nativeEvent: MouseEventLike
  ) {
    const target = (this._currentTarget = first(iterable));
    const targets = target ? [target] : [];
    const event = new PointerEvent({
      targets,
      x,
      y,
      absoluteX,
      absoluteY,
      nativeEvent,
    });

    // 调用当前对象的弹起方法
    if (target) {
      callHandler(target.pointer!.handlers["pointer up"], event);
    }
    if (event.isPropagationStopped()) return;

    // 触发全局弹起事件
    this._emit("pointer up", event);
    if (event.isPropagationStopped()) return;

    // 调用当前对象的点击事件
    const same = areEventQueueItemSame(this._pointerDownTarget, target);

    this._pointerDownTarget = undefined;

    if (target && same) {
      callHandler(target.pointer!.handlers["pointer tap"], event);
      if (event.isPropagationStopped()) return;
    }

    // 触发全局点击事件
    this._emit("pointer tap", event);
  }

  @action
  pointerContextMenu(
    iterable: Iterable<EventQueueItem<S>>,
    x: number,
    y: number,
    absoluteX: number,
    absoluteY: number,
    nativeEvent: MouseEventLike
  ) {
    const targets = Array.from(iterable);
    const target = first(targets);
    const event = new PointerEvent({
      targets,
      x,
      y,
      absoluteX,
      absoluteY,
      nativeEvent,
    });

    // 调用当前对象的弹起方法
    if (target) {
      callHandler(target.pointer!.handlers["pointer context menu"], event);
    }
    if (event.isPropagationStopped()) return;

    // 触发全局弹起事件
    this._emit("pointer context menu", event);
  }

  on<K extends keyof EventHandlerMap>(
    name: K,
    fn: EventHandlerMap<EventQueueItem<S>>[K]
  ) {
    this._events.on(name, fn);
    return this;
  }

  off<K extends keyof EventHandlerMap>(
    name: K,
    fn: EventHandlerMap<EventQueueItem<S>>[K]
  ) {
    this._events.off(name, fn);
    return this;
  }

  private _emit<K extends keyof EventHandlerMap>(
    name: K,
    ...args: Parameters<EventHandlerMap<EventQueueItem<S>>[K]>
  ) {
    let has = false;
    const [event] = args;
    for (const __ of this._events.emitIterable(name, ...args)) {
      has = true;
      if (event.isPropagationStopped()) {
        return has;
      }
    }
    return has;
  }
}

let NEXT_ID = 1;

export interface EventProps<T> {
  readonly targets: readonly T[];
}

export class Event<T = unknown> {
  /**
   * 事件id
   */
  readonly id = NEXT_ID++;

  /**
   * 事件发生时间, 单位毫秒
   */
  readonly timestamp = Date.now();

  /**
   * 事件的目标对象列表
   */
  readonly targets: readonly T[];

  readonly type = "event" as const;

  private _isPropagationStopped = false;

  private _isDefaultPrevented = false;

  constructor(props: EventProps<T>) {
    this.targets = props.targets;
  }

  /**
   * 事件的首个目标对象
   */
  @memorize
  get target() {
    return first(this.targets);
  }

  /**
   * 事件是否被阻止冒泡
   */
  isPropagationStopped() {
    return this._isPropagationStopped;
  }

  /**
   * 事件是否被阻止默认行为
   */
  isDefaultPrevented() {
    return this._isDefaultPrevented;
  }

  /**
   * 阻止事件冒泡
   */
  stopPropagation() {
    this._isPropagationStopped = true;
  }

  /**
   * 阻止事件默认行为
   */
  preventDefault() {
    this._isDefaultPrevented = true;
  }
}

export interface PointerEventProps<T> extends EventProps<T> {
  readonly x: number;
  readonly y: number;
  readonly absoluteX: number;
  readonly absoluteY: number;
  readonly nativeEvent: MouseEventLike;
}

export class PointerEvent<T = unknown> extends Event<T> {
  /**
   * 鼠标相对于画布左上角的横向偏移值, 向右为正
   */
  readonly x: number;

  /**
   * 鼠标相对于画布左上角的纵向偏移值, 向下为正
   */
  readonly y: number;

  /**
   * 鼠标相对于画布DOM(非虚拟画布)左上角的横向偏移值, 向右为正
   */
  readonly absoluteX: number;

  /**
   * 鼠标相对于画布DOM(非虚拟画布)左上角的纵向偏移值, 向下为正
   */
  readonly absoluteY: number;

  readonly nativeEvent: MouseEventLike;

  constructor(props: PointerEventProps<T>) {
    super(props);
    this.x = props.x;
    this.y = props.y;
    this.absoluteX = props.absoluteX;
    this.absoluteY = props.absoluteY;
    this.nativeEvent = props.nativeEvent;
  }
}

export interface KeyboardEventProps<T> extends EventProps<T> {
  readonly keys: readonly string[];
}

export class KeyboardEvent<T = unknown> extends Event<T> {
  /**
   * 曾经按下的按键列表
   */
  readonly keys: readonly string[];

  constructor(props: KeyboardEventProps<T>) {
    super(props);
    this.keys = props.keys;
  }
}

export class ShortcutEvent<T = unknown> extends KeyboardEvent<T> {
  private _isKept = false;

  isKept() {
    return this._isKept;
  }

  keep() {
    this._isKept = true;
  }
}

function callHandler<T extends Event>(
  fn: EventHandler<T> | undefined,
  event: T
) {
  if (typeof fn === "function") {
    fn(event);
    return true;
  }
  return false;
}
