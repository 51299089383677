import { Frame, GDLLayoutSemantic, Image, Text } from "@chuyuan/poster-data-access-layer";
import { touchObjectField as t } from "@chuyuan/poster-utils";
import * as React from "react";

import { i18n } from "../../utils/i18n";
import { SessionState } from "../editor-state/session-state";
import { SelectableTarget } from "../editor-state/types";
import { message } from "../ui-components/message";
import { GlobalContext } from "../ui-components/global-context";

export function pasteContent({
  ctx,
  session,
  pasteTargetNodes,
  pasteSourceNodes,
}: {
  readonly ctx?: GlobalContext;
  readonly session: SessionState;
  readonly pasteSourceNodes?: readonly SelectableTarget[];
  readonly pasteTargetNodes?: readonly SelectableTarget[];
}) {
  if (!pasteSourceNodes) {
    const event = session.clipboard.get();
    if (!event || event.content.type !== "custom clipboard content selection") {
      return false;
    }
    pasteSourceNodes = Array.from(event.content.selection);
  }

  // 之所以要 reverse, 是因为下面添加的时候 index 是固定的, 所以后加的在前面
  const elementsToPaste = pasteSourceNodes.slice().reverse();

  // 1. 未选中的时候默认选中画布
  // 2. 同一个children只能有一个创建位点
  // 3. 位点取选中元素index的最小值, 如果选中了容器, 则为0

  if (!pasteTargetNodes) {
    const { selection } = session;
    const selectedNodes = Array.from(selection);
    pasteTargetNodes = selectedNodes.length ? selectedNodes : [session.root];
  }

  const addTargetsMap = new Map<Frame, number>();
  for (const node of pasteTargetNodes) {
    if (node.kind === "frame") {
      addTargetsMap.set(node, 0);
    } else {
      const parent = node.parent();
      if (parent) {
        const currIndex = addTargetsMap.get(parent);
        const myIndex = parent.children.indexOf(node);
        if (myIndex >= 0) {
          if (typeof currIndex === "number") {
            if (myIndex < currIndex) {
              addTargetsMap.set(parent, myIndex);
            }
          } else {
            addTargetsMap.set(parent, myIndex);
          }
        } else {
          addTargetsMap.set(parent, 0);
        }
      }
    }
  }

  // 再执行
  let count = 0;
  for (const layer of elementsToPaste) {
    for (const [container] of addTargetsMap) {
      let newNode;
      const { kind } = layer;
      if (kind === "text") {
        const oldData = layer.toJSON();
        const data = { ...oldData, id: undefined };
        const properties = t(data, "properties");
        properties.locked = false;
        properties.disabled = false;
        newNode = new Text(container.ds, data);
        container.setChildren([newNode, ...container.children]);
      } else if (kind === "image") {
        const oldData = layer.toJSON();
        const data = { ...oldData, id: undefined };
        const properties = t(data, "properties");
        properties.locked = false;
        properties.disabled = false;
        newNode = new Image(container.ds, data);
        container.setChildren([newNode, ...container.children]);
      } else if (kind === "frame") {
        const oldData = layer.toJSON();
        const data = { ...oldData, id: undefined };
        const properties = t(data, "properties");
        properties.locked = false;
        properties.disabled = false;
        newNode = new Frame(container.ds, data);
        container.setChildren([newNode, ...container.children]);
      } else {
        throw new Error(`Unhandled kind ${kind}`);
      }

      count++;

      const oldParent = layer.parent();
      if (oldParent !== container) {
        const oldContainerType = oldParent?.layout.containerType || "absolute";
        const newContainerType = container.layout.containerType;
        if (oldContainerType !== "absolute" && newContainerType === "absolute") {
          // 父元素从flex布局切换到自由布局
          const op = new GDLLayoutSemantic.Operator(newNode);
          op.applyFlexItemToAbsolute(oldContainerType);
        } else if (oldContainerType === "absolute" && newContainerType !== "absolute") {
          // 父元素从自由布局切换到flex布局
          const op = new GDLLayoutSemantic.Operator(newNode);
          op.applyAbsoluteToFlexItem();
        } else if (oldContainerType === "flex list" && newContainerType === "flex pile") {
          // 父元素从flex list切换到flex pile
          const op = new GDLLayoutSemantic.Operator(newNode);
          op.applyFlexListItemToFlexPileItem();
        }
      }
    }
  }

  if (count) {
    session.history.push({ name: "粘贴元素" });
    if (ctx) message.success(ctx, <粘贴成功 />);
  }
  return true;
}

export const 粘贴成功 = i18n({
  en: () => "Pasted successfully",
  zh: () => "粘贴成功",
});
