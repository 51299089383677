import * as React from "react";
import { useObserver } from "mobx-react";
import styled from "styled-components";
import { runInAction } from "mobx";
import { useEqualSet } from "../../utils/react-hooks";
import { Text } from "@chuyuan/poster-data-access-layer";
import { Fields } from "./state.ui";
import { IconBtn } from "../ui-components/icon-btn";
import { useLocale, i18n } from "../../utils/i18n";
import type { DuplexFieldLike } from "../../utils/multi-value";
import { Radio, RadioOption } from "../ui-components/radio";
import { getInputProps } from "../../utils/react-multi-value";
import type { TextDirectionType } from "./state.text";
import { EditorContext } from "../helpers/react-context";
import {
  TextDirectionH,
  TextDirectionV,
  TextDirectionHorizontalIcon,
  TextDirectionVerticalIcon,
} from "../../../../poster-ui/icons/new-icons";

export const TextDirectionValue = React.memo(
  (props: { readonly field: DuplexFieldLike<TextDirectionType> }) => {
    const { field } = props;

    const editor = React.useContext(EditorContext);
    const locale = useLocale();

    return useObserver(() => {
      const multiValueProps = getInputProps(field, locale);
      const disabled = multiValueProps.disabled;
      return (
        <>
          <Radio
            {...multiValueProps}
            className="iconic"
            disabled={disabled}
            onChange={(v) =>
              runInAction(() => {
                field.set(v);
                editor.session.history.push({ name: "修改文字方向" });
              })
            }
            options={Options}
          />
        </>
      );
    });
  }
);
TextDirectionValue.displayName = "TextDirectionValue";

/**@name 文字方向小组件 */
export const TextDirectionIconBtn = React.memo(
  (props: { readonly targets: ReadonlySet<Text> }) => {
    const fields = Fields.of(useEqualSet(props.targets));
    const field = fields.textDirection;

    const editor = React.useContext(EditorContext);
    const locale = useLocale();

    return useObserver(() => {
      const multiValueProps = getInputProps(field, locale);
      const value = multiValueProps.value;

      const [directionDisabled, setDirectionDisabled] = React.useState<boolean>(
        value === "horizontal" ? true : false
      );
      const directionValue = directionDisabled ? "horizontal" : "vertical";
      const DirectionIcon = directionDisabled
        ? TextDirectionHorizontalIcon
        : TextDirectionVerticalIcon;

      React.useEffect(() => {
        const directionValue = directionDisabled ? "horizontal" : "vertical";
        field.set(directionValue);
        editor.session.history.push({ name: "修改文字方向" });
      }, [directionDisabled]);

      /**@name 切换文字方向 */
      const onTextDirection = () => {
        console.log("directionValue", directionValue);
        setDirectionDisabled(!directionDisabled);
      };

      return (
        <>
          <TextDirectionIconDOM>
            <IconBtn
              className="fold"
              component={DirectionIcon}
              onClick={onTextDirection}
            />
          </TextDirectionIconDOM>
        </>
      );
    });
  }
);
TextDirectionIconBtn.displayName = "TextDirectionIconBtn";

const Options: ReadonlyArray<RadioOption<TextDirectionType>> = [
  {
    value: "horizontal",
    title: () => <TextDirectionH />,
    tooltip: { content: () => <横排字符 /> },
  },
  {
    value: "vertical",
    title: () => <TextDirectionV />,
    tooltip: { content: () => <竖排字符 /> },
  },
];

const TextDirectionIconDOM = styled.div`
  .anticon {
    font-size: 24px;
  }
`;

const 横排字符 = i18n({
  en: () => "Horizontal Layout",
  zh: () => "横排字符",
});

const 竖排字符 = i18n({
  en: () => "Vertical Layout",
  zh: () => "竖排字符",
});
