import * as React from "react";
import styled from "styled-components";

import { EMPTY_ARRAY, dethunk } from "@chuyuan/poster-utils";

import { UseState, useForceUpdate } from "../../utils/react-hooks";
import { Popup } from "./popup";
import { Locator, LocatorProps, Anchor } from "./popup-locator";
import { Bounding, pickBounding } from "../helpers/data-picker";
import { Raf } from "../../utils/raf";
import { TimerLoop } from "../../utils/timer-loop";

export type PickedLocatorProps = Pick<
  LocatorProps,
  "avoids" | "anchors" | "padding"
>;

export interface TooltipCommonProps extends PickedLocatorProps {
  readonly forceVisible?: boolean;
  readonly hoverable?: boolean;
  readonly delay?: number;
  readonly content?: React.ReactNode | (() => React.ReactNode);
}

class TooltipRefOperator {
  ref: HTMLElement | null = null;

  delay?: number;

  entered?: boolean;

  enterTimer?: ReturnType<typeof setTimeout>;

  leaveTimer?: ReturnType<typeof setTimeout>;

  prevRect?: Bounding;

  visible?: boolean;

  constructor(readonly parent: TooltipBaseState) {}

  replace(ref: HTMLElement | null) {
    if (this.ref === ref) return;
    if (this.ref) this.unmount(this.ref);
    this.ref = ref;
    if (ref) this.mount(ref);
  }

  mount(ref: HTMLElement) {
    ref.addEventListener("mouseenter", this.onEnter);
    ref.addEventListener("mouseleave", this.onLeave);
  }

  unmount(ref: Element) {
    ref.removeEventListener("mouseenter", this.onEnter);
    ref.removeEventListener("mouseleave", this.onLeave);
  }

  show() {
    this.visible = true;
    this.prevRect = this.ref
      ? pickBounding(this.ref.getBoundingClientRect())
      : undefined;
    this.parent.toggle();
  }

  hide() {
    this.visible = false;
    this.prevRect = undefined;
    this.parent.toggle();
  }

  onEnter = () => {
    this.entered = true;

    // 清除离开计时器
    if (this.leaveTimer) {
      clearTimeout(this.leaveTimer);
      this.leaveTimer = undefined;
    }

    const delay = this.delay || 0;
    if (delay > 0) {
      // 已经开始计时了, 等就行了 (不考虑 delay 的更新)
      if (this.enterTimer) return;
      this.enterTimer = setTimeout(() => {
        // 回调回来发现依然处于进入状态, 这才显示
        if (this.entered) {
          this.show();
        }
      }, delay);
    } else {
      // 新的 delay 为 0, 当场执行
      if (this.enterTimer) {
        clearTimeout(this.enterTimer);
        this.enterTimer = undefined;
      }
      this.show();
    }
  };

  onLeave = () => {
    this.entered = false;

    // 清除进入计时器
    if (this.enterTimer) {
      clearTimeout(this.enterTimer);
      this.enterTimer = undefined;
    }

    const delay = this.delay || 0;
    if (delay > 0) {
      // 已经开始计时了, 等就行了 (不考虑 delay 的更新)
      if (this.leaveTimer) return;
      this.leaveTimer = setTimeout(() => {
        // 回调回来发现依然处于离开状态, 这才隐藏
        if (!this.entered) {
          this.hide();
        }
      }, delay);
    } else {
      // 新的 delay 为 0, 当场执行
      if (this.leaveTimer) {
        clearTimeout(this.leaveTimer);
        this.leaveTimer = undefined;
      }
      this.hide();
    }
  };

  detect() {
    if (!this.ref) return false;
    const prev = this.prevRect;
    const rect = pickBounding(this.ref.getBoundingClientRect());
    if (
      prev &&
      prev.left === rect.left &&
      prev.right === rect.right &&
      prev.top === rect.top &&
      prev.bottom === rect.bottom
    )
      return false;
    this.prevRect = rect;
    return true;
  }

  dispose() {
    if (this.enterTimer) {
      clearTimeout(this.enterTimer);
      this.enterTimer = undefined;
    }
    if (this.leaveTimer) {
      clearTimeout(this.leaveTimer);
      this.leaveTimer = undefined;
    }
    const { ref } = this;
    if (ref) this.unmount(ref);
  }
}

abstract class TooltipBaseState {
  readonly target = new TooltipRefOperator(this);

  readonly popover = new TooltipRefOperator(this);

  isDetectionBound?: {
    dispose: () => void;
  };

  constructor(
    public visibleRef: UseState<boolean>,
    readonly forceUpdate: () => void
  ) {}

  getPopoverRef = (ref: HTMLElement | null) => {
    this.popover.replace(ref);
  };

  toggle() {
    const visible = this.target.visible || this.popover.visible;
    if (visible === this.visibleRef[0]) return;
    if (visible) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    if (!this.visibleRef[0]) {
      this.visibleRef[1](true);
    }
    if (!this.isDetectionBound) {
      if (
        typeof requestIdleCallback === "function" &&
        typeof cancelIdleCallback === "function"
      ) {
        const timer = new TimerLoop(
          requestIdleCallback,
          cancelIdleCallback,
          () => this.detect()
        );
        timer.start();
        this.isDetectionBound = { dispose: () => timer.stop() };
      } else if (
        typeof requestAnimationFrame === "function" &&
        typeof cancelAnimationFrame === "function"
      ) {
        const timer = new TimerLoop(
          requestAnimationFrame,
          cancelAnimationFrame,
          () => this.detect()
        );
        timer.start();
        this.isDetectionBound = { dispose: () => timer.stop() };
      } else {
        const raf = new Raf();
        const onWheel = () => raf.push(() => this.detect());
        window.addEventListener("wheel", onWheel);
        this.isDetectionBound = {
          dispose: () => {
            raf.clear();
            window.removeEventListener("wheel", onWheel);
          },
        };
      }
    }
  }

  hide() {
    if (this.isDetectionBound) {
      this.isDetectionBound.dispose();
      this.isDetectionBound = undefined;
    }
    if (this.visibleRef[0]) {
      this.visibleRef[1](false);
    }
  }

  detect() {
    let changed = false;
    for (const op of [this.target, this.popover]) {
      const ret = op.detect();
      if (ret) changed = true;
    }
    if (changed) this.forceUpdate();
  }

  effect = () => () => {
    this.isDetectionBound?.dispose();
    this.target.dispose();
    this.popover.dispose();
  };
}

export type TooltipChildren =
  | React.ReactElement<React.RefAttributes<HTMLElement>>
  | ((getRef: (ref: HTMLElement | null) => void) => React.ReactNode);

export interface TooltipProps extends TooltipCommonProps {
  readonly children: TooltipChildren;
}

class TooltipState extends TooltipBaseState {
  constructor(
    public override visibleRef: UseState<boolean>,
    public forwardedRef:
      | React.ForwardedRef<HTMLElement | null>
      | null
      | undefined,
    override readonly forceUpdate: () => void
  ) {
    super(visibleRef, forceUpdate);
  }

  getTargetRef = (ref: HTMLElement | null) => {
    const { forwardedRef } = this;
    if (forwardedRef) {
      if (typeof forwardedRef === "function") {
        forwardedRef(ref);
      } else if (typeof forwardedRef === "object") {
        forwardedRef.current = ref;
      }
    }
    this.target.replace(ref);
  };
}

export function Tooltip(props: TooltipProps) {
  const { forceVisible, delay, anchors, avoids, padding, hoverable } = props;

  const forceUpdate = useForceUpdate();

  const visibleRef = React.useState(false);
  const visible = visibleRef[0];

  const inputChildren = props.children;

  const stateRef = React.useRef<TooltipState>();
  const state =
    stateRef.current ||
    (stateRef.current = new TooltipState(
      visibleRef,
      typeof inputChildren === "function" ? null : inputChildren.props.ref,
      forceUpdate
    ));

  state.visibleRef = visibleRef;

  if (typeof inputChildren === "function") {
    state.forwardedRef = null;
  } else {
    state.forwardedRef = inputChildren.props.ref;
  }

  state.target.delay = delay;

  React.useEffect(state.effect, EMPTY_ARRAY);

  const { ref } = state.target;

  const { content } = props;
  const isContentEmpty =
    (!content && typeof content !== "number") || typeof content === "boolean";

  const outputChildren =
    typeof inputChildren === "function"
      ? inputChildren(state.getTargetRef)
      : React.cloneElement(inputChildren, { ref: state.getTargetRef });

  return (
    <>
      {outputChildren}
      {isContentEmpty || !((forceVisible || visible) && ref) ? null : (
        <TooltipContent
          state={state}
          target={ref}
          content={props.content}
          anchors={anchors}
          avoids={avoids}
          padding={padding}
          hoverable={hoverable}
        />
      )}
    </>
  );
}

interface TooltipContentProps extends PickedLocatorProps {
  readonly state: TooltipBaseState;
  readonly target: HTMLElement;
  readonly hoverable?: boolean;
  readonly content?: React.ReactNode | (() => React.ReactNode);
}

export const TOP_ANCHORS: readonly Anchor[] = [
  { content: "bottom", target: "top" },
  { content: "right", target: "left" },
  { content: "left", target: "right" },
  { content: "top", target: "bottom" },
];

export const LEFT_ANCHORS: readonly Anchor[] = [
  { content: "right", target: "left" },
  { content: "left", target: "right" },
  { content: "bottom", target: "top" },
  { content: "top", target: "bottom" },
];

export const RIGHT_ANCHORS: readonly Anchor[] = [
  { content: "left", target: "right" },
  { content: "right", target: "left" },
  { content: "bottom", target: "top" },
  { content: "top", target: "bottom" },
];

export const BOTTOM_ANCHORS: readonly Anchor[] = [
  { content: "top", target: "bottom" },
  { content: "right", target: "left" },
  { content: "left", target: "right" },
  { content: "bottom", target: "top" },
];

function TooltipContent(props: TooltipContentProps) {
  const { state, hoverable, target, content, anchors, avoids, padding } = props;
  return (
    <Popup
      useMask={false}
      render={() => (
        <Locator
          anchors={anchors || TOP_ANCHORS}
          avoids={avoids}
          padding={padding}
          target={target.getBoundingClientRect()}
          children={(locator) => {
            const { direction, pointer } = locator;
            return (
              <Container
                ref={state.getPopoverRef}
                className={hoverable ? "hoverable" : ""}
              >
                <div className="background">
                  <div
                    className={`arrow-locator ${direction}`}
                    style={
                      pointer && {
                        ...(direction === "left" || direction === "right"
                          ? {
                              top: pointer[1],
                            }
                          : direction === "top" || direction === "bottom"
                          ? {
                              left: pointer[0],
                            }
                          : undefined),
                      }
                    }
                    children={<div className="arrow" />}
                  />
                </div>
                <div className="content">{dethunk(content)}</div>
              </Container>
            );
          }}
        />
      )}
    />
  );
}

const Container = styled.div`
  max-width: 40vw;
  max-height: 40vh;
  overflow: auto;
  padding: 8px;
  color: #fff;
  border-radius: 4px;

  & > .background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #000;
    border-radius: 4px;
    opacity: 0.75;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.2));
    pointer-events: auto;

    & > .arrow-locator {
      position: absolute;
      transform: translate(-50%);

      &.top {
        left: 50%;
        top: 0%;
        display: block;
      }

      &.bottom {
        left: 50%;
        top: 100%;
        display: block;
      }

      &.left {
        left: 0%;
        top: 50%;
        display: block;
      }

      &.right {
        left: 100%;
        top: 50%;
        display: block;
      }

      & > .arrow {
        position: absolute;
        display: block;
        width: 8px;
        height: 8px;
        background-color: #000;
        transform: translateY(-5.6568542495px) rotate(45deg);
        transform-origin: 0 0;
      }
    }
  }

  & > .content {
    position: relative;
    width: max-content;
    max-width: 100%;
  }

  &:not(.hoverable) {
    pointer-events: none;
  }
`;
Container.displayName = "Container";
