// tslint:disable max-line-length

import { computed, makeObservable } from "mobx";
import {
  ReadableSourceLike,
  ReaderLike,
  WritableSourceLike,
  WriterLike,
  setAll,
  DuplexLike,
  setMap,
  SetMapFn,
  DuplexSourceLike,
  SkipEqualsDuplex,
  Duplex,
} from "./accessor";
import { memorize, EMPTY_ARRAY } from "@chuyuan/poster-utils";

export interface ReadFieldLike<T> extends ReadableSourceLike<T | undefined> {
  readonly data: ReadFieldData<T>;
  readonly disabled?: boolean;
  readonly variety: Variety;
}

export interface WriteFieldLike<T, L = WriteLog<boolean>>
  extends WritableSourceLike<T, L> {
  readonly disabled?: boolean;
}

export interface DuplexFieldLike<R, W = R, L = WriteLog<boolean>>
  extends ReadFieldLike<R>,
    WriteFieldLike<W, L>,
    DuplexSourceLike<R | undefined, W, L> {}

export namespace DuplexFieldLike {
  export interface OptionalReadType<R, W = R, L = WriteLog<boolean>>
    extends DuplexFieldLike<R | undefined, W, L> {}
}

export type Variety = "various" | "identical";

export type ReadFieldData<T> =
  | {
      readonly variety: "various";
      readonly value?: undefined;
    }
  | {
      readonly variety: "identical";
      readonly value: T;
    };

export type WriteLog<L> = {
  readonly parent: WriteFieldLike<unknown, unknown>;
  readonly records: readonly L[];
};

export const DEFAULT_VARIOUS = { variety: "various" } as const;

export class ReadField<T> implements ReadFieldLike<T> {
  constructor(
    readonly parent: ReaderLike<T>,
    readonly equals: (a: T, b: T) => boolean = Object.is
  ) {
    makeObservable(this);
  }

  @computed
  get data(): ReadFieldData<T> {
    let hasAny = false;
    let value!: T;
    for (const item of this.parent.read()) {
      if (hasAny) {
        if (!this.equals(item, value)) {
          return DEFAULT_VARIOUS;
        }
      } else {
        hasAny = true;
        value = item;
      }
    }
    return { variety: "identical", value };
  }

  get variety() {
    return this.data.variety;
  }

  get() {
    return this.data.value;
  }
}

export class WriteField<T, L> implements WriteFieldLike<T, WriteLog<L>> {
  constructor(readonly parent: WriterLike<T, L>) {}

  get disabled() {
    return false;
  }

  set(value: T): WriteLog<L> {
    const records = setAll(value, this.parent.write());
    return { parent: this, records };
  }

  map(mapFn: SetMapFn<T>): WriteLog<L> {
    const records = setMap(this.parent.write(), mapFn);
    return { parent: this, records };
  }
}

export class ZipReadWriteField<R, W, L> implements DuplexFieldLike<R, W, L> {
  constructor(
    readonly reader: ReadFieldLike<R>,
    readonly writer: WriteFieldLike<W, L>
  ) {}

  get disabled() {
    return this.reader.disabled || this.writer.disabled;
  }

  get data() {
    return this.reader.data;
  }

  get variety() {
    return this.reader.variety;
  }

  get() {
    return this.reader.get();
  }

  set(value: W) {
    return this.writer.set(value);
  }
}

export class DuplexField<T, R extends T, W extends T, L>
  extends ReadField<R>
  implements WriteField<W, L>, DuplexFieldLike<R, W, WriteLog<L>>
{
  override readonly parent: DuplexLike<R, W, L>;

  constructor(
    parent: DuplexLike<R, W, L>,
    equals: (a: T, b: T) => boolean = Object.is
  ) {
    const skip = new SkipEqualsDuplex(parent, equals);
    super(skip, equals);
    this.parent = skip;
  }

  get disabled() {
    return false;
  }

  set(value: W): WriteLog<L> {
    const records = setAll(value, this.parent.write());
    return { parent: this, records };
  }

  map(mapFn: SetMapFn<W>): WriteLog<L> {
    const records = setMap(this.parent.write(), mapFn);
    return { parent: this, records };
  }
}

export class MergeDuplexField<T, R extends T, W extends T, L>
  implements DuplexFieldLike<R, W, WriteLog<L>>
{
  constructor(
    readonly fields: ReadonlyArray<DuplexFieldLike<R, W, WriteLog<L>>>,
    readonly equals: (a: T, b: T) => boolean = Object.is
  ) {
    makeObservable(this);
  }

  get disabled() {
    return this.fields.some(isDisabledField);
  }

  @computed
  get data(): ReadFieldData<R> {
    let hasAny = false;
    let value!: R;
    for (const field of this.fields) {
      const { data } = field;
      if (data.variety === "various") return DEFAULT_VARIOUS;
      if (hasAny) {
        if (!this.equals(data.value, value)) {
          return DEFAULT_VARIOUS;
        }
      } else {
        hasAny = true;
        value = data.value;
      }
    }
    return { variety: "identical", value };
  }

  get variety() {
    return this.data.variety;
  }

  get() {
    return this.data.value;
  }

  set(value: W): WriteLog<L> {
    const records: L[] = [];
    for (const field of this.fields) {
      const ret = field.set(value);
      records.push(...ret.records);
    }
    return { parent: this, records };
  }
}

export class DuplexFieldDisability<
  T,
  R extends T,
  W extends T,
  L
> extends DuplexField<T, R, W, L> {
  constructor(
    override readonly parent: DuplexLike<R, W, L>,
    readonly disability: ReaderLike<unknown>,
    override readonly equals: (a: T, b: T) => boolean = Object.is
  ) {
    super(parent, equals);
    makeObservable(this);
  }

  @computed
  override get disabled() {
    for (const item of this.disability.read()) {
      if (item) return true;
    }
    return false;
  }
}

export class ScaleValueField<L> implements DuplexFieldLike<number, number, L> {
  constructor(
    readonly parent: DuplexFieldLike<number, number, L>,
    readonly scale: number
  ) {
    makeObservable(this);
  }

  @computed
  get data() {
    const { data } = this.parent;
    if (data.variety === "identical") {
      return { ...data, value: data.value * this.scale };
    }
    return data;
  }

  get disabled() {
    return this.parent.disabled;
  }

  get variety() {
    return this.parent.variety;
  }

  get() {
    return this.data.value;
  }

  set(value: number) {
    return this.parent.set(value / this.scale);
  }
}

export class MapDuplexField<R1, W1, R2, W2, L>
  implements DuplexFieldLike<R2, W2, WriteLog<L>>
{
  /**
   * 如果新的读写类型一致, 可以用这个函数 new, 避免重复手写泛型
   */
  static same<T, R, W, L>(
    parent: DuplexFieldLike<R, W, WriteLog<L>>,
    from: (read: R) => T,
    to: (write: T) => W
  ) {
    return new this(parent, from, to);
  }

  constructor(
    readonly parent: DuplexFieldLike<R1, W1, WriteLog<L>>,
    readonly from: (read: R1) => R2,
    readonly to: (write: W2) => W1
  ) {
    makeObservable(this);
  }

  @computed
  get data() {
    const { data } = this.parent;
    if (data.variety === "identical") {
      return { ...data, value: this.from(data.value) };
    }
    return data;
  }

  get disabled() {
    return this.parent.disabled;
  }

  get variety() {
    return this.parent.variety;
  }

  get() {
    return this.data.value;
  }

  set(value: W2): WriteLog<L> {
    return { parent: this, records: this.parent.set(this.to(value)).records };
  }
}

export class DisabledDuplexField<R = never, W = unknown, L = boolean>
  implements DuplexFieldLike<R, W, WriteLog<L>>
{
  readonly parent = new Duplex<R, W, L>(EMPTY_ARRAY);

  @memorize
  static get instance() {
    return new this();
  }

  get data() {
    return DEFAULT_VARIOUS;
  }

  get disabled() {
    return true;
  }

  get variety() {
    return "various" as const;
  }

  get() {
    return undefined;
  }

  set(): WriteLog<L> {
    return { parent: this, records: EMPTY_ARRAY };
  }
}

export class UnionSetDuplexField<T, L>
  implements DuplexFieldLike<ReadonlySet<T>, ReadonlySet<T>, WriteLog<L>>
{
  constructor(readonly parent: DuplexLike<ReadonlySet<T>, ReadonlySet<T>, L>) {
    makeObservable(this);
  }

  get disabled() {
    return false;
  }

  @computed
  private get internalData() {
    const { parent } = this;
    const whole = new Set<T>();
    let min = Infinity;
    let max = 0;
    for (const set of parent.read()) {
      const { size } = set;
      if (min > size) min = size;
      if (max < size) max = size;
      for (const item of set) {
        whole.add(item);
      }
    }
    let data: ReadFieldData<ReadonlySet<T>>;
    if (!whole.size || min === max) {
      data = { variety: "identical", value: whole };
    } else {
      data = DEFAULT_VARIOUS;
    }
    return { data, whole } as const;
  }

  get data() {
    return this.internalData.data;
  }

  get variety() {
    return this.internalData.data.variety;
  }

  get() {
    return this.internalData.whole;
  }

  set(value: ReadonlySet<T>): WriteLog<L> {
    const records = setAll(value, this.parent.write());
    return { parent: this, records };
  }

  map(mapFn: SetMapFn<ReadonlySet<T>>): WriteLog<L> {
    const records = setMap(this.parent.write(), mapFn);
    return { parent: this, records };
  }
}

export class IntersectionSetReadField<T>
  implements ReadFieldLike<ReadonlySet<T>>
{
  constructor(readonly parent: ReaderLike<ReadonlySet<T>>) {
    makeObservable(this);
  }

  @computed
  private get internalData() {
    const { parent } = this;
    let whole: Set<T> | undefined;
    let min = Infinity;
    let max = 0;
    for (const set of parent.read()) {
      const { size } = set;
      if (min > size) min = size;
      if (max < size) max = size;
      if (whole) {
        for (const item of whole) {
          if (set.has(item)) continue;
          whole.delete(item);
        }
        if (!whole.size) break;
      } else {
        whole = new Set(set);
      }
    }
    if (!whole) whole = new Set();
    let data: ReadFieldData<ReadonlySet<T>>;
    if (!whole.size || min === max) {
      data = { variety: "identical", value: whole };
    } else {
      data = DEFAULT_VARIOUS;
    }
    return { data, whole } as const;
  }

  get data() {
    return this.internalData.data;
  }

  get variety() {
    return this.internalData.data.variety;
  }

  get() {
    return this.internalData.whole;
  }
}

export class IntersectionSetDuplexField<T, L>
  extends IntersectionSetReadField<T>
  implements DuplexFieldLike<ReadonlySet<T>, ReadonlySet<T>, WriteLog<L>>
{
  constructor(
    override readonly parent: DuplexLike<ReadonlySet<T>, ReadonlySet<T>, L>
  ) {
    super(parent);
  }

  get disabled() {
    return false;
  }

  set(value: ReadonlySet<T>): WriteLog<L> {
    const records = setAll(value, this.parent.write());
    return { parent: this, records };
  }

  map(mapFn: SetMapFn<ReadonlySet<T>>): WriteLog<L> {
    const records = setMap(this.parent.write(), mapFn);
    return { parent: this, records };
  }
}

function isDisabledField(x: { readonly disabled?: boolean }) {
  return x.disabled;
}

export class LiteralReadField<T> implements ReadFieldLike<T> {
  constructor(readonly value: T) {}

  @memorize
  get data(): ReadFieldData<T> {
    return { variety: "identical", value: this.value };
  }

  get variety() {
    return this.data.variety;
  }

  get() {
    return this.data.value;
  }
}
