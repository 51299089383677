import { makeObservable, observable } from 'mobx'

export class EditorViewport {
  /**
   * @internal
   */
  @observable.ref
  _width = 0

  /**
   * @internal
   */
  @observable.ref
  _height = 0

  /**
   * @internal
   */
  @observable.ref
  _touched = false

  constructor() {
    makeObservable(this)
  }

  get width() {
    return this._width
  }

  set width(value: number) {
    value = Number.isFinite(value) && value > 0 ? value : 0
    this._width = value
  }

  get height() {
    return this._height
  }

  set height(value: number) {
    value = Number.isFinite(value) && value > 0 ? value : 0
    this._height = value
  }
}
