import * as React from 'react'

import { createModuleToken, implementModule } from '../../utils/modulize'
import { PortalContainer } from '../../utils/react-portal-mobx'

export const LayoutModuleToken = createModuleToken<LayoutModuleState>('LayoutModule')

export const LayoutModule = implementModule(LayoutModuleToken, {
  state: () => new LayoutModuleState(),
})

export class LayoutModuleState {
  /**
   * 注册到布局属性面板的(唯一一个)ui槽里
   */
  readonly PropertiesSlot = new PortalContainer<{
    readonly children?: React.ReactNode | (() => React.ReactNode)
  }>()
}
