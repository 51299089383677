import * as React from 'react'
import styled from 'styled-components'
import * as cx from 'classnames'

import type { CommonProps } from './common'

export interface CheckboxProps extends CommonProps {
  readonly checked?: boolean | 'intermediate'
  readonly intermediateNextValue?: boolean
  readonly onChange?: ((checked: boolean) => void)
  readonly children?: React.ReactNode
}

export type CheckboxRef = HTMLLabelElement

export const Checkbox = React.forwardRef<CheckboxRef, CheckboxProps>(
  (props, ref) => {
    const [hovering, setHovering] = React.useState(false)
    const [focused, setFocused] = React.useState(false)

    const { disabled, readOnly, checked, children } = props

    const immutable = disabled || readOnly

    return (
      <Wrapper
        ref={ref}
        className={cx(
          props.className,
          disabled && 'disabled',
          checked === 'intermediate' ? 'intermediate' : checked ? 'checked' : '',
          hovering || focused ? 'focus' : '',
        )}
        style={props.style}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <span
          className="checkbox-content"
          onClick={immutable ? undefined : () => {
            const intermediateNextValue = props.intermediateNextValue ?? true
            const newValue = checked === 'intermediate' ? intermediateNextValue : !checked
            props.onChange?.(newValue)
          }}
        >
          <input
            type="checkbox"
            className="checkbox-input"
            readOnly={readOnly}
            disabled={disabled}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
          />
          <span className="checkbox-inner" />
          {typeof children === 'boolean' || children === undefined || children === null ? null : (
            <span className="checkbox-text">{children}</span>
          )}
        </span>
      </Wrapper>
    )
  }
)
Checkbox.displayName = 'Checkbox'

const Wrapper = styled.label`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  display: inline-block;
  line-height: unset;
  cursor: pointer;

  .checkbox-content {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    position: relative;
    top: -0.09em;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    vertical-align: middle;
    outline: none;
    cursor: inherit;
  }

  .checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    direction: ltr;
    background-color: #fff;
    border-color: #d9d9d9;
    border-radius: 2px;
    border-collapse: separate;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;

    ::after {
      position: absolute;
      display: table;
      opacity: 0;
      content: ' ';
      box-sizing: border-box;
    }
  }

  &.focus:not(.disabled) {
    .checkbox-inner {
      border-color: #0040f0;
    }
  }

  &.checked {
    .checkbox-inner {
      color: #fff;
      background-color: #0040f0;
      border-color: #0040f0;

      ::after {
        border-color: '#fff';
        top: 50%;
        left: 22%;
        width: 5.71428571px;
        height: 9.14285714px;
        border-style: solid;
        border-width: 2px;
        border-top: 0;
        border-left: 0;
        transform: rotate(45deg) translate(-50%, -50%);
        opacity: 1;
      }
    }
  }

  &.intermediate {
    .checkbox-inner {
      ::after {
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        background-color: #0040f0;
        border: 0;
        transform: translate(-50%,-50%);
        opacity: 1;
      }
    }
  }

  .checkbox-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: inherit;
    opacity: 0;
  }

  .checkbox-text {
    margin-left: 8px;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
