import * as React from "react";
import { useObserver } from "mobx-react";
import { runInAction } from "mobx";

import type { TextSpec } from "@chuyuan/poster-data-structure";
import {
  TextInlineAlignmentTop,
  TextInlineAlignmentCenter,
  TextInlineAlignmentBottom,
} from "../../../../poster-ui/icons/new-icons";

import { useLocale, i18n } from "../../utils/i18n";
import type { DuplexFieldLike } from "../../utils/multi-value";
import { Radio, RadioOption } from "../ui-components/radio";
import { getInputProps } from "../../utils/react-multi-value";
import type { TextDirectionType } from "./state.text";
import { EditorContext } from "../helpers/react-context";

export const InlineAlignmentValue = React.memo(
  (props: {
    readonly textDirection: TextDirectionType;
    readonly field: DuplexFieldLike<TextSpec.TextStyleInlineAlignmentType>;
  }) => {
    const { textDirection, field } = props;

    const { session } = React.useContext(EditorContext);
    const locale = useLocale();

    return useObserver(() => {
      const multiValueProps = getInputProps(field, locale);
      const disabled = multiValueProps.disabled;
      return (
        <>
          <Radio
            {...multiValueProps}
            className="iconic"
            disabled={disabled}
            onChange={(v) =>
              runInAction(() => {
                field.set(v);
                session.history.push({ name: "修改行内对齐" });
              })
            }
            options={
              textDirection === "vertical" ? VerticalOptions : HorizontalOptions
            }
          />
        </>
      );
    });
  }
);
InlineAlignmentValue.displayName = "InlineAlignmentValue";

const HorizontalOptions: ReadonlyArray<
  RadioOption<TextSpec.TextStyleInlineAlignmentType>
> = [
  {
    value: "top",
    title: () => <TextInlineAlignmentTop />,
    tooltip: { content: () => <上对齐 /> },
  },
  {
    value: "center",
    title: () => <TextInlineAlignmentCenter />,
    tooltip: { content: () => <垂直居中 /> },
  },
  {
    value: "bottom",
    title: () => <TextInlineAlignmentBottom />,
    tooltip: { content: () => <下对齐 /> },
  },
];

const VerticalOptions: ReadonlyArray<
  RadioOption<TextSpec.TextStyleInlineAlignmentType>
> = [
  {
    value: "bottom",
    title: () => (
      <TextInlineAlignmentTop style={{ transform: "rotate(-90deg)" }} />
    ),
    tooltip: { content: () => <左对齐 /> },
  },
  {
    value: "center",
    title: () => (
      <TextInlineAlignmentCenter style={{ transform: "rotate(-90deg)" }} />
    ),
    tooltip: { content: () => <水平居中 /> },
  },
  {
    value: "top",
    title: () => (
      <TextInlineAlignmentBottom style={{ transform: "rotate(-90deg)" }} />
    ),
    tooltip: { content: () => <右对齐 /> },
  },
];

const 左对齐 = i18n({
  en: () => "Align Left",
  zh: () => "左对齐",
});

const 水平居中 = i18n({
  en: () => "Align Center",
  zh: () => "水平居中",
});

const 右对齐 = i18n({
  en: () => "Align Right",
  zh: () => "右对齐",
});

const 上对齐 = i18n({
  en: () => "Align Top",
  zh: () => "上对齐",
});

const 垂直居中 = i18n({
  en: () => "Align Center",
  zh: () => "垂直居中",
});

const 下对齐 = i18n({
  en: () => "Align Bottom",
  zh: () => "下对齐",
});
