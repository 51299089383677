import * as React from "react";
import styled from "styled-components";

import { DynamicFontManager } from "@chuyuan/poster-font-pack";
import {
  cached,
  castReadonly,
  memorize,
  predicate,
} from "@chuyuan/poster-utils";

import { matchKVMap, Locale, matchKVMaps } from "../../utils/i18n";
import {
  getStdFontSet,
  getFontFamilySearchKeywords,
  StdFontSetData,
} from "./font-family-helpers";
import { SearchableSelectItemProps } from "../ui-components/searchable-select";
import {
  useVisible,
  useVisibleByDefaultProviderOperator,
} from "../../utils/react-element-intersection-detector";
import { EditorContext } from "../helpers/react-context";
import { FontFaceData, FontSetData } from "../editor-state/api";
import { uniqBy } from "lodash";
import { useObserver } from "mobx-react";

/**
 * 字符集选择器整理数据
 */
export class FontFaceSelectorData {
  constructor(
    /**
     * 字体集列表
     */
    readonly fontSetList: readonly FontSetData[],
    /**
     * 需要过滤的字体语言
     */
    readonly language: "zh" | "en",
    /**
     * 文案渲染语言
     */
    readonly locale: Locale
  ) {}

  /**
   * 过滤出给定语言的字体
   */
  @memorize
  get list() {
    const { fontSetList, language } = this;
    return castReadonly(
      uniqBy(
        fontSetList
          .map(getStdFontSet)
          .filter(predicate)
          .filter((x) => x.languages.includes(language)),
        "id"
      )
    );
  }

  /**
   * 字体集名到字体集的映射表: Map<fontSetId, FontSetData>
   */
  @memorize
  get map() {
    return castReadonly(new Map(this.list.map((x) => [x.id, x])));
  }

  /**
   * 字体名到字体集的映射表: Map<fontFaceId, FontSetData>
   */
  @memorize
  get mapByFontFaceId() {
    const map = new Map<string, StdFontSetData>();
    for (const fontSet of this.list) {
      for (const fontFace of fontSet.family) {
        map.set(fontFace.id, fontSet);
      }
    }
    return castReadonly(map);
  }

  /**
   * 导出搜索选择组件所需的选项列表
   */
  @memorize
  get fontFaceOptions() {
    const { locale } = this;
    return this.list.map((fontSet): SearchableSelectItemProps<string> => {
      // 如果有多个子集的话, 选项渲染的是字符集的相关信息, 否则直接渲染字体信息
      const { hero, displayName } = fontSet;
      const setId = fontSet.id;
      const fontFamily = hero.id;
      const content = matchKVMap(locale, displayName) || setId;
      return {
        value: setId,
        label: () => (
          <FontFamilyLabel fontFamily={fontFamily} content={content} />
        ),
        keywords: Array.from(getFontFamilySearchKeywords(setId, displayName)),
      };
    });
  }
}

const toPreferLocale = cached((locale: Locale) => {
  const preferLocale =
    typeof locale === "string" ? [locale] : Array.from(locale);
  preferLocale.unshift("en");
  return preferLocale;
});

export function getSubFamilySelectorData(
  fontList: readonly FontFaceData[],
  locale: Locale
) {
  // 对于 sub family name 我们优先英文
  const preferLocale = toPreferLocale(locale);
  return fontList.map((font): SearchableSelectItemProps<string> => {
    const { weight, style, id } = font;
    const displayNameList = [];
    if (weight.value !== 400) displayNameList.push(weight.displayName);
    if (style.value !== "normal") displayNameList.push(style.displayName);
    if (!displayNameList.length) displayNameList.push(weight.displayName);
    const subFamilyList = matchKVMaps(preferLocale, displayNameList);
    const displayName = subFamilyList.filter(Boolean).join(" ") || "Default";
    return {
      value: id,
      label: () => <FontFamilyLabel fontFamily={id} content={displayName} />,
      keywords: Array.from(
        getFontFamilySearchKeywords("", { zh: displayName })
      ),
    };
  });
}

const FontFamilySelectorContext = React.createContext<
  DynamicFontManager | undefined
>(undefined);

export function FontFamilySelectorProvider(props: React.PropsWithChildren<{}>) {
  const editor = React.useContext(EditorContext);
  return useObserver(() => {
    const loader = editor.resources.dynamicFontManager.get().data;
    return (
      <FontFamilySelectorContext.Provider
        value={loader}
        children={props.children}
      />
    );
  });
}

export function FontFamilyLabel(props: {
  readonly fontFamily: string;
  readonly content: string;
  readonly children?: React.ReactNode;
}) {
  const { fontFamily, content, children } = props;
  const fontLoader = React.useContext(FontFamilySelectorContext);
  const op = useVisible(useVisibleByDefaultProviderOperator);
  const { visible } = op;

  const stateRef = React.useRef<{
    fontLoader?: DynamicFontManager;
    fontFamily: string;
    content: string;
  }>();
  const state =
    stateRef.current || (stateRef.current = { fontFamily, content });

  if (visible) {
    state.fontLoader = fontLoader;
    state.fontFamily = fontFamily;
    state.content = content;
  }

  {
    // tslint:disable-next-line: no-shadowed-variable
    const { fontLoader, fontFamily, content } = state;
    React.useEffect(() => {
      if (!fontLoader) return;
      fontLoader.load(fontFamily, content).catch(console.error);
    }, [fontLoader, fontFamily, content]);
  }

  return (
    <Label
      ref={op.getRef}
      style={{ fontFamily: `'${fontFamily}'` }}
      children={children ?? content}
    />
  );
}

const Label = styled.span`
  font-weight: unset !important;
  font-style: unset !important;
`;
