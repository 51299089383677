import { useObserver } from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import { useModule } from "../../utils/modulize";
import { EditorContext } from "../helpers/react-context";
import { EditorSlotModuleToken } from "./editor-slot-module";
import { Divider } from "antd";
import { getSelectionFilter } from "../helpers/selection-filter";
// import { StyleEditorSlotModuleToken } from "../gdl-style-panel/module";
import {
  TextFontFamliyPanelContent,
  TextFillPanelContent,
  TextFontStylePanelContent,
  TextFontAlignmentPanelContent,
  TextFontGapPanelContent,
  TextContentPanelContent,
  TextDecorationPanelContent,
  TextEffectPanelContent,
  TextCheckLineWrappingIcon,
} from "../gdl-text/style-panel";
import { TextDirectionIconBtn } from "../gdl-text/text-direction";
import { TextSizePanelContent } from "../gdl-text/appearance-panel";
// import { i18n } from "../../utils/i18n";

/**@name 文字样式导航栏 */
export const TextNavigator = React.memo(
  () => {
    const { session } = React.useContext(EditorContext);

    const EditorSlotModule = useModule(EditorSlotModuleToken);
    // const StyleEditorSlotModule = useModule(StyleEditorSlotModuleToken);
    return useObserver(() => {
      if (!EditorSlotModule) return null;

      const selection = session.selection.set;
      if (!selection.size) return null;

      const targets = getSelectionFilter(session.root, selection).textsOnly;
      if (targets.size !== selection.size) return null;

      // console.log("显示 TextNavigator", {});

      return (
        <NavDOM>
          <div className="left-bar">
            <div style={{ marginLeft: 20, display: "flex", gap: 22, alignItems: "center", flexWrap: "wrap" }}>
              <TextFontFamliyPanelContent targets={targets} />
              <TextSizePanelContent targets={targets} />
              <TextFillPanelContent targets={targets} />
              <TextFontStylePanelContent targets={targets} />
              <Divider type="vertical" />
              <TextDirectionIconBtn targets={targets} />
              <TextFontAlignmentPanelContent targets={targets} />
              <TextFontGapPanelContent targets={targets} />
              <TextContentPanelContent targets={targets} />
              <TextDecorationPanelContent targets={targets} />
              <TextCheckLineWrappingIcon targets={targets} />
              <TextEffectPanelContent targets={targets} />
            </div>
          </div>
          <div className="middle-bar"></div>
          {/* <div className="right-bar" onClick={props.openEdit}>
            <图层编辑 />
          </div> */}
        </NavDOM>
      );
    });
  },
  () => true
);

const NavDOM = styled.div`
  z-index: 9;
  min-height: 60px;
  padding: 0;
  background: #fff;
  border-bottom: 1px solid #eae9e8;
  overflow-x: auto;
  overflow-y: visible;
  display: flex;

  .left-bar {
    flex: 0 0 auto;
    display: flex;
  }

  .middle-bar {
    flex: 1 1 0;
    display: flex;
  }

  .right-bar {
    cursor: pointer;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
`;

// const 图层编辑 = i18n({
//   en: () => "Layer editing",
//   zh: () => "图层编辑",
// });
