import { computed, makeObservable } from "mobx";
import { cached, memorize } from "@chuyuan/poster-utils";

import { SessionState } from "../editor-state/session-state";
import { SelectableTarget } from "../editor-state/types";
import { Frame, LeafModelType, Text } from "@chuyuan/poster-data-access-layer";
import { Image } from "@chuyuan/poster-data-access-layer/lib/cjs/models/image";

export class OptimisticSelection {
  constructor(readonly session: SessionState) {
    makeObservable(this);
  }

  @computed
  get set(): ReadonlySet<SelectableTarget> {
    const { session } = this;
    return new Set([
      ...session.selection.set,
      ...session.ui.temporarySelection.value,
    ]);
  }

  @computed
  get isMoreThanOne() {
    return this.set.size > 1;
  }
}

/**
 * 获取包括临时选中状态在内的所有选中状态
 */
export const getOptimisticSelection = cached(
  (session: SessionState) => new OptimisticSelection(session)
);

export class SelectionFilter<
  T extends Iterable<SelectableTarget> = ReadonlySet<SelectableTarget>
> {
  constructor(readonly root: Frame, readonly set: T) {}

  @memorize
  get rootOnly(): ReadonlySet<Frame> {
    const set = new Set<Frame>();
    const { root } = this;
    for (const target of this.set) {
      if (target === root) {
        set.add(target);
      }
    }
    return set;
  }

  @memorize
  get elementsOnly(): ReadonlySet<LeafModelType> {
    const set = new Set<LeafModelType>();
    for (const target of this.set) {
      if (target.kind === "frame") continue;
      set.add(target);
    }
    return set;
  }

  @memorize
  get containersOnly(): ReadonlySet<Frame> {
    const set = new Set<Frame>();
    for (const target of this.set) {
      if (target.kind !== "frame") continue;
      set.add(target);
    }
    return set;
  }

  @memorize
  get nonRootContainersOnly(): ReadonlySet<Frame> {
    const set = new Set<Frame>();
    const { root } = this;
    for (const target of this.set) {
      if (target.kind !== "frame") continue;
      if (target === root) continue;
      set.add(target);
    }
    return set;
  }

  @memorize
  get textsOnly(): ReadonlySet<Text> {
    const set = new Set<Text>();
    for (const target of this.set) {
      if (target.kind !== "text") continue;
      set.add(target);
    }
    return set;
  }

  @memorize
  get imagesOnly(): ReadonlySet<Image> {
    const set = new Set<Image>();
    for (const target of this.set) {
      if (target.kind !== "image") continue;
      set.add(target);
    }
    return set;
  }
}
export const getSelectionFilter = cached(
  <T extends Iterable<SelectableTarget>>(root: Frame, set: T) =>
    new SelectionFilter(root, set)
);
