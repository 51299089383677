import * as React from "react";
import styled from "styled-components";

import {
  HighlightEditor as RawHighlightEditor,
  HighlightEditorProps,
  HighlightEditorRef,
} from "@chuyuan/poster-rich-text-editor";

export const HighlightEditor = React.forwardRef<
  HighlightEditorRef,
  HighlightEditorProps
>((props, ref) => {
  const [focused, setFocused] = React.useState(false);

  type State = {
    props: HighlightEditorProps;
    readonly onFocus: HighlightEditorProps["onFocus"];
    readonly onBlur: HighlightEditorProps["onBlur"];
  };

  const stateRef = React.useRef<State>();
  const state: State =
    stateRef.current ||
    (stateRef.current = {
      props,
      onFocus: (e) => {
        setFocused(true);
        state.props.onFocus?.(e);
      },
      onBlur: (e) => {
        setFocused(false);
        state.props.onBlur?.(e);
      },
    });
  state.props = props;

  return (
    <StyledHighlightEditor
      {...props}
      ref={ref}
      className={`${props.className} ${focused ? "focused" : ""}`}
      onFocus={state.onFocus}
      onBlur={state.onBlur}
    />
  );
});

export const StyledHighlightEditor = styled(RawHighlightEditor)`
  padding: 8px;
  font-size: 12px;
  background: #f8f7f6;
  border-radius: 4px;
  color: #606060;
  flex: 1 1 0;
  border: 1px solid transparent;
  box-sizing: border-box;

  &.focused {
    border-color: #0040f0;
    box-shadow: 0 0 0 2px rgba(0, 64, 240, 0.2);
  }

  .public-DraftEditor-content {
    min-height: 48px;
  }

  &.content-margin-bottom {
    .public-DraftEditor-content {
      margin-bottom: 1em;
    }
  }

  &.min-row-1 {
    .public-DraftEditor-content {
      min-height: 1.5em;
    }
  }

  &.max-row-8 {
    .text-editor {
      max-height: 12em;
    }
  }

  &.mark-hidden {
    .mark-btn {
      display: none;
    }
  }

  &.disabled {
    color: #c0c0c0;
    pointer-events: none;

    .mark-icon {
      opacity: 0.5;
    }
  }
`;
