import * as React from "react";
import * as ReactDOM from "react-dom";
import styled from "styled-components";
import { once } from "lodash";

import { EMPTY_ARRAY, ClassStaticCache } from "@chuyuan/poster-utils";

import { getRootContainer } from "./internal";

import { PortalContainer } from "../../utils/react-portal-mobx";
import { GlobalContext } from "./global-context";
import { useObserver } from "mobx-react";

export interface PopupProps {
  readonly ctx: GlobalContext;
  readonly useMask?: boolean;
  readonly render: (props: PopupRenderProps) => React.ReactNode;
}

export interface PopupRenderProps {
  readonly container: HTMLElement;
  readonly onClose: () => void;
}

/**
 * 指令式调出一个覆盖全屏的弹出层
 * - 自带 mask
 * - 注意: 如果没有注册全局弹出层的话, children 是不在任何 react context 下的
 */
export function popup(props: PopupProps) {
  const { ctx } = props;
  const my = PopupContext.of(ctx);
  const onClose = once(() => register.dispose());
  const register = my.List.create({
    ...props,
    onClose,
    render: (p) => props.render({ ...p, onClose }),
  });
  return onClose;
}

export function usePopupContainer() {
  const stateRef = React.useRef<{
    readonly container: HTMLElement;
    readonly effect: () => () => void;
  }>();

  if (!stateRef.current) {
    const { container, dispose } = getRootContainer().create();
    const effect = () => dispose;
    stateRef.current = { container, effect };
  }

  {
    const { container, effect } = stateRef.current;

    React.useEffect(effect, EMPTY_ARRAY);

    return container;
  }
}

export interface PopupComponentProps {
  readonly useMask?: boolean;
  readonly render: (props: {
    readonly container: HTMLElement;
  }) => React.ReactNode;
  readonly onClose?: () => void;
}

/**
 * 以 react node 的形式调出一个覆盖全屏的弹出层
 * - 自带 mask
 */
export function Popup(props: PopupComponentProps) {
  const container = usePopupContainer();

  const useMask = props.useMask ?? true;

  return ReactDOM.createPortal(
    <Wrapper>
      {!useMask ? null : (
        <div
          className="mask"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.onClose?.();
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.onClose?.();
          }}
        />
      )}
      {props.render({ container })}
    </Wrapper>,
    container
  );
}

class PopupContext {
  static of = ClassStaticCache;

  constructor(state: GlobalContext) {
    state.Portal.create({
      children: <PopupList state={this} />,
    });
  }

  readonly List = new PortalContainer<PopupComponentProps>();
}

function PopupList({ state }: { readonly state: PopupContext }) {
  return useObserver(() => {
    const items = state.List.items;

    if (!items.size) return null;

    return (
      <>
        {Array.from(items).map(({ id, props }) => {
          return <Popup {...props} key={id} />;
        })}
      </>
    );
  });
}

const Wrapper = styled.div`
  font-family: PingFangSC-Light, PingFang SC, Microsoft YaHei, sans-serif;

  & > .mask {
    position: absolute;
    width: 100vw;
    height: 100vh;
  }
`;
Wrapper.displayName = "Popup";
