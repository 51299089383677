import { parse } from "querystring";

// 文件Mime类型
export enum FILE_MIME_TYPE {
  PSD = "image/vnd.adobe.photoshop",
  SVG = "image/svg+xml",
  PNG = "image/png",
  JPG = "image/jpeg",
  CY = "image/cy",
  XLSX = ".xlsx",
  XLS = ".xls",
}

const _CTokenName = "CYT:oken";

const getPageQuery = () => {
  const { href } = window.location;
  const qsIndex = href.indexOf("?");
  const sharpIndex = href.indexOf("#");

  if (qsIndex !== -1) {
    if (qsIndex > sharpIndex) {
      return parse(href.split("?")[1]);
    }

    return parse(href.slice(qsIndex + 1, sharpIndex));
  }

  return {};
};

class Utils {
  MimeType: { [propName: string]: string };

  constructor() {
    this.MimeType = {
      [FILE_MIME_TYPE.PSD]: "psd",
      [FILE_MIME_TYPE.SVG]: "svg",
    };
  }

  getToken() {
    const locationQuery = getPageQuery();
    return localStorage.getItem(_CTokenName) || locationQuery["access_token"];
  }

  setToken(token: string) {
    localStorage.setItem(_CTokenName, token);
  }
}

export function getToken() {
  return localStorage.getItem(_CTokenName);
}

export function setToken(token: string) {
  localStorage.setItem(_CTokenName, token);
}

/**
 * 将json数据转换为form表单
 * eg:{name:'hehe',age:12}  ==>  name=hehe&age=12
 * @param {obj} a json对象
 * @return {string}
 */
export function json2Form(a: any, prefix = "") {
  var s: any[] = [],
    rbracket = /\[\]$/,
    isArray = function (obj: any) {
      return Object.prototype.toString.call(obj) === "[object Array]";
    },
    add = function (k: string, v: any) {
      v = typeof v === "function" ? v() : v === null ? "" : v === undefined ? "" : v;
      s[s.length] = encodeURIComponent(k) + "=" + encodeURIComponent(v);
    };

  var buildParams = function (prefix: any, obj: any) {
    var i, len, key;

    if (prefix) {
      if (isArray(obj)) {
        for (i = 0, len = obj.length; i < len; i++) {
          if (rbracket.test(prefix)) {
            add(prefix, obj[i]);
          } else {
            buildParams(prefix + "[" + (typeof obj[i] === "object" ? i : "") + "]", obj[i]);
          }
        }
      } else if (obj && String(obj) === "[object Object]") {
        for (key in obj) {
          buildParams(prefix + "[" + key + "]", obj[key]);
        }
      } else {
        add(prefix, obj);
      }
    } else if (isArray(obj)) {
      for (i = 0, len = obj.length; i < len; i++) {
        add(obj[i].name, obj[i].value);
      }
    } else {
      for (key in obj) {
        buildParams(key, obj[key]);
      }
    }
    return s;
  };

  return buildParams(prefix, a).join("&").replace(/%20/g, "+");
}

export default new Utils();
