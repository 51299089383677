import {
  Affine2DTimesBounding,
  mergeBoundings,
  ReadonlyAffine2D,
} from "@chuyuan/poster-math";
import { mapIterate } from "@chuyuan/poster-utils";

import { pickDimensionToBounding } from "./data-picker";
import { VisibleTarget } from "../editor-state/types";

/**
 * 合并 gdl 对象集合视觉坐标系的 bounding
 * @param targets gdl 对象集合
 */
export function mergeTargetsVisualBounding(targets: Iterable<VisibleTarget>) {
  return mergeBoundings(mapIterate(targets, getTargetVisualBounding));
}

/**
 * 计算 gdl 对象视觉坐标系的 bounding
 * @param target gdl 对象
 */
export function getTargetVisualBounding(target: VisibleTarget) {
  const { selfBox, coordinate } = target.layout;
  const b = pickDimensionToBounding(selfBox);
  const t = coordinate.selfFromWorldTransform;
  return Affine2DTimesBounding(t, b);
}

/**
 * 合并 gdl 对象集合给定坐标系的 bounding
 * @param targets gdl 对象集合
 */
export function mergeTargetsBoundingGivenInverseTransform(
  targets: Iterable<VisibleTarget>,
  inverseTransform: ReadonlyAffine2D
) {
  return mergeBoundings(
    mapIterate(targets, (x) =>
      getTargetBoundingGivenInverseTransform(x, inverseTransform)
    )
  );
}

/**
 * 计算 gdl 对象给定坐标系的 bounding
 * @param target gdl 对象
 */
export function getTargetBoundingGivenInverseTransform(
  target: VisibleTarget,
  inverseTransform: ReadonlyAffine2D
) {
  const { selfBox, coordinate } = target.layout;
  const b = pickDimensionToBounding(selfBox);
  const t = coordinate.selfFromWorldTransform
    .clone()
    .leftTimes(inverseTransform);
  return Affine2DTimesBounding(t, b);
}
