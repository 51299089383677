export function getAutoCursorSvg() {
  return `\
<svg height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
  <g fill="none" fill-rule="evenodd" filter="url(#a)">
    <path fill="#fff" d="M13,22.6V8l10.6,10.6h-6.4L17,18.7L13,22.6z"/>
    <path fill="#000" d="M13.9,10.2v10.2l2.7-2.6l0.1-0.1h4.6L13.9,10.2z"/>
  </g>
  <filter id="a">
    <feDropShadow dx="0" dy="1" flood-opacity="0.35" stdDeviation="1"/>
  </filter>
</svg>
`
}

export function getEdgeCursorSvg(degree: number) {
  return `\
<svg height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#a)">
    <g fill="none" fill-rule="evenodd" transform="translate(13 8) rotate(${degree}) translate(-13 -8)">
      <path fill="#000" d="M8.9,6.5L9.4,7L7.9,8.6l10.2,0L16.6,7l0.5-0.6L19.6,9l-2.4,2.5L16.6,11l1.5-1.6H7.9L9.4,11l-0.5,0.6L6.4,9L8.9,6.5" />
      <path fill="#fff" d="M10.3,7.6L10.8,7l-2-2L5,9l3.9,4l2-2l-0.5-0.5h5.3L15.2,11l2,2L21,9l-3.9-4l-2,2l0.5,0.5L10.3,7.6z M7.9,8.6L9.4,7L8.9,6.5L6.4,9l2.4,2.5L9.4,11L7.9,9.4h10.2L16.6,11l0.5,0.6L19.6,9l-2.4-2.5L16.6,7l1.5,1.5L7.9,8.6z" />
    </g>
  </g>
  <filter id="a">
    <feDropShadow dx="0" dy="1" flood-opacity="0.35" stdDeviation="1"/>
  </filter>
</svg>`
}

export function getCornerCursorSvg(degree: number) {
  return `\
<svg height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#a)">
    <g fill="none" fill-rule="evenodd" transform="translate(13 8) rotate(${degree}) translate(-13 -8)">
      <path fill="#000" d="M16.6,10.9l0.5,0.5L19.6,9l-2.4-2.4l-0.5,0.5l1.5,1.5l-6.6,0l0,0.8l6.6,0L16.6,10.9z" />
      <path fill="#fff" d="M17.2,12.8l-1.9-1.9l0.5-0.5l-5.1,0l0-2.8l5.1,0l-0.5-0.5l1.9-1.9L21,9L17.2,12.8z M17.2,11.4L19.6,9l-2.4-2.4l-0.5,0.5l1.5,1.5l-6.6,0l0,0.8l6.6,0l-1.5,1.5L17.2,11.4z" />
    </g>
  </g>
  <filter id="a">
    <feDropShadow dx="0" dy="1" flood-opacity="0.35" stdDeviation="1"/>
  </filter>
</svg>`
}

export function getCornerRotateCursorSvg(degree: number) {
  return `\
<svg height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#a)">
    <g fill="none" fill-rule="evenodd" transform="translate(13 9) rotate(${degree}) translate(-13 -9)">
      <path fill="#000" d="M12.4,13.3c3.8-1.5,3.9-6.9,0.1-8.5l-0.6-0.3l0.9,2l-0.7,0.3l-1.4-3.1l3.1-1.4l0.3,0.7l-1.8,0.9l0.6,0.3c4.4,1.9,4.3,8.2-0.2,9.9l-0.5,0.2l1.9,0.9l-0.3,0.7l-3.1-1.4l1.4-3.1l0.7,0.3L12,13.5L12.4,13.3z"/>
      <path fill="#fff" d="M13.8,2.3l0.3,0.7l-1.8,0.9l0.6,0.3c0.2,0.1,0.4,0.2,0.6,0.3c3.5,2.1,3.5,7.3,0,9.3c-0.2,0.1-0.5,0.2-0.7,0.4l-0.5,0.2l1.9,0.9l-0.3,0.7l-3.1-1.4l1.4-3.1l0.7,0.3L12,13.5l0.5-0.2c0.3-0.1,0.7-0.3,0.9-0.5C16,11,16,7,13.4,5.2c-0.2-0.2-0.5-0.3-0.8-0.4L12,4.5l0.9,2l-0.7,0.3l-1.4-3.1L13.8,2.3z M14.3,0.9l1.2,2.5l-0.9,0.4c3.5,2.5,3.4,7.8,0,10.3l0.9,0.4l-1.2,2.5l-4.9-2.3l2.3-4.9l2.1,1c0.7-1.2,0.7-2.7,0-3.8l-2.1,1L9.4,3.2L14.3,0.9z"/>
    </g>
  </g>
  <filter id="a">
    <feDropShadow dx="0" dy="1" flood-opacity="0.35" stdDeviation="1"/>
  </filter>
</svg>`
}
