import * as React from 'react'
import { useObserver } from 'mobx-react'

import { TextContentPanel } from './content-panel'
import { getSelectionFilter } from '../helpers/selection-filter'
import { TextStylePanelContent } from './style-panel'
import { useModule } from '../../utils/modulize'
import { StyleEditorSlotModuleToken } from '../gdl-style-panel/module'
import { EditorSlotModuleToken } from '../editor/editor-slot-module'
import { EditorContext } from '../helpers/react-context'
import { i18n } from '../../utils/i18n'
import { TextAppearancePanelContent } from './appearance-panel'

/**@name 文字小组件 */
export const UseTextPanelWidget = React.memo(() => {
  const { session } = React.useContext(EditorContext)
  const EditorSlotModule = useModule(EditorSlotModuleToken)
  const StyleEditorSlotModule = useModule(StyleEditorSlotModuleToken)
  return useObserver(() => {
    if (!EditorSlotModule) return null

    const selection = session.selection.set
    if (!selection.size) return null

    const targets = getSelectionFilter(session.root, selection).textsOnly
    if (targets.size !== selection.size) return null

    return (
      <>
        <EditorSlotModule.properties.Portal priority={40} title={<内容 />}>
          <TextContentPanel targets={targets} />
        </EditorSlotModule.properties.Portal>
        <EditorSlotModule.properties.Portal priority={15} title={<外观 />}>
          <TextAppearancePanelContent targets={targets} />
        </EditorSlotModule.properties.Portal>
        {!StyleEditorSlotModule ? null : (
          <StyleEditorSlotModule.portal.Portal
            children={<TextStylePanelContent targets={targets} />}
          />
        )}
      </>
    )
  })
}, () => true)

const 内容 = i18n({
  en: () => 'Content',
  zh: () => '内容',
})

const 外观 = i18n({
  en: () => 'Appearance',
  zh: () => '外观',
})
