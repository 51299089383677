import * as React from "react";
import { runInAction } from "mobx";
import { useObserver } from "mobx-react";
import { ValueOf } from "@chuyuan/poster-utils";
import { Select as AntdSelect } from "antd";
import { Panel } from "../ui-components/panel";
import { Row, TitleText, Square, Gap } from "../ui-components/section";
import { i18n, useLocale } from "../../utils/i18n";
import { LayoutFields } from "./state.ui";
import {
  ButtonBooleanValue,
  ButtonBooleanValueRef,
  ButtonBooleanValueProps,
} from "../ui-components-stateful/boolean";
import { SelectValue, SelectValueRef } from "../ui-components-stateful/enum";
import {
  WH,
  AdjustmentValueType,
  ConstraintValueType,
} from "./state.layout.child";
import { SelectItemProps } from "../ui-components/select";
import { message } from "../ui-components/message";
import { useRefs } from "../../utils/react-hooks";
import { SomeReducer, ReaderLike } from "../../utils/multi-value";
import { SimpleConfirmBox } from "../ui-components/confirm-box";
import { callAutoConversionMessage, callErrorMessage } from "./common";
import { callDropDown } from "../gdl-common/popup";
import { useModule } from "../../utils/modulize";
import { GlobalContextModuleToken } from "../ui-components/global-context";
import {
  Linked,
  SizeConstraintH,
  SizeConstraintV,
  Unlinked,
} from "../../../../poster-ui/icons/new-icons";
import { getSelectionFilter } from "../helpers/selection-filter";
import { EditorContext } from "../helpers/react-context";

/** @name 调整大小面板*/
export const LayoutAdjustmentPanel = React.memo(
  (props: { readonly fields: LayoutFields }) => {
    const { fields } = props;
    const { session } = React.useContext(EditorContext);

    return useObserver(() => {
      const { targets } = fields;
      const selection = session.selection.set;

      if (!targets.length) return null;

      const constraints = fields.constraints.dimension;
      const horizontal = constraints.width.value.parent;
      const vertical = constraints.height.value.parent;

      const isText = () => {
        const targets = getSelectionFilter(session.root, selection).textsOnly;
        if (targets.size !== selection.size) return false;
        return true;
      };

      const isFrame = () => {
        const targets = getSelectionFilter(
          session.root,
          selection
        ).containersOnly;
        if (targets.size !== selection.size) return false;
        return true;
      };

      // const isImage = () => {
      //   const targets = getSelectionFilter(session.root, selection).imagesOnly;
      //   if (targets.size !== selection.size) return false;
      //   return true;
      // };

      const isRoot = () => {
        const targets = getSelectionFilter(session.root, selection).rootOnly;
        if (targets.size !== selection.size) return false;
        return true;
      };

      let SelectOptions: any[] | undefined = [];
      if (isFrame()) {
        SelectOptions = [
          {
            label: "画框适应内容",
            value: "frame adaptive",
          },
        ];
      }
      if (isText()) {
        SelectOptions = [
          {
            label: "文字宽固定高延展",
            value: "text width fixed height adaptive",
          },
          {
            label: "文字高固定宽延展",
            value: "text height fixed width adaptive",
          },
          {
            label: "文字宽高固定文字缩小",
            value: "text width height fixed",
          },
        ];
      }
      if (isRoot()) {
        console.log("这是一个根节点");
        SelectOptions = [];
      }

      // console.log("LayoutResizingItem", {
      //   isText: isText(),
      //   isFrame: isFrame(),
      //   isImage: isImage(),
      //   isRoot: isRoot(),
      //   SelectOptions,
      // });

      const {
        lock,
        dimension: { width, height },
      } = fields.adjustments;

      return (
        <Panel title={<调整大小 />}>
          {lock.disabled ? null : (
            <Row className="margin-y">
              <LockRatioButton
                square
                field={lock}
                horizontal={horizontal}
                vertical={vertical}
                style={{ fontSize: 16 }}
                children={(checked) =>
                  checked === "intermediate" ? (
                    "?"
                  ) : checked ? (
                    <Linked />
                  ) : (
                    <Unlinked />
                  )
                }
              />
              <LockRatioButton
                field={lock}
                horizontal={horizontal}
                vertical={vertical}
                type="text"
                space="stretch"
                style={{ justifyContent: "start", userSelect: "none" }}
                children={(checked) =>
                  !checked ? (
                    <TitleText>
                      <锁定比例 />
                    </TitleText>
                  ) : (
                    <锁定比例 />
                  )
                }
              />
            </Row>
          )}
          {width.hidden.get() ? null : (
            <Row className="margin-y">
              <Square>
                <SizeConstraintH />
              </Square>
              <Gap />
              <Row>
                <AdjustmentValueValue {...width} direction="width" />
              </Row>
            </Row>
          )}
          {height.hidden.get() ? null : (
            <Row className="margin-y">
              <Square>
                <SizeConstraintV />
              </Square>
              <Gap />
              <Row>
                <AdjustmentValueValue {...height} direction="height" />
              </Row>
            </Row>
          )}
          <AntdSelect options={SelectOptions} />
        </Panel>
      );
    });
  }
);
LayoutAdjustmentPanel.displayName = "LayoutAdjustmentPanel";

type LockRatioButtonProps = Omit<
  ButtonBooleanValueProps,
  "historyName" | "onChange" | "props"
> & {
  readonly horizontal: ReaderLike<ConstraintValueType>;
  readonly vertical: ReaderLike<ConstraintValueType>;
};

const LockRatioButton = React.forwardRef<
  ButtonBooleanValueRef,
  LockRatioButtonProps
>((props, forwardedRef) => {
  const { horizontal, vertical, ...rest } = props;
  const refs = useRefs<ButtonBooleanValueRef>().put(forwardedRef);
  const ctx = useModule(GlobalContextModuleToken);
  return (
    <ButtonBooleanValue
      {...rest}
      ref={refs.getRef}
      historyName={(newValue) => (newValue ? `开启锁定比例` : `关闭锁定比例`)}
      props={(checked) => (checked !== true ? undefined : { color: "primary" })}
      onChange={(newValue) =>
        runInAction(() => {
          if (!ctx) return false;

          const { ref } = refs;
          if (!ref) {
            message.error(ctx, "ref is null");
            return false;
          }
          const value = props.field;

          const run = () => value.set(newValue).records.some(Boolean);
          if (
            newValue &&
            (new SomeReducer(
              horizontal,
              (x) => x === "stretch" || x === "scale"
            ).get() ||
              new SomeReducer(
                vertical,
                (x) => x === "stretch" || x === "scale"
              ).get())
          ) {
            // 即将切换到锁定比例, 如果存在调整大小的值为拉伸/缩放时, 需要询问
            return (cb) => {
              callDropDown({
                ctx,
                target: ref,
                render: ({ onClose }) => (
                  <SimpleConfirmBox
                    description={() => <锁定比例时存在拉伸缩放描述 />}
                    onOk={() => {
                      onClose();
                      cb(run());
                    }}
                    onCancel={() => {
                      onClose();
                      cb(false);
                    }}
                  />
                ),
              });
            };
          }
          return run();
        })
      }
    />
  );
});

type AdjustmentValueValueProps = Readonly<
  ValueOf<LayoutFields["adjustments"]["dimension"]>
> & {
  readonly direction: WH;
};

const AdjustmentValueValue = React.memo(
  React.forwardRef<SelectValueRef, AdjustmentValueValueProps>((props, ref) => {
    const { value, possibleValues, direction } = props;

    const locale = useLocale();

    const ctx = useModule(GlobalContextModuleToken);

    const historyTag = direction === "width" ? "横向" : "纵向";

    return (
      <SelectValue
        ref={ref}
        field={value}
        filter={possibleValues}
        historyName={`${historyTag}调整大小`}
        options={AdjustmentValueOptions}
        onChange={(newValue) =>
          runInAction(() => {
            const { records } = value.set(newValue);
            try {
              const changes = Array.from(
                (function* () {
                  for (const record of records) {
                    if (typeof record === "object") {
                      for (const item of record) {
                        for (const change of item.changes) {
                          yield {
                            ...item,
                            width: false,
                            height: false,
                            [change.key]: true,
                          };
                        }
                      }
                    }
                  }
                })()
              );
              callAutoConversionMessage(ctx, [changes], locale);
            } catch (e) {
              console.error(e);
              if (ctx) callErrorMessage(ctx, e);
            }
            return records.some(Boolean);
          })
        }
      />
    );
  })
);
AdjustmentValueValue.displayName = "AdjustmentValueValue";

const AdjustmentValueOptions: ReadonlyArray<
  SelectItemProps<AdjustmentValueType>
> = [
  {
    value: "fill",
    label: () => <填充容器 />,
  },
  {
    value: "fixed",
    label: () => <固定尺寸 />,
  },
  {
    value: "adaptive",
    label: () => <适应内容 />,
  },
  {
    value: "ratio",
    label: () => <固定比例 />,
  },
  {
    value: "contain",
    label: () => <等比适应 />,
  },
  {
    value: "cover",
    label: () => <等比覆盖 />,
  },
  {
    value: "scale",
    label: () => <等比缩放 />,
  },
];

const 调整大小 = i18n({
  en: () => "Adjustment",
  zh: () => "调整大小",
});

const 锁定比例 = i18n({
  en: () => "Lock Ratio",
  zh: () => "锁定比例",
});

const 填充容器 = i18n({
  en: () => "Fill Container",
  zh: () => "填充容器",
});

const 固定尺寸 = i18n({
  en: () => "Fixed Size",
  zh: () => "固定尺寸",
});

const 适应内容 = i18n({
  en: () => "Adaptive",
  zh: () => "适应内容",
});

const 等比适应 = i18n({
  en: () => "Contain",
  zh: () => "等比适应",
});

const 等比覆盖 = i18n({
  en: () => "Cover",
  zh: () => "等比覆盖",
});

const 等比缩放 = i18n({
  en: () => "Scale",
  zh: () => "等比缩放",
});

const 固定比例 = i18n({
  en: () => "Ratio",
  zh: () => "固定比例",
});

const 锁定比例时存在拉伸缩放描述 = i18n({
  en: () =>
    "Lock ratio will automatically convert sides whose constraint is stretch/scale. Are you sure to proceed?",
  zh: () =>
    "约束条件为【拉伸/缩放】时不支持锁定比例，系统将自动替换为默认值。确定开启锁定比例吗？",
});
