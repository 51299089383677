import { i18n, Locale } from "../../utils/i18n";
import { SelectableTarget } from "../editor-state/types";

/**
 * 返回画布节点/元素的名字
 * @param target 节点/元素
 * @param locale 当前语言
 */
export function getGDLTargetName(target: SelectableTarget, locale?: Locale) {
  if (target.kind === "frame") {
    if (!target.parent()) return 页面.get(locale);
    return 组.get(locale);
  }

  const { name } = target.properties;
  if (name) return name;

  if (target.kind === "image") {
    return 图片.get(locale);
  }

  if (target.kind === "text") {
    return target.content.text || 文本.get(locale);
  }

  return 未知元素.get(locale);
}

const 页面 = i18n({
  en: () => "Page",
  zh: () => "页面",
});

const 图片 = i18n({
  en: () => "Image",
  zh: () => "图片",
});

const 文本 = i18n({
  en: () => "Text",
  zh: () => "文本",
});

const 组 = i18n({
  en: () => "Group",
  zh: () => "组",
});

const 未知元素 = i18n({
  en: () => "Unknown",
  zh: () => "未知元素",
});
