// tslint:disable max-line-length

import * as React from "react";
import styled from "styled-components";
import { runInAction } from "mobx";
import { useObserver } from "mobx-react";
import * as tinycolor from "tinycolor2";
import {
  EMPTY_ARRAY,
  intersectSets,
  EMPTY_OBJECT,
} from "@chuyuan/poster-utils";
import { once } from "lodash";

import type {
  CommonFillingPaint,
  CommonFields,
  CommonState,
  CommonFillingPaintImageContent,
  CommonFillingPaintEmpty,
} from "./types";
import { InputNumber, InputNumberRef } from "../../ui-components/input-number";
import { CommonProps } from "../../ui-components/common";
import {
  stringifyTinyColor,
  formatColorString,
  CommonFillingPaintTypes,
  CommonFillingPaintInputTypes,
} from "./state";
import {
  formatGradientStops,
  getCssLinearGradientStringFromGradientStops,
  getCssRadialGradientStringFromGradientStops,
  GradientPicker,
} from "../../ui-components/gradient-picker";
import { i18n, useLocale } from "../../../utils/i18n";
import {
  DuplexLike,
  DuplexFieldLike,
  ScaleValueField,
} from "../../../utils/multi-value";
import { getInputProps } from "../../../utils/react-multi-value";
import {
  InputTextPlain,
  InputTextPlainRef,
} from "../../ui-components/input-text";
import { Row, Gap, TitleText } from "../../ui-components/section";
import { PopupPopover } from "../../ui-components/popup-popover";
import { StyledSketchPicker } from "../../ui-components/sketch-picker";
import { createTinyColor } from "../../../utils/tinycolor-helpers";
import { SingleSlider } from "../../ui-components/slider";
import { AngleBtn } from "../../ui-components/angle-btn";
import type { Anchor } from "../../ui-components/popup-locator";
import { useRaf } from "../../../utils/react-hooks";
import { Tooltip } from "../../ui-components/tooltip";
import { EditorContext } from "../../helpers/react-context";
import { ImageContent } from "../../gdl-image/image-content";

export type FillingPaintPreset = "circle" | "long";

export interface CommonFillingPaintValueProps<T extends CommonFillingPaint>
  extends CommonProps {
  readonly preset?: FillingPaintPreset;
  readonly field: DuplexLike<T | CommonFillingPaintEmpty>;
  readonly supportedTypes?: Iterable<T["kind"] | "empty">;
}

export interface CommonFillingPaintStateProps<T extends CommonFillingPaint>
  extends CommonFillingPaintValueProps<T> {
  readonly preset: FillingPaintPreset;
}

export function formatSupportedTypes(
  this: Pick<CommonState, "props" | "fields">
) {
  const { supportedTypes } = this.props;
  if (!supportedTypes) return CommonFillingPaintTypes;
  const currentType = this.fields.type.get();
  const set = new Set(supportedTypes);
  if (currentType && currentType !== "empty") set.add(currentType);
  return new Set(intersectSets(CommonFillingPaintInputTypes, set));
}

const getEmptyValueLine = once(() =>
  btoa(
    `<svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><line x1="0" x2="24" y1="24" y2="0" stroke="#eb5757" strokeLinecap="square"/></svg>`
  )
);

export function getCssBackground(fields: CommonFields) {
  const { type } = fields;
  const typeValue = type.get();
  if (!typeValue) return;
  if (typeValue === "color") {
    const { color } = fields.colors;
    if (color.variety === "various") return;
    const value = color.get();
    return { backgroundColor: value };
  }
  if (typeValue === "linear gradient") {
    const { stops, angle } = fields.linearGradients;
    if (stops.variety === "various" || angle.variety === "various") return;
    const stopsValue = stops.get();
    const angleValue = angle.get();
    const value = getCssLinearGradientStringFromGradientStops(
      formatGradientStops(stopsValue),
      angleValue
    );
    return { backgroundImage: value };
  }
  if (typeValue === "radial gradient") {
    const { stops } = fields.radialGradients;
    if (stops.variety === "various") return;
    const stopsValue = stops.get();
    const value = getCssRadialGradientStringFromGradientStops(
      formatGradientStops(stopsValue),
      "circle at 50%"
    );
    return { backgroundImage: value };
  }
  if (typeValue === "image") {
    const { image } = fields.images;
    if (image.variety === "various") return;
    const imageValue = image.get();
    if (!imageValue) return EMPTY_OBJECT;
    const value = `url(${JSON.stringify(imageValue.url)})`;
    return { backgroundImage: value };
  }
  if (typeValue === "empty") {
    return {
      backgroundColor: "#fff",
      backgroundImage: `url(data:image/svg+xml;base64,${getEmptyValueLine()})`,
      backgroundSize: "100%",
    };
  }
  return undefined;
}

export const FillingPaintValueLong = React.memo<{
  readonly state: CommonState;
}>(({ state }) => {
  return useObserver(() => {
    const { props, fields } = state;
    const { className, style, disabled, readOnly } = props;
    const typeValue = fields.type.get();

    return (
      <Outline
        ref={(r) => (state.outline = r)}
        className={className}
        style={style}
      >
        <Block state={state} />
        {disabled ||
        readOnly ||
        !(
          state.isPickerVisible &&
          (!typeValue || state.supportedTypes.has(typeValue)) &&
          state.outline &&
          state.block
        ) ? null : (
          <PopupPopover
            target={state.block.getBoundingClientRect()}
            avoids={[state.outline.getBoundingClientRect()]}
            onClose={() => runInAction(() => (state.isPickerVisible = false))}
            children={<UniversalPicker state={state} />}
          />
        )}
      </Outline>
    );
  });
});
FillingPaintValueLong.displayName = "FillingPaintValueLong";

const CIRCLE_BLOCK_ANCHORS: readonly Anchor[] = [
  { target: "center", content: "bottom" },
  { target: "center", content: "top" },
  { target: "center", content: "left" },
  { target: "center", content: "right" },
];

export const FillingPaintValueCircle = React.memo<{
  readonly state: CommonState;
}>((props) => {
  const { state } = props;

  return useObserver(() => {
    const { disabled, readOnly } = state.props;
    const { fields } = state;
    const typeValue = fields.type.get();

    return (
      <>
        <BlockCircle state={state} />
        {disabled ||
        readOnly ||
        !(
          state.isPickerVisible &&
          (!typeValue || state.supportedTypes.has(typeValue)) &&
          state.block
        ) ? null : (
          <PopupPopover
            target={state.block.getBoundingClientRect()}
            anchors={CIRCLE_BLOCK_ANCHORS}
            onClose={() => runInAction(() => (state.isPickerVisible = false))}
            children={<UniversalPicker state={state} />}
          />
        )}
      </>
    );
  });
});
FillingPaintValueCircle.displayName = "FillingPaintValueCircle";

const Block = React.memo((props: { readonly state: CommonState }) => {
  const { state } = props;

  return useObserver(() => {
    const background = state.cssBackground;
    return (
      <div
        ref={(r) => (state.block = r)}
        className="block"
        style={background}
        onClick={() => runInAction(() => (state.isPickerVisible = true))}
        children={background ? undefined : "?"}
      />
    );
  });
});
Block.displayName = "Block";

const BlockCircle = React.memo((props: { readonly state: CommonState }) => {
  const { state } = props;

  return useObserver(() => {
    const { className, style, disabled } = state.props;
    const background = state.cssBackground;
    return (
      <OutlineCircle
        ref={(r) => (state.block = r)}
        className={`${className ? className : ""} ${
          disabled ? "disabled" : ""
        }`}
        style={
          style
            ? background
              ? { ...style, ...background }
              : style
            : background
        }
        onClick={() => runInAction(() => (state.isPickerVisible = true))}
        children={background ? undefined : "?"}
      />
    );
  });
});
BlockCircle.displayName = "BlockCircle";

const ColorValue = React.forwardRef<
  InputTextPlainRef,
  {
    readonly field: DuplexFieldLike<string>;
  }
>((props, ref) => {
  const { field } = props;

  const { session } = React.useContext(EditorContext);
  const locale = useLocale();

  return useObserver(() => {
    const colorProps = getInputProps(field, locale);
    const { value } = colorProps;
    return (
      <InputTextPlain
        {...colorProps}
        value={value && formatColorString(value)}
        ref={ref}
        className="input-color"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        onChange={(newValue) =>
          runInAction(() => {
            const tc = tinycolor(formatColorString(newValue));
            if (!tc.isValid()) return;
            field.set(stringifyTinyColor(tc));
          })
        }
        onComplete={() =>
          runInAction(() => {
            session.history.push({ name: "修改色值" });
          })
        }
      />
    );
  });
});
ColorValue.displayName = "ColorValue";

export const OpacityValueShort = React.forwardRef<
  InputNumberRef,
  {
    readonly field: DuplexFieldLike<number>;
  }
>((props, ref) => {
  const { field: inputField } = props;

  const { session } = React.useContext(EditorContext);
  const locale = useLocale();

  const field = React.useMemo(
    () => new ScaleValueField(inputField, 100),
    [inputField]
  );

  return useObserver(() => {
    const fieldProps = getInputProps(field, locale);
    return (
      <InputNumber
        {...fieldProps}
        ref={ref}
        className="input-opacity"
        min={0}
        max={100}
        step={1}
        suffix="%"
        dragReverse
        onChange={(newValue) => runInAction(() => field.set(newValue))}
        onComplete={() =>
          runInAction(() => {
            session.history.push({ name: "修改填充不透明度" });
          })
        }
      />
    );
  });
});
OpacityValueShort.displayName = "OpacityValueShort";

export const OpacityValueLong = React.memo(
  (props: { readonly field: DuplexFieldLike<number> }) => {
    const { field: inputField } = props;

    const { session } = React.useContext(EditorContext);
    const locale = useLocale();

    const field = React.useMemo(
      () => new ScaleValueField(inputField, 100),
      [inputField]
    );

    return useObserver(() => {
      const fieldProps = getInputProps(field, locale);
      const onChange = (newValue: number) =>
        runInAction(() => field.set(newValue));
      const onComplete = () =>
        runInAction(() => {
          session.history.push({ name: "修改填充不透明度" });
        });
      return (
        <>
          <SingleSlider
            {...fieldProps}
            min={0}
            max={100}
            step={1}
            onChange={onChange}
            onComplete={onComplete}
          />
          <Gap />
          <InputNumber
            {...fieldProps}
            min={0}
            max={100}
            step={1}
            suffix="%"
            dragDirection="horizontal"
            onChange={onChange}
            onComplete={onComplete}
            style={{ maxWidth: 64 }}
          />
        </>
      );
    });
  }
);
OpacityValueLong.displayName = "OpacityValueLong";

export const OpacityFieldLong = React.memo(
  (props: { readonly field: DuplexFieldLike<number> }) => {
    return (
      <>
        <Row className="margin-y">
          <TitleText>
            <不透明度 />
          </TitleText>
        </Row>
        <Row className="margin-y">
          <OpacityValueLong field={props.field} />
        </Row>
      </>
    );
  }
);
OpacityFieldLong.displayName = "OpacityFieldLong";

const TABS = [
  {
    key: "color",
    getIcon: () => PureColorPickerTabIcon,
    title: () => <纯色 />,
  },
  {
    key: "linear gradient",
    getIcon: () => LinearGradientPickerTabIcon,
    title: () => <线性渐变 />,
  },
  {
    key: "radial gradient",
    getIcon: () => RadialGradientPickerTabIcon,
    title: () => <径向渐变 />,
  },
  {
    key: "image",
    getIcon: () => ImagePickerTabIcon,
    title: () => <图片 />,
  },
  {
    key: "empty",
    getIcon: () => EmptyPickerTabIcon,
    title: () => <空值 />,
  },
] as const;

const UniversalPicker = React.memo((props: { readonly state: CommonState }) => {
  const { state } = props;

  const { session } = React.useContext(EditorContext);

  return useObserver(() => {
    const { fields, supportedTypes } = state;
    const typeValue = fields.type.get();
    const pickerContent = state.renderPickerContent?.(typeValue);
    return (
      <PickerOutline className={!pickerContent ? "empty" : ""}>
        {supportedTypes.size < 2 ? null : (
          <PickerTab>
            {TABS.map((item) => {
              if (!supportedTypes.has(item.key)) return null;
              const Icon = item.getIcon();
              const selected = item.key === typeValue;
              return (
                <Tooltip key={item.key} content={item.title}>
                  <Icon
                    className={selected ? "selected" : ""}
                    onClick={
                      selected
                        ? undefined
                        : () => {
                            if (
                              !state.fields.type
                                .set(item.key)
                                .records.some(Boolean)
                            )
                              return;
                            session.history.push({ name: "切换填充类型" });
                          }
                    }
                  />
                </Tooltip>
              );
            })}
          </PickerTab>
        )}
        {pickerContent}
      </PickerOutline>
    );
  });
});
UniversalPicker.displayName = "UniversalPicker";

export const PureColorPickerContent = React.memo(
  (props: { readonly state: CommonState }) => {
    const { state } = props;

    const { session } = React.useContext(EditorContext);

    const raf = useRaf();

    const mutable = React.useRef<{
      pushedHistory?: boolean;
    }>({}).current;

    return useObserver(() => {
      const p = state.props;
      const { fields } = state;
      const { formatted, color, opacity } = fields.colors;
      const disabled = p.disabled || p.readOnly || formatted.disabled;
      const colorData = color.data;
      const opacityData = opacity.data;
      const tc = createTinyColor(
        colorData.variety === "various" ? "#fff" : colorData.value,
        opacityData.variety === "various" ? 1 : opacityData.value
      );
      return (
        <div style={{ padding: 10 }}>
          <StyledSketchPicker
            presetColors={EMPTY_ARRAY as []}
            color={tc.isValid() ? tc.toString() : "#ffffff00"}
            onChange={
              disabled
                ? undefined
                : (result) => {
                    raf.push(() =>
                      runInAction(() => {
                        const newTc = tinycolor(result.rgb);
                        if (!newTc.isValid()) return;
                        formatted.set({
                          kind: "color",
                          color: newTc.clone().setAlpha(1).toHexString(),
                          opacity: newTc.getAlpha(),
                        });
                      })
                    );
                  }
            }
            onChangeComplete={
              disabled
                ? undefined
                : () =>
                    runInAction(() => {
                      raf.flush();
                      const key = mutable.pushedHistory
                        ? "pushOrReplace"
                        : "push";
                      session.history[key]({ name: "修改填充色" });
                      mutable.pushedHistory = true;
                    })
            }
          />
        </div>
      );
    });
  }
);
PureColorPickerContent.displayName = "PureColorPickerContent";

export const LinearGradientPickerContent = React.memo(
  (props: {
    readonly disabledInterpolation?: boolean;
    readonly state: CommonState;
  }) => {
    const { state, disabledInterpolation } = props;

    const { session } = React.useContext(EditorContext);

    const raf = useRaf();

    const mutable = React.useRef<{
      pushedHistory?: boolean;
    }>({}).current;

    return useObserver(() => {
      const p = state.props;
      const fields = state.fields.linearGradients;
      const { stops, angle, opacity } = fields;
      const disabled = p.disabled || p.readOnly || stops.disabled;
      const stopsValue = stops.get();
      return (
        <>
          <GradientPicker
            disabledInterpolation={disabledInterpolation}
            angle={
              <AngleBtn
                style={{ marginLeft: 12 }}
                value={angle.get() ?? "various"}
                onChange={(newValue) => {
                  raf.push(() => runInAction(() => angle.set(newValue)));
                }}
                onComplete={() =>
                  runInAction(() => {
                    raf.flush();
                    session.history.push({ name: "修改线性渐变填充角度" });
                  })
                }
              />
            }
            stops={stopsValue}
            onChange={
              disabled
                ? undefined
                : (newValue) => {
                    raf.push(() => runInAction(() => stops.set(newValue)));
                  }
            }
            onChangeComplete={
              disabled
                ? undefined
                : () =>
                    runInAction(() => {
                      raf.flush();
                      const key = mutable.pushedHistory
                        ? "pushOrReplace"
                        : "push";
                      session.history[key]({ name: "修改填充线性渐变色" });
                      mutable.pushedHistory = true;
                    })
            }
          />
          {!opacity ? null : (
            <PickerContent>
              <OpacityFieldLong field={opacity} />
            </PickerContent>
          )}
        </>
      );
    });
  }
);
LinearGradientPickerContent.displayName = "LinearGradientPickerContent";

export const RadialGradientPickerContent = React.memo(
  (props: {
    readonly disabledInterpolation?: boolean;
    readonly state: CommonState;
  }) => {
    const { state, disabledInterpolation } = props;

    const { session } = React.useContext(EditorContext);

    const raf = useRaf();

    const mutable = React.useRef<{
      pushedHistory?: boolean;
    }>({}).current;

    return useObserver(() => {
      const p = state.props;
      const fields = state.fields.radialGradients;
      const { stops, opacity } = fields;
      const disabled = p.disabled || p.readOnly || stops.disabled;
      const stopsValue = stops.get();
      return (
        <>
          <GradientPicker
            disabledInterpolation={disabledInterpolation}
            stops={stopsValue}
            onChange={
              disabled
                ? undefined
                : (newValue) => {
                    raf.push(() => runInAction(() => stops.set(newValue)));
                  }
            }
            onChangeComplete={
              disabled
                ? undefined
                : () =>
                    runInAction(() => {
                      raf.flush();
                      const key = mutable.pushedHistory
                        ? "pushOrReplace"
                        : "push";
                      session.history[key]({ name: "修改填充径向渐变色" });
                      mutable.pushedHistory = true;
                    })
            }
          />
          {!opacity ? null : (
            <PickerContent>
              <OpacityFieldLong field={opacity} />
            </PickerContent>
          )}
        </>
      );
    });
  }
);
RadialGradientPickerContent.displayName = "RadialGradientPickerContent";

export const ImageContentValue = React.memo(
  (props: {
    readonly field: DuplexFieldLike.OptionalReadType<CommonFillingPaintImageContent>;
  }) => {
    const { field } = props;

    const { session } = React.useContext(EditorContext);
    const locale = useLocale();

    return useObserver(() => {
      const fieldProps = getInputProps(field, locale);
      return (
        <ImageContent
          {...fieldProps}
          value={fieldProps.value?.url}
          onChange={(data) =>
            runInAction(() => {
              const {
                url,
                dimension: { width, height },
              } = data;
              if (!field.set({ url, width, height }).records.some(Boolean))
                return;
              session.history.push({ name: "修改填充图片" });
            })
          }
        />
      );
    });
  }
);
ImageContentValue.displayName = "ImageContentValue";

export const ImageScaleField = React.memo(
  (props: { readonly field: DuplexFieldLike<number> }) => {
    return (
      <>
        <Row className="margin-y">
          <TitleText>
            <缩放 />
          </TitleText>
        </Row>
        <Row className="margin-y">
          <ImageScaleValue field={props.field} />
        </Row>
      </>
    );
  }
);
ImageScaleField.displayName = "ImageScaleField";

const ImageScaleValue = React.memo(
  (props: { readonly field: DuplexFieldLike<number> }) => {
    const { field: inputField } = props;

    const { session } = React.useContext(EditorContext);
    const locale = useLocale();

    const field = React.useMemo(
      () => new ScaleValueField(inputField, 100),
      [inputField]
    );

    return useObserver(() => {
      const fieldProps = getInputProps(field, locale);
      const onChange = (newValue: number) =>
        runInAction(() => field.set(newValue));
      const onComplete = () =>
        runInAction(() => {
          session.history.push({ name: "修改图片填充缩放" });
        });
      return (
        <>
          <SingleSlider
            {...fieldProps}
            min={0}
            max={200}
            step={1}
            onChange={onChange}
            onComplete={onComplete}
          />
          <Gap />
          <InputNumber
            {...fieldProps}
            min={0}
            max={1000}
            step={1}
            suffix="%"
            dragDirection="horizontal"
            onChange={onChange}
            onComplete={onComplete}
            style={{ maxWidth: 64 }}
          />
        </>
      );
    });
  }
);
ImageScaleValue.displayName = "ImageScaleValue";

const Outline = styled.div`
  flex: 1 1 0;
  display: flex;
  align-items: center;
  width: 0;
  height: 32px;
  /* background: #f8f7f6; */
  border-radius: 4px;
  /* padding-left: 8px; */

  & > .block {
    /* width: 16px;
    height: 16px; */
    width: 52px;
    height: 32px;
    margin-right: 4px;
    color: #606060;
    font-size: 12px;
    box-sizing: border-box;
    background: transparent;
    background-size: cover;
    border: 1px solid #fff;
    border-radius: 2px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
  }

  & > .input-color {
    flex: 1 1 0;
    background: transparent;
    display: block;
    width: 0;
    outline: 0;
    border: 0;

    color: #606060;
    padding: 0;
    padding-right: 8px;
    font-size: 12px;
    line-height: 16px;
    height: 100%;
  }

  & > .separator {
    flex: 0 0 auto;
    height: 100%;
    border-right: 1px solid #eae9e8;
  }

  & > .input-opacity {
    flex: 0 0 auto;
    width: 50px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
Outline.displayName = "Outline";

const OutlineCircle = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: transparent;
  background-size: cover;
  border-radius: 12px;
  border: 1px solid #eae9e8;
  box-sizing: border-box;
  user-select: none;
  cursor: pointer;

  &:not(.disabled) {
    &:hover,
    &:active {
      border-color: #0040f0;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
OutlineCircle.displayName = "OutlineCircle";

const PickerOutline = styled.div`
  width: 240px;

  &.empty {
    width: auto;
  }
`;
PickerOutline.displayName = "PickerOutline";

const PickerTab = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
`;
PickerTab.displayName = "PickerTab";

export const PickerContent = styled.div`
  padding: 8px;
`;
PickerContent.displayName = "PickerContent";

const PickerTabIcon = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  margin: 4px;
  background: transparent;
  color: #eae9e8;
  border: 1px solid #eae9e8;
  border-radius: 100px;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s;

  &.selected,
  &:hover {
    color: rgba(0, 64, 240, 0.6);
    border-color: rgba(0, 64, 240, 0.6);
  }
`;
PickerTabIcon.displayName = "PickerTabIcon";

const PureColorPickerTabIcon = styled(PickerTabIcon)`
  background: rgba(234, 233, 232, 0.3);

  &.selected,
  &:hover {
    background: rgba(0, 64, 240, 0.1);
  }
`;
PureColorPickerTabIcon.displayName = "PureColorPickerTabIcon";

const LinearGradientPickerTabIcon = styled(PickerTabIcon)`
  background: linear-gradient(#eae9e8 22.75%, rgba(255, 255, 255, 0) 100%);

  &.selected,
  &:hover {
    background: linear-gradient(
      rgba(0, 63, 240, 0.3) 0%,
      rgba(0, 64, 240, 0) 100%
    );
  }
`;
LinearGradientPickerTabIcon.displayName = "LinearGradientPickerTabIcon";

const RadialGradientPickerTabIcon = styled(PickerTabIcon)`
  background: radial-gradient(
    circle at 50%,
    rgba(255, 255, 255, 0.1) 0%,
    #eae9e8 92.71%
  );

  &.selected,
  &:hover {
    background: radial-gradient(
      circle at 50%,
      rgba(255, 255, 255, 0.03) 0%,
      rgba(0, 64, 240, 0.3) 100%
    );
  }
`;
RadialGradientPickerTabIcon.displayName = "RadialGradientPickerTabIcon";

const ImagePickerTabIcon = React.forwardRef<
  HTMLDivElement,
  {
    readonly className?: string;
    readonly style?: React.CSSProperties;
  }
>((props, ref) => {
  return (
    <PickerTabIcon {...props} ref={ref}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.4661 15.3356L11.7143 15.6098L12.0491 15.4527L17.4491 12.9191L22.3954 16.4136C20.7438 20.5595 16.6412 23.5 11.8368 23.5C5.65017 23.5 0.632422 18.6272 0.502578 12.5831L5.44301 8.68208L11.4661 15.3356Z"
          fill="currentColor"
          stroke="currentColor"
        />
        <circle
          cx="15"
          cy="7"
          r="2.5"
          fill="currentColor"
          stroke="currentColor"
        />
      </svg>
    </PickerTabIcon>
  );
});
ImagePickerTabIcon.displayName = "ImagePickerTabIcon";

const EmptyPickerTabIcon = React.forwardRef<
  HTMLDivElement,
  {
    readonly className?: string;
    readonly style?: React.CSSProperties;
  }
>((props, ref) => {
  return (
    <PickerTabIcon {...props} ref={ref}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line x1="0" x2="22" y1="22" y2="0" stroke="currentColor" />
      </svg>
    </PickerTabIcon>
  );
});
EmptyPickerTabIcon.displayName = "EmptyPickerTabIcon";

const 纯色 = i18n({
  en: () => "Pure Color",
  zh: () => "纯色",
});

const 线性渐变 = i18n({
  en: () => "Linear Gradient",
  zh: () => "线性渐变",
});

const 径向渐变 = i18n({
  en: () => "Radial Gradient",
  zh: () => "径向渐变",
});

const 图片 = i18n({
  en: () => "Image",
  zh: () => "图片",
});

const 空值 = i18n({
  en: () => "Null",
  zh: () => "空值",
});

const 缩放 = i18n({
  en: () => "Scale",
  zh: () => "缩放",
});

const 不透明度 = i18n({
  en: () => "Opacity",
  zh: () => "不透明度",
});
