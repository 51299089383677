import * as React from "react";
import { useObserver } from "mobx-react";
import { runInAction } from "mobx";

import { MaybeThunk, dethunk } from "@chuyuan/poster-utils";

import {
  DuplexLike,
  SomeReducer,
  ReadFieldLike,
  DuplexField,
} from "../../../utils/multi-value";
import {
  Alignment2D,
  Alignment2DRef,
  Alignment2DProps,
  Alignment2DData,
  AlignmentType,
} from "../../ui-components/alignment-2d";
import { HV_KEYS } from "../../helpers/misc";
import { EditorContext } from "../../helpers/react-context";
import { SessionState } from "../../editor-state/session-state";

export type Alignment2DValueRef = Alignment2DHistoryRef;

export type Alignment2DValueProps = Omit<Alignment2DHistoryProps, "value"> & {
  readonly disability?: ReadFieldLike<unknown>;
  readonly horizontal: DuplexLike<AlignmentType | undefined>;
  readonly vertical: DuplexLike<AlignmentType | undefined>;
};

export const Alignment2DValue = React.memo(
  React.forwardRef<Alignment2DValueRef, Alignment2DValueProps>((props, ref) => {
    const {
      horizontal: _0,
      vertical: _1,
      disability,
      onChange,
      ...rest
    } = props;

    const [horizontal, vertical] = HV_KEYS.map((key) => {
      const duplex = props[key];
      return React.useMemo(() => {
        const hasUndefined = new SomeReducer(duplex, (x) => !x);
        const field = new DuplexField(duplex);
        return { hasUndefined, field };
      }, [duplex]);
    });

    return useObserver(() => {
      const hData = horizontal.field.data;
      const vData = vertical.field.data;
      const h = hData.value;
      const v = vData.value;

      const data: Alignment2DData | undefined = h
        ? v
          ? {
              kind: "both",
              horizontal: h,
              vertical: v,
            }
          : {
              kind: "single",
              direction: "horizontal",
              value: h,
            }
        : v
        ? {
            kind: "single",
            direction: "vertical",
            value: v,
          }
        : undefined;

      return (
        <Alignment2DHistory
          {...rest}
          ref={ref}
          disabled={!!disability?.get()}
          readOnly={
            horizontal.hasUndefined.get() && vertical.hasUndefined.get()
          }
          placeholder={!data ? "?" : undefined}
          value={data}
          onChange={(newValue) =>
            runInAction(() => {
              // console.log("Alignment2DHistory", { newValue });
              if (onChange) return onChange(newValue);
              if (newValue.kind === "both") {
                return [
                  horizontal.field
                    .set(newValue.horizontal)
                    .records.some(Boolean),
                  vertical.field.set(newValue.vertical).records.some(Boolean),
                ].some(Boolean);
              } else {
                const { field } =
                  newValue.direction === "horizontal" ? horizontal : vertical;
                return field.set(newValue.value).records.some(Boolean);
              }
            })
          }
        />
      );
    });
  })
);
Alignment2DValue.displayName = "Alignment2DValue";

export type Alignment2DHistoryRef = Alignment2DRef;

export interface Alignment2DHistoryProps extends Alignment2DProps {
  readonly onChange?: (
    value: Alignment2DData
  ) => boolean | ((cb: (value: Alignment2DData) => void) => void);
  readonly historyName: MaybeThunk<string>;
}

export const Alignment2DHistory = React.forwardRef<
  Alignment2DHistoryRef,
  Alignment2DHistoryProps
>((inputProps, ref) => {
  const { historyName, ...rest } = inputProps;

  const inputSession = React.useContext(EditorContext).session;

  type State = Required<Pick<Alignment2DProps, "onChange">> & {
    session: SessionState;
    props: Alignment2DHistoryProps;
  };

  const stateRef = React.useRef<State>();
  const state: State =
    stateRef.current ||
    (stateRef.current = {
      session: inputSession,
      props: inputProps,
      onChange: (newValue) => {
        const { props, session } = state;
        const ret = props.onChange?.(newValue);
        if (!ret) return;
        const done = () =>
          runInAction(() => {
            session.history.push({ name: `修改${dethunk(props.historyName)}` });
          });
        return typeof ret === "function" ? ret(done) : done();
      },
    });

  state.session = inputSession;

  state.props = inputProps;

  return <Alignment2D {...rest} ref={ref} onChange={state.onChange} />;
});
Alignment2DHistory.displayName = "Alignment2DHistory";
