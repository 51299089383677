import * as React from "react";
import styled from "styled-components";
import Icon from "@ant-design/icons";

import { ArrowDown } from "../../../../poster-ui/icons/new-icons";

/**
 * 头部可开关的面板
 */
export function HeaderPanel(props: {
  readonly title?: React.ReactNode;
  readonly unfoldable?: boolean;
  readonly children?: React.ReactNode;
}) {
  const { title, unfoldable } = props;
  const [folded, setFolded] = React.useState(false);
  const noTitle = title === undefined && unfoldable;
  return (
    <Wrapper>
      {noTitle ? null : (
        <Title
          className={folded ? "folded" : ""}
          onClick={() => setFolded((x) => !x)}
        >
          <div className="wrapper">
            <div className="text">{title}</div>
            {!unfoldable && <Icon className="fold" component={ArrowDown} />}
          </div>
        </Title>
      )}
      {folded ? null : (
        <Content className={noTitle ? "no-title" : ""}>
          {props.children}
        </Content>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: -1px 0 0;
  border: 1px solid #eae9e8;
  border-width: 1px 0 1px 0;
  box-sizing: content-box;
`;
Wrapper.displayName = "Wrapper";

const Title = styled.div`
  padding: 12px 12px 0 12px;
  cursor: pointer;
  user-select: none;
  background: #f8f7f6;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    margin-bottom: -1px;
    border-bottom: 1px solid #eae9e8;
    box-sizing: content-box;
  }

  .text {
    flex: 1 1 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #606060;
  }

  .fold {
    flex: 0 0 auto;
    color: #202020;
  }

  &.folded {
    .fold {
      transform: rotate(-90deg);
    }
  }
`;
Title.displayName = "Title";

const Content = styled.div`
  padding: 12px;
`;
Content.displayName = "Content";
