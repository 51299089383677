import { createExternalPromise } from "@chuyuan/poster-utils";
import { EventDefinition, EventListener } from "./types";

export class EventHost {
  private _listeners = [
    new Map<string, Set<Function>>(),
    new Map<string, Set<Function>>(),
  ];

  addListener<T>(
    def: EventDefinition<T>,
    listener: EventListener<T>,
    capturing?: boolean
  ) {
    const listeners = this._listeners[capturing ? 0 : 1];
    let set = listeners.get(def.name);
    if (!set) listeners.set(def.name, (set = new Set()));
    if (!set.has(listener)) {
      set.add(listener);
      return true;
    }
    return false;
  }

  removeListener<T>(
    def: EventDefinition<T>,
    listener: EventListener<T>,
    capturing?: boolean
  ) {
    const map = this._listeners[capturing ? 0 : 1];
    const set = map.get(def.name);
    if (set?.has(listener)) {
      set.delete(listener);
      return true;
    }
    return false;
  }

  dispatch<T>(def: EventDefinition<T>, data: T) {
    const event = new CustomEvent<T>(def.name, data);
    for (let i = 0, list = this._listeners, len = list.length; i < len; i++) {
      const listeners = list[i].get(def.name);
      if (listeners && listeners.size) {
        for (const listener of listeners) {
          try {
            listener(event);
          } catch (e) {
            console.error(e);
          }
          if (event.isPropagationStopped()) break;
        }
      }
    }
    return event;
  }

  on<T>(
    def: EventDefinition<T>,
    listener: EventListener<T>,
    capturing?: boolean
  ) {
    this.addListener(def, listener, capturing);
    return () => this.removeListener(def, listener, capturing);
  }

  once<T>(
    def: EventDefinition<T>,
    listener: EventListener<T>,
    capturing?: boolean
  ) {
    const cb = (event: CustomEvent<T>) => {
      this.removeListener(def, cb, capturing);
      listener(event);
    };
    this.addListener(def, cb, capturing);
    return () => this.removeListener(def, cb, capturing);
  }

  waitFor<T>(def: EventDefinition<T>, capturing?: boolean) {
    const xp = createExternalPromise<CustomEvent<T>>();
    const cb = (event: CustomEvent<T>) => {
      this.removeListener(def, cb, capturing);
      xp.resolve(event);
    };
    this.addListener(def, cb, capturing);
    return xp.promise;
  }
}

export class CustomEvent<T = undefined> {
  private _isPropagationStopped = false;
  private _isDefaultPrevented = false;

  constructor(readonly name: string, readonly data: T) {}

  stopPropagation() {
    if (this._isPropagationStopped) return;
    this._isPropagationStopped = true;
  }

  isPropagationStopped() {
    return this._isPropagationStopped;
  }

  preventDefault() {
    if (this._isDefaultPrevented) return;
    this._isDefaultPrevented = true;
  }

  isDefaultPrevented() {
    return this._isDefaultPrevented;
  }
}
