import * as React from "react";
import { autorun, runInAction } from "mobx";

import { Disposers, runSafeAsync, memorize } from "@chuyuan/poster-utils";

import { State } from "./state";
import { CrashSolverInterface } from "./ui";
import { EditorState } from "../editor-state/editor-state";
import { EditorContext } from "../helpers/react-context";

interface CrashSolverErrorBoundaryProps {
  readonly editor: EditorState;
}

interface CrashSolverErrorBoundaryState {
  readonly caughtRecords: readonly ErrorRecord[];
  readonly hasError: boolean;
}

export interface ErrorRecord {
  readonly error: unknown;
  readonly errorInfo: unknown;
  readonly timestamp: number;
}

export interface CrashSolverOperator {
  readonly resume: () => void;
}

class CrashSolverErrorBoundary extends React.Component<
  React.PropsWithChildren<CrashSolverErrorBoundaryProps>,
  CrashSolverErrorBoundaryState
> {
  constructor(props: React.PropsWithChildren<CrashSolverErrorBoundaryProps>) {
    super(props);
    this.state = {
      caughtRecords: [],
      hasError: false,
    };
  }

  @memorize
  get op(): CrashSolverOperator {
    return {
      resume: () => this.setState({ hasError: false, caughtRecords: [] }),
    };
  }

  override componentDidCatch(error: unknown, errorInfo: unknown) {
    const { state } = this;
    this.setState({
      ...state,
      hasError: true,
      caughtRecords: [
        ...state.caughtRecords,
        {
          error,
          errorInfo,
          timestamp: Date.now(),
        },
      ],
    });
    console.error(error);
    console.error(errorInfo);
  }

  override render() {
    const { props, state } = this;
    if (state.hasError) {
      return <CrashSolverInterface op={this.op} errors={state.caughtRecords} />;
    }
    return props.children;
  }
}

export function CrashSolver(props: React.PropsWithChildren<{}>) {
  const editor = React.useContext(EditorContext);

  React.useEffect(() => {
    const disposers = new Disposers();
    disposers.add(
      "sync history",
      autorun(() => {
        const { history } = editor.session;
        runInAction(() => {
          disposers.override(
            "history",
            history.events.subscribe("internalChange", (record) =>
              runSafeAsync(async () => {
                const h = await State.instance.history;
                await h.push(record.name, record.data);
              })
            )
          );
        });
      })
    );
    return () => {
      disposers.clear();
      runSafeAsync(async () => {
        const h = await State.instance.history;
        await h.clear();
      });
    };
  }, [editor]);

  return (
    <>
      <CrashSolverErrorBoundary {...props} editor={editor} />
    </>
  );
}
