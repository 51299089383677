import * as React from "react";
import styled from "styled-components";
import * as pluralize from "pluralize";
import { format } from "date-fns";
import { runInAction } from "mobx";

import { EMPTY_ARRAY, runSafeAsync, predicate } from "@chuyuan/poster-utils";

import { State, History } from "./state";
import type { DBHistory } from "./indexeddb";
import { i18n } from "../../utils/i18n";
import type { ErrorRecord, CrashSolverOperator } from "./entry";
import { useFetcherLike } from "../../utils/react-hooks";
import { Button } from "./button";
import { EditorContext } from "../helpers/react-context";
import { createDataStoreFromDocument } from "@chuyuan/poster-data-access-layer";

export interface CrashSolverInterfaceProps {
  readonly errors: readonly ErrorRecord[];
  readonly op: CrashSolverOperator;
}

const PropsContext = React.createContext(
  undefined as unknown as CrashSolverInterfaceProps
);

export const CrashSolverInterface = React.memo<CrashSolverInterfaceProps>(
  (props) => {
    return (
      <PropsContext.Provider value={props}>
        <Outline>
          <Title>
            <检测到异常崩溃 />
          </Title>
          <Content />
        </Outline>
      </PropsContext.Provider>
    );
  }
);
CrashSolverInterface.displayName = "CrashSolverInterface";

const Content = React.memo(() => {
  const editor = React.useContext(EditorContext);
  const { op } = React.useContext(PropsContext);

  const records = React.useMemo(
    () => editor.session.history.records.slice(),
    EMPTY_ARRAY
  );

  return (
    <>
      {records.length > 0 ? (
        <Button
          onClick={() => {
            runInAction(() => {
              const { root } = createDataStoreFromDocument();
              const session = editor.setNewSession(root);
              session.history.recover(records.slice(0, -1));
            });
            op.resume();
          }}
        >
          <尝试恢复记录 />
        </Button>
      ) : (
        <HistoryFromLocal />
      )}
    </>
  );
});
Content.displayName = "Content";

const HistoryFromLocal = React.memo(() => {
  const historyFetcher = useFetcherLike(
    EMPTY_ARRAY,
    () => State.instance.history
  );

  return historyFetcher.state === "pending" ? (
    <div>
      <正在加载历史记录 />
    </div>
  ) : historyFetcher.state === "rejected" ? (
    <div>
      <无法加载历史记录 />
    </div>
  ) : (
    <HistoryList history={historyFetcher.result} />
  );
});
HistoryFromLocal.displayName = "HistoryFromLocal";

const HistoryList = React.memo((props: { readonly history: History }) => {
  const { history } = props;

  const editor = React.useContext(EditorContext);
  const { op } = React.useContext(PropsContext);

  const [selectedIndex, setSelectedIndex] = React.useState<number>();
  const [list, setList] = React.useState<readonly DBHistory[]>(EMPTY_ARRAY);

  const fetch = () =>
    runSafeAsync(async () => {
      setList(await fetchHistoryList(history));
      setSelectedIndex(undefined);
    });

  React.useEffect(() => {
    fetch();
  }, EMPTY_ARRAY);

  const selectedRecord =
    typeof selectedIndex === "number" &&
    selectedIndex >= 0 &&
    selectedIndex < list.length
      ? list[selectedIndex]
      : undefined;

  return (
    <HistoryListWrapper>
      <HistoryListTitle>
        <共n条记录 count={list.length} />
        <Button onClick={fetch} children={<刷新 />} />
      </HistoryListTitle>
      <HistoryListContent>
        {list
          .slice()
          .reverse()
          .map((record, i) => {
            const selected = i === selectedIndex;
            return (
              <ItemWrapper
                key={i}
                className={selected ? "selected" : ""}
                onClick={
                  selected
                    ? undefined
                    : () => {
                        setSelectedIndex(i);
                      }
                }
              >
                <ItemName>{record.name}</ItemName>
                <ItemTime>
                  {format(record.createdAt, "YY-MM-DD HH:mm:ss")}
                </ItemTime>
              </ItemWrapper>
            );
          })}
      </HistoryListContent>
      <HistoryListFooter>
        <Button
          disabled={!selectedRecord}
          onClick={() => {
            if (!selectedRecord) return;
            runInAction(() => {
              const { root } = createDataStoreFromDocument(selectedRecord.data);
              editor.setNewSession(root);
            });
            op.resume();
          }}
        >
          <尝试恢复记录 />
        </Button>
      </HistoryListFooter>
    </HistoryListWrapper>
  );
});
HistoryList.displayName = "HistoryList";

async function fetchHistoryList(history: History) {
  return (
    await Promise.all(
      history.recordIds.map(async (id) => {
        const ret = await runSafeAsync(() => history.get(id));
        if (ret.state === "rejected") return;
        return ret.result;
      })
    )
  ).filter(predicate);
}

const Outline = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 12px;
  font-family: PingFangSC-Light, PingFang SC, Microsoft YaHei, sans-serif;
  line-height: 1.5;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
Outline.displayName = "Outline";

const Title = styled.div`
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 24px;
`;
Title.displayName = "Title";

const HistoryListWrapper = styled.div`
  max-height: 25%;
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
HistoryListWrapper.displayName = "HistoryListWrapper";

const HistoryListTitle = styled.div`
  flex: 0 0 auto;
  margin-bottom: 12px;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
HistoryListTitle.displayName = "HistoryListTitle";

const HistoryListTitleText = styled.div`
  flex: 0 0 auto;
`;
HistoryListTitleText.displayName = "HistoryListTitleText";

const HistoryListContent = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  font-family: Menlo, Monaco, "Courier New", monospace;
  margin-bottom: 12px;
`;
HistoryListContent.displayName = "HistoryListContent";

const HistoryListFooter = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
HistoryListFooter.displayName = "HistoryListFooter";

const ItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 4px;
  user-select: none;
  cursor: pointer;

  &.selected,
  &:hover {
    background-color: #f8f7f6;
  }
`;
ItemWrapper.displayName = "ItemWrapper";

const ItemName = styled.div`
  flex: 1 1 0;
`;
ItemName.displayName = "ItemName";

const ItemTime = styled.div`
  flex: 0 0 auto;
  margin-left: 12px;
`;
ItemTime.displayName = "ItemTime";

const 检测到异常崩溃 = i18n({
  en: () => "Crash Detected!",
  zh: () => "检测到异常崩溃!",
});

const 正在加载历史记录 = i18n({
  en: () => "pending history...",
  zh: () => "正在加载历史记录...",
});

const 无法加载历史记录 = i18n({
  en: () => "Unable to load history, please try to refresh browser.",
  zh: () => "无法加载历史记录，请尝试刷新浏览器。",
});

const 共n条记录 = i18n<{
  readonly count: number;
}>({
  en: (p) => `${p.count} ${pluralize("record", p.count)}`,
  zh: (p) => `共 ${p.count} 条记录`,
});

const 刷新 = i18n({
  en: () => "Refresh",
  zh: () => "刷新",
});

const 尝试恢复记录 = i18n({
  en: () => "Try to resume record",
  zh: () => "尝试恢复记录",
});
