import { createModuleToken, implementModule } from '../../utils/modulize'
import { PortalContainer } from '../../utils/react-portal-mobx'

export const StyleEditorSlotModuleToken = createModuleToken<StylePanelState>('StyleEditorSlotModule')

/**@name 样式面板注册 */
export const StyleEditorSlotModule = implementModule(StyleEditorSlotModuleToken, {
  state: () => new StylePanelState(),
})

export class StylePanelState {
  readonly portal = new PortalContainer<{
    /**
     * 优先级, 值越大越靠上
     * - 默认值 0
     */
    readonly priority?: number
    /**
     * 渲染函数
     */
    readonly children: React.ReactNode | (() => React.ReactNode)
  }>()
}
