import * as React from "react";
import { runInAction } from "mobx";
import { useObserver } from "mobx-react";

import { guardTypeExtends } from "@chuyuan/poster-utils";
import { EntityAppearanceBorderRadiusType } from "@chuyuan/poster-data-structure";
import { FourCornersValue } from "@chuyuan/poster-data-access-layer";
import { useLocale, i18n } from "../../utils/i18n";
import { InputNumber } from "../ui-components/input-number";
import { Gap, Block, Row, TitleText } from "../ui-components/section";
import { SingleSlider } from "../ui-components/slider";
import { IconBtn } from "../ui-components/icon-btn";
import { Dialog } from "../ui-components/dialog";
import { AppearanceFields, FOUR_CORNER_KEYS } from "./state.ui";
import { Radio, RadioOption } from "../ui-components/radio";
import { DuplexFieldLike } from "../../utils/multi-value";
import { EditorContext } from "../helpers/react-context";
import { getInputProps } from "../../utils/react-multi-value";
import { LeftRightPanel } from "../gdl-common/popup";
import {
  BorderRadiusBottomLeft,
  BorderRadiusBottomRight,
  BorderRadiusTopLeft,
  BorderRadiusTopRight,
  ChamferingArc,
  ChamferingLine,
  ChamferingReverseArc,
  PropertyDetails,
} from "../../../../poster-ui/icons/new-icons";

export const BorderRadiusBlock = React.memo(
  (props: { readonly fields: AppearanceFields }) => {
    const { fields } = props;

    const [isAdvancedDisplayed, setIsAdvancedDisplayed] = React.useState(false);

    const state = React.useRef<{
      block?: HTMLElement | null;
      btn?: HTMLElement | null;
    }>({}).current;

    return (
      <>
        <Block ref={(ref) => (state.block = ref)}>
          <Row className="margin-y edged">
            <TitleText>
              <圆角 />
            </TitleText>
            <IconBtn
              ref={(ref) => (state.btn = ref)}
              component={PropertyDetails}
              onClick={() => setIsAdvancedDisplayed(true)}
            />
          </Row>
          <Row>
            <UnifiedValue fields={fields} />
          </Row>
        </Block>
        {!(isAdvancedDisplayed && state.block && state.btn) ? null : (
          <LeftRightPanel
            target={state.btn}
            block={state.block}
            onClose={() => setIsAdvancedDisplayed(false)}
            render={({ onClose }) => (
              <Dialog
                title={<高级圆角 />}
                onClose={onClose}
                style={{ width: 240 }}
              >
                <Advanced fields={fields} />
              </Dialog>
            )}
          />
        )}
      </>
    );
  }
);
BorderRadiusBlock.displayName = "BorderRadiusBlock";

const UnifiedValue = React.memo(
  (props: { readonly fields: AppearanceFields }) => {
    const { fields } = props;

    const { session } = React.useContext(EditorContext);
    const locale = useLocale();

    return useObserver(() => {
      const value = fields.borderRadiusUnifiedValue;
      const multiValueProps = getInputProps(value, locale);
      const onChange = (v: number) =>
        runInAction(() => {
          value.set(v);
        });
      const onComplete = () =>
        runInAction(() => {
          session.history.push({ name: "修改圆角大小" });
        });
      return (
        <>
          <SingleSlider
            {...multiValueProps}
            min={0}
            max={fields.borderRadiusUnifiedSliderMaxValue}
            step={1}
            onChange={onChange}
            onComplete={onComplete}
          />
          <Gap />
          <InputNumber
            {...multiValueProps}
            min={0}
            step={1}
            dragDirection="horizontal"
            onChange={onChange}
            onComplete={onComplete}
            style={{ maxWidth: 64 }}
          />
        </>
      );
    });
  }
);
UnifiedValue.displayName = "UnifiedValue";

const Advanced = React.memo((props: { readonly fields: AppearanceFields }) => {
  const { fields } = props;
  return (
    <>
      <AdvancedTypeBlock fields={fields} />
      <AdvancedSizesBlock fields={fields} />
    </>
  );
});
Advanced.displayName = "Advanced";

const AdvancedTypeBlock = React.memo(
  (props: { readonly fields: AppearanceFields }) => {
    const { fields } = props;
    return (
      <Block>
        <Row className="margin-y">
          <TitleText>
            <节点 />
          </TitleText>
        </Row>
        <Row>
          <AdvancedTypeValue fields={fields} />
        </Row>
      </Block>
    );
  }
);
AdvancedTypeBlock.displayName = "AdvancedTypeBlock";

const AdvancedTypeValueOptions: ReadonlyArray<
  RadioOption<EntityAppearanceBorderRadiusType>
> = [
  {
    value: "arc",
    title: () => <ChamferingArc />,
  },
  {
    value: "line",
    title: () => <ChamferingLine />,
  },
  {
    value: "reverse arc",
    title: () => <ChamferingReverseArc />,
  },
];

const AdvancedTypeValue = React.memo(
  (props: { readonly fields: AppearanceFields }) => {
    const { fields } = props;

    const { session } = React.useContext(EditorContext);
    const locale = useLocale();

    return useObserver(() => {
      const value = fields.borderRadiusType;
      const multiValueProps = getInputProps(value, locale);
      return (
        <Radio
          {...multiValueProps}
          onChange={(v) =>
            runInAction(() => {
              value.set(v);
              session.history.push({ name: "修改圆角类型" });
            })
          }
          options={AdvancedTypeValueOptions}
        />
      );
    });
  }
);
AdvancedTypeValue.displayName = "AdvancedTypeValue";

const AdvancedSizesBlock = React.memo(
  (props: { readonly fields: AppearanceFields }) => {
    const { fields } = props;
    return (
      <Block>
        <Row className="margin-y">
          <TitleText>
            <圆角 />
          </TitleText>
        </Row>
        <Row className="margin-y">
          {FOUR_CORNER_KEYS.map((corner) => {
            return (
              <Row key={corner} className="center margin-x">
                <TitleText>{四角图标[corner]()}</TitleText>
              </Row>
            );
          })}
        </Row>
        <Row className="margin-y">
          {FOUR_CORNER_KEYS.map((corner) => {
            return (
              <Row key={corner} className="margin-x">
                <AdvancedSizeValue
                  value={fields.borderRadiusValues[corner]}
                  corner={corner}
                />
              </Row>
            );
          })}
        </Row>
      </Block>
    );
  }
);
AdvancedSizesBlock.displayName = "AdvancedSizesBlock";

const AdvancedSizeValue = React.memo(
  (props: {
    readonly value: DuplexFieldLike<number>;
    readonly corner: keyof FourCornersValue<unknown>;
  }) => {
    const { value, corner: side } = props;

    const { session } = React.useContext(EditorContext);
    const locale = useLocale();

    return useObserver(() => {
      const multiValueProps = getInputProps(value, locale);
      return (
        <InputNumber
          {...multiValueProps}
          onChange={(v) =>
            runInAction(() => {
              value.set(v);
              session.history.push({ name: `修改${四角文案[side]}圆角大小` });
            })
          }
        />
      );
    });
  }
);
AdvancedSizeValue.displayName = "AdvancedSizeValue";

const 四角图标 = guardTypeExtends<FourCornersValue<unknown>>()({
  topLeft: () => <BorderRadiusTopLeft />,
  topRight: () => <BorderRadiusTopRight />,
  bottomRight: () => <BorderRadiusBottomRight />,
  bottomLeft: () => <BorderRadiusBottomLeft />,
});

const 四角文案: FourCornersValue<string> = {
  topLeft: "左上角",
  topRight: "右上角",
  bottomRight: "右下角",
  bottomLeft: "左下角",
};

const 圆角 = i18n({
  en: () => "Border Radius",
  zh: () => "圆角",
});

const 高级圆角 = i18n({
  en: () => "Advanced Border Radius",
  zh: () => "高级圆角",
});

const 节点 = i18n({
  en: () => "Type",
  zh: () => "节点",
});
