import { createModuleToken, implementModule } from '../../utils/modulize'
import { PortalContainer } from '../../utils/react-portal-mobx'

export const EditorSlotModuleToken = createModuleToken<EditorSlotState>('EditorSlotModule')

/**@name 属性面板、导航栏右侧部分模块注册 */
export const EditorSlotModule = implementModule(EditorSlotModuleToken, {
  state: () => new EditorSlotState(),
})

export type EditorSlotItem = {
  /**
   * 优先级, 值越大越靠上
   * - 默认值 0
   */
  readonly priority: number
  /**
   * 渲染函数
   */
  readonly children: React.ReactNode | (() => React.ReactNode)
}

export type EditorPropertySlotItem = EditorSlotItem & ({
  readonly kind?: undefined
  /**
   * 标题
   */
  readonly title?: React.ReactNode | (() => React.ReactNode)
} | {
  readonly kind: 'spread'
})

export class EditorSlotState {
  /**
   * 导航栏右侧部分
   */
  readonly navBarRight = new PortalContainer<EditorSlotItem>()

  /**
   * 画面之上
   */
  readonly aboveGraphic = new PortalContainer<EditorSlotItem>()

  /**
   * 属性面板
   */
  readonly properties = new PortalContainer<EditorPropertySlotItem>()
}
