import { getAllPinyinCombinations } from '../../utils/pinyin'
import { DisplayNameI18N, FontFaceData, FontSetData } from '../editor-state/api'

const FontStyleTypeMap = { normal: 0, italic: 1, oblique: 2, ['italic oblique']: 3 } as const

export interface StdFontSetData extends FontSetData {
  /**
   * 选中用于展示的具体某一个字体
   */
  readonly hero: FontFaceData
}

/**
 * 从字体列表生成标准字体集映射
 * @param list 字体列表
 */
export function getStdFontSet(fontSet: FontSetData): StdFontSetData | undefined {
  const { family } = fontSet
  if (!family.length) return

  if (family.length === 1) {
    return { ...fontSet, hero: family[0] }
  }

  // 按照先 weight 升序, 再 style 的顺序排序
  const sorted = family.slice().sort((a, b) => {
    const w1 = df(a.weight.value, 400)
    const w2 = df(b.weight.value, 400)
    if (w1 < w2) return -1
    if (w1 > w2) return 1
    const s1 = FontStyleTypeMap[df(a.style.value, 'normal')]
    const s2 = FontStyleTypeMap[df(b.style.value, 'normal')]
    if (s1 < s2) return -1
    if (s1 > s2) return 1
    if (a.id < b.id) return -1
    if (a.id > b.id) return 1
    return 0
  })

  // 选出距离 400 最近的 weight 作为字体家族的封面
  let hero = sorted[0]
  let d = Math.abs(df(hero.weight.value, 400) - 400)
  for (let i = 1, len = sorted.length; i < len; i++) {
    const font = sorted[i]
    const md = Math.abs(df(font.weight.value, 400) - 400)
    if (md < d) {
      hero = font
      d = md
    } else if (md === d) {
      // 特别的 prefer Regular to Normal
      const name1 = hero.weight.displayName['en']
      const name2 = font.weight.displayName['en']
      if (name1 && name2) {
        const v1 = name1.toLocaleLowerCase().includes('regular') ? 1 : 0
        const v2 = name2.toLocaleLowerCase().includes('regular') ? 1 : 0
        if (v2 > v1) {
          hero = font
          d = md
        }
      }
    }
  }

  return { ...fontSet, hero }
}

/**
 * 从字体数据读取搜索关键字集合
 * @param font 字体数据
 */
export function getFontFamilySearchKeywords(
  familyName: string,
  displayName: DisplayNameI18N,
) {
  const set = new Set<string>()

  if (familyName) set.add(familyName)

  for (const key of Object.keys(displayName)) {
    const v = displayName[key]
    if (v) set.add(v)
  }

  try {
    const { zh } = displayName
    if (zh) {
      const keywords = getAllPinyinCombinations(zh)
      for (const keyword of keywords) {
        set.add(keyword)
      }
    }
  } catch (e) {
    console.error(e)
  }

  return set
}

function df<T>(v: T | 'unknown', dv: T): T {
  if (v === 'unknown') return dv
  return v
}
