import { observable, action, makeObservable } from 'mobx'
import ReactiveLocalStorage from 'reactive-localstorage'

export interface LocalStorageConfigure {
  readonly parser?: (data: string | null) => unknown
  readonly stringify?: (data: unknown) => string
}

export class LocalStorage implements Omit<Storage, 'getItem'> {
  private _parseValue: (data: string | null) => unknown
  private _stringifyValue: (data: unknown) => string

  private _map = observable.map<string, unknown>([], { deep: false })

  constructor() {
    this._parseValue = parseValue
    this._stringifyValue = x => JSON.stringify(x)
    this._reload()
    ReactiveLocalStorage.on('change', action((key: string, value: string | null) => {
      if (typeof value === 'string') {
        this._map.set(key, this._parseValue(value))
      } else {
        this._map.delete(key)
      }
    }))
    makeObservable(this)
  }

  get length() {
    return this._map.size
  }

  key(index: number) {
    return localStorage.key(index)
  }

  getItem(key: string) {
    const map = this._map
    return map.has(key) ? map.get(key) : null
  }

  setItem(key: string, value: unknown) {
    this.set(key, value)
  }

  removeItem(key: string) {
    this.delete(key)
  }

  @action
  clear() {
    localStorage.clear()
    this._map.clear()
  }

  @action
  set(key: string, value?: unknown) {
    localStorage.setItem(key, this._stringifyValue(value))
    return this
  }

  @action
  delete(key: string) {
    const has = this._map.has(key)
    localStorage.removeItem(key)
    return has
  }

  @action
  configure(config: LocalStorageConfigure) {
    let changed = false
    if (typeof config.parser === 'function') {
      changed = true
      this._parseValue = config.parser
    }
    if (typeof config.stringify === 'function') {
      changed = true
      this._stringifyValue = config.stringify
    }
    if (changed) {
      this._reload()
    }
  }

  private _reload() {
    const len = localStorage.length
    const map = this._map
    for (let i = 0; i < len; i++) {
      const key = localStorage.key(i)!
      const value = this._parseValue(localStorage.getItem(key))
      map.set(key, value)
    }
  }
}

function parseValue(value: string | null) {
  try {
    return value === null ? null : JSON.parse(value)
  } catch (e) {
    // ignore if cannot parse as JSON
  }
  return value
}

export default new LocalStorage()
