import * as React from "react";
import styled from "styled-components";

import { EMPTY_ARRAY, dethunk } from "@chuyuan/poster-utils";
import { Up2 } from "../../../../poster-ui/icons/new-icons";

import { getLocator, Anchor } from "./popup-locator";
import { Popup } from "./popup";
import { CommonProps, mod } from "./common";

export type SelectValueLike = string | number;

export type SelectProps<T extends SelectValueLike> = CommonProps & {
  /**
   * 下拉框的 className
   */
  readonly optionsClassName?: string;
  /**
   * 下拉框的 style
   */
  readonly optionsStyle?: React.CSSProperties;
  /**
   * 当 value 不为 undefined, 但不在 options 里时, 渲染这个标签
   */
  readonly defaultLabel?: React.ReactNode | (() => React.ReactNode);
  readonly placeholder?: React.ReactNode;
  readonly value?: T;
  readonly onChange?: (value: T) => void;
  readonly options?: ReadonlyArray<SelectItemProps<T>>;
};

export type SelectItemProps<T extends SelectValueLike> = {
  readonly key?: string | number;
  readonly hidden?: boolean;
  readonly value: T;
  readonly label?: () => React.ReactNode;
};

export interface SelectRef {
  readonly current: HTMLDivElement | null;
}

export interface SelectComponent {
  <T extends SelectValueLike>(
    props: SelectProps<T> & {
      readonly ref?: React.Ref<SelectRef>;
    }
  ): JSX.Element;
  displayName?: string;
}

export const Select = React.forwardRef(
  <T extends SelectValueLike>(
    props: SelectProps<T>,
    ref: React.ForwardedRef<SelectRef>
  ) => {
    const [visible, setVisible] = React.useState(false);
    const [focused, setFocused] = React.useState(false);
    const [hoverValue, setHoverValue] = React.useState<T>();

    const state = React.useRef<{
      outlineRef?: HTMLDivElement | null;
      inputRef?: HTMLInputElement | null;
    }>({}).current;

    React.useImperativeHandle(ref, () => ({
      get current() {
        return state.outlineRef || null;
      },
    }));

    const { value, disabled, readOnly: inputReadOnly, className } = props;
    const inputOptions = props.options || EMPTY_ARRAY;
    const opt =
      value === undefined
        ? undefined
        : inputOptions.find((x) => x.value === value);
    const label =
      value === undefined
        ? props.placeholder
        : opt
        ? opt.label
          ? opt.label()
          : opt.value
        : dethunk(props.defaultLabel);
    const options: typeof inputOptions = inputOptions.filter((x) => !x.hidden);

    const readOnly = inputReadOnly || options.length < 2;

    const unclickable = disabled || readOnly;

    return (
      <Outline
        ref={(r) => (state.outlineRef = r)}
        // tslint:disable-next-line: max-line-length
        className={`${className ? className : ""} ${
          visible ? "expanded" : ""
        } ${readOnly ? "readonly" : ""} ${disabled ? "disabled" : ""} ${
          focused ? "focused" : ""
        }`}
        style={props.style}
        onClick={
          unclickable
            ? undefined
            : () => {
                setVisible(true);
                setHoverValue(options.length ? options[0].value : undefined);
                state.inputRef?.focus();
              }
        }
      >
        <div className="current-item">
          <input
            ref={(r) => (state.inputRef = r)}
            className="current-item-input"
            type="search"
            role="combobox"
            aria-haspopup="listbox"
            aria-autocomplete="list"
            disabled={disabled}
            readOnly
            unselectable="on"
            aria-expanded="false"
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onKeyDown={(e) => {
              const { key } = e;
              if (visible) {
                if (key === "ArrowUp" || key === "ArrowDown") {
                  if (!options.length) return;
                  const index = options.findIndex(
                    (x) => x.value === hoverValue
                  );
                  if (index >= 0) {
                    const di = key === "ArrowUp" ? -1 : 1;
                    const nextIndex = mod(index + di, options.length);
                    setHoverValue(options[nextIndex].value);
                  } else {
                    setHoverValue(options[0].value);
                  }
                } else if (key === "Enter") {
                  setVisible(false);
                  if (hoverValue === undefined) return;
                  setHoverValue(undefined);
                  if (hoverValue === value) return;
                  if (!options.find((x) => x.value === hoverValue)) return;
                  props.onChange?.(hoverValue);
                } else if (key === "Escape" || key === "Tab") {
                  setVisible(false);
                  setHoverValue(undefined);
                }
              } else {
                if (key === "Escape" || key === "Tab") return;
                setVisible(true);
                setHoverValue(options.length ? options[0].value : undefined);
              }
            }}
          />
          <div className="current-item-text">{label}</div>
          {options.length < 2 ? null : (
            <div className="current-item-icon">
              <Up2
                style={{
                  display: "block",
                  transform: visible ? undefined : "rotate(180deg)",
                }}
              />
            </div>
          )}
        </div>
        {visible && !unclickable
          ? (() => {
              const { outlineRef } = state;
              if (!outlineRef) return null;

              const onClose = () => {
                setVisible(false);
                setHoverValue(undefined);
                state.inputRef?.blur();
              };
              return (
                <Popup
                  onClose={onClose}
                  render={({ container }) => (
                    <SelectChildren
                      className={props.optionsClassName}
                      style={props.optionsStyle}
                      container={container}
                      selectBox={outlineRef}
                      value={value}
                      hoverValue={hoverValue}
                      options={options}
                      onChange={props.onChange}
                      onHover={setHoverValue}
                      onClose={onClose}
                    />
                  )}
                />
              );
            })()
          : null}
      </Outline>
    );
  }
) as SelectComponent;
Select.displayName = "Select";

const DEFAULT_ANCHORS: readonly Anchor[] = [
  { target: "left-bottom", content: "left-top" },
  { target: "left-top", content: "left-bottom" },
  { target: "center", content: "center" },
];

function SelectChildren<T extends SelectValueLike>(props: {
  readonly className?: string;
  readonly style?: React.CSSProperties;
  readonly container: HTMLElement;
  readonly selectBox: HTMLElement;
  readonly value?: T;
  readonly hoverValue?: T;
  readonly options: ReadonlyArray<SelectItemProps<T>>;
  readonly onHover?: (value: T) => void;
  readonly onChange?: (value: T) => void;
  readonly onClose: () => void;
}) {
  const { value, hoverValue, options } = props;

  const [optionsRef, setOptionsRef] = React.useState<HTMLElement | null>(null);

  const selectBoxBounding = props.selectBox.getBoundingClientRect();

  const locator =
    optionsRef &&
    getLocator({
      padding: 4,
      target: selectBoxBounding,
      content: optionsRef.getBoundingClientRect(),
      anchors: DEFAULT_ANCHORS,
    });

  return (
    <Options
      ref={setOptionsRef}
      className={props.className}
      style={{
        ...props.style,
        minWidth: selectBoxBounding.width,
        ...(!locator
          ? {
              visibility: "hidden",
            }
          : {
              left: locator.offset[0],
              top: locator.offset[1],
            }),
      }}
    >
      {options.map((opt) => {
        const myValue = opt.value;
        const key = opt.key ?? myValue;
        const label = opt.label ? opt.label() : myValue;
        const selected = value === myValue;
        const hovered = hoverValue === myValue;
        return (
          <div
            key={key}
            className={`option ${selected ? "selected" : ""} ${
              hovered ? "hovered" : ""
            }`}
            onMouseEnter={() => props.onHover?.(myValue)}
            onClick={(e) => {
              e.stopPropagation();
              props.onClose();
              if (!selected) {
                props.onChange?.(myValue);
              }
            }}
            children={label}
          />
        );
      })}
    </Options>
  );
}

const Outline = styled.div`
  flex: 1 1 0;
  display: flex;
  align-items: center;
  width: 0;
  height: 32px;
  background: #f8f7f6;
  border: 1px solid transparent;
  border-radius: 4px;
  user-select: none;
  transition: all 0.3s;

  .current-item-input {
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    background: 0 0;
    border: none;
    outline: none;
    appearance: none;
    opacity: 0;
  }

  .current-item {
    flex: 1 1 0;
    display: flex;
    align-items: center;
    padding: 0 8px;
    pointer-events: none;
  }

  .current-item-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 0;
    flex: 1 1 0;
    color: #606060;
  }

  .current-item-icon {
    flex: 0 0 auto;
    font-size: 16px;
    color: #202020;
  }

  &.auto {
    &,
    .current-item,
    .current-item-text {
      flex: 0 0 auto;
      width: auto;
    }
    .current-item-icon {
      margin-left: 4px;
    }
  }

  &:not(.readonly, .disabled) {
    cursor: pointer;

    &:hover {
      border-color: #c0c0c0;
    }

    &.focused {
      border-color: #0040f0;
      box-shadow: 0 0 0 2px rgba(0, 64, 240, 0.2);
    }
  }

  &:not(.disabled).readonly {
    .current-item-text {
      opacity: 0.5;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Options = styled.div`
  position: absolute;
  float: left;
  max-height: 40vh;
  padding: 4px 0;
  font-size: 12px;
  background: #fff;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  overflow: auto;
  user-select: none;

  & > .option {
    padding: 0 12px;
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;

    &.hovered {
      background: #f8f7f6;
      font-weight: 600;
      color: #606060;
    }

    &.selected {
      font-weight: 600;
      color: #0040f0;
      background: rgba(0, 64, 240, 0.05);
    }
  }
`;
