import * as React from "react";
import { observable, action, computed, makeObservable } from "mobx";
import { useObserver } from "mobx-react";
import { EMPTY_ARRAY, cached } from "@chuyuan/poster-utils";
import { LeafModelType, Frame, GDLLayoutSemantic } from "@chuyuan/poster-data-access-layer";
import { i18n } from "../../utils/i18n";
import { CommonNodeState, DropSide, CommonRootState } from "./node";
import { useLocalStore } from "../../utils/mobx-react-hooks";
import { useModule } from "../../utils/modulize";
import { createAddContentCommand } from "../gdl-commands";
// import { EditorContext } from "../helpers/react-context";
import { EventSystemModuleToken } from "../event-system";
import {
  GlobalContext,
  // GlobalContextModuleToken,
} from "../ui-components/global-context";
import { SelectableTarget } from "../editor-state/types";
import { SessionState } from "../editor-state/session-state";
import { TextEntityContentData } from "@chuyuan/poster-data-structure";
// import { EditorState } from "../editor-state/editor-state";
import { EventHost } from "../../utils/event-system";

class TreeRootState extends CommonRootState<TreeNodeState> {
  constructor(
    readonly props: {
      readonly session: SessionState;
      readonly rootTarget: Frame;
    }
  ) {
    super();
    makeObservable(this);
  }

  @observable.ref
  dragStartData?: {
    readonly dragged: SelectableTarget;
  } = undefined;

  override setCollapsedBatch(set: ReadonlySet<SelectableTarget>, value: boolean) {
    const { collapsed } = this;
    for (const target of set) {
      collapsed.set(target, value);
    }
  }

  override getExpandedTargets(added: ReadonlySet<SelectableTarget>) {
    const { rootTarget } = this.props;
    const rootState = getContainerState(this)(null, rootTarget);
    const result = new Set<SelectableTarget>();
    aggregateExpandTargets(rootState, added, result);
    return result;
  }
}

function aggregateExpandTargets(
  parent: TreeNodeStateForFrame | TreeNodeStateForLeaf,
  selection: ReadonlySet<SelectableTarget>,
  result: Set<SelectableTarget>
): boolean {
  if (parent instanceof TreeNodeStateForLeaf) {
    const { target } = parent.props;
    if (selection.has(target)) {
      return true;
    }
    return false;
  } else {
    return aggregateExpandTargetsForFrame(parent, selection, result);
  }
}

function aggregateExpandTargetsForFrame(
  parent: TreeNodeStateForFrame,
  selection: ReadonlySet<SelectableTarget>,
  result: Set<SelectableTarget>
): boolean {
  let has = false;
  const { root, target } = parent.props;
  for (const child of parent.children) {
    if (child.kind === "frame") {
      if (selection.has(child)) {
        has = true;
        result.add(child);
      }
      const state = getContainerState(root)(parent, child);
      const ret = aggregateExpandTargetsForFrame(state, selection, result);
      if (ret) has = true;
    } else {
      if (selection.has(child)) {
        has = true;
      }
    }
  }
  if (has) result.add(target);
  return has;
}

export type TreeNodeStateProps<T extends SelectableTarget = SelectableTarget> = {
  readonly parent: TreeNodeStateForFrame | null;
  readonly target: T;
  readonly root: TreeRootState;
};

abstract class TreeNodeState<T extends SelectableTarget = SelectableTarget> extends CommonNodeState {
  constructor(readonly props: TreeNodeStateProps<T>) {
    super();
    makeObservable(this);
  }

  @computed
  get draggable() {
    return !!this.props.parent;
  }

  @computed
  get droppable() {
    const { props } = this;
    const { root } = props;
    const { dragStartData } = root;
    if (!dragStartData || dragStartData.dragged === props.target) {
      return false;
    }
    return this.draggable;
  }

  protected override handleDragStart() {
    const target = this.props.target;
    if (target.kind === "frame" && !target.parent()) return;

    const { root } = this.props;

    root.dragStartData = {
      dragged: target,
    };
  }

  protected override handleDragFinish() {
    this.props.root.dragStartData = undefined;
  }

  @action.bound
  override onDrop(_e: React.DragEvent<HTMLDivElement>, side: DropSide) {
    const { props } = this;
    const { root } = props;
    const { dragStartData } = root;
    if (!dragStartData) return;

    const { dragged } = dragStartData;

    const parent = dragged.parent();
    if (!parent) return;

    const { children } = parent;

    const index = children.indexOf(dragged);
    if (index < 0) return;

    const target = props.target as SelectableTarget;

    let myParent;
    let myChildren;
    let myIndex;
    if (side === "center") {
      if (target.kind !== "frame") return;
      myParent = target;
      myChildren = target.children;
      myIndex = 0;
      side = "before";
    } else {
      myParent = target.parent();
      if (!myParent) return;

      myChildren = myParent.children;

      myIndex = myChildren.indexOf(target);
      if (myIndex < 0) return;
    }

    let targetIndex = side === "before" ? myIndex : myIndex + 1;

    // 保持绝对布局视觉位置不变
    const apply = GDLLayoutSemantic.createAbsoluteFixedMover(dragged);

    // 移动对象
    if (parent === myParent) {
      // 在同一个 parent 下移动
      if (index === myIndex) return;
      if (index < myIndex) {
        // 当原来的 index < drop元素的index 时, splice 会使得 index 位点左移
        targetIndex--;
      }
      const newChildren = children.slice();
      newChildren.splice(index, 1);
      newChildren.splice(targetIndex, 0, dragged);
      parent.setChildren(newChildren);
    } else {
      const prevChildren = children.slice();
      prevChildren.splice(index, 1);
      parent.setChildren(prevChildren);

      const newChildren = myChildren.slice();
      newChildren.splice(targetIndex, 0, dragged);
      myParent.setChildren(newChildren);
    }

    if (parent !== myParent) {
      const containerType = parent.layout.containerType;
      const myContainerType = myParent.layout.containerType;
      if (containerType !== "absolute" && myContainerType === "absolute") {
        // 父元素从flex布局切换到自由布局
        const op = new GDLLayoutSemantic.Operator(dragged);
        op.applyFlexItemToAbsolute(containerType);
      } else if (containerType === "absolute" && myContainerType !== "absolute") {
        // 父元素从自由布局切换到flex布局
        const op = new GDLLayoutSemantic.Operator(dragged);
        op.applyAbsoluteToFlexItem();
      } else if (containerType === "flex list" && myContainerType === "flex pile") {
        // 父元素从flex list切换到flex pile
        const op = new GDLLayoutSemantic.Operator(dragged);
        op.applyFlexListItemToFlexPileItem();
      }
    }

    apply();

    props.root.props.session.history.push({ name: "调整海报树结构" });
  }
}

class TreeNodeStateForFrame extends TreeNodeState<Frame> {
  readonly disabled?: undefined;
  readonly ghost?: undefined;
  readonly dropAreaCenter = true;

  constructor(props: TreeNodeStateProps<Frame>) {
    super(props);
    makeObservable(this);
  }

  get children() {
    return this.props.target.children;
  }

  @computed
  get arrow() {
    const { props } = this;
    const { target } = props;
    const { children } = target;
    if (!children.length) return;

    const { root } = props;
    const { dragStartData } = root;
    if (dragStartData && dragStartData.dragged === target) return "collapsed";

    const collapsed = root.collapsed.get(target);
    return collapsed ? "collapsed" : "expanded";
  }

  @action.bound
  onToggleCollapsed() {
    const { target, root } = this.props;
    const { collapsed } = root;

    const value = collapsed.get(target);

    collapsed.set(target, !value);
  }
}

class TreeNodeStateForLeaf extends TreeNodeState<LeafModelType> {
  readonly arrow?: undefined;
  readonly disabled?: undefined;
  readonly dropAreaCenter?: undefined;
  readonly ghost?: undefined;

  onToggleCollapsed() {}
}

const getContainerState = cached((root: TreeRootState) =>
  cached((parent: TreeNodeStateForFrame | null, target: Frame) => {
    return new TreeNodeStateForFrame({ parent, root, target });
  })
);

const TreeRootStateContext = React.createContext(undefined as unknown as TreeRootState);

// 网格层级面板
export const TreePanel = React.memo(
  (props: { readonly session: SessionState; readonly root: Frame; readonly ctx?: GlobalContext; readonly host?: EventHost }) => {
    const { session, host, ctx } = props;
    const eventSystem = useModule(EventSystemModuleToken);
    const rootTarget = props.root;
    const root = useLocalStore((p) => new TreeRootState(p), {
      session,
      rootTarget,
    });
    root.setEventSystem(eventSystem);
    React.useEffect(() => (root.mount(), () => root.unmount()), EMPTY_ARRAY);
    return (
      <TreeRootStateContext.Provider value={root}>
        <TreeNode host={host} ctx={ctx} session={session} />
      </TreeRootStateContext.Provider>
    );
  }
);
TreePanel.displayName = "TreePanel";

/**@name 添加文字枚举 */
const TextAddEnum: ReadonlyArray<TextEntityContentData> = [
  {
    text: "添加主标题",
    fontSize: 62,
    advancedEffects: { selfLayer: { fill: { fill: "#000" } } },
    backgrounds: {
      // 基础背景
      region: {
        // 区域背景
        shape: {
          type: "rectangle",
          borderColor: "#fff",
          borderWidth: 0,
        },
      },
    },
    styles: { wrap: "any" },
  },
  {
    text: "添加副标题",
    fontSize: 36,
    advancedEffects: { selfLayer: { fill: { fill: "#000" } } },
    backgrounds: {
      // 基础背景
      region: {
        // 区域背景
        shape: {
          type: "rectangle",
          borderColor: "#fff",
          borderWidth: 0,
        },
      },
    },
    styles: { wrap: "any" },
  },
  {
    text: "添加正文",
    fontSize: 24,
    advancedEffects: { selfLayer: { fill: { fill: "#000" } } },
    backgrounds: {
      // 基础背景
      region: {
        // 区域背景
        shape: {
          type: "rectangle",
          borderColor: "#fff",
          borderWidth: 0,
        },
      },
    },
    styles: { wrap: "any" },
  },
  {
    text: "添加免责条款",
    // characterInfo: {
    // 单字样式
    //   sizeMap: {
    //     "0": 2,
    //   },
    //   superscriptMap: {
    //     "1": true,
    //   },
    //   fontFamilyNameMap: {
    //     "1": "WangHanZong ChaoMingTi Fan",
    //   },
    //   fontFamilyMarkingMap: {
    //     "2": "1",
    //   },
    //   characterGapMap: {
    //     "3": 0.25,
    //   },
    //   lineGapMap: {
    //     "4": 0.25,
    //   },
    // },
    fontSize: 22,
    advancedEffects: { selfLayer: { fill: { fill: "#000" } } },
    backgrounds: {
      // 基础背景
      region: {
        // 区域背景
        shape: {
          type: "rectangle",
          borderColor: "#fff",
          borderWidth: 0,
        },
        scaleX: 1,
        scaleY: 1,
      },
    },
    styles: { wrap: "any" },
  },
];

// 树节点
const TreeNode = React.memo((props: { readonly session: SessionState; readonly ctx?: GlobalContext; readonly host?: EventHost }) => {
  const { session, ctx, host } = props;

  // const onAddFrame = () => {
  //   if (!ctx || !host) return;
  //   const command = createAddContentCommand({
  //     ctx,
  //     host,
  //     session,
  //     create: { kind: "frame" },
  //   });

  //   session.ui.setCurrentCommand(command);
  // };

  return useObserver(() => {
    /** @name 添加文字*/
    const onAddContent = (index: number) => {
      if (!ctx || !host) return;
      const command = createAddContentCommand({
        ctx,
        host,
        session,
        create: {
          kind: "text",
          content: TextAddEnum[index],
        },
      });
      console.log("执行 createAddContentCommand");
      session.ui.setCurrentCommand(command);
    };
    return (
      <>
        <div
          className="add-title-item title-h1"
          onClick={() => {
            onAddContent(0);
          }}
        >
          <添加主标题 />
        </div>
        <div
          className="add-title-item title-h2"
          onClick={() => {
            onAddContent(1);
          }}
        >
          <添加副标题 />
        </div>
        <div
          className="add-title-item title-text"
          onClick={() => {
            onAddContent(2);
          }}
        >
          <添加正文 />
        </div>
        <div
          className="add-title-item title-text"
          onClick={() => {
            onAddContent(3);
          }}
        >
          <添加免责条款 />
        </div>
        {/* <div onClick={onAddFrame}>添加一个框（测试）</div> */}
      </>
    );
  });
});
TreeNode.displayName = "TreeNode";

const 添加主标题 = i18n({
  en: () => "Add H1",
  zh: () => "添加主标题",
});

const 添加副标题 = i18n({
  en: () => "Add H2",
  zh: () => "添加副标题",
});

const 添加正文 = i18n({
  en: () => "Add Text",
  zh: () => "添加正文",
});

const 添加免责条款 = i18n({
  en: () => "Add Escape Clause",
  zh: () => "添加免责条款",
});
