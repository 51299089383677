import * as React from "react";
import styled from "styled-components";
import * as pluralize from "pluralize";

import { runSafeSync } from "@chuyuan/poster-utils";

import { i18n, Locale } from "../../utils/i18n";
import { WH } from "./state.layout.child";
import { message } from "../ui-components/message";
import { WH_KEYS } from "../helpers/misc";
import { GlobalContext } from "../ui-components/global-context";
import { getGDLTargetName } from "../gdl-common/target-names";
import { SelectableTarget } from "../editor-state/types";

export type AutoConversionEntry = {
  readonly target: SelectableTarget;
  readonly index: number;
  readonly width: boolean;
  readonly height: boolean;
};

export function callAutoConversionMessage(
  ctx: GlobalContext | undefined,
  groups: ReadonlyArray<ReadonlyArray<AutoConversionEntry>>,
  locale: Locale
) {
  if (!ctx) return;

  const descriptions = [];

  let i = 0;
  for (const group of groups) {
    for (const key of WH_KEYS) {
      const list = group.filter((x) => x[key]);
      if (!list.length) continue;
      const names = list.map((x) => getLayerName(x, locale));
      descriptions.push(
        <div key={i++}>
          <自动转换描述 names={names} wh={key} />
        </div>
      );
    }
  }

  if (!descriptions.length) return;

  return message({
    ctx,
    type: "warning",
    duration: 4500,
    closable: true,
    pauseOnHover: true,
    content: <自动转换 />,
    description: <>{descriptions}</>,
  });
}

export function callErrorMessage(ctx: GlobalContext, e: unknown) {
  let content: React.ReactNode;
  let description: React.ReactNode;
  if (e instanceof Error) {
    content = <Pre>{e.message}</Pre>;
    description = <Pre>{e.stack}</Pre>;
  } else {
    const r = runSafeSync(() => String(e));
    content = r.state === "resolved" ? r.result : "<Unable to stringify error>";
  }
  return message({
    ctx,
    type: "error",
    duration: 0,
    closable: true,
    content,
    description,
  });
}

function getLayerName(entry: AutoConversionEntry, locale: Locale) {
  const { target } = entry;
  const name = target.properties.name || getGDLTargetName(target, locale);
  return `「${name}」`;
}

const 自动转换 = i18n({
  en: () => "Automatic Conversion",
  zh: () => "自动转换",
});

const 自动转换描述 = i18n<{
  readonly names: readonly string[];
  readonly wh: WH;
}>({
  en: ({ names, wh }) => {
    const len = names.length;
    const firstN = names
      .slice(0, 3)
      .map((x) => x || "<Unnamed>")
      .join(", ");
    const rest = len - 3;
    const naming =
      rest <= 0
        ? firstN
        : `${firstN} and other ${rest} ${pluralize("layer", rest)}`;
    return `The ${wh} of ${naming} ${pluralize(
      "is",
      len
    )} converted to "Fixed Size"`;
  },
  zh: ({ names, wh }) => {
    const len = names.length;
    const firstN = names
      .slice(0, 3)
      .map((x) => x || "<未命名>")
      .join("、");
    const rest = len - 3;
    const naming = rest <= 0 ? firstN : `${firstN}，以及其他 ${rest} 个图层`;
    const dim = wh === "width" ? "宽度" : "高度";
    return `${naming} 的${dim}转换为固定尺寸`;
  },
});

const Pre = styled.pre`
  margin: 0;
  padding: 0;
`;
