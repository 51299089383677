import * as React from 'react'

export interface CommonProps {
  readonly disabled?: boolean
  readonly readOnly?: boolean
  readonly className?: string
  readonly style?: React.CSSProperties
}

function nonNaN(x: unknown) {
  if (typeof x === 'number' && !Number.isNaN(x)) return x
  return undefined
}

function formatMin(x: unknown) {
  if (typeof x === 'number' && !Number.isNaN(x)) return x
  return -Infinity
}

function formatMax(x: unknown) {
  if (typeof x === 'number' && !Number.isNaN(x)) return x
  return Infinity
}

export function formatMinMax(min: unknown, max: unknown) {
  const v1 = formatMin(min)
  const v2 = formatMax(max)
  if (v1 > v2) return
  return { min: v1, max: v2 }
}

export function formatValueBetweenMinMax(x: unknown, minmax: { readonly min: number, readonly max: number }) {
  const v = nonNaN(x)
  if (v === undefined) return
  const { min } = minmax
  if (v < min) return min
  const { max } = minmax
  if (v > max) return max
  return v
}

export const scalePercent = createScaledValueProps(100)

export function createScaledValueProps(scale: number) {
  return function scaleValue<TArgs extends ReadonlyArray<unknown>>(
    value: number | undefined,
    onChange: (value: number, ...args: TArgs) => void,
    onComplete?: (value: number, ...args: TArgs) => void,
  ) {
    return {
      value: typeof value === 'number' ? value * scale : value,
      onChange: (v: number, ...args: TArgs) => onChange(v / scale, ...args),
      onComplete: onComplete && ((v: number, ...args: TArgs) => onComplete(v / scale, ...args)),
    }
  }
}

export function mod(a: number, b: number) {
  return ((a % b) + b) % b
}
