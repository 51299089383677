import * as React from "react";
import { useObserver } from "mobx-react";
import { runInAction } from "mobx";

import { useLocale, i18n } from "../../utils/i18n";
import { Row, Gap, Square } from "../ui-components/section";
import {
  getSubFamilySelectorData,
  FontFamilySelectorProvider,
  FontFaceSelectorData,
} from "./font-family-selector";
import { SearchableSelect } from "../ui-components/searchable-select";
import { DuplexFieldLike, MapDuplexField } from "../../utils/multi-value";
import { EditorContext } from "../helpers/react-context";
import { EMPTY_ARRAY } from "@chuyuan/poster-utils";
import { getInputProps } from "../../utils/react-multi-value";
import { FontFaceData } from "../editor-state/api";

export interface FontFamilyBlockProps {
  readonly language: "zh" | "en";
  readonly field: DuplexFieldLike<string>;
}

export const FontFamilyBlock = React.memo((props: FontFamilyBlockProps) => {
  const { field, language } = props;

  const editor = React.useContext(EditorContext);
  const locale = useLocale();

  return useObserver(() => {
    const fontList = editor.resources.fontSetList.get().data || EMPTY_ARRAY;

    const data = React.useMemo(
      () => new FontFaceSelectorData(fontList, language, locale),
      [fontList, language, locale]
    );

    const fontSetField = React.useMemo(() => {
      const { map, mapByFontFaceId } = data;
      return MapDuplexField.same(
        field,
        (fontFaceId) => {
          const fontSet = mapByFontFaceId.get(fontFaceId);
          return fontSet ? fontSet.id : fontFaceId;
        },
        (fontSetId) => {
          const fontSet = map.get(fontSetId);
          return fontSet ? fontSet.hero.id : fontSetId;
        }
      );
    }, [field, data]);

    const fontSetFieldVariety = fontSetField.variety;

    const fontSetFieldValue = fontSetField.get();

    const selectedFontFace = fontSetFieldValue
      ? data.map.get(fontSetFieldValue)
      : undefined;

    const fontSetFieldValueProps = getInputProps(fontSetField, locale);

    // 空字符串需要转为 undefined 才能使用 placeholder
    if (!fontSetFieldValueProps.value) {
      fontSetFieldValueProps.value = undefined;
    }

    return (
      <FontFamilySelectorProvider>
        <Row className="margin-y">
          {/* <Square className="text">{language === 'zh' ? '中' : 'En'}</Square>
          <Gap /> */}
          <SearchableSelect
            {...fontSetFieldValueProps}
            clearable
            defaultLabel={fontSetFieldValue}
            placeholder={
              fontSetFieldValueProps.placeholder ?? 未指定.get(locale)
            }
            options={data.fontFaceOptions}
            onClear={() =>
              runInAction(() => {
                if (!fontSetField.set("").records.some(Boolean)) return;
                editor.session.history.push({
                  name: `清空字样${language === "en" ? "英文" : "中文"}字体`,
                });
              })
            }
            onChange={(fontSetId) =>
              runInAction(() => {
                if (!fontSetField.set(fontSetId).records.some(Boolean)) return;
                editor.session.history.push({
                  name: `修改字样${language === "en" ? "英文" : "中文"}字体`,
                });
              })
            }
          />
        </Row>
        {fontSetFieldVariety === "various" ||
        !(selectedFontFace && selectedFontFace.family.length > 1) ? null : (
          <FontFamilySubFamilyRow
            {...props}
            disabled={fontSetFieldValueProps.disabled}
            list={selectedFontFace.family}
          />
        )}
      </FontFamilySelectorProvider>
    );
  });
});
FontFamilyBlock.displayName = "FontFamilyBlock";

const FontFamilySubFamilyRow = React.memo(
  (
    props: FontFamilyBlockProps & {
      readonly disabled?: boolean;
      readonly list: readonly FontFaceData[];
    }
  ) => {
    const { field, language, list, disabled } = props;

    const editor = React.useContext(EditorContext);
    const locale = useLocale();

    return useObserver(() => {
      const options = React.useMemo(
        () => getSubFamilySelectorData(list, locale),
        [list, locale]
      );

      const multiValueProps = getInputProps(field, locale);
      if (disabled) multiValueProps.disabled = true;

      return (
        <Row className="margin-y">
          <Square />
          <Gap />
          <SearchableSelect
            {...multiValueProps}
            options={options}
            onChange={(newValue) =>
              runInAction(() => {
                if (!field.set(newValue).records.some(Boolean)) return;
                editor.session.history.push({
                  name: `修改字样${language === "en" ? "英文" : "中文"}字体`,
                });
              })
            }
          />
        </Row>
      );
    });
  }
);
FontFamilySubFamilyRow.displayName = "FontFamilySubFamilyRow";

const 未指定 = i18n({
  en: () => "Not specified",
  zh: () => "未指定",
});
