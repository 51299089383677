import { computed } from "mobx";

import type { FourCornersValue } from "@chuyuan/poster-data-access-layer";
import {
  memorize,
  mapIterableAsKeys,
  castReadonly,
  ClassStaticCache,
} from "@chuyuan/poster-utils";

import {
  DisabledDuplexField,
  DuplexField,
  Duplex,
  Reader,
  ReadableSource,
  SpreadKeyDuplex,
} from "../../utils/multi-value";
import {
  BorderRadiusCornerSizeUnifiedField,
  BackgroundFillField,
  OpacityDuplex,
  BorderRadiusCornerSizesDuplex,
  BorderRadiusCornerTypeDuplex,
} from "./state.style";
import { SelectableTarget } from "../editor-state/types";

export class AppearanceFields {
  static of = ClassStaticCache;

  readonly targets: readonly SelectableTarget[];

  constructor(targets: Iterable<SelectableTarget>) {
    this.targets = Array.from(new Set(targets));
  }

  @memorize
  get opacity() {
    const list = [];
    for (const target of this.targets) {
      if (target.kind === "frame" && !target.parent())
        return DisabledDuplexField.instance;
      list.push(target);
    }
    return new DuplexField(new OpacityDuplex(new ReadableSource(list)));
  }

  @computed
  get borderRadiusUnifiedSliderMaxValue() {
    let value = -Infinity;
    for (const target of this.targets) {
      const { width, height } = target.layout.selfBox;
      const selectedValue = Math.min(width, height);
      if (value < selectedValue) value = selectedValue;
    }
    return value > 0 ? value / 2 : 100;
  }

  @memorize
  get borderRadiusUnifiedValue() {
    return BorderRadiusCornerSizeUnifiedField.of(
      new ReadableSource(this.targets)
    );
  }

  @memorize
  get borderRadiusType() {
    return new DuplexField(
      new BorderRadiusCornerTypeDuplex(new ReadableSource(this.targets))
    );
  }

  @memorize
  get borderRadiusValues() {
    const sizesDuplex = BorderRadiusCornerSizesDuplex.of(
      new ReadableSource(this.targets)
    );
    return castReadonly(
      mapIterableAsKeys(FOUR_CORNER_KEYS, (side) => {
        return new DuplexField(
          SpreadKeyDuplex.createIdentical(sizesDuplex, side)
        );
      })
    );
  }

  @memorize
  get backgroundFill() {
    const list = [];
    for (const target of this.targets) {
      if (target.kind === "frame" && !target.parent()) {
        list.push(target);
        continue;
      }
      return {
        paint: DisabledDuplexField.instance.parent,
      } as const;
    }
    const fills = list.map((target) => BackgroundFillField.of(target));
    return {
      paint: new Duplex(fills),
      options: {
        typeCache: new Reader(
          fills.map((x) => {
            const { getFn } = x.cache;
            return { get: () => getFn };
          })
        ),
      } as const,
    } as const;
  }
}

export const FOUR_CORNER_KEYS: ReadonlyArray<keyof FourCornersValue<unknown>> =
  ["topLeft", "topRight", "bottomRight", "bottomLeft"];
