import * as React from 'react'

import { i18n } from '../../utils/i18n'
import { Block, Row, TitleText } from '../ui-components/section'
import type { AppearanceFields } from './state.ui'
import { FillingPaintValue } from '../ui-components-stateful/filling-paint'

export const BackgroundFillBlock = React.memo((props: {
  readonly fields: AppearanceFields
}) => {
  const { fields } = props
  const { paint, options } = fields.backgroundFill
  return (
    <Block>
      <Row className="margin-y">
        <TitleText><背景 /></TitleText>
      </Row>
      <Row>
        <FillingPaintValue field={paint} options={options} />
      </Row>
    </Block>
  )
})
BackgroundFillBlock.displayName = 'BackgroundFillBlock'

const 背景 = i18n({
  en: () => 'Background',
  zh: () => '背景',
})
