import * as React from 'react'
import Icon from '@ant-design/icons'
import styled from 'styled-components'

import type { PropsOf } from '../../utils/react-types'

export type IconBtnProps = Omit<PropsOf<typeof Icon>, 'ref'> & {
  readonly disabled?: boolean | undefined
  readonly className?: string
  readonly onClick?: undefined | React.MouseEventHandler<HTMLButtonElement>
  readonly style?: React.CSSProperties
  readonly children?: JSX.Element
  readonly component?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
}

export const IconBtn = React.forwardRef<HTMLSpanElement, IconBtnProps>((props, ref) => {
  let newProps = props
  if (props.disabled) {
    const { className } = props
    newProps = {
      ...props,
      className: className ? `${className} disabled` : `disabled`,
      onClick: undefined,
    }
  }
  return <IconBtnDOM {...newProps} ref={ref} />
})

const IconBtnDOM = styled(Icon)`
  cursor: pointer;
  &.primary {
    color: #0040f0;
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
` as typeof Icon
