import * as React from 'react'
import styled from 'styled-components'

import {
  UseInputTextProps,
  useInputTextProps,
} from './input-text-hooks'
import type { CommonProps } from './common'

export interface InputTextProps extends
  UseInputTextProps,
  CommonProps,
  Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof UseInputTextProps | keyof CommonProps> {
  readonly placeholder?: string
}

export type InputTextRef = HTMLInputElement

export const InputText = React.forwardRef<InputTextRef, InputTextProps>(
  (props, ref) => {
    const { onChange, onComplete, ...rest } = props
    const inputTextProps = useInputTextProps(props).props

    return (
      <StyledInput
        {...rest}
        {...inputTextProps}
        ref={ref}
      />
    )
  }
)
InputText.displayName = 'InputText'

export type InputTextPlainRef = HTMLInputElement

export const InputTextPlain = React.forwardRef<InputTextPlainRef, InputTextProps>(
  (props, ref) => {
    const { onChange, onComplete, ...rest } = props
    const inputTextProps = useInputTextProps(props).props

    return (
      <input
        {...rest}
        {...inputTextProps}
        ref={ref}
      />
    )
  }
)
InputTextPlain.displayName = 'InputTextPlain'

const StyledInput = styled.input`
  flex: 1 1 0;
  width: 0;
  padding: 7px;
  color: #606060;
  background: #f8f7f6;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid transparent;
  box-sizing: border-box;
  outline: 0;
  transition: all .3s;

  &:hover {
    border-color: #c0c0c0;
  }

  &:focus {
    border-color: #0040f0;
    box-shadow: 0 0 0 2px rgba(0, 64, 240, 0.2);
  }

  &.whole {
    width: 100%;
  }

  &:disabled {
    color: #c0c0c0;
    cursor: not-allowed;
    border-color: transparent;
    box-shadow: none;
  }
`
StyledInput.displayName = 'StyledInput'
