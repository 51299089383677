import Http from './http';
import xieyeziStorage from 'xieyezi-storage';

/**@name 资源中心列表接口 */
interface ISearchFileListType {
  pageNum: number; // 当前页码，必传
  pageSize: number; // 每页大小，必传
  folderids?: string; // 文件夹id（父级id，parentid）集合，多个以英文逗号分隔，必传
  librarys?: string; // 文件库（library）集合，多个以英文逗号分隔，非必传：0-无效数据，1-待分类，2-搜索库
  keyword?: string; // 关键词，支持资源名称和资源标签搜索，非必传
  suffixs?: string; // 文件扩展名集合，多个以英文逗号分隔，非必传
  sortField?: string; // 排序字段，不传默认按srclibid排序，注意；该字段的值请按下划线格式传入，不可用驼峰，正确示例：sortField: create_time，错误示例：sortField: createTime
  direction?: string; // 排序规则，不传默认按DESC降序
  startTime?: string; // 起始时间，非必传
  endTime?: string; // 结束时间，非必传
  srcType?: number; // 来源类型：0-素材，1-插图，2-摄影，非必传
  size?: string; // 尺寸，宽*高，非必传
  srcGroup?: number | undefined; // 来源分组：0-平台，1-团队，不传时为全部，当此参数值为0或空时，不管folderids和librarys传多少都将至为空，表示平台下不分文件夹和文件库
  yingxiaoTarget?: string; // 营销目的，非必传
  fileSize?: string; // 文件大小，0-小样图（2M以内，包含2M），1-标清（2M-10M），2-高清（10M-20M），3-超清（20M以上），非必传
  color?: string; // 主色
  scene?: string; // 场景（已更名为物料类型）
  viewSize?: number;
}

/**
 * 接口服务
 */
class Service {
  /**
   * @description: 资源列表
   * @param {ChuYuan.ISearchFileListType} param
   * @return {*}
   */
  srcLibPage(param: ISearchFileListType): Promise<any> {
    return Http.post('/file/srcManager/list', param)
  }

  /**@name 我的文件夹资源列表 */
  fileList(param: Object) {
    return Http.post('/file/personalFile/fileList', param)
  }

  /**@name 获取用户id */
  getUserId() {
    const userid = xieyeziStorage.get('userid')
    if (!userid) {
      return 10;
      // return eventCenter.emit('login', true);
    }
    return userid
  }
}

export default new Service;
