import { makeObservable, computed, action, IObservableValue } from "mobx";
import { once } from "lodash";
import { EMPTY_ARRAY } from "@chuyuan/poster-utils";
import { createBox } from "./mobx";

export type DefaultValueChainItem<T> = ValueChainBox<T> | ValueChainComputed<T>;

export type ValueChainItem<T> =
  | ValueChainBox<T | Skip>
  | ValueChainComputed<T | Skip>
  | ValueChain<T | Skip>;

export abstract class AbstractValueChain<T> {
  /**
   * @internal
   */
  readonly _chain = createBox<ReadonlyArray<ValueChainItem<T>>>(EMPTY_ARRAY);

  /** */
  @action
  createValue(value: T) {
    const item = new ValueChainBox(value);
    this._chain.set([item, ...this._chain.get()]);
    const dispose = once(() => this._remove(item));
    return { item, dispose };
  }

  @action
  createGetter(getter: () => T) {
    const item = new ValueChainComputed(getter);
    this._chain.set([item, ...this._chain.get()]);
    const dispose = once(() => this._remove(item));
    return { item, dispose };
  }

  @action
  createChain(item: ValueChain<T>) {
    this._chain.set([item, ...this._chain.get()]);
    const dispose = once((): void => this._remove(item));
    return { item, dispose };
  }

  *iterate(): Generator<
    ValueChainBox<T | Skip> | ValueChainComputed<T | Skip>
  > {
    for (const item of this._chain.get()) {
      if (item instanceof AbstractValueChain) {
        yield* item.iterate();
      } else {
        yield item;
      }
    }
  }

  /**
   * @internal
   */
  private _remove(value: ValueChainItem<T>) {
    const list = this._chain.get();
    const pos = list.indexOf(value);
    if (pos < 0) return;
    const newList = list.slice();
    newList.splice(pos, 1);
    this._chain.set(newList);
  }
}

export class ValueChainBox<T> {
  /**
   * @internal
   */
  _value: IObservableValue<T>;

  constructor(value: T) {
    this._value = createBox(value);
  }

  get() {
    return this._value.get();
  }

  set(value: T) {
    this._value.set(value);
    return this;
  }
}

export class ValueChainComputed<T> {
  readonly cpt = computed(() => this.getter());

  constructor(readonly getter: () => T) {}

  get() {
    return this.cpt.get();
  }
}

export const $skip = Symbol("skip");

export type Skip = typeof $skip;

export class ValueChain<T> extends AbstractValueChain<T | Skip> {
  constructor() {
    super();
    makeObservable(this);
  }

  @computed
  get value(): T | Skip {
    for (const item of this._chain.get()) {
      const value = item.get();
      if (value === $skip) continue;
      return value;
    }
    return $skip;
  }

  get(): T | Skip {
    return this.value;
  }
}

export class ValueChainWithDefault<T> extends AbstractValueChain<T | Skip> {
  constructor(readonly defaultValue: DefaultValueChainItem<T>) {
    super();
    makeObservable(this);
  }

  @computed
  get value(): T {
    for (const item of this._chain.get()) {
      const value = item.get();
      if (value === $skip) continue;
      return value;
    }
    return this.defaultValue.get();
  }
}
