import * as React from "react";
import { useObserver } from "mobx-react";
import { runInAction } from "mobx";

import {
  MaybeThunk,
  dethunk,
  EMPTY_ARRAY,
  Mutable,
} from "@chuyuan/poster-utils";

import { useLocale } from "../../../utils/i18n";
import {
  DuplexFieldLike,
  ReadFieldLike,
  WriteLog,
} from "../../../utils/multi-value";
import { getInputProps } from "../../../utils/react-multi-value";
import {
  Select,
  SelectRef,
  SelectProps,
  SelectValueLike,
} from "../../ui-components/select";
import { Radio, RadioRef, RadioProps } from "../../ui-components/radio";
import { EditorContext } from "../../helpers/react-context";
import { SessionState } from "../../editor-state/session-state";

export interface EnumValueCommonProps<T> {
  readonly historyName: MaybeThunk<string>;
  readonly onChange?: (
    value: T
  ) => boolean | ((cb: (changed: boolean) => void) => void);
  readonly filter?: ReadFieldLike<ReadonlySet<T>>;
  readonly options: ReadonlyArray<{
    readonly value: T;
    readonly hidden?: boolean;
  }>;
}

export type SelectValueRef = SelectRef;

export type SelectValueProps<T extends SelectValueLike> = Omit<
  SelectHistoryProps<T>,
  "value"
> &
  Required<Pick<SelectProps<T>, "options">> & {
    readonly field: DuplexFieldLike<T, T, WriteLog<unknown>>;
  };

export interface SelectValueComponent {
  <T extends SelectValueLike>(
    props: SelectValueProps<T> & {
      readonly ref?: React.Ref<SelectValueRef>;
    }
  ): JSX.Element;
  displayName?: string;
}

export const SelectValue = React.memo(
  React.forwardRef(
    <T extends SelectValueLike>(
      props: SelectValueProps<T>,
      ref: React.ForwardedRef<SelectValueRef>
    ) => {
      const { field, onChange, ...rest } = props;

      const locale = useLocale();

      return useObserver(() => {
        const fieldProps = getInputProps(field, locale);
        return (
          <SelectHistory<T>
            {...fieldProps}
            {...rest}
            ref={ref}
            onChange={(newValue) =>
              runInAction(() => {
                if (onChange) return onChange(newValue);
                return field.set(newValue).records.some(Boolean);
              })
            }
          />
        );
      });
    }
  )
) as SelectValueComponent;
SelectValue.displayName = "SelectValue";

export type SelectHistoryRef = SelectRef;

export type SelectHistoryProps<T extends SelectValueLike> = Omit<
  SelectProps<T>,
  "value" | "onChange"
> &
  Required<Pick<SelectProps<T>, "options">> &
  Omit<EnumValueCommonProps<T>, "options">;

export interface SelectHistoryComponent {
  <T extends SelectValueLike>(
    props: SelectHistoryProps<T> & {
      readonly ref?: React.Ref<SelectHistoryRef>;
    }
  ): JSX.Element;
  displayName?: string;
}

export const SelectHistory = React.forwardRef(
  <T extends SelectValueLike>(
    props: SelectHistoryProps<T>,
    ref: React.ForwardedRef<SelectHistoryRef>
  ) => {
    const { historyName, filter, options, ...rest } = props;

    const { session } = React.useContext(EditorContext);

    return useObserver(() => {
      const state = useEnumCommonState(session, props);

      return (
        <Select<T>
          {...rest}
          ref={ref}
          options={state.options}
          onChange={state.onChange}
        />
      );
    });
  }
) as SelectHistoryComponent;
SelectHistory.displayName = "SelectHistory";

export type RadioValueRef = RadioRef;

export type RadioValueProps<T> = Omit<RadioHistoryProps<T>, "value"> &
  Required<Pick<RadioProps<T>, "options">> & {
    readonly field: DuplexFieldLike<T, T, WriteLog<unknown>>;
  };

export interface RadioValueComponent {
  <T>(
    props: RadioValueProps<T> & {
      readonly ref?: React.Ref<RadioValueRef>;
    }
  ): JSX.Element;
  displayName?: string;
}

export const RadioValue = React.memo(
  React.forwardRef(function _RadioValue<T>(
    props: RadioValueProps<T>,
    ref: React.ForwardedRef<RadioValueRef>
  ) {
    const { field, onChange, ...rest } = props;

    const locale = useLocale();

    return useObserver(() => {
      const fieldProps = getInputProps(field, locale);
      return (
        <RadioHistory<T>
          {...fieldProps}
          {...rest}
          ref={ref}
          onChange={(newValue) =>
            runInAction(() => {
              if (onChange) return onChange(newValue);
              return field.set(newValue).records.some(Boolean);
            })
          }
        />
      );
    });
  })
) as RadioValueComponent;
RadioValue.displayName = "RadioValue";

export type RadioHistoryRef = RadioRef;

export type RadioHistoryProps<T> = Omit<RadioProps<T>, "value" | "onChange"> &
  Required<Pick<RadioProps<T>, "options">> &
  Omit<EnumValueCommonProps<T>, "options">;

export interface RadioHistoryComponent {
  <T>(
    props: RadioHistoryProps<T> & {
      readonly ref?: React.Ref<RadioHistoryRef>;
    }
  ): JSX.Element;
  displayName?: string;
}

export const RadioHistory = React.forwardRef(function _RadioHistory<T>(
  props: RadioHistoryProps<T>,
  ref: React.ForwardedRef<RadioHistoryRef>
) {
  const { historyName, filter, options, ...rest } = props;

  const { session } = React.useContext(EditorContext);

  return useObserver(() => {
    const state = useEnumCommonState(session, props);

    return (
      <Radio<T>
        {...rest}
        ref={ref}
        options={state.options}
        onChange={state.onChange}
      />
    );
  });
}) as RadioHistoryComponent;
RadioHistory.displayName = "RadioHistory";

function useEnumCommonState<P extends EnumValueCommonProps<any>>(
  inputSession: SessionState,
  inputProps: P
) {
  type T = P extends EnumValueCommonProps<infer U> ? U : SelectValueLike;
  type State = Mutable<Required<Pick<SelectProps<T>, "onChange">>> &
    Mutable<Required<Pick<P, "options">>> & {
      session: SessionState;
      props: P;
      filter?: ReadonlySet<T> | "all";
    };

  const inputOptions = inputProps.options;

  const stateRef = React.useRef<State>();
  const state: State =
    stateRef.current ||
    (stateRef.current = {
      session: inputSession,
      props: inputProps,
      options: inputOptions,
      filter: "all",
      onChange: (newValue) =>
        runInAction(() => {
          const { props, session } = state;
          const ret = props.onChange?.(newValue);
          if (!ret) return;
          const done = () =>
            runInAction(() => {
              session.history.push({
                name: `修改${dethunk(props.historyName)}`,
              });
            });
          return typeof ret === "function" ? ret(done) : done();
        }),
    });

  const prevOptions = state.props.options;

  state.session = inputSession;

  state.props = inputProps;

  const inputFilter = inputProps.filter;

  const filter = inputFilter ? inputFilter.get() : "all";

  if (inputProps.options !== prevOptions || state.filter !== filter) {
    state.filter = filter;
    if (filter) {
      if (filter === "all") {
        state.options = inputOptions;
      } else {
        state.options = inputOptions.map((x) =>
          filter.has(x.value) ? x : { ...x, hidden: true }
        );
      }
    } else {
      state.options = EMPTY_ARRAY;
    }
  }

  return state;
}
