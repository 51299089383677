import { computed, makeObservable, observable, ObservableMap } from 'mobx'
import { ObservableWeakMap } from './mobx-observable-weak-map'

export class ObservableSignalMap<K extends object, V, R = V> {
  private readonly map = new ObservableWeakMap<K, ObservableMap<string, V>>()

  constructor(private readonly merger: (map: ObservableMap<string, V>) => R) {}

  has(key: K) {
    return this.map.has(key)
  }

  get(key: K) {
    const { map } = this
    const item = map.get(key)
    if (item) return this.merger(item)
    return undefined
  }

  set(key: K, value: V, ns = '') {
    const { map } = this
    let item = map.get(key)
    if (!item) map.set(key, item = observable.map<string, V>([], { deep: false }))
    item.set(ns, value)
    return this
  }

  delete(key: K, ns = '') {
    const { map } = this
    const item = map.get(key)
    if (!item) return this
    item.delete(ns)
    return this
  }

  clear(key: K) {
    const { map } = this
    const item = map.get(key)
    if (item) item.clear()
  }
}

export class SomeBooleanObservableSignalMap<K extends object, V> extends ObservableSignalMap<K, V, boolean> {
  constructor() {
    super(mergeSomeBooleanMap)
  }
}

export class MergeSignal<V, R = V> {
  private readonly map = new ObservableMap<string, V>()

  constructor(private readonly merger: (map: ObservableMap<string, V>) => R) {
    makeObservable(this)
  }

  @computed
  private get value() {
    return this.merger(this.map)
  }

  get() {
    return this.value
  }

  set(key: string, value: V) {
    this.map.set(key, value)
    return this
  }

  delete(key: string) {
    this.map.delete(key)
    return this
  }
}


export function mergeSomeBooleanMap(map: { values(): IterableIterator<unknown> }) {
  for (const value of map.values()) {
    if (value) return true
  }
  return false
}
