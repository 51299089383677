import { FrameChildModelGuardian } from "@chuyuan/poster-data-access-layer";
import { createWeakMapMapper } from "@chuyuan/poster-utils";
import { makeObservable, observable } from "mobx";
import {
  mergeSomeBooleanMap,
  SomeBooleanObservableSignalMap,
} from "../../utils/mobx-observable-signal-map";
import {
  createOverrideValueSorted,
  MergeSignal,
} from "../../utils/mobx-override-values";
import {
  ValueChain,
  ValueChainWithDefault,
  ValueChainBox,
} from "../../utils/value-chain";
import { MouseEventLike } from "../canvas-events";
import { CommandLike } from "../gdl-commands/types";
import { SessionState } from "./session-state";
import { SelectableTarget } from "./types";

export class UIState {
  /**
   * 工作区是否被聚焦
   */
  @observable.ref
  isWorkspaceFocused = false;

  /**
   * 工作区的DOM引用
   */
  @observable.ref
  workspaceRef: HTMLDivElement | null = null;

  /**
   * 主区的DOM引用
   */
  @observable.ref
  mainAreaRef: HTMLDivElement | null = null;

  /**
   * 画板区的DOM引用
   */
  @observable.ref
  canvasRef: HTMLDivElement | null = null;

  /**
   * 手动触发指针移动事件
   */
  @observable.ref
  triggerPointerEvent = new ValueChain<{
    readonly [K in
      | "pointerDown"
      | "pointerMove"
      | "pointerUp"
      | "pointerContextMenu"]: (
      x: number,
      y: number,
      event: MouseEventLike
    ) => void;
  }>();

  /**
   * 对象是否hover, 这是个全局联动对象, 主要用于联动UI显示
   */
  readonly hovering = new SomeBooleanObservableSignalMap<object, boolean>();

  /**
   * 指示系统中涉及到临时选中对象
   */
  readonly temporarySelection = createOverrideValueSorted<
    ReadonlySet<SelectableTarget>
  >()(new Set<SelectableTarget>())();

  /**
   * 指示系统中涉及到「选择」操作的可选择对象
   */
  readonly selectableTypes = createOverrideValueSorted<
    ReadonlySet<SelectableTarget["kind"]>
  >()(FrameChildModelGuardian.keys)();

  /**
   * 画布指针样式
   */
  readonly canvasCursor = createOverrideValueSorted<{
    readonly cursor: string;
    readonly priority: number;
  }>()((x) => x.cursor)((a, b) => b.priority - a.priority);

  /**
   * 文本框是否应该自动聚焦
   */
  readonly isTextAreaAutoFocus = createWeakMapMapper(
    (_key: object) => new ValueChainWithDefault(new ValueChainBox(false))
  );

  /**
   * 节点是否可选中
   */
  readonly isNodeSelectable = createWeakMapMapper((_key: object) =>
    createOverrideValueSorted<boolean>()(false)()
  );

  /**
   * 是否关闭非实时渲染
   */
  readonly isNonRealtimeRenderDisabled = new MergeSignal(mergeSomeBooleanMap);

  /**
   * 当前执行的指令
   * @internal
   */
  @observable.ref
  private _currentCommand?: CommandLike = undefined;

  /**
   * 当前执行的指令
   */
  hasCurrentCommand() {
    return !!this._currentCommand;
  }

  setCurrentCommand(value?: CommandLike) {
    const prev = this._currentCommand;
    if (value && value.isEnded()) {
      value = undefined;
    }
    if (prev === value) return;
    if (prev) prev.abort();
    if (value) {
      value.events.on("end", () => {
        if (this._currentCommand === value) {
          this._currentCommand = undefined;
        }
      });
    }
    this._currentCommand = value;
  }

  constructor(readonly session: SessionState) {
    makeObservable(this);
  }

  /**
   * 获取鼠标焦点在画布坐标系上的坐标
   * - 变换顺序是: 先将画布以视窗为中心缩放(viewportScale), 再平移(viewportX, viewportY)
   * @param x
   * @param y
   */
  getCanvasPointFromViewportPoint(x: number, y: number) {
    const { session } = this;
    const { x: vx, y: vy, scale: vs } = session.viewport;
    const { width: vw, height: vh } = session.editor.viewport;
    const { width: cw, height: ch } = session.root.layout.selfBox;
    // x' = ( x - vw / 2 - vx ) / vs + cw / 2
    // y' = ( x - vh / 2 - vy ) / vs + ch / 2
    return [
      (x - vw / 2 - vx) / vs + cw / 2,
      (y - vh / 2 - vy) / vs + ch / 2,
    ] as const;
  }
}
