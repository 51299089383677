import * as React from "react";
import {
  isReadonlyArray,
  createTupleAliasMap,
  createWeakMapMapper,
  createCompareFn,
} from "@chuyuan/poster-utils";
import { identityTransform, Affine2DArray } from "@chuyuan/poster-math";
import { isEqual } from "lodash";

import {
  EventQueueItem,
  EventQueue,
  EventQueueItemPointer,
} from "./event-queue";

import { EventHandlerMap, Events } from "./events";
import { Bounding, Polygon } from "./polygon";
import { SelectableTarget } from "../editor-state/types";
import { EditorContext } from "../helpers/react-context";

export interface CanvasEventProps<T = unknown>
  extends Omit<
    EventQueueItem<EventItemSortData, FormatCanvasEventData<T>>,
    "pointer"
  > {
  readonly collision?: CanvasEventCollisionProps<T>;
}

export interface CanvasEventCollisionProps<T = unknown> {
  readonly bounding: CanvasEventBounding | CanvasEventVertices;
  readonly transform?: Affine2DArray;
  readonly handlers: CanvasEventHandlerMap<T>;
}

export interface CanvasEventHandlerMap<T = unknown>
  extends Partial<EventHandlerMap<CanvasEventItem<T>>> {}

export type CanvasEventCommandHandlerMap = {
  readonly [command in string]?: () => unknown;
};

export interface CanvasEventBounding {
  readonly left: number;
  readonly top: number;
  readonly right: number;
  readonly bottom: number;
}

export type CanvasEventVertices = ReadonlyArray<readonly [number, number]>;

export const CanvasEvent = React.memo(
  _CanvasEvent,
  isEqual
) as typeof _CanvasEvent;

function _CanvasEvent<T = CanvasEventData>(props: CanvasEventProps<T>) {
  type D = FormatCanvasEventData<T>;

  const event = React.useContext(EditorContext).session.canvasEvents;

  const prevPropsRef = React.useRef<CanvasEventProps<T> | undefined>(undefined);
  const prevEventRef = React.useRef<CanvasEventHost | undefined>(undefined);

  const useEvent = React.useMemo(
    () => createWeakMapMapper((e: CanvasEventHost) => e.use<T>()),
    []
  );

  React.useEffect(
    () => () => {
      if (prevEventRef.current) {
        useEvent(prevEventRef.current)();
      }
    },
    [useEvent]
  );

  const prevProps = prevPropsRef.current;
  let prevEvent = prevEventRef.current;

  if (!prevEvent || prevEvent !== event) {
    if (prevEvent) {
      useEvent(prevEvent)();
    }
    prevEvent = prevEventRef.current = event;
  }

  if (!prevProps || prevProps !== props) {
    useEvent(prevEvent)();
    prevPropsRef.current = props;
  }

  register();

  function register() {
    const { collision } = props;
    let pointer: EventQueueItemPointer<EventItemSortData, D> | undefined;
    if (collision) {
      const { bounding } = collision;
      const transform = collision.transform || identityTransform;
      pointer = {
        container: isReadonlyArray(bounding)
          ? new Polygon(bounding, transform)
          : new Bounding(bounding, transform),
        handlers: collision.handlers,
      };
    }
    const newItem: EventQueueItem<EventItemSortData, D> = {
      ...props,
      pointer,
    };
    useEvent(event)(newItem);
  }

  return null;
}

export type CanvasEventData =
  | string
  | CanvasEventCursorData
  | CanvasEventTargetData;

export type FormatCanvasEventData<T> = T | readonly T[];

export interface CanvasEventCursorData {
  readonly type: "cursor";
  readonly cursor: string;
}

export interface CanvasEventTargetData {
  readonly type: "target";
  readonly target: SelectableTarget;
}

export function* iterateElementData<T = unknown>(
  data?: T
): IterableIterator<T> {
  if (!data) return;
  if (isReadonlyArray(data)) {
    for (const item of data) {
      yield item;
    }
  } else {
    yield data;
  }
}

export function isCanvasEventCursorData(
  x?: unknown
): x is CanvasEventCursorData {
  if (typeof x === "object" && x) {
    const y: { readonly type?: unknown; readonly cursor?: unknown } = x;
    return y.type === "cursor" && typeof y.cursor === "string";
  }
  return false;
}

export function isCanvasEventTargetData(
  x?: unknown
): x is CanvasEventTargetData {
  if (typeof x === "object" && x) {
    const y: { readonly type?: unknown } = x;
    return y.type === "target";
  }
  return false;
}

export interface CanvasEvents extends Events<EventItemSortData> {}

export interface CanvasEventItem<T = unknown>
  extends EventQueueItem<EventItemSortData, FormatCanvasEventData<T>> {}

export class CanvasEventHost {
  readonly events = new Events<EventItemSortData>();

  readonly queue = new EventQueue(createEventItemSortDataCompareFn("element"));

  use<T>() {
    const use = this.queue.use<FormatCanvasEventData<T>>();
    return (item?: CanvasEventItem<T>) => {
      use(item);
    };
  }
}

/**
 * UI事件排序数据
 */
export interface EventItemSortData {
  /**
   * 节点类型
   * - grid 网格
   * - element 元素
   * - global 全局
   */
  readonly nodeType: "grid" | "element" | "global" | "cover";
  /**
   * 事件类型
   */
  readonly eventType: "shortcut" | "pointer";
  /**
   * 优先级, 值越大越在表面
   */
  readonly priority: number;
  /**
   * 图层深度, 为图层设计专用, 越大越在表面
   */
  readonly layerDepth?: number;
  /**
   * 节点索引
   * - 值越大越在表层
   */
  readonly indices: readonly number[];
}

/**
 * UI事件对象排序函数
 * - 排序从左到右为优先级降序
 */
function createEventItemSortDataCompareFn(type: "grid" | "element") {
  const eventTypeMap = createTupleAliasMap(["pointer", "shortcut"] as const);
  const nodeTypeMapOf = {
    pointer: createTupleAliasMap(
      type === "grid"
        ? (["global", "cover", "grid", "element"] as const)
        : (["global", "cover", "element", "grid"] as const)
    ),
    shortcut: createTupleAliasMap(
      type === "grid"
        ? (["cover", "grid", "element", "global"] as const)
        : (["cover", "element", "grid", "global"] as const)
    ),
  };
  return createCompareFn<EventItemSortData>([
    (a, b) => eventTypeMap[a.eventType] - eventTypeMap[b.eventType],
    (a, b) => {
      const nodeTypeMap = nodeTypeMapOf[a.eventType];
      return nodeTypeMap[a.nodeType] - nodeTypeMap[b.nodeType];
    },
    (a, b) => (b.layerDepth || 0) - (a.layerDepth || 0),
    (a, b) => b.priority - a.priority,
    (a, b) => compareIndices(a.indices, b.indices),
  ]);
}

/**
 * 节点索引比较函数
 * - 表层返回 -1
 */
function compareIndices(a: readonly number[], b: readonly number[]) {
  // 长度长的在表层
  if (a.length < b.length) return 1;
  if (a.length > b.length) return -1;

  const { length } = a;
  for (let i = 0; i < length; i++) {
    const x = a[i];
    const y = b[i];
    if (x < y) return 1;
    if (x > y) return -1;
  }

  return 0;
}
