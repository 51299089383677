import * as React from 'react'
import styled from 'styled-components'

export const TitleText = styled.div`
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #a0a0a0;
  user-select: none;
`
TitleText.displayName = 'TitleText'

export const Block = styled.div`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  &.row {
    flex: 0 0 auto;
    display: flex;
  }
`
Block.displayName = 'Block'

export const Row = styled.div`
  flex: 1 1 0;
  display: flex;
  align-items: center;

  &.margin-x {
    margin-right: 8px;
  }

  &.margin-y {
    margin-bottom: 8px;
  }

  &:last-child {
    margin-right: 0px;
    margin-bottom: 0px;
  }

  &.pointer {
    cursor: pointer;
  }

  &.auto {
    flex: 0 0 auto;
  }

  &.square {
    width: 32px;
  }

  &.edged {
    justify-content: space-between;
  }

  &.gap-25{
    padding: 8px 12px;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.no-select {
    user-select: none;
  }

  &.column {
    flex-direction: column;
  }
`
Row.displayName = 'Row'

export const Flex110 = styled.div`
  flex: 1 1 0;
`
Flex110.displayName = 'Flex110'

export const Flex11auto = styled.div`
  flex: 1 1 auto;
  overflow: auto;
`
Flex11auto.displayName = 'Flex11auto'

export const InlineLabel = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  color: #c0c0c0;

  &.margin-right {
    margin-right: 8px;
  }
`
InlineLabel.displayName = 'InlineLabel'

export const Square = styled.div`
  flex: 0 0 auto;
  width: 32px;
  height: 32px;
  font-size: 16px;
  display: flex;
  user-select: none;
  color: #c0c0c0;
  justify-content: center;
  align-items: center;

  &.text {
    font-size: 12px;
    font-weight: 600;
  }
`
Square.displayName = 'Square'

export type GapsProps = {
  readonly type?: 'item' | 'block'
}

export function Gap(props: GapsProps) {
  const type = props.type || 'item'
  return <GapDOM className={type} />
}

type GapInputElement = JSX.Element | boolean | null | undefined

export function insertGaps(
  nodes: readonly GapInputElement[],
  cb: (index: number, count: number) => GapInputElement,
) {
  const result: JSX.Element[] = []
  if (!nodes.length) return result
  let i = -1
  let count = -1
  for (const node of nodes) {
    i++
    if (!node || typeof node !== 'object') continue
    count++
    if (count) {
      const ret = cb(i, count)
      if (ret && typeof ret === 'object') {
        result.push(<React.Fragment key={`$gap ${i}`}>{ret}</React.Fragment>)
      }
    }
    result.push(<React.Fragment key={`$content ${i}`}>{node}</React.Fragment>)
  }
  return result
}

const GapDOM = styled.div`
  flex: 0 0 auto;
  width: 0;
  height: 0;

  &.item {
    width: 8px;
    height: 8px;
  }

  &.block {
    width: 16px;
    height: 16px;
  }
`
