// tslint:disable max-line-length

import * as React from 'react'
import { computed, makeObservable, observable, runInAction } from 'mobx'
import { useObserver } from 'mobx-react'

import {
  formatSupportedTypes,
  getCssBackground,
  CommonFillingPaintValueProps,
  CommonFillingPaintStateProps,
  FillingPaintValueLong,
  FillingPaintValueCircle,
  CommonState,
  PureColorPickerContent,
  LinearGradientPickerContent,
  RadialGradientPickerContent,
  ImageContentValue,
  ImageScaleField,
  OpacityFieldLong,
  PickerContent,
} from '../filling-paint-common'
import type { FillingPaint, FillingPaintImageFit, FillingPaintType } from './types'
import { useLocalStore } from '../../../utils/mobx-react-hooks'
import { InputNumber, InputNumberRef } from '../../ui-components/input-number'
import { Fields, FieldsOptions } from './state'
import { i18n, useLocale } from '../../../utils/i18n'
import { DuplexFieldLike, ScaleValueField } from '../../../utils/multi-value'
import { getInputProps } from '../../../utils/react-multi-value'
import { Row, Gap } from '../../ui-components/section'
import { Select, SelectRef, SelectItemProps } from '../../ui-components/select'
import { SingleSlider } from '../../ui-components/slider'
import { useShallowEqualCache } from '../../../utils/react-hooks'
import { EditorContext } from '../../helpers/react-context'

export type FillingPaintPreset = (
  | 'circle'
  | 'long'
)

export interface FillingPaintValueProps extends CommonFillingPaintValueProps<FillingPaint> {
  readonly options?: FieldsOptions
}

export interface FillingPaintStateProps extends CommonFillingPaintStateProps<FillingPaint> {
  readonly options?: FieldsOptions
}

export class FillingPaintState implements CommonState {
  block?: HTMLDivElement | null
  outline?: HTMLDivElement | null
  color?: HTMLInputElement | null
  opacity?: InputNumberRef | null

  constructor(readonly props: FillingPaintStateProps) {
    makeObservable(this)
  }

  @observable.ref
  isPickerVisible = false

  @computed
  get supportedTypes() {
    return formatSupportedTypes.call(this)
  }

  @computed
  get fields() {
    const { field, options } = this.props
    return new Fields(field, options)
  }

  @computed
  get cssBackground() {
    return getCssBackground(this.fields)
  }

  renderPickerContent = (type?: FillingPaintType | 'empty') => {
    return (
      type === 'color' ? <PureColorPickerContent state={this} /> :
      type === 'linear gradient' ? <LinearGradientPickerContent disabledInterpolation state={this} /> :
      type === 'radial gradient' ? <RadialGradientPickerContent disabledInterpolation state={this} /> :
      type === 'image' ? <ImagePickerContent state={this} /> :
      null
    )
  }
}

export const FillingPaintValue = React.memo(React.forwardRef<FillingPaintState, FillingPaintValueProps>((props, ref) => {
  const preset = props.preset || 'long'

  const supportedTypes = useShallowEqualCache(props.supportedTypes)

  const state = useLocalStore(p => new FillingPaintState(p), { ...props, preset, supportedTypes })

  React.useImperativeHandle(ref, () => state)

  if (preset === 'circle') {
    return <FillingPaintValueCircle state={state} />
  }

  return <FillingPaintValueLong state={state} />
}))
FillingPaintValue.displayName = 'FillingPaintValue'

const ImagePickerContent = React.memo((props: {
  readonly state: FillingPaintState
}) => {
  const { state } = props

  return useObserver(() => {
    const { images } = state.fields
    return (
      <PickerContent>
        <Row className="margin-y">
          <ImageContentValue field={images.image} />
        </Row>
        <Row className="margin-y">
          <ImageFitValue field={images.fit} />
        </Row>
        <ImageScaleField field={images.scale} />
        {state.props.preset !== 'circle' ? null : (
          <OpacityFieldLong field={images.opacity} />
        )}
      </PickerContent>
    )
  })
})
ImagePickerContent.displayName = 'ImagePickerContent'

const AllImageFitTypeOptions: ReadonlyArray<SelectItemProps<FillingPaintImageFit>> = [
  {
    value: 'raw',
    label: () => <原图 />,
  },
  {
    value: 'fill',
    label: () => <拉伸 />,
  },
  {
    value: 'tile',
    label: () => <平铺 />,
  },
]

const ImageFitValue = React.memo(React.forwardRef<SelectRef, {
  readonly field: DuplexFieldLike<FillingPaintImageFit>
}>(
  (props, ref) => {
    const { field } = props

    const { session } = React.useContext(EditorContext)
    const locale = useLocale()

    return useObserver(() => {
      const fieldProps = getInputProps(field, locale)
      return (
        <Select<FillingPaintImageFit>
          {...fieldProps}
          ref={ref}
          options={AllImageFitTypeOptions}
          onChange={newValue => runInAction(() => {
            if (!field.set(newValue).records.some(Boolean)) return
            session.history.push({ name: '修改图片填充方式' })
          })}
        />
      )
    })
  }
))
ImageFitValue.displayName = 'ImageFitValue'

const ImageScaleValue = React.memo((props: {
  readonly field: DuplexFieldLike<number>
}) => {

  const { field: inputField } = props

  const { session } = React.useContext(EditorContext)
  const locale = useLocale()

  const field = React.useMemo(() => new ScaleValueField(inputField, 100), [inputField])

  return useObserver(() => {
    const fieldProps = getInputProps(field, locale)
    const onChange = (newValue: number) => runInAction(() => field.set(newValue))
    const onComplete = () => runInAction(() => {
      session.history.push({ name: '修改图片填充缩放' })
    })
    return (
      <>
        <SingleSlider
          {...fieldProps}
          min={0}
          max={200}
          step={1}
          onChange={onChange}
          onComplete={onComplete}
        />
        <Gap />
        <InputNumber
          {...fieldProps}
          min={0}
          max={1000}
          step={1}
          suffix="%"
          dragDirection="horizontal"
          onChange={onChange}
          onComplete={onComplete}
          style={{ maxWidth: 64 }}
        />
      </>
    )
  })
})
ImageScaleValue.displayName = 'ImageScaleValue'

const 原图 = i18n({
  en: () => 'Original Image',
  zh: () => '原图',
})

const 拉伸 = i18n({
  en: () => 'fill',
  zh: () => '拉伸',
})

const 平铺 = i18n({
  en: () => 'tile',
  zh: () => '平铺',
})
