import * as React from "react";
import styled from "styled-components";

import { isEqual, EMPTY_ARRAY } from "@chuyuan/poster-utils";
import type { CommonProps } from "./common";

export type InputNumberArrayRef = HTMLDivElement;

export interface InputNumberArrayProps extends CommonProps {
  readonly value?: readonly number[];
  readonly prefix?: React.ReactNode;
  readonly suffix?: React.ReactNode;
  readonly placeholder?: string;
  readonly onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  readonly onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  readonly onBlur?: (
    event: React.FocusEvent<HTMLInputElement> | FocusEvent
  ) => void;
  readonly onChange?: (value: readonly number[]) => void;
  readonly onComplete?: (value: readonly number[]) => void;
}

export const InputNumberArray = React.forwardRef<
  InputNumberArrayRef,
  InputNumberArrayProps
>((props, forwardedRef) => {
  const { prefix, suffix, disabled, readOnly, placeholder } = props;

  type State = {
    changed?: boolean;
    // 解释一下这个字段.
    // input的blur事件触发的顺序是: mouse down -> blur
    // 然后如果在 mouse down 事件里触发了一些动作, 导致传入的 onChange, onComplete, onBlur 等, 变成了新的语义
    // 然后在 blur 事件中, 原本预期是触发旧语义的动作, 错误地触发了新语义.
    // 所以这个值代表了正确的语义 props (主要是关于回调函数)
    focusProps?: InputNumberArrayProps;
    isFocused?: HTMLInputElement;
    readonly effect: () => () => void;
  };

  const stateRef = React.useRef<State>();
  const state: State =
    stateRef.current ||
    (stateRef.current = {
      effect: () => () => {
        const ref = state.isFocused;
        if (ref) {
          const onBlur = state.focusProps?.onBlur;
          if (onBlur) {
            const cb = (e: FocusEvent) => {
              ref.removeEventListener("blur", cb);
              onBlur(e);
            };
            ref.addEventListener("blur", cb);
            ref.blur();
          }
        }
      },
    });

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const [isFocused, setIsFocused] = React.useState(false);

  const inputPropsValue = props.value || EMPTY_ARRAY;

  const propsDraft = {
    input: inputPropsValue.map((x) => +x.toFixed(2)).join(", ") || "",
    array: inputPropsValue,
    valid: inputPropsValue.every((x) => Number.isFinite(x)),
  } as const;

  const [draft, setDraft] = React.useState(propsDraft);

  if (!isFocused) {
    // 非 focus 的时候总是更新语义 props
    state.focusProps = props;

    // 当非 focus 时, 接受外部传值
    if (!isEqual(propsDraft.array, draft.array)) {
      setDraft(propsDraft);
    }
  }

  return (
    <Outline
      ref={forwardedRef}
      className={`${props.className} ${isFocused ? "focused" : ""} ${
        disabled ? "disabled" : ""
      }`}
      style={props.style}
    >
      {!prefix ? null : (
        <Title
          className="prefix"
          onClick={() => inputRef.current?.focus()}
          style={disabled ? { cursor: "not-allowed" } : undefined}
        >
          {prefix}
        </Title>
      )}
      <Input
        ref={(ref) => (inputRef.current = ref)}
        disabled={disabled}
        readOnly={readOnly}
        value={draft.input}
        placeholder={placeholder}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            // tslint:disable-next-line: no-collapsible-if
            if (draft.valid) {
              // tslint:disable-next-line: no-collapsible-if
              if (!isEqual(propsDraft.array, draft.array)) {
                state.changed = true;
                props.onChange?.(draft.array);
              }
            }
          }
          props.onKeyPress?.(e);
        }}
        onFocus={(e) => {
          state.isFocused = e.currentTarget;
          state.changed = false;
          setIsFocused(true);
          props.onFocus?.(e);
        }}
        onChange={(e) => {
          const input = e.target.value;
          const array: readonly number[] = input
            ? input.split(REGEXP_SPLIT).map(Number)
            : EMPTY_ARRAY;
          const valid = array.every((x) => Number.isFinite(x));
          setDraft({ input, array, valid });
        }}
        onBlur={(e) => {
          state.isFocused = undefined;
          if (draft.valid) {
            setDraft({
              ...draft,
              input: draft.array.map((x) => +x.toFixed(2)).join(", "),
            });
            if (!isEqual(propsDraft.array, draft.array)) {
              if (!state.changed) {
                props.onChange?.(draft.array);
              }
              props.onComplete?.(draft.array);
            }
          } else {
            setDraft(propsDraft);
          }
          setIsFocused(false);
          state.changed = false;
          props.onBlur?.(e);
        }}
      />
      {!suffix ? null : (
        <Title
          className="suffix"
          style={disabled ? { cursor: "not-allowed" } : undefined}
        >
          {suffix}
        </Title>
      )}
    </Outline>
  );
});
InputNumberArray.displayName = "InputNumberArray";

const REGEXP_SPLIT = /[^.0-9eE-]+/;

const Outline = styled.div`
  flex: 1 1 0;
  display: flex;
  align-items: center;
  width: 0;
  height: 32px;
  background: #f8f7f6;
  border-radius: 4px;
  border: 1px solid transparent;
  box-sizing: border-box;
  transition: all 0.3s;

  &:hover {
    border-color: #c0c0c0;
  }

  &.focused {
    border-color: #0040f0;
    box-shadow: 0 0 0 2px rgba(0, 64, 240, 0.2);
  }

  &.disabled {
    cursor: not-allowed;
    border-color: transparent;
    box-shadow: none;
  }
`;

const Title = styled.div`
  flex: 0 0 auto;
  display: flex;
  height: 100%;
  align-items: center;
  user-select: none;
  color: #c0c0c0;

  &.prefix {
    padding-left: 8px;
  }
  &.suffix {
    padding-right: 8px;
  }
`;

const Input = styled.input`
  display: block;
  flex: 1 1 0;
  padding: 0 8px;
  font-size: 12px;
  line-height: 16px;
  height: 100%;
  border: 0;
  background: transparent;
  color: #606060;

  &:focus {
    outline: 0;
  }

  &:disabled {
    color: #c0c0c0;
    cursor: not-allowed;

    ::placeholder {
      color: #c0c0c0;
    }
  }
`;
