import * as React from "react";
import { runInAction } from "mobx";
import { Text, Frame, Image } from "@chuyuan/poster-data-access-layer";
import { createExternalPromise, Disposers, EventEmitter, runSafeSync } from "@chuyuan/poster-utils";

import type { CommandLike, CommandEventMapLike } from "./types";
import type { EventHost } from "../../utils/event-system";
import { i18n } from "../../utils/i18n";
import { message } from "../ui-components/message";
import { SessionState } from "../editor-state/session-state";
import { AddTargetInput, getDefaultEntityLayoutData, getImageLayoutData, getTextDefaultEntityLayoutData } from "../gdl-common/add-content";
import { SelectableTarget } from "../editor-state/types";
import { GlobalContext } from "../ui-components/global-context";
import { ImageSelectorData } from "../editor-state/api";
import { SelectGDLObject } from "../event-system";

export interface AddContentCommand extends CommandLike {
  readonly name: "add content";
}

export function createAddContentCommand({
  ctx,
  host,
  session,
  create,
  isGroup,
}: {
  readonly ctx: GlobalContext;
  readonly host: EventHost;
  readonly session: SessionState;
  readonly create: {
    readonly kind: SelectableTarget["kind"];
    readonly content?: object;
  };
  readonly isGroup?: boolean;
}): AddContentCommand | undefined {
  const events = new EventEmitter<CommandEventMapLike>();

  let ended = false;

  const end = (success: boolean) => {
    if (ended) return;
    ended = true;
    events.emit("end", success);
  };

  const add = async (inputs: readonly AddTargetInput[]) => {
    let success = true;
    // console.log('添加一个组件');
    try {
      const { kind, content } = create;
      if (kind === "text") {
        const layers = [];
        for (const { parent } of inputs) {
          const layer = new Text(session.ds, {
            content: content
              ? content
              : {
                  text: "Text",
                  fontSize: null,
                  advancedEffects: { selfLayer: { fill: { fill: "#000" } } },
                },
            layout: getTextDefaultEntityLayoutData(parent.layout.contentBox),
          });
          parent.setChildren([layer, ...parent.children]);
          layers.push(layer);

          // 添加文字后第一次会自动focus
          const { isTextAreaAutoFocus } = session.ui;
          const { dispose } = isTextAreaAutoFocus(layer).createValue(true);
          Promise.resolve().then(dispose);
        }
        session.selection.replace(...layers);

        // console.log("add-text", {
        //   content,
        //   kind,
        //   selection: session.selection,
        // });
        session.history.push({ name: "增加文本内容" });
      } else if (kind === "image") {
        const { api } = session.editor;
        if (api.callImageSelector) {
          const xp = createExternalPromise<ImageSelectorData | undefined>();
          api.callImageSelector({
            events: new EventEmitter(),
            onData: (data) => {
              xp.resolve(data);
            },
            onClose: () => {
              xp.resolve(undefined);
            },
          });
          const data = await xp.promise;
          if (data) {
            const layers = [];
            for (const { parent } of inputs) {
              const layer = new Image(session.ds, {
                content: {
                  url: data.url,
                },
                layout: getImageLayoutData(parent.layout.contentBox, data.dimension),
              });
              // console.log('getImageLayoutData(parent.layout.contentBox, data.dimension)', getImageLayoutData(parent.layout.contentBox, data.dimension));
              parent.setChildren([layer, ...parent.children]);
              layers.push(layer);
            }
            session.ds.imageResources.resolve(data.url, data);
            session.selection.replace(...layers);
            session.history.push({ name: "增加图片内容" });
          }
        } else if (ctx) {
          message.error(ctx, <未实现图片选择器接口 />);
        }
      } else if (kind === "frame") {
        const layers = [];
        for (const { parent } of inputs) {
          const layer = new Frame(session.ds, {
            layout: getDefaultEntityLayoutData(parent.layout.contentBox),
          });
          parent.setChildren([layer, ...parent.children]);
          layers.push(layer);
        }
        session.selection.replace(...layers);
        session.history.push({ name: "增加画框" });
      }
    } catch (e) {
      success = false;
      console.error(e);
      message.error(ctx, String(e));
    } finally {
      end(success);
    }
  };

  const { selection } = session;
  const selectedNodes = Array.from(selection);
  const nodes = selectedNodes.length ? selectedNodes : [session.root];

  const getAddTargetsMap = () => {
    // 如果有选中的容器, 直接从选中的容器里面创建
    // 1. 未选中的时候默认选中画布
    // 2. 同一个children只能有一个创建位点
    // 3. 位点取选中元素index的最小值, 如果选中了容器, 则为0

    const addTargetsMap = new Map<Frame, number>();
    for (const node of nodes) {
      const parent = node.parent();
      if (node.kind === "frame") {
        if (isGroup) {
          // 如果是编组模式，判断是否为根节点
          if (!parent) {
            // 如果是根节点，那么在根节点下添加子节点
            addTargetsMap.set(node, 0);
          } else {
            // 如果不是根节点，那么在子节点平级添加父节点
            const currIndex = addTargetsMap.get(parent);
            const myIndex = parent.children.indexOf(node);
            // console.log("查询", { currIndex, myIndex });
            if (myIndex >= 0) {
              if (typeof currIndex === "number") {
                // if (myIndex < currIndex) {
                //   addTargetsMap.set(parent, myIndex);
                // }
              } else {
                addTargetsMap.set(parent, myIndex);
              }
            } else {
              // addTargetsMap.set(parent, 0);
            }
          }
        } else {
          addTargetsMap.set(node, 0);
        }
      } else {
        if (parent) {
          const currIndex = addTargetsMap.get(parent);
          const myIndex = parent.children.indexOf(node);
          if (myIndex >= 0) {
            if (typeof currIndex === "number") {
              if (myIndex < currIndex) {
                addTargetsMap.set(parent, myIndex);
              }
            } else {
              addTargetsMap.set(parent, myIndex);
            }
          } else {
            addTargetsMap.set(parent, 0);
          }
        }
      }
    }
    return addTargetsMap;
  };

  if (nodes.length) {
    add(
      Array.from(getAddTargetsMap().entries()).map(([parent, index]) => ({
        parent,
        index,
      }))
    );
    return {
      name: "add content",
      events,
      isEnded: () => ended,
      abort() {},
    };
  }

  // 没有的话, 就走选中的流程

  const disposers = new Disposers();

  let destroyed = false;

  const cleanup = () => {
    if (destroyed) return;
    destroyed = true;
    disposers.clear();
  };

  const abort = () => {
    if (destroyed) return;
    runSafeSync(cleanup);
    end(false);
  };

  const entry = () => {
    // 覆盖系统选中类型, 只能选择容器类型
    disposers.add("selectableTypes", session.ui.selectableTypes.create(new Set(["frame"])));

    // 让画布根节点可选中
    disposers.add("isCurrentRootNodeSelectable", session.ui.isNodeSelectable(session.root).create(true));

    // 显示添加提醒
    {
      const msg = message.info(ctx, <请选择一个画框 />, 0, { through: true });
      disposers.add("message", () => msg.close());
    }

    // 监听选中 dal 事件, 以触发下一步添加操作
    disposers.add(
      "select dal object",
      host.once(
        SelectGDLObject,
        (e) =>
          runInAction(() => {
            e.stopPropagation();
            e.preventDefault();
            const { target } = e.data;

            if (target.kind !== "frame") return abort();

            runSafeSync(cleanup);

            add([{ parent: target }]);
          }),
        true
      )
    );
  };

  entry();

  return {
    name: "add content",
    events,
    isEnded: () => ended,
    abort,
  };
}

const 请选择一个画框 = i18n({
  en: () => "Please select a frame",
  zh: () => "请选择一个画框",
});

const 未实现图片选择器接口 = i18n({
  en: () => "Image selector is not provided",
  zh: () => "未实现图片选择器接口",
});
