import { action, makeObservable, observable } from 'mobx'
import { SelectableTarget } from './types'

export interface CustomClipboardEvent {
  readonly type: 'custom clipboard event'
  readonly content: CustomClipboardContent
  readonly timestamp: number
}

export type CustomClipboardContent = CustomClipboardContentSelection

export interface CustomClipboardContentSelection {
  readonly type: 'custom clipboard content selection'
  readonly purpose: 'style' | 'content'
  readonly selection: readonly SelectableTarget[]
}

export class CustomClipboard {
  constructor() {
    makeObservable(this)
  }

  @observable.ref
  protected _current?: CustomClipboardEvent = undefined

  get() {
    return this._current
  }

  @action
  setSelection(purpose: 'style' | 'content', selection: readonly SelectableTarget[]) {
    this._set({
      type: 'custom clipboard content selection',
      purpose,
      selection,
    })
  }

  clear() {
    this._current = undefined
  }

  @action
  protected _set(content: CustomClipboardContent) {
    this._current = {
      type: 'custom clipboard event',
      content,
      timestamp: Date.now(),
    }
  }
}
