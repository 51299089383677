import immer from "immer";

import { Text } from "@chuyuan/poster-data-access-layer";
import { TextSpec } from "@chuyuan/poster-data-structure";
import {
  memorize,
  ClassStaticCache,
  cached,
  EMPTY_OBJECT,
  isIterable,
  predicate,
  mapIterableAsKeys,
  identity,
  typedValuesOf,
  KeyMap,
} from "@chuyuan/poster-utils";

import {
  DuplexField,
  SpreadKeyDuplex,
  KeyReader,
  MinReducer,
  DuplexLike,
  SpreadIndexDuplex,
  MapDuplex,
  SpreadDuplex,
  DefaultValueDuplex,
  ReadableSource,
  FilterDuplex,
  SkipDuplex,
  EveryReducer,
  ConcatDuplex,
  FlattenDuplex,
  ReaderLike,
  MapReader,
  ZipReader,
  ZipReaderWriter,
  // Duplex,
} from "../../utils/multi-value";
import {
  TextDuplex,
  FontSizeDuplex,
  TextDirectionDuplex,
  LineAlignmentDuplex,
  RegionAlignmentDuplex,
  WrapDuplex,
  SelfLayerFillDuplex,
  FontFamilyDuplexs,
  InlineGapDuplex,
  LineGapDuplex,
  TextTransformDuplex,
  TextDecorationDuplex,
  OverflowDuplex,
  SelfAdvancedEffectLayerDuplex,
  OffsetAdvancedEffectLayersDuplex,
  fromTextSpecFillingPaint,
  toTextSpecFillingPaint,
  SkewDuplexs,
  BackgroundsDuplex,
  formatTextBackground,
  BackgroundEditDataDuplex,
  AdvancedEffectLayerCache,
  TextCompanionData,
} from "./state.text";
import type {
  NullableFillingPaint,
  FillingPaintColor,
} from "../ui-components-stateful/filling-paint-text-spec";
import { safeDimension } from "../ui-components/data-guard";
import { FillingPaintCache } from "./filling-paint-cache-text-spec";
import { mapValues } from "lodash";

export class Fields {
  static of = ClassStaticCache;

  readonly targets: readonly Text[];

  constructor(targets: Text | Iterable<Text>) {
    this.targets = isIterable(targets)
      ? Array.from(new Set(targets))
      : [targets];
  }

  @memorize
  get source() {
    return new ReadableSource(this.targets);
  }

  @memorize
  get text() {
    return new DuplexField(new TextDuplex(this.source));
  }

  @memorize
  get fontSize() {
    return new DuplexField(new FontSizeDuplex(this.source));
  }

  @memorize
  get textDirection() {
    return new DuplexField(new TextDirectionDuplex(this.source));
  }

  @memorize
  get lineAlignment() {
    return new DuplexField(new LineAlignmentDuplex(this.source));
  }

  @memorize
  get regionAlignment() {
    return new DuplexField(new RegionAlignmentDuplex(this.source));
  }

  @memorize
  get wrap() {
    return new DuplexField(new WrapDuplex(this.source));
  }

  @memorize
  get textStyle() {
    return new BasicTextStyleFields(this, this.targets);
  }

  @memorize
  get selfLayerFill() {
    const duplex = new SelfLayerFillDuplex(this.source);
    const cache = new MapReader(
      this.source,
      (x) => TextCompanionData.of(x).cache.self.fill
    );
    return createPaintWithCache(duplex, cache);
  }

  @memorize
  get selfLayerFillOptions() {
    return {
      typeCache: new MapReader(
        this.source,
        (x) => TextCompanionData.of(x).cache.self.fill.getFn
      ),
    } as const;
  }
  @memorize
  get regionShape() {
    return new DuplexField(new BackgroundsDuplex(this.source));
  }

  @memorize
  get backgrounds() {
    return createBackgrounds(this.source, TextBackgroundTypes);
  }
}

export abstract class AbstractTextStyleFields<T extends Text = Text> {
  constructor(readonly parent: Fields, readonly targets: readonly T[]) {}

  @memorize
  get source() {
    return new ReadableSource(this.targets);
  }

  @memorize
  get fontFamilyZh() {
    return new DuplexField(new FontFamilyDuplexs.zh(this.source));
  }

  @memorize
  get fontFamilyEn() {
    return new DuplexField(new FontFamilyDuplexs.en(this.source));
  }

  @memorize
  get inlineGap() {
    return new DuplexField(new InlineGapDuplex(this.source));
  }

  @memorize
  get textTransform() {
    return new DuplexField(new TextTransformDuplex(this.source));
  }

  @memorize
  get textDecoration() {
    return new DuplexField(new TextDecorationDuplex(this.source));
  }
}

export type AnyTextStyleFields = BasicTextStyleFields;

export class BasicTextStyleFields extends AbstractTextStyleFields<Text> {
  readonly kind = "basic";

  @memorize
  get lineGap() {
    return new DuplexField(LineGapDuplex.of(this.source));
  }

  @memorize
  get overflow() {
    return new DuplexField(new OverflowDuplex(this.source));
  }

  @memorize
  get selfLayer() {
    return new SelfAdvancedEffectLayerDuplex(this.source);
  }

  @memorize
  get offsetLayers() {
    return new OffsetAdvancedEffectLayersDuplex(this.source);
  }

  @memorize
  get allLayers() {
    return new ConcatDuplex([
      this.selfLayer,
      new FlattenDuplex(this.offsetLayers),
    ]);
  }

  @memorize
  get offsetLayersLength() {
    return new MinReducer(new KeyReader(this.offsetLayers, "length"));
  }

  @memorize
  get selfLayerWithCache() {
    return {
      layer: this.selfLayer,
      cache: new MapReader(
        this.source,
        (x) => TextCompanionData.of(x).cache.self
      ),
    } as const;
  }

  @memorize
  get getOffsetLayerWithCache() {
    const layers = this.offsetLayers;
    return cached(
      (index: number) =>
        ({
          layer: new SpreadIndexDuplex(layers, index, () => EMPTY_OBJECT),
          cache: new MapReader(this.source, (x) =>
            TextCompanionData.of(x).cache.getOffset(index)
          ),
        } as const)
    );
  }

  @memorize
  get getAdvancedEffectLayerFields() {
    return cached(
      ({
        layer,
        cache,
      }: {
        readonly layer: DuplexLike<TextSpec.TextAdvancedEffectLayer>;
        readonly cache: ReaderLike<AdvancedEffectLayerCache>;
      }) => {
        return new AdvancedEffectLayerFields(layer, cache);
      }
    );
  }

  @memorize
  get skew() {
    return mapValues(
      SkewDuplexs,
      (Class) => new DuplexField(new Class(this.allLayers))
    );
  }

  @memorize
  get backgrounds() {
    return createBackgrounds(this.source, TextBackgroundTypes);
  }
}

function createBackgrounds<T extends keyof TextSpec.TextLayoutBackgrounds>(
  source: ReaderLike<Text>,
  types: Iterable<T>
) {
  const backgroundsCache = new MapReader(source, (x) => {
    return TextCompanionData.of(x).cache.backgrounds;
  });
  return mapIterableAsKeys(types, (type) => {
    const bg = new SpreadKeyDuplex(new BackgroundsDuplex(source), type);
    const formatted = MapDuplex.createIdentical(
      bg,
      formatTextBackground,
      identity
    );
    const data = new BackgroundEditDataDuplex(
      new FilterDuplex(formatted, predicate)
    );
    return {
      has: new EveryReducer(formatted),
      colors: mapIterableAsKeys(BackgroundColorTypes, (colorType) => {
        const paint = new SkipDuplex(
          new SpreadKeyDuplex(data, colorType),
          (input: NullableFillingPaint): input is FillingPaintColor => {
            return input.kind === "color";
          }
        );
        const cache = new MapReader(
          backgroundsCache,
          (x) => x[type][colorType]
        );
        return {
          paint: createPaintWithCache(paint, cache),
          options: {
            typeCache: new MapReader(
              backgroundsCache,
              (x) => x[type][colorType].getFn
            ),
          },
        } as const;
      }),
      strokeWidth: new DuplexField(
        MapDuplex.createIdentical(
          data,
          (target) => safeDimension(target.shape.borderWidth),
          (newValue, target) =>
            immer(target, (draft) => {
              draft.shape.borderWidth = newValue;
            })
        )
      ),
      borderLineCap: new DuplexField(
        MapDuplex.createIdentical(
          data,
          (target) => target.shape.borderLineCap,
          (newValue, target) =>
            immer(target, (draft) => {
              draft.shape.borderLineCap = newValue;
            })
        )
      ),
      type: new DuplexField(
        MapDuplex.createIdentical(
          data,
          (target) => target.shape.type,
          (newValue, target) =>
            immer(target, (draft) => {
              draft.shape.type = newValue;
            })
        )
      ),
      scaleX: new DuplexField(
        MapDuplex.createIdentical(
          data,
          (target) => target.scaleX,
          (newValue, target) =>
            immer(target, (draft) => {
              draft.scaleX = newValue;
            })
        )
      ),
      scaleY: new DuplexField(
        MapDuplex.createIdentical(
          data,
          (target) => target.scaleY,
          (newValue, target) =>
            immer(target, (draft) => {
              draft.scaleY = newValue;
            })
        )
      ),
    };
  });
}
const BackgroundColorTypes = ["fill", "stroke"] as const;

const TextBackgroundTypes = typedValuesOf<
  KeyMap<keyof TextSpec.TextLayoutBackgrounds>
>({
  character: "character",
  phrase: "phrase",
  line: "line",
  paragraph: "paragraph",
  region: "region",
  lineEnds: "lineEnds",
  regionEnds: "regionEnds",
});

export class AdvancedEffectLayerFields {
  constructor(
    readonly parent: DuplexLike<TextSpec.TextAdvancedEffectLayer>,
    readonly cache: ReaderLike<AdvancedEffectLayerCache>
  ) {}

  @memorize
  private get fill() {
    return new DuplexField(
      new DefaultValueDuplex(
        new SpreadKeyDuplex(this.parent, "fill"),
        EMPTY_OBJECT
      )
    );
  }

  @memorize
  private get stroke() {
    return new DuplexField(
      new DefaultValueDuplex(
        new SpreadKeyDuplex(this.parent, "stroke"),
        EMPTY_OBJECT
      )
    );
  }

  @memorize
  private get dropShadow() {
    return new DuplexField(
      new DefaultValueDuplex(
        new SpreadKeyDuplex(this.parent, "dropShadow"),
        EMPTY_OBJECT
      )
    );
  }

  // @memorize
  // private get transform() {
  //   return new DuplexField(new SpreadKeyDuplex(this.parent, 'transform'))
  // }

  @memorize
  get fillPaint() {
    const duplex = MapDuplex.createIdentical(
      new SpreadDuplex(this.fill.parent),
      (source) => {
        const { fill, fillOpacity } = source || EMPTY_OBJECT;
        return fromTextSpecFillingPaint(fill, fillOpacity);
      },
      (data) => {
        const [fill, fillOpacity] = toTextSpecFillingPaint(data);
        return { fill, fillOpacity };
      }
    );
    const cache = new MapReader(this.cache, (x) => x.fill);
    return createPaintWithCache(duplex, cache);
  }

  @memorize
  get fillPaintOptions() {
    return {
      typeCache: new MapReader(this.cache, (x) => x.fill.getFn),
    } as const;
  }

  @memorize
  get strokePaint() {
    const duplex = MapDuplex.createIdentical(
      new SpreadDuplex(this.stroke.parent),
      (source) => {
        const { stroke, strokeOpacity } = source || EMPTY_OBJECT;
        return fromTextSpecFillingPaint(stroke, strokeOpacity);
      },
      (data) => {
        const [stroke, strokeOpacity] = toTextSpecFillingPaint(data);
        return { stroke, strokeOpacity };
      }
    );
    const cache = new MapReader(this.cache, (x) => x.stroke);
    return createPaintWithCache(duplex, cache);
  }

  @memorize
  get strokePaintOptions() {
    return {
      typeCache: new MapReader(this.cache, (x) => x.stroke.getFn),
    } as const;
  }

  @memorize
  get shadowPaint() {
    const duplex = MapDuplex.createIdentical(
      new SpreadDuplex(this.dropShadow.parent),
      (source) => {
        const { shadowFill, shadowOpacity } = source || EMPTY_OBJECT;
        return fromTextSpecFillingPaint(shadowFill, shadowOpacity);
      },
      (data) => {
        const [shadowFill, shadowOpacity] = toTextSpecFillingPaint(data);
        return { shadowFill, shadowOpacity };
      }
    );
    const cache = new MapReader(this.cache, (x) => x.shadow);
    return createPaintWithCache(duplex, cache);
  }

  @memorize
  get shadowPaintOptions() {
    return {
      typeCache: new MapReader(this.cache, (x) => x.shadow.getFn),
    } as const;
  }

  @memorize
  get strokeWidth() {
    return new DuplexField(
      new DefaultValueDuplex(
        new SpreadKeyDuplex(this.stroke.parent, "strokeWidth"),
        0
      )
    );
  }

  @memorize
  get offsetAngle() {
    return new DuplexField(
      new DefaultValueDuplex(new SpreadKeyDuplex(this.parent, "offsetAngle"), 0)
    );
  }

  @memorize
  get offsetDistance() {
    return new DuplexField(
      new DefaultValueDuplex(
        new SpreadKeyDuplex(this.parent, "offsetDistance"),
        0
      )
    );
  }

  @memorize
  get shadowAngle() {
    return new DuplexField(
      new DefaultValueDuplex(
        new SpreadKeyDuplex(this.dropShadow.parent, "shadowAngle"),
        0
      )
    );
  }

  @memorize
  get shadowOffset() {
    return new DuplexField(
      new DefaultValueDuplex(
        new SpreadKeyDuplex(this.dropShadow.parent, "shadowOffset"),
        0
      )
    );
  }

  @memorize
  get shadowBlurRadius() {
    return new DuplexField(
      new DefaultValueDuplex(
        new SpreadKeyDuplex(this.dropShadow.parent, "shadowBlurRadius"),
        0
      )
    );
  }

  // @memorize
  // get skewX() {
  //   return new DefaultNumberMultiValue(new SpreadKeyDuplex(this.transform, 'skewX'), 0)
  // }

  // @memorize
  // get skewY() {
  //   return new DefaultNumberMultiValue(new SpreadKeyDuplex(this.transform, 'skewY'), 0)
  // }
}

function createPaintWithCache<T extends NullableFillingPaint>(
  duplex: DuplexLike<T>,
  reader: ReaderLike<FillingPaintCache>
) {
  const zipped = new ZipReader([duplex, reader] as const);
  const merged = new ZipReaderWriter(zipped, duplex);
  return MapDuplex.createIdentical(
    merged,
    (x) => x[0],
    (newValue, [oldValue, cache]) => {
      if (oldValue.kind !== "empty") {
        cache.put(oldValue);
      }
      if (newValue.kind !== "empty") {
        cache.put(newValue);
      }
      return newValue;
    }
  );
}
