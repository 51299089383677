import * as React from "react";
import { useObserver } from "mobx-react";
import { runInAction } from "mobx";

import {
  TextLineAlignmentLeft,
  TextLineAlignmentCenter,
  TextLineAlignmentRight,
  TextLineAlignmentJustify,
} from "../../../../poster-ui/icons/new-icons";

import { useLocale, i18n } from "../../utils/i18n";
import type { DuplexFieldLike } from "../../utils/multi-value";
import { Radio, RadioOption } from "../ui-components/radio";
import { getInputProps } from "../../utils/react-multi-value";
import type { LineAlignmentType, TextDirectionType } from "./state.text";
import { EditorContext } from "../helpers/react-context";

export const LineAlignmentValue = React.memo(
  (props: {
    readonly textDirection: TextDirectionType;
    readonly field: DuplexFieldLike<LineAlignmentType>;
  }) => {
    const { textDirection, field } = props;

    const { session } = React.useContext(EditorContext);
    const locale = useLocale();

    return useObserver(() => {
      const multiValueProps = getInputProps(field, locale);
      const disabled = multiValueProps.disabled;
      return (
        <>
          <Radio
            {...multiValueProps}
            className="iconic"
            disabled={disabled}
            onChange={(v) =>
              runInAction(() => {
                field.set(v);
                session.history.push({ name: "修改行对齐" });
              })
            }
            options={
              textDirection === "vertical" ? VerticalOptions : HorizontalOptions
            }
          />
        </>
      );
    });
  }
);
LineAlignmentValue.displayName = "LineAlignmentValue";

const HorizontalOptions: ReadonlyArray<RadioOption<LineAlignmentType>> = [
  {
    value: "left",
    title: () => <TextLineAlignmentLeft />,
    tooltip: { content: () => <左对齐 /> },
  },
  {
    value: "center",
    title: () => <TextLineAlignmentCenter />,
    tooltip: { content: () => <水平居中 /> },
  },
  {
    value: "right",
    title: () => <TextLineAlignmentRight />,
    tooltip: { content: () => <右对齐 /> },
  },
  {
    value: "justify",
    title: () => <TextLineAlignmentJustify />,
    tooltip: { content: () => <两端对齐 /> },
  },
];

const VerticalOptions: ReadonlyArray<RadioOption<LineAlignmentType>> = [
  {
    value: "left",
    title: () => (
      <TextLineAlignmentLeft style={{ transform: "rotate(90deg)" }} />
    ),
    tooltip: { content: () => <上对齐 /> },
  },
  {
    value: "center",
    title: () => (
      <TextLineAlignmentCenter style={{ transform: "rotate(90deg)" }} />
    ),
    tooltip: { content: () => <垂直居中 /> },
  },
  {
    value: "right",
    title: () => (
      <TextLineAlignmentRight style={{ transform: "rotate(90deg)" }} />
    ),
    tooltip: { content: () => <下对齐 /> },
  },
  {
    value: "justify",
    title: () => (
      <TextLineAlignmentJustify style={{ transform: "rotate(90deg)" }} />
    ),
    tooltip: { content: () => <两端对齐 /> },
  },
];

const 左对齐 = i18n({
  en: () => "Align Left",
  zh: () => "左对齐",
});

const 水平居中 = i18n({
  en: () => "Align Center",
  zh: () => "水平居中",
});

const 右对齐 = i18n({
  en: () => "Align Right",
  zh: () => "右对齐",
});

const 两端对齐 = i18n({
  en: () => "Justify",
  zh: () => "两端对齐",
});

const 上对齐 = i18n({
  en: () => "Align Top",
  zh: () => "上对齐",
});

const 垂直居中 = i18n({
  en: () => "Align Center",
  zh: () => "垂直居中",
});

const 下对齐 = i18n({
  en: () => "Align Bottom",
  zh: () => "下对齐",
});
