import * as React from "react";
import { useObserver } from "mobx-react";
import { runInAction } from "mobx";

import type { TextSpec } from "@chuyuan/poster-data-structure";

import { useLocale, i18n } from "../../utils/i18n";
import type { DuplexFieldLike } from "../../utils/multi-value";
import { Select, SelectItemProps, SelectRef } from "../ui-components/select";
import { getInputProps } from "../../utils/react-multi-value";
import { EditorContext } from "../helpers/react-context";

export const WrapValue = React.memo(
  React.forwardRef<
    SelectRef,
    {
      readonly field: DuplexFieldLike<TextSpec.TextStyleWrapType>;
    }
  >((props, ref) => {
    const { field } = props;

    const { session } = React.useContext(EditorContext);
    const locale = useLocale();

    return useObserver(() => {
      const fieldProps = getInputProps(field, locale);
      return (
        <>
          <Select<TextSpec.TextStyleWrapType>
            {...fieldProps}
            ref={ref}
            options={Options}
            onChange={(newValue) =>
              runInAction(() => {
                if (!field.set(newValue).records.some(Boolean)) return;
                session.history.push({ name: "修改换行方式" });
              })
            }
          />
        </>
      );
    });
  })
);
WrapValue.displayName = "WrapValue";

const Options: ReadonlyArray<SelectItemProps<TextSpec.TextStyleWrapType>> = [
  {
    value: "no",
    label: () => <不换行 />,
  },
  {
    value: "word",
    label: () => <按词换行 />,
  },
  {
    value: "any",
    label: () => <按字换行 />,
  },
];

const 不换行 = i18n({
  en: () => "Never",
  zh: () => "不换行",
});

const 按词换行 = i18n({
  en: () => "By words",
  zh: () => "按词换行",
});

const 按字换行 = i18n({
  en: () => "By characters",
  zh: () => "按字换行",
});
