import { Mutable } from "@chuyuan/poster-utils";

export function pickBox(box: BoxLike) {
  const { x, y, width, height } = box;
  return { x, y, width, height };
}

export function pickXY(xy: XYLike) {
  const { x, y } = xy;
  return { x, y };
}

export function pickDimension(x: DimensionLike) {
  const { width, height } = x;
  return { width, height };
}

export function pickBounding(x: Bounding) {
  const { left, right, top, bottom } = x;
  return { left, right, top, bottom };
}

export function pickBoxToBounding(box: BoxLike) {
  const { x, y, width, height } = box;
  return {
    left: x,
    top: y,
    right: x + width,
    bottom: x + height,
  };
}

export function pickDimensionToBounding(x: DimensionLike) {
  const { width, height } = x;
  return {
    left: 0,
    top: 0,
    right: width,
    bottom: height,
  };
}

export function getBoundingGivenCenterAndPadding(
  x: number,
  y: number,
  padding: number
) {
  return {
    left: x - padding,
    right: x + padding,
    top: y - padding,
    bottom: y + padding,
  };
}

export function expandBounding(b: Bounding, padding: number) {
  return {
    left: b.left - padding,
    right: b.right + padding,
    top: b.top - padding,
    bottom: b.bottom + padding,
  };
}

export function ensureValidBounding(b: Mutable<Bounding>) {
  if (b.left > b.right) {
    b.left = b.right = (b.left + b.right) / 2;
  }
  if (b.top > b.bottom) {
    b.top = b.bottom = (b.top + b.bottom) / 2;
  }
}

export function xyToVector2D(xy: XYLike) {
  return [xy.x, xy.y] as [number, number];
}

export function boxToCenter(box: BoxLike) {
  return [box.x + box.width / 2, box.y + box.height / 2] as [number, number];
}

export function boundingToCenter(b: Bounding) {
  return [(b.left + b.right) / 2, (b.top + b.bottom) / 2] as [number, number];
}

export function dimensionCenterToVector2D(dimension: DimensionLike) {
  return [dimension.width / 2, dimension.height / 2] as [number, number];
}

export class BoxToBounding {
  constructor(readonly box: BoxLike) {}

  get left() {
    return this.box.x;
  }

  get top() {
    return this.box.y;
  }

  get right() {
    return this.box.x + this.box.width;
  }

  get bottom() {
    return this.box.y + this.box.height;
  }
}

export class DimensionToBounding {
  constructor(readonly dim: DimensionLike) {}

  get left() {
    return 0;
  }

  get top() {
    return 0;
  }

  get right() {
    return this.dim.width;
  }

  get bottom() {
    return this.dim.height;
  }
}

export type Bounding = {
  readonly left: number;
  readonly right: number;
  readonly top: number;
  readonly bottom: number;
};

export type BoxLike = XYLike & DimensionLike;

export type XYLike = {
  readonly x: number;
  readonly y: number;
};

export type DimensionLike = {
  readonly width: number;
  readonly height: number;
};
