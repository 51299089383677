import * as React from 'react'
import styled from 'styled-components'

import { i18n } from '../../utils/i18n'
import { AnyTextStyleFields } from './state.ui'
import { Row } from '../ui-components/section'
import { Radio, RadioOption } from '../ui-components/radio'
import { BasicSettingsPanel } from './edit-style-panel-basic-settings'
import { LayerStylePanel } from './edit-style-panel-layer-style'

export interface EditTextStylePanelContentProps {
  readonly fields: AnyTextStyleFields
  readonly onClose?: () => void
}

export const EditTextStylePanelContent = React.memo((props: EditTextStylePanelContentProps) => {
  const [tabKey, setTabKey] = React.useState<TabType>('basic')

  const { fields } = props

  return (
    <>
      <Row className="margin-y auto">
        <Radio
          value={tabKey}
          onChange={setTabKey}
          options={TabOptions}
        />
      </Row>
      {
        tabKey === 'basic' ? <BasicSettingsPanel {...props} /> :
        tabKey === 'layer' ? <LayerStylePanel {...props} fields={fields} /> :
        null
      }
    </>
  )
})
EditTextStylePanelContent.displayName = 'EditTextStylePanelContent'

type TabType = 'basic' | 'layer'

const TabOptions: ReadonlyArray<RadioOption<TabType>> = [{
  value: 'basic',
  title: () => <基本设置 />,
}, {
  value: 'layer',
  title: () => <图层样式 />,
}]

const Preview = styled.div`
  width: 100%;
  height: 104px;
  background: #f8f7f6;
  border: 1px solid #eae9e8;
  box-sizing: border-box;
  border-radius: 4px;
`
Preview.displayName = 'Preview'

const 基本设置 = i18n({
  en: () => 'Basic Settings',
  zh: () => '基本设置',
})

const 图层样式 = i18n({
  en: () => 'Layer Style',
  zh: () => '图层样式',
})
