import * as React from "react";
import { runInAction } from "mobx";
import { useObserver } from "mobx-react";

import { EMPTY_ARRAY } from "@chuyuan/poster-utils";
import type { HighlightEditorRef } from "@chuyuan/poster-rich-text-editor";

import { useLocale, i18n } from "../../utils/i18n";
import { DuplexFieldLike } from "../../utils/multi-value";
import { 多个值 } from "../../utils/react-multi-value";
import { HighlightEditor } from "../ui-components/highlight-editor";
import { EditorContext } from "../helpers/react-context";

export const TextValue = React.memo(
  (props: {
    readonly autoFocus?: boolean;
    readonly text: DuplexFieldLike<string>;
  }) => {
    const { text, autoFocus } = props;

    const editor = React.useContext(EditorContext);
    const locale = useLocale();

    const state = React.useRef<{
      editor?: HighlightEditorRef | null;
      isFocused?: boolean;
      hasChange?: boolean;
    }>({}).current;

    React.useEffect(() => {
      if (autoFocus) state?.editor?.editor?.focus();
    }, EMPTY_ARRAY);

    return useObserver(() => {
      const textVariety = text.variety;
      const textValue = text.get();
      return (
        <>
          <HighlightEditor
            ref={(r) => (state.editor = r)}
            text={textValue}
            placeholder={
              textVariety === "various"
                ? 多个值.get(locale)
                : 请输入.get(locale)
            }
            onFocus={() => (state.hasChange = false)}
            onBlur={() => {
              const { hasChange } = state;
              state.hasChange = false;
              if (hasChange) {
                editor.session.history.push({ name: "修改文本或高亮标记" });
              }
            }}
            onChange={(data) =>
              runInAction(() => {
                console.log("HighlightEditor", data);
                if (![text.set(data.text).records.some(Boolean)].some(Boolean))
                  return;
                state.hasChange = true;
              })
            }
          />
        </>
      );
    });
  }
);
TextValue.displayName = "TextValue";

const 请输入 = i18n({
  en: () => "Any text...",
  zh: () => "请输入...",
});
