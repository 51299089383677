import * as React from 'react'

export interface UseInputTextProps extends Readonly<Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  | 'readOnly'
  | 'disabled'
  | 'onFocus'
  | 'onKeyDown'
  | 'onBlur'
>> {
  readonly value?: string
  readonly onChange?: (value: string) => void
  readonly onComplete?: (value: string) => void
}

export function useInputTextProps(props: UseInputTextProps) {
  const state = React.useRef<{
    changed?: boolean
    // 解释一下这个字段.
    // input的blur事件触发的顺序是: mouse down -> blur
    // 然后如果在 mouse down 事件里触发了一些动作, 导致传入的 onChange, onComplete, onBlur 等, 变成了新的语义
    // 然后在 blur 事件中, 原本预期是触发旧语义的动作, 错误地触发了新语义.
    // 所以这个值代表了正确的语义 props (主要是关于回调函数)
    focusProps?: UseInputTextProps
  }>({}).current

  const propsText = props.value || ''
  const [draft, setDraft] = React.useState({ text: propsText, isFocused: false })

  if (!draft.isFocused && propsText !== draft.text) {
    setDraft({ ...draft, text: propsText })
  }

  if (!draft.isFocused) {
    // 非 focus 的时候总是更新语义 props
    state.focusProps = props
  }

  const newProps: React.InputHTMLAttributes<HTMLInputElement> = {
    readOnly: props.readOnly,
    disabled: props.disabled,
    autoComplete: 'off',
    value: draft.text,
    onFocus: e => {
      state.changed = false
      setDraft(x => ({ ...x, isFocused: true }))
      props.onFocus?.(e)
    },
    onChange: e => {
      const text = e.target.value
      setDraft(x => ({ ...x, text }))
    },
    onKeyDown: e => {
      // 内容更新的时候触发语义 props 更新
      state.focusProps = props
      props.onKeyDown?.(e)
      if (e.isPropagationStopped()) return
      if (props.readOnly || props.disabled) {
        return
      }
      if (e.key === 'Enter') {
        state.changed = true
        props.onChange?.(draft.text)
        e.preventDefault()
      }
    },
    onBlur: e => {
      // 读取语义 props
      const p = state.focusProps || props
      setDraft(x => ({ ...x, isFocused: false }))
      if (propsText !== draft.text) {
        // 值不同意味着没有 emit 过 changes
        state.changed = true
        p.onChange?.(draft.text)
      }
      if (state.changed) {
        p.onComplete?.(draft.text)
      }
      state.changed = false
      p.onBlur?.(e)
    },
  }

  return {
    props: newProps,
  }
}
