import * as React from "react";
import styled from "styled-components";

import { MaybeThunk } from "@chuyuan/poster-utils";
import {
  AlignmentTopSparse,
  AlignmentCenterSparse,
  AlignmentBottomSparse,
} from "../../../../poster-ui/icons/new-icons";

import {
  BlockAlignLeftIcon,
  BlockAlignRightIcon,
  BlockAlignVerticalCenterIcon,
} from "../../../../poster-ui/icons/new-icons";

import type { CommonProps } from "./common";

export type AlignmentType = "start" | "center" | "end";

export type Alignment2DRef = HTMLButtonElement;

export type Alignment2DInput = Alignment2DBothData | Alignment2DSingleInput;

export type Alignment2DData = Alignment2DBothData | Alignment2DSingleData;

export type Alignment2DBothData = {
  readonly kind: "both";
  readonly horizontal: AlignmentType;
  readonly vertical: AlignmentType;
};

export type Alignment2DSingleInput = {
  readonly kind: "single";
  readonly direction: HV;
  readonly value?: AlignmentType;
};

export type Alignment2DSingleData = {
  readonly kind: "single";
  readonly direction: HV;
  readonly value: AlignmentType;
};

export type Alignment2DProps = CommonProps & {
  readonly placeholder?: MaybeThunk<React.ReactNode>;
  readonly value?: Alignment2DInput;
  readonly onChange?: (data: Alignment2DData) => void;
};

export const Alignment2D = React.forwardRef((props: Alignment2DProps) => {
  const { value: data, onChange } = props;

  return (
    <>
      {!data || data.kind === "both" ? (
        <Selector9
          data={data}
          onChange={(newData) => onChange?.(newData)}
          onClose={() => {}}
        />
      ) : (
        <Selector3
          data={data}
          onChange={(newData) => onChange?.(newData)}
          onClose={() => {}}
        />
      )}
    </>
  );
});
Alignment2D.displayName = "Alignment2D";

type HV = "horizontal" | "vertical";

export function Selector9(props: {
  readonly data?: Alignment2DBothData;
  readonly onClose: () => void;
  readonly onChange?: (data: Alignment2DBothData) => void;
}) {
  const { data, onChange, onClose } = props;

  const k = data
    ? AlignmentTypesIndex[data.vertical] * 3 +
      AlignmentTypesIndex[data.horizontal]
    : -1;

  const slots: React.ReactNode[] = [];

  for (let i = 0; i < 3; i++) {
    for (let j = 0; j < 3; j++) {
      const index = i * 3 + j;
      const horizontal = AlignmentTypes[j];
      const vertical = AlignmentTypes[i];
      const Icon =
        horizontal === "start"
          ? BlockAlignLeftIcon
          : horizontal === "end"
          ? BlockAlignRightIcon
          : BlockAlignVerticalCenterIcon;
      const selected = index === k;
      // console.log("index", index);
      slots.push(
        <Icon
          key={index}
          className={`icon ${selected ? "selected" : ""}`}
          onClick={() => {
            try {
              onChange?.({ kind: "both", horizontal, vertical });
            } finally {
              onClose();
            }
          }}
        />
      );
    }
  }

  return <Container children={slots} />;
}

function Selector3(props: {
  readonly data: Alignment2DSingleInput;
  readonly onClose: () => void;
  readonly onChange?: (data: Alignment2DSingleData) => void;
}) {
  const { data, onClose, onChange } = props;

  const k = data.value ? AlignmentTypesIndex[data.value] : -1;

  const { direction } = data;

  const slots: React.ReactNode[] = [];

  for (let i = 0; i < 3; i++) {
    const value = AlignmentTypes[i];
    const Icon =
      value === "start"
        ? AlignmentTopSparse
        : value === "end"
        ? AlignmentBottomSparse
        : AlignmentCenterSparse;
    const selected = i === k;
    slots.push(
      <Icon
        key={i}
        className={`icon ${selected ? "selected" : ""}`}
        onClick={() => {
          try {
            onChange?.({ kind: "single", direction, value });
          } finally {
            onClose();
          }
        }}
      />
    );
  }

  return <Container className={direction} children={slots} />;
}

const AlignmentTypes = ["start", "center", "end"] as const;

const AlignmentTypesIndex = {
  start: 0,
  center: 1,
  end: 2,
} as const;

const Container = styled.div`
  width: 74px;
  // height: 104px;
  // padding: 4px;
  border: 1px solid rgb(233, 233, 233);
  display: flex;
  flex-wrap: wrap;

  .icon {
    width: 24px;
    height: 24px;
    display: block;
    cursor: pointer;
    // padding: 4px;
    opacity: 1;
    color: #eae9e8;
    font-size: 24px;

    &:hover,
    &.selected {
      color: #0040f0;
    }

    &:hover {
      opacity: 0.5;
    }

    &.selected {
      opacity: 1;
    }
  }

  &.horizontal,
  &.vertical {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .icon {
      width: 100%;

      svg {
        width: 100%;
      }
    }
  }

  &.horizontal {
    transform: rotate(-90deg);
  }
`;
