import ls from '../../utils/mobx-local-storage'
import { v4 as uuid } from 'uuid'
import { Immutable } from 'immer'

import { NS } from './common'

export const SS_SESSION_ID = `${NS} session id`

export function getSessionId() {
  let sessionId = sessionStorage.getItem(SS_SESSION_ID)
  if (!sessionId) sessionStorage.setItem(SS_SESSION_ID, sessionId = uuid())
  return sessionId
}

export const LS_LOCALE = createStringLocalStorageItem('locale')

export function createEnumLocalStorageItem<T extends string | number | boolean>(
  key: string,
  formatEnum: (x: unknown) => T,
) {
  return createLocalStorageItem<T>(key, {
    parse: x => {
      return formatEnum(parseJson(x))
    },
    stringify: x => {
      return JSON.stringify(formatEnum(x))
    },
  })
}

export function createStringLocalStorageItem(key: string) {
  return createLocalStorageItem<string>(key, {
    parse: x => String(x || ''),
    stringify: x => String(x || ''),
  })
}

export function createBooleanLocalStorageItem(key: string, defaultValue?: boolean) {
  const dv = defaultValue ?? false
  const ret = createLocalStorageItem<boolean>(key, {
    parse: x => {
      const v = parseJson(x)
      if (v === null || v === undefined) return dv
      if (typeof v === 'boolean') return v
      if (typeof v === 'string') {
        const t = v.toLowerCase()
        if (t === 'true') return true
        if (t === 'false') return false
      }
      return Boolean(v)
    },
    stringify: x => x ? 'true' : 'false',
  })
  return {
    ...ret,
    toggle: () => ret.set(!ret.get()),
  }
}

export function createNumberLocalStorageItem(key: string, formatValue?: (v?: number) => number) {
  const fm = formatValue || (x => x || 0)
  const ret = createLocalStorageItem<number>(key, {
    parse: x => {
      const v = parseJson(x)
      if (typeof v === 'number') return fm(v)
      return fm()
    },
    stringify: x => JSON.stringify(fm(x)),
  })
  return {
    ...ret,
    format: fm,
  }
}

export function createJsonLocalStorageItem<T>(
  key: string,
) {
  const KEY = `${NS} ${key}`
  return {
    get: () => parseJson<Immutable<T>>(String(ls.getItem(KEY))),
    set: (value: Immutable<T>) => { ls.set(KEY, JSON.stringify(value)) },
  }
}

export function createLocalStorageItem<T>(
  key: string,
  methods: {
    readonly parse: (text: string) => T
    readonly stringify?: (value: T) => string
  }
) {
  const KEY = `${NS} ${key}`
  return {
    get: () => methods.parse(String(ls.getItem(KEY))),
    set: (value: T) => {
      const text = methods.stringify ? methods.stringify(value) : JSON.stringify(value)
      ls.set(KEY, text)
    },
  }
}

export type Operator<T> = {
  readonly get: () => T
  readonly set: (value: T) => void
}

export function parseJson<T>(x: string): T | null | undefined | string {
  try {
    return JSON.parse(x)
  } catch (e) {
    return x
  }
}
