import * as React from "react";
import { observable, reaction, runInAction } from "mobx";
import { Observer, useLocalStore } from "mobx-react";
import Axios from "axios";
import { UploadChangeParam } from "antd/lib/upload/interface";
import { Document } from "@chuyuan/poster-data-structure";
import { Upload, message } from "antd";
import { ExportDocumentFromFrame, ExtractResources } from "@chuyuan/poster-data-access-layer";
import { DynamicFontManager, DynamicFontHeader, DynamicFontGlyph, FontDataEncoded, decodeFontData } from "@chuyuan/poster-font-pack";
import { Disposers, EMPTY_ARRAY, Raf } from "@chuyuan/poster-utils";
import { PicturesChoose } from "@chuyuan/ui";
import {
  EditorAPI,
  ImageSelectorData,
  ImageSelectorProps,
  EditorState,
  popup,
  Modal,
  useModule,
  GlobalContextModuleToken,
  GlobalContext,
  GraphicEditor,
  Select,
  Button,
  Gap,
  Row,
  EditorSlotModuleToken,
  i18n,
  EditorContext,
} from "./../../../libs/poster-component-editor/src";
// import { Raf } from "../src/utils/raf";
import { createDataStoreFromDocument } from "@chuyuan/poster-data-access-layer";
import { parse } from "querystring";
import Service from "./service";
import { HTTP_URL } from "./http";

const getPageQuery = () => {
  const { href } = window.location;
  const qsIndex = href.indexOf("?");
  const sharpIndex = href.indexOf("#");

  if (qsIndex !== -1) {
    if (qsIndex > sharpIndex) {
      return parse(href.split("?")[1]);
    }

    return parse(href.slice(qsIndex + 1, sharpIndex));
  }

  return {};
};

class AppState {
  readonly disposers = new Disposers();
  token: string | string[] | undefined;
  componentId: string | string[] | undefined;

  visible = false;

  init = async (editor: EditorState) => {
    const locationQuery = getPageQuery();
    this.token = locationQuery["access_token"];
    this.componentId = locationQuery["id"] == "undefined" || locationQuery["id"] == undefined ? "" : locationQuery["id"];

    if (this.componentId != "") {
      let result = await Axios.get("https://demo.chuyuanai.com/file/component/json?componentId=" + this.componentId, {
        headers: { Authorization: this.token },
      });
      // console.log('result', result);
      // 从数据库获取到的 document 的json 数据
      let document: Document = JSON.parse(result.data.data.json);
      const { root } = createDataStoreFromDocument(document);
      // console.log("root", root);
      editor.setNewSession(root);
    } else {
      // let document: Document = {
      //   root: "c7eb2db3-525b-466e-a3f4-63df5676c371",
      //   entities: {
      //     frames: [
      //       {
      //         layout: { root: { width: 360, height: 640 } },
      //         children: ["65e8bad6-73f1-4772-a214-0970240eba4a"],
      //         kind: "frame",
      //         id: "c7eb2db3-525b-466e-a3f4-63df5676c371",
      //       },
      //     ],
      //     texts: [
      //       {
      //         content: {
      //           text: "添加主标题",
      //           fontSize: 127,
      //           advancedEffects: {
      //             selfLayer: {
      //               fill: { fill: "#ffffff", fillOpacity: 1 },
      //               stroke: {
      //                 stroke: {
      //                   type: "gradient",
      //                   method: { type: "linear", angle: 358.451842301022 },
      //                   stops: [
      //                     { color: "rgb(213, 61, 208)", opacity: 0.75, offset: 0.2318181818181818 },
      //                     { color: "rgb(25, 113, 227)", opacity: 0.75, offset: 0.8863636363636364 },
      //                   ],
      //                 },
      //                 strokeWidth: 0.04,
      //                 strokeOpacity: 1,
      //               },
      //               dropShadow: { shadowFill: "#9e17d4", shadowOpacity: 1, shadowBlurRadius: 0.02, shadowOffset: 0 },
      //             },
      //             layers: [],
      //           },
      //           backgrounds: {
      //             region: {
      //               shape: { type: "rectangle", borderColor: "#316bac", borderWidth: 0, borderColorOpacity: 1 },
      //               color: "#fafafa",
      //               colorOpacity: 0,
      //             },
      //           },
      //           styles: { wrap: "any" },
      //           randomseed: "1693455892115",
      //         },
      //         layout: {
      //           absolute: {
      //             kind: "constraint",
      //             horizontal: { kind: "fixed", dimension: { unit: "px", value: 360 }, offset: { unit: "px", value: 0 }, alignment: "start" },
      //             vertical: {
      //               kind: "fixed",
      //               dimension: { unit: "px", value: 111.7617866004963 },
      //               offset: { unit: "px", value: 160 },
      //               alignment: "start",
      //             },
      //           },
      //           flexItem: { kind: "static", width: { kind: "stretch" }, height: { kind: "stretch" } },
      //         },
      //         kind: "text",
      //         id: "65e8bad6-73f1-4772-a214-0970240eba4a",
      //       },
      //     ],
      //     images: [],
      //   },
      //   resources: { nlp: [{ text: "添加主标题", markings: [0, 2, 0, 1, 0, 2] }], images: [] },
      // };
      let document = {};
      const { root } = createDataStoreFromDocument(document);
      // console.log("root", root);
      editor.setNewSession(root);
      console.log("editor", editor);
    }

    Axios.interceptors.request.use(
      (config: any) => {
        // console.log("interceptors", { ...config });
        if (config.method === "get") {
          //  给data赋值以绕过if判断
          config.data = true;
        }
        config.headers["Authorization"] = this.token;
        return config;
      },
      (err) => Promise.reject(err)
    );

    // console.log(editor)

    {
      const raf = new Raf();
      const dispose = reaction(
        () => {
          const resources = new ExtractResources();
          resources.add(editor.session.root);
          return resources;
        },
        (resources) => {
          raf.push(async () => {
            try {
              await Promise.all([
                Promise.all(
                  Array.from(resources.nlpResources).map(async (text) => {
                    const { data } = (await Axios.post<{ data: number[] }>(HTTP_URL.NODE_SERVER + `/openApi/nlp`, { text })).data;
                    editor.session.ds.wordSplitResources.resolve(text, data);
                  })
                ),
                Promise.all(
                  Array.from(resources.fontDataResources).map(async (name) => {
                    const encoded = (await Axios.get<FontDataEncoded>(HTTP_URL.NODE_SERVER + `/static/fonts/${name}/font-width.json`)).data;
                    editor.session.ds.fontDataResources.resolve(name, decodeFontData(encoded));
                  })
                ),
              ]);
            } catch (e) {
              console.error(e);
            }
          });
        },
        { fireImmediately: true }
      );

      this.disposers.add("extract resources", () => {
        dispose();
        raf.clear();
      });
    }

    let fontResult = await Axios.get("http://139.155.147.224/generator/api-v2.0/font_info_list");
    const fontData = fontResult.data.data.result;

    // console.log('fontData', fontData);

    this.disposers.add(
      "fontSetList",
      editor.resources.fontSetList.events.subscribe("request", () => {
        editor.resources.fontSetList.resolve(fontData);
      })
    );

    editor.resources.dynamicFontManager.resolve(
      new DynamicFontManager({
        api: {
          requestHeader: async (id) => (await Axios.get<DynamicFontHeader>(HTTP_URL.NODE_SERVER + `/static/fonts/${id}/header.json`)).data,
          requestGlyph: async (id, unicode) =>
            (await Axios.get<DynamicFontGlyph>(HTTP_URL.NODE_SERVER + `/static/fonts/${id}/glyphs/${unicode}.json`)).data,
        },
      })
    );
  };

  onUploadSearch = async (info: UploadChangeParam) => {
    // const { fileList, file } = info;
    // console.log("onUploadSearch", {
    //   fileList,
    //   file
    // });
  };

  callFunction = (img: HTMLImageElement) => {
    return new Promise((resolve, reject) => {
      img.onload = () => {
        resolve(true);
      };
    });
  };

  // Base64 转 Blob/File
  dataURLtoBlob = (dataurl: string) => {
    let bstr = window.atob(dataurl);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], "图片.png", { type: "image/png" });
    // return new Blob([u8arr], { type: 'image/png' });
  };

  getImgData = async (imgPath: string) => {
    const image = new Image();
    image.setAttribute("crossOrigin", "anonymous");
    image.src = imgPath;
    await this.callFunction(image);
    return image;
  };

  /**上传图片到腾讯云**/
  uploadFileToTencentClound = async (file: any) => {
    const that = this;

    let result = await Axios.get("https://demo.chuyuanai.com/file/cos/sts", {
      // headers: { 'Authorization': 'eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2lkIjozLCJ1c2VyX2tleSI6IjZmZjBlOGY4LWQxMzItNGU3Ny1iMDJjLWJhZTJkYzc0NDExMiIsInVzZXJuYW1lIjoidGVzdDEifQ.GMQcYD2rN2GtY8J7na0vHagniPdqpMywTXh6VEdD-wfA31-9nXymSW7rlDUnp-X1YXYUoR9L2CG1qYQiBN9RJQ' },
    });
    let credentials = result.data.data;

    // 引入cos和uuid包 因为我包安装的不太对用import提示没有这个包只能用require
    const COS = require("cos-js-sdk-v5");
    // 请求用到的参数
    var Bucket = "resource-1259607807";
    var Region = "ap-chengdu";

    let cosJson = {
      SecretId: credentials.tmpSecretId, // 文档是下面两个，实际配置报错，查看源代码是这两个
      SecretKey: credentials.tmpSecretKey,
      SecurityToken: credentials.sessionToken,
      // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
      StartTime: credentials.dateTime, // 时间戳，单位秒，如：1580000000
      ExpiredTime: credentials.dateTime, // 时间戳，单位秒，如：1580000000
    };

    let cos = new COS(cosJson);

    let fileName = new Date().getTime() + file.name;

    return new Promise((resolve) => {
      cos.uploadFile({
        Bucket: Bucket /* 必须 */,
        Region: Region /* 存储桶所在地域，必须字段 */,
        Key: `/upload_resource/single_image/${fileName}` /* 必须 相当于在桶里新建 末尾带/表示创建文件夹格式(/后面有name表示在文件夹下创建名字为name 的文件) 不带仅文件 */,
        StorageClass: "STANDARD",
        Body: file, // 上传文件对象
        onFileFinish: async function (err: any, data: any, options: any) {
          /* 非必须 */
          const image = await that.getImgData(`https://${data.Location}`);
          let json = {
            url: `https://${data.Location}`,
            name: `${fileName}`,
            type: file.type,
            size: file.size,
            dimension: { width: image.width, height: image.height },
          };
          resolve(json);
        },
      });
    });
  };

  /**@name 本地上传 */
  customRequest = async (callback: any, option: any) => {
    const { filename, file } = option;
    if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/vnd.adobe.photoshop") {
      const formData = new FormData();
      formData.append(filename, file);
      this.uploadFileToTencentClound(file).then((response) => {
        callback(response);
      });
    } else {
      return message.error("仅支持上传png jpg jpeg格式");
    }
  };

  api: EditorAPI = {
    callImageSelector: (props: ImageSelectorProps) => this.callImageSelector(props),
    myMaterialList: (props: ImageSelectorProps) => this.myMaterialList(props),
    resourceMaterialList: (props: any) => this.resourceMaterialList(props),
    pitTypeList: (props: any) => this.pitTypeList(props),
    callMaterialSelector: (props: any) => this.callMaterialSelector(props),
    labelList: (props: any) => this.labelList(props),
    labelChildrenList: (props: any) => this.labelChildrenList(props),
  };

  demo!: {
    reference: 1;
    subtitle: {
      min: 0.45;
      max: 0.7;
    };
  };

  ctx?: GlobalContext;

  private pitTypeList(props?: any) {
    const json = [
      {
        label: "直播",
        options: [
          { label: "标题", value: "fieldTitle" },
          { label: "副标题", value: "fieldSubTitle" },
          { label: "销售信息", value: "fieldMarketInfo" },
          { label: "内文", value: "fieldInnerText" },
          { label: "项目logo", value: "fieldProjectLogo" },
          { label: "开发商logo", value: "fieldDevelopersLogoUrl" },
          { label: "项目二维码", value: "fieldProjectQrcodeUrl" },
          { label: "电话号码", value: "fieldPhoneNumber" },
          { label: "地址", value: "fieldAddress" },
          { label: "免责条款", value: "fieldDisclaimer" },
          { label: "备案号", value: "fieldIcpNumber" },
          { label: "预售证号", value: "fieldPreSaleNumber" },
          { label: "身份", value: "fieldPersonIdentity" },
          { label: "姓名", value: "fieldPersonName" },
          { label: "人物照片", value: "fieldPersonPhotoUrl" },
          { label: "邀约文字", value: "fieldInvitationDesc" },
          { label: "直播二维码", value: "fieldLivingQrcodeUrl" },
          { label: "直播时间", value: "fieldLivingDate" },
          { label: "直播开始时间", value: "fieldStartTime" },
          { label: "直播结束时间", value: "fieldEndTime" },
          { label: "福利描述", value: "fieldWelfareDes" },
          { label: "福利图片", value: "fieldWelfarePhotoUrl" },
        ],
      },
    ];
    // props.onData(json);
    return json;
  }

  private async labelList(props: any) {
    const { token } = props;
    if (!token) return;
    const parentId = props.parentId || 0;
    let result = await Axios.get("https://demo.chuyuanai.com/file/labelGroup/baseListTreeNode?parentId=" + parentId, {
      headers: { Authorization: token },
    });
    return result.data.data;
  }

  private async labelChildrenList(props: any) {
    //

    const { token } = props;
    if (!token) return;
    const groupId = props.groupId || 1;
    const labelName = props.labelName || "";
    let result = await Axios.post(
      "https://demo.chuyuanai.com/file/label/list?",
      {
        groupId,
        labelName,
      },
      {
        headers: { Authorization: token },
      }
    );
    return result.data.data;
  }

  private async myMaterialList(props: any) {
    const { pageNum, pageSize } = props;
    const userId = Service.getUserId();
    let data = {
      pageNum,
      pageSize,
      endSize: 0,
      foldId: 0,
      formatList: [],
      name: "",
      sizeList: [],
      startSize: 0,
      orderByColumn: "create_time",
      isAsc: "desc", // asc/desc
      typeList: ["personalUpload"],
      userId: userId,
      viewSize: "500",
    };
    const result = await Service.fileList(data);
    return result.data;
  }

  private async resourceMaterialList(props: any) {
    const { pageNum, pageSize } = props;

    let data = {
      pageNum,
      pageSize,
      viewSize: 800,
    };
    const result = await Service.srcLibPage(data);
    return result.data;
  }

  private async callMaterialSelector(props: any) {
    const { fileUrl, fileName } = props;
    const image = await this.getImgData(fileUrl);
    let json: any = {
      url: fileUrl,
      name: fileName,
      // dimension: { width: e[0]['width'], height: e[0]['height'] },
      dimension: { width: image["width"], height: image["height"] },
    };

    // console.log('callMaterialSelector', JSON.stringify(json));
    props.onData(json);
  }

  private async callImageSelector(props: ImageSelectorProps) {
    const list: ReadonlyArray<{
      readonly title: string;
      readonly data: ImageSelectorData;
    }> = [
      {
        title: "本地上传",
        data: {
          url: "/data/images/2021924-112041.png",
          name: "2021924-112041",
          dimension: { width: 863, height: 1294 },
        },
      },
      {
        title: "从资源中心导入",
        data: {
          url: "/data/images/2021925-161622.png",
          name: "2021925-161622",
          dimension: { width: 859, height: 1294 },
        },
      },
    ];
    const state = observable({ value: 0 });
    if (!this.ctx) return;

    let visible = 1;

    const onChoosePicture = async (e: any) => {
      const that = this;
      if (e.length > 0) {
        const image = await that.getImgData(e[0]["fileUrl"]);
        let json: any = {
          url: e[0]["fileUrl"],
          name: e[0]["fileName"],
          // dimension: { width: e[0]['width'], height: e[0]['height'] },
          dimension: { width: image["width"], height: image["height"] },
        };

        // console.log('onChoosePicture', JSON.stringify(json));
        props.onData(json);
      }
    };

    const onCancel = (e: any) => {
      console.log("onCancel");
    };

    const showPictureChoose = () => {
      if (!this.ctx) return;
      popup({
        ctx: this.ctx,
        render: ({ onClose }) => {
          return (
            <>
              <Modal
                title=""
                onClose={() => {
                  onClose();
                  props.onClose();
                }}
                style={{ width: 800, maxWidth: "80vw", maxHeight: "70vh" }}
              >
                <Observer
                  render={() => (
                    <>
                      <PicturesChoose
                        maxCount={1}
                        onCancel={(e: any) => {
                          onClose();
                          props.onClose();
                          onCancel(e);
                        }}
                        onOk={(e: any) => {
                          onChoosePicture(e);
                          onClose();
                          props.onClose();
                        }}
                        visible={false}
                      ></PicturesChoose>
                    </>
                  )}
                />
              </Modal>
            </>
          );
        },
      });
    };

    popup({
      ctx: this.ctx,
      render: ({ onClose }) => {
        return (
          <Modal
            title="选择图片"
            onClose={() => {
              onClose();
              props.onClose();
            }}
            style={{ width: 400, maxWidth: "50vw", maxHeight: "50vh" }}
          >
            <Observer
              render={() => (
                <>
                  <Upload
                    showUploadList={false}
                    headers={{ Authorization: "123456" }}
                    action={`http://1.14.15.246:8080/file/upload2Cos`}
                    onChange={this.onUploadSearch}
                    customRequest={this.customRequest.bind(this, (json: any) => {
                      onClose();
                      props.onData(json);
                    })}
                  >
                    <div style={{ padding: "10px 15px", cursor: "pointer" }}>本地上传</div>
                  </Upload>
                  <div
                    style={{ padding: "10px 15px", cursor: "pointer" }}
                    onClick={() => {
                      onClose();
                      showPictureChoose();
                    }}
                  >
                    从资源中心导入
                  </div>
                  {/* <Select
                    style={{ width: '100%' }}
                    value={state.value}
                    onChange={v => state.value = v}
                    options={list.map((x, i) => ({ value: i, label: () => x.title }))}
                  /> */}
                </>
              )}
            />
            {/* <Gap />
            <Row>
              <Button
                color="primary"
                space="stretch"
                onClick={() => {
                  onClose()
                  props.onData(list[state.value].data)
                }}
                children="确认"
              />
            </Row> */}
          </Modal>
        );
      },
    });
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.disposers.clear();
  }

  readonly Children = React.memo(() => {
    const editor = React.useContext(EditorContext);
    const ctx = useModule(GlobalContextModuleToken);
    const slot = useModule(EditorSlotModuleToken);
    // const state = useLocalStore((p) => new CanvasState(p), { editor });
    // console.log("======================", { ctx, editor, canvas: editor.session.ui.canvasRef });
    const that = this;
    this.ctx = ctx;
    return (
      <>
        {!slot ? null : (
          <slot.navBarRight.Portal priority={0}>
            <Button
              type="text"
              style={{ padding: "0 8px" }}
              onClick={() =>
                runInAction(async () => {
                  console.log(editor.componentName);

                  const ruleData = localStorage.getItem("ruleList") || [];
                  let data = new ExportDocumentFromFrame(editor.session.root).result;

                  const images: any = [];
                  data.entities &&
                    data.entities.images &&
                    data.entities.images.map((img) => {
                      images.push(img.content?.url);
                    });

                  // data.root = data.entities.frames[0]['id'];
                  console.log("ExportDocumentFromFrame", { data, ruleData, images });

                  // return;
                  // 调用 generateComponentPng 生成预览图
                  // const url = `https://app-1259607807.cos.ap-chengdu.myqcloud.com/editor/index.html?token=${that.token}&id=${that.componentId}&type=&read=1`;
                  let result = await Axios.post(HTTP_URL.NODE_SERVER + "/openApi/preview", {
                    json_model: JSON.stringify(data),
                    images: images.join(";"),
                  });
                  // console.log("result", result);
                  // return;
                  if (result.status != 200) {
                    return message.error("生成预览图失败！");
                  }
                  const preview = result.data.result;

                  this.uploadFileToTencentClound(this.dataURLtoBlob(preview)).then(async (response: any) => {
                    // console.log("response", response);
                    // 调用接口保存数据
                    let result = await Axios.post(
                      "https://demo.chuyuanai.com/file/component/save",
                      {
                        componentName: editor.componentName || "测试_" + new Date().getTime(),
                        componentId: that.componentId,
                        json: JSON.stringify(data),
                        ruleJsons: ruleData,
                        name: response.name,
                        type: response.type,
                        size: response.size,
                        url: response.url,
                      },
                      {
                        headers: { Authorization: this.token },
                      }
                    );
                    if (result.data.code != 200) {
                      return message.error("保存失败：" + result.data.msg);
                    }
                    console.log("result", {
                      that,
                      id: result.data.data.id,
                    });
                    message.success(that.componentId ? "更新成功，请到组件管理刷新后查看" : "保存成功，请到组件管理刷新后查看");
                    that.componentId = result.data.data.id;
                  });
                })
              }
              children={<保存 />}
            />
            <Button
              type="text"
              style={{ padding: "0 8px" }}
              onClick={() =>
                runInAction(async () => {
                  const ruleData = localStorage.getItem("ruleList") || [];
                  let data = new ExportDocumentFromFrame(editor.session.root).result;
                  // data.root = data.entities.frames[0]['id'];
                  console.log("ruleData", JSON.stringify(ruleData));
                  console.log("data", JSON.stringify(data));

                  await Axios.post(HTTP_URL.NODE_SERVER + "/openApi/debug", {
                    componentName: editor.componentName || "测试_" + new Date().getTime(),
                    componentId: that.componentId,
                    json: JSON.stringify(data),
                  });
                })
              }
              children={<查询数据 />}
            />
            {/* <Button
              type="primary"
              color="primary"
              style={{ marginRight: 40, height: 32 }}
              onClick={() =>
                runInAction(async () => {
                  let data = new ExportDocumentFromFrame(editor.session.root)
                    .result;
                  // data.root = data.entities.frames[0]['id'];
                  console.log(
                    "ExportDocumentFromFrame",
                    data,
                    JSON.stringify(data)
                  );
                })
              }
              children={<导出 />}
            /> */}
          </slot.navBarRight.Portal>
        )}
      </>
    );
  });

  readonly onChoosePicture = () => {};

  readonly onCancel = () => {};
}

export const App = React.memo(() => {
  const appStateRef = React.useRef<AppState>();
  const appState = appStateRef.current || (appStateRef.current = new AppState());
  const [readOnly, setReadOnly] = React.useState(false);
  const [init, setInit] = React.useState(false);

  React.useEffect(() => {
    const locationQuery = getPageQuery();
    setReadOnly(locationQuery["read"] == "1" ? true : false);
    setInit(true);
    appState.componentDidMount();
    return () => appState.componentWillUnmount();
  }, EMPTY_ARRAY);

  // console.log('readOnly', readOnly);

  // console.log("WorkspaceState-init", { ...process });
  return <>{init && <GraphicEditor renderOnly={readOnly} init={appState.init} api={appState.api} children={<appState.Children />} />}</>;
});

App.displayName = "App";

const 保存 = i18n({
  en: () => "Save",
  zh: () => "保存",
});

const 查询数据 = i18n({
  en: () => "SaveData",
  zh: () => "查询数据（开发调试）",
});

const 导出 = i18n({
  en: () => "Export",
  zh: () => "导出",
});

// curl --location --request POST 'https://demo.chuyuanai.com/auth/login' \
// --header 'Accept: */*' \
// --header 'Accept-Language: zh-CN,zh;q=0.9' \
// --header 'Connection: keep-alive' \
// --header 'Content-Type: application/json;charset=UTF-8' \
// --header 'Origin: https://www.chuyuanai.com' \
// --header 'Referer: https://www.chuyuanai.com/' \
// --header 'Sec-Fetch-Dest: empty' \
// --header 'Sec-Fetch-Mode: cors' \
// --header 'Sec-Fetch-Site: same-site' \
// --header 'User-Agent: Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36' \
// --header 'sec-ch-ua: "Google Chrome";v="107", "Chromium";v="107", "Not=A?Brand";v="24"' \
// --header 'sec-ch-ua-mobile: ?0' \
// --header 'sec-ch-ua-platform: "Windows"' \
// --data-raw '{
//     "username": "test1",
//     "password": "123456"
// }'
