// tslint:disable max-line-length

import * as React from "react";
import { computed, makeObservable, observable, runInAction } from "mobx";
import { useObserver } from "mobx-react";

import {
  formatSupportedTypes,
  getCssBackground,
  CommonFillingPaintValueProps,
  CommonFillingPaintStateProps,
  FillingPaintValueLong,
  FillingPaintValueCircle,
  CommonState,
  PureColorPickerContent,
  LinearGradientPickerContent,
  RadialGradientPickerContent,
  ImageContentValue,
  OpacityFieldLong,
  PickerContent,
  ImageScaleField,
} from "../filling-paint-common";
import type {
  FillingPaint,
  FillingPaintImageSizingType,
  FillingPaintType,
} from "./types";
import { useLocalStore } from "../../../utils/mobx-react-hooks";
import { InputNumber, InputNumberRef } from "../../ui-components/input-number";
import { Fields, FieldsOptions } from "./state";
import { i18n, useLocale } from "../../../utils/i18n";
import { DuplexFieldLike, MapDuplexField } from "../../../utils/multi-value";
import {
  getInputProps,
  getCheckboxProps,
} from "../../../utils/react-multi-value";
import { Row, TitleText } from "../../ui-components/section";
import { Select, SelectRef, SelectItemProps } from "../../ui-components/select";
import { useShallowEqualCache } from "../../../utils/react-hooks";
import { Checkbox } from "../../ui-components/checkbox";
import { EditorContext } from "../../helpers/react-context";

export type FillingPaintPreset = "circle" | "long";

export interface FillingPaintValueProps
  extends CommonFillingPaintValueProps<FillingPaint> {
  readonly options?: FieldsOptions;
}

export interface FillingPaintStateProps
  extends CommonFillingPaintStateProps<FillingPaint> {
  readonly options?: FieldsOptions;
}

export class FillingPaintState implements CommonState {
  block?: HTMLDivElement | null;
  outline?: HTMLDivElement | null;
  color?: HTMLInputElement | null;
  opacity?: InputNumberRef | null;

  constructor(readonly props: FillingPaintStateProps) {
    makeObservable(this);
  }

  @observable.ref
  isPickerVisible = false;

  @computed
  get supportedTypes() {
    return formatSupportedTypes.call(this);
  }

  @computed
  get fields() {
    const { field, options } = this.props;
    return new Fields(field, options);
  }

  @computed
  get cssBackground() {
    return getCssBackground(this.fields);
  }

  renderPickerContent = (type?: FillingPaintType | "empty") => {
    return type === "color" ? (
      <PureColorPickerContent state={this} />
    ) : type === "linear gradient" ? (
      <LinearGradientPickerContent state={this} />
    ) : type === "radial gradient" ? (
      <RadialGradientPickerContent state={this} />
    ) : type === "image" ? (
      <ImagePickerContent state={this} />
    ) : null;
  };
}

export const FillingPaintValue = React.memo(
  React.forwardRef<FillingPaintState, FillingPaintValueProps>((props, ref) => {
    const preset = props.preset || "long";

    const supportedTypes = useShallowEqualCache(props.supportedTypes);

    const state = useLocalStore((p) => new FillingPaintState(p), {
      ...props,
      preset,
      supportedTypes,
    });

    React.useImperativeHandle(ref, () => state);

    if (preset === "circle") {
      return <FillingPaintValueCircle state={state} />;
    }

    return <FillingPaintValueLong state={state} />;
  })
);
FillingPaintValue.displayName = "FillingPaintValue";

const ImagePickerContent = React.memo(
  (props: { readonly state: FillingPaintState }) => {
    const { state } = props;

    return useObserver(() => {
      const { images } = state.fields;
      const sizingValue = images.sizing.get();
      return (
        <PickerContent>
          <Row className="margin-y">
            <ImageContentValue field={images.image} />
          </Row>
          <Row className="margin-y">
            <ImageSizingValue field={images.sizing} />
          </Row>
          <ImageScaleField field={images.scale} />
          {!sizingValue || sizingValue === "stretch" ? null : (
            <FontSizeField field={images.fontSize} />
          )}
          {state.props.preset !== "circle" ? null : (
            <OpacityFieldLong field={images.opacity} />
          )}
        </PickerContent>
      );
    });
  }
);
ImagePickerContent.displayName = "ImagePickerContent";

const AllImageSizingTypeOptions: ReadonlyArray<
  SelectItemProps<FillingPaintImageSizingType>
> = [
  {
    value: "original",
    label: () => <原图 />,
  },
  {
    value: "stretch",
    label: () => <拉伸 />,
  },
  {
    value: "tile",
    label: () => <平铺 />,
  },
];

const ImageSizingValue = React.memo(
  React.forwardRef<
    SelectRef,
    {
      readonly field: DuplexFieldLike<FillingPaintImageSizingType>;
    }
  >((props, ref) => {
    const { field } = props;

    const { session } = React.useContext(EditorContext);
    const locale = useLocale();

    return useObserver(() => {
      const fieldProps = getInputProps(field, locale);
      return (
        <Select<FillingPaintImageSizingType>
          {...fieldProps}
          ref={ref}
          options={AllImageSizingTypeOptions}
          onChange={(newValue) =>
            runInAction(() => {
              if (!field.set(newValue).records.some(Boolean)) return;
              session.history.push({ name: "修改图片填充方式" });
            })
          }
        />
      );
    });
  })
);
ImageSizingValue.displayName = "ImageSizingValue";

const FontSizeField = React.memo(
  (props: { readonly field: DuplexFieldLike<number | undefined> }) => {
    const { field } = props;

    const toggleField = React.useMemo(
      () =>
        MapDuplexField.same(
          field,
          (x) => x !== undefined,
          (x) => (x ? 36 : undefined)
        ),
      [field]
    );

    const { session } = React.useContext(EditorContext);
    const locale = useLocale();

    return useObserver(() => {
      const toggleValue = toggleField.get();
      const checkboxProps = getCheckboxProps(toggleField);
      return (
        <>
          <Row className="margin-y pointer edged" as="label">
            <TitleText>
              <缩放跟随字号 />
            </TitleText>
            <div>
              <Checkbox
                {...checkboxProps}
                onChange={(newValue) =>
                  runInAction(() => {
                    if (!toggleField.set(newValue).records.some(Boolean))
                      return;
                    session.history.push({ name: "修改图片缩放跟随字号" });
                  })
                }
              />
            </div>
          </Row>
          {!toggleValue ? null : (
            <Row className="margin-y">
              <InputNumber
                {...getInputProps(field, locale)}
                min={0}
                step={1}
                prefix={字号.get(locale)}
                dragDirection="vertical"
                dragReverse
                onChange={(newValue) => runInAction(() => field.set(newValue))}
                onComplete={() =>
                  runInAction(() => {
                    session.history.push({ name: "修改图片缩放跟随字号" });
                  })
                }
              />
            </Row>
          )}
        </>
      );
    });
  }
);
FontSizeField.displayName = "FontSizeValue";

const 原图 = i18n({
  en: () => "Original Image",
  zh: () => "原图",
});

const 拉伸 = i18n({
  en: () => "fill",
  zh: () => "拉伸",
});

const 平铺 = i18n({
  en: () => "tile",
  zh: () => "平铺",
});

const 缩放跟随字号 = i18n({
  en: () => "Scale follows font size",
  zh: () => "缩放跟随字号",
});

const 字号 = i18n({
  en: () => "Size",
  zh: () => "字号",
});
