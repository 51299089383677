import * as React from "react";
import { useObserver } from "mobx-react";
import { runInAction } from "mobx";

import type { Text } from "@chuyuan/poster-data-access-layer";
import styled from "styled-components";
import { i18n, useLocale } from "../../utils/i18n";
import { Panel } from "../ui-components/panel";
import { useEqualSet } from "../../utils/react-hooks";
import { EditorContext } from "../helpers/react-context";
import { Fields } from "./state.ui";
import { Block, Row, Gap, TitleText, Square } from "../ui-components/section";
import { FillingPaintValue } from "../ui-components-stateful/filling-paint-text-spec";
import { IconBtn } from "../ui-components/icon-btn";
import { Dialog } from "../ui-components/dialog";
import { FontFamilyBlock } from "./font-family-block";
import { EditTextStylePanelContent } from "./edit-style-panel";
import { EditTextStyleIconsPanelContent } from "./edit-style-icons-panel";
import { CanvasPanel } from "../gdl-common/popup";
import type { DuplexFieldLike } from "../../utils/multi-value";
import { LineAlignmentValue } from "./line-alignment";
import { InputNumber } from "../ui-components/input-number";
import { getInputProps } from "../../utils/react-multi-value";
import {
  TextCaseBtn,
  TextLineAlignmentJustify,
  TextGapIconBtn,
  ArrowDown,
  TextInlineGap,
  TextLineGap,
  TextEffectIcon,
  ACharacter,
  PropertyDetails,
  TextDecorationIcon,
  TextLineWrapIconBtn,
  TextNotWrapIconBtn,
} from "../../../../poster-ui/icons/new-icons";
import Icon from "@ant-design/icons";
import { TextContentPanel } from "./content-panel";
import { TextBackgroundRegionPanel } from "./background-region-panel";
import { TextEffectsPanel } from "./font-effect-panel";

export const TextStylePanel = React.memo((props: { readonly targets: ReadonlySet<Text> }) => {
  return (
    <Panel title={<样式 />}>
      <TextStylePanelContent {...props} />
    </Panel>
  );
});
TextStylePanel.displayName = "TextStylePanel";

/**@name 文本样式-字体选择组件 */
export const TextFontFamliyPanelContent = React.memo((props: { readonly targets: ReadonlySet<Text> }) => {
  const fields = Fields.of(useEqualSet(props.targets));

  return useObserver(() => {
    return (
      <>
        <TextStyleItem>
          <FontFamilyBlock language="zh" field={fields.textStyle.fontFamilyZh} />
        </TextStyleItem>
      </>
    );
  });
});
TextFontFamliyPanelContent.displayName = "TextFontFamliyPanelContent";

/**@name 文本样式-字体颜色组件 */
export const TextFillPanelContent = React.memo((props: { readonly targets: ReadonlySet<Text> }) => {
  const fields = Fields.of(useEqualSet(props.targets));

  return useObserver(() => {
    return (
      <>
        <TextStyleFillItem>
          <FillingPaintValue field={fields.selfLayerFill} options={fields.selfLayerFillOptions} />
        </TextStyleFillItem>
      </>
    );
  });
});
TextFillPanelContent.displayName = "TextFillPanelContent";

/**@name 文本样式-字体排版组件 */
export const TextFontAlignmentPanelContent = React.memo((props: { readonly targets: ReadonlySet<Text> }) => {
  const fields = Fields.of(useEqualSet(props.targets));

  return useObserver(() => {
    return (
      <>
        <AlignmentDetailPanelBtn fields={fields} />
      </>
    );
  });
});
TextFontAlignmentPanelContent.displayName = "TextFontAlignmentPanelContent";

/**@name 文本样式-字体样式组件 */
export const TextFontStylePanelContent = React.memo((props: { readonly targets: ReadonlySet<Text> }) => {
  const fields = Fields.of(useEqualSet(props.targets));

  return useObserver(() => {
    return (
      <>
        <StyleDetailPanelBtn fields={fields} />
      </>
    );
  });
});
TextFontStylePanelContent.displayName = "TextFontStylePanelContent";

/**@name 文本样式-字体间距组件 */
export const TextFontGapPanelContent = React.memo((props: { readonly targets: ReadonlySet<Text> }) => {
  const fields = Fields.of(useEqualSet(props.targets));

  return useObserver(() => {
    return (
      <>
        <GapDetailPanelBtn fields={fields} />
      </>
    );
  });
});
TextFontGapPanelContent.displayName = "TextFontGapPanelContent";

/**@name 文本内容小组件 */
export const TextContentPanelContent = React.memo((props: { readonly targets: ReadonlySet<Text> }) => {
  // const [ref, setRef] = React.useState<HTMLSpanElement | null>(null);

  // const [display, setDisplay] = React.useState(false);
  return useObserver(() => {
    return (
      <>
        {/* <StyleDetailBtn>
          <Icon className="case-btn" component={TextareaIconBtn} onClick={() => setDisplay(true)} />
          <IconBtn ref={setRef} className="fold" component={ArrowDown} onClick={() => setDisplay(true)} />
        </StyleDetailBtn>
        {!(display && ref) ? null : (
          <CanvasPanel
            target={ref}
            onClose={() => setDisplay(false)}
            render={() => (
              <Dialog
                flexContent
                style={{
                  width: 260,
                  // maxHeight: 44,
                }}
                closable={false}
                children={
                  <>
                    <TextContentPanel targets={props.targets} />
                  </>
                }
              />
            )}
          />
        )} */}
        <TextContentPanel targets={props.targets} />
      </>
    );
  });
});
TextContentPanelContent.displayName = "TextContentPanelContent";

/**@name 文本装饰小组件 */
export const TextDecorationPanelContent = React.memo((props: { readonly targets: ReadonlySet<Text> }) => {
  const [ref, setRef] = React.useState<HTMLSpanElement | null>(null);

  const [display, setDisplay] = React.useState(false);
  return useObserver(() => {
    return (
      <>
        <StyleDetailBtn>
          <Icon className="case-btn" component={TextDecorationIcon} onClick={() => setDisplay(true)} />
          <IconBtn ref={setRef} className="fold" component={ArrowDown} onClick={() => setDisplay(true)} />
        </StyleDetailBtn>
        {!(display && ref) ? null : (
          <CanvasPanel
            target={ref}
            onClose={() => setDisplay(false)}
            render={() => (
              <Dialog
                flexContent
                style={{
                  width: 260,
                  // maxHeight: 44,
                }}
                closable={false}
                children={
                  <>
                    <TextBackgroundRegionPanel targets={props.targets} />
                  </>
                }
              />
            )}
          />
        )}
      </>
    );
  });
});
TextContentPanelContent.displayName = "TextContentPanelContent";

/**@name 文本是否可换行组件 */
export const TextCheckLineWrappingIcon = React.memo((props: { readonly targets: ReadonlySet<Text> }) => {
  const fields = Fields.of(useEqualSet(props.targets));

  return useObserver(() => {
    const isWrap = fields.wrap.get() === "any";
    // console.log("fields.wrap", fields.wrap.get());
    return (
      <>
        <StyleDetailBtn>
          <Icon
            className="case-btn"
            component={isWrap ? TextLineWrapIconBtn : TextNotWrapIconBtn}
            onClick={() => {
              fields.wrap.set(isWrap ? "no" : "any");
            }}
          />
        </StyleDetailBtn>
      </>
    );
  });
});
TextCheckLineWrappingIcon.displayName = "TextCheckLineWrappingIcon";

/**@name 文本特效小组件 */
export const TextEffectPanelContent = React.memo((props: { readonly targets: ReadonlySet<Text> }) => {
  const [ref, setRef] = React.useState<HTMLSpanElement | null>(null);

  const [display, setDisplay] = React.useState(false);
  return useObserver(() => {
    return (
      <>
        <StyleDetailBtn>
          <Icon className="case-btn" component={TextEffectIcon} onClick={() => setDisplay(true)} />
          <IconBtn ref={setRef} className="fold" component={ArrowDown} onClick={() => setDisplay(true)} />
        </StyleDetailBtn>
        {!(display && ref) ? null : (
          <CanvasPanel
            target={ref}
            onClose={() => setDisplay(false)}
            render={() => (
              <Dialog
                flexContent
                style={{
                  width: 260,
                  // maxHeight: 44,
                }}
                closable={false}
                children={
                  <>
                    <TextEffectsPanel targets={props.targets} />
                  </>
                }
              />
            )}
          />
        )}
      </>
    );
  });
});
TextEffectPanelContent.displayName = "TextEffectPanelContent";

/**@name 文本样式组件 */
export const TextStylePanelContent = React.memo((props: { readonly targets: ReadonlySet<Text> }) => {
  const fields = Fields.of(useEqualSet(props.targets));

  return useObserver(() => {
    return (
      <>
        <Block>
          <Row className="margin-y">
            <TitleText>
              <基础字样 />
            </TitleText>
          </Row>
          <Row className="margin-y">
            <Square>
              <DetailPanelBtn fields={fields} />
            </Square>
          </Row>
          <Row className="margin-y">
            <Square>
              <ACharacter />
            </Square>
            <Gap />
            <FillingPaintValue field={fields.selfLayerFill} options={fields.selfLayerFillOptions} />
          </Row>
        </Block>
      </>
    );
  });
});
TextStylePanelContent.displayName = "TextStylePanelContent";

const DetailPanelBtn = React.memo((props: { readonly fields: Fields }) => {
  const { fields } = props;

  const [ref, setRef] = React.useState<HTMLSpanElement | null>(null);

  const [display, setDisplay] = React.useState(false);

  return useObserver(() => {
    return (
      <>
        <IconBtn style={{ color: "#606060" }} ref={setRef} component={PropertyDetails} onClick={() => setDisplay(true)} />
        {!(display && ref) ? null : (
          <CanvasPanel
            target={ref}
            onClose={() => setDisplay(false)}
            render={({ onClose }) => (
              <Dialog
                flexContent
                style={{
                  width: 240,
                  maxHeight: "min(640px, 80vh)",
                }}
                closable={false}
                title={<编辑字样 />}
                children={<EditTextStylePanelContent onClose={onClose} fields={fields.textStyle} />}
              />
            )}
          />
        )}
      </>
    );
  });
});
DetailPanelBtn.displayName = "DetailPanelBtn";

const StyleDetailPanelBtn = React.memo((props: { readonly fields: Fields }) => {
  const { fields } = props;

  const [ref, setRef] = React.useState<HTMLSpanElement | null>(null);

  const [display, setDisplay] = React.useState(false);

  return useObserver(() => {
    return (
      <>
        <StyleDetailBtn>
          <Icon className="case-btn" component={TextCaseBtn} onClick={() => {}} />
          <IconBtn ref={setRef} className="fold" component={ArrowDown} onClick={() => setDisplay(true)} />
        </StyleDetailBtn>
        {!(display && ref) ? null : (
          <CanvasPanel
            target={ref}
            onClose={() => setDisplay(false)}
            render={({ onClose }) => (
              <Dialog
                flexContent
                style={{
                  width: 160,
                  maxHeight: 44,
                }}
                closable={false}
                children={<EditTextStyleIconsPanelContent onClose={onClose} fields={fields.textStyle} />}
              />
            )}
          />
        )}
      </>
    );
  });
});
StyleDetailPanelBtn.displayName = "StyleDetailPanelBtn";

// /**@name 大小写枚举 */
// const TextTransformOptions: ReadonlyArray<RadioOption<TextSpec.TextStyleTextTransformType>> = [{
//   value: 'none',
//   title: () => '-',
// }, {
//   value: 'lowercase',
//   title: () => 'aa',
// }, {
//   value: 'capitalize',
//   title: () => 'Aa',
// }, {
//   value: 'uppercase',
//   title: () => 'AA',
// }]

// /**@name 文字上划线\删除线\下划线枚举 */
// const TextDecorationOptions: ReadonlyArray<RadioOption<TextDecorationType>> = [{
//   value: 'none',
//   title: () => '-',
// }, {
//   value: 'overline',
//   title: () => '',
// }, {
//   value: 'line-through',
//   title: () => '',
// }, {
//   value: 'underline',
//   title: () => '',
// }]

const GapDetailPanelBtn = React.memo((props: { readonly fields: Fields }) => {
  const { fields } = props;

  const [ref, setRef] = React.useState<HTMLSpanElement | null>(null);

  const [display, setDisplay] = React.useState(false);

  return useObserver(() => {
    return (
      <>
        <StyleDetailBtn>
          <Icon className="case-btn" component={TextGapIconBtn} onClick={() => {}} />
          <IconBtn ref={setRef} className="fold" component={ArrowDown} onClick={() => setDisplay(true)} />
        </StyleDetailBtn>
        {!(display && ref) ? null : (
          <CanvasPanel
            target={ref}
            onClose={() => setDisplay(false)}
            render={() => (
              <Dialog
                flexContent
                style={{
                  width: 260,
                  // maxHeight: 44,
                }}
                closable={false}
                children={
                  <>
                    <Row className="margin-y">
                      <Square>
                        <TextInlineGap />
                      </Square>
                      <InlineGapValue field={fields.textStyle.inlineGap} />
                    </Row>
                    <Row className="margin-y">
                      <Square>
                        <TextLineGap />
                      </Square>
                      <LineGapValue field={fields.textStyle.lineGap} />
                    </Row>
                  </>
                }
              />
            )}
          />
        )}
      </>
    );
  });
});
GapDetailPanelBtn.displayName = "GapDetailPanelBtn";

const InlineGapValue = React.memo((props: { readonly field: DuplexFieldLike<number> }) => {
  const { field } = props;

  const editor = React.useContext(EditorContext);
  const locale = useLocale();

  return useObserver(() => {
    const multiValueProps = getInputProps(field, locale);
    return (
      <InputNumber
        {...multiValueProps}
        step={0.01}
        onChange={(newValue) => runInAction(() => field.set(newValue))}
        onComplete={() =>
          runInAction(() => {
            editor.session.history.push({ name: "修改字间距" });
          })
        }
      />
    );
  });
});
InlineGapValue.displayName = "InlineGapValue";

const LineGapValue = React.memo((props: { readonly field: DuplexFieldLike<number> }) => {
  const { field } = props;

  const editor = React.useContext(EditorContext);
  const locale = useLocale();

  return useObserver(() => {
    const multiValueProps = getInputProps(field, locale);
    return (
      <InputNumber
        {...multiValueProps}
        step={0.01}
        onChange={(newValue) => runInAction(() => field.set(newValue))}
        onComplete={() =>
          runInAction(() => {
            editor.session.history.push({ name: "修改行间距" });
          })
        }
      />
    );
  });
});
LineGapValue.displayName = "LineGapValue";

const AlignmentDetailPanelBtn = React.memo((props: { readonly fields: Fields }) => {
  const { fields } = props;

  const [ref, setRef] = React.useState<HTMLSpanElement | null>(null);

  const [display, setDisplay] = React.useState(false);

  const textDirection = fields.textDirection.get() || "horizontal";

  return useObserver(() => {
    return (
      <>
        <StyleDetailBtn>
          <Icon className="case-btn" component={TextLineAlignmentJustify} onClick={() => {}} />
          <IconBtn ref={setRef} className="fold" component={ArrowDown} onClick={() => setDisplay(true)} />
        </StyleDetailBtn>
        {!(display && ref) ? null : (
          <CanvasPanel
            target={ref}
            onClose={() => setDisplay(false)}
            render={() => (
              <Dialog
                flexContent
                style={{
                  width: 260,
                  // maxHeight: 44,
                }}
                closable={false}
                children={<LineAlignmentValue textDirection={textDirection} field={fields.lineAlignment} />}
              />
            )}
          />
        )}
      </>
    );
  });
});
AlignmentDetailPanelBtn.displayName = "AlignmentDetailPanelBtn";

const TextStyleItem = styled.div`
  width: 150px;
  display: flex;
`;

const StyleDetailBtn = styled.div`
  display: flex;
  align-items: center;
  .case-btn {
    font-size: 24px;
  }
`;

const TextStyleFillItem = styled.div`
  width: 52px;
  display: flex;
`;

const 样式 = i18n({
  en: () => "Style",
  zh: () => "样式",
});

const 基础字样 = i18n({
  en: () => "Basic Style",
  zh: () => "基础字样",
});

const 编辑字样 = i18n({
  en: () => "Edit Style",
  zh: () => "编辑字样",
});
