import * as React from "react";
import styled from "styled-components";
import { Renderer } from "@chuyuan/poster-renderer-react";
import { EditorContext } from "../helpers/react-context";
import { RenderTypeMap } from "@chuyuan/poster-data-access-layer";
import {
  Disposers,
  EMPTY_ARRAY,
  mapIterableAsKeys,
  memorize,
  noop,
} from "@chuyuan/poster-utils";
import { EditorState } from "../editor-state/editor-state";
import { useLocalStore } from "../../utils/mobx-react-hooks";
import {
  action,
  autorun,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { pick } from "lodash";
import { MouseEventLike } from "../canvas-events";
import { createElementResizeDetectorOperator } from "../../utils/react-element-resize-detector";
import { preventDefault } from "../../utils/dom";
import { Raf } from "../../utils/raf";
import { WheelRecord } from "../editor-state/canvas-viewport";
import {
  isCanvasEventCursorData,
  iterateElementData,
} from "../canvas-events/canvas-event";
import { observer, useObserver } from "mobx-react";
import { useModule } from "../../utils/modulize";
import { EditorSlotModuleToken } from "./editor-slot-module";
import { renderReactPortalChildren } from "../../utils/react-portal-helpers";

type CanvasStateProps = {
  readonly editor: EditorState;
};

class CanvasState {
  private canvasRef: HTMLElement | null = null;
  private readonly disposers = new Disposers();

  @observable.ref
  isMouseDown = false;

  private lastPointerEvent?: {
    readonly kind:
      | "pointerDown"
      | "pointerMove"
      | "pointerUp"
      | "pointerContextMenu";
    readonly x: number;
    readonly y: number;
    readonly event: MouseEventLike;
  };

  constructor(readonly props: CanvasStateProps) {
    makeObservable(this);
  }

  @computed
  get cursor() {
    const { session } = this.props.editor;
    const target = session.canvasEvents.events.currentTarget;
    if (target) {
      for (const item of iterateElementData(target.data)) {
        if (isCanvasEventCursorData(item)) {
          return item.cursor;
        }
      }
    }
    return session.ui.canvasCursor.value || "auto";
  }

  componentDidMount() {
    this.disposers.add(
      "reapply pointer move",
      autorun(() => {
        // 重放鼠标移动事件
        const e = this.lastPointerEvent;
        if (e && e.kind === "pointerMove") {
          this.pointerEvents[e.kind](e.x, e.y, e.event);
        }
      })
    );
    this.disposers.add(
      "attach pointer move",
      this.props.editor.session.ui.triggerPointerEvent.createValue(
        this.pointerEvents
      ).dispose
    );
    this.disposers.override("paste event", () => {
      document.removeEventListener("paste", this.onWindowPaste, true);
    });
    document.addEventListener("paste", this.onWindowPaste, {
      capture: true,
      passive: false,
    });
  }

  componentWillUnmount() {
    if (this.canvasRef) {
      this.unregisterCanvasDOM(this.canvasRef);
    }
    this.erd.dispose();
    this.disposers.clear();
  }

  @memorize
  private get erd() {
    return createElementResizeDetectorOperator(this.onCanvasResize);
  }

  registerCanvasDOM(dom: HTMLElement) {
    dom.addEventListener("wheel", preventDefault, {
      passive: false,
      capture: true,
    });
    dom.addEventListener("wheel", this.onWheel, {
      passive: false,
      capture: false,
    });
  }

  unregisterCanvasDOM(dom: HTMLElement) {
    dom.removeEventListener("wheel", preventDefault, { capture: true });
    dom.removeEventListener("wheel", this.onWheel, { capture: false });
  }

  @action.bound
  onCanvasResize(element: HTMLElement) {
    const { viewport } = this.props.editor;
    viewport._touched = true;
    viewport.width = element.offsetWidth;
    viewport.height = element.offsetHeight;
  }

  @action.bound
  getCanvasRef(ref: HTMLElement | null) {
    if (ref === this.canvasRef) return;
    this.erd.replace(ref);
    if (this.canvasRef) {
      this.unregisterCanvasDOM(this.canvasRef);
    }
    this.canvasRef = ref;
    if (ref) {
      this.registerCanvasDOM(ref);
    }
  }

  private _wheelRaf = new Raf();
  private _wheelRecords: WheelRecord[] = [];

  @action.bound
  onWheel(e: WheelEvent) {
    e.preventDefault();
    e.stopPropagation();
    const { pageX, pageY } = e;
    const e1 = cloneMouseEventLike(e);
    const [x, y] = this.getCanvasXY(pageX, pageY);
    this._wheelRecords.push({
      mode: e.deltaMode,
      x,
      y,
      dx: e.deltaX,
      dy: e.deltaY,
      ctrl: e.ctrlKey,
    });
    this._wheelRaf.push(() =>
      runInAction(() => {
        const { viewport } = this.props.editor.session;
        const records = this._wheelRecords.splice(0);
        for (const record of records) {
          viewport.wheel(record);
        }
        this.pointerEvents.pointerMove(pageX, pageY, e1);
      })
    );
  }

  readonly pointerEvents = mapIterableAsKeys(
    ["pointerDown", "pointerMove", "pointerUp", "pointerContextMenu"] as const,
    (key) =>
      action(key, (pageX: number, pageY: number, e: MouseEventLike) => {
        // 写入上次事件用于重现
        this.lastPointerEvent = {
          kind: key,
          x: pageX,
          y: pageY,
          event: cloneMouseEventLike(e),
        };

        const { queue, events } = this.props.editor.session.canvasEvents;

        const { session } = this.props.editor;
        const [mouseX, mouseY] = this.getCanvasXY(pageX, pageY);
        const [x, y] = session.ui.getCanvasPointFromViewportPoint(
          mouseX,
          mouseY
        );
        events[key](queue.collide([x, y]), x, y, mouseX, mouseY, e);
      })
  );

  getCanvasXY(x: number, y: number) {
    if (this.canvasRef) {
      const { left, top } = this.canvasRef.getBoundingClientRect();
      x -= left;
      y -= top;
    }
    return [x, y] as const;
  }

  @action.bound
  onMouseDown(e: React.MouseEvent) {
    if (e.button !== 0) return;
    this.disposers.override("mouse move event", () => {
      window.removeEventListener("mousemove", this.onWindowMouseMove, true);
      window.removeEventListener("mouseup", this.onWindowMouseUp, true);
    });
    window.addEventListener("mousemove", this.onWindowMouseMove, true);
    window.addEventListener("mouseup", this.onWindowMouseUp, true);
    this.isMouseDown = true;
    this.pointerEvents.pointerDown(e.pageX, e.pageY, e);
  }

  @action.bound
  onMouseMove(e: React.MouseEvent) {
    if (this.isMouseDown) return;
    this.pointerEvents.pointerMove(e.pageX, e.pageY, e);
  }

  @action.bound
  private onWindowMouseMove(e: MouseEvent) {
    this.pointerEvents.pointerMove(e.pageX, e.pageY, e);
  }

  // 这里故意不用 action, 让 pointerUp 和 pointerMove 在两个不同的 action 中运行
  private onWindowMouseUp = (e: MouseEvent) => {
    this.disposers.dispose("mouse move event");
    const { pageX: x, pageY: y } = e;
    const e1 = cloneMouseEventLike(e);
    runInAction(() => {
      this.isMouseDown = false;
      this.pointerEvents.pointerUp(x, y, e);
    });
    this.pointerEvents.pointerMove(x, y, e1);
  };

  @action.bound
  onTouchStart(e: React.TouchEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.disposers.override("touch move event", () => {
      window.removeEventListener("touchmove", this.onWindowTouchMove, true);
      window.removeEventListener("touchend", this.onWindowTouchEnd, true);
    });
    window.addEventListener("touchmove", this.onWindowTouchMove, {
      capture: true,
      passive: false,
    });
    window.addEventListener("touchend", this.onWindowTouchEnd, true);
    const touch = e.touches[0];
    this.pointerEvents.pointerDown(touch.pageX, touch.pageY, e);
  }

  @action.bound
  private onWindowTouchMove(e: TouchEvent) {
    e.preventDefault();
    e.stopPropagation();
    const touch = e.touches[0];
    this.pointerEvents.pointerMove(touch.pageX, touch.pageY, e);
  }

  // 这里故意不用 action, 让 pointerUp 和 pointerMove 在两个不同的 action 中运行
  private onWindowTouchEnd = (e: TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    this.disposers.dispose("touch move event");
    const touch = e.changedTouches[0];
    const { pageX: x, pageY: y } = touch;
    const e1 = cloneMouseEventLike(e);
    this.pointerEvents.pointerUp(x, y, e);
    this.pointerEvents.pointerMove(x, y, e1);
  };

  @action.bound
  onContextMenuCapture(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.pointerEvents.pointerContextMenu(e.pageX, e.pageY, e);
  }

  @action.bound
  onPaste(e: React.ClipboardEvent) {
    this.props.editor.session.canvasEvents.queue.naive.paste?.(e.nativeEvent);
  }

  @action.bound
  private onWindowPaste(e: ClipboardEvent) {
    this.props.editor.session.canvasEvents.queue.naive.paste?.(e);
  }

  @action.bound
  onDrop(e: React.DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.props.editor.session.canvasEvents.queue.naive.drop?.(e.nativeEvent);
  }
}

function cloneMouseEventLike(e: MouseEventLike) {
  return {
    ...pick(e, ["altKey", "ctrlKey", "metaKey", "shiftKey"]),
    stopPropagation: noop,
    preventDefault: noop,
  };
}

export const Canvas = React.memo(() => {
  const editor = React.useContext(EditorContext);
  const state = useLocalStore((p) => new CanvasState(p), { editor });

  return useObserver(() => {
    const { cursor } = state;

    return (
      <>
        <CanvasDOM
          ref={state.getCanvasRef}
          onMouseDownCapture={state.onMouseDown}
          onMouseMoveCapture={state.onMouseMove}
          onContextMenuCapture={state.onContextMenuCapture}
          // onWheelCapture={preventDefault}
          onPasteCapture={state.onPaste}
          onTouchStartCapture={state.onTouchStart}
          // onMouseDown={stopPropagation}
          onDrop={state.onDrop}
          // onWheel={state.onWheel}
          style={{
            cursor: cursor === "auto" ? undefined : cursor,
          }}
        >
          <VisibleRegion />
        </CanvasDOM>
      </>
    );
  });
});
Canvas.displayName = "Canvas";

const VisibleRegion = observer(() => {
  const editor = React.useContext(EditorContext);

  const { session } = editor;
  const { width: w0, height: h0 } = session.root.layout.selfBox;
  const { width: W, height: H, _touched: touched } = editor.viewport;
  const { x, y, scale } = session.viewport;
  const w = w0 * scale;
  const h = h0 * scale;

  const marginH = (W - w0) / 2;
  const marginV = (H - h0) / 2;

  const position = {
    width: w0,
    height: h0,
    marginLeft: marginH,
    marginRight: marginH,
    marginTop: marginV,
    marginBottom: marginV,
    transform: `translate(${x}px, ${y}px) scale(${scale})`,
  };

  let mask: JSX.Element | null = null;
  const frame: JSX.Element | null = null;

  // 计算蒙层
  {
    const semiDH = (H - h) / 2;
    const semiDW = (W - w) / 2;
    const top = semiDH + y;
    const left = semiDW + x;
    const clipPath = `polygon(
      0% 0%, 100% 0%, 100% 100%, 0% 100%,
      0% ${top}px, ${left}px ${top}px,
      ${left}px ${top + h}px, ${left}px ${top + h}px,
      ${left + w}px ${top + h}px, ${left + w}px ${top}px,
      0% ${top}px
    )`;

    mask = (
      <MaskDOM
        style={{
          clipPath,
          WebkitClipPath: clipPath,
        }}
      />
    );
  }

  // 计算边框
  // {
  //   frame = (
  //     <Frame
  //       style={position}
  //     />
  //   )
  // }

  return (
    <>
      <ContentDOM
        data-id="canvas-content"
        style={{
          ...position,
          background: "#fff",
        }}
      >
        {!touched ? null : <RenderResult />}
      </ContentDOM>
      {mask}
      {frame}
      <ContentDOM data-id="canvas-operation" style={position}>
        {/* <RootOperationsContainer /> */}
        <AboveGraphic />
        {/* <DebugCollisionContainer /> */}
      </ContentDOM>
    </>
  );
});

export const RenderResult = observer(() => {
  const editor = React.useContext(EditorContext);
  const { render } = editor.session;

  return (
    <Renderer<RenderTypeMap>
      render={render.renderParams}
      api={render.renderAPI}
      style={{ overflow: "visible" }}
    />
  );
});

const AboveGraphic = observer(() => {
  const EditorSlot = useModule(EditorSlotModuleToken);
  const items = Array.from(EditorSlot?.aboveGraphic.items || EMPTY_ARRAY);
  const sortedItems = items.sort((a, b) => b.props.priority - a.props.priority);

  return <>{renderReactPortalChildren(sortedItems)}</>;
});

const CanvasDOM = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  user-select: none;
  overflow: hidden;
`;
CanvasDOM.displayName = "CanvasDOM";

const ContentDOM = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  pointer-events: none;
  box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.08);
`;
ContentDOM.displayName = "ContentDOM";

const MaskDOM = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(248, 248, 248, 0.8);
  pointer-events: none;
`;
MaskDOM.displayName = "MaskDOM";
