// tslint:disable no-empty-interface

import { observable, computed, action, makeObservable } from "mobx";

import { SelectableTarget } from "./types";

import { EventEmitter, isSetEqual } from "@chuyuan/poster-utils";
import { SessionState } from "./session-state";

export type SelectionStateEventMap = {
  change(instance: SelectionState): void;
};

const canvasId: object = {};

export type SelectionTargetId = object | string;

export class SelectionState {
  constructor(readonly session: SessionState) {
    makeObservable(this);
  }

  readonly events = new EventEmitter<SelectionStateEventMap>();

  @observable.ref
  private _rawSet: ReadonlySet<SelectionTargetId> = new Set();

  get size() {
    return this._set.size;
  }

  get length() {
    return this._set.size;
  }

  @computed
  get isMoreThanOne() {
    return this._set.size > 1;
  }

  @computed
  get set(): ReadonlySet<SelectableTarget> {
    return new Set(this);
  }

  @action
  apply(data: Iterable<SelectionTargetId>) {
    const newSet = this._toValidSet(data);
    if (isSetEqual(this._rawSet, newSet)) return;
    this._rawSet = newSet;
    this.events.emit("change", this);
  }

  @computed({ keepAlive: true })
  private get _set(): ReadonlySet<SelectionTargetId> {
    return this._toValidSet(this._rawSet);
  }

  @computed
  get map() {
    return new Map([...this._set].map((x, i) => [x, i]));
  }

  @action
  select(target: SelectableTarget, multi: boolean) {
    if (multi) {
      if (this.has(target)) {
        this.delete(target);
      } else {
        this.add(target);
      }
    } else {
      this.replace(target);
    }
  }

  @action
  add(...targets: ReadonlyArray<string | SelectableTarget>) {
    const newSet = new Set(this._set);
    for (const target of targets) {
      newSet.add(this.getTargetId(target));
    }
    this.apply(newSet);
  }

  @action
  delete(...targets: ReadonlyArray<string | SelectableTarget>) {
    const newSet = new Set(this._set);
    for (const target of targets) {
      newSet.delete(this.getTargetId(target));
    }
    this.apply(newSet);
  }

  replace(...targets: ReadonlyArray<string | SelectableTarget>) {
    const newSet = new Set<SelectionTargetId>();
    for (const target of targets) {
      newSet.add(this.getTargetId(target));
    }
    this.apply(newSet);
  }

  has(target: string | SelectableTarget) {
    return this._set.has(this.getTargetId(target));
  }

  *values(): Generator<SelectableTarget> {
    const store = this.session.ds;
    for (const id of this._set) {
      if (typeof id === "string") {
        const node = store.get(id);
        if (node) yield node;
      } else {
        yield this.session.root;
      }
    }
  }

  [Symbol.iterator]() {
    return this.values();
  }

  getTargetId(node: string | SelectableTarget) {
    return typeof node === "string"
      ? node
      : node === this.session.root
      ? canvasId
      : node.id;
  }

  /**
   * @internal
   */
  protected _toValidSet(iterable: Iterable<SelectionTargetId>) {
    const store = this.session.ds;
    const set = new Set<SelectionTargetId>();
    for (const id of iterable) {
      if (typeof id === "string") {
        const node = store.get(id);
        if (!node) continue;
        set.add(id);
      } else if (id === canvasId) {
        set.add(id);
      }
    }
    return set;
  }
}
