import {
  createExternalPromise,
  EventEmitter,
  ExternalPromise,
} from "@chuyuan/poster-utils";
import { IObservableValue } from "mobx";
import { createBox } from "./mobx";

export type ResourceData<T> =
  | {
      readonly state: "pending";
      readonly data?: undefined;
      readonly error?: undefined;
    }
  | {
      readonly state: "resolved";
      readonly data: T;
      readonly error?: undefined;
    }
  | {
      readonly state: "rejected";
      readonly data?: undefined;
      readonly error: unknown;
    };

const PENDING_STATE = Object.freeze({ state: "pending" } as const);

export class Resource<T> {
  /**
   * @internal
   */
  private _data?: IObservableValue<ResourceData<T>>;

  readonly events = new EventEmitter<{
    request(): void;
  }>();

  /**
   * @internal
   */
  private _xps: Array<ExternalPromise<T>> = [];

  promise() {
    const data = this._data?.get();
    if (data) {
      if (data.state === "resolved") {
        return Promise.resolve(data.data);
      }
      if (data.state === "rejected") {
        return Promise.reject(data.error);
      }
    }
    const xp = createExternalPromise<T>();
    this._xps.push(xp);
    return xp.promise;
  }

  /**
   * 获取资源
   */
  get() {
    let box = this._data;
    if (!box) {
      box = this._data = createBox(PENDING_STATE);
      try {
        this.events.emit("request");
      } catch (e) {
        console.error(e);
      }
    }
    return box.get();
  }

  /**
   * 设置资源状态到成功
   */
  resolve(data: T) {
    let box = this._data;
    if (!box) box = this._data = createBox(PENDING_STATE);
    box.set({
      state: "resolved",
      data,
    });
    for (const { resolve } of this._xps) {
      resolve(data);
    }
    this._xps = [];
    return this;
  }

  /**
   * 设置资源状态到失败
   */
  reject(error: unknown) {
    let box = this._data;
    if (!box) box = this._data = createBox(PENDING_STATE);
    box.set({
      state: "rejected",
      error,
    });
    for (const { reject } of this._xps) {
      reject(error);
    }
    this._xps = [];
    return this;
  }

  /**
   * 清空资源
   */
  clear() {
    this._data?.set(PENDING_STATE);
    return this;
  }
}

export class ResourceMap<K, V> {
  /**
   * @internal
   */
  private _resources = new Map<K, IObservableValue<ResourceData<V>>>();

  /**
   * @internal
   */
  private _xps = new Map<K, Array<ExternalPromise<V>>>();

  readonly events = new EventEmitter<{
    request(key: K): void;
  }>();

  /**
   *
   * @param key
   * @returns
   */
  promise(key: K) {
    const resources = this._resources.get(key)?.get();
    if (resources) {
      if (resources.state === "resolved") {
        return Promise.resolve(resources.data);
      }
      if (resources.state === "rejected") {
        return Promise.reject(resources.error);
      }
    }
    const xp = createExternalPromise<V>();
    let xps = this._xps.get(key);
    if (!xps) this._xps.set(key, (xps = []));
    xps.push(xp);
    return xp.promise;
  }

  /**
   * 获取资源
   * @param key 资源键
   */
  get(key: K) {
    const resources = this._resources;
    let box = resources.get(key);
    if (!box) {
      resources.set(key, (box = createBox(PENDING_STATE)));
      try {
        this.events.emit("request", key);
      } catch (e) {
        console.error(e);
      }
    }
    return box.get();
  }

  /**
   * 查看资源, 不存在时返回 undefined
   * @param key 资源键
   */
  peek(key: K) {
    return this._resources.get(key)?.get();
  }

  /**
   * 设置资源状态到成功
   * @param key 资源键
   * @param data 资源数据
   */
  resolve(key: K, data: V) {
    const resources = this._resources;
    const box = resources.get(key);
    const resource: ResourceData<V> = {
      state: "resolved",
      data,
    };
    if (box) {
      box.set(resource);
    } else {
      resources.set(key, createBox(resource));
    }
    return this;
  }

  /**
   * 设置资源状态到失败
   * @param key 资源键
   * @param error 错误信息
   */
  reject(key: K, error: unknown) {
    const resources = this._resources;
    const box = resources.get(key);
    const resource: ResourceData<V> = {
      state: "rejected",
      error,
    };
    if (box) {
      box.set(resource);
    } else {
      resources.set(key, createBox(resource));
    }
    return this;
  }

  /**
   * 返回所有图片资源 url
   */
  keys() {
    return this._resources.keys();
  }

  /**
   * 返回所有资源对
   */
  *entries() {
    for (const [key, box] of this._resources) {
      yield [key, box.get()];
    }
  }

  /**
   * 删除一个资源
   * @param key 资源键
   */
  delete(key: K) {
    const resources = this._resources;
    const box = resources.get(key);
    if (box) box.set(PENDING_STATE);
    return this;
  }
}

export function getResolvedResourceData<T>(data: ResourceData<T>) {
  if (data.state === "resolved") return data.data;
  return undefined;
}
