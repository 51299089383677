// tslint:disable max-line-length array-type

import {
  WritableKeysOf,
  Mutable,
  EMPTY_ARRAY,
  ClassStaticCache,
  Nullable,
  ItemOf,
} from "@chuyuan/poster-utils";

// 标准泛型字母含义:
// R => Read Type   (读类型)
// W => Write Type  (写类型)
// L => Log Type    (写入日志类型)

export interface ReadableSourceLike<T> {
  get(): T;
}

export interface WritableSourceLike<T, L = boolean> {
  set(value: T): L;
}

export interface DuplexSourceLike<R, W = R, L = boolean>
  extends ReadableSourceLike<R>,
    WritableSourceLike<W, L> {}

export interface ReaderLike<T> {
  read(): Iterable<T>;
}

export type GetReadTypeFromReaderLike<T, R = unknown> = T extends ReaderLike<
  infer U
>
  ? U
  : R;

export type WriteGetData<T, L> = {
  readonly value: T;
  readonly records: readonly L[];
};

export type WriteSetData<T> =
  | {
      readonly skip: true;
      readonly value?: T;
    }
  | {
      readonly skip?: false;
      readonly value: T;
    };

export interface WriterLike<T, L = boolean> {
  write(): WriteGenerator<T, L>;
}

export type WriteGeneratorLike<T, L> = Generator<L, unknown, WriteSetData<T>>;

export type WriteGenerator<T, L> = WriteGeneratorLike<T, readonly L[]>;

export interface DuplexLike<R, W = R, L = boolean>
  extends ReaderLike<R>,
    WriterLike<W, L> {
  overwrite(): OverwriteGenerator<R, W, L>;
}

export namespace DuplexLike {
  export type GetR<T, U = unknown> = T extends DuplexLike<
    infer _R,
    infer _W,
    infer _L
  >
    ? _R
    : U;

  export type GetW<T, U = unknown> = T extends DuplexLike<
    infer _R,
    infer _W,
    infer _L
  >
    ? _W
    : U;

  export type GetL<T, U = unknown> = T extends DuplexLike<
    infer _R,
    infer _W,
    infer _L
  >
    ? _L
    : U;

  export function broadenReadType<R, W, L>(x: DuplexLike<R, W, L>) {
    return function type<T>(): DuplexLike<R | T, W, L> {
      return x;
    };
  }

  export function narrowWriteType<R, W, L>(x: DuplexLike<R, W, L>) {
    return function type<T extends W>(): DuplexLike<R, T, L> {
      return x;
    };
  }

  export function writeTypeRequired<R, W, L>(
    x: DuplexLike<R, W, L>
  ): DuplexLike<R, NonNullable<W>, L> {
    return x;
  }
}

export type OverwriteGenerator<R, W, L> = WriteGeneratorLike<
  W,
  WriteGetData<R, L>
>;

export class ReadableSource<T>
  implements ReaderLike<T>, ReadableSourceLike<readonly T[]>
{
  constructor(readonly targets: readonly T[]) {}

  get() {
    return this.targets;
  }

  *read() {
    const { targets } = this;
    for (let i = 0, len = targets.length; i < len; i++) {
      yield targets[i];
    }
  }
}

export class ReadableSourceFromGetter<T extends Iterable<unknown>>
  implements ReaderLike<ItemOf<T>>, ReadableSourceLike<T>
{
  constructor(readonly getTargets: () => T) {}

  get() {
    return this.getTargets();
  }

  read() {
    return this.getTargets() as Iterable<ItemOf<T>>;
  }
}

export class Reader<T> implements ReaderLike<T> {
  static literals<T>(...values: readonly T[]) {
    return new this(values.map((x) => ({ get: () => x })));
  }

  constructor(readonly targets: ReadonlyArray<ReadableSourceLike<T>>) {}

  *read() {
    const { targets } = this;
    for (let i = 0, len = targets.length; i < len; i++) {
      yield targets[i].get();
    }
  }
}

export class Writer<T, L = boolean> implements WriterLike<T, L> {
  constructor(readonly targets: ReadonlyArray<WritableSourceLike<T, L>>) {}

  *write(): WriteGenerator<T, L> {
    const records: L[] = [];
    const { targets } = this;
    for (let i = 0, len = targets.length; i < len; i++) {
      const cmd = yield records;
      if (cmd.skip) continue;
      const record = targets[i].set(cmd.value);
      records.push(record);
    }
    return records;
  }
}

export class Duplex<R, W, L = boolean>
  extends Reader<R>
  implements DuplexLike<R, W, L>
{
  constructor(
    override readonly targets: ReadonlyArray<DuplexSourceLike<R, W, L>>
  ) {
    super(targets);
  }

  *write(): WriteGenerator<W, L> {
    const records: L[] = [];
    const { targets } = this;
    for (let i = 0, len = targets.length; i < len; i++) {
      const cmd = yield records;
      if (cmd.skip) continue;
      const record = targets[i].set(cmd.value);
      records.push(record);
    }
    return records;
  }

  *overwrite(): OverwriteGenerator<R, W, L> {
    const emit = createEmptyGetData<R, L>();
    const records: L[] = (emit.records = []);
    const { targets } = this;
    for (let i = 0, len = targets.length; i < len; i++) {
      const target = targets[i];
      emit.value = target.get();
      const cmd = yield emit;
      if (cmd.skip) continue;
      const record = target.set(cmd.value);
      records.push(record);
    }
    return records;
  }
}

export class ZipReaderWriter<R, W, L> implements DuplexLike<R, W, L> {
  constructor(
    readonly reader: ReaderLike<R>,
    readonly writer: WriterLike<W, L>
  ) {}

  read() {
    return this.reader.read();
  }

  write() {
    return this.writer.write();
  }

  overwrite() {
    return zipReaderAndWriter(
      this.reader.read()[Symbol.iterator](),
      this.writer.write()
    );
  }
}

export class ZipReader<T extends ReadonlyArray<ReaderLike<unknown>>>
  implements ReaderLike<ZipReadersYield<ZipReaderLikeRead<T>>>
{
  static read<T extends ReadonlyArray<ReaderLike<unknown>>>(parents: T) {
    return zipReaders(
      parents.map(
        (x): Iterator<unknown> => x.read()[Symbol.iterator]()
      ) as unknown as ZipReaderLikeRead<T>
    );
  }

  constructor(readonly parents: T) {}

  read() {
    return ZipReader.read(this.parents);
  }
}

type ZipReaderLikeRead<T extends ReadonlyArray<ReaderLike<unknown>>> = {
  +readonly [P in keyof T]-?: T[P] extends ReaderLike<infer U>
    ? Iterator<U>
    : T[P];
};

export class ConcatReader<T> implements ReaderLike<T> {
  static *read<T>(parents: Iterable<ReaderLike<T>>) {
    for (const parent of parents) {
      yield* parent.read();
    }
  }

  constructor(readonly parents: Iterable<ReaderLike<T>>) {}

  read() {
    return ConcatReader.read(this.parents);
  }
}

export class ConcatWriter<T, L> implements WriterLike<T, L> {
  static *write<T, L>(
    parents: Iterable<WriterLike<T, L>>
  ): WriteGenerator<T, L> {
    const records: L[] = [];
    for (const parent of parents) {
      const gen = parent.write();
      let next!: WriteSetData<T>;
      let prevList: readonly L[] = EMPTY_ARRAY;
      let prevLength = 0;
      while (true) {
        const ret = gen.next(next);
        const rsLength = prevList.length;
        if (prevLength < rsLength) {
          prevLength = rsLength;
          for (let i = prevLength; i < rsLength; i++) {
            records.push(prevList[i]);
          }
        }
        if (ret.done) break;
        prevList = ret.value;
        next = yield records;
      }
    }
    return;
  }

  constructor(readonly parents: Iterable<WriterLike<T, L>>) {}

  write() {
    return ConcatWriter.write(this.parents);
  }
}

export class ConcatDuplex<R, W, L>
  extends ConcatReader<R>
  implements DuplexLike<R, W, L>
{
  static override read = ConcatReader.read;

  static write = ConcatWriter.write;

  static *overwrite<R, W, L>(
    parents: Iterable<DuplexLike<R, W, L>>
  ): OverwriteGenerator<R, W, L> {
    const records: L[] = [];
    const emit = { records } as {} as Mutable<WriteGetData<R, L>>;
    for (const parent of parents) {
      const gen = parent.overwrite();
      let next!: WriteSetData<W>;
      let prevList: readonly L[] = EMPTY_ARRAY;
      let prevLength = 0;
      while (true) {
        const ret = gen.next(next);
        const rsLength = prevList.length;
        if (prevLength < rsLength) {
          prevLength = rsLength;
          for (let i = prevLength; i < rsLength; i++) {
            records.push(prevList[i]);
          }
        }
        if (ret.done) break;
        const r = ret.value;
        prevList = r.records;
        emit.value = r.value;
        next = yield emit;
      }
    }
    return;
  }

  constructor(override readonly parents: Iterable<DuplexLike<R, W, L>>) {
    super(parents);
  }

  override read() {
    return ConcatReader.read(this.parents);
  }

  write() {
    return ConcatWriter.write(this.parents);
  }

  overwrite() {
    return ConcatDuplex.overwrite(this.parents);
  }
}

/**
 * 读取时相当于执行:
 * ```
 * return source
 * ```
 * 写入相当于执行:
 * ```
 * source = { ...source, ...value }
 * ```
 */
export class SpreadDuplex<W extends Nullable<object>, R extends W, L>
  implements DuplexLike<R, Partial<W>, L>
{
  constructor(readonly parent: DuplexLike<R, W, L>) {}

  read() {
    return this.parent.read();
  }

  *write(): WriteGenerator<W, L> {
    const gen = this.parent.overwrite();
    const next = createEmptySetData<W>();
    while (true) {
      const ret = gen.next(next);
      if (ret.done) return;
      const { records, value: oldObject } = ret.value;
      const cmd = yield records;
      next.skip = cmd.skip;
      if (cmd.skip) continue;
      next.value = { ...oldObject, ...cmd.value };
    }
  }

  *overwrite(): OverwriteGenerator<R, W, L> {
    const gen = this.parent.overwrite();
    const emit = createEmptyGetData<R, L>();
    const next = createEmptySetData<W>();
    while (true) {
      const ret = gen.next(next);
      if (ret.done) return;
      const { records, value: oldObject } = ret.value;
      emit.records = records;
      emit.value = oldObject;
      const cmd = yield emit;
      next.skip = cmd.skip;
      if (cmd.skip) continue;
      next.value = { ...oldObject, ...cmd.value };
    }
  }
}

/**
 * 读取时相当于执行:
 * ```
 * return source[key]
 * ```
 */
export class KeyReader<T extends object, K extends keyof T>
  implements ReaderLike<T[K]>
{
  constructor(readonly parent: ReaderLike<T>, readonly key: K) {}

  *read() {
    const { parent, key } = this;
    for (const item of parent.read()) {
      yield item[key];
    }
  }
}

/**
 * 读取时相当于执行:
 * ```
 * return source[key]
 * ```
 * 写入时相当于执行:
 * ```
 * source[key] = value
 * ```
 */
export class AssignKeyDuplex<
    T extends object,
    K extends WritableKeysOf<T> & string
  >
  extends KeyReader<T, K>
  implements DuplexLike<T[K], T[K], undefined>
{
  constructor(
    override readonly parent: ReaderLike<T>,
    override readonly key: K
  ) {
    super(parent, key);
  }

  *write(): WriteGenerator<T[K], undefined> {
    const { parent, key } = this;
    for (const item of parent.read()) {
      const cmd = yield EMPTY_ARRAY;
      if (cmd.skip) continue;
      item[key] = cmd.value;
    }
    return;
  }

  *overwrite(): OverwriteGenerator<T[K], T[K], undefined> {
    const emit = createEmptyGetData<T[K], undefined>();
    const { parent, key } = this;
    for (const item of parent.read()) {
      emit.value = item[key];
      const cmd = yield emit;
      if (cmd.skip) continue;
      item[key] = cmd.value;
    }
    return;
  }
}

/**
 * 读取时相当于执行:
 * ```
 * return source[key]
 * ```
 * 写入时相当于执行:
 * ```
 * source[key] = value
 * ```
 */
export class AssignIndexDuplex<
    T extends unknown[],
    K extends WritableKeysOf<T> & number
  >
  extends KeyReader<T, K>
  implements DuplexLike<T[K], T[K], undefined>
{
  constructor(
    override readonly parent: ReaderLike<T>,
    override readonly key: K,
    readonly pad: (index: K) => T[K]
  ) {
    super(parent, key);
  }

  *write(): WriteGenerator<T[K], undefined> {
    const { parent, key } = this;
    for (const item of parent.read()) {
      const cmd = yield EMPTY_ARRAY;
      if (cmd.skip) continue;
      const len = item.length;
      if (key > len) {
        for (let i = len; i < key; i++) {
          item[i] = this.pad(i as K);
        }
      }
      item[key] = cmd.value;
    }
    return;
  }

  *overwrite(): OverwriteGenerator<T[K], T[K], undefined> {
    const emit = createEmptyGetData<T[K], undefined>();
    const { parent, key } = this;
    for (const item of parent.read()) {
      emit.value = item[key];
      const cmd = yield emit;
      if (cmd.skip) continue;
      const len = item.length;
      if (key > len) {
        for (let i = len; i < key; i++) {
          item[i] = this.pad(i as K);
        }
      }
      item[key] = cmd.value;
    }
    return;
  }
}

/**
 * 读取时相当于执行:
 * ```
 * return source[key]
 * ```
 * 写入相当于执行:
 * ```
 * source = { ...source, [key]: value }
 * ```
 */
export class SpreadKeyDuplex<
    W extends Nullable<object>,
    R extends NonNullable<W>,
    K extends keyof R & string,
    L
  >
  extends KeyReader<R, K>
  implements DuplexLike<R[K], R[K], L>
{
  static defineIdentical<K extends string>(key: K) {
    return class AnonymousSpreadKeyDuplex<
      T extends { readonly [P in K]: unknown },
      L
    > extends SpreadKeyDuplex<T, NonNullable<T>, K, L> {
      constructor(parent: DuplexLike<T, T, L>) {
        super(parent, key);
      }
    };
  }

  static createIdentical<T extends object, K extends keyof T & string, L>(
    parent: DuplexLike<T, T, L>,
    key: K
  ) {
    return new this(parent as DuplexLike<NonNullable<T>, T, L>, key);
  }

  constructor(
    override readonly parent: DuplexLike<R, W, L>,
    override readonly key: K
  ) {
    super(parent, key);
  }

  *write(): WriteGenerator<R[K], L> {
    const { parent, key } = this;
    const gen = parent.overwrite();
    const next = createEmptySetData<R>();
    while (true) {
      const ret = gen.next(next);
      if (ret.done) return;
      const { records, value: oldObject } = ret.value;
      const cmd = yield records;
      next.skip = cmd.skip;
      if (cmd.skip) continue;
      next.value = { ...oldObject, [key]: cmd.value };
    }
  }

  *overwrite(): OverwriteGenerator<R[K], R[K], L> {
    const { parent, key } = this;
    const gen = parent.overwrite();
    const emit = createEmptyGetData<R[K], L>();
    const next = createEmptySetData<R>();
    while (true) {
      const ret = gen.next(next);
      if (ret.done) return;
      const { records, value: oldObject } = ret.value;
      emit.records = records;
      emit.value = oldObject[key];
      const cmd = yield emit;
      next.skip = cmd.skip;
      if (cmd.skip) continue;
      next.value = { ...oldObject, [key]: cmd.value };
    }
  }
}

/**
 * 读取时相当于执行:
 * ```
 * return source[key]
 * ```
 * 写入相当于执行:
 * ```
 * source = [...source]
 * source[key] = value
 * ```
 */
export class SpreadIndexDuplex<
    T extends ReadonlyArray<unknown>,
    K extends keyof T & number,
    L
  >
  extends KeyReader<T, K>
  implements WriterLike<T[K], L>
{
  constructor(
    override readonly parent: DuplexLike<T, T, L>,
    override readonly key: K,
    readonly pad: (index: K) => T[K]
  ) {
    super(parent, key);
  }

  *write(): WriteGenerator<T[K], L> {
    const { parent, key } = this;
    const gen = parent.overwrite();
    const next = createEmptySetData<T>();
    while (true) {
      const ret = gen.next(next);
      if (ret.done) return;
      const { records, value: oldList } = ret.value;
      const cmd = yield records;
      next.skip = cmd.skip;
      if (cmd.skip) continue;
      const newList = oldList.slice();
      const len = newList.length;
      if (key > len) {
        for (let i = len; i < key; i++) {
          newList[i] = this.pad(i as K);
        }
      }
      newList[key] = cmd.value;
      next.value = newList as unknown as typeof oldList;
    }
  }

  *overwrite(): OverwriteGenerator<T[K], T[K], L> {
    const { parent, key } = this;
    const gen = parent.overwrite();
    const emit = createEmptyGetData<T[K], L>();
    const next = createEmptySetData<T>();
    while (true) {
      const ret = gen.next(next);
      if (ret.done) return;
      const { records, value: oldList } = ret.value;
      emit.records = records;
      emit.value = oldList[key];
      const cmd = yield emit;
      if (cmd.skip) continue;
      const newList = oldList.slice();
      const len = newList.length;
      if (key > len) {
        for (let i = len; i < key; i++) {
          newList[i] = this.pad(i as K);
        }
      }
      newList[key] = cmd.value;
      next.value = newList as unknown as typeof oldList;
    }
  }
}

/**
 * 读取时相当于执行:
 * ```
 * return from(source)
 * ```
 */
export abstract class AbstractMapReader<R1, R2> implements ReaderLike<R2> {
  static of = ClassStaticCache;

  static define<T, R>(from: (item: T) => R) {
    return class AnonymousMapReader extends AbstractMapReader<T, R> {
      from = from;
    };
  }

  constructor(readonly parent: ReaderLike<R1>) {}

  abstract from(item: R1): R2;

  *read() {
    for (const item of this.parent.read()) {
      yield this.from(item);
    }
  }
}

export class MapReader<R1, R2> extends AbstractMapReader<R1, R2> {
  constructor(
    override readonly parent: ReaderLike<R1>,
    readonly from: (item: R1) => R2
  ) {
    super(parent);
  }
}

/**
 * 写入时相当于执行:
 * ```
 * source = to(target)
 * ```
 */
export abstract class AbstractMapWriter<W1, W2, L>
  implements WriterLike<W2, L>
{
  static of = ClassStaticCache;

  static define<T, R, L>(to: (item: R) => T) {
    return class AnonymousMapWriter extends AbstractMapWriter<T, R, L> {
      to = to;
    };
  }

  constructor(readonly parent: WriterLike<W1, L>) {}

  abstract to(item: W2): W1;

  *write(): WriteGenerator<W2, L> {
    const gen = this.parent.write();
    const next = createEmptySetData<W1>();
    while (true) {
      const ret = gen.next(next);
      if (ret.done) return;
      const cmd = yield ret.value;
      next.skip = cmd.skip;
      if (cmd.skip) continue;
      next.value = this.to(cmd.value);
    }
  }
}

export class MapWriter<W1, W2, L> extends AbstractMapWriter<W1, W2, L> {
  constructor(
    override readonly parent: WriterLike<W1, L>,
    readonly to: (item: W2) => W1
  ) {
    super(parent);
  }
}

/**
 * 写入时相当于执行:
 * ```
 * source = to(target)
 * ```
 * 读取时相当于执行:
 * ```
 * return from(source)
 * ```
 */
export abstract class AbstractMapDuplex<R1, R2, W1, W2, L>
  implements DuplexLike<R2, W2, L>
{
  static of = ClassStaticCache;

  static define<R1, R2, W1, W2>(
    from: (item: R1) => R2,
    to: (item: W2, source: R1) => W1
  ) {
    return class AnonymousMapDuplex<L> extends AbstractMapDuplex<
      R1,
      R2,
      W1,
      W2,
      L
    > {
      from = from;
      to = to;
    };
  }

  static defineIdentical<R1, W1, T>(
    from: (item: R1) => T,
    to: (item: T, source: R1) => W1
  ) {
    return class AnonymousMapDuplex<L> extends AbstractMapDuplex<
      R1,
      T,
      W1,
      T,
      L
    > {
      from = from;
      to = to;
    };
  }

  constructor(readonly parent: DuplexLike<R1, W1, L>) {}

  abstract from(item: R1): R2;

  abstract to(item: W2, source: R1): W1;

  *read() {
    for (const item of this.parent.read()) {
      yield this.from(item);
    }
  }

  *write(): WriteGenerator<W2, L> {
    const gen = this.parent.overwrite();
    const next = createEmptySetData<W1>();
    while (true) {
      const ret = gen.next(next);
      if (ret.done) return;
      const r = ret.value;
      const cmd = yield r.records;
      next.skip = cmd.skip;
      if (cmd.skip) continue;
      next.value = this.to(cmd.value, r.value);
    }
  }

  *overwrite(): OverwriteGenerator<R2, W2, L> {
    const gen = this.parent.overwrite();
    const emit = createEmptyGetData<R2, L>();
    const next = createEmptySetData<W1>();
    while (true) {
      const ret = gen.next(next);
      if (ret.done) return;
      const { records, value } = ret.value;
      emit.records = records;
      emit.value = this.from(value);
      const cmd = yield emit;
      next.skip = cmd.skip;
      if (cmd.skip) continue;
      next.value = this.to(cmd.value, value);
    }
  }
}

export class MapDuplex<R1, R2, W1, W2, L> extends AbstractMapDuplex<
  R1,
  R2,
  W1,
  W2,
  L
> {
  // static createIdentical<R1, W1, L>(parent: DuplexLike<R1, W1, L>) {
  //   return <T>(from: (item: R1) => T, to: (item: T, source: R1) => W1) => {
  //     return new this(parent, from, to)
  //   }
  // }

  static createIdentical<R1, W1, L, T>(
    parent: DuplexLike<R1, W1, L>,
    from: (item: R1) => T,
    to: (item: T, source: R1) => W1
  ) {
    return new this(parent, from, to);
  }

  constructor(
    override readonly parent: DuplexLike<R1, W1, L>,
    readonly from: (item: R1) => R2,
    readonly to: (item: W2, source: R1) => W1
  ) {
    super(parent);
  }
}

export class DefaultValueReader<T> extends AbstractMapReader<
  T,
  NonNullable<T>
> {
  constructor(
    override readonly parent: ReaderLike<T>,
    readonly defaultValue: NonNullable<T>
  ) {
    super(parent);
  }

  from(item: T) {
    return item ?? this.defaultValue;
  }
}

export class DefaultValueDuplex<R, W, L> extends AbstractMapDuplex<
  R,
  NonNullable<R>,
  W,
  W,
  L
> {
  constructor(
    override readonly parent: DuplexLike<R, W, L>,
    readonly defaultValue: NonNullable<R>
  ) {
    super(parent);
  }

  to(item: W) {
    return item;
  }

  from(item: R) {
    return item ?? this.defaultValue;
  }
}

/**
 * 读取时相当于执行:
 * ```
 * return from(source)
 * ```
 */
export abstract class AbstractReaderFromReader<T, R> implements ReaderLike<R> {
  static of = ClassStaticCache;

  static define<T, R>(getFn: (target: T) => R) {
    return class AnonymousDuplexFromReader extends AbstractReaderFromReader<
      T,
      R
    > {
      static getFn = getFn;

      getFn = getFn;
    };
  }

  static *read<T, R>(parent: ReaderLike<T>, getFn: (target: T) => R) {
    for (const target of parent.read()) {
      yield getFn(target);
    }
  }

  constructor(readonly parent: ReaderLike<T>) {}

  abstract getFn(this: this, target: T): R;

  read() {
    return AbstractReaderFromReader.read(this.parent, this.getFn.bind(this));
  }
}

/**
 * 写入时相当于执行:
 * ```
 * source = to(target)
 * ```
 */
export abstract class AbstractWriterFromReader<T, W, L>
  implements WriterLike<W, L>
{
  static of = ClassStaticCache;

  static define<T, W, L>(setFn: (target: T, value: W) => L) {
    return class AnonymousWriterFromReader extends AbstractWriterFromReader<
      T,
      W,
      L
    > {
      static setFn = setFn;

      setFn = setFn;
    };
  }

  static *write<T, W, L>(
    parent: ReaderLike<T>,
    setFn: (target: T, value: W) => L
  ): WriteGenerator<W, L> {
    const records: L[] = [];
    for (const target of parent.read()) {
      const cmd = yield records;
      if (cmd.skip) continue;
      const record = setFn(target, cmd.value);
      records.push(record);
    }
    return;
  }

  constructor(readonly parent: ReaderLike<T>) {}

  abstract setFn(this: this, target: T, value: W): L;

  write(): WriteGenerator<W, L> {
    return AbstractWriterFromReader.write(this.parent, this.setFn.bind(this));
  }
}

/**
 * 写入时相当于执行:
 * ```
 * source = to(target)
 * ```
 * 读取时相当于执行:
 * ```
 * return from(source)
 * ```
 */
export abstract class AbstractDuplexFromReader<T, R, W, L>
  implements DuplexLike<R, W, L>
{
  static of = ClassStaticCache;

  static define<T, R, W, L>(
    getFn: (target: T) => R,
    setFn: (
      this: { readonly getFn: (target: T) => R },
      target: T,
      value: W
    ) => L
  ) {
    return class AnonymousDuplexFromReader extends AbstractDuplexFromReader<
      T,
      R,
      W,
      L
    > {
      static getFn = getFn;

      static setFn = setFn;

      getFn = getFn;

      setFn = setFn;
    };
  }

  static defineIdentical<T, RW, L>(
    getFn: (target: T) => RW,
    setFn: (
      this: { readonly getFn: (target: T) => RW },
      target: T,
      value: RW
    ) => L
  ) {
    return class AnonymousDuplexFromReader extends AbstractDuplexFromReader<
      T,
      RW,
      RW,
      L
    > {
      static getFn = getFn;

      static setFn = setFn;

      getFn = getFn;

      setFn = setFn;
    };
  }

  static *overwrite<T, R, W, L>(
    parent: ReaderLike<T>,
    getFn: (target: T) => R,
    setFn: (target: T, value: W) => L
  ): OverwriteGenerator<R, W, L> {
    const emit = createEmptyGetData<R, L>();
    const records: L[] = (emit.records = []);
    for (const target of parent.read()) {
      emit.value = getFn(target);
      const cmd = yield emit;
      if (cmd.skip) continue;
      const record = setFn(target, cmd.value);
      records.push(record);
    }
    return;
  }

  constructor(readonly parent: ReaderLike<T>) {}

  abstract getFn(this: this, target: T): R;

  abstract setFn(this: this, target: T, value: W): L;

  read() {
    return AbstractReaderFromReader.read(this.parent, this.getFn.bind(this));
  }

  write(): WriteGenerator<W, L> {
    return AbstractWriterFromReader.write(this.parent, this.setFn.bind(this));
  }

  overwrite(): OverwriteGenerator<R, W, L> {
    return AbstractDuplexFromReader.overwrite(
      this.parent,
      this.getFn.bind(this),
      this.setFn.bind(this)
    );
  }
}

export class DuplexFromReader<T, R, W, L> extends AbstractDuplexFromReader<
  T,
  R,
  W,
  L
> {
  constructor(
    override readonly parent: ReaderLike<T>,
    readonly getFn: (target: T) => R,
    readonly setFn: (
      this: { readonly getFn: (target: T) => R },
      target: T,
      value: W
    ) => L
  ) {
    super(parent);
  }
}

export type FlattenReaderInfo<T extends ArrayLike<unknown>> = {
  readonly length: number;
  readonly offsets: readonly number[];
  readonly items: readonly T[];
};

export class FlattenReader<T extends ArrayLike<unknown>>
  implements ReaderLike<T[number]>
{
  static *read<T extends ArrayLike<unknown>>(
    parent: ReaderLike<T>
  ): Generator<T[number], void> {
    for (const item of parent.read()) {
      for (let i = 0, len = item.length; i < len; i++) {
        yield item[i];
      }
    }
  }

  constructor(readonly parent: ReaderLike<T>) {}

  read() {
    return FlattenReader.read(this.parent);
  }
}

export class FlattenDuplex<W, R extends Extract<W, readonly unknown[]>, L>
  implements DuplexLike<R[number], R[number], L>
{
  static read = FlattenReader.read;

  static *write<W, R extends Extract<W, readonly unknown[]>, L>(
    parent: DuplexLike<R, W, L>
  ): WriteGenerator<R[number], L> {
    const gen = parent.overwrite();
    const next = createEmptySetData<R>();
    while (true) {
      const ret = gen.next(next);
      if (ret.done) return;
      const { records, value: oldList } = ret.value;
      const tmpList = oldList.slice();
      let changed = false;
      for (let i = 0, len = oldList.length; i < len; i++) {
        const cmd = yield records;
        if (cmd.skip) continue;
        changed = true;
        tmpList[i] = cmd.value;
      }
      next.skip = !changed;
      next.value = tmpList as unknown as typeof oldList;
    }
  }

  static *overwrite<W, R extends Extract<W, readonly unknown[]>, L>(
    parent: DuplexLike<R, W, L>
  ): OverwriteGenerator<R[number], R[number], L> {
    const gen = parent.overwrite();
    const emit = createEmptyGetData<R[number], L>();
    const next = createEmptySetData<R>();
    while (true) {
      const ret = gen.next(next);
      if (ret.done) return;
      const { records, value: oldList } = ret.value;
      emit.records = records;
      const tmpList = oldList.slice();
      let changed = false;
      for (let i = 0, len = oldList.length; i < len; i++) {
        emit.value = oldList[i];
        const cmd = yield emit;
        if (cmd.skip) continue;
        changed = true;
        tmpList[i] = cmd.value;
      }
      next.skip = !changed;
      next.value = tmpList as unknown as typeof oldList;
    }
  }

  constructor(readonly parent: DuplexLike<R, W, L>) {}

  read() {
    return FlattenReader.read(this.parent);
  }

  write() {
    return FlattenDuplex.write(this.parent);
  }

  overwrite() {
    return FlattenDuplex.overwrite(this.parent);
  }
}

export class SliceReader<T> implements ReaderLike<T> {
  static *read<T>(
    parent: ReaderLike<T>,
    from: number | ReadableSourceLike<number>,
    to: number | ReadableSourceLike<number>
  ) {
    let i = 0;
    const gen = parent.read();

    const startIndex = unwrapScalarOrReadableSourceLike(from);
    for (const item of gen) {
      if (i++ < startIndex) continue;
      yield item;
      break;
    }

    const endIndex = unwrapScalarOrReadableSourceLike(to);
    for (const item of gen) {
      if (i++ < endIndex) {
        yield item;
      } else {
        break;
      }
    }
  }

  constructor(
    readonly parent: ReaderLike<T>,
    readonly from: number | ReadableSourceLike<number>,
    readonly to: number | ReadableSourceLike<number>
  ) {}

  read() {
    const { parent, from, to } = this;
    return SliceReader.read(parent, from, to);
  }
}

export const SkipWriteSetData = { skip: true } as const;

export class SliceWriter<T, L> implements WriterLike<T, L> {
  constructor(
    readonly parent: WriterLike<T, L>,
    readonly from: number | ReadableSourceLike<number>,
    readonly to: number | ReadableSourceLike<number>
  ) {}

  write(): WriteGenerator<T, L> {
    const from = unwrapScalarOrReadableSourceLike(this.from);
    const to = unwrapScalarOrReadableSourceLike(this.to);
    const gen = this.parent.write();
    return sliceWriteGeneratorLike(gen, from, to);
  }
}

export class SliceDuplex<R, W, L>
  extends SliceReader<R>
  implements DuplexLike<R, W, L>
{
  constructor(
    override readonly parent: DuplexLike<R, W, L>,
    override readonly from: number | ReadableSourceLike<number>,
    override readonly to: number | ReadableSourceLike<number>
  ) {
    super(parent, from, to);
  }

  write(): WriteGenerator<W, L> {
    const from = unwrapScalarOrReadableSourceLike(this.from);
    const to = unwrapScalarOrReadableSourceLike(this.to);
    const gen = this.parent.write();
    return sliceWriteGeneratorLike(gen, from, to);
  }

  overwrite(): OverwriteGenerator<R, W, L> {
    const from = unwrapScalarOrReadableSourceLike(this.from);
    const to = unwrapScalarOrReadableSourceLike(this.to);
    const gen = this.parent.overwrite();
    return sliceWriteGeneratorLike(gen, from, to);
  }
}

function unwrapScalarOrReadableSourceLike<T extends string | number | boolean>(
  x: T | ReadableSourceLike<T>
) {
  if (typeof x === "object") return x.get();
  return x;
}

export class FilterReader<T, U extends T> implements ReaderLike<U> {
  constructor(
    readonly parent: ReaderLike<T>,
    readonly filterFn: (item: T) => item is U
  ) {}

  *read() {
    for (const item of this.parent.read()) {
      if (this.filterFn(item)) {
        yield item;
      }
    }
  }
}

export class FilterDuplex<R, U extends R, W, L>
  extends FilterReader<R, U>
  implements DuplexLike<U, W, L>
{
  constructor(
    override readonly parent: DuplexLike<R, W, L>,
    override readonly filterFn: (item: R) => item is U
  ) {
    super(parent, filterFn);
  }

  *write(): WriteGenerator<W, L> {
    const gen = this.parent.overwrite();
    const next = createEmptySetData<W>();
    while (true) {
      const ret = gen.next(next);
      if (ret.done) return;
      const { records, value } = ret.value;
      if (this.filterFn(value)) {
        const cmd = yield records;
        next.skip = cmd.skip;
        if (cmd.skip) continue;
        next.value = cmd.value;
      } else {
        next.skip = true;
      }
    }
  }

  *overwrite(): OverwriteGenerator<U, W, L> {
    const gen = this.parent.overwrite();
    const emit = createEmptyGetData<U, L>();
    const next = createEmptySetData<W>();
    while (true) {
      const ret = gen.next(next);
      if (ret.done) return;
      const { records, value } = ret.value;
      if (this.filterFn(value)) {
        emit.records = records;
        emit.value = value;
        const cmd = yield emit;
        next.skip = cmd.skip;
        if (cmd.skip) continue;
        next.value = cmd.value;
      } else {
        next.skip = true;
      }
    }
  }
}

export type FilterDuplexSameType<T, U extends T, L> = FilterDuplex<T, U, U, L>;

export class SkipDuplex<W0, R, W extends W0, L>
  implements DuplexLike<R, W0, L>
{
  constructor(
    readonly parent: DuplexLike<R, W, L>,
    readonly is: (newValue: W0, oldValue: R) => newValue is W
  ) {}

  read() {
    return this.parent.read();
  }

  *write(): WriteGenerator<W0, L> {
    const gen = this.parent.overwrite();
    const next = createEmptySetData<W>();
    while (true) {
      const ret = gen.next(next);
      if (ret.done) return;
      const read = ret.value;
      const cmd = yield read.records;
      if (!cmd.skip) {
        const newValue = cmd.value;
        if (this.is(newValue, read.value)) {
          next.skip = false;
          next.value = newValue;
          continue;
        }
      }
      next.skip = true;
      next.value = undefined;
    }
  }

  *overwrite(): OverwriteGenerator<R, W0, L> {
    const gen = this.parent.overwrite();
    const next = createEmptySetData<W>();
    while (true) {
      const ret = gen.next(next);
      if (ret.done) return;
      const read = ret.value;
      const cmd = yield read;
      if (!cmd.skip) {
        const newValue = cmd.value;
        if (this.is(newValue, read.value)) {
          next.skip = false;
          next.value = newValue;
          continue;
        }
      }
      next.skip = true;
      next.value = undefined;
    }
  }
}

export function enlargeDuplex<R2>() {
  return function finish<R extends R2, W, L>(
    duplex: DuplexLike<R, W, L>
  ): DuplexLike<R2, W, L> {
    return duplex;
  };
}

export class SkipEqualsDuplex<T, R extends T, W extends T, L>
  implements DuplexLike<R, W, L>
{
  constructor(
    readonly parent: DuplexLike<R, W, L>,
    readonly equals: (a: T, b: T) => unknown
  ) {}

  read() {
    return this.parent.read();
  }

  *write(): WriteGenerator<W, L> {
    const gen = this.parent.overwrite();
    const next = createEmptySetData<W>();
    while (true) {
      const ret = gen.next(next);
      if (ret.done) return;
      const read = ret.value;
      const cmd = yield read.records;
      if (!cmd.skip) {
        const newValue = cmd.value;
        if (!this.equals(read.value, newValue)) {
          next.skip = false;
          next.value = newValue;
          continue;
        }
      }
      next.skip = true;
      next.value = undefined;
    }
  }

  *overwrite(): OverwriteGenerator<R, W, L> {
    const gen = this.parent.overwrite();
    const next = createEmptySetData<W>();
    while (true) {
      const ret = gen.next(next);
      if (ret.done) return;
      const read = ret.value;
      const cmd = yield read;
      if (!cmd.skip) {
        const newValue = cmd.value;
        if (!this.equals(read.value, newValue)) {
          next.skip = false;
          next.value = newValue;
          continue;
        }
      }
      next.skip = true;
      next.value = undefined;
    }
  }
}

export class ReshapeReader<P extends ReaderLike<unknown>, T>
  implements ReaderLike<T>
{
  constructor(
    readonly parent: P,
    readonly reshapeFn: (
      iterable: Iterable<GetReadTypeFromReaderLike<P>>
    ) => Iterable<T>
  ) {}

  read() {
    return this.reshapeFn(
      this.parent.read() as Iterable<GetReadTypeFromReaderLike<P>>
    );
  }
}

export class Reducer<P extends ReaderLike<unknown>, C>
  implements ReadableSourceLike<C>, ReaderLike<C>
{
  constructor(
    readonly parent: P,
    readonly reduceFn: (iterable: Iterable<GetReadTypeFromReaderLike<P>>) => C
  ) {}

  read(): [C] {
    return [this.get()];
  }

  get() {
    return this.reduceFn(
      this.parent.read() as Iterable<GetReadTypeFromReaderLike<P>>
    );
  }
}

export function min<P extends ReaderLike<unknown>>(parent: P) {
  type T = GetReadTypeFromReaderLike<P>;
  return new Reducer(parent, (items) => {
    let once = false;
    let prev!: T;
    for (const item of items) {
      if (once) {
        if (item < prev) prev = item;
      } else {
        once = true;
        prev = item;
      }
    }
    return (once ? [prev] : EMPTY_ARRAY) as readonly [] | readonly [T];
  });
}

export function max<P extends ReaderLike<unknown>>(parent: P) {
  type T = GetReadTypeFromReaderLike<P>;
  return new Reducer(parent, (items) => {
    let once = false;
    let prev!: T;
    for (const item of items) {
      if (once) {
        if (item > prev) prev = item;
      } else {
        once = true;
        prev = item;
      }
    }
    return (once ? [prev] : EMPTY_ARRAY) as readonly [] | readonly [T];
  });
}

export class MinReducer<T>
  implements ReadableSourceLike<T | undefined>, ReaderLike<T>
{
  constructor(readonly parent: ReaderLike<T>) {}

  read(): readonly T[] {
    let once = false;
    let prev!: T;
    for (const item of this.parent.read()) {
      if (once) {
        if (item < prev) prev = item;
      } else {
        once = true;
        prev = item;
      }
    }
    return once ? [prev] : EMPTY_ARRAY;
  }

  get(): T | undefined {
    return this.read()[0];
  }
}

export class HasAnyReducer<T>
  implements ReadableSourceLike<boolean>, ReaderLike<boolean>
{
  constructor(readonly parent: ReaderLike<T>) {}

  read(): [boolean] {
    return [this.get()];
  }

  get(): boolean {
    for (const _ of this.parent.read()) {
      return true;
    }
    return false;
  }
}

export class SomeReducer<T>
  implements ReadableSourceLike<boolean>, ReaderLike<boolean>
{
  constructor(
    readonly parent: ReaderLike<T>,
    readonly someFn: (item: T) => unknown = Boolean
  ) {}

  read(): [boolean] {
    return [this.get()];
  }

  get(): boolean {
    for (const item of this.parent.read()) {
      if (this.someFn(item)) return true;
    }
    return false;
  }
}

export class EveryReducer<T>
  implements ReadableSourceLike<boolean>, ReaderLike<boolean>
{
  constructor(
    readonly parent: ReaderLike<T>,
    readonly everyFn: (item: T) => unknown = Boolean
  ) {}

  read(): [boolean] {
    return [this.get()];
  }

  get(): boolean {
    for (const item of this.parent.read()) {
      // console.log('EveryReducer-get', item)
      // if (item === undefined) return false;
      if (!this.everyFn(item)) return false;
    }
    return true;
  }
}

export abstract class AbstractAction<R, W, L> implements DuplexLike<R, W, L> {
  static define<R, W, L>(each: (item: R, value: W) => L) {
    return class AnonymousAction extends AbstractAction<R, W, L> {
      each = each;
    };
  }

  constructor(readonly parent: ReaderLike<R>) {}

  abstract each(item: R, value: W): L;

  read() {
    return this.parent.read();
  }

  *write(): WriteGenerator<W, L> {
    const records: L[] = [];
    for (const item of this.parent.read()) {
      const cmd = yield records;
      if (cmd.skip) continue;
      this.each(item, cmd.value);
    }
    return;
  }

  *overwrite(): OverwriteGenerator<R, W, L> {
    const emit = createEmptyGetData<R, L>();
    const records: L[] = (emit.records = []);
    for (const item of this.parent.read()) {
      emit.value = item;
      const cmd = yield emit;
      if (cmd.skip) continue;
      const record = this.each(item, cmd.value);
      records.push(record);
    }
    return;
  }
}

export class Action<R, W, L> extends AbstractAction<R, W, L> {
  constructor(parent: ReaderLike<R>, readonly each: (item: R, value: W) => L) {
    super(parent);
  }
}

export function* zipReaderAndWriter<R, W, L>(
  reader: Iterator<R>,
  writer: WriteGenerator<W, L>
): OverwriteGenerator<R, W, L> {
  const emit = createEmptyGetData<R, L>();
  let next!: WriteSetData<W>;
  while (true) {
    // 写入第 n-1 个值
    const w = writer.next(next);
    if (w.done) return;
    // 读出第 n 个值
    const r = reader.next();
    if (r.done) return;
    emit.records = w.value;
    emit.value = r.value;
    next = yield emit;
  }
}

export function* zipReaders<T extends ReadonlyArray<Iterator<unknown>>>(
  readers: T
): Generator<ZipReadersYield<T>, void> {
  const len = readers.length;
  const yields = new Array(len);
  while (true) {
    for (let i = 0; i < len; i++) {
      const reader = readers[i];
      const ret = reader.next();
      if (ret.done) return;
      yields[i] = ret.value;
    }
    yield yields as unknown as ZipReadersYield<T>;
  }
}

type ZipReadersYield<T extends ReadonlyArray<Iterator<unknown>>> = {
  +readonly [P in keyof T]-?: T[P] extends Iterator<infer U> ? U : T[P];
};

export function* sliceWriteGeneratorLike<T, L>(
  gen: WriteGeneratorLike<T, L>,
  from: number,
  to: number
) {
  let i = 0;

  let next: WriteSetData<T> = SkipWriteSetData;
  while (true) {
    const ret = gen.next(next);
    if (ret.done) return;
    if (i++ < from) continue;
    next = yield ret.value;
    break;
  }

  while (true) {
    const ret = gen.next(next);
    if (ret.done) return;
    if (!(i++ < to)) break;
    next = yield ret.value;
  }
}

export function setAll<T, L>(value: T, gen: WriteGenerator<T, L>) {
  const next = createEmptySetData(value);
  let records: readonly L[] = EMPTY_ARRAY;
  while (true) {
    const ret = gen.next(next);
    if (ret.done) return records;
    records = ret.value;
  }
}

export function setList<T, L>(values: readonly T[], gen: WriteGenerator<T, L>) {
  let records: readonly L[] = EMPTY_ARRAY;
  const { length } = values;
  if (!length) return records;
  const next = createEmptySetData<T>();
  let i = 0;
  while (i <= length) {
    const ret = gen.next(next);
    if (ret.done) return records;
    records = ret.value;
    next.value = values[i++];
  }
  return records;
}

export type SetMapFn<T> = (index: number) => WriteSetData<T>;

export function setMap<T, L>(gen: WriteGenerator<T, L>, mapFn: SetMapFn<T>) {
  let cmd!: WriteSetData<T>;
  let records: readonly L[] = EMPTY_ARRAY;
  let i = 0;
  while (true) {
    const ret = gen.next(cmd);
    if (ret.done) return records;
    records = ret.value;
    cmd = mapFn(i++);
  }
}

export function createEmptyGetData<T, L>() {
  return { records: EMPTY_ARRAY } as {} as Mutable<WriteGetData<T, L>>;
}

export function createEmptySetData<T>(value?: T) {
  return { skip: false, value } as {} as Mutable<WriteSetData<T>>;
}

type IterableItem<T, R = unknown> = T extends Iterable<infer U> ? U : R;

export function reduce<T extends Iterable<unknown>, U, C = unknown>(
  this: C,
  iterable: T,
  callbackfn: (
    this: C,
    previousValue: U,
    currentValue: IterableItem<T>,
    currentIndex: number,
    array: T
  ) => U,
  initialValue: U
): U {
  let index = 0;
  let value = initialValue;
  for (const item of iterable as Iterable<IterableItem<T>>) {
    value = callbackfn.call(this, value, item, index++, iterable);
  }
  return value;
}
