import * as React from "react";
import { useObserver } from "mobx-react";
import { Text } from "@chuyuan/poster-data-access-layer";
import { EMPTY_ARRAY } from "@chuyuan/poster-utils";

import { useEqualSet } from "../../utils/react-hooks";
import { Fields } from "./state.ui";
import { Block, Row } from "../ui-components/section";
import { TextValue } from "./text";
import { EditorContext } from "../helpers/react-context";

/**@name 文本内容 */
export const TextContentPanel = React.memo(
  (props: { readonly targets: ReadonlySet<Text> }) => {
    const editor = React.useContext(EditorContext);
    const fields = Fields.of(useEqualSet(props.targets));

    const { isTextAreaAutoFocus } = editor.session.ui;

    const autoFocus = React.useMemo(
      () => fields.targets.every((x) => isTextAreaAutoFocus(x).value),
      EMPTY_ARRAY
    );

    // fields

    return useObserver(() => {
      return (
        <Block>
          <Row className="margin-y">
            <TextValue autoFocus={autoFocus} text={fields.text} />
          </Row>
        </Block>
      );
    });
  }
);
TextContentPanel.displayName = "TextContentPanel";
