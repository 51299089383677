// tslint:disable interface-name
import * as React from 'react'

export interface I18NProviderProps {
  locale: Locale
  children?: React.ReactNode
}

export type I18NMap<T, R> = RequiredKeys<T> extends never ? {
  [lang: string]: (props: Partial<T>) => R
} : {
  [lang: string]: (props: T) => R
}

export type Locale = string | Iterable<string>

export const LocaleContext = React.createContext<Locale>('zh')

export function useLocale() {
  return React.useContext(LocaleContext)
}

/**
 * 语言提供者
 * @param props 属性
 */
export function Provider(props: I18NProviderProps) {
  return (
    <LocaleContext.Provider
      value={props.locale}
      children={props.children}
    />
  )
}

export type withLocaleProps<K extends string> = { [P in K]: Locale }

export interface I18N<T extends {} = {}, R = string> {
  (props: T): JSX.Element
  match: I18NMatch
  get: I18NGet<T, R>
  bind: I18NBind<T, R>
}

export type I18NMatch = (locale: Loose<Locale>) => ReturnType<typeof matchLocale>

export type I18NGet<T, R> = RequiredKeys<T> extends never ? (
  (locale: LooseLocale, props?: Loose<Partial<T>>) => string | R
) : (
  (locale: LooseLocale, props: T) => string | R
)

export type I18NBind<T extends object, R> = RequiredKeys<T> extends never ? (
  (props?: Loose<Partial<T>>) => I18NBound<T, R>
) : (
  (props: T) => I18NBound<T, R>
)

export interface I18NBound<T extends object = object, R = string> {
  readonly source: I18N<T, R>,
  readonly props: RequiredKeys<T> extends never ? Loose<Partial<T>> : T
  get(locale: LooseLocale): string | R
}

class I18NBoundClass<T extends {} = {}, R = string> {
  constructor(
    readonly source: I18N<T, R>,
    readonly props: T,
  ) {}

  get(locale: LooseLocale) {
    return this.source.get(locale, this.props)
  }
}

type LooseLocale = Loose<Locale>

type Loose<V> = V | null | undefined | void | 0 | false

/**
 * 创建一个i18n组件
 * @param map 各语言生成函数
 */
export function i18n<T extends object = object, R extends React.ReactNode = string>(map: I18NMap<T, R>): I18N<T, R> {
  const sourceList = Object.keys(map)
  const component = function I18NComponent(props: T) {
    return (
      <LocaleContext.Consumer>
        {(locale) => get(locale, props)}
      </LocaleContext.Consumer>
    )
  }
  const result = component as I18N<T, R>

  const match = result.match = (locale) => matchLocale(sourceList, locale || '')

  const get = (
    (locale: LooseLocale, props: T) => {
      props = props || {} as T
      const matched = match(locale)
      if (!matched) {
        if (!sourceList.length) return ''
        return map[sourceList[0]](props)
      }
      return map[matched](props)
    }
  ) as I18NGet<T, R>

  result.get = get

  const bind = (
    (props: T) => new I18NBoundClass(result, props || {} as T)
  ) as I18NBind<T, R>

  result.bind = bind

  return result
}

export function matchKVMap(
  locale: LooseLocale,
  map: { readonly [key: string]: string },
) {
  const sourceList = Object.keys(map)
  const matched = matchLocale(sourceList, locale || '')
  if (!matched) {
    if (!sourceList.length) return ''
    return map[sourceList[0]]
  }
  return map[matched]
}

export function matchKVMaps(
  locale: LooseLocale,
  maps: ReadonlyArray<{ readonly [key: string]: string }>,
) {
  const { length } = maps
  const sourceLists = maps.map(Object.keys)
  const matches: string[] = []
  const l = locale || ''
  for (let i = 0; i < length; i++) {
    const matched = matchLocale(sourceLists[i], l)
    if (matched) {
      matches.push(maps[i][matched])
      continue
    }
    const ret: string[] = []
    for (let k = 0; k < length; k++) {
      const sourceList = sourceLists[k]
      ret.push(sourceList.length ? maps[k][sourceList[0]] : '')
    }
    return ret
  }
  return matches
}

const localeListCache = new Map<Locale, Map<string, string>>()
const matchedLocaleCache = new Map<Locale, Map<Locale, string | undefined>>()
const splittedLocaleCache = new Map<string, string[]>()

/**
 * 匹配语言
 * @param source 语言提供方
 * @param target 目标语言
 */
export function matchLocale(source: Locale, target: Locale) {
  let map = matchedLocaleCache.get(target)
  if (!map) matchedLocaleCache.set(target, map = new Map())
  let result = map.get(source)
  if (typeof result === 'string') return result
  const sourceList = getLocaleList(source)
  const targetList = getLocaleList(target)
  map.set(source, result = matchLocaleMaps(sourceList, targetList))
  return result
}

export function *combineLocales(...locales: Locale[]): Locale {
  for (const locale of locales) {
    if (typeof locale === 'string') {
      yield locale
    } else {
      yield *locale
    }
  }
}

function matchLocaleMaps(source: Map<string, string>, target: Map<string, string>) {
  for (const x of target.keys()) {
    if (source.has(x)) return source.get(x)!
  }
  for (const x of source.values()) {
    return x
  }
  return undefined
}

function getLocaleList(locale: Locale) {
  let item = localeListCache.get(locale)
  if (!item) {
    item = new Map()
    const list = typeof locale === 'string' ? [locale] : locale
    for (const x of list) {
      const y = getSplitLocale(x)
      item.set(x, x)
      item.set(y[0], x)
    }
    localeListCache.set(locale, item)
  }
  return item
}

function getSplitLocale(locale: string) {
  let item = splittedLocaleCache.get(locale)
  if (!item) {
    item = locale.split('-')
    splittedLocaleCache.set(locale, item)
  }
  return item
}

/**
 * 获取环境语言
 */
export function getEnvLocales(): string[] {
  if (typeof navigator === 'undefined') {
    return []
  }

  if (Array.isArray(navigator.languages)) {
    return navigator.languages
  }

  if (navigator.language) {
    return [navigator.language]
  }

  return []
}

type KeysOfType<T, U> = { [K in keyof T]: T[K] extends U ? K : never }[keyof T]

type RequiredKeys<T> = Exclude<KeysOfType<T, Exclude<T[keyof T], undefined>>, undefined>
