export const MODEL = 'model'
export const NAME = 'name'
export const TYPE = 'type'
export const VENDOR = 'vendor'
export const VERSION = 'version'
export const ARCHITECTURE = 'architecture'
export const CONSOLE = 'console'
export const MOBILE = 'mobile'
export const TABLET = 'tablet'
export const SMARTTV = 'smarttv'
export const WEARABLE = 'wearable'
export const EMBEDDED = 'embedded'

export const DEVICE_TYPES = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  SMART_TV: 'smarttv',
  CONSOLE: 'console',
  WEARABLE: 'wearable',
  BROWSER: undefined,
}

export const BROWSER_TYPES = {
  CHROME: 'Chrome',
  FIREFOX: 'Firefox',
  OPERA: 'Opera',
  YANDEX: 'Yandex',
  SAFARI: 'Safari',
  INTERNET_EXPLORER: 'Internet Explorer',
  EDGE: 'Edge',
  CHROMIUM: 'Chromium',
  IE: 'IE',
  MOBILE_SAFARI: 'Mobile Safari',
  EDGE_CHROMIUM: 'Edge Chromium',
  MIUI: 'MIUI Browser',
  WECHAT: 'WeChat',
  WECHAT_DESKTOP: 'WeChat(Win) Desktop',
}

export const OS_TYPES = {
  IOS: 'iOS',
  ANDROID: 'Android',
  WINDOWS_PHONE: 'Windows Phone',
  WINDOWS: 'Windows',
  MAC_OS: 'Mac OS',
}
