import * as React from 'react'
import { useObserver } from 'mobx-react'

import { runInAction } from 'mobx'
import { useLocale, i18n } from '../../utils/i18n'
import { DuplexFieldLike } from '../../utils/multi-value'
import { InputNumber } from '../ui-components/input-number'
import { Gap, Block, Row, TitleText } from '../ui-components/section'
import { SingleSlider } from '../ui-components/slider'
import { scalePercent } from '../ui-components/common'
import { EditorContext } from '../helpers/react-context'
import { getInputProps } from '../../utils/react-multi-value'

export const OpacityBlock = React.memo((props: {
  readonly value: DuplexFieldLike<number>
}) => {
  return (
    <Block>
      <Row className="margin-y">
        <TitleText><不透明度 /></TitleText>
      </Row>
      <Row>
        <OpacityValue value={props.value} />
      </Row>
    </Block>
  )
})
OpacityBlock.displayName = 'OpacityBlock'

const OpacityValue = React.memo((props: {
  readonly value: DuplexFieldLike<number>
}) => {
  const { value } = props

  const { session } = React.useContext(EditorContext)
  const locale = useLocale()

  return useObserver(() => {
    const { value: valueValue, ...multiValueProps } = getInputProps(value, locale)
    const onComplete = () => runInAction(() => {
      session.history.push({ name: '修改不透明度' })
    })
    const valueChangeProps = scalePercent(valueValue, v => runInAction(() => value.set(v)))
    return (
      <>
        <SingleSlider
          {...multiValueProps}
          {...valueChangeProps}
          min={0}
          max={100}
          step={1}
          onComplete={onComplete}
        />
        <Gap />
        <InputNumber
          {...multiValueProps}
          {...valueChangeProps}
          min={0}
          max={100}
          step={1}
          suffix="%"
          dragDirection="horizontal"
          onComplete={onComplete}
          style={{ maxWidth: 64 }}
        />
      </>
    )
  })
})
OpacityValue.displayName = 'OpacityValue'

const 不透明度 = i18n({
  en: () => 'Opacity',
  zh: () => '不透明度',
})
