import { observable, computed, runInAction, makeObservable } from "mobx";
import { memorize, EMPTY_ARRAY } from "@chuyuan/poster-utils";
import {
  ReadonlyAffine2D,
  getIncludedAngle,
  subVector2D,
  getAngle,
} from "@chuyuan/poster-math";

import {
  PointerEventHandlers,
  PointerEventRegister,
  PointerEventRegisterData,
} from "./event";
import { Raf } from "../../utils/raf";
import { BBox } from "./bbox";
import type { RootState } from "./state";
import type { RenderProps } from "./render";
import { CORNERS_ORDERED, getCornerRotateCursor } from "./common";
import { DimensionLike, INFINITE_BOUNDING } from "../helpers/misc";
import {
  dimensionCenterToVector2D,
  getBoundingGivenCenterAndPadding,
  pickXY,
} from "../helpers/data-picker";
import { isMac } from "../../utils/device-detect";

export interface RotationOperator {
  readonly getRoot: () => RootState;
  readonly getDegree: () => number;
  readonly setDegree: (value: number) => void;
  readonly getTransform: () => ReadonlyAffine2D;
  readonly getDimension: () => DimensionLike;
  readonly getHoverRegister: () => Omit<PointerEventRegister, "data">;
  readonly getColor: () => string;
}

let ROTATION_STATE_COUNT = 0;

export class RotationState {
  readonly raf = new Raf();

  private readonly _uniqKey = `rotation state ${++ROTATION_STATE_COUNT}`;

  private _disposed = false;

  constructor(readonly op: RotationOperator) {
    makeObservable(this);
  }

  dispose() {
    if (this._disposed) return;
    this._disposed = true;
    this.raf.flush();
    this.op
      .getRoot()
      .props.session.ui.isNonRealtimeRenderDisabled.delete(this._uniqKey);
  }

  @observable.ref
  init?: {
    readonly angle: number;
    readonly transform: ReadonlyAffine2D;
    readonly x: number;
    readonly y: number;
    readonly corner: (typeof CORNERS_ORDERED)[number];
  } = undefined;

  @observable.ref
  hoveringCorner?: (typeof CORNERS_ORDERED)[number] = undefined;

  hasMoved = false;

  @computed
  get cornerHoverRegisters() {
    const { op } = this;
    const INNER_RADIUS = 8;
    const EXTRA_SIZE = 8;
    const register = op.getHoverRegister();
    return CORNERS_ORDERED.map((corner, i): PointerEventRegister => {
      return {
        ...register,
        priority: (register.priority || 0) + i,
        data: () => {
          const transform = op.getTransform();
          const dim = op.getDimension();
          const inverseTransform = transform.clone().inverse();

          const scale = op.getRoot().uiScale;

          const innerRadius = INNER_RADIUS * scale;
          const extraSize = EXTRA_SIZE * scale;

          const cv = CORNER_VECTOR[corner];
          const cn = CORNER_XY_NAME[corner];

          const cx = dim.width * cv[0];
          const cy = dim.height * cv[1];
          const sx = LR_SIG[cn.x];
          const sy = TB_SIG[cn.y];
          const bounding = getBoundingGivenCenterAndPadding(
            cx,
            cy,
            innerRadius
          );
          bounding[cn.x] += sx * extraSize;
          bounding[cn.y] += sy * extraSize;

          return {
            bbox: new BBox(bounding, inverseTransform),
            handlers: this.getHoverHandlers(corner),
            cursor: () => {
              const vs = [
                [cx + sx, cy + sy],
                [cx, cy],
              ] as const;
              const [v1, v2] = transform.timesVectors(vs);
              const v = subVector2D(v1, v2);
              const degree = getAngle(v);
              return getCornerRotateCursor(degree);
            },
          };
        },
      };
    });
  }

  @memorize
  get degreeTextRenderProps(): Omit<RenderProps, "children"> {
    return {
      path: EMPTY_ARRAY,
      layer: "cover",
      selected: true,
    };
  }

  @memorize
  get dragRegister(): PointerEventRegister {
    const { op } = this;
    const data: PointerEventRegisterData = {
      bbox: new BBox(INFINITE_BOUNDING),
      handlers: this.dragHandlers,
      cursor: () => {
        const { init } = this;
        if (!init) return "grabbing";
        const { corner } = init;
        const transform = op.getTransform();
        const dim = op.getDimension();
        const cv = CORNER_VECTOR[corner];
        const cn = CORNER_XY_NAME[corner];
        const cx = dim.width * cv[0];
        const cy = dim.height * cv[1];
        const sx = LR_SIG[cn.x];
        const sy = TB_SIG[cn.y];
        const vs = [
          [cx + sx, cy + sy],
          [cx, cy],
        ] as const;
        const [v1, v2] = transform.timesVectors(vs);
        const v = subVector2D(v1, v2);
        const degree = getAngle(v);
        return getCornerRotateCursor(degree);
      },
    };
    return {
      path: EMPTY_ARRAY,
      layer: "cover",
      selected: true,
      data: () => data,
    };
  }

  getHoverHandlers(
    corner: (typeof CORNERS_ORDERED)[number]
  ): PointerEventHandlers {
    return {
      enter: () => {
        this.hoveringCorner = corner;
      },
      leave: () => {
        this.hoveringCorner = undefined;
      },
      move: (e) => e.stopPropagation(),
      down: (e) => {
        e.stopPropagation();
        const { op } = this;
        this.hoveringCorner = undefined;
        this.init = {
          ...pickXY(e.data),
          angle: op.getDegree(),
          transform: op.getTransform(),
          corner,
        };
      },
    };
  }

  @memorize
  get dragHandlers(): PointerEventHandlers {
    return {
      move: (e) => {
        e.stopPropagation();

        const init = this.init;
        if (!init) return;

        if (!this.hasMoved) {
          this.op
            .getRoot()
            .props.session.ui.isNonRealtimeRenderDisabled.set(
              this._uniqKey,
              true
            );
        }

        this.hasMoved = true;

        const { data } = e;

        this.raf.push(() =>
          runInAction(() => {
            const { op } = this;
            const center = dimensionCenterToVector2D(op.getDimension());
            const inverseTransform = init.transform.clone().inverse();
            const [pInit, pData] = inverseTransform.timesVectors([
              [init.x, init.y],
              [data.x, data.y],
            ]);
            const Δ = getIncludedAngle(
              subVector2D(pInit, center),
              subVector2D(pData, center)
            );
            let angle = init.angle + Δ;
            if (!Number.isFinite(angle)) return;
            if (isMac() ? data.metaKey : data.ctrlKey) {
              angle = angle >> 0;
            } else if (data.shiftKey) {
              angle = ((angle / 5) >> 0) * 5;
            }
            angle = ((angle % 360) + 360) % 360;
            op.setDegree(angle);
          })
        );
      },
      up: (e) => {
        try {
          e.stopPropagation();
          if (this.hasMoved) {
            this.op.getRoot().props.session.history.push({ name: "旋转对象" });
          }
        } finally {
          this.raf.flush();
          this.init = undefined;
          this.hasMoved = false;
          this.op
            .getRoot()
            .props.session.ui.isNonRealtimeRenderDisabled.delete(this._uniqKey);
        }
      },
    };
  }
}

export const CORNER_VECTOR = {
  "top-left": [0, 0],
  "top-right": [1, 0],
  "bottom-right": [1, 1],
  "bottom-left": [0, 1],
} as const;

export const CORNER_XY_NAME = {
  "top-left": { x: "left", y: "top" },
  "top-right": { x: "right", y: "top" },
  "bottom-right": { x: "right", y: "bottom" },
  "bottom-left": { x: "left", y: "bottom" },
} as const;

export const LR_SIG = { left: -1, right: 1 } as const;

export const TB_SIG = { top: -1, bottom: 1 } as const;
