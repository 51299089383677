import * as React from "react";

import { dethunk, FalseLike } from "@chuyuan/poster-utils";

import type { PortalItem } from "./react-portal";

export function renderReactPortalChildren(
  iterable?:
    | FalseLike
    | Iterable<
        PortalItem<{
          readonly children?: React.ReactNode | (() => React.ReactNode);
        }>
      >
) {
  const nodes: React.ReactNode[] = [];
  if (iterable) {
    for (const x of iterable) {
      nodes.push(
        <React.Fragment key={x.id} children={dethunk(x.props.children)} />
      );
    }
  }
  return nodes;
}
