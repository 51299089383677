import * as React from "react";
import styled from "styled-components";
import * as fuzzysort from "fuzzysort";

import { EMPTY_ARRAY, dethunk } from "@chuyuan/poster-utils";
import { CloseOutlined, Up2 } from "../../../../poster-ui/icons/new-icons";

import { Locator, Anchor } from "./popup-locator";
import { Popup } from "./popup";
import { mod } from "./common";
import type { SelectValueLike, SelectProps, SelectItemProps } from "./select";
import { i18n } from "../../utils/i18n";
import { IconBtn } from "./icon-btn";

export type SearchableSelectProps<T extends SelectValueLike> =
  SelectProps<T> & {
    /**
     * 选项
     */
    readonly options?: ReadonlyArray<SearchableSelectItemProps<T>>;
    /**
     * 是否显示清除按钮
     */
    readonly clearable?: boolean;
    /**
     * 点击清除按钮时的回调
     */
    readonly onClear?: () => void;
  };

export type SearchableSelectKeyword = string | Fuzzysort.Prepared;

export type SearchableSelectItemProps<T extends SelectValueLike> =
  SelectItemProps<T> & {
    readonly keywords?: readonly SearchableSelectKeyword[];
  };

export interface SearchableSelectRef {
  readonly current: HTMLDivElement | null;
}

export interface SearchableSelectComponent {
  <T extends SelectValueLike>(
    props: SearchableSelectProps<T> & {
      readonly ref?: React.Ref<SearchableSelectRef>;
    }
  ): JSX.Element;
  displayName?: string;
}

type SearchItem<T extends SelectValueLike> = {
  readonly key: Fuzzysort.Prepared;
  readonly option: SearchableSelectItemProps<T>;
};

class SearchOperator<T extends SelectValueLike> {
  readonly searchList: ReadonlyArray<SearchItem<T>>;

  constructor(readonly options: ReadonlyArray<SearchableSelectItemProps<T>>) {
    const searchList: Array<SearchItem<T>> = (this.searchList = []);
    for (const option of options) {
      const keywords = new Set([
        ...(option.keywords || EMPTY_ARRAY),
        String(option.value),
      ]);
      for (const keyword of keywords) {
        const key =
          typeof keyword === "string" ? fuzzysort.prepare(keyword)! : keyword;
        searchList.push({ option, key });
      }
    }
  }

  search(text: string) {
    const results = fuzzysort.go(text, this.searchList, {
      keys: ["key"],
    });
    const set = new Set<SearchableSelectItemProps<T>>();
    for (const result of results) {
      const { option } = result.obj;
      if (set.has(option)) continue;
      set.add(option);
    }
    return Array.from(set);
  }
}

export const SearchableSelect = React.forwardRef(
  <T extends SelectValueLike>(
    props: SearchableSelectProps<T>,
    ref: React.ForwardedRef<SearchableSelectRef>
  ) => {
    const { value, disabled, readOnly, className, clearable, onClear } = props;

    const [visible, setVisible] = React.useState(false);
    const [focused, setFocused] = React.useState(false);
    const [draftText, setDraftText] = React.useState("");
    const [_hoverValue, setHoverValue] = React.useState<T>();

    const state = React.useRef<{
      outlineRef?: HTMLDivElement | null;
      inputRef?: HTMLInputElement | null;
    }>({}).current;

    React.useImperativeHandle(ref, () => ({
      get current() {
        return state.outlineRef || null;
      },
    }));

    const inputOptions = props.options || EMPTY_ARRAY;

    const visibleOptions = React.useMemo(
      () => inputOptions.filter((x) => !x.hidden),
      [inputOptions]
    );

    const searchOperator = React.useMemo(
      () => new SearchOperator(visibleOptions),
      [visibleOptions]
    );

    const options = React.useMemo(
      () =>
        draftText ? searchOperator.search(draftText) : searchOperator.options,
      [searchOperator, draftText]
    );

    const _hoverValueIndex =
      _hoverValue === undefined
        ? -1
        : options.findIndex((x) => x.value === _hoverValue);

    const hoverValueIndex =
      _hoverValueIndex >= 0 ? _hoverValueIndex : options.length ? 0 : -1;

    const hoverValue =
      hoverValueIndex >= 0 ? options[hoverValueIndex].value : undefined;

    const unclickable = disabled || readOnly;

    return (
      <Outline
        ref={(r) => (state.outlineRef = r)}
        // tslint:disable-next-line: max-line-length
        className={`${className ? className : ""} ${
          visible ? "expanded" : ""
        } ${disabled ? "disabled" : ""} ${focused ? "focused" : ""} ${
          value === undefined ? "null" : ""
        }`}
        style={props.style}
        onClick={
          unclickable
            ? undefined
            : () => {
                setVisible(true);
                setHoverValue(options.length ? options[0].value : undefined);
                state.inputRef?.focus();
              }
        }
      >
        <div className="current-item">
          <input
            ref={(r) => (state.inputRef = r)}
            className="current-item-input"
            type="search"
            role="combobox"
            aria-haspopup="listbox"
            aria-autocomplete="list"
            disabled={disabled}
            unselectable="on"
            aria-expanded="false"
            value={draftText}
            onChange={(e) => {
              setDraftText(e.target.value);
              setHoverValue(undefined);
            }}
            onFocus={() => setFocused(true)}
            onBlur={() => {
              setFocused(false);
              setVisible(false);
              setHoverValue(undefined);
              setDraftText("");
            }}
            onKeyDown={(e) => {
              const { key } = e;
              if (visible) {
                if (key === "ArrowUp" || key === "ArrowDown") {
                  e.preventDefault();
                  if (!options.length) return;
                  if (hoverValueIndex >= 0) {
                    const di = key === "ArrowUp" ? -1 : 1;
                    const nextIndex = mod(hoverValueIndex + di, options.length);
                    setHoverValue(options[nextIndex].value);
                  } else {
                    setHoverValue(options[0].value);
                  }
                } else if (key === "Enter") {
                  if (hoverValue === undefined) return;
                  if (hoverValue === value) return;
                  if (hoverValueIndex < 0) return;
                  props.onChange?.(hoverValue);
                  if (!e.shiftKey) {
                    setDraftText("");
                    setVisible(false);
                    setHoverValue(undefined);
                  }
                } else if (key === "Escape" || key === "Tab") {
                  setVisible(false);
                  setHoverValue(undefined);
                }
              } else {
                if (
                  key === "Escape" ||
                  key === "Tab" ||
                  key.includes("Meta") ||
                  key.includes("Shift") ||
                  key.includes("Control") ||
                  key.includes("Alt")
                )
                  return;
                setVisible(true);
                setHoverValue(options.length ? options[0].value : undefined);
              }
            }}
          />
          <div className="current-item-text">
            {draftText
              ? ""
              : (() => {
                  if (value === undefined) return props.placeholder;
                  const opt = inputOptions.find((x) => x.value === value);
                  if (opt) return opt.label ? opt.label() : opt.value;
                  return dethunk(props.defaultLabel);
                })()}
          </div>
          <div className={`current-item-icon ${clearable ? "clearable" : ""}`}>
            <Up2
              className={`current-item-icon-up ${visible ? "visible" : ""}`}
            />
            {!clearable ? null : (
              <IconBtn
                className="current-item-icon-delete"
                disabled={unclickable}
                style={{ transform: "scale(0.8)" }}
                onClick={(e: any) => {
                  e.stopPropagation();
                  onClear?.();
                }}
                children={<CloseOutlined />}
              />
            )}
          </div>
        </div>
        {visible && !unclickable
          ? (() => {
              const { outlineRef } = state;
              if (!outlineRef) return null;

              const onClose = () => {
                state.inputRef?.blur();
              };
              return (
                <Popup
                  onClose={onClose}
                  render={({ container }) => (
                    <Locator
                      padding={4}
                      target={outlineRef.getBoundingClientRect()}
                      anchors={DEFAULT_ANCHORS}
                      children={
                        <SelectChildren
                          container={container}
                          selectBox={outlineRef}
                          value={value}
                          hoverValue={hoverValue}
                          options={options}
                          onChange={props.onChange}
                          onHover={setHoverValue}
                          onClose={onClose}
                        />
                      }
                    />
                  )}
                />
              );
            })()
          : null}
      </Outline>
    );
  }
) as SearchableSelectComponent;
SearchableSelect.displayName = "SearchableSelect";

const DEFAULT_ANCHORS: readonly Anchor[] = [
  { target: "left-bottom", content: "left-top" },
  { target: "left-top", content: "left-bottom" },
  { target: "center", content: "center" },
];

function SelectChildren<T extends SelectValueLike>(props: {
  readonly container: HTMLElement;
  readonly selectBox: HTMLElement;
  readonly value?: T;
  readonly hoverValue?: T;
  readonly options: ReadonlyArray<SelectItemProps<T>>;
  readonly onHover?: (value: T) => void;
  readonly onChange?: (value: T) => void;
  readonly onClose: () => void;
}) {
  const { value, hoverValue, options } = props;

  const selectBoxBounding = props.selectBox.getBoundingClientRect();

  return (
    <Options style={{ minWidth: selectBoxBounding.width }}>
      {options.length ? (
        options.map((opt) => {
          const myValue = opt.value;
          const key = opt.key ?? myValue;
          const label = opt.label ? opt.label() : myValue;
          const selected = value === myValue;
          const hovered = hoverValue === myValue;
          return (
            <div
              key={key}
              className={`option ${selected ? "selected" : ""} ${
                hovered ? "hovered" : ""
              }`}
              onMouseEnter={() => props.onHover?.(myValue)}
              onMouseDown={(e) => {
                e.stopPropagation();
                props.onClose();
                if (!selected) {
                  props.onChange?.(myValue);
                }
              }}
              children={label}
            />
          );
        })
      ) : (
        <div className="option disabled" children={<无选择项 />} />
      )}
    </Options>
  );
}

const Outline = styled.div`
  flex: 1 1 0;
  display: flex;
  align-items: center;
  position: relative;
  width: 0;
  height: 32px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  user-select: none;
  cursor: pointer;

  &.focused {
    border-color: #0040f0;
    box-shadow: 0 0 0 2px rgba(0, 64, 240, 0.2);
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .current-item-input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    padding: 0 8px;
    background: 0 0;
    border: none;
    outline: none;
    appearance: none;
    opacity: 0;
  }

  .current-item {
    flex: 1 1 0;
    display: flex;
    align-items: center;
    padding: 0 8px;
  }

  .current-item-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 0;
    flex: 1 1 0;
    color: #606060;
  }

  .current-item-icon {
    flex: 0 0 auto;
    color: #202020;

    .current-item-icon-up {
      display: block;
      font-size: 16px;
      pointer-events: none;

      &:not(.visible) {
        transform: rotate(180deg);
      }
    }

    .current-item-icon-delete {
      display: none;
    }

    &.clearable {
      position: relative;
      z-index: 1;
    }
  }

  &.auto {
    &,
    .current-item,
    .current-item-text {
      flex: 0 0 auto;
      width: auto;
    }
    .current-item-icon {
      margin-left: 4px;
    }
  }

  &.null {
    .current-item-text {
      color: #c0c0c0;
    }
  }

  &.focused {
    .current-item-input {
      opacity: 1;
    }
    .current-item-text {
      pointer-events: none;
    }
  }

  &:hover {
    .current-item-icon.clearable {
      .current-item-icon-up {
        display: none;
      }
      .current-item-icon-delete {
        display: block;
      }
    }
  }
`;

const Options = styled.div`
  max-height: 40vh;
  padding: 4px 0;
  font-size: 12px;
  background: #fff;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  overflow: auto;
  user-select: none;

  .option {
    padding: 0 12px;
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;

    &.hovered {
      background: #f8f7f6;
      font-weight: 600;
      color: #606060;
    }

    &.selected {
      font-weight: 600;
      color: #0040f0;
      background: rgba(0, 64, 240, 0.05);
    }

    &.disabled {
      opacity: 0.5;
      cursor: inherit;
    }
  }
`;

const 无选择项 = i18n({
  en: () => "No options",
  zh: () => "无选择项",
});
