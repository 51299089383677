import { IObservableValue } from 'mobx'
import { createBox } from './mobx'

export class ObservableWeakMap<K extends object, V> implements WeakMap<K, V> {
  declare [Symbol.toStringTag]: 'WeakMap'

  protected _map = new WeakMap<K, IObservableValue<V | undefined>>()

  /**
   * 注意, 这个动作不会被mobx observe
   */
  delete(key: K) {
    return this._map.delete(key)
  }

  get(key: K) {
    const map = this._map
    let box = map.get(key)
    if (!box) {
      map.set(key, (box = createBox(undefined)))
    }
    return box.get()
  }

  /**
   * 注意, 这个动作不会被mobx observe
   */
  has(key: K) {
    return this._map.has(key)
  }

  set(key: K, value: V) {
    const map = this._map
    const box = map.get(key)
    if (box) {
      box.set(value)
    } else {
      map.set(key, createBox(value))
    }
    return this
  }
}

ObservableWeakMap.prototype[Symbol.toStringTag] = 'WeakMap'
