import { computed, makeObservable } from "mobx";
import {
  parseCssPaddingLikeForCorners,
  exportCssPaddingLikeForCorners,
  Frame,
} from "@chuyuan/poster-data-access-layer";
import {
  isEqual,
  EMPTY_ARRAY,
  ClassStaticCache,
  memorize,
  castReadonly,
  typedValuesOf,
} from "@chuyuan/poster-utils";

import {
  DuplexSourceLike,
  DuplexFieldLike,
  DEFAULT_VARIOUS,
  ReadFieldData,
  WriteLog,
  AbstractDuplexFromReader,
  ReaderLike,
  setAll,
} from "../../utils/multi-value";
import { FillingPaint } from "../ui-components-stateful/filling-paint";
import { SelectableTarget } from "../editor-state/types";
import { safeDimension } from "../ui-components/data-guard";
import { FillingPaintCache } from "../gdl-common/filling-paint-cache";
import { fromFillingPaint, toFillingPaint } from "../gdl-common/filling-paint";

export const OpacityDuplex = AbstractDuplexFromReader.defineIdentical(
  (target: SelectableTarget) => target.appearance.opacity,
  (target, value) => target.appearance.setOpacity(value)
);

export const BorderRadiusCornerSizesDuplex =
  AbstractDuplexFromReader.defineIdentical(
    (target: SelectableTarget) =>
      castReadonly(
        parseCssPaddingLikeForCorners(
          target.appearance.borderRadius.radius || 0
        )
      ),
    (target, value) => {
      const { borderRadius } = target.appearance;
      return target.appearance.setBorderRadius({
        ...borderRadius,
        radius: exportCssPaddingLikeForCorners(value),
      });
    }
  );

export const BorderRadiusCornerSizeUnifiedDuplex =
  AbstractDuplexFromReader.define(
    (target: SelectableTarget) =>
      castReadonly(
        typedValuesOf(
          parseCssPaddingLikeForCorners(
            target.appearance.borderRadius.radius || 0
          )
        )
      ),
    (target, value: number) => {
      const { borderRadius } = target.appearance;
      return target.appearance.setBorderRadius({
        ...borderRadius,
        radius: value,
      });
    }
  );

export class BorderRadiusCornerSizeUnifiedField
  implements DuplexFieldLike<number>
{
  static of = ClassStaticCache;

  constructor(readonly parent: ReaderLike<SelectableTarget>) {}

  @computed({ equals: isEqual })
  get data(): ReadFieldData<number> {
    const values = new Set<number>();
    for (const sizes of BorderRadiusCornerSizeUnifiedDuplex.of(
      this.parent
    ).read()) {
      for (const size of sizes) {
        values.add(size);
        if (values.size > 1) return DEFAULT_VARIOUS;
      }
    }
    return { variety: "identical", value: Array.from(values)[0] };
  }

  get variety() {
    return this.data.variety;
  }

  get() {
    return this.data.value;
  }

  set(value: number): WriteLog<boolean> {
    const newValue = safeDimension(value);
    if (isEqual(this.get(), newValue))
      return { parent: this, records: EMPTY_ARRAY };
    const records = setAll(
      value,
      BorderRadiusCornerSizeUnifiedDuplex.of(this.parent).write()
    );
    return { parent: this, records };
  }
}

export const BorderRadiusCornerTypeDuplex =
  AbstractDuplexFromReader.defineIdentical(
    (target: SelectableTarget) => target.appearance.borderRadius.type || "arc",
    (target, value) => {
      const { borderRadius } = target.appearance;
      return target.appearance.setBorderRadius({
        ...borderRadius,
        type: value,
      });
    }
  );

export class BackgroundFillField implements DuplexSourceLike<FillingPaint> {
  static of = ClassStaticCache;

  constructor(readonly target: Frame) {
    makeObservable(this);
  }

  // 切换填充类型时需要保留色值
  @memorize
  get cache() {
    return new FillingPaintCache();
  }

  @computed
  get value(): FillingPaint {
    return toFillingPaint(this.target.appearance.fill, "#fff");
  }

  get() {
    return this.value;
  }

  set(value: FillingPaint) {
    const oldValue = this.get();
    if (isEqual(oldValue, value)) return false;
    // 切换填充类型时需要保留色值
    const { target, cache } = this;
    cache.put(oldValue);
    cache.put(value);
    return target.appearance.setFill(fromFillingPaint(value));
  }
}
