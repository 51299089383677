import * as React from "react";
import { Divider, Select as AntdSelect, Switch } from "antd";
import { runInAction } from "mobx";
import styled from "styled-components";
import { Frame } from "@chuyuan/poster-data-access-layer";
import { useObserver } from "mobx-react";
import { useLocale, i18n, Locale } from "../../utils/i18n";
import { useModule } from "../../utils/modulize";
import { Button } from "../ui-components/button";
import { useLocalStore } from "../../utils/mobx-react-hooks";
import { TreePanel } from "./tree";
import { SessionState } from "../editor-state/session-state";
import { EditorContext } from "../helpers/react-context";
import { LayoutFields } from "../gdl-layout/state.ui";
import { ContextMenuState } from "../gdl-context-menu";
import { GlobalContext, GlobalContextModuleToken } from "../ui-components/global-context";
import { LayoutPropertiesPanel } from "../gdl-layout/layout-properties-panel";
import { getSelectionFilter } from "../helpers/selection-filter";
import { AbsoluteAlignmentBar } from "../gdl-layout/abs-alignment-bar";
import { InputNumberArrayValue } from "../ui-components-stateful/number-array";
import {
  BlockDirectionDownIcon,
  BlockDirectionUpIcon,
  BlockDirectionLeftIcon,
  BlockDirectionRightIcon,
  BlockHorizontalSpacingIcon,
  BlockVerticalSpacingIcon,
  PaddingTop,
  PaddingRight,
  PaddingBottom,
  PaddingLeft,
} from "../../../../poster-ui/icons/new-icons";
import { DuplexField, EveryReducer, IntersectionSetReadField, SpreadKeyDuplex } from "../../utils/multi-value";
import { Alignment2DValue } from "../ui-components-stateful/alignment-2d";
import { Row } from "../ui-components/section";
import { callAutoConversionMessage, callErrorMessage } from "../gdl-layout/common";
import { AdjustmentValueType, ChildLayoutAdjustmentSetValueRecord } from "../gdl-layout/state.layout.child";
import { EventSystemModuleToken } from "../event-system";
import { TextContentPanelContent } from "../gdl-text/style-panel";
import { Fields } from "../gdl-text/state.ui";
import { useEqualSet } from "../../utils/react-hooks";
// import { LayoutModuleToken } from "../gdl-layout/layout.module";
import { ShortValue } from "../ui-components-stateful/number";

/**@name 左侧图层树 */
export const GraphicLayoutTreePanel = React.memo((props: { readonly session: SessionState; readonly root: Frame }) => {
  const { session, root } = props;
  return (
    <Wrapper>
      <FixedModeTitle />
      <Panel>
        <FixedModeSubTitle>
          <位置调整 />
        </FixedModeSubTitle>
        <TreePanel session={session} root={root} />
      </Panel>
      <Panel>
        <FixedModeSubTitle>
          <对齐 />
        </FixedModeSubTitle>
        <UseLayoutAlign session={session} root={root} />
      </Panel>
      <Panel>
        <FixedModeSubTitle>
          <大小位置 />
        </FixedModeSubTitle>
        <UseLayoutSize session={session} root={root} />
      </Panel>
      <Panel>
        <FixedModeSubTitle>{"Padding"}</FixedModeSubTitle>
        <UseLayoutPadding session={session} root={root} />
      </Panel>
      <Panel>
        <FixedModeSubTitle>
          <操作 />
        </FixedModeSubTitle>
        <UseLayoutOptions session={session} root={root} />
      </Panel>
    </Wrapper>
  );
});
GraphicLayoutTreePanel.displayName = "GraphicLayoutTreePanel";

/**@name 对齐小组件 */
export const UseLayoutAlign = React.memo(
  (props: { readonly session: SessionState; readonly root: Frame }) => {
    return useObserver(() => {
      const { session } = props;

      const selection = session.selection.set;
      const targets = getSelectionFilter(session.root, selection).textsOnly;
      const filter = getSelectionFilter(session.root, selection);

      console.log("UseLayoutAlign", {
        setSize: filter.set.size,
        eleSize: filter.elementsOnly.size,
      });

      if (filter.set.size == 0) {
        return null;
      }

      return (
        <>
          <AbsoluteAlignmentBar targets={filter.set} />
          {targets.size !== selection.size ? null : <TextContentPanelContent targets={targets} />}
        </>
      );
    });
  },
  () => true
);

/**@name 大小小组件 */
export const UseLayoutSize = React.memo(
  (props: { readonly session: SessionState; readonly root: Frame }) => {
    return useObserver(() => {
      const { session } = props;

      const selection = session.selection.set;

      const fields = LayoutFields.of(session.root, selection.size ? selection : session.root);

      return (
        <>
          <LayoutPropertiesPanel fields={fields} />

          <div style={{ display: "flex" }}>
            {/* 排列布局 */}
            <ArrangementLayoutItem fields={fields}></ArrangementLayoutItem>
            {/* 对齐状态 */}
            <AlignmentStatusItem fields={fields}></AlignmentStatusItem>
          </div>
          <Row>
            {/* 调整大小 */}
            <LayoutResizingItem fields={fields} session={session} />
          </Row>
        </>
      );
    });
  },
  () => true
);

/**@name padding组件 */
export const UseLayoutPadding = React.memo(
  (props: { readonly session: SessionState; readonly root: Frame }) => {
    return useObserver(() => {
      const { session } = props;

      const selection = session.selection.set;

      const fields = LayoutFields.of(session.root, selection.size ? selection : session.root);

      return (
        <>
          <LayoutPaddingPanel fields={fields} />
        </>
      );
    });
  },
  () => true
);

/**@name 布局Padding属性面板*/
export const LayoutPaddingPanel = React.memo((props: { readonly fields: LayoutFields }) => {
  const { fields } = props;

  return useObserver(() => {
    const { targets } = fields;

    if (!targets.length) return null;

    const sides = fields.flexPaddingSides;

    return (
      <>
        {"需要先设置为[画框适应内容]"}
        <Panel className="grid-content">
          {(
            [
              { side: "top", icon: PaddingTop },
              { side: "right", icon: PaddingRight },
              { side: "bottom", icon: PaddingBottom },
              { side: "left", icon: PaddingLeft },
            ] as const
          ).map(({ side }, i) => {
            return (
              <Row key={i} className="margin-y">
                <ShortValue field={sides[side]} historyName={`${side}边距值`} dragReverse suffix="px" align="left" />
              </Row>
            );
          })}
        </Panel>
      </>
    );
  });
});
LayoutPaddingPanel.displayName = "LayoutPaddingPanel";

/**@name 排列布局 */
const ArrangementLayoutItem = React.memo((props: { readonly fields: LayoutFields }) => {
  return useObserver(() => {
    const { fields } = props;
    const direction = fields.flexDirection.get();
    const sort = fields.flexSort.get();

    const onChange = (directionValue: any, newValue: any) => {
      fields.flexDirection.set(directionValue).records.some(Boolean);
      fields.flexSort.set(newValue).records.some(Boolean);
    };

    return (
      <ArrangementBlock>
        <Row>
          {sort === "normal" && direction === "column" ? (
            <ArrangementDirection
              onClick={() =>
                runInAction(() => {
                  onChange("column", "reverse");
                })
              }
            >
              <BlockDirectionUpIcon />
            </ArrangementDirection>
          ) : (
            <ArrangementDirection
              onClick={() =>
                runInAction(() => {
                  onChange("column", "normal");
                })
              }
            >
              <BlockDirectionDownIcon />
            </ArrangementDirection>
          )}
          {sort === "normal" && direction === "row" ? (
            <ArrangementDirection
              onClick={() =>
                runInAction(() => {
                  onChange("row", "reverse");
                })
              }
            >
              <BlockDirectionRightIcon />
            </ArrangementDirection>
          ) : (
            <ArrangementDirection
              onClick={() =>
                runInAction(() => {
                  onChange("row", "normal");
                })
              }
            >
              <BlockDirectionLeftIcon />
            </ArrangementDirection>
          )}
        </Row>
        <Row>
          {direction === "row" ? (
            <ArrangementDirection>
              <BlockHorizontalSpacingIcon />
            </ArrangementDirection>
          ) : (
            <ArrangementDirection>
              <BlockVerticalSpacingIcon />
            </ArrangementDirection>
          )}
          <ArrangementDirection>
            <InputNumberArrayValue
              field={fields.flexGapList}
              historyName="固定间距值"
              onChange={(values) =>
                runInAction(() => {
                  if (!values.length) return false;
                  const length = fields.maxFlexGapFullListLength.get()[0] || 0;
                  if (values.length > 1 && values.length > length) {
                    values = values.slice(0, length);
                  }
                  return fields.flexGapList.set(values).records.some(Boolean);
                })
              }
            />
          </ArrangementDirection>
        </Row>
      </ArrangementBlock>
    );
  });
});

/**@name 对齐状态 */
const AlignmentStatusItem = React.memo((props: { readonly fields: LayoutFields }) => {
  const { fields } = props;
  const duplex = fields.flexChildrenAlignment.parent;
  const nextFields = React.useMemo(() => {
    return {
      horizontal: new SpreadKeyDuplex(duplex, "horizontal"),
      vertical: new SpreadKeyDuplex(duplex, "vertical"),
    };
  }, [duplex]);

  return (
    <AlignmentStatusBlock>
      <Alignment2DValue {...nextFields} historyName="网格布局子元素对齐" />
    </AlignmentStatusBlock>
  );
});

export const changeWidth = (props: {
  readonly newValue: AdjustmentValueType;
  readonly width: {
    value: DuplexField<unknown, AdjustmentValueType, AdjustmentValueType, boolean | ChildLayoutAdjustmentSetValueRecord[]>;
    possibleValues: IntersectionSetReadField<AdjustmentValueType>;
    hidden: EveryReducer<boolean>;
  };
  readonly ctx: GlobalContext | undefined;
  readonly locale: Locale;
}) => {
  const { newValue, width, ctx, locale } = props;
  const widthRecords = width.value.set(newValue).records;
  try {
    const changes = Array.from(
      (function* () {
        for (const record of widthRecords) {
          if (typeof record === "object") {
            for (const item of record) {
              for (const change of item.changes) {
                yield {
                  ...item,
                  width: false,
                  height: false,
                  [change.key]: true,
                };
              }
            }
          }
        }
      })()
    );
    console.log("changes", { changes });
    callAutoConversionMessage(ctx, [changes], locale);
  } catch (e) {
    console.error(e);
    if (ctx) callErrorMessage(ctx, e);
  }
  widthRecords.some(Boolean);
};

export const changeHeight = (props: {
  readonly newValue: AdjustmentValueType;
  readonly height: {
    value: DuplexField<unknown, AdjustmentValueType, AdjustmentValueType, boolean | ChildLayoutAdjustmentSetValueRecord[]>;
    possibleValues: IntersectionSetReadField<AdjustmentValueType>;
    hidden: EveryReducer<boolean>;
  };
  readonly ctx: GlobalContext | undefined;
  readonly locale: Locale;
}) => {
  const { height, newValue, ctx, locale } = props;
  const heightRecords = height.value.set(newValue).records;
  try {
    const changes = Array.from(
      (function* () {
        for (const record of heightRecords) {
          if (typeof record === "object") {
            for (const item of record) {
              for (const change of item.changes) {
                yield {
                  ...item,
                  width: false,
                  height: false,
                  [change.key]: true,
                };
              }
            }
          }
        }
      })()
    );
    callAutoConversionMessage(ctx, [changes], locale);
  } catch (e) {
    console.error(e);
    if (ctx) callErrorMessage(ctx, e);
  }
  heightRecords.some(Boolean);
};

/**@name 对齐状态 */
const LayoutResizingItem = React.memo((props: { readonly fields: LayoutFields; readonly session: SessionState }) => {
  const { fields, session } = props;
  const ctx = useModule(GlobalContextModuleToken);
  const locale = useLocale();
  // const [value, setValue] = React.useState("");

  return useObserver(() => {
    const { targets } = fields;
    if (!targets.length) return null;

    const selection = session.selection.set;
    let value = "";

    const isText = () => {
      const targets = getSelectionFilter(session.root, selection).textsOnly;
      if (targets.size !== selection.size) return false;
      return true;
    };

    const isFrame = () => {
      const targets = getSelectionFilter(session.root, selection).containersOnly;
      if (targets.size !== selection.size) return false;
      return true;
    };

    // const isImage = () => {
    //   const targets = getSelectionFilter(session.root, selection).imagesOnly;
    //   if (targets.size !== selection.size) return false;
    //   return true;
    // };

    const isRoot = () => {
      const targets = getSelectionFilter(session.root, selection).rootOnly;
      if (targets.size !== selection.size) return false;
      return true;
    };

    let SelectOptions: any[] | undefined = [];
    if (isFrame()) {
      SelectOptions = [
        {
          label: "画框适应内容",
          value: "frame flex list",
        },
        {
          label: "画框固定",
          value: "frame fixed",
        },
        {
          label: "画框自由",
          value: "frame absolute",
        },
      ];
      value = `frame ${fields.containerType.get()}`;
    }
    if (isText()) {
      SelectOptions = [
        {
          label: "框宽固定，高延展",
          value: "text width fixed height adaptive",
        },
        {
          label: "框高固定，宽延展",
          value: "text width adaptive height fixed",
        },
        {
          label: "框宽高均固定，文字缩小",
          value: "text width fixed height fixed",
        },
        {
          label: "框适应内容",
          value: "text width adaptive height adaptive",
        },
        {
          label: "框填充内容",
          value: "text width fill height fill",
        },
      ];
      value = `text width ${fields.adjustments.dimension.width.value.get()} height ${fields.adjustments.dimension.height.value.get()}`;
    }
    if (isRoot()) {
      // console.log("这是一个根节点");
      // SelectOptions = [];
      SelectOptions = [
        {
          label: "画框适应内容",
          value: "frame flex list root",
        },
        {
          label: "画框固定",
          value: "frame fixed",
        },
        {
          label: "画框自由",
          value: "frame absolute",
        },
      ];
      value = `frame ${fields.containerType.get()}`;
    }

    console.log("LayoutResizingItem", {
      // isText: isText(),
      // isFrame: isFrame(),
      // isRoot: isRoot(),
      // SelectOptions,
      value,
      fields,
      width: fields.adjustments.dimension.width.value.get(),
      height: fields.adjustments.dimension.height.value.get(),
    });

    const {
      // lock,
      dimension: { width, height },
    } = fields.adjustments;

    const onChange = (type: string) => {
      console.log("type", type);
      if (type === "frame flex list" || type === "frame flex list root") {
        // TODO: 画框适应内容这里有问题，跟原功能不对等
        const field = fields.containerType;
        const { records } = field.set("flex list");
        const group = Array.from(
          (function* () {
            for (const record of records) {
              if (typeof record !== "object") continue;
              yield* record;
            }
          })()
        );
        callAutoConversionMessage(ctx, [group], locale);
        records.some(Boolean);
        const newValue = "adaptive";
        changeWidth({ newValue, width, ctx, locale });
        changeHeight({ newValue, height, ctx, locale });
        if (type === "frame flex list root") {
          fields.dimensions.width.value.set(0);
          fields.dimensions.height.value.set(0);
        }
        console.log("已设置 flex list");
      }
      if (type === "frame fixed") {
        const field = fields.containerType;
        const { records } = field.set("absolute");
        const group = Array.from(
          (function* () {
            for (const record of records) {
              if (typeof record !== "object") continue;
              yield* record;
            }
          })()
        );
        callAutoConversionMessage(ctx, [group], locale);
        records.some(Boolean);
        // console.log("已设置 absolute");
        const newValue = "fixed";
        changeWidth({ newValue, width, ctx, locale });
        changeHeight({ newValue, height, ctx, locale });
      }
      // TODO: 组的布局、调整大小【适应内容】这里感觉功能不完善
      if (type === "text width fixed height adaptive") {
        // const field = fields.containerType;
        // const { records } = field.set("flex list");
        // records.some(Boolean);
        changeWidth({ newValue: "fixed", width, ctx, locale });
        changeHeight({ newValue: "adaptive", height, ctx, locale });
      }
      if (type === "text width adaptive height fixed") {
        // const field = fields.containerType;
        // const { records } = field.set("flex list");
        // records.some(Boolean);
        changeWidth({ newValue: "adaptive", width, ctx, locale });
        changeHeight({ newValue: "fixed", height, ctx, locale });
      }
      if (type === "text width fixed height fixed") {
        // const field = fields.containerType;
        // const { records } = field.set("flex list");
        // records.some(Boolean);
        changeWidth({ newValue: "fixed", width, ctx, locale });
        changeHeight({ newValue: "fixed", height, ctx, locale });
      }
      if (type === "text width adaptive height adaptive") {
        changeWidth({ newValue: "adaptive", width, ctx, locale });
        changeHeight({ newValue: "adaptive", height, ctx, locale });
      }
    };

    return (
      <Row>
        <AntdSelect style={{ width: "100%" }} options={SelectOptions} onChange={onChange} value={value} />
      </Row>
    );
  });
});

const AlignmentStatusBlock = styled.div`
  flex: 1;
  padding: 10px;
  svg {
    font-size: 20px;
    color: #999;
  }
`;

const ArrangementBlock = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  gap: 10px;
`;

const ArrangementDirection = styled.div`
  cursor: pointer;
  font-size: 20px;
  margin-right: 10px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  :hover {
    background: #c9c2c2;
  }
`;

// const 自动布局 = i18n({
//   en: () => "Auto layout",
//   zh: () => "Auto layout",
// });

/**@name 操作小组件 */
export const UseLayoutOptions = React.memo(
  (props: { readonly session: SessionState; readonly root: Frame }) => {
    return useObserver(() => {
      const { session } = React.useContext(EditorContext);
      const locale = useLocale();
      const ctx = useModule(GlobalContextModuleToken);
      const host = useModule(EventSystemModuleToken);

      const state = useLocalStore((p) => new ContextMenuState(p), {
        ...props,
        session,
        ctx,
        locale,
        host,
      });

      // console.log("state", { state });

      return (
        <>
          <LayoutOptionsWrapper>
            <Button type="text" disabled={!state.canCut} onClick={state.onCutSelection}>
              <剪切 />
            </Button>
            <Divider type="vertical" />
            <Button type="text" disabled={!state.canCopy} onClick={state.onCopySelection}>
              <复制 />
            </Button>
            <Divider type="vertical" />
            <Button type="text" disabled={!state.canPaste} onClick={state.onPasteSelection}>
              <粘贴 />
            </Button>
            <Divider type="vertical" />
            <Button type="text" disabled={!state.canDelete} onClick={state.onDeleteSelection}>
              <删除 />
            </Button>
          </LayoutOptionsWrapper>
          <LayoutOptionsWrapper>
            <Button type="text" onClick={state.onGroupSelection}>
              <编组 />
            </Button>
            <Divider type="vertical" />
            <Button type="text" disabled={true} onClick={() => {}}>
              <取消编组 />
            </Button>
          </LayoutOptionsWrapper>
          <LayoutOptionsWrapper>
            <TextCheckLineWrappingSwitch session={session} />
          </LayoutOptionsWrapper>
        </>
      );
    });
  },
  () => true
);

const TextCheckLineWrappingSwitch = React.memo((props: { readonly session: SessionState }) => {
  const { session } = props;
  return useObserver(() => {
    const selection = session.selection.set;
    const targets = getSelectionFilter(session.root, selection).textsOnly;

    const fields = Fields.of(useEqualSet(targets));

    const isWrap = fields.wrap.get() === "any";
    console.log("fields.wrap", fields.wrap.get());

    const onChange = (checked: boolean) => {
      // console.log("onChange", checked);
      fields.wrap.set(checked ? "any" : "no");
    };

    return (
      <>
        {/* <StyleDetailBtn>
          <Icon
            className="case-btn"
            component={isWrap ? TextLineWrapIconBtn : TextNotWrapIconBtn}
            onClick={() => {
              fields.wrap.set(isWrap ? "no" : "any");
            }}
          />
        </StyleDetailBtn> */}
        是否换行
        <Switch checked={isWrap} onChange={onChange} />
      </>
    );
  });
});
TextCheckLineWrappingSwitch.displayName = "TextCheckLineWrappingSwitch";

const FixedModeTitle = React.memo(() => {
  return (
    <ModeTabs>
      <ModeTab className="selected">
        <图层编辑 />
      </ModeTab>
    </ModeTabs>
  );
});
FixedModeTitle.displayName = "FixedModeTitle";

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  direction: ltr;
  background: #fff;
  transition: all 1s ease-in-out;
  border-right: 1px solid #eae9e8;
`;

const ModeTabs = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  padding: 4px;
  display: flex;
  height: 66px;
  align-items: center;
  border-bottom: 1px solid #eae9e8;

  &.center {
    justify-content: center;
  }
`;

const ModeTab = styled.div`
  padding: 0 8px;
  text-align: center;
  color: #a0a0a0;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  &.selected {
    color: #202020;
    font-weight: bold;
  }
`;

const Panel = styled.div`
  flex: 1 1 0;
  overflow-x: hidden;
  overflow-y: auto;
  border-bottom: 1px solid #dcdcdc;
`;

const FixedModeSubTitle = styled.div`
  font-size: 12px;
  padding: 10px 8px;
`;

const LayoutOptionsWrapper = styled.div`
  padding: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const 图层编辑 = i18n({
  en: () => "Layout Edit",
  zh: () => "图层编辑",
});

const 删除 = i18n({
  en: () => "Delete",
  zh: () => "删除",
});

const 剪切 = i18n({
  en: () => "Cut",
  zh: () => "剪切",
});

const 复制 = i18n({
  en: () => "Copy",
  zh: () => "复制",
});

const 粘贴 = i18n({
  en: () => "Paste",
  zh: () => "粘贴",
});

const 位置调整 = i18n({
  en: () => "Adjustment of position",
  zh: () => "位置调整",
});

const 对齐 = i18n({
  en: () => "Alignment",
  zh: () => "对齐",
});

const 大小位置 = i18n({
  en: () => "Size/position",
  zh: () => "大小/位置",
});

const 操作 = i18n({
  en: () => "Operation",
  zh: () => "操作",
});

const 编组 = i18n({
  en: () => "Group",
  zh: () => "编组",
});

const 取消编组 = i18n({
  en: () => "UnGrouping",
  zh: () => "取消编组",
});
