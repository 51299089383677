import { EMPTY_ARRAY, EventEmitter } from "@chuyuan/poster-utils";
import { useObserver } from "mobx-react";
import * as React from "react";
import styled from "styled-components";

import { i18n } from "../../utils/i18n";
import { getResolvedResourceData } from "../../utils/mobx-resources";
import { useModule } from "../../utils/modulize";
import { useBoxedValue } from "../../utils/react-hooks";
import { ImageSelectorData } from "../editor-state/api";
import { EditorContext } from "../helpers/react-context";
import type { CommonProps } from "../ui-components/common";
import { GlobalContextModuleToken } from "../ui-components/global-context";
import { message } from "../ui-components/message";

export interface ImageContentProps extends CommonProps {
  readonly placeholder?: string;
  readonly value?: string;
  readonly onChange?: (data: ImageSelectorData) => void;
}

export const ImageContent = React.memo((props: ImageContentProps) => {
  const { value, readOnly, className, style } = props;
  const propsRef = useBoxedValue(props);

  const editor = React.useContext(EditorContext);
  const ctx = useModule(GlobalContextModuleToken);

  const stateRef = React.useRef<{
    readonly events: EventEmitter<{ close(): void }>;
  }>();
  const state =
    stateRef.current ||
    (stateRef.current = {
      events: new EventEmitter(),
    });

  React.useEffect(
    () => () => {
      state.events.emit("close");
    },
    EMPTY_ARRAY
  );

  const url =
    value && editor.api.getImageRealUrl
      ? getResolvedResourceData(editor.resources.imageRealUrl.get(value))
      : value;

  const onAddImage = async () => {
    if (editor.api.callImageSelector) {
      editor.api.callImageSelector({
        events: state.events,
        onData: (data) => {
          propsRef.current.onChange?.(data);
        },
        onClose: () => {},
      });
    } else if (ctx) {
      message.error(ctx, <未实现图片选择器接口 />);
    }
  };

  return useObserver(() => {
    return (
      <Wrapper className={className} style={style}>
        {url ? (
          <img className="img" src={url} />
        ) : url === undefined ? (
          <div className="placeholder">{props.placeholder}</div>
        ) : null}
        <div className="mask">
          {readOnly ? null : (
            <div className="add" onClick={onAddImage}>
              <替换 />
            </div>
          )}
        </div>
        {readOnly && (
          <div className="add-holder">
            <此内容不支持替换 />
          </div>
        )}
      </Wrapper>
    );
  });
});
ImageContent.displayName = "ImageContent";

const 替换 = i18n({
  en: () => "Replace",
  zh: () => "替换",
});

const 此内容不支持替换 = i18n({
  en: () => "This content can not replace",
  zh: () => "此内容不支持替换",
});

const 未实现图片选择器接口 = i18n({
  en: () => "Image selector is not provided",
  zh: () => "未实现图片选择器接口",
});

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 104px;
  background: #f8f7f6;
  border-radius: 4px;
  overflow: hidden;

  & > .img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  & > .placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > .mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.35);
    transition: all 0.25s;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      opacity: 1;
    }

    & > .add {
      width: 72px;
      height: 32px;
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
      border: 1px solid #ffffff;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    & > .add-holder {
      margin-top: 8px;
      color: #c0c0c0;
    }
  }
`;
