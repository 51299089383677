import Dexie from "dexie";
import type { Draft, Immutable } from "immer";
import { once } from "lodash";

import { memorize, createMapMapper } from "@chuyuan/poster-utils";
import { Document } from "@chuyuan/poster-data-structure";

import { NS } from "./common";
import { getSessionId } from "./storage";

export interface DBHistory {
  readonly id: number;
  readonly name: string;
  readonly data: Document;
  readonly createdAt: number;
}

export interface DBSession {
  readonly id: string;
  readonly data?: { [key: string]: unknown };
  readonly createdAt: number;
}

export class DB extends Dexie {
  @memorize
  static get instance() {
    return new DB();
  }

  readonly history!: DexieAutoIdTable<DBHistory, "id">;
  readonly sessions!: Dexie.Table<DBSession, string>;

  private constructor() {
    super(NS);
    this.version(1).stores({
      history: "++id",
      sessions: "id",
    });
  }
}

export interface Namespace<T> {
  getInitData(): Immutable<T> | undefined;
  update(data: Immutable<T>): Promise<void>;
}

class Session {
  readonly id: string;
  readonly createdAt: number;

  private _initData: { readonly [key: string]: unknown };

  constructor(data: DBSession) {
    this.id = data.id;
    this.createdAt = data.createdAt;
    this._initData = data.data || {};
  }

  readonly getNamedOperator = createMapMapper(<T>(ns: string): Namespace<T> => {
    return {
      getInitData: () => {
        return this._initData[ns] as Immutable<T> | undefined;
      },
      update: async (data) => {
        await DB.instance.sessions
          .where(":id")
          .equals(this.id)
          .modify((_item) => {
            const item = _item as Draft<typeof _item>;
            const map = item.data || (item.data = {});
            map[ns] = data;
          });
      },
    };
  });
}

export type { Session };

export const getCurrentSession = once(() => loadSession(getSessionId()));

async function loadSession(sessionId: string) {
  let data = await DB.instance.sessions.get(sessionId);
  if (!data) {
    data = {
      id: sessionId,
      createdAt: Date.now(),
    };
    await DB.instance.sessions.put(data);
  }
  return new Session(data);
}

interface DexieAutoIdTable<T extends object, K extends keyof T>
  extends Dexie.Table<T, T[K]> {
  add(item: Omit<T, K>, key?: T[K]): Dexie.Promise<T[K]>;
}
