import * as React from "react";
import { computed, action, makeObservable } from "mobx";
import styled from "styled-components";
import { useObserver } from "mobx-react";
import Icon from "@ant-design/icons";

import { SessionState } from "../editor-state/session-state";
import { SelectableTarget } from "../editor-state/types";
import { sumBy } from "lodash";
import { isTargetDeletable } from "../gdl-common/target-operations";
import { createGroups, Menu, MenuItem, MenuSeparator } from "../ui-components/context-menu";
import { stopPropagation } from "../../utils/dom";
import { Frame } from "@chuyuan/poster-data-access-layer";
import { message } from "../ui-components/message";
import { GlobalContext, GlobalContextModuleToken } from "../ui-components/global-context";
import { useModule } from "../../utils/modulize";
import { EditorContext } from "../helpers/react-context";
import { useLocale, Locale, i18n } from "../../utils/i18n";
import { useLocalStore } from "../../utils/mobx-react-hooks";
import { isMac } from "../../utils/device-detect";
import { EMPTY_ARRAY } from "@chuyuan/poster-utils";
import { getGDLTargetName } from "../gdl-common/target-names";
import { getGDLTargetIconComponent } from "../gdl-common/target-icons";
import { pasteContent } from "../gdl-commands/paste-content-command";
import { CheckIcon } from "../../../../poster-ui/icons/new-icons";
import { LayoutFields } from "../gdl-layout/state.ui";
import { createAddContentCommand } from "../gdl-commands";
import { EventHost } from "../../utils/event-system";
import { changeHeight, changeWidth } from "../gdl-layout-tree";

export interface ContextMenuProps {
  readonly getTargets?: () => Iterable<SelectableTarget>;
  readonly disableSelectLayer?: boolean;
  readonly onClose?: (e: React.MouseEvent<HTMLElement>) => void;
}

export class ContextMenuState {
  constructor(
    readonly props: ContextMenuProps & {
      readonly ctx?: GlobalContext;
      readonly session: SessionState;
      readonly locale: Locale;
      readonly host?: EventHost;
    }
  ) {
    makeObservable(this);
  }

  /**
   * 当前画布选中的图层元素
   */
  @computed
  get selectedLayers(): readonly SelectableTarget[] {
    const { selectNodes } = this;
    return Array.from(
      (function* () {
        for (const item of selectNodes) {
          if (item.kind === "frame" && !item.parent()) continue;
          yield item;
        }
      })()
    );
  }

  /**
   * 编辑器剪贴板中选中的对象
   */
  @computed
  get clipboardContent() {
    const { clipboard } = this.props.session;
    const event = clipboard.get();
    if (event && event.content.type === "custom clipboard content selection") {
      return event.content;
    }
    return;
  }

  /**
   * 编辑器剪贴板中选中的对象
   */
  @computed
  get clipboardSelection() {
    if (this.clipboardContent) {
      return this.clipboardContent.selection;
    }
    return [];
  }

  /**
   * 编辑器剪贴板中选中的对象, 过滤出布局节点
   */
  @computed
  get clipboardLayoutNodes(): readonly Frame[] {
    const selection: Frame[] = [];
    for (const selected of this.clipboardSelection) {
      if (selected.kind === "frame") {
        selection.push(selected);
      }
    }
    return selection;
  }

  /**
   * 编辑器剪贴板中选中的对象, 过滤出图层元素
   */
  @computed
  get clipboardLayers(): readonly SelectableTarget[] {
    const selection: SelectableTarget[] = [];
    for (const selected of this.clipboardSelection) {
      if (selected.kind === "frame" && !selected.parent()) continue;
      selection.push(selected);
    }
    return selection;
  }

  /**
   * 当前画布选中的对象除去编辑器剪贴板中的对象, 剩下的对象
   */
  @computed
  get selectionToPaste() {
    // console.log("selectionToPaste", {
    //   a: this.selectNodes,
    //   b: this.clipboardSelection,
    // });
    const set = new Set(this.selectNodes);
    // for (const selected of this.clipboardSelection) {
    // set.delete(selected);
    // }
    return set;
  }

  /**
   * 可用于粘贴的布局节点, 从画布选中除去剪贴板中过滤出
   */
  @computed
  get layoutNodesToPaste() {
    // console.log("layoutNodesToPaste", {
    //   a: this.selectionToPaste,
    // });
    const selection: Frame[] = [];
    for (const selected of this.selectionToPaste) {
      if (selected.kind === "frame") {
        selection.push(selected);
      }
    }
    return selection;
  }

  /**
   * 可用于粘贴的图层元素, 从画布选中除去剪贴板中过滤出
   */
  @computed
  get layersToPaste(): readonly SelectableTarget[] {
    const selection: SelectableTarget[] = [];
    for (const selected of this.selectionToPaste) {
      if (selected.kind === "frame" && !selected.parent()) continue;
      selection.push(selected);
    }
    return selection;
  }

  @computed
  get canCut() {
    // 恰好选中一个, 且该元素为图层元素
    return this.selectNodes.length === 1 && this.selectedLayers.length === 1;
  }

  @computed
  get canCopy() {
    // 恰好选中一个图层元素
    return this.selectNodes.length === 1 && this.selectedLayers.length === 1;
  }

  @computed
  get canPaste() {
    // console.log("canPaste", {
    //   a: this.clipboardContent,
    //   b: this.clipboardLayers,
    //   c: this.selectionToPaste,
    // });
    if (
      // 剪贴板的类型是内容
      this.clipboardContent &&
      this.clipboardContent.purpose === "content" &&
      // 剪贴板中恰好有一个图层
      this.clipboardLayers.length === 1 // &&
      // 可粘贴元素恰好为1个
      // this.selectionToPaste.size === 1
    ) {
      return true;
    }
    return false;
  }

  @computed
  get canCopyStyles() {
    return this.selectNodes.length === 1;
  }

  @computed
  get canPasteStyles() {
    return Boolean(
      // 剪贴板的类型是样式
      this.clipboardContent &&
        this.clipboardContent.purpose === "style" &&
        // 存在可粘贴元素
        this.selectionToPaste.size > 0 &&
        // 剪贴板中恰好有1个
        this.clipboardSelection.length === 1 &&
        // 可粘贴目标和剪贴板的类型一致
        ((this.clipboardLayers.length > 0 &&
          this.layersToPaste.length > 0 &&
          this.layersToPaste.every((x) => x.kind === this.clipboardLayers[0].kind)) ||
          (this.clipboardLayoutNodes.length > 0 && this.layoutNodesToPaste.length > 0))
    );
  }

  @computed
  get canDelete() {
    return sumBy(this.selectNodes, (x) => (isTargetDeletable(x) ? 1 : 0)) > 0;
  }

  @computed
  get selectNodes(): readonly SelectableTarget[] {
    return Array.from(this.props.session.selection);
  }

  @action.bound
  onSelectLayer(e: React.MouseEvent<HTMLElement>, target?: SelectableTarget) {
    const { selection } = this.props.session;
    const multi = e.shiftKey;
    if (target) {
      if (selection.has(target)) {
        if (multi) {
          selection.delete(target);
        } else {
          selection.replace();
        }
      } else {
        if (multi) {
          selection.add(target);
        } else {
          selection.replace(target);
        }
      }
    } else if (!multi) {
      selection.replace();
    }
    if (!multi) {
      this.hide(e);
    }
  }

  /**@name 剪切元素 */
  @action.bound
  onCutSelection(e?: React.MouseEvent<HTMLElement>) {
    this.props.session.clipboard.setSelection("content", this.selectNodes);
    for (const item of this.selectNodes) {
      if (!isTargetDeletable(item)) continue;
      item.delete();
    }
    this.props.session.selection.replace();
    this.props.session.history.push({ name: "剪切选中元素" });
    if (this.props.ctx) {
      message.success(this.props.ctx, <剪切成功 />);
    }
    e && this.hide(e);
  }

  /**@name 复制元素 */
  @action.bound
  async onCopySelection(e?: React.MouseEvent<HTMLElement>) {
    this.props.session.clipboard.setSelection("content", this.selectNodes);
    if (this.props.ctx) {
      message.success(this.props.ctx, <复制成功 />);
    }
    console.log("onCopySelection", { ...this.props });
    e && this.hide(e);
  }

  /**@name 粘贴元素 */
  @action.bound
  onPasteSelection(e?: React.MouseEvent<HTMLElement>) {
    // console.log("onPasteSelection", { ...this.selectionToPaste });
    if (this.selectionToPaste.size) {
      pasteContent({
        ...this.props,
        pasteSourceNodes: this.clipboardLayers,
        pasteTargetNodes: Array.from(this.selectionToPaste),
      });
    }
    e && this.hide(e);
  }

  /**@name 锁定元素 */
  @action.bound
  onLockSelection(e: React.MouseEvent<HTMLElement>) {
    const newValue = !this.selectedLayers.some((x) => x.properties.locked);
    for (const layer of this.selectedLayers) {
      layer.properties.setLocked(newValue);
    }
    this.hide(e);
  }

  /**@name 隐藏元素 */
  @action.bound
  onHideSelection(e: React.MouseEvent<HTMLElement>) {
    const newValue = !this.selectedLayers.some((x) => x.properties.disabled);
    for (const layer of this.selectedLayers) {
      layer.properties.setDisabled(newValue);
    }
    this.hide(e);
  }

  /**@name 删除元素 */
  @action.bound
  onDeleteSelection(e: React.MouseEvent<HTMLElement>) {
    const { session } = this.props;
    const { selection } = session;
    let hasDeletion = false;
    const rest = new Set(selection);
    for (const target of selection) {
      if (!isTargetDeletable(target)) continue;
      target.delete();
    }
    selection.replace(...Array.from(rest));
    if (hasDeletion) {
      session.history.push({ name: "删除选中元素" });
    }
    this.hide(e);
  }

  /**@name 编组元素 */
  @action.bound
  onGroupSelection() {
    // 获取需要编组的元素
    const { session, ctx, host, locale } = this.props;
    const selection = session.selection.set;
    const selectionFields = LayoutFields.of(session.root, selection.size ? selection : session.root);
    // 记录编组的元素id
    const childIds: any[] = [];
    const selectionFieldsTargets: any[] = [];
    selectionFields.targets.map((target) => {
      childIds.push(target.id);
      selectionFieldsTargets.push(target);
    });

    if (!ctx || !host) return null;

    // 创建一个组
    const command = createAddContentCommand({
      ctx,
      host,
      session,
      create: { kind: "frame" },
      isGroup: true,
    });

    session.ui.setCurrentCommand(command);

    // 获取添加组的信息
    const selectionAdd = session.selection.set;
    const fieldsAdd = LayoutFields.of(session.root, selectionAdd.size ? selectionAdd : session.root);

    if (fieldsAdd.targets[0].kind == "frame") {
      // 根节点进行编组不生效
      if (childIds.indexOf(fieldsAdd.root.id) > -1) {
        console.log("选中了顶层");
        return;
      }
      // 记录未选中的其他节点
      const parentChildren = selectionFields.root.getChildren();
      const preservingChild: any[] = [];
      parentChildren.map((child: any) => {
        if (childIds.indexOf(child.id) == -1) {
          preservingChild.push(child);
        }
      });

      // 重新更新所选元素的父节点信息
      selectionFields.root.setChildren(preservingChild.length > 0 ? preservingChild : fieldsAdd.targets);
      // 进行编组
      fieldsAdd.targets[0]?.setChildren(selectionFieldsTargets.reverse());

      console.log("selectionFields.targets", selectionFields.targets);

      // fieldsAdd.dimensions.height.value.set(300);

      console.log("selectionFields.root", { a: selectionFields.root, b: selectionFields.root.data.layout?.containerType });

      if (selectionFields.root.data.layout?.containerType === "flex list") {
        const field = fieldsAdd.containerType;
        const { records } = field.set("flex list");
        records.some(Boolean);
        console.log("已设置 flex list");
      }

      // TODO: 编组后，自动设置为适应内容

      // const newValue = "adaptive";

      // const {
      //   dimension: { width, height },
      // } = fieldsAdd.adjustments;
      // changeWidth({ newValue, width, ctx, locale });
      // changeHeight({ newValue, height, ctx, locale });

      // TODO: 现在的问题是，用户操作
      // 1. 文字宽固定高扩展（正常）
      // 2. 编组（正常）
      // 3. 组添加适应内容（不正常，组适应内容后，子图层变成了填充容器，且无法改变。）
      // 补充：组添加适应内容的前提下，在修改文字宽固定高扩展后，【调整大小】显示不变，但字号修改是符合预期的

      session.history.push({
        name: `对元素进行编组`,
      });
    }
    return;
  }

  @action.bound
  onScrollIntoView(e: React.MouseEvent<HTMLElement>) {
    try {
      const targets = this.selectNodes;
      if (targets.length !== 1) return;
      const target = targets[0];
      this.props.session.viewport.scrollIntoView(target);
    } finally {
      this.hide(e);
    }
  }

  hide(e: React.MouseEvent<HTMLElement>) {
    const { onClose } = this.props;
    if (typeof onClose === "function") {
      onClose(e);
    }
  }

  @action.bound
  selectLayer(e: React.MouseEvent<HTMLElement>, target: SelectableTarget) {
    const { selection } = this.props.session;
    const multi = e.shiftKey || (isMac() ? e.metaKey : e.ctrlKey);
    if (target) {
      if (selection.has(target)) {
        if (multi) {
          selection.delete(target);
        } else {
          selection.replace();
        }
      } else {
        if (multi) {
          selection.add(target);
        } else {
          selection.replace(target);
        }
      }
    } else if (!multi) {
      selection.replace();
    }
  }
}

export function ContextMenu(props: ContextMenuProps) {
  const locale = useLocale();
  const { session } = React.useContext(EditorContext);
  const ctx = useModule(GlobalContextModuleToken);
  const state = useLocalStore((p) => new ContextMenuState(p), {
    ...props,
    session,
    ctx,
    locale,
  });
  return useObserver(() => {
    const { selection } = session;

    // console.log("context", { ...state });

    const { getTargets, disableSelectLayer } = props;
    const isSelectLayerDisabled = !(getTargets || selection.size > 0);

    return (
      <Menu onClick={stopPropagation}>
        {disableSelectLayer ? null : (
          <>
            <MenuItem
              disabled={isSelectLayerDisabled}
              content={isSelectLayerDisabled ? <无选择图层 /> : <选择图层 />}
              children={isSelectLayerDisabled ? undefined : () => <SelectLayers state={state} getTargets={getTargets} />}
            />
            <MenuSeparator />
          </>
        )}
        <MenuItem disabled={!state.canCut} onClick={state.onCutSelection} content={<剪切 />} />
        <MenuItem disabled={!state.canCopy} onClick={state.onCopySelection} content={<复制 />} />
        <MenuItem disabled={!state.canPaste} onClick={state.onPasteSelection} content={<粘贴 />} />
        <MenuSeparator />
        {!state.selectedLayers.length ? null : (
          <>
            <MenuItem onClick={state.onLockSelection} content={state.selectedLayers.some((x) => x.properties.locked) ? <解锁图层 /> : <锁定图层 />} />
            <MenuItem
              onClick={state.onHideSelection}
              content={state.selectedLayers.some((x) => x.properties.disabled) ? <显示图层 /> : <隐藏图层 />}
            />
            <MenuSeparator />
          </>
        )}
        {state.selectNodes.length !== 1 ? null : (
          <>
            <MenuItem onClick={state.onScrollIntoView} content={<调整视窗使可见 />} />
            <MenuSeparator />
          </>
        )}
        <MenuItem disabled={!state.canDelete} onClick={state.onDeleteSelection} content={<删除 />} />
      </Menu>
    );
  });
}

const SelectLayers = React.memo((props: { readonly state: ContextMenuState; readonly getTargets?: () => Iterable<SelectableTarget> }) => {
  const { state, getTargets } = props;

  const selectionSnapshot = React.useMemo(() => new Set(state.props.session.selection), EMPTY_ARRAY);

  return useObserver(() => {
    const { session } = state.props;

    const groups: React.ReactNode[][] = [[], []];

    const rendered = new Set<SelectableTarget>();

    if (getTargets) {
      for (const target of getTargets()) {
        if (target.kind === "frame" && !target.parent()) {
          continue;
        }
        rendered.add(target);
        groups[0].push(<SelectLayer key={target.id || ""} state={state} target={target} />);
      }
    }

    for (const target of selectionSnapshot) {
      if (rendered.has(target)) continue;
      rendered.add(target);
      groups[1].push(<SelectLayer key={target.id || ""} state={state} target={target} />);
    }

    for (const target of session.selection) {
      if (rendered.has(target)) continue;
      rendered.add(target);
      groups[1].push(<SelectLayer key={target.id || ""} state={state} target={target} />);
    }

    return <>{createGroups(groups)}</>;
  });
});
SelectLayers.displayName = "SelectLayers";

const SelectLayer = React.memo((props: { readonly state: ContextMenuState; readonly target: SelectableTarget }) => {
  const { state, target } = props;

  const locale = useLocale();

  return useObserver(() => {
    const title = getGDLTargetName(target, locale);
    const MyIcon = getGDLTargetIconComponent(target);
    const isSelected = state.props.session.selection.has(target);
    return (
      <MenuItem
        key={target.id}
        onClick={(e) => state.selectLayer(e, target)}
        data={target}
        content={
          <MenuText>
            <StyledIcon component={CheckIcon} className={isSelected ? "" : "hidden"} />
            <MyIcon />
            <div className="name">{title}</div>
          </MenuText>
        }
      />
    );
  });
});
SelectLayer.displayName = "SelectLayer";

const MenuText = styled.div`
  margin-left: -1em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;

  .name {
    flex: 1 1 0;
    margin-left: 0.5em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const StyledIcon = styled(Icon)`
  margin-right: 0.5em;

  &.hidden {
    opacity: 0;
  }
`;

const 无选择图层 = i18n({
  en: () => "No Selectable Layers",
  zh: () => "无选择图层",
});

const 选择图层 = i18n({
  en: () => "Select Layers",
  zh: () => "选择图层",
});

const 剪切 = i18n({
  en: () => "Cut",
  zh: () => "剪切",
});

const 复制 = i18n({
  en: () => "Copy",
  zh: () => "复制",
});

const 粘贴 = i18n({
  en: () => "Paste",
  zh: () => "粘贴",
});

const 显示图层 = i18n({
  en: () => "Show Layer",
  zh: () => "显示图层",
});

const 隐藏图层 = i18n({
  en: () => "Hide Layer",
  zh: () => "隐藏图层",
});

const 锁定图层 = i18n({
  en: () => "Lock Layer",
  zh: () => "锁定图层",
});

const 解锁图层 = i18n({
  en: () => "Unlock Layer",
  zh: () => "解锁图层",
});

const 调整视窗使可见 = i18n({
  en: () => "Scroll into view",
  zh: () => "调整视窗使可见",
});

const 删除 = i18n({
  en: () => "Delete",
  zh: () => "删除",
});

const 剪切成功 = i18n({
  en: () => "Cut successfully",
  zh: () => "剪切成功",
});

const 复制成功 = i18n({
  en: () => "Copied successfully",
  zh: () => "复制成功",
});
