// tslint:disable max-line-length

import type { FillingPaint } from "@chuyuan/poster-data-structure";
import {
  memorize,
  isEqual,
  cached,
  castReadonly,
  typedValuesOf,
  KeyMap,
} from "@chuyuan/poster-utils";

import {
  DuplexLike,
  MapReader,
  ZipReaderWriter,
  DuplexField,
  SpreadKeyDuplex,
  MapDuplex,
} from "../../../utils/multi-value";
import type {
  CommonFillingPaintColor,
  CommonFillingPaintLinearGradient,
  CommonFillingPaintRadialGradient,
  CommonFillingPaintImage,
  CommonFillingPaintType,
  CommonFillingPaintInputType,
} from "./types";
import { createTinyColor } from "../../../utils/tinycolor-helpers";
import { mod } from "../../ui-components/common";
import { safeNumber } from "../../ui-components/data-guard";

export const CommonFillingPaintTypes = castReadonly(
  new Set(
    typedValuesOf<KeyMap<CommonFillingPaintType>>({
      color: "color",
      "linear gradient": "linear gradient",
      "radial gradient": "radial gradient",
      image: "image",
    })
  )
);

export const CommonFillingPaintInputTypes = castReadonly(
  new Set(
    typedValuesOf<KeyMap<CommonFillingPaintInputType>>({
      color: "color",
      "linear gradient": "linear gradient",
      "radial gradient": "radial gradient",
      image: "image",
      empty: "empty",
    })
  )
);

export const DEFAULT_STOPS: readonly FillingPaint.GradientStop[] = [
  {
    color: "#000",
    offset: 0,
  },
  {
    color: "#fff",
    offset: 1,
  },
];

export class CommonColorFields<T extends CommonFillingPaintColor> {
  constructor(readonly parent: DuplexLike<T>) {}

  @memorize
  get formatted() {
    return new DuplexField(
      new ZipReaderWriter(
        new MapReader(this.parent, (x) => formatColorOpacityCached(x)),
        this.parent
      )
    );
  }

  @memorize
  get color() {
    return new DuplexField(
      SpreadKeyDuplex.createIdentical(this.formatted.parent, "color")
    );
  }

  @memorize
  get opacity() {
    return new DuplexField(
      SpreadKeyDuplex.createIdentical(this.formatted.parent, "opacity")
    );
  }
}

export class CommonLinearGradientFields<
  T extends CommonFillingPaintLinearGradient
> {
  constructor(readonly parent: DuplexLike<T>) {}

  @memorize
  get stops() {
    return new DuplexField(
      SpreadKeyDuplex.createIdentical(this.parent, "stops"),
      isEqual
    );
  }

  @memorize
  get angle() {
    return new DuplexField(
      new MapDuplex(
        SpreadKeyDuplex.createIdentical(this.parent, "angle"),
        formatAngle,
        formatAngle
      )
    );
  }
}

export class CommonRadialGradientFields<
  T extends CommonFillingPaintRadialGradient
> {
  constructor(readonly parent: DuplexLike<T>) {}

  @memorize
  get stops() {
    return new DuplexField(
      SpreadKeyDuplex.createIdentical(this.parent, "stops"),
      isEqual
    );
  }
}

export class CommonImageFields<T extends CommonFillingPaintImage> {
  constructor(readonly parent: DuplexLike<T>) {}

  @memorize
  get image() {
    return new DuplexField(
      SpreadKeyDuplex.createIdentical(this.parent, "image"),
      isEqual
    );
  }

  @memorize
  get scale() {
    return new DuplexField(
      SpreadKeyDuplex.createIdentical(this.parent, "scale")
    );
  }
}

export function formatAngle(angle: number) {
  return mod(safeNumber(angle), 360);
}

export const formatColorCached = cached(formatColor);

export function formatColor(x?: string): {
  readonly color: string;
  readonly opacity: number;
} {
  const tc = createTinyColor(x);
  const color = stringifyTinyColor(tc.clone().setAlpha(1));
  const opacity = tc.getAlpha();
  return { color, opacity };
}

export const formatColorOpacityCached = cached(formatColorOpacity);

export function formatColorOpacity<
  T extends {
    readonly color?: string;
    readonly opacity?: number;
  }
>(
  x: T
): T & {
  readonly color: string;
  readonly opacity: number;
} {
  const tc = createTinyColor(x.color, x.opacity);
  const color = stringifyTinyColor(tc.clone().setAlpha(1));
  const opacity = tc.getAlpha();
  return { ...x, color, opacity };
}

const REGEXP_SHARP = /^[#＃]+/;

export function formatColorString(str: string) {
  if (str.startsWith("#") || str.startsWith("＃")) {
    return str.replace(REGEXP_SHARP, "");
  }
  return str;
}

export function stringifyTinyColor(tc: tinycolor.Instance) {
  const format = tc.getFormat();
  if (format === "hsl") return tc.toHslString();
  if (format === "hsv") return tc.toHsvString();
  if (tc.getAlpha() === 1) return tc.toHexString();
  return tc.toRgbString();
}
