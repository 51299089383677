import { computed } from "mobx";
import immer, { castDraft } from "immer";

import { TextSpec } from "@chuyuan/poster-data-structure";
import { Text } from "@chuyuan/poster-data-access-layer";
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  isEqual,
  ClassStaticCache,
  touchObjectField as t,
  cached,
  mapIterableAsKeys,
  memorize,
} from "@chuyuan/poster-utils";

import {
  AbstractDuplexFromReader,
  AbstractMapDuplex,
} from "../../utils/multi-value";
import {
  FillingPaint,
  NullableFillingPaint,
  FillingPaintColor,
} from "../ui-components-stateful/filling-paint-text-spec";
import { formatColorOpacity } from "../ui-components-stateful/filling-paint-common";
import { formatOpacity } from "../../utils/tinycolor-helpers";
import { FillingPaintCache } from "./filling-paint-cache-text-spec";
import { mapValues } from "lodash";

export class TextCompanionData {
  static of = ClassStaticCache;

  constructor(readonly target: Text) {}

  @memorize
  get cache() {
    return new TextCompanionDataCache(this);
  }

  @computed
  get selfLayerFill(): FillingPaint {
    const { target } = this;
    const advancedEffects = target.content.advancedEffects;
    const { fill, fillOpacity } =
      advancedEffects.selfLayer?.fill || EMPTY_OBJECT;
    return fromTextSpecFillingPaint(fill, fillOpacity);
  }

  @computed
  get selfLayer(): TextSpec.TextAdvancedEffectLayer {
    const { target } = this;
    const advancedEffects = target.content.advancedEffects;
    if (advancedEffects) return advancedEffects.selfLayer || EMPTY_OBJECT;
    const [fill, fillOpacity] = toTextSpecFillingPaint(this.selfLayerFill);
    return { fill: { fill, fillOpacity } };
  }
}

export class TextCompanionDataCache {
  constructor(readonly parent: TextCompanionData) {}

  @memorize
  get self() {
    return new AdvancedEffectLayerCache(this);
  }

  @memorize
  get getOffset() {
    return cached((_index: number) => new AdvancedEffectLayerCache(this));
  }

  @memorize
  get backgrounds() {
    return new BackgroundsCache(this);
  }
}

export class AdvancedEffectLayerCache {
  constructor(readonly parent: TextCompanionDataCache) {}

  readonly fill = new FillingPaintCache();

  readonly stroke = new FillingPaintCache();

  readonly shadow = new FillingPaintCache();
}

export class BackgroundsCache {
  constructor(readonly parent: TextCompanionDataCache) {}

  @memorize
  get character() {
    return new BackgroundCache(this);
  }

  @memorize
  get phrase() {
    return new BackgroundCache(this);
  }

  @memorize
  get line() {
    return new BackgroundCache(this);
  }

  @memorize
  get paragraph() {
    return new BackgroundCache(this);
  }

  @memorize
  get region() {
    return new BackgroundCache(this);
  }

  @memorize
  get lineEnds() {
    return new BackgroundCache(this);
  }

  @memorize
  get regionEnds() {
    return new BackgroundCache(this);
  }
}

export class BackgroundCache {
  constructor(readonly parent: BackgroundsCache) {}

  readonly fill = new FillingPaintCache();

  readonly stroke = new FillingPaintCache();
}

export const TextDuplex = AbstractDuplexFromReader.defineIdentical(
  (target: Text) => target.content.text,
  (target, value) => target.content.setText(value)
);

export const FontSizeDuplex = AbstractDuplexFromReader.defineIdentical(
  (target: Text) => target.content.fontSize,
  (target, value) => target.content.setFontSize(value)
);

export type TextDirectionType = "horizontal" | "vertical";

export const TextDirectionDuplex = AbstractDuplexFromReader.defineIdentical(
  (target: Text): TextDirectionType =>
    target.content.styles.isVertical ? "vertical" : "horizontal",
  function (target, value) {
    if (this.getFn(target) === value) return false;
    const regionAlignmentValue = RegionAlignmentDuplex.getFn(target);
    return [
      target.content.styles.setIsVertical(value === "vertical"),
      RegionAlignmentDuplex.setFn(target, regionAlignmentValue),
    ].some(Boolean);
  }
);

export type LineAlignmentType = "left" | "center" | "right" | "justify";

export const LineAlignmentDuplex = AbstractDuplexFromReader.defineIdentical(
  (target: Text): LineAlignmentType => {
    const v = target.content.styles.lineAlignment;
    switch (v) {
      case "justify":
      case "justify-left":
      case "justify-center":
      case "justify-right":
        return "justify";
    }
    return v;
  },
  function (target, value) {
    if (this.getFn(target) === value) return false;
    return target.content.styles.setLineAlignment(value);
  }
);

export const RegionAlignmentDuplex = AbstractDuplexFromReader.defineIdentical(
  (target: Text): TextSpec.TextStyleInlineAlignmentType => {
    const { styles } = target.content;
    if (styles.isVertical) {
      const value = styles.regionAlignmentHorizontal || "center";
      return value === "left" ? "top" : value === "right" ? "bottom" : "center";
    }
    return styles.regionAlignmentVertical || "center";
  },
  function (target, value) {
    if (this.getFn(target) === value) return false;
    const { styles } = target.content;
    let changed;
    if (styles.isVertical) {
      const v =
        value === "top" ? "left" : value === "bottom" ? "right" : "center";
      changed = [
        styles.setRegionAlignmentHorizontal(v),
        styles.setRegionAlignmentVertical(undefined),
      ].some(Boolean);
    } else {
      changed = [
        styles.setRegionAlignmentHorizontal(undefined),
        styles.setRegionAlignmentVertical(value),
      ].some(Boolean);
    }
    return changed;
  }
);

export const WrapDuplex = AbstractDuplexFromReader.defineIdentical(
  (target: Text) => target.content.styles.wrap,
  (target, value) => target.content.styles.setWrap(value)
);

export const SelfLayerFillDuplex = AbstractDuplexFromReader.define(
  (target: Text) => TextCompanionData.of(target).selfLayerFill,
  function (target, value: NullableFillingPaint) {
    if (value.kind === "empty") return false;
    if (isEqual(this.getFn(target), value)) return false;
    const [fill, fillOpacity] = toTextSpecFillingPaint(value);
    const newValue = immer(
      target.content.advancedEffects,
      (advancedEffects) => {
        const obj = t(t(advancedEffects, "selfLayer"), "fill");
        obj.fill = castDraft(fill);
        obj.fillOpacity = fillOpacity;
      }
    );
    return target.content.setAdvancedEffects(newValue);
  }
);

export function fromTextSpecFillingPaint(
  paint?: TextSpec.FillingPaint,
  opacity?: number
): FillingPaint {
  opacity = opacity ?? 1;
  if (!paint || typeof paint === "string") {
    return {
      kind: "color",
      color: paint || "#000",
      opacity,
    };
  } else if (paint.type === "gradient") {
    const { method, stops } = paint;
    if (!method || method.type === "linear") {
      return {
        kind: "linear gradient",
        angle: method?.angle || 0,
        stops: stops || EMPTY_ARRAY,
        opacity,
      };
    } else {
      return {
        kind: "radial gradient",
        stops: stops || EMPTY_ARRAY,
        opacity,
      };
    }
  } else if (paint.type === "pattern") {
    const { url, width, height } = paint;
    const sizing = paint.sizing || { type: "stretch" };
    return {
      kind: "image",
      image:
        url && typeof width === "number" && typeof height === "number"
          ? { url, width, height }
          : undefined,
      sizing: sizing.type,
      fontSize: sizing.type !== "stretch" ? sizing.pxPerEm : undefined,
      scale: paint.scale ?? 1,
      opacity,
    };
  }
  return {
    kind: "color",
    color: "#fff",
    opacity: 0,
  };
}

export function toTextSpecFillingPaint(
  paint: FillingPaint
): [TextSpec.FillingPaint, number] {
  if (paint.kind === "color") {
    return [paint.color, paint.opacity];
  }
  if (paint.kind === "linear gradient") {
    return [
      {
        type: "gradient",
        method: {
          type: "linear",
          angle: paint.angle,
        },
        stops: paint.stops,
      },
      paint.opacity,
    ];
  }
  if (paint.kind === "radial gradient") {
    return [
      {
        type: "gradient",
        method: {
          type: "radial",
        },
        stops: paint.stops,
      },
      paint.opacity,
    ];
  }
  if (paint.kind === "image") {
    const { image, sizing } = paint;
    return [
      {
        type: "pattern",
        url: image?.url,
        width: image?.width,
        height: image?.height,
        sizing:
          sizing === "stretch"
            ? undefined
            : {
                type: sizing,
                pxPerEm: paint.fontSize,
              },
        scale: paint.scale,
      },
      paint.opacity,
    ];
  }
  throw new Error(`Unknown paint ${JSON.stringify(paint)}`);
}

export const FontFamilyDuplexs = mapIterableAsKeys(
  ["en", "zh"] as const,
  (key) => {
    return AbstractDuplexFromReader.defineIdentical(
      (target: Text) => target.content.fontFamily[key] || "",
      (target, value) => target.content.setFontFamily({ [key]: value })
    );
  }
);

export const InlineGapDuplex = AbstractDuplexFromReader.defineIdentical(
  (target: Text) => target.content.styles.inlineGap,
  function (target, value) {
    if (this.getFn(target) === value) return false;
    return target.content.styles.setInlineGap(value);
  }
);

export const LineGapDuplex = AbstractDuplexFromReader.defineIdentical(
  (target: Text) => target.content.styles.lineGap,
  (target, value) => target.content.styles.setLineGap(value)
);

export const TextTransformDuplex = AbstractDuplexFromReader.defineIdentical(
  (target: Text) => target.content.styles.textTransform,
  (target, value) => target.content.styles.setTextTransform(value)
);

export type TextDecorationType = TextSpec.TextDecorationType | "none";

export const TextDecorationDuplex = AbstractDuplexFromReader.defineIdentical(
  (target: Text) => target.content.textDecoration,
  (target, value) => target.content.setTextDecoration(value)
);

export const OverflowDuplex = AbstractDuplexFromReader.defineIdentical(
  (target: Text) => target.content.styles.overflow,
  (target, value) => target.content.styles.setOverflow(value)
);

export const AdvancedEffectsDuplex = AbstractDuplexFromReader.defineIdentical(
  (target: Text) => target.content.advancedEffects,
  (target, value) => target.content.setAdvancedEffects(value)
);

export const SelfAdvancedEffectLayerDuplex =
  AbstractDuplexFromReader.defineIdentical(
    (target: Text) => TextCompanionData.of(target).selfLayer,
    (target, value) => {
      const { content } = target;
      return content.setAdvancedEffects({
        ...content.advancedEffects,
        selfLayer: value,
      });
    }
  );

export const OffsetAdvancedEffectLayersDuplex =
  AbstractDuplexFromReader.defineIdentical(
    (target: Text) => target.content.advancedEffects.layers || EMPTY_ARRAY,
    (target, value) => {
      const { content } = target;
      return content.setAdvancedEffects({
        ...content.advancedEffects,
        layers: value,
      });
    }
  );

export const SkewDuplexs = mapValues(
  {
    x: "skewX",
    y: "skewY",
  } as const,
  (key, xy) => {
    const sign = xy === "x" ? -1 : 1;
    return AbstractMapDuplex.defineIdentical(
      (target: TextSpec.TextAdvancedEffectLayer) =>
        sign * (target.transform?.[key] ?? 0),
      (value, source): TextSpec.TextAdvancedEffectLayer => ({
        ...source,
        transform: {
          ...source.transform,
          [key]: value * sign,
        },
      })
    );
  }
);

export const BackgroundsDuplex = AbstractDuplexFromReader.defineIdentical(
  (target: Text): TextSpec.TextLayoutBackgrounds => target.content.backgrounds,
  (target, value) => target.content.setBackgrounds(value)
);

export type FormattedTextBackground = Omit<
  TextSpec.TextEndsBackground,
  "shape"
> &
  Required<Pick<TextSpec.TextEndsBackground, "shape">>;

export type BackgroundEditData = {
  readonly fill: FillingPaintColor;
  readonly stroke: FillingPaintColor;
  readonly shape: TextSpec.TextBackgroundShape;
  readonly scaleX?: number;
  readonly scaleY?: number;
  readonly color?: string;
};

export const formatTextBackground = cached(
  (
    target?: TextSpec.TextEndsBackground
  ): FormattedTextBackground | undefined => {
    if (target) {
      const { shape, hidden } = target;
      if (shape && !hidden) {
        return { ...target, shape };
      }
    }
    return;
  }
);

export const getBackgroundEditData = cached(
  (target: FormattedTextBackground): BackgroundEditData => {
    const { shape, scaleX, scaleY } = target;
    const opacity = formatOpacity(target.alpha);
    const fillColor = target.color;
    const fillOpacity = opacity * formatOpacity(target.colorOpacity);
    const strokeColor = shape.borderColor;
    const strokeOpacity = opacity * formatOpacity(shape.borderColorOpacity);
    return {
      fill: formatColorOpacity({
        kind: "color" as const,
        color: fillColor || "#000",
        opacity: fillColor ? fillOpacity : 0,
      }),
      stroke: formatColorOpacity({
        kind: "color" as const,
        color: strokeColor || "#000",
        opacity: strokeColor ? strokeOpacity : 0,
      }),
      shape,
      scaleX,
      scaleY,
    };
  }
);

export const BackgroundEditDataDuplex = AbstractMapDuplex.defineIdentical(
  getBackgroundEditData,
  (item, source): FormattedTextBackground => {
    const fill = formatColorOpacity(item.fill);
    const stroke = formatColorOpacity(item.stroke);
    return immer(
      {
        ...source,
        shape: item.shape,
        scaleX: item.scaleX,
        scaleY: item.scaleY,
        color: item.color,
      },
      (draft) => {
        delete draft.alpha;
        draft.color = fill.color;
        draft.colorOpacity = fill.opacity;
        const { shape } = draft;
        shape.borderColor = stroke.color;
        shape.borderColorOpacity = stroke.opacity;
      }
    );
  }
);
