import { AnyModelType } from "@chuyuan/poster-data-access-layer";
import { createEventName } from "../../utils/event-system/helpers";
import { SelectionState } from "../editor-state/selection-state";

/**
 * 选择 dal 对象事件
 */
export const SelectGDLObject = createEventName<{
  readonly target: AnyModelType;
}>("select dal object");

/**
 * 清空 dal 对象选择事件
 */
export const ClearGDLObjectSelection = createEventName<{
  readonly selection: SelectionState;
}>("clear dal object selection");
