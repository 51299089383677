import { dethunk, EMPTY_ARRAY } from "@chuyuan/poster-utils";
import { action, makeObservable } from "mobx";
// import { observer } from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import { preventPropagationAndDefault, stopPropagation } from "../../utils/dom";
import { useLocalStore } from "../../utils/mobx-react-hooks";
import { ModuleHostProvider, useModule } from "../../utils/modulize";
import { CanvasOperationModule } from "../canvas-operation";
import { getAutoCursor } from "../dynamic-cursor";
import { EditorState } from "../editor-state/editor-state";
import { EventSystemModule, EventSystemModuleToken } from "../event-system";
import { GraphicLayoutTreePanel } from "../gdl-layout-tree";
import { MyMaterialLayout } from "./my-material-layout";
import { ResourcesMaterialLayout } from "./resources-material-layout";
import { GraphicLayoutTextPanel } from "../gdl-layout-text";
import { StyleEditorSlotModule } from "../gdl-style-panel/module";
import { UseStylePanelWidget } from "../gdl-style-panel/widget";
import { UseTextPanelWidget } from "../gdl-text/widget";
import { DragDropProtector } from "../helpers/drag-drop-protector";
import { EditorContext } from "../helpers/react-context";
import { GlobalContextModule, GlobalContextModuleToken } from "../ui-components/global-context";
// import { Panel } from "../ui-components/panel";
import { Canvas } from "./canvas";
import { Navigator } from "./navigator";
import { TextNavigator } from "./text-navigator";
import { ImageNavigator } from "./image-navigator";
import { EditorSlotModule, EditorSlotModuleToken } from "./editor-slot-module";
import { UseLayoutPanelWidget } from "../gdl-layout/widget";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import { useLocale } from "../../utils/i18n";
import { ContextMenuState } from "../gdl-context-menu";
import { RulesPanel } from "./rules-panel";
import { TabMyIcon, TabTeamIcon, TabTextIcon } from "../../../../poster-ui/icons/new-icons";
import { EditorNavigatorSlotModule } from "./navigator-module";
import { observer } from "mobx-react";
import { Panel } from "../ui-components/panel";

export const Editor = React.memo<React.PropsWithChildren>((props) => {
  return (
    <ModuleHostProvider>
      {props.children}
      <Workspace />
    </ModuleHostProvider>
  );
});

Editor.displayName = "Editor";

type WorkspaceStateProps = {
  readonly editor: EditorState;
};

class WorkspaceState {
  private wrapperRef: HTMLDivElement | null = null;

  constructor(readonly props: WorkspaceStateProps) {
    makeObservable(this);
  }

  componentDidMount() {
    document.addEventListener("touchmove", stopPropagation, { passive: false });
    if (this.wrapperRef) {
      this.wrapperRef.focus();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("touchmove", stopPropagation);
  }

  @action.bound
  getWrapperRef(ref: HTMLDivElement | null) {
    this.wrapperRef = ref;
    this.props.editor.session.ui.workspaceRef = ref;
  }

  @action.bound
  getMainAreaRef(ref: HTMLDivElement | null) {
    this.props.editor.session.ui.mainAreaRef = ref;
  }

  @action.bound
  getCanvasRef(ref: HTMLDivElement | null) {
    this.props.editor.session.ui.canvasRef = ref;
  }

  @action.bound
  onBlur() {
    this.props.editor.session.ui.isWorkspaceFocused = false;
  }

  @action.bound
  onFocus() {
    this.props.editor.session.ui.isWorkspaceFocused = true;
  }
}

const Workspace = React.memo(() => {
  const editor = React.useContext(EditorContext);
  const state = useLocalStore((p) => new WorkspaceState(p), { editor });

  const { session } = editor;
  const locale = useLocale();
  const ctx = useModule(GlobalContextModuleToken);
  const host = useModule(EventSystemModuleToken);
  const menuState = useLocalStore((p) => new ContextMenuState(p), {
    session,
    ctx,
    locale,
    host,
  });

  const [tabActive, setTabActive] = React.useState("1");

  React.useEffect(() => {
    state.componentDidMount();

    document.addEventListener("keydown", (e) => {
      console.log("e.keyCode ", e.keyCode);
      if (e.ctrlKey && e.keyCode === 67) {
        console.log("ctrl+c");
        // menuState.onCopySelection();
      }
      if (e.ctrlKey && e.keyCode === 88) {
        console.log("ctrl+x");
        // menuState.onCutSelection();
      }
      if (e.ctrlKey && e.keyCode === 86) {
        console.log("ctrl+v");
        // menuState.onPasteSelection();
      }
      if (e.ctrlKey && e.keyCode === 71) {
        console.log("ctrl+g");
        menuState.onGroupSelection();
      }
    });
    return () => state.componentWillUnmount();
  }, EMPTY_ARRAY);

  const onChange = (index: string) => {
    console.log("onChange", index);
    setTabActive(index);
  };

  return (
    <WrapperDOM
      tabIndex={-1}
      ref={state.getWrapperRef}
      onClick={stopPropagation}
      onContextMenu={stopPropagation}
      onFocusCapture={state.onFocus}
      onBlurCapture={state.onBlur}
    >
      <DragDropProtector />
      <GlobalContextModule.Register />
      <EventSystemModule.Register />
      <EditorSlotModule.Register />
      <EditorNavigatorSlotModule.Register />
      <StyleEditorSlotModule.Register />
      <UseLayoutPanelWidget />
      <UseTextPanelWidget />
      <UseStylePanelWidget />
      <CanvasOperationModule />
      <Navigator />
      <MainAreaDOM ref={state.getMainAreaRef}>
        <LayerManagerAreaDOM onContextMenu={preventPropagationAndDefault}>
          <Tabs
            tabPosition={"left"}
            defaultActiveKey={tabActive}
            activeKey={tabActive}
            onChange={onChange}
            items={[
              {
                label: (
                  <>
                    <div className="tab-item">
                      <TabTextIcon />
                      文字
                    </div>
                  </>
                ),
                key: "1",
                children: <GraphicLayoutTextPanel session={editor.session} root={editor.session.root} host={host} ctx={ctx} />,
              },
              {
                label: (
                  <>
                    <div className="tab-item">
                      <TabTeamIcon />
                      素材
                    </div>
                  </>
                ),
                key: "3",
                children: <ResourcesMaterialLayout session={editor.session} root={editor.session.root} />,
              },
              {
                label: (
                  <>
                    <div className="tab-item">
                      <TabMyIcon />
                      我的
                    </div>
                  </>
                ),
                key: "4",
                children: <MyMaterialLayout session={editor.session} root={editor.session.root} />,
              },
              {
                label: (
                  <>
                    <div className="tab-item">图层</div>
                  </>
                ),
                key: "5",
                children: <GraphicLayoutTreePanel session={editor.session} root={editor.session.root} />,
              },
              {
                label: (
                  <>
                    <div className="tab-item">测试编辑</div>
                  </>
                ),
                key: "6",
                children: <PropertyArea />,
              },
            ]}
          />
        </LayerManagerAreaDOM>
        <RulesAreaDOM>
          <Tabs
            tabPosition={"right"}
            // defaultActiveKey={tabActive}
            // activeKey={tabActive}
            // onChange={onChange}
            items={[
              {
                label: (
                  <>
                    <div className="tab-item">添加规则</div>
                  </>
                ),
                key: "1",
                children: <RulesPanel />,
              },
              {
                label: (
                  <>
                    <div className="tab-item">图层</div>
                  </>
                ),
                key: "3",
                children: <GraphicLayoutTreePanel session={editor.session} root={editor.session.root} />,
              },
            ]}
          />
        </RulesAreaDOM>
        <CanvasAreaDOM ref={state.getCanvasRef}>
          <TextNavigator
          // openEdit={() => {
          //   onChange("5");
          // }}
          />
          <ImageNavigator
            openEdit={() => {
              onChange("5");
            }}
          />
          <Canvas />
        </CanvasAreaDOM>
      </MainAreaDOM>
    </WrapperDOM>
  );
});

const PropertyArea = observer(() => {
  const EditorSlot = useModule(EditorSlotModuleToken);
  const items = Array.from(EditorSlot?.properties.items || EMPTY_ARRAY);
  const sortedItems = items.sort((a, b) => b.props.priority - a.props.priority);

  // console.log("PropertyArea", {
  //   EditorSlot,
  //   items,
  //   sortedItems,
  // });

  return (
    <>
      {sortedItems.map(({ id, props }) => {
        if (props.kind === "spread") {
          return <React.Fragment key={id}>{dethunk(props.children)}</React.Fragment>;
        }
        return (
          <Panel key={id} title={dethunk(props.title)}>
            {dethunk(props.children)}
          </Panel>
        );
      })}
    </>
  );
});

const WrapperDOM = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 12px;
  font-family: PingFangSC-Light, PingFang SC, Microsoft YaHei, sans-serif;
  line-height: 1.5;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  outline: 0;
  color: rgb(32, 32, 32);
  cursor: ${getAutoCursor()};
`;
WrapperDOM.displayName = "WrapperDOM";

const MainAreaDOM = styled.div`
  flex: 1 1 0;
  overflow: hidden;
  display: flex;
`;
MainAreaDOM.displayName = "MainAreaDOM";

const CanvasAreaDOM = styled.div`
  flex: 1 1 0;
  order: 2;
  position: relative;
  overflow: visible;
  display: flex;
  flex-direction: column;
  background: #fff;
`;
CanvasAreaDOM.displayName = "CanvasAreaDOM";

const LayerManagerAreaDOM = styled.div<{ hasStyleManagementPanel?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  position: relative;
  left: 0;
  top: 0;
  order: 0;
  bottom: 0;
  width: 400px;
  background: #f9f9f9;
  box-shadow: -1px 0 0 0 rgba(0, 0, 0, 0.1);
  resize: horizontal;
  overflow: hidden;
  .ant-tabs {
    flex: 1;
    height: 100%;
    .ant-tabs-nav {
      background-color: #fff;
      .ant-tabs-nav-wrap {
        width: 60px;
        .ant-tabs-tab {
          justify-content: space-around;
          .tab-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            .anticon {
              margin-right: 0;
            }
          }
        }
        .ant-tabs-tab:nth-child(4) {
          /* margin-right: 0; */
          display: none;
        }
        .ant-tabs-tab-active {
          background: #f8f7f6;
          .ant-tabs-tab-btn {
            color: #202020;
          }
        }
      }
    }
    .ant-tabs-content {
      height: 100%;
      .ant-tabs-tabpane {
        overflow-y: auto;
        padding-left: 0px !important;
        height: 100%;
      }
    }
  }
`;
const RulesAreaDOM = styled.div`
  order: 3;
  width: 400px;
  background: #f9f9f9;
  box-shadow: -1px 0 0 0 rgba(0, 0, 0, 0.1);
  resize: horizontal;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  right: 0;
  position: relative;
  z-index: 99;
  // top: 60px;
  bottom: 0;
  .ant-tabs {
    flex: 1;
    height: 100%;
    .ant-tabs-nav {
      background-color: #fff;
      .ant-tabs-nav-wrap {
        width: 60px;
        .ant-tabs-tab {
          justify-content: space-around;
          .tab-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            .anticon {
              margin-right: 0;
            }
          }
        }
        .ant-tabs-tab:nth-child(4) {
          /* margin-right: 0; */
          display: none;
        }
        .ant-tabs-tab-active {
          background: #f8f7f6;
          .ant-tabs-tab-btn {
            color: #202020;
          }
        }
      }
    }
    .ant-tabs-content {
      height: 100%;
      .ant-tabs-tabpane {
        padding-left: 0px !important;
        padding-right: 0px !important;
        height: 100%;
      }
    }
  }
`;
// const PropertyAreaDOM = styled.div`
//   width: 240px;
//   flex: 0 0 auto;
//   order: 3;
//   position: relative;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   display: flex;
//   flex-direction: column;
//   overflow-x: hidden;
//   overflow-y: auto;
// `;
