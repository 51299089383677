// tslint:disable max-line-length

import * as GDS from "@chuyuan/poster-data-structure";
import {
  createFieldValueGuardian,
  memorize,
  KeyMap,
  typedValuesOf,
  castReadonly,
} from "@chuyuan/poster-utils";

import {
  CommonImageFields,
  CommonLinearGradientFields,
  CommonRadialGradientFields,
  CommonColorFields,
  CommonFields,
} from "../filling-paint-common";
import {
  DuplexLike,
  MapDuplex,
  FilterDuplex,
  SpreadKeyDuplex,
  DuplexField,
  ReaderLike,
  ZipReaderWriter,
  ZipReader,
} from "../../../utils/multi-value";
import type {
  FillingPaint,
  FillingPaintImage,
  FillingPaintLinearGradient,
  FillingPaintRadialGradient,
  NullableFillingPaint,
} from "./types";

export const FillingPaintKinds = castReadonly(
  new Set(
    typedValuesOf<KeyMap<FillingPaint["kind"]>>({
      color: "color",
      "linear gradient": "linear gradient",
      "radial gradient": "radial gradient",
      image: "image",
    })
  )
);

export const isFillingPaintEmpty = createFieldValueGuardian(
  "kind",
  "empty" as const
);

export const isFillingPaintColor = createFieldValueGuardian(
  "kind",
  "color" as const
);

export const isFillingPaintLinearGradient = createFieldValueGuardian(
  "kind",
  "linear gradient" as const
);

export const isFillingPaintRadialGradient = createFieldValueGuardian(
  "kind",
  "radial gradient" as const
);

export const isFillingPaintImage = createFieldValueGuardian(
  "kind",
  "image" as const
);

export const DEFAULT_STOPS: readonly GDS.FillingPaint.GradientStop[] = [
  {
    color: "#000",
    offset: 0,
  },
  {
    color: "#fff",
    offset: 1,
  },
];

export type TypeCacheFn = (
  newKind: FillingPaint["kind"],
  oldValue: NullableFillingPaint
) => FillingPaint | undefined;

export type FieldsOptions = {
  readonly typeCache?: ReaderLike<TypeCacheFn>;
};

export class Fields<T extends DuplexLike<NullableFillingPaint>>
  implements CommonFields
{
  constructor(readonly parent: T, readonly options?: FieldsOptions) {}

  @memorize
  get type() {
    const typeCache = this.options?.typeCache;
    if (typeCache) {
      const parent: DuplexLike<NullableFillingPaint> = this.parent;
      return new DuplexField(
        MapDuplex.createIdentical(
          new ZipReaderWriter(
            new ZipReader([parent, typeCache] as const),
            parent
          ),
          (x) => x[0].kind,
          (kind, [oldValue, getCache]) => {
            if (kind === "empty") return { kind };
            const cache = getCache(kind, oldValue);
            if (cache && cache.kind === kind) return cache;
            return getDefaultFillingPaint(kind);
          }
        )
      );
    }
    return new DuplexField(
      MapDuplex.createIdentical(
        this.parent,
        (x) => x.kind,
        getDefaultFillingPaint
      )
    );
  }

  @memorize
  get colors() {
    return new CommonColorFields(
      new FilterDuplex(this.parent, isFillingPaintColor)
    );
  }

  @memorize
  get linearGradients() {
    return new LinearGradientFields(
      new FilterDuplex(this.parent, isFillingPaintLinearGradient)
    );
  }

  @memorize
  get radialGradients() {
    return new RadialGradientFields(
      new FilterDuplex(this.parent, isFillingPaintRadialGradient)
    );
  }

  @memorize
  get images() {
    return new ImageFields(new FilterDuplex(this.parent, isFillingPaintImage));
  }
}

export class LinearGradientFields extends CommonLinearGradientFields<FillingPaintLinearGradient> {
  @memorize
  get opacity() {
    return new DuplexField(new SpreadKeyDuplex(this.parent, "opacity"));
  }
}

export class RadialGradientFields extends CommonRadialGradientFields<FillingPaintRadialGradient> {
  @memorize
  get opacity() {
    return new DuplexField(new SpreadKeyDuplex(this.parent, "opacity"));
  }
}

export class ImageFields<
  T extends DuplexLike<FillingPaintImage>
> extends CommonImageFields<FillingPaintImage> {
  constructor(override readonly parent: T) {
    super(parent);
  }

  @memorize
  get sizing() {
    return new DuplexField(new SpreadKeyDuplex(this.parent, "sizing"));
  }

  @memorize
  get fontSize() {
    return new DuplexField(new SpreadKeyDuplex(this.parent, "fontSize"));
  }

  @memorize
  get opacity() {
    return new DuplexField(new SpreadKeyDuplex(this.parent, "opacity"));
  }
}

export function getDefaultFillingPaint<K extends NullableFillingPaint["kind"]>(
  kind: K
): Extract<NullableFillingPaint, { readonly kind: K }>;
export function getDefaultFillingPaint(
  kind: NullableFillingPaint["kind"]
): NullableFillingPaint {
  switch (kind) {
    case "empty":
      return {
        kind: "empty",
      };
    case "linear gradient":
      return {
        kind: "linear gradient",
        stops: DEFAULT_STOPS,
        angle: 0,
        opacity: 1,
      };
    case "radial gradient":
      return {
        kind: "radial gradient",
        stops: DEFAULT_STOPS,
        opacity: 1,
      };
    case "image":
      return {
        kind: "image",
        sizing: "original",
        scale: 1,
        opacity: 1,
      };
    default:
      return {
        kind: "color",
        color: "#000",
        opacity: 1,
      };
  }
}
