import * as React from "react";
import styled from "styled-components";
import { EMPTY_OBJECT } from "@chuyuan/poster-utils";

import type { LocatorData } from "./popup-locator";

export interface PopoverProps extends React.HTMLAttributes<HTMLDivElement> {
  readonly locator: LocatorData;
  readonly children?: React.ReactNode;
}

/**
 * 含样式的弹出框
 * - 带有一个箭头
 */
export const Popover = React.forwardRef<HTMLDivElement, PopoverProps>(
  (props, ref) => {
    const { locator, children, ...rest } = props;

    const { direction, pointer } = locator;

    return (
      <Container {...rest} ref={ref}>
        <div
          className={`arrow-locator ${direction}`}
          style={
            pointer && {
              ...(direction === "left" || direction === "right"
                ? {
                    top: pointer[1],
                  }
                : direction === "top" || direction === "bottom"
                ? {
                    left: pointer[0],
                  }
                : EMPTY_OBJECT),
            }
          }
          children={<div className="arrow" />}
        />
        {children}
      </Container>
    );
  }
);
Popover.displayName = "Popover";

const Container = styled.div`
  background: #fff;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 4px;

  .arrow-locator {
    position: absolute;
    transform: translate(-50%);
    pointer-events: auto;
    display: none;

    &.top {
      left: 50%;
      top: 0%;
      display: block;
    }

    &.bottom {
      left: 50%;
      top: 100%;
      display: block;
    }

    &.left {
      left: 0%;
      top: 50%;
      display: block;
    }

    &.right {
      left: 100%;
      top: 50%;
      display: block;
    }
  }

  .arrow {
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    background-color: #fff;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.08);
    transform: translateY(-5.6568542495px) rotate(45deg);
    transform-origin: 0 0;
  }
`;
