import { once } from "lodash";
import { cached } from "@chuyuan/poster-utils";
import {
  getEdgeCursorSvg,
  getAutoCursorSvg,
  getCornerRotateCursorSvg,
  getCornerCursorSvg,
} from "./cursors";

// 浏览器在切换指针的时候, 会因为 hotspot 不同而造成闪烁 (包括系统指针在内也有这个现象)
// 除非放不下, 否则我们统一使用 (13, 8) 进行定义

export function gerEdgeCursorCached(degree: number) {
  return cached(getEdgeCursor)(formatDegree(degree));
}

export function getEdgeCursor(degree: number) {
  return `url("data:image/svg+xml;base64,${btoa(
    getEdgeCursorSvg(degree)
  )}") 13 9, ${getEdgeCursorFallback(degree)}`;
}

export function getCornerCursorCached(degree: number) {
  return cached(getCornerCursor)(formatDegree(degree));
}

export function getCornerCursor(degree: number) {
  return `url("data:image/svg+xml;base64,${btoa(
    getCornerCursorSvg(degree)
  )}") 13 9, ${getEdgeCursorFallback(degree)}`;
}

export function gerCornerRotateCursorCached(degree: number) {
  return cached(getCornerRotateCursor)(formatDegree(degree));
}

export function getCornerRotateCursor(degree: number) {
  return `url("data:image/svg+xml;base64,${btoa(
    getCornerRotateCursorSvg(degree)
  )}") 13 9, grab`;
}

const CURSORS = [
  "ew-resize",
  "se-resize",
  "ns-resize",
  "sw-resize",
  "ew-resize",
  "nw-resize",
  "ns-resize",
  "ne-resize",
] as const;

export function getEdgeCursorFallback(degree: number) {
  return CURSORS[((Math.floor(degree / 45) % 8) + 8) % 8];
}

export const getAutoCursor = once(() => {
  return `url("data:image/svg+xml;base64,${btoa(
    getAutoCursorSvg()
  )}") 13 8, auto`;
});

export function formatDegree(degree: number) {
  degree = Number.isFinite(degree) ? degree : 0;
  degree = Math.floor(degree / 3) * 3;
  degree = ((degree % 360) + 360) % 360;
  return degree;
}
