import * as React from "react";
import { useObserver } from "mobx-react";
import { solveTransformAsSxSRT } from "@chuyuan/poster-math";
import {
  RotationState,
  CORNER_VECTOR,
  CORNER_XY_NAME,
  LR_SIG,
  TB_SIG,
} from "./rotation.state";
import { PointerEvent } from "./event";
import { Render } from "./render";

export const RotationEvent = React.memo(
  (props: { readonly state: RotationState }) => {
    const { state } = props;
    return useObserver(() => {
      const { op } = state;
      const root = op.getRoot();
      return (
        <>
          {state.cornerHoverRegisters.map((register, i) => (
            <PointerEvent key={i} root={root.eventRoot} register={register} />
          ))}
          {!state.init ? null : (
            <PointerEvent root={root.eventRoot} register={state.dragRegister} />
          )}
        </>
      );
    });
  }
);
RotationEvent.displayName = "RotationEvent";

export const RotationVisual = React.memo(
  (props: { readonly state: RotationState }) => {
    const { state } = props;
    return useObserver(() => {
      const { op } = state;
      const root = op.getRoot();
      return (
        <>
          {/* <RotationHandle state={state} /> */}
          <Render
            root={root.renderInReact}
            props={{
              ...state.degreeTextRenderProps,
              children: <RotationDegree state={state} />,
            }}
          />
        </>
      );
    });
  }
);
RotationVisual.displayName = "RotationVisual";

// 旋转角度文本

function RotationDegree(props: { readonly state: RotationState }) {
  const { state } = props;
  return useObserver(() => {
    const { init } = state;
    const corner = init ? init.corner : state.hoveringCorner;
    if (!corner) return null;

    const { op } = state;

    const dim = op.getDimension();

    const scale = op.getRoot().uiScale;

    const degree = solveTransformAsSxSRT(op.getTransform().toArray()).θ;
    const text = `${Math.round(op.getDegree())}°`;

    const transform = op.getTransform().toArray();

    const cv = CORNER_VECTOR[corner];
    const cn = CORNER_XY_NAME[corner];
    const sx = LR_SIG[cn.x];
    const sy = TB_SIG[cn.y];
    const DISTANCE = 32;

    return (
      <div
        style={{
          position: "absolute",
          // tslint:disable-next-line: max-line-length
          transform: `matrix(${transform}) translate(${cv[0] * dim.width}px, ${
            cv[1] * dim.height
          }px) scale(${scale}) translate(${sx * DISTANCE}px, ${
            sy * DISTANCE
          }px) rotate(${-degree}deg) translate(-50%, -50%)`,
          transformOrigin: "0 0",
        }}
      >
        <span
          style={{
            padding: 8,
            color: "#fff",
            background: "rgba(0,0,0,0.6)",
            borderRadius: 2,
            fontSize: 12,
            lineHeight: 1,
          }}
        >
          {text}
        </span>
      </div>
    );
  });
}
