import { SortedArray, identity } from "@chuyuan/poster-utils";
import { observable, computed, ObservableMap, makeObservable } from "mobx";

type OverrideValueSortedItem<T> = {
  readonly id: number;
  readonly data: T;
};

type Sorter<T> = (a: T, b: T) => number;

export function createOverrideValueSorted<T>() {
  function withToValue<D extends T | undefined>(
    defaultValue: D
  ): (sortData?: Sorter<T>) => OverrideValueSorted<T, D>;

  function withToValue<R>(
    toValue: (x: T) => R
  ): (sortData?: Sorter<T>) => OverrideValueSorted<T, undefined, R>;

  function withToValue<R, D extends R | undefined>(
    toValue: (x: T) => R,
    defaultValue: D
  ): (sortData?: Sorter<T>) => OverrideValueSorted<T, D, R>;

  function withToValue<R, D extends R | undefined>(
    ...args: [D | ((x: T) => R)] | [(x: T) => R, D]
  ): (sortData?: (a: T, b: T) => number) => OverrideValueSorted<T, D, R> {
    const [toValue, defaultValue] = ((): [(x: T) => R, D] => {
      if (args.length === 2) return args;
      const arg = args[0];
      if (typeof arg === "function")
        return [arg as (x: T) => R, undefined as unknown as D];
      return [identity as (x: T) => R, arg];
    })();

    return function withSortData(sortData?: (a: T, b: T) => number) {
      if (!sortData) sortData = (() => 0) as (a: T, b: T) => number;
      return new OverrideValueSorted<T, D, R>(sortData, toValue, defaultValue);
    };
  }
  return withToValue;
}

/**
 * 一个可以排序的值覆盖对象
 * - 取排序后列表索引第一个
 * - 弱水三千，只取一瓢
 */
export class OverrideValueSorted<
  T,
  D extends R | undefined = undefined,
  R = T
> {
  private nextId = 0;

  private sort: (
    a: OverrideValueSortedItem<T>,
    b: OverrideValueSortedItem<T>
  ) => number;

  private readonly list: SortedArray<OverrideValueSortedItem<T>>;

  constructor(
    sortFn: (a: T, b: T) => number,
    private readonly toValue: (x: T) => R,
    private readonly defaultValue: D
  ) {
    this.sort = (a, b) => {
      const d1 = a.data;
      const d2 = b.data;
      const dc = sortFn(d1, d2);
      if (dc) return dc;
      const id1 = a.id;
      const id2 = b.id;
      return id1 < id2 ? 1 : id1 > id2 ? -1 : 0;
    };

    this.list = new SortedArray<OverrideValueSortedItem<T>>(
      undefined,
      this.sort,
      () => observable.array(undefined, { deep: false })
    );

    makeObservable(this);
  }

  get size() {
    return this.list.native.length;
  }

  @computed
  get value(): R | Extract<D, undefined> {
    const list = this.list.native;
    if (list.length) return this.toValue(list[0].data);
    return this.defaultValue as R;
  }

  create(data: T) {
    const obj: OverrideValueSortedItem<T> = { data, id: this.nextId++ };
    this.list.insert(obj);
    return () => {
      this.list.remove(obj);
    };
  }
}

export class MergeSignal<V, R = V> {
  private readonly map = new ObservableMap<string, V>();

  constructor(private readonly merger: (map: ObservableMap<string, V>) => R) {
    makeObservable(this);
  }

  @computed
  private get value() {
    return this.merger(this.map);
  }

  get() {
    return this.value;
  }

  set(key: string, value: V) {
    this.map.set(key, value);
    return this;
  }

  delete(key: string) {
    this.map.delete(key);
    return this;
  }
}
