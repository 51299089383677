import {
  applyDocumentToDataStore,
  ExportDocumentFromFrame,
} from "@chuyuan/poster-data-access-layer";
import { Document } from "@chuyuan/poster-data-structure";
import {
  EMPTY_ARRAY,
  EventEmitter,
  Mutable,
  runSafeSync,
} from "@chuyuan/poster-utils";
import { observable, makeObservable, IObservableArray } from "mobx";
import { v4 as uuid } from "uuid";
import { SelectionTargetId } from "./selection-state";
import { SessionState } from "./session-state";

/**
 * 历史记录条目
 */
export interface HistoryRecord {
  readonly id: string;
  /**
   * 操作的名字
   */
  readonly name: string;
  /**
   * 海报数据快照
   */
  readonly data: Document;
  /**
   * 时间戳, 单位ms
   */
  readonly timestamp: number;
}

/**
 * 历史记录输入
 */
export type HistoryRecordInput = Omit<
  HistoryRecord,
  "id" | "data" | "timestamp"
>;

export type HistoryStateEventMap = {
  internalChange(record: HistoryRecord): void;
  push(record: HistoryRecord): void;
  replace(record: HistoryRecord): void;
  apply(record: HistoryRecord): void;
};

export class HistoryState {
  /**
   * @internal
   */
  @observable.ref
  _cursor = 0;

  /**
   * @internal
   */
  readonly _list: IObservableArray<HistoryRecord>;

  readonly _selection = observable.map<string, readonly SelectionTargetId[]>(
    [],
    { deep: false }
  );

  readonly events = new EventEmitter<HistoryStateEventMap>();

  constructor(readonly session: SessionState) {
    this._list = observable.array<HistoryRecord>([this.createInitialRecord()], {
      deep: false,
    });

    // 同步选择项
    this.session.selection.events.on("change", () => {
      // console.log('event-change');
      this._updateSelection(this._list[this._cursor].id);
    });

    makeObservable(this);
  }

  get records(): readonly HistoryRecord[] {
    return this._list;
  }

  /**
   * 当前记录序号
   */
  get cursor() {
    return this._cursor;
  }

  /**
   * 获取是否能够撤销
   */
  get canUndo() {
    return this._cursor > 0;
  }

  /**
   * 获取是否能够重做
   */
  get canRedo() {
    return this._cursor < this._list.length - 1;
  }

  /**
   * 是否有效的序数
   * @param index 序数
   */
  isValidIndex(index: number) {
    return typeof index === "number" && index >= 0 && index < this._list.length;
  }

  createInitialRecord(): Mutable<HistoryRecord> {
    const data = new ExportDocumentFromFrame(this.session.root).result;
    return {
      id: "init",
      name: "init",
      data,
      timestamp: Date.now(),
    };
  }

  createRecord(input: HistoryRecordInput): Mutable<HistoryRecord> {
    return {
      ...input,
      id: uuid(),
      timestamp: Date.now(),
      data: new ExportDocumentFromFrame(this.session.root).result,
    };
  }

  /**
   * 往历史栈最后加一条记录
   * @param input 历史记录输入
   */
  push(input: HistoryRecordInput) {
    const currentCursor = this._cursor;
    const list = this._list;

    const record = this.createRecord(input);

    list.splice(currentCursor + 1, list.length, record);

    this._updateSelection(record.id);

    this._cursor = list.length - 1;

    runSafeSync(() => {
      this.events.emit("internalChange", record);
      this.events.emit("push", record);
    });
  }

  /**
   * 替换历史栈最后加一条记录
   * @internal
   * @param input 历史记录输入
   */
  replace(input: HistoryRecordInput) {
    if (!this.canUndo) {
      throw new Error(`cannot replace initial state`);
    }

    const list = this._list;

    const record = this.createRecord(input);

    list.splice(list.length, list.length, record);

    const removedRecords = this._list.splice(this._cursor, list.length, record);

    this._updateSelection(record.id);
    this._gcSelection(removedRecords);

    runSafeSync(() => {
      this.events.emit("internalChange", record);
      this.events.emit("replace", record);
    });
  }

  /**
   * 往历史栈最后加一条记录, 如果名字相同, 则合并动作
   * @param input 历史记录输入
   */
  pushOrReplace(input: HistoryRecordInput) {
    const cursor = this._cursor;
    if (this._list[cursor].name === input.name) {
      return this.replace(input);
    } else {
      return this.push(input);
    }
  }

  /**
   * 撤销
   */
  undo() {
    return this.apply(this._cursor - 1);
  }

  /**
   * 重做
   */
  redo() {
    return this.apply(this._cursor + 1);
  }

  /**
   * 获取某一条历史记录
   */
  get(index: number) {
    if (!this.isValidIndex(index)) return;
    return this._list[index];
  }

  /**
   * 使用某一条历史记录
   */
  apply(index: number) {
    if (!this.isValidIndex(index)) return false;
    const record = this._list[index];

    applyDocumentToDataStore(this.session.ds, record.data);

    const selectedIds = this._selection.get(record.id);
    this.session.selection.apply(selectedIds || EMPTY_ARRAY);

    this._cursor = index;

    runSafeSync(() => {
      this.events.emit("internalChange", record);
      this.events.emit("push", record);
    });
    return true;
  }

  /**
   * 恢复记录
   */
  recover(records: readonly HistoryRecord[]) {
    if (!records.length) {
      throw new Error(`Cannot set empty records`);
    }

    const list = this._list;
    list.splice(0, list.length, ...records);
    const cursor = records.length - 1;
    const record = records[cursor];
    this._cursor = cursor;

    applyDocumentToDataStore(this.session.ds, record.data);
  }

  /**
   * @internal
   */
  _updateSelection(id: string) {
    const { selection } = this.session;
    const ids = Array.from(selection.set);
    this._selection.set(id, ids);
  }

  /**
   * @internal
   */
  _gcSelection(removedRecords: Iterable<HistoryRecord>) {
    const selection = this._selection;
    for (const record of removedRecords) {
      selection.delete(record.id);
    }
  }
}
