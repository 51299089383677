import { useObserver } from 'mobx-react'
import * as React from 'react'

import { createModuleToken, implementModule } from '../../utils/modulize'
import { PortalContainer } from '../../utils/react-portal-mobx'

export const GlobalContextModuleToken = createModuleToken<GlobalContext>('GlobalContextModule')

/**@name message, dialog, notification之类的窗口, 需要注册到一个位置 在整套 ui-components 框架中, 这个位置由 GlobalContext 类的实例提供 */
export const GlobalContextModule = implementModule(GlobalContextModuleToken, {
  state: () => new GlobalContext(),
  render: (init) => {
    return useObserver(() => {
      if (init.state === 'resolved') {
        const items = init.data.Portal.items
        if (items.size) {
          const nodes: React.ReactNode[] = []
          for (const x of items) {
            nodes.push(<React.Fragment key={x.id} children={x.props.children} />)
          }
          return <>{nodes}</>
        }
      }
      return null
    })
  }
})

export class GlobalContext {
  readonly Portal = new PortalContainer<{
    readonly children?: React.ReactNode
  }>()
}
