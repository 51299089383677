import * as React from "react";
import { observer, useObserver } from "mobx-react";

import { dethunk } from "@chuyuan/poster-utils";

import { useModule } from "../../utils/modulize";
import { StyleEditorSlotModuleToken, StylePanelState } from "./module";
import { StylePanelDefaultBlocks } from "./style-panel-default";
import { SelectableTarget } from "../editor-state/types";

export const StylePanel = React.memo(
  (props: { readonly targets: ReadonlySet<SelectableTarget> }) => {
    const state = useModule(StyleEditorSlotModuleToken);

    return useObserver(() => {
      return (
        <>
          {state && <StylePanelInjection state={state} />}
          <StylePanelDefaultBlocks targets={props.targets} />
        </>
      );
    });
  }
);
StylePanel.displayName = "StylePanel";

const StylePanelInjection = observer(
  (props: { readonly state: StylePanelState }) => {
    const { items } = props.state.portal;

    const list = Array.from(items).sort((a, b) => {
      const v1 = a.props.priority ?? 0;
      const v2 = b.props.priority ?? 0;
      if (v1 < v2) return v2;
      if (v1 > v2) return v1;
      const id1 = a.id;
      const id2 = b.id;
      return id1 < id2 ? -1 : id1 > id2 ? 1 : 0;
    });

    return (
      <>
        {list.map((p) => (
          <React.Fragment key={p.id} children={dethunk(p.props.children)} />
        ))}
      </>
    );
  }
);
