import { createModuleToken, implementModule } from "../../utils/modulize";
import { PortalContainer } from "../../utils/react-portal-mobx";

export const EditorNavigatorSlotModuleToken = createModuleToken<EditorNavigatorSlotState>("EditorNavigatorSlotModule");

/**@name 导航栏模块注册 */
export const EditorNavigatorSlotModule = implementModule(EditorNavigatorSlotModuleToken, {
  state: () => new EditorNavigatorSlotState(),
});

export type EditorSlotItem = {
  /**
   * 优先级, 值越大越靠上
   * - 默认值 0
   */
  readonly priority: number;
  /**
   * 渲染函数
   */
  readonly children: React.ReactNode | (() => React.ReactNode);
};

export type EditorNavigatorSlotItem = EditorSlotItem &
  (
    | {
        readonly kind?: undefined;
        /**
         * 标题
         */
        readonly title?: React.ReactNode | (() => React.ReactNode);
      }
    | {
        readonly kind: "spread";
      }
  );

export class EditorNavigatorSlotState {
  /**
   * 导航栏右侧部分
   */
  readonly navBarRight = new PortalContainer<EditorSlotItem>();

  /**
   * 画面之上
   */
  readonly aboveGraphic = new PortalContainer<EditorSlotItem>();

  /**
   * 属性面板
   */
  readonly properties = new PortalContainer<EditorNavigatorSlotItem>();
}
