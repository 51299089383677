/**
 * @author: songlei 190835837@qq.com
 * @create: 2022-08-02 12:20:36
 * @LastEditors: songlei 190835837@qq.com
 * @lastTime: 2022-11-15 00:34:58
 * @description: 图片选择组件
 */
import { Button, Empty, Image, message } from "antd";
import * as React from "react";
import "./index.css";
import Axios from "axios";
import classNames from "classnames";
import { UploadChangeParam } from "antd/lib/upload";
import axios from "axios";
import { ArrayUtils } from "great-jsutils";
import _ from "lodash";
import { unstable_batchedUpdates } from "react-dom";

var dedupe = require("dedupe");

const CTabs = [
  {
    name: "团队",
    value: "team",
  },
  // {
  //   name: "我的",
  //   value: "my"
  // }
];

let chooseRef: HTMLDivElement;
let page = 1;
// 图片选择
export const PicturesChoose = (props: any) => {
  const [tabActive, setTabActive] = React.useState("team");
  const [visible, setVisible] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [pageNum, setPageNum] = React.useState(1);
  const [list, setList] = React.useState([]);
  const [checkList, setCheckList] = React.useState([]);
  const [percent, setPercent] = React.useState(null);
  const [total, setTotal] = React.useState(0);
  const [fileType, setFileType] = React.useState([]); // 筛选条件：文件格式|类型 PSD JPG ...
  const [hasMoreData, setHasMoreData] = React.useState(true); // 是否有更多数据
  const [bottom, setBottom] = React.useState(0);
  const [locker, setLocker] = React.useState(true);

  const pageSize = 50;
  const sortField = "create_time";
  const folderids = [1];

  let source;
  // let locker = true;

  // 点击取消
  const onCancel = (e: any) => {
    setVisible(false);
    props.onCancel && props.onCancel();
  };

  React.useEffect(() => {
    if (visible) {
      bindScrollEvent();
      // console.log('useEffect', 'visible');
      srcLibPage();
    }
  }, [visible]);

  React.useEffect(() => {
    setVisible(props.visible);
    setCheckList([]);
    // console.log('useEffect');
  }, [props.visible]);

  React.useEffect(() => {
    if (tabActive == "team") {
      srcLibPage();
    } else {
      favoritesList();
    }
  }, [tabActive]);

  const onOk = (e: any) => {
    props.onOk && props.onOk(checkList);
    setVisible(false);
  };

  const onTabChange = (key: string) => {
    setList([]);
    setTabActive(key);
  };

  const onSearchConfirm = () => {};

  const onSearchChange = (options: any) => {
    console.log("options", options);
  };

  // 列表
  const srcLibPage = async () => {
    //   let data: ChuYuan.ISearchFileListType = {
    //     pageNum,
    //     pageSize,
    //     srcGroup: 1,
    //     sortField,
    //     fileSize: "0", // 尺寸
    //     suffixs: '0', // 格式
    //     folderids: folderids.join(',')}
    //   // SourceService.srcLibPage(data).then(res => {
    //   //   unstable_batchedUpdates(()=>{
    //   //     setLocker(true);
    //   //     setList(list.concat(res.data.list));
    //   //     setTotal(res.data.total);
    //   //   })
    //   // });

    let result = await Axios.post(
      `https://demo.chuyuanai.com/file/srcManager/list?pageNum=${pageNum}&pageSize=${pageSize}`,
      {
        // headers: { 'Authorization': 'eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2lkIjozLCJ1c2VyX2tleSI6IjZmZjBlOGY4LWQxMzItNGU3Ny1iMDJjLWJhZTJkYzc0NDExMiIsInVzZXJuYW1lIjoidGVzdDEifQ.GMQcYD2rN2GtY8J7na0vHagniPdqpMywTXh6VEdD-wfA31-9nXymSW7rlDUnp-X1YXYUoR9L2CG1qYQiBN9RJQ' },
      }
    );
    console.log("srcLibPage", result);
    if (result.data.code == 200) {
      unstable_batchedUpdates(() => {
        setLocker(true);
        setList(list.concat(result.data.data.rows));
        setTotal(result.data.data.pagination.total);
      });
    }
  };
  // 我的收藏列表
  const favoritesList = () => {
    // SourceService.favoritesSee({
    //   pageNum,
    //   pageSize,
    // }).then(res => {
    //   // console.log('favoritesList', res);
    //   res.data && res.data.map((item:any, index:number)=>{
    //     let json = _.cloneDeep(item.srclibJson);
    //     let json1 = _.cloneDeep(item.fileJson);
    //     // console.log('item', {...json, ...json1, ...item});
    //     res.data[index] = {...json, ...json1, ...item};
    //   });
    //   // console.log('res', res.data);
    //   setList(res.data);
    // });
  };

  // 上传中
  const onUploadChange = async (info: UploadChangeParam) => {
    if (info.file.status === "done") {
      setFile(info.file.response.result);
      // console.log('onUploadChange', info);
      const fileId = info.file.response.data[0].fileid;
      const libraryId = 1;
      const srcType = 0; // 来源类型：0-素材，1-插图，2-摄影
      // await FileService.insertLibraryFile({
      //   fileid: fileId,
      //   parentid: libraryId,
      //   srcType
      // });
      setList([]);
      setTabActive("team");
      window.scrollTo(0, 0);
      srcLibPage();
    } else {
      setPercent(info.file.percent);
    }
  };

  // 自定义上传
  const customRequest = (option: any) => {
    const {
      action,
      withCredentials,
      headers,
      filename,
      file,
      onProgress,
      onSuccess,
      onError,
    } = option;

    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
      source = axios.CancelToken.source();

      const formData = new FormData();
      formData.append(filename, file);
      axios
        .post(action, formData, {
          withCredentials,
          headers,
          cancelToken: source.token,
          onUploadProgress: ({ total, loaded }) => {
            onProgress(
              {
                percent: Number(Math.round((loaded / total) * 100).toFixed(2)),
              },
              file
            );
          },
        })
        .then(({ data: response }) => {
          onSuccess(response, file);
        })
        .catch(() => {
          onError("失败");
        });
    } else {
      return message.error("仅支持上传png jpg jpeg格式");
    }
  };

  // 单个元素选择
  const onCheckChoose = (item: any, isCheck: boolean) => {
    let cList = checkList;
    if (isCheck) {
      ArrayUtils.remove(cList, { smId: item.smId }, { idKey: "smId" });
      setCheckList(_.cloneDeep(cList));
    } else {
      if (cList.length == props.maxCount) {
        ArrayUtils.remove(
          cList,
          { smId: checkList[0]?.smId },
          { idKey: "smId" }
        );
      }
      const isAdd = ArrayUtils.add(cList, item);
      cList = dedupe(cList);
      if (isAdd) {
        setCheckList(cList);
      }
    }
    // console.log('onCheckChoose', cList, isCheck);
  };

  // 监听页面滚动
  const scrollEvent = () => {
    // console.log(chooseRef);
    const clientHeight = dom.body.clientHeight;
    const elementBottom = chooseRef.getBoundingClientRect().bottom;

    if (list.length === 0) return;
    // console.log(elementBottom, clientHeight, elementBottom - clientHeight)
    if (clientHeight > elementBottom - 400) {
      if (!hasMoreData) return;
      // return;
      if (locker && pageNum < total / pageSize) {
        // console.log('scroll', { hasMoreData, locker, pageNum, total, elementBottom, page });
        page = page + 1;
        // unstable_batchedUpdates(()=>{
        setLocker(false);
        setPageNum(page);
        setHasMoreData(pageNum !== total / pageSize);
        // })
      } else {
        console.log("scroll-no", {
          hasMoreData,
          locker,
          pageNum,
          total,
          elementBottom,
          pageSize,
        });
      }
    }
  };

  // 绑定滚动事件
  const bindScrollEvent = () => {
    // document.querySelector('.choose-wrapper').addEventListener('scroll', scrollEvent, false);
    // window.addEventListener("scroll", scrollEvent);
  };

  // 更新
  React.useEffect(() => {
    // console.log('pageNum', 'hasMoreData');
    srcLibPage();
    page = pageNum;
  }, [pageNum]);

  // console.log("====", {pageNum, hasMoreData, locker});

  return (
    <>
      <div className="tabs">
        {CTabs.map((item) => {
          const activeCls = classNames(
            "tab-pane",
            tabActive == item.value && "tab-active"
          );
          return (
            <div
              className={activeCls}
              onClick={onTabChange.bind(this, item.value)}
            >
              {item.name}
            </div>
          );
        })}
      </div>
      <div className="choose-wrapper">
        <div className="filter-view">
          {/* <SmallSearchFilter onConfirm={onSearchConfirm} onChange={onSearchChange} /> */}
          {/* <Upload
            showUploadList={false}
            headers={{ 'Authorization': utils.getToken() }}
            action={`${HTTP_URL.MAIN}/file/upload2Cos`}
            onChange={onUploadChange}
            customRequest={customRequest}
          >
            <div className='upload-text'>上传图片</div>
          </Upload> */}
        </div>
        {/* <div className='choose-content' ref={ele => (chooseRef = ele)}> */}
        <div className="choose-content">
          {list.length == 0 && <Empty description="暂无数据" />}
          {list.map((item: any) => {
            const c = checkList.filter((e: any) => {
              return e.smId == item.smId;
            });
            const isCheck = c.length > 0;
            // console.log('isCheck', {
            //   isCheck,
            //   checkList,
            //   list,
            //   item
            // });
            if (item.fileName == null) {
              return;
            }
            const activeCls = classNames(
              "choose-item",
              isCheck && "choose-active"
            );
            return (
              <div
                className={activeCls}
                onClick={onCheckChoose.bind(this, item, isCheck)}
              >
                <Image
                  src={item.fileUrl}
                  className="choose-img"
                  preview={false}
                />
                <div className="choose-name">{item.fileName}</div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="drawer-footer">
        <div className="choose-view">
          已选中
          <div className="choose-num">{checkList.length}</div>项
        </div>
        <div className="choose-btns">
          <Button onClick={onCancel}>取消</Button>
          <Button type="primary" onClick={onOk}>
            确定
          </Button>
        </div>
      </div>
    </>
  );
};

function bindScrollEvent() {
  throw new Error("Function not implemented.");
}

function favoritesList() {
  throw new Error("Function not implemented.");
}
