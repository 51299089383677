// tslint:disable max-line-length

import * as React from "react";
import styled from "styled-components";
import immer from "immer";
import {
  ColorChangeHandler,
  SketchPickerProps,
  ColorResult,
} from "react-color";
import * as tinycolor from "tinycolor2";
import { useHotkeys } from "react-hotkeys-hook";

import { isEqual } from "@chuyuan/poster-utils";

import { StyledSketchPicker } from "./sketch-picker";

export type InputGradientStop = {
  readonly color?: string;
  readonly opacity?: number;
  readonly offset?: number;
  readonly interpolation?: number;
};

export type GradientStop = {
  readonly color: string;
  readonly opacity: number;
  readonly offset: number;
  readonly interpolation?: number;
};

type InternalGradientStop = {
  readonly id: number;
  readonly color: string;
  readonly opacity: number;
  readonly offset: number;
  readonly interpolation: number;
};

export type GradientPickerProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "stops" | "onChange" | "onChangeComplete"
> & {
  readonly angle?: React.ReactNode | (() => React.ReactNode);
  readonly disabledInterpolation?: boolean;
  readonly stops?: readonly InputGradientStop[];
  readonly onChange?: (stops: readonly GradientStop[]) => void;
  readonly onChangeComplete?: (stops: readonly GradientStop[]) => void;
};

type MutableState = {
  readonly selectedId: number;
  readonly stops: readonly InternalGradientStop[];
};

type ReactStateObject<T> = {
  readonly current: T;
  readonly update: React.Dispatch<React.SetStateAction<T>>;
};

class State {
  constructor(
    public props: GradientPickerProps,
    public boxed: ReactStateObject<MutableState>,
    public barRef: { readonly current: HTMLDivElement | null }
  ) {}

  updateProps(nextProps: GradientPickerProps) {
    const { props } = this;
    this.props = nextProps;
    if (
      nextProps.stops !== props.stops &&
      nextProps.stops !== this.currentOutput
    ) {
      const currentStops = this.boxed.current.stops;
      const nextStops = formatGradientStops(nextProps.stops);
      if (!isEqual(currentStops, nextStops)) {
        this.boxed.update((state) => ({
          ...state,
          stops: nextStops,
        }));
      }
    }
  }

  currentOutput = this.boxed.current.stops.map(formatOutputStop);

  dragStartData = {
    x: 0,
    y: 0,
    id: -1,
    interpolation: false,
  };

  isMouseDown = false;

  componentWillUnmount() {
    this.unmountDraggingEvent();
  }

  mountDraggingEvent() {
    document.addEventListener("mousemove", this.onMouseMove);
    document.addEventListener("mouseup", this.onMouseUp);
  }

  unmountDraggingEvent() {
    document.removeEventListener("mousemove", this.onMouseMove);
    document.removeEventListener("mouseup", this.onMouseUp);
  }

  emitChange(stops: readonly InternalGradientStop[], complete: boolean) {
    const output = stops.map(formatOutputStop);
    this.currentOutput = output;
    this.props.onChange?.(output);
    if (complete) {
      this.props.onChangeComplete?.(output);
    }
  }

  onPickerChange: ColorChangeHandler = (color) =>
    this.emitPickerChange(color, false);

  onPickerChangeComplete: ColorChangeHandler = (color) =>
    this.emitPickerChange(color, true);

  emitPickerChange(color: ColorResult, complete: boolean) {
    const nextState = immer(this.boxed.current, (state) => {
      const { selectedId, stops } = state;
      const stop = stops.find((x) => x.id === selectedId);
      if (!stop) return;
      const tc = tinycolor(color.rgb);
      stop.opacity = tc.getAlpha();
      stop.color = tc.setAlpha(1).toString();
    });
    this.boxed.update(nextState);
    this.emitChange(nextState.stops, complete);
  }

  onDelete = () => {
    if (this.isMouseDown) return;
    this.boxed.update((_) =>
      immer(_, (state) => {
        const { selectedId, stops } = state;
        if (stops.length < 2) return;
        const index = stops.findIndex((x) => x.id === selectedId);
        if (index >= 0) {
          const stop = stops[index];
          stops.splice(index, 1);
          const prev = index ? stops[index - 1] : undefined;
          const next = index < stops.length ? stops[index] : undefined;
          const prevDistance = prev
            ? Math.abs(stop.offset - prev.offset)
            : Infinity;
          const nextDistance = next
            ? Math.abs(stop.offset - next.offset)
            : Infinity;
          state.selectedId = prevDistance < nextDistance ? prev!.id : next!.id;
        }
      })
    );
  };

  onBarMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.buttons !== 1) return;
    const bar = this.barRef.current;
    if (!bar) return;

    e.preventDefault();
    e.stopPropagation();

    const { stops } = this.boxed.current;
    const rect = bar.getBoundingClientRect();
    const offset = getAreaClickOffset(e.pageX, rect);

    const tc = getOffsetColorInStops(stops, offset);
    const opacity = tc.getAlpha();
    tc.setAlpha(1);

    const nextId =
      1 +
      this.boxed.current.stops.reduce((id, x) => (id < x.id ? x.id : id), -1);

    const stop: InternalGradientStop = {
      id: nextId,
      color: tc.toString(),
      opacity,
      offset,
      interpolation: 0.5,
    };

    this.dragStartData = {
      x: e.pageX,
      y: e.pageY,
      id: stop.id,
      interpolation: false,
    };

    this.boxed.update((x) => ({
      ...x,
      stops: [...x.stops, stop].sort(stopsSorter),
      selectedId: stop.id,
    }));

    this.onMouseDown();
  };

  onUpperPointerMouseDown(index: number) {
    return (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (e.buttons !== 1) return;
      const bar = this.barRef.current;
      if (!bar) return;

      e.preventDefault();
      e.stopPropagation();

      const stop = this.boxed.current.stops[index];
      this.dragStartData = {
        x: e.pageX,
        y: e.pageY,
        id: stop.id,
        interpolation: true,
      };

      this.boxed.update((x) => ({ ...x, selectedId: stop.id }));

      this.onMouseDown();
    };
  }

  onLowerPointerMouseDown(index: number) {
    return (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (e.buttons !== 1) return;
      const bar = this.barRef.current;
      if (!bar) return;

      e.preventDefault();
      e.stopPropagation();

      const stop = this.boxed.current.stops[index];
      this.dragStartData = {
        x: e.pageX,
        y: e.pageY,
        id: stop.id,
        interpolation: false,
      };

      this.boxed.update((x) => ({ ...x, selectedId: stop.id }));

      this.onMouseDown();
    };
  }

  onMouseDown() {
    this.isMouseDown = true;
    this.mountDraggingEvent();
  }

  onMouseMove = (e: MouseEvent) => {
    const bar = this.barRef.current;
    if (!bar) return;

    const init = this.dragStartData;

    const rect = bar.getBoundingClientRect();
    const offset = getAreaClickOffset(e.pageX, rect);

    this.boxed.update((state) =>
      immer(state, ({ stops }) => {
        const index = stops.findIndex((x) => x.id === init.id);
        if (index < 0) return;
        const stop = stops[index];
        if (init.interpolation) {
          if (index < stops.length - 1) {
            const nextStop = stops[index + 1];
            const formattedOffset =
              offset < stop.offset
                ? stop.offset
                : offset > nextStop.offset
                ? nextStop.offset
                : offset;
            const offsetDiff = nextStop.offset - stop.offset;
            if (offsetDiff) {
              stop.interpolation = (formattedOffset - stop.offset) / offsetDiff;
            } else {
              stop.interpolation = 0.5;
            }
          }
        } else {
          stop.offset = offset;
          stops.sort(stopsSorter);
        }
      })
    );

    if (!(e.buttons & 1)) {
      this.onMouseUp();
    }
  };

  onMouseUp = () => {
    this.isMouseDown = false;
    this.unmountDraggingEvent();
    this.emitChange(this.boxed.current.stops, true);
  };
}

const DEFAULT_STOPS: ReadonlyArray<Omit<InternalGradientStop, "id">> = [
  {
    color: "#fff",
    opacity: 0,
    offset: 0,
    interpolation: 0.5,
  },
  {
    color: "#fff",
    opacity: 0,
    offset: 1,
    interpolation: 0.5,
  },
];

const EMPTY_ARRAY: readonly never[] = [];

export const GradientPicker = React.memo((props: GradientPickerProps) => {
  const barRef = React.useRef<HTMLDivElement | null>(null);

  const [mutableState, setMutableState] = React.useState<MutableState>(() => ({
    selectedId: 0,
    stops: formatGradientStops(props.stops),
  }));

  const boxed: ReactStateObject<MutableState> = {
    current: mutableState,
    update: setMutableState,
  };

  const state = React.useMemo(
    () => new State(props, boxed, barRef),
    EMPTY_ARRAY
  );
  state.boxed = boxed;
  state.barRef = barRef;
  state.updateProps(props);

  React.useEffect(() => () => state.componentWillUnmount(), EMPTY_ARRAY);

  useHotkeys("backspace, delete", state.onDelete);

  const { stops, selectedId } = mutableState;

  const lowerPointerNodes = stops.map((stop, i) => {
    return (
      <LowerPointerLocator
        key={stop.id}
        onMouseDown={state.onLowerPointerMouseDown(i)}
        onDoubleClick={state.onDelete}
        style={{ left: `${stop.offset * 100}%` }}
      >
        <LowerPointerIcon
          color={stop.color}
          selected={mutableState.selectedId === stop.id}
        />
      </LowerPointerLocator>
    );
  });

  const interpolationNodes: JSX.Element[] = [];
  if (!props.disabledInterpolation) {
    for (let i = 0, len = stops.length - 1; i < len; i++) {
      const stop = stops[i];
      const nextStop = stops[i + 1];
      const offset =
        stop.offset + (nextStop.offset - stop.offset) * stop.interpolation;
      interpolationNodes.push(
        <UpperPointerLocator
          key={stop.id}
          onMouseDown={state.onUpperPointerMouseDown(i)}
          style={{ left: `${offset * 100}%` }}
        >
          <UpperPointerIcon />
        </UpperPointerLocator>
      );
    }
  }

  const selectedStop = stops.find((x) => x.id === selectedId);

  const {
    stops: _0,
    onChange: _1,
    onChangeComplete: _2,
    ref: _3,
    angle,
    ...rest
  } = props;

  return (
    <Wrapper {...rest}>
      <div className="toolbar">
        <ColorBar
          ref={(ref) => (barRef.current = ref)}
          className="color-bar"
          onMouseDown={state.onBarMouseDown}
        >
          <BG
            style={{
              backgroundImage:
                getCssLinearGradientStringFromGradientStops(stops),
            }}
          />
          {interpolationNodes}
          {lowerPointerNodes}
        </ColorBar>
        {typeof angle === "function" ? angle() : angle}
      </div>
      <StyledSketchPicker
        presetColors={EMPTY_ARRAY as []}
        color={createTinyColor(
          selectedStop?.color,
          selectedStop?.opacity
        ).toString()}
        onChange={state.onPickerChange}
        onChangeComplete={state.onPickerChangeComplete}
      />
    </Wrapper>
  );
});
GradientPicker.displayName = "GradientPicker";

type GradientBarProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "stops"
> & {
  readonly stops?: readonly InputGradientStop[];
};

export const GradientBar = React.memo(
  ({ stops, ref, children, ...rest }: GradientBarProps) => {
    const internalStops = React.useMemo(
      () => formatGradientStops(stops),
      [stops]
    );
    return (
      <ColorBar {...rest}>
        <BG
          style={{
            backgroundImage:
              getCssLinearGradientStringFromGradientStops(internalStops),
          }}
        />
        {children}
      </ColorBar>
    );
  }
);
GradientBar.displayName = "GradientBar";

export type ColorPickerProps = Pick<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "className" | "style"
> &
  SketchPickerProps;

export const ColorPicker = React.memo(
  React.forwardRef<React.Component<SketchPickerProps>, ColorPickerProps>(
    (props, ref) => {
      const { ref: _0, className, style, ...rest } = props;
      return (
        <Wrapper className={className} style={style}>
          <StyledSketchPicker ref={ref} {...rest} />
        </Wrapper>
      );
    }
  )
);
ColorPicker.displayName = "ColorPicker";

const LowerPointer = React.memo(
  (props: { readonly color: string; readonly selected?: boolean }) => {
    const { color, selected } = props;
    return (
      <svg width="6px" height="12px" viewBox="0 0 11 17">
        <path
          d="M 0.5 6 L 0.5 16.5 L 10.5 16.5 L 10.5 6 Z"
          fill={color}
          stroke="gray"
          strokeWidth="1"
        />
        <path
          d="M 5.5 0.5 L 0.5 6 L 10.5 6 Z"
          fill={selected ? "#000" : "transparent"}
          stroke="gray"
          strokeWidth="1"
        />
      </svg>
    );
  }
);
LowerPointer.displayName = "LowerPointer";

const LowerPointerIcon = React.memo(
  (props: { readonly color: string; readonly selected?: boolean }) => {
    const { color, selected } = props;
    return (
      <svg width="11px" height="17px" viewBox="0 0 11 17">
        <path
          d="M 0.5 7 L 0.5 16.5 L 10.5 16.5 L 10.5 7 Z"
          fill={color}
          stroke="gray"
          strokeWidth="1"
        />
        <path
          d="M 5.5 0.5 L 0.5 7 L 10.5 7 Z"
          fill={selected ? "#000" : "transparent"}
          stroke="gray"
          strokeWidth="1"
        />
      </svg>
    );
  }
);
LowerPointerIcon.displayName = "LowerPointerIcon";

const UpperPointerIcon = React.memo(() => {
  return (
    <svg width="8px" height="12px" viewBox="0 0 8 12">
      <path
        d="M 4 0.5 L 7.5 6 L 4 11.5 L 0.5 6 Z"
        fill="yellow"
        stroke="gray"
        strokeWidth="1"
      />
    </svg>
  );
});
UpperPointerIcon.displayName = "UpperPointerIcon";

export function getCssLinearGradientStringFromGradientStops(
  stops: readonly InternalGradientStop[],
  angle = 0
) {
  const str = getCssGradientStringFromGradientStops(stops);
  if (!str) return;
  return `linear-gradient(${angle + 90}deg, ${str})`;
}

export function getCssRadialGradientStringFromGradientStops(
  stops: readonly InternalGradientStop[],
  center?: string
) {
  const str = getCssGradientStringFromGradientStops(stops);
  if (!str) return;
  if (center) {
    return `radial-gradient(${center}, ${str})`;
  }
  return `radial-gradient(${str})`;
}

export function getCssGradientStringFromGradientStops(
  stops: readonly InternalGradientStop[]
) {
  const formatted = stops.map((x) => ({
    ...x,
    tc: tinycolor(x.color).setAlpha(x.opacity || 0.001),
  }));
  if (formatted.length === 0) return undefined;
  if (formatted.length === 1) formatted.push(formatted[0]);
  const strs: string[] = [];
  for (let i = 0, len = formatted.length; i < len; i++) {
    const stop = formatted[i];
    strs.push(`${stop.tc.toString()} ${stop.offset * 100}%`);
    if (typeof stop.interpolation === "number" && i < len - 1) {
      const nextStop = formatted[i + 1];
      const offsetDiff = nextStop.offset - stop.offset;
      if (offsetDiff) {
        const offset = stop.offset + offsetDiff * stop.interpolation;
        const tc = interpolateColors(stop.tc, formatted[i + 1].tc, 0.5);
        strs.push(`${tc.toString()} ${offset * 100}%`);
      }
    }
  }
  return strs.join(", ");
}

export function formatGradientStops(
  stops?: readonly InputGradientStop[]
): readonly InternalGradientStop[] {
  if (!stops || stops.length === 0) stops = DEFAULT_STOPS;
  return stops
    .map((stop, index) => {
      const opacity = formatZeroToOne(formatNumber(stop.opacity, 1));
      const c = createTinyColor(stop.color, opacity);
      const alpha = c.getAlpha();
      c.setAlpha(1);
      return {
        ...stop,
        color: c.toString(),
        offset: formatZeroToOne(formatNumber(stop.offset, 0)),
        opacity: alpha,
        interpolation: formatZeroToOne(formatNumber(stop.interpolation, 0.5)),
        index,
      };
    })
    .sort((a, b) => a.offset - b.offset || a.index - b.index)
    .map((x, i) => ({ ...x, id: i }));
}

function formatNumber(x: unknown, defaultValue: number) {
  return typeof x === "number" && !Number.isNaN(x) ? x : defaultValue;
}

function formatZeroToOne(x: number) {
  return x < 0 ? 0 : x > 1 ? 1 : x;
}

function createTinyColor(color?: string, opacity?: number) {
  let tc = tinycolor(color || "transparent");
  if (!tc.isValid()) tc = tinycolor("transparent");
  tc.setAlpha(tc.getAlpha() * (opacity ?? 1));
  return tc;
}

function interpolateColors(
  tc1: tinycolor.Instance,
  tc2: tinycolor.Instance,
  ratio: number
) {
  if (ratio <= 0) return tc1;
  if (ratio >= 1) return tc2;
  let c1 = tc1.toRgb();
  let c2 = tc2.toRgb();
  if (!c1.a) {
    c1 = { ...c2, a: 0 };
  } else if (!c2.a) {
    c2 = { ...c1, a: 0 };
  }
  return tinycolor({
    r: c1.r + (c2.r - c1.r) * ratio,
    g: c1.g + (c2.g - c1.g) * ratio,
    b: c1.b + (c2.b - c1.b) * ratio,
    a: c1.a + (c2.a - c1.a) * ratio,
  });
}

function getOffsetColorInStops(
  stops: readonly InternalGradientStop[],
  offset: number
) {
  for (let i = 0, len = stops.length; i < len; i++) {
    const stop = stops[i];
    if (offset < stop.offset) {
      if (i) {
        const prev = stops[i - 1];
        const tc1 = tinycolor(prev.color).setAlpha(prev.opacity);
        const tc2 = tinycolor(stop.color).setAlpha(stop.opacity);
        const offsetDiff = stop.offset - prev.offset;
        return interpolateColors(
          tc1,
          tc2,
          offsetDiff ? (offset - prev.offset) / offsetDiff : 0.5
        );
      } else {
        return tinycolor(stop.color).setAlpha(stop.opacity);
      }
    }
  }
  const last = stops[stops.length - 1];
  return tinycolor(last.color).setAlpha(last.opacity);
}

function getAreaClickOffset(
  x: number,
  rect: Readonly<Record<"left" | "right" | "width", number>>
) {
  const coordX = x < rect.left ? rect.left : x > rect.right ? rect.right : x;
  return (coordX - rect.left) / rect.width || 0;
}

function stopsSorter(a: InternalGradientStop, b: InternalGradientStop) {
  return a.offset - b.offset || a.id - b.id;
}

function formatOutputStop(stop: InternalGradientStop): GradientStop {
  return {
    color: stop.color,
    opacity: stop.opacity,
    offset: stop.offset,
    interpolation: stop.interpolation === 0.5 ? undefined : stop.interpolation,
  };
}

const Wrapper = styled.div`
  position: relative;
  padding: 10px;
  user-select: none;

  .color-bar {
    margin-bottom: 17px;
  }

  .toolbar {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .sketch-picker {
    box-shadow: none !important;
    background-color: transparent !important;
    border-radius: 0 !important;
    width: 100% !important;
    padding: 0 !important;
  }
`;

const ColorBar = styled.div`
  position: relative;
  width: 100%;
  height: 10px;

  background: transparent repeat
    url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMiIgaGVpZ2h0PSIyIiB2aWV3Qm94PSIwIDAgMiAyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSIjZmZmIiAvPg0KICA8cmVjdCB4PSIxIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSIjY2NjIiAvPg0KICA8cmVjdCB4PSIxIiB5PSIxIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSIjZmZmIiAvPg0KICA8cmVjdCB4PSIwIiB5PSIxIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSIjY2NjIiAvPg0KPC9zdmc+DQo=);
  background-size: 10px 10px;

  svg {
    display: block;
  }
`;

const BG = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
`;

const UpperPointerLocator = styled.div`
  position: absolute;
  bottom: 100%;
  transform: translate(-50%, 0);
`;

const LowerPointerLocator = styled.div`
  position: absolute;
  top: 100%;
  transform: translate(-50%, 0);
`;
