import { memorize, guardTypeExtends, EMPTY_ARRAY } from "@chuyuan/poster-utils";

import type { RootState } from "./state";
import {
  PointerEventHandlers,
  PointerEventRegister,
  PointerEventRegisterData,
} from "./event";
import { Raf } from "../../utils/raf";
import { INFINITE_BOUNDING } from "../helpers/misc";
import { BBox } from "./bbox";
import { runInAction } from "mobx";
import { MoveData, moveAbsoluteTargets } from "../helpers/gdl-move-absolute";

let MOVE_STATE_COUNT = 0;

export class MoveState {
  readonly id = ++MOVE_STATE_COUNT;

  readonly raf = new Raf();

  private readonly _uniqKey = `move state ${++MOVE_STATE_COUNT}`;

  private _disposed = false;

  hasMoved = false;

  constructor(readonly root: RootState, readonly init: MoveData) {}

  dispose() {
    if (this._disposed) return;
    this._disposed = true;
    this.raf.flush();
    this.root.props.session.ui.isNonRealtimeRenderDisabled.delete(
      this._uniqKey
    );
  }

  @memorize
  get register(): PointerEventRegister {
    const data: PointerEventRegisterData = {
      bbox: new BBox(INFINITE_BOUNDING),
      handlers: this.handlers,
    };
    return {
      path: EMPTY_ARRAY,
      layer: "cover",
      selected: true,
      data: () => data,
    };
  }

  @memorize
  get handlers() {
    return guardTypeExtends<PointerEventHandlers>()({
      move: (e) => {
        if (this._disposed) return;

        e.stopPropagation();
        const { data } = e;

        if (!this.hasMoved) {
          this.root.props.session.ui.isNonRealtimeRenderDisabled.set(
            this._uniqKey,
            true
          );
        }

        this.hasMoved = true;

        this.raf.push(() =>
          runInAction(() => {
            const { session } = this.root.props;
            const { coordinate } = this.init;
            const [x, y] = session.ui.getCanvasPointFromViewportPoint(
              coordinate.x,
              coordinate.y
            );
            const init: MoveData = {
              ...this.init,
              coordinate: { ...this.init.coordinate, x, y },
            };
            moveAbsoluteTargets(init, data.x, data.y);
          })
        );
      },
      up: (e) => {
        if (this._disposed) return;
        try {
          e.stopPropagation();
          this.dispose();
          this.root.props.session.history.push({ name: "移动对象" });
        } finally {
          this.root.handleMoveEnd();
        }
      },
    });
  }
}
