import { computed, makeObservable, observable, runInAction } from "mobx";
import { cached, memorize, EMPTY_ARRAY } from "@chuyuan/poster-utils";
import {
  DIRECTION_DIMENSION,
  DIRECTION_TO_HV,
  HV_TO_BOUNDING_KEY,
  HV_TO_WH,
  HV_TO_XY,
  DIRECTION_SIDES,
  GDLLayoutSemantic,
  RenderTypeMap,
} from "@chuyuan/poster-data-access-layer";
import { Render } from "@chuyuan/poster-renderer-parameters";

import type { RootState } from "./state";
import { PointerEventRegister, PointerEvent } from "./event";
import { BBox } from "./bbox";
import { Raf } from "../../utils/raf";
import { SelectableTarget } from "../editor-state/types";
import { BoxToBounding, pickXY } from "../helpers/data-picker";
import { INFINITE_BOUNDING } from "../helpers/misc";

const BAR_RESPONSE_WIDTH = 150;

export class FlexListReorderState {
  readonly targets: readonly SelectableTarget[];

  readonly raf = new Raf();

  constructor(readonly root: RootState, targets: readonly SelectableTarget[]) {
    this.targets = Array.from(new Set(targets));
    makeObservable(this);
  }

  dispose() {
    this.raf.flush();
  }

  @observable.ref
  moved = false;

  @observable.ref
  hoverIndex = -1;

  @observable.ref
  startPosition = { x: 0, y: 0 };

  @observable.ref
  currentPosition = { x: 0, y: 0 };

  @computed
  get data() {
    // 元素为空时不能操作
    const { targets } = this;
    if (!targets.length) return;

    // 不是共同 parent 时不能操作
    const parents = new Set(targets.map((x) => x.parent()));
    if (parents.size !== 1) return;

    // parent 为空时不能操作
    const parent = Array.from(parents)[0];
    if (!parent) return;

    // parent 不是 flex list 时不能操作
    if (parent.layout.containerType !== "flex list") return;

    const { children } = parent;

    const targetIndexList = targets.map((x) => children.indexOf(x));

    // 如果有 target 不在 children 中, 不能操作
    if (targetIndexList.some((x) => x < 0)) return;

    if (targetIndexList.length > 1) {
      // 拖动超过 1 个元素时, 只有连续索引才能被拖动
      // 索引升序排列
      targetIndexList.sort((a, b) => a - b);
      for (let i = 0, leni = targetIndexList.length - 1; i < leni; i++) {
        // 如果不是连续索引, 不能操作
        if (targetIndexList[i + 1] - targetIndexList[i] !== 1) return;
      }
    }

    // 创建 drop-bars

    const { root } = this;

    const { scale: viewportScale } = root.props.session.viewport;
    const uiScale = viewportScale ? 1 / viewportScale : 0;
    const barResponseWidth = BAR_RESPONSE_WIDTH * uiScale;

    const op = new GDLLayoutSemantic.Operator(parent);
    const container = op.getFlexListContainer();
    const { sort } = container;

    const reverse = sort === "normal";

    const direction = parent.layout.flexbox.childrenDirection;

    const DimKeys = DIRECTION_DIMENSION[direction];

    const orthogonal = parent.layout.contentBox[DimKeys.orthogonal];

    const transform = parent.layout.coordinate.contentFromWorldTransform;

    const firstTargetIndex = targetIndexList[0];
    const lastTargetIndex = targetIndexList[targetIndexList.length - 1];
    const targetLength = lastTargetIndex - firstTargetIndex + 1;

    const HV = DIRECTION_TO_HV[direction];
    const XY = HV_TO_XY[HV];
    const WH = HV_TO_WH[HV];
    const H_SIDES = HV_TO_BOUNDING_KEY[HV];
    const StartSide = reverse ? H_SIDES.end : H_SIDES.start;
    const EndSide = reverse ? H_SIDES.start : H_SIDES.end;

    let minParallel = Infinity;
    let maxParallel = -Infinity;

    for (let i = 0, leni = children.length; i < leni; i++) {
      const child = children[i];
      const { selfBox } = child.layout;
      const min = selfBox[XY];
      const max = min + selfBox[WH];
      if (min < minParallel) minParallel = min;
      if (max > maxParallel) maxParallel = max;
    }

    const bars = [];

    // 位于拖动元素(s)索引前部分, 添加开始边
    for (let i = 0; i < firstTargetIndex; i++) {
      const child = children[i];
      if (child.properties.disabled) continue;
      const { selfBox } = child.layout;
      const b = new BoxToBounding(selfBox);
      const size1 = i
        ? children[i - 1].layout.selfBox[WH] / 2
        : barResponseWidth;
      const size2 = selfBox[WH] / 2;
      bars.push({
        dropIndex: i,
        offset: b[StartSide],
        size: {
          start: Math.min(reverse ? size2 : size1, barResponseWidth),
          end: Math.min(reverse ? size1 : size2, barResponseWidth),
        },
      });
    }

    // 位于拖动元素(s)索引后部分, 添加结束边
    for (
      let i = lastTargetIndex + 1, lastIndex = children.length - 1;
      i <= lastIndex;
      i++
    ) {
      const child = children[i];
      if (child.properties.disabled) continue;
      const { selfBox } = child.layout;
      const b = new BoxToBounding(selfBox);
      const size1 = selfBox[WH];
      const size2 =
        i < lastIndex
          ? children[i + 1].layout.selfBox[WH] / 2
          : barResponseWidth;
      bars.push({
        dropIndex: i - targetLength + 1,
        offset: b[EndSide],
        size: {
          start: Math.min(reverse ? size2 : size1, barResponseWidth),
          end: Math.min(reverse ? size1 : size2, barResponseWidth),
        },
      });
    }

    return {
      parent,
      children,
      targets,
      targetIndexList,
      direction,
      reverse,
      bars,
      minParallel,
      maxParallel,
      orthogonal,
      transform,
      uiScale,
    } as const;
  }

  @computed
  get dropRegisters(): readonly PointerEventRegister[] | undefined {
    const { data } = this;
    if (!data) return;

    return data.bars.map((bar, i): PointerEventRegister => {
      return {
        path: [i],
        layer: "cover",
        selected: false,
        data: () => {
          const { direction } = data;
          const { offset, size } = bar;
          const b = { left: 0, right: 0, top: 0, bottom: 0 };
          const Sides = DIRECTION_SIDES[direction];
          b[Sides.parallel[0]] = offset - size.start;
          b[Sides.parallel[1]] = offset + size.end;
          b[Sides.orthogonal[1]] = data.orthogonal;
          return {
            bbox: new BBox(b, data.transform.clone().inverse()),
            handlers: {
              enter: () => {
                this.hoverIndex = i;
              },
              leave: () => {
                if (this.hoverIndex === i) {
                  this.hoverIndex = -1;
                }
              },
              up: (e) => {
                e.stopPropagation();
                try {
                  this.hoverIndex = -1;
                  const { parent, children, targetIndexList } = data;
                  const { dropIndex } = bar;
                  const i1 = targetIndexList[0];
                  const i2 = targetIndexList[targetIndexList.length - 1];
                  const newChildren = children.slice();
                  const items = newChildren.splice(
                    targetIndexList[0],
                    i2 - i1 + 1
                  );
                  newChildren.splice(dropIndex, 0, ...items);
                  parent.setChildren(newChildren);
                  this.root.props.session.history.push({
                    name: "修改自动布局子元素位置",
                  });
                } finally {
                  this.raf.flush();
                  this.root.handleMoveEnd();
                }
              },
            },
          };
        },
      };
    });
  }

  handleDragStart(e: PointerEvent) {
    this.startPosition = this.currentPosition = pickXY(e.data);
  }

  @computed
  get dragRegister(): PointerEventRegister {
    return {
      path: EMPTY_ARRAY,
      layer: "cover",
      selected: false,
      data: () => {
        return {
          bbox: new BBox(INFINITE_BOUNDING),
          handlers: {
            move: (e) => {
              const position = pickXY(e.data);
              this.raf.push(() =>
                runInAction(() => (this.currentPosition = position))
              );
            },
          },
        };
      },
    };
  }

  @memorize
  get getRenderParams() {
    return cached((target: SelectableTarget) => {
      return computed((): Render<RenderTypeMap> => {
        return target.ds.render.render(target);
      });
    });
  }
}
