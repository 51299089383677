import { EMPTY_OBJECT } from "@chuyuan/poster-utils";
import { BBox } from "./bbox";
import { PointerEventRegisterData } from "./event";
import {
  gerEdgeCursorCached,
  gerCornerRotateCursorCached,
  getCornerCursorCached,
} from "../dynamic-cursor";
import { INFINITE_BOUNDING } from "../helpers/misc";

export type EventLayer =
  | "cover"
  | "global"
  | "multi"
  | "highlight"
  | "default"
  | "fallback";

let EventLayerCounter = 0;

export const EventLayerPriority: {
  readonly [P in EventLayer]: number;
} = {
  fallback: EventLayerCounter++,
  default: EventLayerCounter++,
  highlight: EventLayerCounter++,
  multi: EventLayerCounter++,
  global: EventLayerCounter++,
  cover: EventLayerCounter++,
};

export type ResizeCorner =
  | "top-left"
  | "top"
  | "top-right"
  | "right"
  | "bottom-right"
  | "bottom"
  | "bottom-left"
  | "left";

export const CORNERS_ORDERED = [
  "top-left",
  "top-right",
  "bottom-right",
  "bottom-left",
] as const;

export const SIDES_ORDERED = ["top", "right", "bottom", "left"] as const;

export const CORNERS_AND_SIDES_ORDERED = [
  "top",
  "right",
  "bottom",
  "left",
  "top-left",
  "top-right",
  "bottom-right",
  "bottom-left",
] as const;

export const CORNERS_CLOCK_WISE = [
  "right",
  "bottom-right",
  "bottom",
  "bottom-left",
  "left",
  "top-left",
  "top",
  "top-right",
] as const;

export const DefaultPointerEventData: PointerEventRegisterData = {
  bbox: new BBox(INFINITE_BOUNDING),
  handlers: EMPTY_OBJECT,
};

export function getEdgeCursor(degree: number, index: number) {
  return gerEdgeCursorCached(degree + index * 45);
}

export function getCornerCursor(degree: number, index: number) {
  return getCornerCursorCached(degree + index * 45);
}

export function getCornerRotateCursor(degree: number) {
  return gerCornerRotateCursorCached(degree);
}
