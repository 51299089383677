import {
  AnyModelType,
  GDLLayoutSemantic,
  HV_TO_BOUNDING_KEY,
} from "@chuyuan/poster-data-access-layer";
import {
  subVector2D,
  ReadonlyAffine2D,
  IdentityAffine2D,
  Affine2DTimesBounding,
} from "@chuyuan/poster-math";
import { guardTypeExtends, predicate, sortBy } from "@chuyuan/poster-utils";
import {
  boundingToCenter,
  pickDimensionToBounding,
  pickXY,
} from "./data-picker";

import { removeOffsprings } from "./gdl-tree";
import { Bounding, HV_INDEXES, PointingDirection } from "./misc";

export interface MoveData {
  readonly coordinate: {
    readonly x: number;
    readonly y: number;
  };
  readonly items: readonly MoveDataItem[];
}

export interface MoveDataItem {
  readonly target: AnyModelType;
  readonly offset: {
    readonly x: number;
    readonly y: number;
  };
}

export function createMoveDataItems(targets: Iterable<AnyModelType>) {
  const set = removeOffsprings(new Set(targets));
  return Array.from(set).map((target) =>
    guardTypeExtends<MoveDataItem>()({
      target,
      offset: pickXY(target.layout.selfBox),
    })
  );
}

/**
 * 移动绝对定位的对象
 * @param init 初始数据
 * @param x 当前坐标 x
 * @param y 当前坐标 y
 */
export function moveAbsoluteTargets(init: MoveData, x: number, y: number) {
  const { coordinate, items } = init;
  const initCoordinateVector = [x, y] as const;
  let changed = false;
  for (const { target, offset } of items) {
    const parent = target.parent();
    if (!parent) continue;
    if (parent.layout.containerType !== "absolute") continue;
    const transform = parent.layout.coordinate.contentFromWorldTransform;

    const [dx, dy] = subVector2D(
      ...transform
        .clone()
        .inverse()
        .timesVectors([
          initCoordinateVector,
          [coordinate.x, coordinate.y],
        ] as const)
    );
    changed = true;
    const op = new GDLLayoutSemantic.Operator(target);
    op.setAbsoluteOffsetVisualValue("x", offset.x + dx);
    op.setAbsoluteOffsetVisualValue("y", offset.y + dy);
  }
  return changed;
}

/**
 * 移动绝对定位的对象固定距离
 */
export function moveAbsoluteTargetsFixedDistance(
  targets: Iterable<AnyModelType>,
  direction: PointingDirection,
  px: number
) {
  const items = createMoveDataItems(targets);
  if (!items.length) return false;
  const dx = direction === "right" ? px : direction === "left" ? -px : 0;
  const dy = direction === "down" ? px : direction === "up" ? -px : 0;
  return moveAbsoluteTargets({ items, coordinate: { x: 0, y: 0 } }, dx, dy);
}

/**
 * 以指定框的某一边对齐绝对定位的元素
 * @param inputTargets 输入元素集合
 * @param side 对齐边
 * @param bounding 对齐框
 * @param transform 对齐框的线性变换
 */
export function alignAbsoluteTargetsToSide(
  inputTargets: ReadonlySet<AnyModelType>,
  side: keyof Bounding,
  bounding: Bounding,
  transform: ReadonlyAffine2D = IdentityAffine2D
) {
  if (!inputTargets.size) return;

  // 一旦父元素被选中, 子元素不会被遍历移动

  // 对齐时, 只考虑平移, 将对象框按照transform正过来对齐

  const targets = removeOffsprings(new Set(inputTargets));

  const inverseTransform: ReadonlyAffine2D = transform.clone().inverse();

  for (const target of targets) {
    const parent = target.parent();
    if (!parent) continue;

    const { width, height } = target.layout.selfBox;
    const semiWidth = width / 2;
    const semiHeight = height / 2;

    const selfTransform = target.layout.coordinate.selfFromWorldTransform;

    // 从元素中心为原点的元素坐标系到bounding坐标系的相对变换
    const relativeTransform = selfTransform
      .clone()
      .translateRight(semiWidth, semiHeight)
      .leftTimes(inverseTransform);

    // 平移相对变换以对齐边
    if (side === "left") {
      relativeTransform.e = bounding.left + semiWidth;
    } else if (side === "right") {
      relativeTransform.e = bounding.right - semiWidth;
    } else if (side === "top") {
      relativeTransform.f = bounding.top + semiHeight;
    } else if (side === "bottom") {
      relativeTransform.f = bounding.bottom - semiHeight;
    }

    // 从元素中心为原点的元素坐标系到世界坐标系
    const newSelfCentralTransform = transform
      .clone()
      .rightTimes(relativeTransform);

    const parentTransform = parent.layout.coordinate.contentFromWorldTransform;
    const selfCentralFromParentTransform = parentTransform
      .clone()
      .inverse()
      .rightTimes(newSelfCentralTransform);

    const op = new GDLLayoutSemantic.Operator(target);
    op.setAbsoluteOffsetVisualValue(
      "x",
      selfCentralFromParentTransform.e - semiWidth
    );
    op.setAbsoluteOffsetVisualValue(
      "y",
      selfCentralFromParentTransform.f - semiHeight
    );
  }
}

/**
 * 以坐标系的某一方向直线对齐绝对定位的元素
 * @param inputTargets 输入元素集合
 * @param direction 对齐方向
 * @param offset 对齐线偏移值
 * @param transform 对齐线的线性变换
 */
export function alignAbsoluteTargetsToCentralLine(
  inputTargets: ReadonlySet<AnyModelType>,
  direction: "horizontal" | "vertical",
  offset: number,
  transform: ReadonlyAffine2D = IdentityAffine2D
) {
  if (!inputTargets.size) return;

  // 一旦父元素被选中, 子元素不会被遍历移动

  // 对齐时, 只考虑平移, 将对象框按照transform正过来对齐

  const targets = removeOffsprings(new Set(inputTargets));

  const inverseTransform: ReadonlyAffine2D = transform.clone().inverse();

  for (const target of targets) {
    const parent = target.parent();
    if (!parent) continue;

    const { width, height } = target.layout.selfBox;
    const semiWidth = width / 2;
    const semiHeight = height / 2;

    const selfTransform = target.layout.coordinate.selfFromWorldTransform;

    // 从元素中心为原点的元素坐标系到bounding坐标系的相对变换
    const relativeTransform = selfTransform
      .clone()
      .translateRight(semiWidth, semiHeight)
      .leftTimes(inverseTransform);

    // 平移相对变换以对齐边
    if (direction === "horizontal") {
      relativeTransform.e = offset;
    } else if (direction === "vertical") {
      relativeTransform.f = offset;
    }

    // 从元素中心为原点的元素坐标系到世界坐标系
    const newSelfCentralTransform = transform
      .clone()
      .rightTimes(relativeTransform);

    const parentTransform = parent.layout.coordinate.contentFromWorldTransform;
    const selfCentralFromParentTransform = parentTransform
      .clone()
      .inverse()
      .rightTimes(newSelfCentralTransform);

    const op = new GDLLayoutSemantic.Operator(target);
    op.setAbsoluteOffsetVisualValue(
      "x",
      selfCentralFromParentTransform.e - semiWidth
    );
    op.setAbsoluteOffsetVisualValue(
      "y",
      selfCentralFromParentTransform.f - semiHeight
    );
  }
}

/**
 * 以坐标系的某一方向分布排列绝对定位的元素
 * @param inputTargets 输入元素集合
 * @param direction 对齐方向
 * @param offset1 对齐线起点
 * @param offset2 对齐线终点
 * @param transform 对齐线的线性变换
 */
export function distributeAbsoluteTargetsFromEdgeToEdge(
  inputTargets: ReadonlySet<AnyModelType>,
  direction: "horizontal" | "vertical",
  offset1: number,
  offset2: number,
  transform: ReadonlyAffine2D = IdentityAffine2D
) {
  if (inputTargets.size < 2) return;

  // 一旦父元素被选中, 子元素不会被遍历移动
  const targets = removeOffsprings(new Set(inputTargets));
  if (targets.size < 2) return;

  const validItems = Array.from(targets)
    .map((target) => {
      const parent = target.parent();
      if (!parent) return;
      return {
        parent,
        target,
      };
    })
    .filter(predicate);

  if (validItems.length < 2) return;

  // 对齐时, 只考虑平移, 将对象框按照transform正过来对齐

  const inverseTransform: ReadonlyAffine2D = transform.clone().inverse();

  const { start: startKey, end: endKey } = HV_TO_BOUNDING_KEY[direction];

  // 选起点和终点按照边缘, 排序按照中心, 计算间隔按照自动布局, 剔除同时占2端的

  const items = validItems.map((item, index) => {
    const { parent, target } = item;
    const { selfBox, coordinate } = target.layout;
    const t = coordinate.selfFromWorldTransform
      .clone()
      .leftTimes(inverseTransform);
    const b = pickDimensionToBounding(selfBox);
    const bounding = Affine2DTimesBounding(t, b);
    const start = bounding[startKey];
    const end = bounding[endKey];
    const middle = (start + end) / 2;
    const center = boundingToCenter(bounding);
    return {
      parent,
      index,
      target,
      start,
      end,
      middle,
      length: end - start,
      center,
    };
  });

  const itemsFromStart = sortBy(items, ["start", "middle", "index"]);
  const itemsFromEnd = sortBy(items, [
    ["end", "desc"],
    ["middle", "desc"],
    ["index", "desc"],
  ]);

  const firstItem = itemsFromStart[0];
  const lastItem =
    itemsFromEnd[0] === firstItem ? itemsFromEnd[1] : itemsFromEnd[0];

  const sortedItems = sortBy(
    items.filter((item) => item !== firstItem && item !== lastItem),
    ["middle", "index"]
  );
  sortedItems.unshift(firstItem);
  sortedItems.push(lastItem);

  const totalLength = sortedItems.reduce((s, item) => s + item.length, 0);

  if (offset1 > offset2) {
    [offset1, offset2] = [offset2, offset1];
  }

  const space = offset2 - offset1;

  const gap = (space - totalLength) / (items.length - 1);

  const middleList: number[] = [];
  const sortedItem0 = sortedItems[0];
  middleList.push(offset1 + sortedItem0.middle - sortedItem0.start);

  for (let i = 1, len = sortedItems.length; i < len; i++) {
    const curr = sortedItems[i];
    const prev = sortedItems[i - 1];
    middleList[i] =
      middleList[i - 1] +
      prev.end -
      prev.middle +
      gap +
      curr.middle -
      curr.start;
  }

  const HV_INDEX = HV_INDEXES[direction];

  for (let i = 0, len = sortedItems.length; i < len; i++) {
    const item = sortedItems[i];
    const { parent, target, center: c1 } = item;
    const middle = middleList[i];
    const c2 = [...c1] as typeof c1;
    c2[HV_INDEX] = middle;
    const parentTransform = parent.layout.coordinate.contentFromWorldTransform;
    const vs = parentTransform
      .clone()
      .inverse()
      .timesVectors(transform.timesVectors([c1, c2]));
    const [dx, dy] = subVector2D(vs[1], vs[0]);
    const { x, y } = target.layout.selfBox;
    const op = new GDLLayoutSemantic.Operator(target);
    op.setAbsoluteOffsetVisualValue("x", x + dx);
    op.setAbsoluteOffsetVisualValue("y", y + dy);
  }
}
