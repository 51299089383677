import * as React from "react";
import { SortedArray, EMPTY_ARRAY, compareArray } from "@chuyuan/poster-utils";

import type { BBox } from "./bbox";
import { EventLayer, EventLayerPriority } from "./common";

export class EventRoot {
  nextRegisterId = 0;

  readonly pointer = new SortedArray<PointerEventRegisterItem>(
    undefined,
    comparePointerEventRegisterItem
  );

  pointerEntered: ReadonlySet<PointerEventRegister> = new Set();

  dispatchPointerEvent(eventData: PointerEventData) {
    const { action, x, y } = eventData;
    const point = [x, y] as const;

    const toLeave = new Set(this.pointerEntered);
    const toEnter = (this.pointerEntered = new Set());
    const list = this.pointer.native;

    let neverHover = true;
    let cursor: string | (() => string | undefined) | undefined;

    const matched = (() => {
      if (!list.length) return false;
      for (let i = 0, leni = list.length; i < leni; i++) {
        const { register } = list[i];
        const data = register.data();
        const contains = data.bbox.contains(point);
        if (!contains) continue;
        // 点落在区域内

        const { handlers } = data;
        if (handlers.enter || handlers.leave) {
          // 只有提供了 enter 或 leave handler 才捕捉
          // tslint:disable-next-line: no-collapsible-if
          if (neverHover && action === "move") {
            // 移动事件会保持 enter 状态, 但这里的设定是只触发1次
            neverHover = false;
            cursor = data.cursor;
            toEnter.add(register);
            toLeave.delete(register);
          }
        }

        const fn = data.handlers[action];
        if (fn) {
          const e = new CustomEvent(eventData);
          fn(e);
          if (e.isPropagationStopped()) {
            cursor = data.cursor;
            return register;
          }
        }
      }
      return false;
    })();

    if (toLeave.size) {
      // 如果有之前进入的又没有触发离开的, 触发离开事件
      for (const register of toLeave) {
        const data = register.data();
        const fn = data.handlers.leave;
        if (fn) {
          fn(new CustomEvent(eventData));
        }
      }
    }
    if (toEnter.size) {
      // 触发进入事件
      for (const register of toEnter) {
        const data = register.data();
        const fn = data.handlers.enter;
        if (fn) {
          fn(new CustomEvent(eventData));
        }
      }
    }

    return { matched, cursor };
  }
}

export class CustomEvent<T = undefined> {
  private _isPropagationStopped = false;
  private _isDefaultPrevented = false;

  constructor(readonly data: T) {}

  stopPropagation() {
    if (this._isPropagationStopped) return;
    this._isPropagationStopped = true;
  }

  isPropagationStopped() {
    return this._isPropagationStopped;
  }

  preventDefault() {
    if (this._isDefaultPrevented) return;
    this._isDefaultPrevented = true;
  }

  isDefaultPrevented() {
    return this._isDefaultPrevented;
  }
}

export interface CommonEventData {
  readonly altKey: boolean;
  readonly ctrlKey: boolean;
  readonly metaKey: boolean;
  readonly shiftKey: boolean;
}

/**
 * 事件注册
 */
export interface EventRegister<T extends object = object> {
  /**
   * 事件层, 决定事件处于哪个等级的队列里
   */
  readonly layer: EventLayer;
  /**
   * 树路径, 用于一般排序
   */
  readonly path: readonly number[];
  /**
   * 是否选中
   */
  readonly selected: boolean;
  /**
   * 优先级
   */
  readonly priority?: number;
  /**
   * 因为事件相应是指令式的, 这个字段的内容不涉及事件响应排序, 可以随意修改而不告知react
   */
  readonly data: () => T;
}

/**
 * 指针事件注册
 */
export interface PointerEventRegister
  extends EventRegister<PointerEventRegisterData> {}

/**
 * 指针事件注册数据
 */
export interface PointerEventRegisterData {
  /**
   * 二维矩形范围 (可带线性变换)
   */
  readonly bbox: BBox;
  /**
   * css指针样式 (只针对给出的区域)
   */
  readonly cursor?: string | (() => string | undefined);
  readonly handlers: PointerEventHandlers;
  readonly others?: unknown;
}

export type PointerEventHandlers = {
  readonly [P in PointerEventHandlerType]?: PointerEventHandler;
};

export type PointerEventHandler = (e: PointerEvent) => void;

export type PointerEventAction = "down" | "move" | "up" | "context menu";

export type PointerEventHandlerType = PointerEventAction | "enter" | "leave";

export type PointerEvent = CustomEvent<PointerEventData>;

export interface PointerEventData extends CommonEventData {
  readonly kind: "pointer";
  readonly action: PointerEventAction;
  /**
   * 鼠标相对于画布左上角的横向偏移值, 向右为正
   */
  readonly x: number;
  /**
   * 鼠标相对于画布左上角的纵向偏移值, 向下为正
   */
  readonly y: number;
  readonly absoluteX: number;
  readonly absoluteY: number;
}

interface PointerEventRegisterItem {
  id: number;
  register: PointerEventRegister;
}

export const PointerEvent = React.memo(
  (props: {
    readonly root: EventRoot;
    readonly register: PointerEventRegister;
  }) => {
    const state = React.useRef<{
      root?: EventRoot;
      item?: PointerEventRegisterItem;
    }>({}).current;

    React.useEffect(
      () => () => {
        const { root, item } = state;
        if (root && item) {
          root.pointer.remove(item);
        }
      },
      EMPTY_ARRAY
    );

    {
      const { root, register } = props;

      const prevRoot = state.root;
      let prevItem = state.item;

      if (prevRoot !== root || prevItem?.register !== register) {
        if (prevRoot && prevItem) {
          prevRoot.pointer.remove(prevItem);
        }
        state.root = root;
        if (prevItem) {
          prevItem.register = register;
        } else {
          prevItem = state.item = { id: root.nextRegisterId++, register };
        }
        root.pointer.insert(prevItem);
      }
    }

    return null;
  }
);
PointerEvent.displayName = "PointerEvent";

function comparePointerEventRegisterItem(
  x: PointerEventRegisterItem,
  y: PointerEventRegisterItem
) {
  const a = x.register;
  const b = y.register;
  const layer1 = EventLayerPriority[a.layer];
  const layer2 = EventLayerPriority[b.layer];
  if (layer1 < layer2) return 1;
  if (layer1 > layer2) return -1;
  const p1 = a.path;
  const p2 = b.path;
  const l1 = p1.length;
  const l2 = p2.length;
  if (l1 < l2) return 1;
  if (l1 > l2) return -1;
  const s1 = a.selected ? 1 : 0;
  const s2 = b.selected ? 1 : 0;
  if (s1 < s2) return 1;
  if (s1 > s2) return -1;
  const dp = compareArray(p1, p2);
  if (dp) return -dp;
  const v1 = a.priority || 0;
  const v2 = b.priority || 0;
  if (v1 < v2) return 1;
  if (v1 > v2) return -1;
  return y.id - x.id;
}
