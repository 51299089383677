import * as React from "react";
import { useObserver } from "mobx-react";
import { Text } from "@chuyuan/poster-data-access-layer";
import { useEqualSet } from "../../utils/react-hooks";
import { Fields } from "./state.ui";
import { Block, Row } from "../ui-components/section";
import { Select as AntdSelect, Divider } from "antd";
import { i18n } from "../../utils/i18n";
import { FillingPaintValue } from "../ui-components-stateful/filling-paint-text-spec";
import { InputNumber } from "../ui-components/input-number";
import { EmValueLong, EmValueShort } from "./edit-style-panel-layer-style";
import { AngleShortValue } from "../ui-components-stateful/number";

const ShapeTypeOptions = [
  {
    value: "rectangle",
    label: "矩形",
  },
  {
    value: "oval",
    label: "椭圆",
  },
  {
    value: "regular polygon",
    label: "正多边形",
  },
  {
    value: "diagonal",
    label: "对角线",
  },
  {
    value: "label",
    label: "标签",
  },
];

const StrokeLineCapOptions = [
  {
    value: "square",
    label: "直角",
  },
  {
    value: "round",
    label: "圆角",
  },
];

const LayerPaintList = [
  { type: "fill", key: "fillPaint" },
  { type: "stroke", key: "strokePaint" },
  { type: "shadow", key: "shadowPaint" },
] as const;

/**@name 文本特效 */
export const TextEffectsPanel = React.memo((props: { readonly targets: ReadonlySet<Text> }) => {
  const fields = Fields.of(useEqualSet(props.targets));
  const { paint, options } = fields.backgrounds.region.colors.stroke;
  const fillPaint = fields.backgrounds.region.colors.fill.paint;
  const fillOptions = fields.backgrounds.region.colors.fill.options;
  const textStyleLayer = fields.textStyle.getAdvancedEffectLayerFields(fields.textStyle.selfLayerWithCache);

  const onChangeShapeType = (newValue: any) => {
    fields.backgrounds.region.type.set(newValue);
  };

  const onChangeBorderLineCap = (newValue: any) => {
    fields.backgrounds.region.borderLineCap.set(newValue);
  };

  console.log("textStyleLayer", { textStyleLayer });

  return useObserver(() => {
    return (
      <Block>
        <文字特效 />
        <Divider />
        <Row className="margin-y edged">
          <描边颜色 />
          <FillingPaintValue field={textStyleLayer.strokePaint} options={textStyleLayer.strokePaintOptions} />
        </Row>
        <Row className="margin-y">
          <描边粗细 />
          <EmValueLong field={textStyleLayer.strokeWidth} max={25} historyName={() => `修改描边粗细`} />
        </Row>
        <Divider />
        <Row className="margin-y edged">
          <投影颜色 />
          <FillingPaintValue field={textStyleLayer.shadowPaint} options={textStyleLayer.shadowPaintOptions} />
        </Row>
        <投影设置 />
        <Row className="margin-y">
          <角度 />
          <AngleShortValue
            field={textStyleLayer.shadowAngle}
            historyName={() => {
              const n = 偏移层.get("zh", { no: 0 + 1 });
              return `${n}投影角度`;
            }}
          />
        </Row>
        <Row className="margin-y">
          <偏移 />
          <EmValueShort
            field={textStyleLayer.shadowOffset}
            historyName={() => {
              const n = 偏移层.get("zh", { no: 0 + 1 });
              return `${n}投影偏移`;
            }}
          />
        </Row>
        <Row className="margin-y">
          <模糊 />
          <EmValueShort
            field={textStyleLayer.shadowBlurRadius}
            historyName={() => {
              const n = 偏移层.get("zh", { no: 0 + 1 });
              return `${n}投影模糊半径`;
            }}
          />
        </Row>
        <Divider />
      </Block>
    );
  });
});
TextEffectsPanel.displayName = "TextEffectsPanel";

const 文字特效 = i18n({
  en: () => "Text effects",
  zh: () => "文字特效",
});

const 描边粗细 = i18n({
  en: () => "Stroke weight",
  zh: () => "描边粗细",
});

const 描边颜色 = i18n({
  en: () => "Stroke color",
  zh: () => "描边颜色",
});

const 投影颜色 = i18n({
  en: () => "Projection color",
  zh: () => "投影颜色",
});

const 投影设置 = i18n({
  en: () => "Projection Settings",
  zh: () => "投影设置",
});

const 角度 = i18n({
  en: () => "Angle",
  zh: () => "角度",
});

const 偏移 = i18n({
  en: () => "Offset",
  zh: () => "偏移",
});

const 模糊 = i18n({
  en: () => "Dim",
  zh: () => "模糊",
});

const 偏移层 = i18n<{
  readonly no: number;
}>({
  en: (p) => `Offset Layer ${p.no}`,
  zh: (p) => `偏移层${p.no}`,
});
