import * as React from "react";
import styled from "styled-components";
import { runInAction } from "mobx";
import * as raf from "raf";
import * as cx from "classnames";
import { once } from "lodash";

import { EMPTY_ARRAY } from "@chuyuan/poster-utils";

import { useBoxedValue, useMutableValue } from "../../utils/react-hooks";
import {
  UseInputNumberProps,
  useInputNumberProps,
  ToFixedFormatter,
} from "./input-number-hooks";
import { CommonProps, formatValueBetweenMinMax } from "./common";

export interface InputNumberProps extends UseInputNumberProps, CommonProps {
  readonly prefix?: React.ReactNode;
  readonly suffix?: React.ReactNode;
  /**
   * 文字对齐
   * @default right
   */
  readonly align?: "left" | "center" | "right";
  /**
   * 拖拽方向
   * @default vertical
   */
  readonly dragDirection?: "horizontal" | "vertical";
  readonly dragReverse?: boolean;
  readonly placeholder?: string;
}

export interface InputNumberRef {
  readonly input?: HTMLInputElement | null;
}

export const InputNumber = React.forwardRef(
  (props: InputNumberProps, ref: React.ForwardedRef<InputNumberRef>) => {
    const {
      prefix,
      suffix,
      dragDirection,
      dragReverse,
      style,
      className,
      placeholder,
      disabled,
    } = props;

    type State = {
      inputRef: HTMLInputElement | null;
      isDragging: boolean;
      readonly getRef: (ref: HTMLInputElement | null) => void;
    };
    const stateRef = React.useRef<State>();
    const state: State =
      stateRef.current ||
      (stateRef.current = {
        inputRef: null,
        isDragging: false,
        getRef: (r) => (state.inputRef = r),
      });

    React.useImperativeHandle(ref, () => ({
      get input() {
        return state.inputRef;
      },
    }));

    const inputNumberProps = useInputNumberProps({
      ...props,
      format: props.format ?? ToFixedFormatter[2],
    });

    const cursor =
      props.dragDirection === "horizontal" ? "ew-resize" : "ns-resize";

    const { onMouseDown } = useDragOrClick({
      ...props,
      direction: dragDirection,
      reverse: dragReverse,
      onDragStart: () => {
        state.inputRef?.blur();
      },
      onDragMove: (dv) =>
        runInAction(() => {
          const { minmax } = inputNumberProps;
          if (!minmax) return;
          const { value } = props;
          if (!Number.isFinite(value!)) return;
          const newValue = formatValueBetweenMinMax(value! + dv, minmax)!;
          props.onChange?.(newValue);
        }),
      onDragEnd: () => {
        const { value } = props;
        if (!Number.isFinite(value!)) return;
        props.onComplete?.(value!);
      },
      onClick: () => {
        const { inputRef } = state;
        if (!inputRef) return;
        inputRef.select();
        inputRef.focus();
      },
    });

    return (
      <Outline
        className={`${className} ${
          inputNumberProps.isFocused ? "focused" : ""
        } ${disabled ? "disabled" : ""}`}
        style={style}
      >
        {!prefix ? null : disabled ? (
          <Title>{prefix}</Title>
        ) : (
          <Title onMouseDown={onMouseDown} style={{ cursor }}>
            {prefix}
          </Title>
        )}
        <Input
          {...inputNumberProps.props}
          ref={state.getRef}
          disabled={disabled}
          className={cx(props.align, !prefix && "padding-left")}
          placeholder={placeholder}
        />
        {!suffix ? null : disabled ? (
          <Title className="no-gap" style={{ cursor: "not-allowed" }}>
            {suffix}
          </Title>
        ) : (
          <Title
            className="no-gap"
            onMouseDown={onMouseDown}
            style={{ cursor }}
          >
            {suffix}
          </Title>
        )}
      </Outline>
    );
  }
);
InputNumber.displayName = "InputNumber";

function useDragOrClick(input: {
  readonly direction?: "horizontal" | "vertical";
  readonly reverse?: boolean;
  readonly scale?: number;
  readonly onClick?: () => void;
  readonly onDragStart?: () => void;
  readonly onDragMove?: (dv: number) => void;
  readonly onDragEnd?: () => void;
}) {
  const isMouseDown = useMutableValue(false);
  const inputRef = useBoxedValue(input);

  return React.useMemo(() => {
    let state = {
      init: { x: 0, y: 0 },
      hasMoved: false,
    };

    let nextMove = 0;
    let rafId: number | undefined;

    const emit = (value: number) => {
      nextMove += value;
      if (rafId === undefined) {
        rafId = raf(() => {
          rafId = undefined;
          const move = nextMove;
          nextMove = 0;
          inputRef.current.onDragMove?.(move);
        });
      }
    };

    const onMouseDown = (e: React.MouseEvent) => {
      if (!(e.buttons & 1)) return;
      e.preventDefault();
      e.stopPropagation();
      document.addEventListener("mousemove", handleMouseMove, true);
      document.addEventListener("mouseup", handleMouseUp, true);
      isMouseDown.set(true);
      const curr = { x: e.clientX, y: e.clientY };
      state = { ...state, init: curr, hasMoved: false };
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (!(e.buttons & 1)) {
        // 左键没有按下, 认为松开了
        return handleMouseUp(e);
      }

      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      const { init } = state;
      const curr = { x: e.clientX, y: e.clientY };

      let { hasMoved } = state;

      let isDragStart = false;

      if (!hasMoved) {
        // 移动超过 5px 才认为是拖动
        const distance = Math.sqrt(
          (init.x - curr.x) ** 2 + (init.y - curr.y) ** 2
        );
        if (distance >= 5) {
          hasMoved = true;
          isDragStart = true;
          inputRef.current.onDragStart?.();
        }
      }

      state = { ...state, hasMoved };

      if (!hasMoved) return;

      if (isDragStart) {
        getCanvas().requestPointerLock();
      }

      const { direction, scale = 1, reverse } = inputRef.current;

      let dv = direction === "horizontal" ? e.movementX : e.movementY;
      dv /= scale;

      if (e.metaKey || e.altKey) dv *= 100;
      else if (e.shiftKey) dv *= 10;

      if (reverse) dv = -dv;

      emit(dv);
    };

    const handleMouseUp = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      document.removeEventListener("mousemove", handleMouseMove, true);
      document.removeEventListener("mouseup", handleMouseUp, true);
      try {
        isMouseDown.set(false);
        if (state.hasMoved) {
          inputRef.current.onDragEnd?.();
        } else {
          inputRef.current.onClick?.();
        }
      } finally {
        document.exitPointerLock();
      }
    };

    return { onMouseDown };
  }, EMPTY_ARRAY);
}

const getCanvas = once(() => {
  const canvas = document.createElement("canvas");
  canvas.style.display = "none";
  document.body.append(canvas);
  return canvas;
});

const Outline = styled.div`
  flex: 1 1 0;
  display: flex;
  align-items: center;
  width: 0;
  height: 32px;
  /* background: #f8f7f6; */
  /* border-radius: 4px; */
  /* border: 1px solid transparent; */
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  box-sizing: border-box;
  transition: all 0.3s;

  &:hover {
    border-color: #c0c0c0;
  }

  &.focused {
    border-color: #0040f0;
    box-shadow: 0 0 0 2px rgba(0, 64, 240, 0.2);
  }

  &.whole {
    width: 100%;
  }

  &.disabled {
    cursor: not-allowed;
    border-color: transparent;
    box-shadow: none;

    & > * {
      pointer-events: none;
    }
  }
`;

const Title = styled.div`
  flex: 0 0 auto;
  display: flex;
  padding: 0 8px;
  height: 100%;
  align-items: center;
  user-select: none;
  color: #c0c0c0;

  &.no-gap {
    margin-left: -14px;
  }
`;

const Input = styled.input`
  flex: 1 1 0;
  background: transparent;
  display: block;
  width: 0;
  outline: 0;
  border: 0;

  color: #606060;
  text-align: right;
  padding: 0;
  padding-right: 8px;
  font-size: 12px;
  line-height: 16px;
  height: 100%;

  &.padding-left {
    padding-left: 8px;
  }

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }

  &.center {
    text-align: center;
  }

  &:disabled {
    color: #c0c0c0;
    cursor: not-allowed;

    ::placeholder {
      color: #c0c0c0;
    }
  }
`;
