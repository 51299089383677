import { MaybePromise, memorize, runSafeAsync } from "@chuyuan/poster-utils";
import { makeObservable, observable } from "mobx";
import { DB, getCurrentSession, Session, Namespace } from "./indexeddb";
import { Document } from "@chuyuan/poster-data-structure";

export class State {
  @memorize
  static get instance() {
    return new State();
  }

  readonly history = this._getHistory();

  private constructor() {}

  private async _getHistory() {
    return new History(await getCurrentSession());
  }
}

const MAX_HISTORY_RECORD_COUNT = 20;

export class History {
  readonly op: Namespace<DBSessionData>;

  @observable.ref
  recordIds: readonly number[];

  private _prevPush?: Promise<unknown>;

  constructor(session: Session) {
    this.op = session.getNamedOperator("history");
    const initData = this.op.getInitData();
    if (initData) {
      this.recordIds = initData.recordIds || [];
    } else {
      this.recordIds = [];
    }
    makeObservable(this);
  }

  async get(id: number) {
    return DB.instance.history.get(id);
  }

  async push(name: string, data: Document) {
    const addPromise = DB.instance.history.add({
      name,
      data,
      createdAt: Date.now(),
    });
    return this._queue(async () => {
      const id = await addPromise;
      const newRecordIds = [...this.recordIds, id];
      if (newRecordIds.length > MAX_HISTORY_RECORD_COUNT) {
        const idsToDelete = newRecordIds.splice(
          0,
          newRecordIds.length - MAX_HISTORY_RECORD_COUNT
        );
        this._queue(() => DB.instance.history.bulkDelete(idsToDelete));
      }
      this.recordIds = newRecordIds;
      return id;
    });
  }

  async clear() {
    return this._queue(async () => {
      await runSafeAsync(() =>
        DB.instance.history.bulkDelete(this.recordIds.slice())
      );
      this.recordIds = [];
    });
  }

  private async _queue<T>(block: () => MaybePromise<T>) {
    const promise = runSafeAsync(() => this._prevPush).then(async () => {
      const ret = await block();
      await runSafeAsync(() => this._update());
      return ret;
    });
    this._prevPush = promise;
    return promise;
  }

  private async _update() {
    await this.op.update({
      recordIds: this.recordIds,
    });
  }
}

export interface DBSessionData {
  readonly recordIds?: readonly number[];
}
