import * as React from "react";
import { useObserver } from "mobx-react";
import { Text } from "@chuyuan/poster-data-access-layer";
import styled from "styled-components";
import { useEqualSet } from "../../utils/react-hooks";
import { Fields } from "./state.ui";
import { Row } from "../ui-components/section";
import { FontSizeValue } from "./font-size";
import { TextDirectionValue } from "./text-direction";
import { LineAlignmentValue } from "./line-alignment";
import { InlineAlignmentValue } from "./inline-alignment";
import { WrapValue } from "./wrap";

/**@name 文本外观组件 */
export const TextAppearancePanelContent = React.memo(
  (props: { readonly targets: ReadonlySet<Text> }) => {
    const fields = Fields.of(useEqualSet(props.targets));

    return useObserver(() => {
      const textDirection = fields.textDirection.get() || "horizontal";
      return (
        <>
          <Row className="margin-y">
            <FontSizeValue field={fields.fontSize} />
          </Row>
          <Row className="margin-y">
            <TextDirectionValue field={fields.textDirection} />
          </Row>
          <Row className="margin-y">
            <LineAlignmentValue
              textDirection={textDirection}
              field={fields.lineAlignment}
            />
          </Row>
          <Row className="margin-y">
            <InlineAlignmentValue
              textDirection={textDirection}
              field={fields.regionAlignment}
            />
          </Row>
          <Row className="margin-y">
            <WrapValue field={fields.wrap} />
          </Row>
        </>
      );
    });
  }
);
TextAppearancePanelContent.displayName = "TextAppearancePanelContent";

/**@name 文本大小组件 */
export const TextSizePanelContent = React.memo(
  (props: { readonly targets: ReadonlySet<Text> }) => {
    const fields = Fields.of(useEqualSet(props.targets));

    return useObserver(() => {
      return (
        <TextStyleItem>
          <FontSizeValue field={fields.fontSize} />
        </TextStyleItem>
      );
    });
  }
);
TextSizePanelContent.displayName = "TextSizePanelContent";

const TextStyleItem = styled.div`
  width: 70px;
  display: flex;
`;
