import { observable, computed, action, makeObservable } from "mobx";
import {
  Mutable,
  SortedArray,
  typedEntriesOf,
  createWeakMapMapper,
} from "@chuyuan/poster-utils";
import { Vector2DArray } from "@chuyuan/poster-math";
import { Polygon, Bounding } from "./polygon";
import { EventHandlerMap } from "./events";

let NEXT_ID = 1;

let NEXT_ITEM_ID = 1;

export interface EventQueueItem<S = unknown, D = unknown> {
  readonly description: string;
  readonly data: D;
  readonly sortData: S;
  readonly pointer?: EventQueueItemPointer<S, D>;
  readonly native?: Partial<NativeEventMap>;
  readonly commands?: EventQueueItemCommandMap;
}

export type NativeEventMap = {
  readonly paste: (e: ClipboardEvent) => unknown;
  readonly drop: (e: DragEvent) => unknown;
};

interface InternalEventQueueItem<S = unknown, D = unknown>
  extends EventQueueItem<S, D> {
  readonly id: number;
}

export interface EventQueueItemPointer<S, D> {
  readonly container: Polygon | Bounding;
  readonly handlers: Partial<EventHandlerMap<EventQueueItem<S, D>>>;
}

export interface EventQueueItemCommandMap {
  readonly [command: string]: (() => unknown) | undefined;
}

/**
 * 事件队列
 * @generic S 排序对象类型
 */
export class EventQueue<S> {
  readonly id = NEXT_ID++;

  readonly type = "event queue" as const;

  private _sortedList: SortedArray<InternalEventQueueItem<S>>;

  constructor(
    compare: (a: S, b: S) => number,
    initialData?: Iterable<InternalEventQueueItem<S>>
  ) {
    this._sortedList = new SortedArray<InternalEventQueueItem<S>>(
      initialData,
      createCompareFn(compare),
      () => observable.array([], { deep: false })
    );
    makeObservable(this);
  }

  /**
   * 设置比较函数, 返回新的事件队列
   * @param compare 表函数
   */
  @action
  setCompareFn(compare: (a: S, b: S) => number) {
    return new EventQueue<S>(compare, this._sortedList);
  }

  @computed.struct
  get naive(): Partial<NativeEventMap> {
    const events: Mutable<Partial<NativeEventMap>> = {};
    for (const item of this._sortedList) {
      if (!item.native) continue;
      for (const entry of typedEntriesOf(
        item.native as Pick<NativeEventMap, "paste">
      )) {
        if (!entry) continue;
        const [key, value] = entry;
        if (!value) continue;
        if (events[key]) continue;
        events[key] = value;
      }
    }
    return events;
  }

  @computed.struct
  get commands(): EventQueueItemCommandMap {
    const commands: Mutable<EventQueueItemCommandMap> = {};
    for (const item of this._sortedList) {
      if (!item.commands) continue;
      for (const entry of typedEntriesOf(item.commands)) {
        if (!entry) continue;
        const [key, value] = entry;
        if (!value) continue;
        if (commands[key]) continue;
        commands[key] = value;
      }
    }
    return commands;
  }

  use<D>() {
    const id = NEXT_ITEM_ID++;
    let prev: InternalEventQueueItem<S, unknown> | undefined;
    const list = this._sortedList;
    const wrap = createWeakMapMapper(
      (
        item: EventQueueItem<S, unknown>
      ): InternalEventQueueItem<S, unknown> => {
        return {
          ...(item as EventQueueItem<S, unknown>),
          id,
        };
      }
    );
    return function use(item?: EventQueueItem<S, D>) {
      if (prev) list.remove(prev);
      if (item) {
        list.insert((prev = wrap(item)));
      } else {
        prev = undefined;
      }
    };
  }

  clear() {
    const list = this._sortedList;
    list.splice(0, list.length);
  }

  *collide(point: Vector2DArray): IterableIterator<EventQueueItem<S>> {
    for (const item of this._sortedList) {
      const { pointer } = item;
      if (pointer && pointer.container.contains(point)) {
        yield item;
      }
    }
  }

  *[Symbol.iterator]() {
    yield* this._sortedList;
  }
}

export function areEventQueueItemSame<S, D>(
  a?: EventQueueItem<S, D>,
  b?: EventQueueItem<S, D>
) {
  if (!a || !b) return true;
  if ("id" in a && "id" in b) {
    return (
      (a as { readonly id?: unknown }).id ===
      (b as { readonly id?: unknown }).id
    );
  }
  return false;
}

const createCompareFn = createWeakMapMapper(<S>(fn: (a: S, b: S) => number) => {
  type Item = Pick<InternalEventQueueItem<S>, "sortData" | "id">;
  return function compare(a: Item, b: Item) {
    const value = fn(a.sortData, b.sortData);
    if (value) return value;
    const id1 = a.id;
    const id2 = b.id;
    return id1 < id2 ? -1 : id1 > id2 ? 1 : 0;
  };
});
