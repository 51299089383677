import * as React from "react";
import { useObserver } from "mobx-react";

import { LayoutPropertiesPanel } from "./layout-properties-panel";
import { LayoutAdjustmentPanel } from "./layout-adjustment-panel";
import { LayoutConstraintPanel } from "./layout-constraint-panel";
import { AbsoluteAlignmentBar } from "./abs-alignment-bar";
import { LayoutFields } from "./state.ui";
import { LayoutContainerPanel } from "./layout-container-panel";
import { useModule } from "../../utils/modulize";
import { EditorSlotModuleToken } from "../editor/editor-slot-module";
import { EditorContext } from "../helpers/react-context";
import { getSelectionFilter } from "../helpers/selection-filter";

/**@name 布局小组件 */
export const UseLayoutPanelWidget = React.memo(
  () => {
    return useObserver(() => {
      const { session } = React.useContext(EditorContext);
      const EditorSlotModule = useModule(EditorSlotModuleToken);

      if (!EditorSlotModule) return null;

      const selection = session.selection.set;

      // console.log("UseLayoutPanelWidget", { selection: Array.from(selection) });

      const fields = LayoutFields.of(session.root, selection.size ? selection : session.root);

      const filter = getSelectionFilter(session.root, selection);

      return (
        <>
          <EditorSlotModule.properties.Portal kind="spread" priority={50}>
            {filter.elementsOnly.size !== filter.set.size ? null : <AbsoluteAlignmentBar targets={filter.elementsOnly} />}
            <LayoutPropertiesPanel fields={fields} />
            {!fields.hasOnlyContainers ? null : <LayoutContainerPanel fields={fields} />}
            <LayoutAdjustmentPanel fields={fields} />
            {!fields.constraints.enabled.get() ? null : <LayoutConstraintPanel fields={fields} />}
          </EditorSlotModule.properties.Portal>
        </>
      );
    });
  },
  () => true
);
