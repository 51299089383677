import * as React from "react";
import styled from "styled-components";
import * as cx from "classnames";
import { CommonProps, formatMinMax, formatValueBetweenMinMax } from "./common";
import { safeDimension } from "./data-guard";
import { EMPTY_ARRAY, Disposers } from "@chuyuan/poster-utils";

export interface SingleSliderProps extends CommonProps {
  readonly min?: number;
  readonly max?: number;
  readonly step?: number;
  readonly value?: number;
  readonly onChange?: (value: number) => void;
  readonly onComplete?: (value: number) => void;
}

export interface SingleSliderRef {
  readonly wrapper: HTMLDivElement | null;
}

export const SingleSlider = React.forwardRef<
  SingleSliderRef,
  SingleSliderProps
>((props, ref) => {
  const { value: inputValue } = props;

  React.useImperativeHandle(ref, () => ({
    get wrapper() {
      return state.wrapperRef || null;
    },
  }));

  const state = React.useRef<{
    wrapperRef?: HTMLDivElement | null;
    handlerRef?: HTMLDivElement | null;
    disposers?: Disposers;
    minmax?: ReturnType<typeof formatMinMax>;
    props?: SingleSliderProps;
  }>({}).current;
  const disposers = state.disposers || (state.disposers = new Disposers());

  React.useEffect(() => () => disposers.clear(), EMPTY_ARRAY);

  const minmax = (state.minmax = formatMinMax(
    props.min ?? 0,
    props.max ?? 100
  ));

  const value =
    inputValue === undefined || !minmax
      ? undefined
      : formatValueBetweenMinMax(inputValue, minmax);

  const disabled = props.disabled || value === undefined;

  const inputPercent = formatPercent(
    value !== undefined && minmax
      ? (value - minmax.min) / (minmax.max - minmax.min)
      : 0
  );

  const [isDragging, setIsDragging] = React.useState(false);
  const [draftPercent, setDraftPercent] = React.useState(inputPercent);

  const activePercent = isDragging ? draftPercent : inputPercent;

  const getNewValueFromPercent = (pc: number) => {
    const p = state.props || props;
    const step = safeDimension(p.step ?? 1);
    if (!step) return;
    const mm = state.minmax;
    if (!mm) return;
    const { max, min } = mm;
    if (min >= max) return;
    const length = max - min;
    const floatValue = formatPercent(pc) * length + min;
    const steppedValue = Math.round(floatValue / step) * step;
    const newValue = formatValueBetweenMinMax(steppedValue, mm)!;
    return {
      value: newValue,
      percent: formatPercent((newValue - min) / length),
    };
  };

  const onDragStart = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    initValue: number,
    initPercent: number,
    changed: boolean
  ) => {
    disposers.override("drag", () => {
      setIsDragging(false);
      document.removeEventListener("mousemove", handleMouseMove, true);
      document.removeEventListener("mouseup", handleMouseUp, true);
    });

    setIsDragging(true);
    setDraftPercent(initPercent);

    const init = {
      x: event.pageX,
      y: event.pageY,
      percent: initPercent,
    };

    let prevValue = initValue;

    const handleMouseMove = (e: MouseEvent) => {
      if (!(e.buttons & 1)) {
        // 左键没有按下, 认为松开了
        return handleMouseUp(e);
      }

      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();

      const p = state.props || props;
      if (p.readOnly) return;

      const step = safeDimension(p.step ?? 1);
      if (!step) return;

      const wrapper = state.wrapperRef;
      if (!wrapper) return;

      const { width } = wrapper.getBoundingClientRect();
      if (!width) return;

      const ret = getNewValueFromPercent(
        init.percent + (e.pageX - init.x) / width
      );
      if (!ret) return;

      changed = true;

      prevValue = ret.value;
      setDraftPercent(ret.percent);
      p.onChange?.(ret.value);
    };

    const handleMouseUp = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      disposers.dispose("drag");
      const p = state.props || props;
      if (p.readOnly) return;
      if (changed) {
        p.onComplete?.(prevValue);
      }
    };

    document.addEventListener("mousemove", handleMouseMove, true);
    document.addEventListener("mouseup", handleMouseUp, true);
  };

  return (
    <Wrapper
      className={cx(
        props.className,
        disabled && "disabled",
        isDragging && "dragging"
      )}
      ref={(r) => (state.wrapperRef = r)}
      onMouseDown={
        disabled
          ? undefined
          : (e) => {
              if (!(e.buttons & 1)) return;
              if (value === undefined) return;
              const { left, right } = e.currentTarget.getBoundingClientRect();
              if (left >= right) return;
              const length = right - left;
              const percent =
                (formatValueBetweenMinMax(e.pageX, { min: left, max: right })! -
                  left) /
                length;
              const ret = getNewValueFromPercent(percent);
              if (!ret) return;
              state.handlerRef?.focus();
              if (ret.value !== value) {
                onDragStart(e, ret.value, ret.percent, true);
              }
              props.onChange?.(ret.value);
            }
      }
    >
      <div className="slider-rail" />
      <div
        className="slider-track"
        style={{ width: `${activePercent * 100}%` }}
      />
      <div
        ref={(r) => (state.handlerRef = r)}
        tabIndex={0}
        className="slider-handle"
        role="slider"
        aria-valuemin={minmax?.min}
        aria-valuemax={minmax?.max}
        aria-valuenow={value}
        aria-disabled={disabled}
        onMouseDown={(e) => {
          if (!(e.buttons & 1)) return;
          e.stopPropagation();
          if (value === undefined) return;
          onDragStart(e, value, inputPercent, false);
        }}
        onKeyDown={(e) => {
          const { key } = e;
          switch (key) {
            case "ArrowLeft":
            case "ArrowRight":
            case "ArrowUp":
            case "ArrowDown": {
              e.preventDefault();
              e.stopPropagation();

              if (props.readOnly) return;
              if (typeof value !== "number" || !minmax) return;

              const step = safeDimension(props.step ?? 1);
              if (!step) return;

              const s = e.shiftKey ? 10 : e.metaKey || e.altKey ? 100 : 1;
              const d = key === "ArrowLeft" || key === "ArrowDown" ? -s : s;

              const expectedValue = formatValueBetweenMinMax(
                value + d * step,
                minmax
              )!;
              const newValue =
                Math.round((expectedValue - minmax.min) / step) * step +
                minmax.min;
              const formattedNewValue = formatValueBetweenMinMax(
                newValue,
                minmax
              )!;
              if (formattedNewValue === value) return;
              props.onChange?.(formattedNewValue);
              break;
            }
          }
        }}
        style={{ left: `${activePercent * 100}%` }}
      />
    </Wrapper>
  );
});

function formatPercent(x: number) {
  if (Number.isNaN(x)) return 0;
  return x < 0 ? 0 : x > 1 ? 1 : x;
}

const Wrapper = styled.div`
  position: relative;
  flex: 1 1 0;
  width: 0;
  height: 10px;
  margin: 0 5px;

  &.auto {
    flex: 0 0 auto;
    width: auto;
  }

  .slider-rail,
  .slider-track {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 4px;
    border-radius: 2px;
  }

  .slider-rail {
    left: 0;
    right: 0;
    background: #f8f7f6;
  }

  .slider-track {
    width: 0;
    background: #0040f0;
    pointer-events: none;
  }

  .slider-handle {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background: #fff;
    box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.12),
      0px 6px 13px rgba(0, 0, 0, 0.12);
    transform: translateX(-50%);
    outline: 1px solid transparent;
    transition: outline 0.3s;
  }

  &:not(.disabled) {
    &.dragging,
    &:hover,
    &:focus {
      .slider-handle {
        outline-color: #0040f0;
      }
    }
    .slider-handle:focus {
      outline-color: #0040f0;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;

    & > * {
      pointer-events: none;
    }

    .slider-handle {
      display: none;
    }
  }
`;
