import * as React from 'react'
import styled from 'styled-components'

import { Popup } from './popup'
import { Dialog, DialogProps } from './dialog'

export interface ModalProps extends DialogProps {
  readonly mask?: boolean
  readonly maskClosable?: boolean
  readonly onClose?: () => void
  readonly children?: React.ReactNode
}

export function Modal(props: ModalProps) {
  const { onClose, children, mask, maskClosable, ...rest } = props
  const useVisualMask = mask ?? true
  const isMaskClosable = maskClosable ?? true
  return (
    <Popup
      useMask={isMaskClosable}
      onClose={onClose}
      render={() => (
        <Outline>
          {useVisualMask ? <div className="mask" /> : null}
          <div className="content">
            <Dialog {...rest} onClose={onClose}>
              {children}
            </Dialog>
          </div>
        </Outline>
      )}
    />
  )
}

const Outline = styled.div`
  & > .mask {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.45);
    pointer-events: none;
  }

  & > .content {
    position: absolute;
    left: 50vw;
    top: 50vh;
    transform: translate(-50%, -50%);
  }
`
