import { computed } from "mobx";
import { GDLLayoutSemantic } from "@chuyuan/poster-data-access-layer";
import { memorize, EMPTY_ARRAY } from "@chuyuan/poster-utils";
import { IdentityAffine2D } from "@chuyuan/poster-math";

import type { RootState } from "./state";
import { PointerEventRegister } from "./event";
import { BBox } from "./bbox";
import { ResizeState } from "./resize.state";
import { mergeTargetsVisualBounding } from "../helpers/gdl-bounding";
import { getOptimisticSelection } from "../helpers/selection-filter";
import { REVERSE_INFINITE_BOUNDING } from "../helpers/misc";

export class MultiSelectionState {
  constructor(readonly root: RootState) {}

  dispose() {}

  get isMultiSelected() {
    return this.root.props.session.selection.isMoreThanOne;
  }

  get isOptimisticMultiSelected() {
    const { session } = this.root.props;
    return getOptimisticSelection(session).isMoreThanOne;
  }

  @computed
  get bounding() {
    const { session } = this.root.props;
    const selection = getOptimisticSelection(session).set;
    return mergeTargetsVisualBounding(selection) || REVERSE_INFINITE_BOUNDING;
  }

  @memorize
  get selectionRegister(): PointerEventRegister {
    return {
      path: EMPTY_ARRAY,
      layer: "multi",
      selected: true,
      priority: -100,
      data: () => ({
        bbox: new BBox(this.bounding),
        handlers: {
          down: (e) => {
            e.stopPropagation();
            const { absoluteX: x, absoluteY: y } = e.data;
            this.root.handleMoveStart(e, { x, y });
          },
          move: (e) => {
            e.stopPropagation();
          },
          up: (e) => {
            e.stopPropagation();
            this.root.handleSelectionEnd();
          },
        },
      }),
    };
  }

  @memorize
  get resize() {
    const { root } = this;
    const allowedSides = { horizontal: true, vertical: true, corner: true };
    return new ResizeState<GDLLayoutSemantic.ResizeMultipleInitialSnapshot>({
      getRoot: () => root,
      getColor: () => "#a0a0a0",
      getHoverRegister: () => ({
        path: EMPTY_ARRAY,
        layer: "global",
        selected: true,
      }),
      getAllowedSides: () => allowedSides,
      getBounding: () => this.bounding,
      getSnapshot: () => {
        const snapshots = new Map(
          Array.from(root.props.session.selection).map((target) => {
            return [target, GDLLayoutSemantic.getResizeInitialSnapshot(target)];
          })
        );
        return {
          bounding: this.bounding,
          transform: IdentityAffine2D,
          snapshots,
        };
      },
      apply: (init, input) => {
        GDLLayoutSemantic.resizeMultiple(init, input);
      },
      onStart: () => {
        const { viewport } = root.props.session;
        viewport.disableConstraint();
      },
      onEnd: () => {
        const { viewport } = root.props.session;
        viewport.enableConstraint();
      },
    });
  }
}
