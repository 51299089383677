import { memorize, EMPTY_ARRAY } from "@chuyuan/poster-utils";

import type { RootState } from "./state";
import { PointerEventRegister, PointerEventRegisterData } from "./event";
import { BBox } from "./bbox";
import { INFINITE_BOUNDING } from "../helpers/misc";

export class SelectionState {
  hasMoved = false;

  constructor(
    readonly root: RootState,
    readonly data: {
      readonly x: number;
      readonly y: number;
    }
  ) {}

  dispose() {}

  @memorize
  get register(): PointerEventRegister {
    const data: PointerEventRegisterData = {
      bbox: new BBox(INFINITE_BOUNDING),
      handlers: {
        move: (e) => {
          e.stopPropagation();
          if (this.hasMoved) return;
          this.hasMoved = true;
          // 转交移动开始的控制权
          this.root.handleMoveStart(e, this.data);
        },
        up: (e) => {
          try {
            e.stopPropagation();
            this.hasMoved = false;
          } finally {
            this.root.handleSelectionEnd();
          }
        },
      },
    };
    return {
      path: EMPTY_ARRAY,
      layer: "highlight",
      selected: false,
      priority: -100,
      data: () => data,
    };
  }
}
