import * as React from "react";
import { SortedArray, EMPTY_ARRAY } from "@chuyuan/poster-utils";
import { EventLayer, EventLayerPriority } from "./common";
import { HooksSubscription } from "../../utils/react-hooks";
import { observable } from "mobx";
import { useObserver } from "mobx-react";

export class RenderRootState {
  counter = 0;

  nextItemId = 0;

  readonly sub = new HooksSubscription<number>();

  readonly list = new SortedArray<RenderPropsItem>(
    undefined,
    compareRenderPropsItem,
    () => observable.array([], { deep: false })
  );

  broadcast() {
    this.sub.emit(++this.counter);
  }
}

export interface RenderProps {
  readonly layer: EventLayer;
  readonly path: readonly number[];
  readonly selected: boolean;
  readonly priority?: number;
  readonly children: React.ReactNode;
}

export const RenderList = React.memo(
  (props: { readonly root: RenderRootState }) => {
    return useObserver(() => {
      const { root } = props;
      // const { sub } = root
      // const [, setCounter] = React.useState(root.counter)
      // React.useEffect(() => (setCounter(root.counter), sub.listen(setCounter)), [sub, root])

      const children: React.ReactNode[] = [];

      let i = 0;
      for (const p of root.list) {
        children.push(
          React.createElement(React.Fragment, { key: i }, p.props.children)
        );
        i++;
      }

      return React.createElement(React.Fragment, null, children);
    });
  }
);
RenderList.displayName = "RenderList";

interface RenderPropsItem {
  id: number;
  props: RenderProps;
}

export function Render({
  root,
  props,
}: {
  readonly root: RenderRootState;
  readonly props: RenderProps;
}) {
  const state = React.useRef<{
    root?: RenderRootState;
    item?: RenderPropsItem;
  }>({}).current;

  React.useEffect(
    () => () => {
      const prevRoot = state.root;
      const prevItem = state.item;
      if (prevRoot && prevItem) {
        prevRoot.list.remove(prevItem);
        prevRoot.broadcast();
      }
    },
    EMPTY_ARRAY
  );

  React.useEffect(() => {
    const prevRoot = state.root;
    let prevItem = state.item;

    const isRootDifferent = prevRoot !== root;

    if (isRootDifferent || prevItem?.props !== props) {
      if (prevRoot && prevItem) {
        prevRoot.list.remove(prevItem);
        if (isRootDifferent) {
          prevRoot.broadcast();
        }
      }
      state.root = root;
      if (prevItem) {
        prevItem.props = props;
      } else {
        prevItem = state.item = { id: root.nextItemId++, props };
      }
      root.list.insert(prevItem);
      root.broadcast();
    }
  });

  {
    // const prevRoot = state.root
    // let prevItem = state.item

    // const isRootDifferent = prevRoot !== root

    // if (isRootDifferent || prevItem?.props !== props) {
    //   if (prevRoot && prevItem) {
    //     prevRoot.list.remove(prevItem)
    //     if (isRootDifferent) {
    //       prevRoot.broadcast()
    //     }
    //   }
    //   state.root = root
    //   if (prevItem) {
    //     prevItem.props = props
    //   } else {
    //     prevItem = state.item = { id: root.nextItemId++, props }
    //   }
    //   root.list.insert(prevItem)
    //   root.broadcast()
    // }

    return null;
  }
}

function compareRenderPropsItem(x: RenderPropsItem, y: RenderPropsItem) {
  const a = x.props;
  const b = y.props;
  const layer1 = EventLayerPriority[a.layer];
  const layer2 = EventLayerPriority[b.layer];
  if (layer1 < layer2) return -1;
  if (layer1 > layer2) return 1;
  const p1 = a.path;
  const p2 = b.path;
  const l1 = p1.length;
  const l2 = p2.length;
  if (l1 < l2) return -1;
  if (l1 > l2) return 1;
  const s1 = a.selected ? 1 : 0;
  const s2 = b.selected ? 1 : 0;
  if (s1 < s2) return -1;
  if (s1 > s2) return 1;
  if (p1 < p2) return -1;
  if (p1 > p2) return 1;
  const v1 = a.priority || 0;
  const v2 = b.priority || 0;
  if (v1 < v2) return -1;
  if (v1 > v2) return 1;
  return y.id - x.id;
}
