import { XY_TO_WH } from "@chuyuan/poster-data-access-layer";
import { makeObservable, observable } from "mobx";
import { getTargetVisualBounding } from "../helpers/gdl-bounding";
import { SessionState } from "./session-state";
import { SelectableTarget } from "./types";

const DEFAULT_BLEEDING = 40;

const DEFAULT_BLEEDING_2X = DEFAULT_BLEEDING * 2;

export class CanvasViewport {
  constructor(readonly session: SessionState) {
    makeObservable(this);
  }

  /**
   * @internal
   */
  @observable.ref
  _isOffsetLimitationEnabled = false;

  /**
   * @internal
   */
  @observable.ref
  _overrideDefaultScale?: number;

  /**
   * @internal
   */
  @observable.ref
  _x = 0;

  /**
   * @internal
   */
  @observable.ref
  _y = 0;

  /**
   * @internal
   */
  @observable.ref
  _scale?: number = undefined;

  get x() {
    if (!this._isOffsetLimitationEnabled) return this._x;
    return this.getValidOffset("x");
  }

  set x(value: number) {
    this._x = value;
  }

  get y() {
    if (!this._isOffsetLimitationEnabled) return this._y;
    return this.getValidOffset("y");
  }

  set y(value: number) {
    this._y = value;
  }

  get scale() {
    const value = this._scale;
    const v = typeof value === "number" ? value : this.defaultScale;
    return Number.isFinite(v) ? Math.abs(v) : 1;
  }

  get defaultScale() {
    const override = this._overrideDefaultScale;
    if (typeof override === "number") return override;
    const { session } = this;
    const { viewport } = session.editor;
    const { width, height } = session.root.layout.selfBox;
    return Math.min(
      (viewport.width - DEFAULT_BLEEDING_2X) / width,
      (viewport.height - DEFAULT_BLEEDING_2X) / height
    );
  }

  /**
   * 以视窗中点为中心缩放
   * @param deltaScale 缩放变化值
   */
  zoom(deltaScale: number) {
    // 计算scale
    const { width, height } = this.session.root.layout.selfBox;
    const minScale = 1 / Math.min(width, height);
    const maxScale = 2000;
    const s0 = this.scale;
    let s = s0;
    s *= Math.exp(deltaScale / 100);
    s = Math.min(Math.max(minScale, s), maxScale);

    // 计算中心偏移
    const k = 1 - s0 / s;
    const { x, y } = this;
    const dX = 0;
    const dY = 0;

    // 缩放平移viewport
    this._scale = s;
    this._x = x + k * (x + dX);
    this._y = y + k * (y + dY);
  }

  /**
   * 以视窗中点为中心缩放
   * @param record
   */
  wheel(record: WheelRecord) {
    const { width: cw, height: ch } = this.session.root.layout.selfBox;
    const { x, y } = this;
    if (record.ctrl) {
      // 计算scale
      const minScale = 1 / Math.min(cw, ch) || 0;
      const maxScale = 2000;
      const { dy } = record;
      const s0 = this.scale;
      let s = s0;
      s *= Math.exp(-dy / 100);
      s = Math.min(Math.max(minScale, s), maxScale);

      // 计算中心偏移
      const k = 1 - s0 / s;
      const { width, height } = this.session.editor.viewport;
      const dX = width / 2 - record.x;
      const dY = height / 2 - record.y;

      // 缩放平移viewport
      this._scale = s;
      this._x = x + k * (x + dX);
      this._y = y + k * (y + dY);
    } else {
      let { dx, dy } = record;
      if (record.mode === DOM_DELTA_LINE) {
        dx *= cw * 0.125;
        dy *= ch * 0.125;
      } else if (record.mode === DOM_DELTA_PAGE) {
        dx *= cw * 0.5;
        dy *= ch * 0.5;
      }
      this._x = x - dx;
      this._y = y - dy;
    }
  }

  /**
   * 重置视图
   */
  reset() {
    this._x = 0;
    this._y = 0;
    this._scale = undefined;
  }

  /**
   * 调整视窗以显示对象
   */
  scrollIntoView(target: SelectableTarget, _options?: ScrollIntoViewOptions) {
    const b = getTargetVisualBounding(target);
    const tx = (b.left + b.right) / 2;
    const ty = (b.top + b.bottom) / 2;
    const width = b.right - b.left;
    const height = b.bottom - b.top;
    const { viewport } = this.session.editor;
    const { width: W, height: H } = viewport;
    const { width: cw, height: ch } = this.session.root.layout.selfBox;
    if (W > DEFAULT_BLEEDING_2X && H > DEFAULT_BLEEDING_2X) {
      const s = Math.min(
        width ? (W - DEFAULT_BLEEDING_2X) / width : Infinity,
        height ? (H - DEFAULT_BLEEDING_2X) / height : Infinity
      );
      const scale = Number.isFinite(s) && s > 0 ? s : 1;
      const dx = (cw / 2 - tx) * scale;
      const dy = (ch / 2 - ty) * scale;
      this._scale = scale;
      this._x = dx;
      this._y = dy;
    } else {
      const dx = cw / 2 - tx;
      const dy = ch / 2 - ty;
      this._x = dx;
      this._y = dy;
    }
  }

  /**
   * 制作视窗数据快照
   */
  snapshot() {
    const { viewport } = this.session.editor;
    return new CanvasViewportSnapshot({
      x: this.x,
      y: this.y,
      scale: this.scale,
      width: viewport.width,
      height: viewport.height,
    });
  }

  /**
   * @internal
   */
  getValidOffset(key: "x" | "y") {
    const p = DEFAULT_BLEEDING;

    const dimKey = XY_TO_WH[key];
    const { session } = this;
    const { viewport } = session.editor;
    const parent = viewport[dimKey];
    const dim = session.root.layout.selfBox[dimKey];
    const { scale } = this;
    const dimension = dim * scale;
    const offset = key === "x" ? this._x : this._y;
    const k = Math.abs((parent - dimension) / 2 - p);
    if (offset < -k) return -k;
    if (offset > k) return k;
    return offset;
  }

  /**
   * @internal
   */
  disableConstraint() {
    this._x = this.x;
    this._y = this.y;
    this._isOffsetLimitationEnabled = false;
    this._overrideDefaultScale = this.scale;
  }

  /**
   * @internal
   */
  enableConstraint() {
    this._isOffsetLimitationEnabled = true;
    this._overrideDefaultScale = undefined;
  }
}

export type CanvasViewportSnapshotData = {
  readonly x: number;
  readonly y: number;
  readonly scale: number;
  readonly width: number;
  readonly height: number;
};

export class CanvasViewportSnapshot {
  constructor(readonly data: CanvasViewportSnapshotData) {}

  fromAbsolute(absoluteX: number, absoluteY: number) {
    const { x, y, scale, width, height } = this.data;
    const X = (absoluteX - width / 2 - x) / scale;
    const Y = (absoluteY - height / 2 - y) / scale;
    return [X, Y] as [number, number];
  }
}

export const DOM_DELTA_PIXEL = 0x00;
export const DOM_DELTA_LINE = 0x01;
export const DOM_DELTA_PAGE = 0x02;

export type WheelRecord = {
  readonly mode: number;
  readonly x: number;
  readonly y: number;
  readonly dx: number;
  readonly dy: number;
  readonly ctrl: boolean;
};
