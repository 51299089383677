import * as React from "react";
import { useObserver } from "mobx-react";

import { Root } from "./ui";
import { EditorContext } from "../helpers/react-context";

export const CanvasOperationModule = React.memo(
  () => {
    const { session } = React.useContext(EditorContext);
    return useObserver(() => {
      return <Root target={session.root} />;
    });
  },
  () => true
);
