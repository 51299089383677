import * as React from "react";
import * as ReactDOM from "react-dom";

import { EMPTY_ARRAY } from "@chuyuan/poster-utils";
import { usePopupContainer } from "./popup";

export interface DragMaskProps {
  readonly className?: string;
  readonly style?: React.CSSProperties;
  readonly onDragging?: (e: MouseEvent) => void;
  readonly onDragEnd?: (e: MouseEvent) => void;
}

/**
 * 拖拽蒙版组件
 */
export function DragMask(props: DragMaskProps) {
  const stateRef = React.useRef<{
    props: DragMaskProps;
    readonly effect: React.EffectCallback;
  }>();
  const state =
    stateRef.current ||
    (stateRef.current = {
      props,
      effect: () => {
        const onMove = (e: MouseEvent) => state.props.onDragging?.(e);
        const onUp = (e: MouseEvent) => state.props.onDragEnd?.(e);
        window.addEventListener("mousemove", onMove);
        window.addEventListener("mouseup", onUp);
        return () => {
          window.removeEventListener("mousemove", onMove);
          window.removeEventListener("mouseup", onUp);
        };
      },
    });

  React.useEffect(state.effect, EMPTY_ARRAY);

  const container = usePopupContainer();

  return ReactDOM.createPortal(
    <div
      className={props.className}
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        ...props.style,
      }}
    />,
    container
  );
}
