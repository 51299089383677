import * as React from "react";
import {
  EMPTY_ARRAY,
  MaybePromise,
  isThenable,
  createExternalPromise,
  EMPTY_OBJECT,
  IsTypeAnyOrUnknown,
} from "@chuyuan/poster-utils";
import { HooksSubscription, typedMemo } from "./react-hooks";

/**
 * 模块定义和操作器
 */
export interface ModuleToken<TState> {
  readonly TState: TState;
  /**
   * 模块令牌id
   * - 按照调用属性自增数字, 初始值为 0
   */
  readonly id: number;
  /**
   * 模块语义名
   */
  readonly name?: string;
}

let nextModuleId = 0;

/**
 * 定义一个模块的声明令牌
 * @param name 模块的名字
 */
export function createModuleToken<TState = undefined>(
  name?: string
): ModuleToken<TState> {
  return { id: nextModuleId++, name } as ModuleToken<TState>;
}

/**
 * 实现一个模块
 * @param token 模块令牌
 * @param def 定义内容
 */
export function implementModule<TState>(
  token: ModuleToken<TState>,
  implementation: ModuleImplementation<TState>
) {
  return new ModuleOperator(token, implementation);
}

/**
 * [react hooks]
 * 获取模块的初始化状态
 * @param token
 */
export function useModuleInitialization<T>(token: ModuleToken<T>) {
  const host = useModuleHostContext();
  const instance = host.get(token);
  const { init } = instance;
  const setInit = React.useState(init)[1];
  React.useEffect(() => {
    // 因为注册可能发生在 useEffect 之前, 需要手动检测一次
    if (init !== instance.init) {
      setInit(instance.init);
    }
    return instance.$init.listen(setInit);
  }, EMPTY_ARRAY);
  return init;
}

/**
 * [react hooks]
 * 获取模块的数据
 * @param token
 */
export function useModule<T>(token: ModuleToken<T>) {
  const init = useModuleInitialization(token);
  if (init.state === "resolved") return init.data;
  return undefined;
}

/**
 * [react hooks]
 * 获取模块的数据
 * @param token
 */
export function useModuleAsync<T>(token: ModuleToken<T>) {
  const host = useModuleHostContext();
  const instance = host.get(token);
  return instance.xp.promise;
}

/**
 * 注册一个模块状态范围
 */
// 因为 children 可能会更新, 所以不用 memo wrap
export const ModuleHostProvider = (props: React.PropsWithChildren) => {
  const host = React.useMemo(() => new ModuleHost(), EMPTY_ARRAY);
  return (
    <ModuleHostContext.Provider value={host}>
      <RegisterModuleInstances />
      {props.children}
    </ModuleHostContext.Provider>
  );
};

// 因为注册了之后就不再更新, 直接用 memo wrap 永不重渲染
const RegisterModuleInstances = React.memo(
  () => {
    const host = useModuleHostContext();
    const [, setData] = React.useState(EMPTY_OBJECT);

    // 监听模块实例的变化
    React.useEffect(() => {
      // 因为注册可能发生在 useEffect 之前, 需要手动 set 一次
      setData({});
      return host.$newInstance.listen(setData);
    }, [host]);

    const nodes = [];

    const { instances } = host;

    for (const [token, operator] of host.operators.entries()) {
      if (operator.def.render) {
        const instance = instances.get(token)!;
        nodes.push(
          <RenderModuleInstance
            key={instance.id}
            instance={instance}
            operator={operator}
          />
        );
      }
    }

    return <>{nodes}</>;
  },
  () => true
);

RegisterModuleInstances.displayName = "RegisterModuleInstances";

// 因为 instance id 是不会重复的, 所以不需要比对 props
const RenderModuleInstance = typedMemo(
  function module<T>(props: {
    readonly instance: ModuleInstance<T>;
    readonly operator: ModuleOperator<T>;
  }) {
    const { instance } = props;
    const [init, setInit] = React.useState(instance.init);

    React.useEffect(() => instance.$init.listen(setInit), [init]);

    return props.operator.def.render!(init as ModuleInitialization<T>) ?? null;
  },
  () => true
);

/**
 * 模块注册宿主 React 上下文
 */
const ModuleHostContext = React.createContext(
  undefined as ModuleHost | undefined
);

function useModuleHostContext() {
  const host = React.useContext(ModuleHostContext);
  if (!host) {
    throw new Error(
      `[modulize] host is undefined, please make should <Provider> is used to wrap modules scope`
    );
  }
  return host;
}

/**
 * 模块注册宿主
 */
class ModuleHost {
  private _nextInstanceId = 0;

  readonly instances = new Map() as TypedModuleInstanceMap;

  readonly operators = new Map() as TypedModuleOperatorMap;

  readonly $newInstance = new HooksSubscription<{}>();

  get<T>(token: ModuleToken<T>): ModuleInstance<T> {
    const map = this.instances;
    let instance = map.get(token);
    if (!instance) {
      map.set(token, (instance = new ModuleInstance(this._nextInstanceId++)));
      const operator = this.operators.get(token);
      if (operator) {
        const init = operator.def.state || (() => undefined as unknown as T);
        instance.set(() => init(this));
        this.$newInstance.emit({});
      }
    }
    return instance;
  }

  register<T>(operator: ModuleOperator<T>) {
    const { token } = operator;
    const map = this.operators;
    const existed = map.get(token);
    if (existed) {
      if (existed === operator) return;
      const nameString = token.name ? ` ${JSON.stringify(token.name)}` : "";
      throw new Error(
        `Module(${token.id})${nameString} has been defined before, and is defined again`
      );
    }
    map.set(token, operator);

    const { instances } = this;
    let instance = instances.get(token);
    if (!instance) instance = new ModuleInstance(this._nextInstanceId++);
    this.instances.set(token, instance);

    const init = operator.def.state || (() => undefined as unknown as T);
    instance.set(() => init(this));
    this.$newInstance.emit({});
  }
}

export type { ModuleHost };

class ModuleInstance<TState = unknown> {
  readonly xp = createExternalPromise<TState>();

  constructor(readonly id: number) {}

  init: ModuleInitInternal<TState> = { state: "not implemented" };

  set(init: () => MaybePromise<TState>) {
    this.init = { state: "pending" };
    const xp = this.xp;
    try {
      const p = init();
      if (isThenable(p)) {
        p.then(
          (state) => {
            this.update({ state: "resolved", data: state });
            xp.resolve(state);
          },
          (error) => {
            this.update({ state: "rejected", error });
            xp.reject(error);
          }
        );
      } else {
        this.update({ state: "resolved", data: p });
        xp.resolve(p);
      }
    } catch (e) {
      this.update({ state: "rejected", error: e });
      xp.reject(e);
    }
  }

  readonly $init = new HooksSubscription<ModuleInitInternal<TState>>();

  update(data: ModuleInitInternal<TState>) {
    this.init = data;
    this.$init.emit(data);
  }
}

type ModuleImplementation<TState> = IsStateOptional<
  { v: TState },
  {
    /**
     * 初始化模块状态函数
     */
    readonly state?: (host: ModuleHost) => MaybePromise<TState>;
  },
  {
    /**
     * 初始化模块状态函数
     */
    readonly state: (host: ModuleHost) => MaybePromise<TState>;
  }
> & {
  /**
   * 模块被激活时的react渲染函数
   */
  readonly render?: (
    init: ModuleInitialization<TState>
  ) => JSX.Element | null | undefined;
};

class ModuleOperator<TState> {
  constructor(
    readonly token: ModuleToken<TState>,
    readonly def: ModuleImplementation<TState>
  ) {}

  readonly Register = React.memo(
    () => {
      const ctx = useModuleHostContext();
      React.useEffect(() => {
        ctx.register(this);
      }, EMPTY_ARRAY);
      return null;
    },
    () => true
  );
}

export type { ModuleOperator };

export type ModuleInitialization<T> =
  | {
      readonly state: "not implemented";
    }
  | {
      readonly state: "pending";
    }
  | {
      readonly state: "resolved";
      readonly data: T;
    }
  | {
      readonly state: "rejected";
      readonly error: unknown;
    };

type ModuleInitInternal<T> =
  | {
      readonly state: "not implemented";
    }
  | ModuleInitialization<T>;

type IsStateOptional<
  T extends { readonly v: unknown },
  A = true,
  B = false
> = IsTypeAnyOrUnknown<T["v"], B, T["v"] extends void | undefined ? A : B>;

interface TypedModuleInstanceMap extends Map<object, unknown> {
  get<T>(key: ModuleToken<T>): ModuleInstance<T> | undefined;
  set<T>(key: ModuleToken<T>, value: ModuleInstance<T>): this;
}

interface TypedModuleOperatorMap extends Map<object, unknown> {
  keys(): IterableIterator<ModuleToken<unknown>>;
  entries(): IterableIterator<[ModuleToken<unknown>, ModuleOperator<unknown>]>;
  get<T>(key: ModuleToken<T>): ModuleOperator<T> | undefined;
  set<T>(key: ModuleToken<T>, value: ModuleOperator<T>): this;
}
