import { computed, makeObservable, observable, runInAction } from "mobx";
import * as raf from "raf";
import { mapValues, once } from "lodash";

import {
  EMPTY_ARRAY,
  memorize,
  guardTypeExtends,
  cached,
  Disposers,
  createValueInSetGuardian,
} from "@chuyuan/poster-utils";
import {
  AnyParentModelType,
  Frame,
  GDLLayoutSemantic,
  LeafModelType,
} from "@chuyuan/poster-data-access-layer";
import {
  ReadonlyAffine2D,
  IdentityAffine2D,
  mergeBoundings,
  isBoundingCollided,
  Affine2DTimesBounding,
} from "@chuyuan/poster-math";

import {
  EventSystemModuleToken,
  SelectGDLObject,
  ClearGDLObjectSelection,
} from "../event-system";
import {
  EventRoot,
  PointerEventData,
  PointerEventAction,
  PointerEventHandlers,
  PointerEvent,
  PointerEventRegisterData,
  PointerEventRegister,
} from "./event";
import { BBox } from "./bbox";
import { RenderRootState } from "./render";
import { FlexListReorderState } from "./flex-list-reorder.state";
import { SelectionState } from "./selection.state";
import { MoveState } from "./move.state";
import { MultiSelectionState } from "./multi-selection.state";
import { RotationState } from "./rotation.state";
import { ResizeState } from "./resize.state";
import { SessionState } from "../editor-state/session-state";
import { SelectableTarget } from "../editor-state/types";
import { createMoveDataItems } from "../helpers/gdl-move-absolute";
import { Bounding, INFINITE_BOUNDING } from "../helpers/misc";
import { isMac } from "../../utils/device-detect";
import {
  iterateLeafModelType,
  iterateAnyModelTypePost,
  removeOffsprings,
} from "../helpers/gdl-tree";
import {
  DimensionToBounding,
  pickDimension,
  pickXY,
} from "../helpers/data-picker";
import { CanvasEventProps } from "../canvas-events/canvas-event";
import { PointerEvent as MyPointerEvent } from "../canvas-events/events";
import {
  CanvasChildLayoutDimensionOperator,
  ElementChildLayoutAdjustmentOperator,
  ElementChildLayoutDimensionOperator,
} from "../gdl-layout/state.layout.child";
import { createOverrideValueSorted } from "../../utils/mobx-override-values";
import { callContextMenu } from "../gdl-common/popup";
import { GlobalContext } from "../ui-components/global-context";

export type RootOptions = {
  readonly allowRotation: boolean;
  readonly allowRegionSelection: boolean;
  readonly allowMultiSelection: boolean;
  readonly allowResize: boolean;
  readonly allowMove: boolean;
  readonly allowDisplayParents: boolean;
  readonly allowDisplayPadding: boolean;
};

export class RootState {
  readonly disposers = new Disposers();

  readonly eventRoot = new EventRoot();

  readonly renderInSvg = new RenderRootState();

  readonly renderInReact = new RenderRootState();

  constructor(
    readonly props: {
      readonly ctx?: GlobalContext;
      readonly session: SessionState;
      readonly rootTarget: SelectableTarget;
      readonly eventSystem?: (typeof EventSystemModuleToken)["TState"];
      readonly options: RootOptions;
    }
  ) {
    makeObservable(this);
  }

  @computed
  get uiScale() {
    return 1 / this.props.session.viewport.scale || 0;
  }

  @memorize
  get multiSelection() {
    return new MultiSelectionState(this);
  }

  isMoveStarted = false;

  selectionDownData = { x: 0, y: 0 };

  @observable.ref
  selection?: SelectionState = undefined;

  @observable.ref
  move?: MoveState = undefined;

  @observable.ref
  flexListReorder?: FlexListReorderState = undefined;

  @computed
  get isMoving() {
    return !!(this.move || this.flexListReorder);
  }

  checkCanMove(targets: readonly SelectableTarget[]): boolean {
    return targets.every((x) => !getSharedState(x).isLocked);
  }

  handleSelectionStart(_e: PointerEvent) {
    this.disposeSelectionAndMove().override("selection", () => {
      this.selection?.dispose();
      this.selection = undefined;
    });
    return (this.selection = new SelectionState(this, this.selectionDownData));
  }

  disposeSelectionAndMove() {
    this.isMoveStarted = false;
    return this.disposers.dispose("selection").dispose("any move");
  }

  handleSelectionEnd() {
    this.disposeSelectionAndMove();
  }

  handleMoveStart(
    e: PointerEvent,
    init: { readonly x: number; readonly y: number }
  ) {
    this.disposeSelectionAndMove().override("any move", () => {
      this.move?.dispose();
      this.move = undefined;
      this.flexListReorder?.dispose();
      this.flexListReorder = undefined;
    });

    this.isMoveStarted = true;

    if (!this.props.options.allowMove) return;

    const targets = Array.from(this.props.session.selection);
    if (!this.checkCanMove(targets)) return;

    if (targets.every((x) => x.parent()?.layout.containerType === "absolute")) {
      // 全部元素都是自由布局子元素
      const items = createMoveDataItems(targets);
      this.move = new MoveState(this, { coordinate: init, items });
      this.move.handlers.move(e);
      return;
    }

    {
      const state = new FlexListReorderState(this, targets);
      if (!state.data) return;
      this.flexListReorder = state;
      state.handleDragStart(e);
      return;
    }
  }

  handleMoveEnd() {
    this.disposeSelectionAndMove();
  }

  getNodeState(parent: ContainerState | null, target: SelectableTarget) {
    return target.kind === "frame"
      ? this.getContainerNodeState(parent, target)
      : this.getLeafNodeState(parent, target);
  }

  @memorize
  get getLeafNodeState() {
    return cached(
      <T extends LeafModelType = LeafModelType>(
        parent: ContainerState | null,
        target: T
      ) => new LeafNodeState({ root: this, parent, target })
    );
  }

  @memorize
  get getContainerNodeState() {
    return cached(
      <T extends AnyParentModelType = AnyParentModelType>(
        parent: ContainerState | null,
        target: T
      ) => new ContainerState({ root: this, parent, target })
    );
  }

  mount() {}

  unmount() {
    this.disposers.clear();
  }

  dispatchPointerEvent(eventData: PointerEventData) {
    const ret = this.eventRoot.dispatchPointerEvent(eventData);
    const { ui } = this.props.session;
    const { disposers } = this;
    disposers.dispose("cursor");
    const { cursor } = ret;
    if (cursor) {
      const c = typeof cursor === "function" ? cursor() : cursor;
      if (c) {
        disposers.add(
          "cursor",
          ui.canvasCursor.create({ cursor: c, priority: 50 })
        );
      }
    }
    return ret;
  }

  @memorize
  get CanvasEventProps(): CanvasEventProps<undefined> {
    const handlers = mapValues(
      {
        "pointer down": "down",
        "pointer move": "move",
        "pointer up": "up",
        "pointer context menu": "context menu",
      } as const,
      (action) => {
        return (e: MyPointerEvent) => {
          const data = createPointerEventData(e, action);
          const { matched } = this.dispatchPointerEvent(data);
          if (matched) e.stopPropagation();
        };
      }
    );
    return {
      description: "canvas-operation",
      sortData: {
        nodeType: "element",
        eventType: "pointer",
        priority: 50,
        indices: EMPTY_ARRAY,
      },
      data: undefined,
      collision: {
        bounding: INFINITE_BOUNDING,
        handlers,
      },
    };
  }

  @memorize
  get DeepSelectElementRegister(): PointerEventRegister {
    return {
      path: EMPTY_ARRAY,
      layer: "global",
      selected: false,
      data: once(
        (): PointerEventRegisterData => ({
          bbox: new BBox(INFINITE_BOUNDING),
          handlers: {
            down: (e) => {
              const { data } = e;
              const cmdKey = isMac() ? data.metaKey : data.ctrlKey;
              if (!cmdKey) return;
              const target = this.props.rootTarget;
              if (target.kind !== "frame") return;

              const point = [data.x, data.y] as const;

              for (const layer of iterateLeafModelType(target)) {
                if (!getSharedState(layer).isSelectable) continue;
                const b = GDLLayoutSemantic.boxToBounding({
                  ...pickDimension(layer.layout.selfBox),
                  x: 0,
                  y: 0,
                });
                const t = layer.layout.coordinate.selfFromWorldTransform
                  .clone()
                  .inverse();

                const inside = new BBox(b, t).contains(point);
                if (!inside) continue;

                e.stopPropagation();

                const { eventSystem, session } = this.props;
                const { selection } = session;
                if (eventSystem) {
                  const event = eventSystem.dispatch(SelectGDLObject, {
                    target: layer,
                  });
                  if (event.isPropagationStopped()) return;
                }
                const multi = data.shiftKey;
                selection.select(layer, multi);

                return;
              }
            },
          },
        })
      ),
    };
  }

  @observable.ref
  regionSelectionInitData?: {
    readonly x: number;
    readonly y: number;
  } = undefined;

  @observable.ref
  isRegionSelecting = false;

  @observable.ref
  regionSelectionCurrentData?: {
    readonly x: number;
    readonly y: number;
    readonly selection: ReadonlySet<SelectableTarget>;
    readonly additionalSelection: ReadonlySet<SelectableTarget>;
    readonly optimizedSelection: ReadonlySet<SelectableTarget>;
    readonly bounding: Bounding;
    readonly contentBounding?: Bounding;
  } = undefined;

  @memorize
  get RegionRegister(): PointerEventRegister {
    return {
      path: EMPTY_ARRAY,
      layer: "fallback",
      selected: false,
      data: once(
        (): PointerEventRegisterData => ({
          bbox: new BBox(INFINITE_BOUNDING),
          handlers: {
            down: (e) => {
              const { eventSystem, session, options } = this.props;
              const { selection } = session;
              if (eventSystem) {
                eventSystem.dispatch(ClearGDLObjectSelection, { selection });
              }
              if (!e.data.shiftKey || !options.allowRegionSelection) {
                // 清空选择
                selection.replace();
                this.disposeSelectionAndMove();
              }

              if (!options.allowRegionSelection) return;

              this.regionSelectionInitData = pickXY(e.data);
            },
          },
        })
      ),
    };
  }

  @memorize
  get RegionSelectionRegister(): PointerEventRegister {
    return {
      path: EMPTY_ARRAY,
      layer: "cover",
      selected: false,
      data: once(
        (): PointerEventRegisterData => ({
          bbox: new BBox(INFINITE_BOUNDING),
          handlers: {
            move: (e) => {
              // 元素"一触即发", 一碰到就当做选中
              // 容器必须全部包含才算选中
              e.stopPropagation();
              const init = this.regionSelectionInitData;
              if (!init) return;

              const { data } = e;

              const rafId = raf(() => {
                const target = this.props.rootTarget;

                const b1 = x1y1x2y2ToBounding(data.x, data.y, init.x, init.y);

                const activeSelection = new Set(this.props.session.selection);
                const allSelection = new Set(activeSelection);
                const additionalSelection = new Set<SelectableTarget>();

                const boundings: Bounding[] = [];

                const isDeepSelect = isMac() ? data.metaKey : data.ctrlKey;

                const iterable = isDeepSelect
                  ? iterateAnyModelTypePost(target)
                  : target.kind === "frame"
                  ? target.children
                  : EMPTY_ARRAY;

                for (const node of iterable) {
                  if (allSelection.has(node)) continue;
                  if (!getSharedState(node).isSelectable) continue;
                  const b2 = GDLLayoutSemantic.boxToBounding({
                    ...pickDimension(node.layout.selfBox),
                    x: 0,
                    y: 0,
                  });
                  const t = node.layout.coordinate.selfFromWorldTransform;
                  if (isDeepSelect && node.kind === "frame") {
                    // 深度选取模式下, 容器必须全部包含才算选中
                    const B2 = Affine2DTimesBounding(t, b2);
                    if (
                      b1.left <= B2.left &&
                      B2.left <= b1.right &&
                      b1.left <= B2.right &&
                      B2.right <= b1.right &&
                      b1.top <= B2.top &&
                      B2.top <= b1.bottom &&
                      b1.top <= B2.bottom &&
                      B2.bottom <= b1.bottom
                    ) {
                      allSelection.add(node);
                      additionalSelection.add(node);
                      boundings.push(B2);
                    }
                  } else {
                    // 元素"一触即发", 一碰到就当做选中
                    const ret = isBoundingCollided(b1, b2, t);
                    if (ret.result) {
                      allSelection.add(node);
                      additionalSelection.add(node);
                      boundings.push(ret.y);
                    }
                  }
                }

                const optimizedSelection = new Set(additionalSelection);
                removeOffsprings(optimizedSelection);

                this.isRegionSelecting = true;
                this.regionSelectionCurrentData = {
                  ...pickXY(data),
                  selection: new Set([
                    ...activeSelection,
                    ...optimizedSelection,
                  ]),
                  additionalSelection,
                  optimizedSelection,
                  bounding: b1,
                  contentBounding: mergeBoundings(boundings),
                };

                const { ui } = this.props.session;
                const dispose =
                  ui.temporarySelection.create(optimizedSelection);
                this.disposers.override("temp selection", dispose);
              });
              this.disposers.override("region selection raf", () =>
                raf.cancel(rafId)
              );
            },
            up: (e) => {
              e.stopPropagation();
              this.disposers.dispose("region selection raf");
              this.disposers.dispose("temp selection");
              const curr = this.regionSelectionCurrentData;
              if (curr) {
                const { selection } = curr;
                if (selection.size) {
                  this.props.session.selection.add(...selection);
                }
              }
              this.isRegionSelecting = false;
              this.regionSelectionInitData = undefined;
              this.regionSelectionCurrentData = undefined;
            },
          },
        })
      ),
    };
  }
}

let NEXT_NODE_STATE_ID = 0;

export abstract class NodeState<T extends SelectableTarget = SelectableTarget> {
  constructor(
    readonly props: {
      readonly root: RootState;
      readonly parent: ContainerState | null;
      readonly target: T;
    }
  ) {
    makeObservable(this);
  }

  readonly id = NEXT_NODE_STATE_ID++;

  readonly disposers = new Disposers();

  @memorize
  get rotation() {
    const { root, target } = this.props;
    const shared = getSharedState(target);
    const { layout } = target;
    const { selfBox, transform, coordinate } = layout;
    return new RotationState({
      getRoot: () => root,
      getColor: () => shared.frameColor,
      getDegree: () => transform.rotation,
      setDegree: (value) => (transform.rotation = value),
      getTransform: () => coordinate.getRotatedFromWorld(),
      getDimension: () => selfBox,
      getHoverRegister: () => ({
        path: this.path,
        layer: "highlight",
        selected: true,
      }),
    });
  }

  @memorize
  get resize(): ResizeState<unknown> {
    const target: SelectableTarget = this.props.target;
    if (target.kind === "frame" && !target.parent()) {
      return this.createCanvasResize(target) as ResizeState<unknown>;
    }
    return this.createElementResize(target) as ResizeState<unknown>;
  }

  protected createCanvasResize(target: Frame) {
    const { root } = this.props;
    const shared = getSharedState(target);
    const { layout } = target;
    const { selfBox, coordinate } = layout;
    const DimensionOperator = CanvasChildLayoutDimensionOperator.of(target);
    return new ResizeState<{
      readonly width: number;
      readonly height: number;
    }>({
      getRoot: () => root,
      getColor: () => shared.frameColor,
      getAllowedSides: () => {
        const allowedSides = { horizontal: true, vertical: true, corner: true };
        if (!DimensionOperator.isValueMutable("width")) {
          allowedSides.horizontal = false;
        }
        if (!DimensionOperator.isValueMutable("height")) {
          allowedSides.vertical = false;
        }
        return allowedSides;
      },
      getTransform: () => coordinate.getRotatedFromWorld(),
      getHoverRegister: () => ({
        path: this.path,
        layer: "highlight",
        selected: true,
      }),
      getBounding: () => new DimensionToBounding(selfBox),
      getSnapshot: () => pickDimension(target.layout.selfBox),
      apply: (init, input) => {
        const resizableX =
          typeof target.layout.canvas.dimension.width === "number";
        const resizableY =
          typeof target.layout.canvas.dimension.height === "number";

        const newInput = ((): typeof input | undefined => {
          if (resizableX) {
            if (resizableY) return input;
            // 只能水平变化
            if (input.kind === "single") {
              // 只能水平变化时, 上下是拉不动的
              if (input.side === "top" || input.side === "bottom") return;
              return { ...input, preserveRatio: false };
            } else {
              // 角拉动变水平拉动
              return {
                ...input,
                kind: "single",
                side: input.horizontal === "start" ? "left" : "right",
                preserveRatio: false,
              };
            }
          } else {
            if (!resizableY) return;
            // 只能竖直变化
            if (input.kind === "single") {
              // 只能竖直变化, 左右是拉不动的
              if (input.side === "left" || input.side === "right") return;
              return { ...input, preserveRatio: false };
            } else {
              // 角拉动变竖直拉动
              return {
                ...input,
                kind: "single",
                side: input.vertical === "start" ? "top" : "bottom",
                preserveRatio: false,
              };
            }
          }
        })();

        if (!newInput) return;

        const newBounding = GDLLayoutSemantic.createResizeBoundingGetter(
          { ...newInput, symmetric: true },
          { left: 0, top: 0, right: init.width, bottom: init.height },
          IdentityAffine2D
        )();

        if (resizableX) {
          const width = newBounding.right - newBounding.left;
          target.layout.canvas.setDimension({ width });
        }
        if (resizableY) {
          const height = newBounding.bottom - newBounding.top;
          target.layout.canvas.setDimension({ height });
        }
      },
      onStart: () => {
        const { viewport } = root.props.session;
        viewport.disableConstraint();
      },
      onEnd: () => {
        const { viewport } = root.props.session;
        viewport.enableConstraint();
        viewport.x = viewport.x;
        viewport.y = viewport.y;
      },
    });
  }

  protected createElementResize(target: SelectableTarget) {
    const { root } = this.props;
    const shared = getSharedState(target);
    const { layout } = target;
    const { selfBox, coordinate } = layout;
    const DimensionOperator = ElementChildLayoutDimensionOperator.of(target);
    const AdjustmentOperator = ElementChildLayoutAdjustmentOperator.of(target);
    return new ResizeState({
      getRoot: () => root,
      getColor: () => shared.frameColor,
      getTransform: () => coordinate.getRotatedFromWorld(),
      getHoverRegister: () => ({
        path: this.path,
        layer: "highlight",
        selected: true,
      }),
      getAllowedSides: () => {
        const allowedSides = { horizontal: true, vertical: true, corner: true };
        if (!DimensionOperator.isValueMutable("width")) {
          allowedSides.horizontal = false;
        }
        if (!DimensionOperator.isValueMutable("height")) {
          allowedSides.vertical = false;
        }
        if (allowedSides.horizontal && allowedSides.vertical) {
          // tslint:disable-next-line: no-collapsible-if
          if (
            !isCornerResizableDimensionType(
              AdjustmentOperator.getValue("width")
            ) ||
            !isCornerResizableDimensionType(
              AdjustmentOperator.getValue("height")
            )
          ) {
            allowedSides.corner = false;
          }
        }
        return allowedSides;
      },
      getBounding: () => new DimensionToBounding(selfBox),
      getSnapshot: () => GDLLayoutSemantic.getResizeInitialSnapshot(target),
      apply: (init, input) => GDLLayoutSemantic.resize(target, init, input),
      onStart: () => {
        const { viewport } = root.props.session;
        viewport.disableConstraint();
      },
      onEnd: () => {
        const { viewport } = root.props.session;
        viewport.enableConstraint();
      },
    });
  }

  isPointerEntered = false;

  get paddingBox(): Bounding | undefined {
    return undefined;
  }

  get isDisplayFrame() {
    return this.isSelected || this.isHovering;
  }

  get isHighlighted(): boolean {
    return !!this.selectNodeExecution;
  }

  @computed
  get isSelected(): boolean {
    const { root, target } = this.props;
    return root.props.session.selection.has(target);
  }

  @computed
  get isSiblingSelectable() {
    return !!this.props.parent?.isDisplayChildren;
  }

  @computed
  get isHovering() {
    const { root, target } = this.props;
    return !!root.props.session.ui.hovering.get(target);
  }

  @computed
  get isMovable() {
    const parent = this.props.target.parent();
    if (!parent) return false;
    const { containerType } = parent.layout;
    return containerType === "absolute" || containerType === "flex list";
  }

  get path(): readonly number[] {
    return getSharedState(this.props.target).path;
  }

  @computed
  get inverseSelfTransformFromWorld(): ReadonlyAffine2D {
    return this.props.target.layout.coordinate.selfFromWorldTransform
      .clone()
      .inverse();
  }

  @memorize
  get selectionHandlers() {
    return this.getSelectionHandlers();
  }

  @memorize
  get hoverHandlers() {
    return guardTypeExtends<PointerEventHandlers>()({
      enter: () => {
        if (this.isPointerEntered) return;
        this.isPointerEntered = true;
        this.setHovering(true);
      },
      leave: () => {
        if (!this.isPointerEntered) return;
        this.isPointerEntered = false;
        this.setHovering(false);
      },
    });
  }

  mount() {}

  unmount() {
    this.disposers.clear();
    this.hoverHandlers.leave();
  }

  @observable.ref
  selectNodeExecution?: {
    readonly multi: boolean;
  } = undefined;

  protected resetSelection() {}

  protected selectHandler(e: PointerEvent) {
    const { root, target } = this.props;
    const { eventSystem } = root.props;

    if (eventSystem) {
      const event = eventSystem.dispatch(SelectGDLObject, { target });
      if (event.isDefaultPrevented()) {
        this.resetSelection();
        return false;
      }
    }

    const { data } = e;
    const multi = data.shiftKey || (isMac() ? data.metaKey : data.ctrlKey);

    root.selectionDownData = { x: data.absoluteX, y: data.absoluteY };

    root.handleSelectionStart(e);

    // 如果 down 的时候没有选中元素, 将要添加元素, 而不清除其他元素 (即 add 操作)
    // 在 down 事件中就触发选择
    // 否则在 up 事件中触发选择

    const { selection } = root.props.session;
    if (!selection.has(target)) {
      selection.select(target, multi);
      this.selectNodeExecution = undefined;
    } else {
      this.selectNodeExecution = { multi };
    }

    return true;
  }

  protected getSelectionHandlers(): PointerEventHandlers {
    return {
      ...this.hoverHandlers,
      down: (e) => {
        e.stopPropagation();

        this.selectHandler(e);
      },
      move: (e) => {
        e.stopPropagation();
        const { root } = this.props;
        if (!root.selection) return;
        if (root.isMoveStarted) return;
        this.selectNodeExecution = undefined;
        // 转交移动开始的控制权
        root.handleMoveStart(e, root.selectionDownData);
      },
      up: (e) => {
        e.stopPropagation();
        const { root, target } = this.props;
        try {
          if (root.isMoveStarted) return;
          const select = this.selectNodeExecution;
          if (select) {
            this.selectNodeExecution = undefined;
            const { selection } = root.props.session;
            selection.select(target, select.multi);
          }
        } finally {
          root.handleSelectionEnd();
        }
      },
      "context menu": (e) => {
        const { root } = this.props;
        const { ui } = root.props.session;

        const { canvasRef } = ui;
        if (!canvasRef) return;

        e.stopPropagation();

        const { session, ctx } = root.props;

        const { x, y } = canvasRef.getBoundingClientRect();
        const { data } = e;
        const point = [data.x, data.y] as const;

        function* iterate() {
          for (const node of iterateLeafModelType(session.root)) {
            const b = GDLLayoutSemantic.boxToBounding({
              ...pickDimension(node.layout.selfBox),
              x: 0,
              y: 0,
            });
            const t = node.layout.coordinate.selfFromWorldTransform
              .clone()
              .inverse();

            const inside = new BBox(b, t).contains(point);
            if (inside) yield node;
          }
        }

        callContextMenu(ctx, x + data.absoluteX, y + data.absoluteY, {
          getTargets: iterate,
        });
      },
    };
  }

  private setHovering(value: boolean) {
    const { root, target } = this.props;
    const { ui } = root.props.session;
    ui.hovering.set(target, value, `canvas-operation:${this.id}`);
  }
}

export const FRAME_COLORS = {
  container: "#0000ff",
  element: "#666",
} as const;

export class LeafNodeState<
  T extends LeafModelType = LeafModelType
> extends NodeState<T> {
  override get isHighlighted(): boolean {
    return super.isHighlighted || !!this.props.parent?.isDisplayChildren;
  }
}

export class ContainerState<T extends Frame = Frame> extends NodeState<T> {
  clicks = 0;
  clickTimeout?: ReturnType<typeof setTimeout>;

  @observable.ref
  isExpanded = false;

  constructor(props: {
    readonly root: RootState;
    readonly parent: ContainerState | null;
    readonly target: T;
  }) {
    super(props);
    makeObservable(this);
  }

  override unmount() {
    super.unmount();
    if (this.clickTimeout) clearTimeout(this.clickTimeout);
  }

  override get paddingBox(): Bounding | undefined {
    const { layout } = this.props.target;
    if (layout.containerType === "absolute") return;
    return this.computedPaddingBox;
  }

  @memorize
  get computedPaddingBox() {
    const { padding } = this.props.target.layout.flexbox;
    return {
      get left() {
        return padding.left.computedValue;
      },
      get right() {
        return padding.right.computedValue;
      },
      get top() {
        return padding.top.computedValue;
      },
      get bottom() {
        return padding.bottom.computedValue;
      },
    };
  }

  override get isDisplayFrame() {
    return (
      super.isDisplayFrame ||
      (this.props.root.props.options.allowDisplayParents &&
        this.isAnyChildSelected)
    );
  }

  override get isHighlighted(): boolean {
    return (
      super.isHighlighted ||
      this.props.parent?.isDisplayChildren ||
      this.isDisplayChildren
    );
  }

  @computed
  get isDisplayChildren(): boolean {
    if (this.isExpanded) return true;
    const { children } = this;
    if (!children.length) return false;
    const { root } = this.props;
    for (const child of children) {
      if (child.kind === "frame") {
        const state = root.getContainerNodeState(this, child);
        if (state.isSelected || state.isHovering || state.isDisplayChildren)
          return true;
      } else {
        const state = root.getLeafNodeState(this, child);
        if (state.isSelected || state.isHovering) return true;
      }
    }
    return false;
  }

  @computed
  get isAnyChildSelected(): boolean {
    const { children } = this;
    if (!children.length) return false;
    const { root } = this.props;
    for (const child of children) {
      if (child.kind === "frame") {
        const state = root.getContainerNodeState(this, child);
        if (state.isSelected) return true;
        if (state.isAnyChildSelected) return true;
      } else {
        const state = root.getLeafNodeState(this, child);
        if (state.isSelected) return true;
      }
    }
    return false;
  }

  @computed
  get children(): readonly SelectableTarget[] {
    return this.props.target.children.slice().reverse();
  }

  protected override resetSelection() {
    this.clicks = 0;
  }

  protected override selectHandler(e: PointerEvent) {
    const clicks = ++this.clicks;

    if (this.clickTimeout) clearTimeout(this.clickTimeout);

    const { root } = this.props;

    if (clicks >= 2 && !this.isExpanded) {
      this.clicks = 0;
      // 展开 children
      this.isExpanded = true;
      // 在下一个 microtask 循环中触发点击事件, 以触发 children 的点击事件
      Promise.resolve().then(() =>
        runInAction(() => {
          root.dispatchPointerEvent({ ...e.data, action: "down" });
          this.isExpanded = false;
        })
      );
      return false;
    }

    if (!super.selectHandler(e)) return false;

    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = undefined;
      this.clicks = 0;
    }, 300);

    return true;
  }
}

export class SharedState<T extends SelectableTarget = SelectableTarget> {
  constructor(readonly target: T) {
    makeObservable(this);
  }

  /**
   * 选择框设置覆盖配置
   */
  readonly selectFrameSetting = createOverrideValueSorted<{
    readonly visible: boolean;
    readonly priority: number;
  }>()((x) => x)((a, b) => b.priority - a.priority);

  @computed
  get frameColor() {
    return getFrameColor(this.target);
  }

  @computed
  get path(): readonly number[] {
    const { target } = this;
    const parent = target.parent();
    if (!parent) return EMPTY_ARRAY;
    const { children } = parent;
    const index = children.indexOf(target);
    return [...getSharedState(parent).path, children.length - 1 - index];
  }

  @computed
  get isSelectable(): boolean {
    const { target } = this;
    if (!target.properties.disabled && !target.properties.locked) {
      const p = target.parent();
      if (p) return getSharedState(p).isSelectable;
      return true;
    }
    return false;
  }

  @computed
  get isLocked(): boolean {
    const { target } = this;
    if (target.properties.locked) return true;
    const p = target.parent();
    if (p) return getSharedState(p).isLocked;
    return false;
  }
}

export function getFrameColor(target: SelectableTarget) {
  if (target.kind === "frame") return FRAME_COLORS.container;
  return FRAME_COLORS.element;
}

export const getSharedState = cached(
  <T extends SelectableTarget = SelectableTarget>(target: T) =>
    new SharedState(target)
);

const isCornerResizableDimensionType = createValueInSetGuardian(
  new Set(["scale", "fixed", "ratio"] as const)
);

function createPointerEventData(
  e: MyPointerEvent,
  action: PointerEventAction
): PointerEventData {
  const { altKey, ctrlKey, shiftKey, metaKey } = e.nativeEvent;
  const { x, y, absoluteX, absoluteY } = e;
  return {
    kind: "pointer",
    action,
    altKey,
    ctrlKey,
    shiftKey,
    metaKey,
    x,
    y,
    absoluteX,
    absoluteY,
  };
}

function x1y1x2y2ToBounding(x1: number, y1: number, x2: number, y2: number) {
  const left = Math.min(x1, x2);
  const right = Math.max(x1, x2);
  const top = Math.min(y1, y2);
  const bottom = Math.max(y1, y2);
  return { left, right, top, bottom };
}
