import * as React from 'react'
import styled from 'styled-components'

export interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  readonly space?: 'auto' | 'stretch'
  readonly size?: 'small' | 'default'
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { space, size, ...newProps } = props
  if (props.disabled) {
    newProps.onClick = undefined
  }
  if (size && size !== 'default') {
    newProps.className = appendClassName(newProps.className, `size-${size}`)
  }
  if (space && space !== 'auto') {
    newProps.className = appendClassName(newProps.className, `space-${space}`)
  }
  return <ButtonDOM {...newProps} ref={ref} />
})

function appendClassName(x: string | undefined, y: string) {
  return x ? `${x} ${y}` : y
}

const ButtonDOM = styled.button`
  flex: 0 0 auto;
  display: block;
  padding: 8px;
  color: #606060;
  background: #f8f7f6;
  box-sizing: border-box;
  border: 1px solid #f8f7f6;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;

  &.size-small {
    padding: 4px;
    border-radius: 3px;
  }

  &.space-stretch {
    flex: 1 1 0;
    width: 0;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
